import { REHYDRATE } from 'redux-persist';
import { SHOWBROADCASTSCREEN, LOGOUT_RESET } from '../../actions/types';

const INITIAL_STATE = false;

export default function (state = INITIAL_STATE, action) {

    switch (action.type) {
        case REHYDRATE: if (action.payload && action.payload.show_broadcast_screen) return action.payload.show_broadcast_screen;
        else return INITIAL_STATE;
        case SHOWBROADCASTSCREEN: return action.data;
        case LOGOUT_RESET: return INITIAL_STATE;
        default: return state;
    }
}