import { REHYDRATE } from 'redux-persist';
import { GEONAMES_STATE_LIST, GEONAMES_STATE_LIST_SUCCESS, GEONAMES_STATE_LIST_ERROR } from '../../actions/types';
import ApiError, { handleNetworkError } from '../../utils/api-error';

const INITIAL_STATE = {
    geonames_state_list: [],
    cache: null,
    error: undefined,
    loading: false,
    is_logout: false
};

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case REHYDRATE: if (action.payload && action.payload.geonames_state_list) return { ...action.payload.geonames_state_list }; else return INITIAL_STATE;
        case GEONAMES_STATE_LIST: return { ...INITIAL_STATE, loading: true, is_logout: false };
        case GEONAMES_STATE_LIST_SUCCESS:
            const apiData = action.payload && action.payload.apiData;
            const cache = action.payload && action.payload.cache;
            if (apiData !== undefined && apiData !== null) {
                const state_list = ApiError(apiData.statusCode, apiData.message, apiData.gsRespData, "states", []);
                if (state_list && state_list.constructor === Array) {
                    return { ...state, loading: false, is_logout: false, geonames_state_list: state_list, cache: cache }

                } else {
                    return { ...state, loading: false, is_logout: false, error: state_list };
                }

            } else {
                return { ...state, loading: false, is_logout: false };
            }
        case GEONAMES_STATE_LIST_ERROR:
            let hNE_res = handleNetworkError(action);
            if (hNE_res.error)
                return { ...INITIAL_STATE, ...hNE_res, loading: false, is_logout: false };
            else if (action.payload && action.payload.response && action.payload.response.data && action.payload.response.data.error) {
                if (action.payload.response.data.error.statusCode === 401)
                    return { ...INITIAL_STATE, error: action.payload.response.data.error, geonames_state_list: [], loading: false, is_logout: true };
                else
                    return { ...INITIAL_STATE, error: action.payload.response.data.error, geonames_state_list: [], loading: false, is_logout: false };
            }
            else
                return { ...INITIAL_STATE, error: action.payload, geonames_state_list: [], loading: false, is_logout: false };
        default: return state;
    }
}