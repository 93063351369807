import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from '@fortawesome/free-solid-svg-icons';
import ProfilePicAvatar from 'components/profile-pic';
import styles from './styles';

/*
    category_id: 14,
    community_referred: false,
    display_name: "Lotus painting services",
    distance: 0.29,
    sp_id: "55489276-3cb0-4e68-8afc-e5dad795263d",
    sp_profile_pic: "in/55489276-3cb0-4e68-8afc-e5dad795263d/SP_PROFILE_PIC_d0131a00-d555-11e9-8466-69244c4715d8.jpeg",
    sp_rating: null,
    store_profile_pic: null,
    work_exp: "8"
*/

function SPListItem({ sp, handleClick }) {
    return (
        <div onClick={() => handleClick()} style={styles.container}>
            <ProfilePicAvatar
                size={40}
                profile_pic={sp.sp_profile_pic || sp.store_profile_pic}
                display_name={sp.display_name}
            />
            <div style={styles.outerStyle}>
                <div style={styles.headingStyle}>{sp.display_name}</div>
                <div style={styles.innerStyle}>
                    {/*<div style={styles.categoryStyle}>{sp.category_name}</div>*/}
                    {sp.service_name ?
                        <div style={styles.serviceStyle}>{sp.service_name}</div>
                        :<div style={styles.categoryStyle}>{sp.category_name}</div>
                    }                    
                </div>
                <div style={styles.addressContainer}>
                    <span style={styles.addressStyle}>Exp. - {sp.work_exp ? `${sp.work_exp} years` : "NA"} </span>
                    <span style={styles.separatorStyle}>|</span>
                    <span style={styles.ratingStyle}><FontAwesomeIcon className='svg-inline--fa' icon={faStar} style={styles.starIconStyle} />   {sp.sp_rating || "NA"}</span>
                    <span style={styles.separatorStyle}>|</span>
                    <span style={styles.distanctStyle}>{sp.distance} KM</span>
                </div>
            </div>
        </div>
    );
}

export default SPListItem;