import { REHYDRATE } from 'redux-persist';
import {
    GET_DISCOUNT_CODE_LIST,
    GET_DISCOUNT_CODE_LIST_SUCCESS,
    GET_DISCOUNT_CODE_LIST_ERROR,
    CLEAR_DISCOUNT_CODE_LIST_CACHE,
} from '../../../actions/types';
import ApiError, { parseError } from '../../../utils/api-error';

const INITIAL_STATE = {
    loading: true,
    error: undefined,
    discount_list: [],
    cache: undefined,
};


const DiscountCodeList = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case REHYDRATE:
            return action.payload && action.payload.food_discount_code_list
                ? { ...action.payload.food_discount_code_list }
                : INITIAL_STATE;

        case GET_DISCOUNT_CODE_LIST:
            return { ...state, loading: true };

        case GET_DISCOUNT_CODE_LIST_SUCCESS: {
            const apiData = action.payload;
            const discount_list = ApiError(apiData.statusCode, apiData.message, apiData.gsRespData, "discount_list", []);

            if (discount_list && discount_list.error) {
                return { error: discount_list.error, loading: false, cache: undefined, delivery_data: undefined };
            } else {
                return { error: undefined, loading: false, cache: action.cache, discount_list: discount_list || [] };
            }
        }

        case GET_DISCOUNT_CODE_LIST_ERROR:
            return { ...parseError(action) };

        case CLEAR_DISCOUNT_CODE_LIST_CACHE:
            return {
                ...state,
                cache: undefined,
            }

        default:
            return state;
    }
};

export default DiscountCodeList;