import { encodeUrl, parseCityData } from 'utils/helper-functions';
import { getCityList } from '../../home/api';
import { city_list, city, country_selected } from 'config/storage-variables';
import flagIconCSSCountryCodes from 'components/country-code/utils/flagIconCSSCountryCodes';

export function setDefaultCountry(default_country) {
    if (!default_country) {
        return;
    }

    let localVal = localStorage.getItem(country_selected);
    const countryLocal = localVal && typeof localVal === "string" && localVal.toLowerCase();

    if (countryLocal && flagIconCSSCountryCodes.includes(countryLocal)) {
        return countryLocal;
    }

    if (typeof default_country === 'string') {
        default_country = default_country.toLowerCase();
        if (flagIconCSSCountryCodes.includes(default_country)) {
            localStorage.setItem(country_selected, default_country);
            return default_country;
        }
    }
}

export const getCityId = city_name => {
    const name = encodeUrl(city_name);

    return new Promise((resolve, reject) => {
        let open_city_list = sessionStorage.getItem(city_list) ? JSON.parse(sessionStorage.getItem(city_list)) : null;

        if (open_city_list && open_city_list.constructor === Array && open_city_list.length > 0) {
            const selected_city = searchCityId(open_city_list, name);
            if (selected_city) {
                localStorage.setItem(city, JSON.stringify(selected_city));
                resolve(selected_city.id);
            } else {
                resolve(null);
            }
        } else {
            getCityList((res, err) => {
                const resCities = res.city_list;
                const countrySelected = res.default_country && typeof res.default_country === 'string' && res.default_country.toLowerCase();
                if (resCities && resCities.constructor === Array && resCities.length > 0) {
                    sessionStorage.setItem(city_list, JSON.stringify(resCities));
                    const selected_city = searchCityId(resCities, name);
                    if (selected_city) {
                        localStorage.setItem(city, JSON.stringify(selected_city));
                        localStorage.setItem(country_selected, countrySelected);
                        resolve(selected_city.id);
                    } else {
                        resolve(null);
                    }
                } else {
                    resolve(null)
                }
            });
        }
    });
}

function searchCityId(city_list, name) {
    for (const city of city_list) {
        if (encodeUrl(city.city) === name) {
            return parseCityData(city);
        }
    }

    return null;
}