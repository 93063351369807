import { setCookie, getCookie, deleteCookie } from './helper-functions';
import {
    ads_fetched, fetch_service_category, ss_allservices, ss_services, ss_all_svc_discounts,
    bkg_call_questionnaire_api, bkg_call_service_specific_api,
    ssr_book_now_click,
    service_category,
    city,
    ref_code,
    device_id, pushUriId, pushUriUpdate,
    cl_signedIn, sp_signedIn,
    cl_user_id, cl_access_token,
} from '../config/storage-variables';
import { GRS_ENV_CLIENT, GRS_ENV_SP } from '../config/config';
import { removePushUri } from 'scenes/client/scenes/authentication/api';

// This function set and remove session and local storage values based on ssr requirements.

const cat_ssr = "cat",
    svc_ssr = "svc",
    sp_ssr = "sp",
    ssr_type = "ssr_type",      //  --  string/enum         --  ['cat', 'svc', 'sp', 'faqcl', 'faqsp']
    selected_city = "selected_city",    //  --  JSON        --  '{"name":"Nagpur","id":1}'
    share_ref_code = "share_ref_code",  //  --  string      --  'ABCD1234'
    DEVICE_ID = "DEVICE_ID",
    PUSH_URI = "PUSH_URI",
    GRS_ENV = "GRS_ENV",
    USER_ID = "USER_ID",
    ACCESS_TOKEN = "ACCESS_TOKEN";


export function addDeviceIdCookies(device_id) {
    setCookie(DEVICE_ID, device_id, 365, "/", window.location.hostname);
}

function updateSelectedCity() {
    //  cookie | selected_city      ->      localstorage | city

    const selected_city_val = getCookie(selected_city);

    if (selected_city_val !== "") {
        var city_uri = selected_city_val;
        var encode = decodeURIComponent(city_uri);

        localStorage.setItem(city, encode);

        deleteCookie(selected_city);
    }
}

function updateSharedReferralCode() {
    //  cookie | share_ref_code      ->      localstorage | ref_code

    const share_ref_code_val = getCookie(share_ref_code);

    if (share_ref_code_val !== "") {
        localStorage.setItem(ref_code, share_ref_code_val);

        deleteCookie(share_ref_code);
    }
}

function handleCategorySSR() {
    /*
        localstorage
        city        --  set
        service_category        --  remove
    
        session
        fetch_service_category      --  remove
        allservices     --  remove
        services        --  remove
        ads_fetched     --  remove
    */

    localStorage.removeItem(service_category);

    sessionStorage.removeItem(fetch_service_category);
    sessionStorage.removeItem(ss_allservices);
    sessionStorage.removeItem(ss_services);
    sessionStorage.removeItem(ads_fetched);
    sessionStorage.removeItem(ss_all_svc_discounts);
}

function handleServiceSSR() {
    /*
        localstorage
        city        --  set
        service_category        --  remove
    
        session
        fetch_service_category      --  remove
        allservices     --  remove
        services        --  remove
        ads_fetched     --  remove
        bkg_call_questionnaire_api      --  remove
        bkg_call_service_specific_api       --  remove
    */

    localStorage.removeItem(service_category);

    sessionStorage.removeItem(fetch_service_category);
    sessionStorage.removeItem(ss_allservices);
    sessionStorage.removeItem(ss_services);
    sessionStorage.removeItem(ads_fetched);
    sessionStorage.removeItem(ss_all_svc_discounts);
    sessionStorage.removeItem(bkg_call_questionnaire_api);
    sessionStorage.removeItem(bkg_call_service_specific_api);
}

function handleSPSSR() {
    /*
        localstorage
        ref_code        --  set
    
        session
        ssr_book_now_click      --  set
    */

    sessionStorage.setItem(ssr_book_now_click, 'true');
}

function handleSSRType() {
    const ssr_val = getCookie(ssr_type);

    if (ssr_val !== "") {
        if (ssr_val === cat_ssr) {
            handleCategorySSR();
        }
        else if (ssr_val === svc_ssr) {
            handleServiceSSR();
        }
        else if (ssr_val === sp_ssr) {
            handleSPSSR();
        }

        deleteCookie(ssr_type);
    }
}

function removeOldDevicePushUri(device_id) {
    if (device_id) {
        if (localStorage.getItem(cl_signedIn) === 'true' || localStorage.getItem(sp_signedIn) === 'true') {
            let push_uri = localStorage.getItem(pushUriId) ? localStorage.getItem(pushUriId) : '';
            removePushUri(device_id, push_uri);
        }
    }
}

function handleDeviceId() {
    const device_id_val = getCookie(DEVICE_ID);
    const device_id_ls = localStorage.getItem(device_id);

    if (device_id_val && device_id_val !== device_id_ls) {
        if (device_id_ls) {
            removeOldDevicePushUri(device_id_ls);
        }

        localStorage.setItem(device_id, device_id_val);
    }
    else if (!device_id_val) {
        addDeviceIdCookies(device_id_ls);
    }
}

function handlePushUri() {
    const push_uri_val = getCookie(PUSH_URI);
    const push_uri_ls = localStorage.getItem(pushUriId);

    if (push_uri_val && push_uri_val !== push_uri_ls) {
        localStorage.setItem(pushUriId, push_uri_val);

        // Update push update flag to call api and update it accordingly
        if (push_uri_ls) {
            sessionStorage.setItem(pushUriUpdate, 'true');
        }
    }
}

export function preProcessCookies() {
    updateSelectedCity();
    updateSharedReferralCode();

    handleSSRType();

    handleDeviceId();
    handlePushUri();

    handleUserIdCookies();
}

export function tempAddCookie() {
    setCookie(selected_city, '{"name":"Nagpur","id":1}', 0, "/", window.location.hostname);

    setCookie(ssr_type, 'svc', 0, "/", window.location.hostname);
}

// This function updates Push uri and device id from query String
export function updateFromQS(qs) {
    if (!qs) {
        return;
    }

    if (qs.DEVICE_ID) {
        const device_id_val = getCookie(DEVICE_ID);
        const device_id_ls = localStorage.getItem(device_id);

        if (device_id_ls !== qs.DEVICE_ID) {
            localStorage.setItem(device_id, qs.DEVICE_ID);
        }
        if (device_id_val !== qs.DEVICE_ID) {
            if (device_id_ls) {
                removeOldDevicePushUri(device_id_ls);
            }

            setCookie(DEVICE_ID, qs.DEVICE_ID, 365, "/", window.location.hostname);
        }
    }

    if (qs.PUSH_URI) {
        const push_uri_val = getCookie(PUSH_URI);
        const push_uri_ls = localStorage.getItem(pushUriId);

        if (push_uri_ls !== qs.PUSH_URI) {
            localStorage.setItem(pushUriId, qs.PUSH_URI);

            // Update push update flag to call api and update it accordingly
            if (push_uri_ls) {
                sessionStorage.setItem(pushUriUpdate, 'true');
            }
        }
        if (push_uri_val !== qs.PUSH_URI) {
            setCookie(PUSH_URI, qs.PUSH_URI, 365, "/", window.location.hostname);
        }
    }

    const GRS_ENV_val = getCookie(GRS_ENV);

    // Requesting for client code, so store it in cookie for further usage.
    if (qs.CLIENT && !qs.SP && GRS_ENV_val !== GRS_ENV_CLIENT) {
        setCookie(GRS_ENV, GRS_ENV_CLIENT, 30, "/", window.location.hostname);
    }
    // Requesting for sp code, so store it in cookie for further usage.
    if (!qs.CLIENT && qs.SP && GRS_ENV_val !== GRS_ENV_SP) {
        setCookie(GRS_ENV, GRS_ENV_SP, 30, "/", window.location.hostname);
    }
}

export function addUserIdCookies(user_id, acces_token) {
    setCookie(USER_ID, user_id, 0, "/", window.location.hostname);
    setCookie(ACCESS_TOKEN, acces_token, 0, "/", window.location.hostname);
}

export function deleteUserIdCookies() {
    deleteCookie(USER_ID);
    deleteCookie(ACCESS_TOKEN);
}

export function handleUserIdCookies() {
    const user_id_cookies = getCookie(USER_ID);
    const user_id = localStorage.getItem(cl_user_id);
    const access_token = localStorage.getItem(cl_access_token);

    if (!user_id_cookies && user_id) {
        addUserIdCookies(user_id, access_token);
    }
}