const enabledColor = "#616161";
const disabledColor = "#BDBDBD";

const ItemOuterDiv = {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: '4px',
    padding: '4px 8px 4px 12px',
    borderRadius: '10px',
    color: enabledColor,
    cursor: 'pointer'
}

const ItemImage = {
    height: '24px',
    width: '24px',
    borderRadius: '50%'
}

const ItemText = {
    padding: '6px',
    marginTop: '4px',
    marginLeft: '8px',
    fontSize: '19px',
    fontWeight: 'bold',
    textDecoration: 'none'
}

export default {
    ItemOuterDiv,
    ItemImage,
    ItemText,
    enabledColor,
    disabledColor
}