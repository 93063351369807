import React from 'react'
import ContentLoader from 'react-content-loader';

function ListItemLoader() {
    return (
        <ContentLoader height={100} speed={2} primaryColor={'#f3f3f3'} secondaryColor={'#ecebeb'}>
            <rect x={50} y={5} height={80} rx={5} ry={5} width={80} />
            <rect x={150} y={20} height={10} rx={5} ry={5} width={200} />
            <rect x={150} y={40} height={10} rx={5} ry={5} width={200} />
            <rect x={150} y={60} height={10} rx={5} ry={5} width={200} />
        </ContentLoader>
    );
}

function RestaurantListLoader() {
    return (
        <div style={{ marginTop: '20px' }}>
            <ListItemLoader />
            <ListItemLoader />
            <ListItemLoader />
            <ListItemLoader />
            <ListItemLoader />
        </div>
    );
}

export default RestaurantListLoader;