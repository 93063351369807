import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { updateRoute } from 'actions'

import Dialog from 'material-ui/Dialog'
import FlatButton from 'material-ui/FlatButton'
import CircularProgress from 'material-ui/CircularProgress'

import CardView from '../../../society/components/card-view'
import { residentStatus, myPropertyCard } from '../../../society/config'
import { propertyDetailsRoute } from 'routes-parameters/config'

import gs_color from 'config/colors.css'

class PropertyViewInActive extends PureComponent {
    constructor(props) {
        super(props)

        this.state = {
            showRemoveDialog: false,
            removeLoader: false
        }
    }

    onPropertyDetailsClick = route => {
        this.props.updateRoute(propertyDetailsRoute, {
            propId: this.props.selectedPropId,
            societyId: this.props.societyId,
            residentType: this.props.residentType,
            showLimited: this.props.status === residentStatus.PENDING || this.props.status === residentStatus.REJECTED
        })

        this.props.history.push(route)
    }

    handleOpenEditProperty = () => window.location.hash = "editProperty"

    render() {
        const removeActions = [
            <FlatButton
                label="Cancel"
                onClick={() => this.setState({ showRemoveDialog: false })}
            />,
            <FlatButton
                label="Remove"
                labelStyle={{ color: 'red' }}
                onClick={() => this.props.callRemoveProperty()}
            />
        ]

        return (
            <div style={{ padding: '0px 5%', display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%' }}>
                <div style={{ marginTop: '12px', fontSize: '18px' }}>
                    Property Registration Status
                </div>
                <div style={{ marginTop: '4px', fontSize: '22px', fontWeight: 'bold', color: this.props.status === residentStatus.REJECTED ? 'red' : 'orange' }}>
                    {this.props.status === residentStatus.REJECTED ? "REJECTED" : "PENDING"}
                </div>

                {
                    this.props.status === residentStatus.PENDING &&
                    <>
                        <div style={{ marginTop: '12px' }}>
                            Sit back and relax. We will notify you once your registration is approved.
                        </div>
                        <div style={{ padding: '4px 20px 5%', display: 'flex', alignItems: 'center', flexDirection: 'column', width: '100%', marginTop: '16px' }}>
                            <CardView
                                {...myPropertyCard}
                                key={myPropertyCard.title}
                                onClick={() => this.onPropertyDetailsClick(myPropertyCard.route)}
                                style={{ width: '46%', margin: '8px 0px', textAlign: 'center', cursor: 'pointer', minHeight: '150px' }}
                            />
                        </div>
                    </>
                }

                {
                    this.props.status === residentStatus.REJECTED &&
                    <>
                        <div style={{ marginTop: '12px' }}>
                            Your registration was rejected for the following reason:
                        </div>
                        <div style={{ marginTop: '4px', fontWeight: 'bold' }}>
                            {this.props.rejectionComment || ""}
                        </div>
                        <div style={{ marginTop: '4px' }}>
                            Please contact your Board or OlaGate, if you think this is a mistake.
                        </div>
                        <div style={{ padding: '4px 20px 5%', display: 'flex', alignItems: 'center', flexDirection: 'column', width: '100%', marginTop: '16px' }}>
                            <FlatButton
                                label={"Edit Details and Re-Submit"}
                                labelStyle={{ color: '#fff' }}
                                style={{ backgroundColor: gs_color.primaryColor, marginBottom: '12px' }}
                                fullWidth={true}
                                onClick={this.handleOpenEditProperty}
                            />
                            {
                                this.state.removeLoader
                                    ?
                                    <div style={{ width: '100%', textAlign: 'center' }}>
                                        <CircularProgress size={26} />
                                    </div>
                                    :
                                    <FlatButton
                                        label={"Remove Property"}
                                        labelStyle={{ color: 'red' }}
                                        style={{ border: '1px solid red' }}
                                        fullWidth={true}
                                        onClick={() => this.setState({ showRemoveDialog: true })}
                                    />
                            }
                            {
                                this.state.showRemoveDialog &&
                                <Dialog
                                    title={"This action is permanent !"}
                                    actions={removeActions}
                                    open={this.state.showRemoveDialog}
                                    onRequestClose={() => this.setState({ showRemoveDialog: false })}
                                    titleClassName="dialogTitleClassName"
                                    bodyClassName="dialogBodyClassName"
                                    contentClassName="DialogNewStyle"
                                >
                                    Are you sure you want to remove this property ?
                                </Dialog>
                            }
                        </div>
                    </>
                }
            </div>
        )
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ updateRoute }, dispatch);
}

export default connect(null, mapDispatchToProps)(PropertyViewInActive)