import "@babel/polyfill";
import './polyfills/storage-polyfill';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter, Route } from 'react-router-dom';

// This ensures that the icon CSS is loaded immediately before attempting to render icons
// import '@fortawesome/fontawesome-svg-core/styles.css';
// import { config } from '@fortawesome/fontawesome-svg-core';

/* THEME */
import theme from './config/my-theme';

import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import getMuiTheme from 'material-ui/styles/getMuiTheme';

import App from './App_new';
import * as serviceWorker from './serviceWorker';
import './update-cache';
import { updateAppVersionIndexedDB, updateLastSeenIndexedDB } from './handle-indexeddb';

import store from './reducer-store';    // Get the store object created in the called file.

import { preProcessCookies } from './utils/preprocess-cookies';

// Prevent fontawesome from dynamically adding its css since we did it manually above
// config.autoAddCss = false;

preProcessCookies();

ReactDOM.render(
    <Provider store={store}>
        <BrowserRouter>
            <MuiThemeProvider muiTheme={getMuiTheme(theme)}>
                <Route component={App} />
            </MuiThemeProvider>
        </BrowserRouter>
    </Provider>,
    document.querySelector('#app')      //Assigning the whole objects to app class in html
);

window.addEventListener('load', () => {
    setTimeout(() => {
        serviceWorker.register();

        // Update app version in indexeddb
        updateAppVersionIndexedDB();
        updateLastSeenIndexedDB();


        // This ensures that the icon CSS is loaded immediately before attempting to render icons
        import(/* webpackChunkName: "fortawesomeCss" */ '@fortawesome/fontawesome-svg-core/styles.css')
            .then(() => {
                import(/* webpackChunkName: "fortawesomePackage" */ '@fortawesome/fontawesome-svg-core')
                    .then(fortawesome => {
                        const config = fortawesome.config;

                        // Prevent fontawesome from dynamically adding its css since we did it manually above
                        config.autoAddCss = false;
                    });
            });

        var iOS = !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform);
        // Add extra padding for iOS, because there is some issue
        if (iOS) {
            var appDivEle = document.getElementById('app');
            if (appDivEle) {
                appDivEle.style.paddingBottom = '1px';
            }
        }
    }, 5000);
});