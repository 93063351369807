import React from 'react';
import { APP_LINK } from 'config/config';
import OGIcon from 'assets/Logo.svg';
import gs_color from 'config/colors.css';

export default function OlaGateAd() {
    return (
        <a href={APP_LINK} style={{ textDecoration: 'none' }}>
            <div style={{ margin: '8px', padding: '8px', borderRadius: '5px', marginBottom: '16px', backgroundColor: gs_color.accentColor, color: 'white' }}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <div style={{ width: '50px', height: '50px', backgroundColor: 'white', borderRadius: '5px', display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '5px' }}>
                        <img src={OGIcon} style={{ height: '100%' }} />
                    </div>
                    <div style={{ margin: '0px 10px', fontSize: '20px', fontFamily: 'Days One' }}>
                        <div>OLA</div>
                        <div>GATE</div>
                    </div>
                    <div>
                        Register your society today & get 6 month free trail & Re 1/unit/month thereafter <span style={{ fontSize: '10px' }}>(*T&C apply)</span>
                    </div>
                </div>
            </div>
        </a>
    );
}
