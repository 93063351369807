import { cl_society_properties } from 'config/storage-variables';
import { unit_detail_types } from './scenes/select-society/scenes/register-property/config';

export function convertArrayToJson(array, id_key_name = "id") {
    if (array && array.constructor === Array) {
        let json = {};

        array.forEach(item => {
            if (item[id_key_name]) {
                json[item[id_key_name]] = item;
            }
        });

        return json;
    }
    else {
        return array;
    }
}

export function getPropDetails(propId) {
    let activeProps = localStorage.getItem(cl_society_properties);
    if (activeProps) {
        activeProps = JSON.parse(activeProps);
    }
    else {
        activeProps = [];
    }

    if (propId && activeProps.length > 0) {
        const filterProp = activeProps.filter(property => property.prop_id === propId);
        if (filterProp && filterProp.length > 0) {
            return filterProp[0];
        }
        else {
            return {};
        }
    }
    else {
        return {};
    }
}

export function parseAddlDetails(details) {
    let reqStr = "";

    if (details) {
        Object.keys(details).map(key => {
            const keyType = unit_detail_types.filter(detType => detType.type === key);
            if (keyType && keyType.length > 0) {
                const str = `${keyType && keyType.length > 0 ? keyType[0].label : key} - ${details[key]}`;

                if (reqStr === "") {
                    reqStr += str;
                } else {
                    reqStr += ", " + str;
                }
            }
        })
    }

    return reqStr;
}

export function isSocietySchemaExists(comm_schema_fields) {
    if (typeof comm_schema_fields === "string") {
        return true;
    }
    else {
        return false;
    }
}

/*
    [
        {
            "type": "tower",
            "value": "01",
            "data": [
                {
                    "type": "phase",
                    "value": "1",
                    "data": [
                        {
                            "type": "building",
                            "value": "A"
                        }
                    ]
                },
                {
                    "type": "phase",
                    "value": "2"
                }
            ]
        }
    ]
*/

var a = {
    "tower": [
        {
            "value": "01"
        }
    ],
    "phase": [
        {
            "value": "1",
            "parent_type": "tower",
            "parent_value": "01",
        },
        {
            "value": "2",
            "parent_type": "tower",
            "parent_value": "01",
        }
    ],
    "building": [
        {
            "value": "A",
            "parent_type": "phase",
            "parent_value": "1",
        }
    ],
}

export function encodePropFilter(type, value) {
    return type + '$$' + value
}

export function decodePropFilter(item) {
    return item.split('$$');
}

export function buildPropFilterRelation(data, parent = 'start') {
    // key - current filter type
    // value - parent

    let input = [];
    if (data.constructor !== Array) {
        input.push(data);
    }
    else {
        input = input.concat(data);
    }

    let obj = {};

    input.forEach(item => {
        const current_filter_type = encodePropFilter(item.type, item.value);
        obj[current_filter_type] = parent;
        if (item.data) {
            obj = { ...obj, ...buildPropFilterRelation(item.data, current_filter_type) };
        }
    });

    return obj;
}

export function buildInitialPropFilterObject(relation_obj) {
    let obj = {};

    if (relation_obj.constructor !== Object) {
        return obj;
    }

    Object.keys(relation_obj).forEach(key => {
        let item = relation_obj[key];
        if (item) {
            if (!obj[item]) {
                obj[item] = [];
            }
            const [type, value] = decodePropFilter(key);
            obj[item].push({ type, value, key });
        }
    });

    return obj;
}

export function extractBoardIdForFilter(filter_options, filter_data, common_board_id) {
    let board_id = common_board_id || null;

    if (!filter_options || filter_options.constructor !== Array || !filter_data || typeof filter_data !== "object") {
        return board_id;
    }
    let list = filter_options;
    while (true) {
        let flag = false;

        for (let i = 0; i < list.length; i++) {
            if (filter_data[list[i].type] && filter_data[list[i].type] === list[i].value) {
                if (list[i].board_id) {
                    board_id = list[i].board_id;
                }
                list = list[i].data;
                flag = true;
                break;
            }
        }
        if (!flag || !list) {
            break;
        }
    }

    return board_id;
}