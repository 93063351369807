/* Session Storage */

export const fetch_service_category = 'fetch_service_category';
export const ss_services = 'services';
export const ss_all_svc_discounts = 'all_svc_discounts';
export const ss_allservices = 'allservices';
export const ss_allservices_country_code = 'allservices_country_code';
export const search_dp_keywords = 'search_dp_keywords';
export const pushUriUpdate = 'pushUriUpdate';
export const schedule_type = 'schedule_type';
export const schedule_start_date = 'schedule_start_date';
export const schedule_end_date = 'schedule_end_date';
export const service_category_id = 'service_category_id';
export const svc_cells = 'svc_cells';
export const current_chat_id = 'current_chat_id';
export const current_review_id = 'current_review_id';
export const current_booking_id = 'current_booking_id';
export const rate_timeslots = 'rate_timeslots';
export const ss_scroll_position = 'ss_scroll_position';
export const status = 'status';
export const signin_realm = 'signin_realm';
export const no_schedule_flag = 'no_schedule_flag';
export const svc_item_units = 'svc_item_units';
export const last_fetch_sp_status = 'last_fetch_sp_status';
export const last_fetch_notification_count = 'last_fetch_notification_count';
export const city = 'city';
export const city_list = 'open_city_list';
export const ads_fetched = 'home_screen_fetched';           // Updated for smooth release
export const sp_premium_fees = 'sp_premium_fees';
export const sp_premium_base_fees = 'sp_premium_base_fees';
export const registration_redirect_url = 'registration_redirect_url';
export const ssr_book_now_click = 'ssr_book_now_click';
export const last_fetch_cl_home_rewards = 'last_fetch_cl_home_rewards';
export const cl_home_gr_cash = 'cl_home_gr_cash';
export const cl_home_gr_points = 'cl_home_gr_points';
export const last_fetch_society_notification_count = 'last_fetch_society_notification_count';
export const society_pending_gatekeeper = 'society_pending_gatekeeper';
export const society_notification_count = 'society_notification_count';
export const last_fetch_all_props_notification_count = 'last_fetch_all_props_notification_count';
export const selected_prop_notification_count = 'selected_prop_notification_count';
export const all_props_notification_count = 'all_props_notification_count';
export const all_props_notification_data = 'all_props_notification_data';

// booking 
export const bkg_service_items = 'bkg_service_items';
export const bkg_service = 'bkg_service';
export const bkg_booking_date = 'bkg_booking_date';
export const bkg_booking_time_slot = 'bkg_booking_time_slot';
export const bkg_new_agent_data = 'bkg_new_agent_data';
export const bkg_bkg_duration = 'bkg_bkg_duration';
export const bkg_sp_id = 'bkg_sp_id';
export const bkg_svc_description = 'bkg_svc_description';
export const bkg_svc_time = 'bkg_svc_time';
export const bkg_timezone = 'bkg_timezone';
export const bkg_svc_address = 'bkg_svc_address';
export const bkg_call_sp_details_api = 'bkg_call_sp_details_api';
export const bkg_call_sp_profile_api = 'bkg_call_sp_profile_api';
export const bkg_booking_type = 'bkg_booking_type';
export const bkg_quantity_info = 'bkg_quantity_info';
export const bkg_call_questionnaire_api = 'bkg_call_questionnaire_api';
export const bkg_call_service_specific_api = 'bkg_call_service_specific_api';
// booking status -> sp
export const sp_bkg_duration = 'sp_bkg_duration';
export const sp_bkg_duration_id = 'sp_bkg_duration_id';

export const notification_count = 'notification_count';
export const notification_data = 'notification_data';
export const notification_booking = 'notification_booking';
/* Local Storage */

export const service_category = 'service_category';
export const lastFetchServiceCategoryTime = 'lastFetchServiceCategoryTime';
export const services = 'services';
export const lastFetchServiceTime = 'lastFetchServiceTime';
export const pushUriId = 'pushUriId';
export const phone_number = 'phone_number';
export const email_id = 'email_id';

// export const otp_type = 'otp_type';
export const device_id = 'device_id';
export const notification_permission = 'notification_permission';
export const blockNotificationPermission = 'blockNotificationPermission';
export const login_realm = 'login_realm';

/* index.html */
// pushUriId
// device_id


//temp
export const cl_duplicate_address = 'cl_duplicate_address';
export const cl_existing_address = 'cl_existing_address';

//Client
export const cl_signedIn = 'cl_signedIn';
export const cl_access_token = 'cl_access_token';
export const cl_user_id = 'cl_user_id';
export const cl_property_address = 'cl_property_address';
export const cl_selected_address = 'cl_selected_address';
export const cl_display_name = 'cl_display_name';
export const cl_common_info_public = 'cl_common_info_public';
export const cl_client_info_public = 'cl_client_info_public';
export const cl_profile_pic = 'cl_profile_pic';
export const cl_phone_exists = 'cl_phone_exists';
export const expanded_active_category_list = 'expanded_active_category_list';
export const expanded_upcoming_category_list = 'expanded_upcoming_category_list';
export const cl_new_booking_created = 'cl_new_booking_created';         // Used for going back to home if new booking created.
export const cl_society_properties = 'cl_society_properties';
export const cl_selected_society_property = 'cl_selected_society_property'
export const cl_call_prop_api = 'cl_call_prop_api'

//SP
export const sp_signedIn = 'sp_signedIn';
export const sp_acnt_status = 'sp_acnt_status';
export const sp_user_id = 'sp_user_id';
export const sp_access_token = 'sp_access_token';
export const sp_display_name = 'sp_display_name';
export const sp_common_info_public = 'sp_common_info_public';
export const sp_sp_info_public = 'sp_sp_info_public';
export const sp_profile_pic = 'sp_profile_pic';
export const sp_store_profile_pic = 'sp_store_profile_pic';
export const sp_temp_address = 'sp_temp_address';
export const sp_profile_and_work = 'sp_profile_and_work';
export const sp_common_info = 'sp_common_info';
export const sp_common_info_date = 'sp_common_info_date';
export const sp_online_status = 'sp_online_status';
export const sp_today_working_status = 'sp_today_working_status';
export const sp_phone_exists = 'sp_phone_exists';
export const sp_create_schedule = 'sp_create_schedule';
export const sp_svc_place = 'sp_svc_place';
export const sp_acnt_type = 'sp_acnt_type';
export const sp_public_url = 'sp_public_url';
export const sp_online_last_checked = 'sp_online_last_checked';
export const payment_failed_obj = 'payment_failed_obj';
export const remove_premium_banner = 'remove_premium_banner';
export const is_payment_available_for_sp = 'is_payment_available_for_sp';
export const is_payment_screen_open = 'is_payment_screen_open';
export const payment_called_from_booking = 'payment_called_from_booking';

export const phone_num = 'phone_num';
export const user_realm = 'user_realm';
export const profile_lang = 'profile_lang';
export const get_profile_lang = 'get_profile_lang';
export const ref_code = 'ref_code';
export const disable_window_timeout = 'disable_window_timeout';
export const product_type = "product_type";
export const is_broadcast_applicable = "is_broadcast_applicable";
export const is_discount_applicable = "is_discount_applicable";
export const profile_timezone = "profile_timezone";
export const cl_society_details = "cl_society_details";
export const soc_code = 'soc_code';
export const share_soc_banner = 'share_soc_banner';
export const cl_society_staff_types = "cl_society_staff_types";
export const comm_rzp_payment_failed_obj = 'comm_rzp_payment_failed_obj';

export const product_category = 'product_category';
export const fetch_product_category = 'fetch_product_category';

export const product_categories = 'product_categories';
export const product_sub_categories = 'product_sub_categories';

export const product_cart_cache = "product_cart_cache";
export const product_cart_updated = "product_cart_updated";
export const product_clear_data = "product_clear_data";
export const product_rzp_payment_failed_obj = 'product_rzp_payment_failed_obj';
export const product_paypal_payment_failed_obj = 'product_paypal_payment_failed_obj';

export const country_selected = 'country_selected';
export const display_features = 'display_features';

export const product_conversion_values_kg = 'product_conversion_values_kg';
export const product_conversion_values_lb = 'product_conversion_values_lb';

export const lastFetchEmailTime = 'lastFetchEmailTime';
export const checkEmailExistance = 'checkEmailExistance';
export const cl_email_exists = 'cl_email_exists';

export const is_ogcart = 'is_ogcart';
export const is_ogcare = 'is_ogcare';
export const intermediate_explore_route = 'intermediate_explore_route';
export const is_home_screen_flow = 'is_home_screen_flow';
export const cl_validate_email = 'cl_validate_email';
export const cl_new_ogfood_order_created = 'cl_new_ogfood_order_created';         // Used for going back to explore if new order place.
export const emergency_contacts_zipcode = 'emergency_contacts_zipcode';

export const cl_jsk_enabled = 'cl_jsk_enabled';
