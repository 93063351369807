import React from 'react'

import './styles.css'

function SectionHeader({ title, onActionClick, titleColor }) {
    return (
        <div className="header-outer">
            <div className="header-title" style={{ color: titleColor }}>
                {title || ""}
            </div>
            {
                onActionClick &&
                <div className="header-action" onClick={() => onActionClick()}>
                    View All
                </div>
            }
        </div>
    )
}

export default SectionHeader