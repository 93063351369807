import React, { PureComponent } from 'react'
import DownArrow from 'material-ui/svg-icons/hardware/keyboard-arrow-down';
import gs_color from 'config/colors.css';

const styles = {
    Switcher: {
        border: `1px solid #E0E0E0`,
        borderRadius: '5px',
        background: `linear-gradient(90deg, ${gs_color.gradientLightColor}, ${gs_color.gradientDarkColor})`,
        padding: '8px',
        color: gs_color.textColor,
        display: 'flex',
        flex: 1,
        alignItems: 'center',
        cursor: 'pointer',
        minWidth: 0,
    }
}

class SelectLocation extends PureComponent {
    render() {
        return (
            <div style={this.props.style || {}}>
                <div id="property-switcher" style={styles.Switcher} onClick={() => this.props.onClick()}>
                    <div style={{ flex: 1, display: 'flex', alignItems: 'center', minWidth: 0 }}>
                        <div style={{ fontSize: '15px', fontWeight: 'bold', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', flex: 1 }}>
                            {this.props.address ? parseString(this.props.address) : "Select Location"}
                        </div>
                        <div style={{ display: 'flex', marginTop: '2px', marginLeft: 'auto' }}>
                            <DownArrow size={30} color={gs_color.backgroundDarkColor} />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const parseString = (text) => {
    return text;
    if (text.length < 20) {
        return text;
    } else {
        return `${text.slice(0, 20)}...`
    }
}

export default SelectLocation