import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';

import CircularProgress from 'material-ui/CircularProgress';
import RaisedButton from 'material-ui/RaisedButton';
import Paper from 'material-ui/Paper';
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux';

import MyAppBar from 'components/app-bar';
import BottomNav from 'components/bottom-navigation';

import { updateMiscellaneousComponents } from 'actions';
import { cl_signedIn, signin_realm, registration_redirect_url } from 'config/storage-variables';
import { selectSocietyRoute, signinRoute, signupClientRoute, exploreRoute } from 'routes-parameters/config';
import { getEnvHomeRoute } from 'utils/helper-functions';
import featureData, { topFeaturesData } from './config';
import CompanyLogo from 'assets/LOGO192.png';
import BuildingImage from './assets/bottom-img.svg';
import BottomCloudImage from './assets/bottom-cloud.svg';
import Cloud1Image from './assets/cloud-1.svg';
import Cloud2Image from './assets/cloud-2.svg';
import Cloud3Image from './assets/cloud-3.svg';
import Cloud4Image from './assets/cloud-4.svg';
import Cloud5Image from './assets/cloud-5.svg';
import Cloud6Image from './assets/cloud-6.svg';
import gs_color from 'config/colors.css';
import './styles.css';

const selectedIndex = 2;

const styles = {
    buttonStyleClient: {
        padding: '4px 8px',
        borderRadius: '5px',
        color: '#fff',
        background: gs_color.primaryColor,
        marginTop: "10px",
        marginBottom: "10px",
        textAlign: "center",
        cursor: 'pointer'
    }
}

class NoSociety extends PureComponent {
    constructor(props) {
        super(props)

    }

    signinCl(props) {
        const envHomeRoute = getEnvHomeRoute();
        sessionStorage.setItem(signin_realm, 'svc_consumer');
        sessionStorage.setItem(registration_redirect_url, envHomeRoute);
    }

    signupCl(props) {
        const envHomeRoute = getEnvHomeRoute();
        sessionStorage.setItem(signin_realm, 'svc_consumer');
        sessionStorage.setItem(registration_redirect_url, envHomeRoute);
    }

    getActionButton = () => {
        if (localStorage.getItem(cl_signedIn) === 'true') {
            return (
                <div style={{ display: "flex", margin: '24px 5%', flexWrap: 'wrap' }}>
                    <div style={{ width: "75%", margin: '0px auto' }}>
                        { <div style={{ fontSize: '12px', marginBottom: '5px', textAlign: 'center' }}>Non Apartment Resident ?</div> }
                        <RaisedButton
                            className="buttonShake"
                            label="Connect To Your Neighborhood"
                            backgroundColor={gs_color.accentColor}
                            labelStyle={{ color: gs_color.gradientLightColor, textTransform: 'capitalize', fontSize: '16px', paddingLeft: '8px', paddingRight: '8px', paddingTop: '8px', paddingBottom: '8px' }}
                            onClick={() => this.props.history.push(`/${exploreRoute}`)}
                            style={{ borderRadius: '5px' }}
                            buttonStyle={{ borderRadius: '5px' }}
                            fullWidth
                        />
                    </div>
                    <div style={{ width: "50%", margin: '0px auto', marginTop: '30px' }}>
                        <div style={{ fontSize: '12px', marginBottom: '5px', textAlign: 'center' }}>Apartment Resident ?</div>
                        <RaisedButton
                            className="buttonShake"
                            label="Register Property"
                            backgroundColor={gs_color.accentColor}
                            labelStyle={{ color: gs_color.gradientLightColor, textTransform: 'capitalize', fontSize: '14px', paddingLeft: '8px', paddingRight: '8px' }}
                            onClick={() => this.props.history.push(`/${selectSocietyRoute}`)}
                            style={{ borderRadius: '5px' }}
                            buttonStyle={{ borderRadius: '5px' }}
                            fullWidth
                        />
                    </div>
                </div>
            )

        } else {
            return (
                <div style={{ display: "flex", margin: '24px 5%' }}>
                    <div style={{ width: "40%", margin: '0px auto' }}>
                        <Link style={{ textDecoration: "none" }} to={`/${signinRoute}`}>
                            <div className="buttonShake" style={Object.assign({}, styles.buttonStyleClient, { backgroundColor: gs_color.accentColor, color: gs_color.gradientLightColor })} onClick={() => this.signinCl()}>
                                <div style={{ fontSize: '10px' }}>Already member?</div>
                                <div>SIGN IN</div>
                            </div>
                        </Link>
                    </div>
                    <div style={{ width: "40%", margin: '0px auto' }}>
                        <Link style={{ textDecoration: "none" }} to={`/${signupClientRoute}`}>
                            <div className="buttonShake" style={Object.assign({}, styles.buttonStyleClient, { backgroundColor: gs_color.gradientLightColor, color: gs_color.accentColor })} onClick={() => this.signupCl()}>
                                <div style={{ fontSize: '10px' }}>New member?</div>
                                <div>SIGN UP</div>
                            </div>
                        </Link>
                    </div>
                </div>
            )
        }
    }

    getFeatureItem = feature => {
        const w3 = feature.weight === 3;
        const w2 = feature.weight === 2;

        const onClick = (index) => {
            if ( index != -1 ) {
                this.props.updateMiscellaneousComponents({ explore_screen_tab: index })
                this.props.history.push(`/${exploreRoute}`);
            }
        }

        if(w2) {
            return (
                <a href={feature.redirect_url} target="_blank" rel="noopener noreferrer" style={{ display: 'block', alighItem: 'flex-start', width: '50%', textAlign: 'center', textDecoration: "none" }} key={feature.title} onClick={() => onClick(feature.tabIdx)}>
                    <div style={{ padding: '8px', marginTop: '5px',fontSize: '12px', color: gs_color.accentColor, textAlign: 'center' }}>
                        {feature.intro}                                
                    </div>
                    <div style={{ cursor: 'pointer'}}>                            
                        <img src={feature.svgIcon} style={{ width: '50%', marginTop: '5px' }} alt="" />
                    </div>
                    <div className="no_society_feature_card_title no_society_feature_card_title_w4" style={{ cursor: 'pointer', marginTop: '5px', color: gs_color.accentColor, textAlign: 'center' }}>
                        {feature.title}
                    </div>
                </a>
            )
        }

        return (
            <div className={`no_society_feature_card ${w3 ? '' : 'no_society_feature_card__w4'}`} key={feature.title} onClick={() => onClick(feature.tabIdx)}>
                {
                    w3 ?
                        <div style={{ display: 'block', flexDirection: 'row', cursor: 'pointer', textAlign: 'center' }}>
                            <img src={feature.svgIcon} style={{ width: '80%' }} alt="" />
                        </div>
                        :                          
                        <Paper style={{ cursor: 'pointer', backgroundColor: gs_color.gradientLightColor, borderRadius: '5px' }} zDepth={1}>
                            <div style={{ padding: '8px', textAlign: 'center' }}>
                                <img src={feature.svgIcon} style={{ width: '100%' }} alt="" />
                            </div>
                        </Paper>                                      
                        /*<div>
                            <div style={{ width: '100%', padding: '8px', marginTop: '5px',fontSize: '14px', color: gs_color.accentColor, textAlign: 'center'}}>
                                {feature.intro}                                
                            </div
                            <div style={{  cursor: 'pointer'}}>                            
                                <img src={feature.svgIcon} style={{ width: '100%' }} alt="" />
                            </div>
                        </div>
                        */
                }
                <div className="no_society_feature_card_title no_society_feature_card_title_w4" style={{ cursor: 'pointer', marginTop: '5px', color: gs_color.accentColor, textAlign: 'center' }}>
                    {feature.title}
                </div>
            </div>
        )
            {/*
            <div className={`no_society_feature_card ${w3 ? '' : 'no_society_feature_card__w4'}`} key={feature.title} onClick={() => onClick(feature.tabIdx)}>
                {
                    w3 ?
                        <div style={{ cursor: 'pointer', textAlign: 'center' }}>
                            <img src={feature.svgIcon} style={{ width: '80%' }} alt="" />
                        </div>
                        :
                        <Paper style={{ cursor: 'pointer', backgroundColor: gs_color.gradientLightColor, borderRadius: '5px' }} zDepth={1}>
                            <div style={{ padding: '8px', textAlign: 'center' }}>
                                <img src={feature.svgIcon} style={{ width: '100%' }} alt="" />
                            </div>
                        </Paper>
                }
                <div className="no_society_feature_card_title no_society_feature_card_title_w4" style={{ cursor: 'pointer', marginTop: '5px', color: gs_color.accentColor, textAlign: 'center' }}>
                    {feature.title}
                </div>
            </div>
            */}
        
    }

    render() {
        if (this.redirection) {
            return (
                <div className="nonAppBar">
                    <MyAppBar
                        className="myAppBar"
                        elementLeft="none"
                        title="Society"
                    />
                    <div style={{ marginTop: '20px', textAlign: 'center' }}>
                        <CircularProgress
                            size={30}
                        />
                    </div>
                </div>
            );
        }
        else {
            return (
                <div>
                    <div className="nonBottomNavigation noSocietyContainerBackground">
                        <div>
                            <div style={{ display: 'flex', padding: '16px 24px', justifyContent: 'center', alignItems: 'center' }}>
                                <div>
                                    <img src={CompanyLogo} className="no_society_app_logo" alt="OlaGate" />
                                </div>
                                <div className="no_society_app_name">
                                    <div>OLA</div>
                                    <div style={{ marginTop: '-8px' }}>GATE</div>
                                </div>
                            </div>
                            <div style={{ width: '100%', textAlign: 'center' }} className="no_society_tagline">
                                Your Digital Neighborhood Platform
                            </div>
                            <div style={{ width: '100%', textAlign: 'center' }} className="no_society_tagline_2">
                                Happy Neighborhood. Safe Neighborhood. 
                            </div>
                            {
                                this.getActionButton()
                            }
                            <div style={{ width: '100%', textAlign: 'center' }} className="no_society_tagline">
                                Are you a business owner or service provider?
                            </div>
                            <div style={{ padding: '20px 5%', paddingTop: '8px' }}>
                                <div style={{ display: 'flex', justifyContent: 'space-evenly', flexWrap: 'wrap', alignItems: 'flex-start', marginBottom: 16 }}>
                                    {
                                        topFeaturesData.map(feature => this.getFeatureItem(feature))
                                    }
                                </div>                                
                                <div style={{ alignItems:'flex-start', display: 'flex', justifyContent: 'space-evenly', flexWrap: 'wrap' }}>
                                    {
                                        featureData.map(feature => this.getFeatureItem(feature))
                                    }
                                </div>
                            </div>
                            <div style={{ display: 'flex', width: '100%' }}>
                                <img src={BuildingImage} style={{ width: '100%' }} alt="" />
                            </div>
                        </div>
                        <div style={{ marginTop: '-100%', zIndex: -1, position: 'relative' }}>
                            <img src={BottomCloudImage} style={{ width: '100%' }} alt="" />
                        </div>
                        <div style={{ top: '5%', left: '5%', zIndex: -1, position: 'absolute' }}>
                            <img src={Cloud1Image} style={{ width: '100%' }} alt="" />
                        </div>
                        <div style={{ top: '17%', right: '5%', zIndex: -1, position: 'absolute', width: '144px' }}>
                            <img src={Cloud2Image} style={{ width: '100%' }} alt="" />
                        </div>
                        <div style={{ top: '33%', left: '5%', zIndex: -1, position: 'absolute', width: '162px' }}>
                            <img src={Cloud3Image} style={{ width: '100%' }} alt="" />
                        </div>
                        <div style={{ top: '51%', right: '5%', zIndex: -1, position: 'absolute', width: '108px' }}>
                            <img src={Cloud4Image} style={{ width: '100%' }} alt="" />
                        </div>
                        <div style={{ top: '67%', left: '5%', zIndex: -1, position: 'absolute', width: '90px' }}>
                            <img src={Cloud5Image} style={{ width: '100%' }} alt="" />
                        </div>
                        <div style={{ top: '67%', right: '5%', zIndex: -1, position: 'absolute', width: '153px' }}>
                            <img src={Cloud6Image} style={{ width: '100%' }} alt="" />
                        </div>
                    </div>
                    <BottomNav
                        selectedIndex={selectedIndex}
                        history={this.props.history}
                    />
                </div>
            );
        }
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        updateMiscellaneousComponents,
    }, dispatch);
}

export default connect(null, mapDispatchToProps)(NoSociety);