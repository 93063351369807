import { REHYDRATE } from 'redux-persist';
import findIndex from 'lodash/findIndex';
import {
    USER_ADDRESSES, USER_ADDRESSES_SUCCESS, USER_ADDRESSES_ERROR,
    USER_ADDRESSES_SELECT, LOGOUT_RESET, LOGIN_TRIGGER_ACTION,
    ADD_NEW_USER_ADDRESS, EDIT_USER_ADDRESS, REMOVE_USER_ADDRESS,
} from '../../actions/types';
import ApiError, { handleNetworkError } from '../../utils/api-error';
import { setSelectedAddress } from '../../utils/handle-address-duplication';
import { cl_property_address, cl_selected_address } from 'config/storage-variables';
import { getAddressPropertyList } from '../../scenes/client/scenes/products/config'

const INITIAL_STATE = {
    address_list: [],
    selected_address: localStorage.getItem(cl_selected_address) ? JSON.parse(localStorage.getItem(cl_selected_address)) : undefined,
    error: undefined,
    loading: true,
    is_logout: false
}

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case REHYDRATE: case REHYDRATE: if (action.payload && action.payload.user_addresses) return { ...action.payload.user_addresses }; else return INITIAL_STATE;

        case USER_ADDRESSES: return { ...INITIAL_STATE, loading: true, is_logout: false, selected_address: state.selected_address };

        case USER_ADDRESSES_SUCCESS:
            const apiData = action.payload && action.payload.apiData;

            if (apiData !== undefined && apiData !== null) {
                const property_list = ApiError(apiData.statusCode, apiData.message, apiData.gsRespData, "prop_list", []);
                if (property_list && property_list.constructor === Array) {
                    let prop_list = property_list.filter(item => item.note !== "DUPLICATE:EXISTING");
                    prop_list = prop_list.map(prop_list_item => ({
                        g_address: prop_list_item.prop_address && prop_list_item.prop_address.g_address ? prop_list_item.prop_address.g_address : undefined,
                        svc_prop_id: prop_list_item.svc_prop_id ? prop_list_item.svc_prop_id : prop_list_item.prop_address ? prop_list_item.prop_address.svc_prop_id : undefined,
                        svc_cell_id: prop_list_item.svc_cell_id ? prop_list_item.svc_cell_id : undefined,
                        gcs_buckets: prop_list_item.gcs_buckets ? prop_list_item.gcs_buckets : undefined,
                        society_info: prop_list_item.society_info ? prop_list_item.society_info : undefined,
                        jsk_enabled: prop_list_item.jsk_enabled ? prop_list_item.jsk_enabled : false,
                        city_id: prop_list_item.city_id ? prop_list_item.city_id : false,
                        og_delivery: prop_list_item.og_delivery ? prop_list_item.og_delivery : false,
                    }));

                    let selected_address = state.selected_address;
                    if (prop_list.length === 0) {
                        selected_address = undefined;
                    }
                    else if (selected_address) {
                        selected_address = setSelectedAddress(prop_list, selected_address, true);
                    }
                    if (!selected_address && prop_list.length > 0) {
                        selected_address = prop_list[0];
                    }

                    localStorage.setItem(cl_property_address, JSON.stringify(prop_list));
                    if (selected_address) {
                        localStorage.setItem(cl_selected_address, JSON.stringify(selected_address));
                    }
                    else {
                        localStorage.removeItem(cl_selected_address);
                    }

                    return { ...state, loading: false, is_logout: false, address_list: prop_list, selected_address }

                } else {
                    localStorage.setItem(cl_property_address, JSON.stringify(property_list));
                    localStorage.removeItem(cl_selected_address);
                    return { ...state, loading: false, is_logout: false, error: property_list, selected_address: undefined };
                }

            } else {
                return { ...state, loading: false, is_logout: false };
            }

        case USER_ADDRESSES_ERROR:
            let hNE_res = handleNetworkError(action);
            if (hNE_res.error)
                return { ...INITIAL_STATE, ...hNE_res, loading: false, is_logout: false, selected_address: state.selected_address };
            else if (action.payload && action.payload.response && action.payload.response.data && action.payload.response.data.error) {
                if (action.payload.response.data.error.statusCode === 401)
                    return { ...INITIAL_STATE, error: action.payload.response.data.error, loading: false, is_logout: true, selected_address: state.selected_address };
                else
                    return { ...INITIAL_STATE, error: action.payload.response.data.error, loading: false, is_logout: false, selected_address: state.selected_address };
            }
            else
                return { ...INITIAL_STATE, error: action.payload, loading: false, is_logout: false, selected_address: state.selected_address };

        case USER_ADDRESSES_SELECT:
            if (action.payload) {
                localStorage.setItem(cl_selected_address, JSON.stringify(action.payload));
                let selectedPropId = action.payload.selectedPropId;
                if (!selectedPropId) {
                    const prop_list = getAddressPropertyList(action.payload);
                    if (prop_list && prop_list.length === 1) {
                        selectedPropId = prop_list[0].prop_id;
                    }
                }
                return { ...state, selected_address: { ...action.payload, selectedPropId } }
            } else {
                return state;
            }

        case ADD_NEW_USER_ADDRESS:
            if (action.payload) {
                const address = action.payload;

                // If address already in address list then do not add it.
                // Check existence based on place_id.

                const is_already_exist = state.address_list.some(item => (item.g_address && address.g_address && item.g_address.place_id === address.g_address.place_id));
                if (is_already_exist) {
                    return state;
                }

                let address_list = [].concat(state.address_list);
                address_list.push(address);
                let selected_address = state.selected_address;
                if (!selected_address) {
                    selected_address = address_list[0];
                }

                return { ...state, address_list, selected_address }
            } else {
                return state;
            }

        case EDIT_USER_ADDRESS:
            if (action.payload) {
                const address = action.payload;

                let addresses = [].concat(state.address_list);;
                // Find item index using _.findIndex (thanks @AJ Richardson for comment)
                var index = findIndex(addresses, { svc_prop_id: address.svc_prop_id });

                // Replace item at index using native splice
                addresses.splice(index, 1, address);

                localStorage.setItem(cl_property_address, JSON.stringify(addresses));

                let selected_address = state.selected_address;
                if (selected_address && address.svc_prop_id === selected_address.svc_prop_id) {
                    selected_address = { ...address };
                    localStorage.setItem(cl_selected_address, JSON.stringify(selected_address));
                }

                return { ...state, selected_address: selected_address, address_list: addresses }
            } else {
                return state;
            }

        case REMOVE_USER_ADDRESS:
            if (action.payload) {
                const address = action.payload;
                const remove_address = state.address_list.filter(addr => addr.svc_prop_id !== address.svc_prop_id);

                let selected_address = state.selected_address;
                if (remove_address.length === 0) {
                    selected_address = undefined;
                }
                else if (selected_address) {
                    selected_address = setSelectedAddress(remove_address, selected_address, true);
                }
                if (!selected_address && remove_address.length > 0) {
                    selected_address = remove_address[0];
                }

                localStorage.setItem(cl_property_address, JSON.stringify(remove_address));
                if (selected_address) {
                    localStorage.setItem(cl_selected_address, JSON.stringify(selected_address));
                }
                else {
                    localStorage.removeItem(cl_selected_address);
                }

                return { ...state, address_list: remove_address, selected_address }
            } else {
                return state;
            }

        case LOGIN_TRIGGER_ACTION:
            return { ...state, loading: true };

        case LOGOUT_RESET:
            return { ...state, loading: false };

        default: return state;
    }
}