import React from 'react';
import { Link } from 'react-router-dom';

import styles from './styles';

const disbaledStyle = {
    color: styles.disabledColor,
    cursor: 'default'
}

class SideNavItemMinor extends React.PureComponent {
    render() {
        const enabled = this.props.enabled;
        const outerStyle =
            enabled
                ? styles.SideNavItemMinorOuterDiv
                : Object.assign({}, styles.SideNavItemMinorOuterDiv, disbaledStyle)

        return (
            <Link
                action="replace"
                to={`/${this.props.route}`}
                style={{ textDecoration: 'none' }}
                onClick={(e) => enabled ? {} : e.preventDefault()}>
                <div style={outerStyle}>
                    {this.props.title}
                </div>
            </Link>
        )
    }
}

export default SideNavItemMinor;