import { REHYDRATE } from 'redux-persist';
import {
    CLIENT_ADDRESSES, CLIENT_ADDRESSES_SUCCESS, CLIENT_ADDRESSES_ERROR,
    CLIENT_ADDRESSES_SHIPPING_SELECT,
    CLIENT_ADDRESSES_CLEAR_CACHE
} from '../../actions/types';
import { getSelectedAddress, getCommunityIdFromAddress } from '../../scenes/client/scenes/products/config';
import ApiError, { handleNetworkError } from '../../utils/api-error';

const INITIAL_STATE = {
    address_list: [],
    selected_address: null,
    community_id: null,
    cache: null,
    error: undefined,
    loading: false,
    is_logout: false
}

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case REHYDRATE: case REHYDRATE: if (action.payload && action.payload.client_addresses) return { ...action.payload.client_addresses }; else return INITIAL_STATE;

        case CLIENT_ADDRESSES: return { ...INITIAL_STATE, loading: true, is_logout: false };

        case CLIENT_ADDRESSES_SUCCESS:
            const apiData = action.payload && action.payload.apiData;
            const cache = action.payload && action.payload.cache;
            const cart_address_data = action.payload && action.payload.cart_address_data;

            if (apiData !== undefined && apiData !== null) {
                const property_list = ApiError(apiData.statusCode, apiData.message, apiData.gsRespData, "prop_list", []);
                if (property_list && property_list.constructor === Array) {
                    const selected_address = getSelectedAddress(property_list, apiData.gsRespData.last_shipped_prop_id, cart_address_data);
                    const community_id = selected_address ? getCommunityIdFromAddress(selected_address) : null;
                    return { ...state, loading: false, is_logout: false, address_list: property_list, selected_address, community_id, cache }

                } else {
                    return { ...state, loading: false, is_logout: false, error: property_list };
                }

            } else {
                return { ...state, loading: false, is_logout: false };
            }


        case CLIENT_ADDRESSES_ERROR:
            let hNE_res = handleNetworkError(action);
            if (hNE_res.error)
                return { ...INITIAL_STATE, ...hNE_res, loading: false, is_logout: false };
            else if (action.payload && action.payload.response && action.payload.response.data && action.payload.response.data.error) {
                if (action.payload.response.data.error.statusCode === 401)
                    return { ...INITIAL_STATE, error: action.payload.response.data.error, loading: false, is_logout: true };
                else
                    return { ...INITIAL_STATE, error: action.payload.response.data.error, loading: false, is_logout: false };
            }
            else
                return { ...INITIAL_STATE, error: action.payload, loading: false, is_logout: false };

        case CLIENT_ADDRESSES_SHIPPING_SELECT:
            if (action.payload) {
                const community_id = getCommunityIdFromAddress(action.payload);
                return { ...state, selected_address: action.payload, community_id }
            } else {
                return state;
            }

        case CLIENT_ADDRESSES_CLEAR_CACHE:
            return { ...INITIAL_STATE };

        default: return state;
    }
}