import { COMM_PGWT_FEES, COUNTRY_CODE } from 'config/config';
import { faMoneyBill, faCreditCard, faLaptopCode } from '@fortawesome/free-solid-svg-icons';
import UpiLogo from '../assets/upi-logo.png';
import PayPalLogo from '../assets/paypal.svg';
import { country_selected } from 'config/storage-variables';

export const MODE_PAYMENT_GATEWAY = 'PGWT';
export const paymentOptions = (countryCode = undefined) => {
    let code = countryCode;
    if (!code) {
        if (localStorage.getItem(country_selected)) {
            code = localStorage.getItem(country_selected).toLowerCase();

        } else {
            code = "in";
        }
    }

    if (code === COUNTRY_CODE.USA) {
        return ([
            {
                key: 'PayPal',
                mode: MODE_PAYMENT_GATEWAY,
                assetIcon: PayPalLogo,
                primaryText: 'PayPal',
                showRemark: true,
                txn_fees_pct: COMM_PGWT_FEES,
                buttonLabel: 'Place Order',
                enableKey: "isPgwtEnabled",
                hashRedirect: 'confirm-order-paypal'
            }
        ])
    }


    return ([
        {
            key: 'UPI',
            mode: MODE_PAYMENT_GATEWAY,
            assetIcon: UpiLogo,
            primaryText: 'UPI',
            showRemark: true,
            txn_fees_pct: COMM_PGWT_FEES,
            buttonLabel: 'Place Order',
            enableKey: "isPgwtEnabled",
            hashRedirect: 'confirm-order'
        },       
        {
            key: 'NETBNK',
            mode: MODE_PAYMENT_GATEWAY,
            faIcon: faLaptopCode,
            primaryText: 'Internet Banking',
            showRemark: true,
            txn_fees_pct: COMM_PGWT_FEES,
            buttonLabel: 'Place Order',
            enableKey: "isPgwtEnabled",
            hashRedirect: 'confirm-order'
        },
        {
            key: 'CC-DB',
            mode: MODE_PAYMENT_GATEWAY,
            faIcon: faCreditCard,
            primaryText: 'Credit/Debit Card',
            showRemark: true,
            txn_fees_pct: COMM_PGWT_FEES,
            buttonLabel: 'Place Order',
            enableKey: "isPgwtEnabled",
            hashRedirect: 'confirm-order'
        },       
        {
            key: 'Cash',
            mode: 'Cash',
            faIcon: faMoneyBill,
            primaryText: 'Cash On Delivery',
            buttonLabel: 'Place Order',
            enableKey: "isCODEnabled",
            hashRedirect: 'confirm-order'
        }
    ])
}