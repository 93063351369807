import { REHYDRATE } from 'redux-persist';

import {
    GET_OGFOOD_ORDER_DETAILS,
    GET_OGFOOD_ORDER_DETAILS_SUCCESS,
    GET_OGFOOD_ORDER_DETAILS_ERROR,
    CLEAR_OGFOOD_ORDER_DETAILS_CACHE,
} from '../../../actions/types';
import ApiError, { parseError } from '../../../utils/api-error';

const INITIAL_STATE = {
    loading: true,
    error: undefined,
    order_details: undefined,
    cache: undefined,
};

const OGFoodOrderDetails = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case REHYDRATE:
            return action.payload && action.payload.food_order_details
                ? { ...action.payload.food_order_details }
                : INITIAL_STATE;

        case GET_OGFOOD_ORDER_DETAILS:
            return { ...state, loading: true };

        case GET_OGFOOD_ORDER_DETAILS_SUCCESS:
            const cache = action.cache;

            const apiData = action.payload;
            const payload = ApiError(apiData.statusCode, apiData.message, apiData.gsRespData, null, {});

            if (payload.error) {
                return {
                    ...state,
                    error: payload.error,
                    loading: false
                }
            }

            let order_detail = undefined;
            if (payload && payload.order_detail) {
                order_detail = payload.order_detail;
            }

            let newState = {
                loading: false,
                error: undefined,
                order_details: order_detail || undefined,
                cache: cache
            };

            return newState;

        case GET_OGFOOD_ORDER_DETAILS_ERROR:
            return {
                ...state,
                ...parseError(action)
            };

        case CLEAR_OGFOOD_ORDER_DETAILS_CACHE:
            return {
                ...state,
                cache: undefined,
            };


        default:
            return state;
    }
};

export default OGFoodOrderDetails;
