import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { selectUserAddress, updateRoute } from 'actions';
import { Code } from 'react-content-loader';

import MyAppBar from 'components/app-bar';
import CrossButton from 'components/cross-button';
import ShowMap from 'components/show-map';
import AddressItem from './address-item';

import Paper from 'material-ui/Paper';
import RaisedButton from 'material-ui/RaisedButton';
import AddIcon from 'material-ui/svg-icons/content/add';


import { fixedMarkerRoute } from 'routes-parameters/config';
import { isNullOrEmptyObject } from 'utils/helper-functions';
import gs_color from 'config/colors.css';
import './styles.css';
import { getAddressId, getGAddress, checkPropertyForAddress, getAddressPropertyList } from '../../../client/scenes/products/config';

const defaultHashState = {
    showMap: false
}

const Hashes = {
    default: 'select-address',
    showMap: 'select-address--show-map'
}

class LocationAddress extends PureComponent {
    constructor(props) {
        super(props)

        this.state = {
            selectedId: null,
            selectedPropId: null,
            showMapCenter: null,
            showMapLandmark: null,
            ...defaultHashState
        }
    }

    componentDidMount() {
        const selected_address = this.props.user_selected_address;

        this.updateSelectedId(selected_address);

        window.scrollTo(0, 0);

        window.addEventListener('hashchange', this.handleHashChange, false);
    }

    getLoader = () => {
        return (
            <div style={{ paddingLeft: '30px' }}>
                <div style={{ marginTop: '100px' }}><Code /></div>
                <div style={{ marginTop: '50px' }}><Code /></div>
                <div style={{ marginTop: '50px' }}><Code /></div>
            </div>
        );
    }


    renderAddressItems = (address_list, selected_address) => {
        if (address_list && address_list.constructor === Array && address_list.length > 0) {
            let selectedId = null;
            if (!isNullOrEmptyObject(selected_address)) {
                selectedId = getAddressId(selected_address);
            }

            return address_list
                .filter(address => getAddressId(address) !== selectedId)
                .map(address =>
                    <AddressItem
                        key={getAddressId(address)}
                        isSelected={this.state.selectedId === getAddressId(address)}
                        selectedPropId={this.state.selectedPropId}
                        address={address}
                        onClick={this.setSelectedId}
                        onShowMap={this.onShowMap}
                    />
                )
        }
    }

    updateSelectedId = (selected_address) => {
        if (!this.state.selectedId && !isNullOrEmptyObject(selected_address)) {
            const selectedId = this.state.selectedId;
            const selectedPropId = this.state.selectedPropId;
            const id = getAddressId(selected_address);
            const prop_id = selected_address && selected_address.selectedPropId ? selected_address.selectedPropId : undefined;
            if (id !== selectedId || prop_id !== selectedPropId) {
                this.setState({ selectedId: id, selectedPropId: prop_id })
            }
            else if (selectedPropId && !checkPropertyForAddress(selected_address, selectedPropId)) {
                this.setState({ selectedPropId: undefined })
            }
        }
    }

    setSelectedId = (id, prop_id) => this.setState({ selectedId: id, selectedPropId: prop_id })

    handleHashChange = () => {
        if (window.location.hash === `#${Hashes.default}`) {
            this.setState({ ...defaultHashState });

        } else if (window.location.hash === `#${Hashes.showMap}`) {
            this.setState({ ...defaultHashState, showMap: true });
        }
    }

    onCurrentLocationClick = () => {
        this.props.updateRoute(fixedMarkerRoute, {
            redirect: "product_flow"
        });
        this.props.history.push(`${fixedMarkerRoute}/svc_consumer`);
    }

    onShowMap = address => {
        const gAddress = getGAddress(address);
        if (gAddress) {
            let landmark = gAddress.address && gAddress.address.landmark ? gAddress.address.landmark : "";
            this.setState({ showMapCenter: gAddress.geo_location, showMapLandmark: landmark });

            window.location.hash = `#${Hashes.showMap}`
        }
    }

    closeHash = () => window.location.hash = `#${Hashes.default}`

    onSaveClick = (address_list) => {
        const id = this.state.selectedId;
        const selectedPropId = this.state.selectedPropId;
        if (id) {
            const filter = address_list.filter(address => getAddressId(address) === id);
            if (filter && filter.length > 0) {
                this.props.selectUserAddress({ ...filter[0], selectedPropId: selectedPropId || undefined });
            }

            this.props.onSaveClick();
        }
    }

    render() {
        if (this.props.user_addresses_loading) {
            return (
                <div className="nonAppBar">
                    <MyAppBar
                        title={`Select Address`}
                        className="myAppBar"
                        elementLeft={<CrossButton onClick={() => this.props.history.goBack()} />}
                    />
                    {this.getLoader()}
                </div>
            )
        }

        if (this.state.showMap) {
            return (
                <div>
                    <MyAppBar
                        className="myAppBar"
                        title={'Property Address Location'}
                        elementLeft={<CrossButton onClick={() => this.props.history.goBack()} />}
                    />
                    <div className="nonAppBar">
                        <div className="mapDiv">
                            <ShowMap center={this.state.showMapCenter} landmark={this.state.showMapLandmark} />
                        </div>
                    </div>
                </div>
            );
        }

        const selected_address = this.props.user_selected_address;
        const address_list = this.props.user_address_list || [];

        const address = this.state.selectedId ? address_list.filter(item => getAddressId(item) === this.state.selectedId)[0] : undefined;
        const propertyList = this.state.selectedId ? getAddressPropertyList(address) : undefined;
        const showSaveButton = this.state.selectedId && (
            (!propertyList && !this.state.selectedPropId) ||
            (propertyList && this.state.selectedPropId)
        );

        return (
            <div className="nonAppBar">
                <MyAppBar
                    title={`Select Address`}
                    className="myAppBar"
                    elementLeft={<CrossButton onClick={() => this.props.history.goBack()} />}
                />
                <div style={{ paddingTop: '12px', paddingBottom: '60px' }}>
                    {
                        !isNullOrEmptyObject(selected_address) &&
                        <>
                            <AddressItem
                                isSelected={this.state.selectedId === getAddressId(selected_address)}
                                selectedPropId={this.state.selectedPropId}
                                address={selected_address}
                                onClick={this.setSelectedId}
                                onShowMap={this.onShowMap}
                            />
                            <div style={{ width: '100%', fontSize: '16px', fontWeight: 'bold', textAlign: 'center', padding: '8px 0px' }}>
                                OR
                            </div>
                        </>
                    }
                    <Paper onClick={this.onCurrentLocationClick} style={{ padding: '12px 16px', margin: '10px 5%', borderRadius: '5px', display: 'flex', cursor: 'pointer' }}>
                        <div style={{ width: '10%', textAlign: 'center', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                            <AddIcon size={24} />
                        </div>
                        <div style={{ width: '90%', padding: '0px 8px', fontSize: '14px', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                            Add New Address
                        </div>
                    </Paper>
                    {this.renderAddressItems(address_list, selected_address)}
                </div>
                {
                    showSaveButton &&
                    <Paper className="save-address-paper" style={{}}>
                        <RaisedButton
                            id="save-product-address"
                            label="SAVE CHANGES"
                            labelColor="#FFF"
                            backgroundColor={gs_color.primaryColor}
                            style={{ width: '100%' }}
                            onClick={() => this.onSaveClick(address_list)}
                        />
                    </Paper>
                }
            </div>
        )
    }

    componentDidUpdate() {
        const selected_address = this.props.user_selected_address;

        this.updateSelectedId(selected_address);
    }

    componentWillUnmount() {
        window.removeEventListener('hashchange', this.handleHashChange, false);
    }
}

function mapStateToProps({ user_addresses }) {
    const user_addresses_loading = user_addresses.loading;
    const user_selected_address = user_addresses.selected_address;
    const user_address_list = user_addresses.address_list;

    return {
        user_addresses_loading,
        user_selected_address,
        user_address_list,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ updateRoute, selectUserAddress }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(LocationAddress);