import { isDNhoodEnv } from 'utils/helper-functions';

// commented line to get the understanding of why it is used.... (Bussiness point of view)

// client/profile/components/not-signed-profile/index.js
// sp/profile/components/not-signed-profile/index.js
// For authentication purpose, if the user is not registered then signup as client or service provider else login
const not_signed_string = "Start taking advantage of this amazing new app!";

// components/share-component/components/share-button-card/index.js
//Share App on social media like whatsapp,fb,gmail etc
const share_button_string = "Share OlaGate App to win credits";

// client/home/components/add-to-home-screen/index.js
//If you want to add the app to home screen
const add_to_home_screen_string = "Add OlaGate to homescreen";

// client/home/components/service-grid/index.js
// Searching a service as per your choice
const service_grid_string = "Search for services";

// client/home/scenes/property-address/index.js
// Initially when no address is selected (as its is mandatory to choose address first)
const no_address_available_string = "No Addresses Available";

// client/home/scenes/property-address/index.js
// If no address is selected thn add address
const add_address_string = "Please add your address";

// client/home/scenes/property-address/index.js
// After selection of address click the button to save it (Button)
const saved_addresses_string = "SAVED ADDRESSES";
// Max address limit reached
const max_address_limit_title = "You have reached maximum address limit";
// Add new address after removing existing.
const max_address_limit_desc = "To add new address please remove existing address.";
// Removing address which already has booking
const block_address_removal_title = "Unable to remove address";
// Removing address which already has booking
const block_address_removal_desc = "This address cannot be removed because you have some bookings on this address";

//scenes/client/scenes/home/scenes/search-service/index.js
//scenes/client/scenes/home/scenes/sub-category/index.js
//Selected service is not available in selected area
const service_not_in_area = "No relevant service matching your search";
const service_not_in_country_title = "No service available in";
const service_not_in_country_desc = "We will notify you when this service gets active in your area.";
const service_not_available_area_title = "Currently, this service is not available in your area";
const service_not_available_area_desc = "We will notify you when this service gets active in your area.";

//scenes/client/scenes/home/scenes/search-service/index.js
//scenes/client/scenes/home/scenes/sub-category/index.js
//If particular service is not available user can select other service which is available
const service_not_in_area_string = "May we help you in booking some other services?";
// no service available for particular service category
const service_not_available_service_category = "No service availabe in this category";
// unable to fetch services
const unable_fetch_services = "Unable to fetch services";
// below are upcoming services
const upcoming_services_string = "Below are upcoming services under selected category";

//scenes/client/scenes/booking/scenes/get-service-items/index.js
// Choose service items
const choose_items_string = "Select from below items to get a better estimate from the service provider.";
// Unable to find service item
const unable_to_find_service_items_string = "Unable to find appropriate service item ?";
// Inspection Item
const inpection_item_title = "Need help?. Not sure about the problem.";
const inpection_item_desc = "Rates will be finalized after inspection.";
const visiting_charge_applicable = "Visiting charge / Delivery charge may be applicable.";
const charge_applicable = " may be applicable.";

//scenes/client/scenes/booking/scenes/cart/index.js
// After Booking the service Quotation will be send by service provider
const cart_further_contact_string = "Quotations will be provided by the service provider soon.";

//scenes/client/scenes/booking/scenes/cart/index.js
//After the booking is confirmed string will be displayed
const cart_booking_confirm_string = "Your booking is confirmed.";

//scenes/client/scenes/booking/scenes/quotation/components/no-quotation/index.js
//scenes/sp/scenes/booking/scenes/quotation/components/no-quotation/index.js
//If the Quotation is not send by service provider for particular booking
const no_quotation_string = "Request Quotation or Skip.";

//scenes/client/scenes/booking/scenes/reviews/components/no-review/index.js
// If no review is available for any of the service provider to show to client
const no_review_string = "No review available.";

//scenes/client/scenes/booking/scenes/sp-list/index.js
//commented in code
const sp_list_mobile_string = "Onsite/Doorstep";

//scenes/client/scenes/booking/scenes/sp-list/index.js //commented in code
const sp_list_fixed_string = "Shop/Office";

//scenes/client/scenes/booking/scenes/quotation-details/index.js
//booking/accepted
// If User want to cancel the booking or not
const reject_quote_string = "Are you sure to cancel this booking?";

//scenes/client/scenes/authentication/scenes/sign-up/index.js
// Want to signup as client 
const client_sign_up_string = "Connect with wide range of Service Providers on OlaGate & avail their services at discounted rates.";

//scenes/client/scenes/authentication/scenes/sign-up/index.js
// Want to signup as service provider
const sp_sign_up_string = "Deliver your expert service through OlaGate and get 100% value of your hardwork.";

//scenes/authentication/scenes/forgot-or-register/index.js
// verification link expired.
const verification_link_expired = "Verification link expired";

//scenes/authentication/scenes/forgot-or-register/index.js
// invalid parameters.
const invalid_parameters = "Invalid parameters";

//scenes/not-found/index.js
//If some Page not Found in our App
const not_found_string = "You might be looking wrong way";

//scenes/sp/scenes/set-service-range/index.js
//Range in which user can provide service
const set_service_range_string = "Service Range is area around your chosen service location where you wish to provide your services. You will receive requests for your services from clients within this range.";

//scenes/sp/scenes/schedule/scenes/new-schedule/index.js
//schedule name already exist.
const schedule_name_already_exist = "Schedule name already exists, please use another name.";

//scenes/sp/scenes/schedule/components/schedule-all-date/index.js
// One day schedule not allowed
const one_day_schedule_string = "One day Schedule is not allowed";
const atleast_one_working_day_string = "Schedule must have at least one working day.";

const went_wrong = 'Oops! Something went wrong!';
const enter_st_date_end_date_string = 'Please enter Start Time and End Time';

//scenes/sp/scenes/schedule/components/schedule-all-date/index.js
// Select start and end time (time in between service provider is available for service)
const check_st_date_end_date_string = 'Please check Start Date and End Date';

//scenes/sp/scenes/schedule/components/schedule-all-date/index.js
// Select start and end time (time in between service provider is available for service)
const check_st_time_end_time_string = 'Please check Start Time and End Time';
const check_st_time_string = 'Please enter Start Time';
const check_end_time_string = 'Please enter End Time';
const same_editted_times_string = 'Edited time is same as original schedule time';

//scenes/sp/scenes/schedule/components/schedule-all-date/index.js
//scenes/sp/scenes/schedule/components/schedule-selected-date/index.js
// If any recess is needed so add recess time and start time
const check_rs_time_start_time_string = 'Please check Recess Time and Start Time';

//scenes/sp/scenes/schedule/components/schedule-all-date/index.js
//scenes/sp/scenes/schedule/components/schedule-selected-date/index.js
// If any recess is needed so add recess time and start time
const check_rs_time_end_time_string = 'Please check Recess Time and End Time';

//scenes/sp/scenes/schedule/components/schedule-all-date/index.js
//scenes/sp/scenes/schedule/components/schedule-selected-date/index.js
// Recess time is equal to start time and recess time duration is equal to end time Like taking whole duration as recess time
const check_rs_time_start_time_end_time_string = 'Please check Recess Time, Start Time and End Time';
const select_rs_time_dur_string = 'Please select Recess Time and Recess Duration.';

//scenes/sp/scenes/schedule/components/schedule-all-date/index.js
//Atleast one working day should be selected
const select_working_day_string = 'Please select  at least one working day.';

//scenes/sp/scenes/schedule/components/schedule-all-date/index.js
//Sp should select the working days
const select_day_string = 'Select Days you want to work.';

//Days which are unselected are considered as holidays
const unselect_holiday_string = 'Unselected days will be considered as your off day.';

//scenes/sp/scenes/schedule/components/schedule-all-date/index.js
//scenes/sp/scenes/schedule/components/schedule-selected-date/index.js
//Select the start and end time to create schedule and limit should be max 1 month
const new_schedule = "Select Start Date and End Date.";
const new_schedule_constarint = "(You can select a range of max 1 month)";

//scenes/sp/scenes/schedule/components/schedule-all-date/index.js
//pick a date to update a schedule
const update_schedule = "Select a Date Range to Update Schedule.";

//scenes/sp/scenes/schedule/components/schedule-all-date/index.js
// to create new schedule
const new_schedule_string = "New Schedule";
const create_schedule_string = "Create Schedule";

//scenes/sp/scenes/schedule/components/schedule-all-date/index.js
// to update schedule
const update_schedule_string = "Update Schedule";

//scenes/sp/scenes/schedule/scenes/sch-details/index.js
// error from server regarding updation of schedule failed because particular date having booking.
const error_sch_booking_string = "Schedule update not allowed. Reschedule existing booking/s.";

//scenes/sp/scenes/schedule/components/schedule-selected-date/index.js
// change schedule of selected date - currently is_working option
const edit_particular_date_schedule = "Change working status for this day";

//scenes/sp/scenes/schedule/components/schedule-selected-date/index.js
// booking on particular date
const booking_on_particular_date = "You have booking on this date";

//scenes/sp/scenes/schedule/components/schedule-all-date/index.js (Dialog info)
// Booking in hourly basis
// Not in use
//const svc_type_hourly_string = " - If you want booking on hourly basis";
// Booking in daily basis
//const svc_type_daily_string = " - If you want booking on daily basis";
// Booking in monthy basis
//const svc_type_monthly_string = " - If you want booking on monthly basis (subscription)";

//scenes/sp/scenes/schedule/components/schedule-component-list/index.js
//If the limit is reached for schedule thn delete the unused one
const reach_sch_limit = "Max schedule limit reached. Remove unused schedule to add new.";
// Maximum scheudle limit is reached
const reach_sch_limit_string = "Max schedule limit reached";
// If some schedule is not active and service provider want to active that schedule -> service provider is currently providing active service(Dialog)
const activate_sch_string = "Do you want to set this as an active schedule?";
// If not available or serviced thn service provider can remove particular service
const remove_sch_string = "Do you want to remove this schedule ?";
// If no schedule is availble for service provider
const no_sch_string = "No Schedule Available";
//String for active schedule
const active_sch_string = "Active Schedule";
//String for remaining schedule
const remaining_sch_string = "Other Created Schedules";
//If taking holiday on particular date or not working
const sp_not_working_string = "You have selected this date as your off day.";
// Message for asking booking serviced to sp.
const sp_booking_serviced_message = "Is job done? Please update status.";
// String for serviced confirmation
const sp_serviced_confirmation_string = "Are you sure you done with all your work?";
// Service time expired, reschedule.
const service_time_expired_reschedule1_string = " Service time expired. Please create new booking";
const service_time_expired_reschedule_string = " Service time expired. Please reschedule or cancel the booking";
//scenes/sp/scenes/schedule/components/schedule-selected-date/index.js
// If Sp want to create new schedule without updating schedule details
const create_sch_without_update_string = "Are you sure you want to create a new schedule ?";

//scenes/sp/scenes/booking/components/not-signed/index.js
//For creating the booking,firstly sign in to app
const not_signed_in_string = "To get your bookings, SignIn to OlaGate";

//scenes/sp/scenes/home/index.js
//To know how many service provider are near around your area
const find_peer_sp_string = ' Find Service Providers Near You !';

//src/components/add-service/index.js Step 1
const svc_in_svc_cat_string = 'Choose the service category appropriate to your service/s :';
//Service is added to app after which we can see the service provider near our range
const svc_added_string = 'New service/s request submitted.You will be notified once approved and activated';
//Step 2 of adding a service
const add_svc_details = "Add and provide basic details of your service/s : ";
//Step 3
const add_svc_to_portfolio = "Review the details you have provided, then click on the button below to add the service/s to your portfolio."

const change_svc_category_warning = "Currently, you are allowed to offer services in only one category. Previously, selected services will be removed. Would you still like to change service category ?";

//scenes/client/scenes/booking/scenes/sp-profile/index.js
//timeslot has already discussion booking confirmation
const book_booked_ts = 'This Timeslot has other bookings under discussion .\nAre you sure to book this timeslot?';
// invalid server reponse
const invalid_server_response = 'Invalid server response. Please try again later.';

//scenes/client/scenes/booking/scenes/booking-history/components/no-booking-history/index.js
//booking which is completed is shown
const no_history_description_string = "Your booking history will be shown here.";

//scenes/booking/scenes/booking-history/scenes/invoice/components/no-invoice/index.js
//No invoice is available by service provider
const no_invoice_string = "Invoice is pending for service provider action";
const no_invoice = "No Invoice Available";

//scenes/booking/scenes/booking-history/scenes/invoice/components/edit-invoice/index.js
const successfully_submitted = "Successfully submited.";

//scenes/client/scenes/authentication/scenes/sign-in/components/sign-in-component/index.js
//When email is not recognized for google or facebook
const facebook_error_string = "Sorry! Unable to validate your credential with facebook.";
const google_error_string = " Sorry! Unable to validate your credential with google.";
// If any duplicate address found then select one address
const duplicate_add_alert_string = "Duplicate addresses found, please select correct address";
const login_failed = "Login failed"
//scenes/client/scenes/authentication/scenes/sign-up/scenes/sign-up-client/index.js
// After signup by email id and password then verification link is send to mail
const email_string = "Enter valid email address";
//scenes/client/scenes/authentication/scenes/forgot-password/index.js
//scenes/client/scenes/authentication/scenes/sign-up/scenes/sign-up-client/index.js
//scenes/client/scenes/authentication/scenes/sign-up/scenes/sign-up-sp/extra/signup-sp-1.js
//scenes/client/scenes/authentication/scenes/sign-up/scenes/sign-up-sp/extra/singup-sp-2.js
//scenes/client/scenes/authentication/scenes/sp-registration/components/signup-sp-1.js
//scenes/client/scenes/authentication/scenes/sp-registration/components/signup-sp-2.js
//scenes/client/scenes/authentication/scenes/sp-registration/components/temp.js
// Enter the email id and password required for signup of Sp and client
const valid_mobile_string = "Enter your mobile number";
const valid_email_string = "Enter your email address";
const valid_email_phone = "Enter valid email Id or phone number";
const valid_email_phone_pass = "Fill out All required fields.";
const join_gruhaps_string = () => isDNhoodEnv() ? "Join DNhood Now " : "Join OlaGate Now ";
const send_verification = "We will send you a verification link.";
const phone_email_string = "Please enter your phone number or email";
const email_max_limit_reached = "Max email tries reached. Please try after 24 hours";

//scenes/client/scenes/authentication/scenes/sign-up/scenes/sign-up-client/index.js
// For verification require password
const pass_string = "Enter your password";

//Enter display name
//scenes/client/scenes/authentication/scenes/sign-up/scenes/sign-up-client/index.js
const dispname_help_string = "Display name will be used as profile name. \nUse your prefered name as display name.";

const name_string = " Display Name is mandatory.";

//Enter referral code
//scenes/client/scenes/authentication/scenes/sign-up/scenes/sign-up-client/index.js
const referalcode_help_string = "Referral code helps earn rewards, Use valid Referral Code.";
const referral_code_string = "Referral Code is mandatory.";

//For valid phone verify that number is 10 digits
//scenes/client/scenes/authentication/scenes/sign-up/scenes/sign-up-client/index.js
const mobile_string = "Mobile number should be of 10 digits";

//[Information Dialog]
//scenes/client/scenes/authentication/scenes/sp-registration/components/sp-reg-3-part1.js
//service can be provided through door steps
const sp_mobile_string = "Your service involves onsite visit at service location.";
// services can be provided by coming to Service providers's shop
const sp_fixed_string = "Your service requires customer to visit your store/venue.";

//Photo Id Discalaimer
const photo_id_p1 = "By submitting this, you hereby declare that you do not have a driving license and you willfully opted to use your ";
const photo_id_p2 = " for photo identification";
const id_purpose = "This will be visible to clients for authentication purpose.";

//Complete Registration Click
const complete_registration_message = "Your information is being reviewed by OlaGate. We will notify you via SMS, when it is complete. In the meantime, you can create your schedule and explore our app."

//Alert --> After cancelation all the chnages will be discarded
const discard_changes_alert_string = "All the changes you made on this page will be lost. Would you like to keep the changes you made ?";

//scenes/sp/scenes/profile/scenes/manage-services/components/add-service-to-portfolio/index.js
//scenes/sp/scenes/profile/scenes/manage-services/index.js
//Services Provided under different categories
const provide_under_category_string = "You provide services under the following category:";

// scenes/sp/scenes/profile/scenes/manage-services/components/add-service-to-portfolio/index.js
// After saving the changes, recheck the what you have added
const review_add_service_string = "Re-verify the service/s you would like to add:";

// /scenes/profile/scenes/manage-services/components/add-service-to-portfolio/index.js
//Other services in this category
const other_in_category_string = "Available services in this category:";


// scenes/sp/scenes/profile/scenes/manage-services/components/add-service-to-portfolio/index.js
// Whatever the services available not fall into your choice then add the service
const no_existing_in_category_string = "Don’t find service matching your expertise? Request new service.";

// Services created will be rechecked by team whether it exists or not
const new_service_review_gruhap_string = "New service sent to OlaGate admins for further verification and approval.";

// If all the services is provided previously and no servies are left to be shown
const every_service_in_category_string = "You are currently providing all services available in this category.";

// scenes/sp/scenes/profile/scenes/portfolio-images/index.js
// Heading
const portfolio_images_heading_string = "Add photos to showcase your skills and services to your clients";

// scenes/booking/scenes/booking-status/index.js
const cl_advisory_bkg_msg = [
    "Make sure you get invoice on the platform.",
    "Check all charges in the invoice.",
    "OlaGate doesn't charge any service."
];
const sent_admin_request = "We received your request. We will connect you shortly.";
const set_bkg_team_string = "Do you need more people to handle work? Assign team to this booking below";
const bkg_team_assigned_string = "Number of people assigned";
const booking_string = "Booking :";
const service_duration_string = "Service Duration";
const quotation_string = "Quotation :";
const invoice_string = "Invoice :";
const payment_string = "Payment :";
const review_string = "Review :";
const update_payment_status = "Did you get paid? Update payment status.";
const invoice_requested_string = "Did you forget to send invoice? Client has requested for it.";
const invoice_not_sent_string = "Service Provider  is working on the invoice."
const invoice_accepted_string = "Invoice accepted by ";
const invoice_rejected_string = "Invoice rejected by ";
// booking is canceled by client
const booking_canceled_string = "Booking canceled.";
// booking is expired
const booking_expired_string = "Booking expired.";
const booking_client_message_act_1_string = "Booking time expired. You can still reschedule the booking.";
const booking_client_message_act_2_string = "Booking time expired, reschedule requested.";
const booking_sp_message_act_1_string = "Booking time expired, you can still reschedule the booking.";
const booking_sp_message_act_2_string = "Booking time expired, reschedule requested.";

//New Added String for booking Details 

const booking_expired_reschedule_expire_messagesp = "Booking time Expired"
const booking_expired_reschedule_expire_messagecl = "Booking Time Expired. Please Make new booking"

const booking_expired_reschedule_expire_message1cl = "Service providers's Proposed Reschedule time expired. Please reschedule for new date & time."
const booking_expired_reschedule_expire_message1sp = "Your Proposed Reschedule Time expired. Please reschedule for new date & time."

const booking_expired_reschedule_expire_message2cl = "Your Proposed Reschedule time expired. Please reschedule for new date & time."
const booking_expired_reschedule_expire_message2sp = "Client's Proposed Reschedule Time expired. Please reschedule for new date & time."

const booking_expired_reschedule_expire_message3cl = "Your Proposed Reschedule Time expired. Please accept the reschedule requested by service provider."
const booking_expired_reschedule_expire_message3sp = "Your Proposed Reschedule Time expired. Please accept the reschedule requested by client."

const booking_expired_reschedule_expire_message4cl = "Service providers's proposed reschedule time expired. Your reschedule request is pending."
const booking_expired_reschedule_expire_message4sp = "Client's proposed reschedule time expired. Your reschedule request is pending."

const reschedule_expire_message = "Proposed reschedule time expired. Booking will remain confirmed for earlier booked date"

const reschedule_expire_message1cl = "Service providers's proposed reschedule time expired. Please reschedule or booking will remain confirmed for earlier booked date."
const reschedule_expire_message1sp = "Your proposed reschedule time expired. Please reschedule or booking will remain confirmed for earlier booked date."

const reschedule_expire_message2cl = "Your proposed reschedule time expired. Please reschedule or booking will remain confirmed for earlier booked date. "
const reschedule_expire_message2sp = "Client's proposed reschedule time expired. Please reschedule or booking will remain confirmed for earlier booked date."

const reschedule_expire_message3cl = "Your proposed reschedule time expired. Please accept the reschedule requested by service provider."
const reschedule_expire_message3sp = "Client's proposed reschedule time expired. Your reschedule request is pending."

const reschedule_expire_message4cl = "Service providers's proposed reschedule time expired. Your reschedule request is pending. "
const reschedule_expire_message4sp = "Your proposed reschedule time expired. Please accept the reschedule requested by client."

const reschedule_request_sp_message_cl = "Service provider requested reschedule. Please take action on request.";
const reschedule_request_cl_message_sp = "Client requested reschedule. Please take action on request.";

const resch_expire = "Reschedule time expired."

// booking is rejected by client
const booking_rejected_string = "Booking rejected.";
// booking is declined by client
const booking_declined_string = "Booking declined.";
// booking is serviced by service provider
const booking_serviced_string = "Booking serviced.";
// booking (payment) is settled
const booking_settled_string = "Booking settled.";
// booking (payment) is indispute
const booking_indispute_string = "Booking indispute.";
//When quotation is send for revision
const revice_quote_string = "Quotation revision requested.";
// booking reschedule
const booking_reschedule_string = "Booking reschedule requested.";
// Booking is not accepted by service provider
const booking_accepted_string = "Booking accepted.";
//booking is confirmed by client
const booking_confirmed_string = "Booking confirmed.";
const booking_completed_string = "Booking completed.";
// Quotation is rejected by client/service provider
const quotation_rejected_string = "Quotation rejected by client/service provider.";
// Generate quotation for service provider.
const genrate_quotation_sp = "Generate quotation and send to client.";
// When client request for quotation
const request_for_quotation_cl = "Request for quotation.";
const client_requested_quotation_cl = "Quotation request sent to service provider.";
const client_requested_quotation_sp = "Client requested for quotation.";
// When quotation is not submitted by service provider to client
const not_submit_quote_string_sp = "Please send quotation to the client.";
const not_submit_quote_string_cl = "Please wait quotation is on the way.";
//Quoattion related status
const action_quote_review_string_cl = "Review quotation sent by service provider.";
const action_quote_string_sp = "Waiting for client response on quotation.";
const action_quote_string_cl = "Review and respond to quotation.";
// When client request for quotation
const request_for_invoice_cl = "Request for invoice.";
const client_requested_invoice_cl = "Invoice request sent to service provider.";
const client_requested_invoice_sp = "Client requested for invoice.";

const action_invoice_review_string_cl = "Review invoice sent by service provider.";
const action_invoice_string_client = "Be sure to verify invoice details before accepting it.";
const not_submit_invoice_string_sp = "Please send invoice to the client.";
const action_raised_invoice_string_sp = "Invoice pending for client acceptance.";
const action_invoice_string_sp = "Good Job! Get paid now, review and submit invoice to client.";
const revise_quote_string = "requested for the revision on Quotation";
const revise_invoice_string = "requested for the revised Invoice.";
const booking_not_accepted_string = "Booking under review. Acceptance is pending";

// Before proceeding service duration is need to be defined
const duration_not_defined_string = "Missing estimated duration for the booking.";
//Date and time service provider or client have suggested and seen by themselves
const suggested_date_time_string = "Your proposed Date and Time :";
//Client suggested date and time and seen by service provider
const client_suggested_date_time_string = "Client's proposed Date and Time :";
//service provider suggested date and time and seen by Client
const sp_suggested_date_time_string = "Service provider's proposed Date and Time :";
//Uploaded media can be viewed
const view_media_string = "Click to view attached media";
// Client should ask for invoice before payment
const client_collect_invoice_string = "Before Payment, make sure you get an invoice.";

// service provider should generate invoice before payment
const sp_generate_invoice_string = "Before Payment, make sure you send invoice";
// Booking expired, sp open edit quote message. || Client taking action on quote.
const bkg_expired_quote_string = "Booking expired, you need to reschedule the booking.";
// Aks client to give review.
const client_give_review_string = "Please submit review to earn rewards points.";
const sp_give_review_string = "Please submit review to client.";

const cancel_warning_header = "Warning!";
const cancel_booking_message = "Are you sure you want to cancel this booking?";
const reject_booking_message = "Are you sure you want to reject this booking?";
const cancel_warning_message = "Sorry, little late to cancel booking, You may need to compensate service provider for time.";

const reschedule_warning_header = "Warning!";
const reschedule_warning_message = "Sorry, little late to reschedule booking, You may need to compensate service provider for his lost opportunity.";

// accept booking or reschedule confirmation
const accept_confirmation_title = "Accept booking with following details?";
const reschedule_confirmation_title = "Reschedule booking with following details?";

const booking_broadcasted_to_sp_string = "Booking is broadcasted to available service provider.";
const booking_sp_cancel_brodacast_string = "Sorry, Service Provider seems to be busy but no worries, there are more service providers available in your neighbourhood. Do you want to broadcast this requirements to them ?";
const booking_sp_no_response_brodacast_string = "Service Provider not responded your booking. There are more service providers available to help you. Do you want to broadcast your requirements to other service provider ?";
const broadcast_booking_missed_string = "You missed this broadcast booking.";
const broadcast_booking_rejected_string = "This broadcast booking is rejected.";
const broadcast_booking_expired_string = "This broadcast booking is expired.";
const urgent_request_pending = "Service provider is yet to be assigned.";
const attend_service_pending = "Service request is pending. Service needs to be attended by";

//scenes/booking/scenes/reschedule-booking/index.js
// When service provider is not available on particualr date
const no_work_string = "Sorry, it’s my day off.";
const slot_already_booked_string = "Slot is already booked";
// On particular date service provider is not available on particular time
const no_time_string_client = "Sorry, little busy on this day. May I help some other day?";
// On particular date service provider is not available on particular time
const no_time_string_sp = "You are not working on this date.";
// Timelsot is not available
const no_timeslot_string = "Sorry, No timeslots available for selected date. Try other date from above available dates.";
// Timelsot is not available within the range client message
const no_timeslot_string_client = "Sorry, No free timeslot. Try other date of service provider";
// Timelsot is not available within the range sp message
const no_timeslot_string_sp = "You don't have any free timeslot. Try other date";
// Timeslot in which service provider is available for client
const available_timeslot_string = "Available timeslots";
// Selected timeslot matches with booking service time.
const reschedule_timeslot_same_booking_timeslot_string = "Same as booking service time";
// Selected timeslot matches with client proposed time. (selecting user sp)
const reschedule_timeslot_same_clproposed_timeslot_string_sp = "Same as client reschedule time";
// Selected timeslot matches with client proposed time. (selecting user client)
const reschedule_timeslot_same_clproposed_timeslot_string_client = "Same as your last reschedule time";
// Selected timeslot matches with sp service time. (selecting user client)
const reschedule_timeslot_same_sppropsped_timeslot_string_client = "Same as service provider reschedule time";
// Selected timeslot matches with sp service time. (selecting user sp)
const reschedule_timeslot_same_sppropsped_timeslot_string_sp = "Same as your last reschedule time";
// Reschedule selected time disable dialog title.
const show_disable_time_dialog_title = "Selected reschedule time not available";
// Sp selected timeslot overlaps recess time.
const selected_timeslot_overlaps_recess_time = "Selected timeslot overlapping with schedule recess time";
// Sp selected timeslot exceeds end time.
const selected_timeslot_exceeds_end_time = "Booking exceeds your schedule end time";
// Unable to reschedule. Some issue encountered.
const reschedule_issue = "Unable to reschedule. Please try again later";

//scenes/client/scenes/authentication/scenes/forgot-password/index.js
// Valid phone number is needed
const valid_number_string = "Please enter valid phone number";
// Proper email id or phone number is needed
const proper_valid_mobile_string = " Email address or mobile number is mandatory";

const display_pass = "Password must not contain your display name";
//scenes/client/scenes/authentication/scenes/sign-in/components/no-notification-permission/index.js
// Allow notification permission
const notification_benefits = "Benefits of notifications :-";
const notification_benefits_reasons = [
    "Realtime chat feature",
    "Get up-to-date with your bookings",
    "Get Invoices after booking completion"
];
// If permission is unavailable
const notification_permission_unavailable_reason = "This generally happens because of following reasons :-";
const notification_permission_unavailable_reasons = [
    "User blocked permission",
    "Device having low storage",
    "Using iOS devices",
    "Using browsers which not supports notifications (use latest version of chrome)",
    "Using old version of browser"
];
// If denied user will not recieve notifications
const denied_string = "Notification is really important for this App to help you.\nIf you deny you will not able to get chat, booking & other notification from the service provider";
//After allowing user will recieve diffetent notifications
const allow_permission_string = "Allow permission";

//scenes/client/scenes/authentication/scenes/sign-up/scenes/otp-received/index.js
//Otp send to phone should be valid 
const valid_otp_string = "Please enter valid OTP.";

//If not recieved otp
const not_recieved_otp_string = "Didn't received OTP? ";

const change_no_string = "Your primary phone number has been verified. ";
const change_email_string = "Your email address has been updated. ";
const change_prim_phone_string = "Your primary phone number has been updated. ";
const phone_succ_verified = "Your phone number has been verified. ";
const email_succ_verified = "Your email has been verified. ";


//scenes/client/scenes/authentication/scenes/sign-up/scenes/otp-verification/index.js
//After the registration user will recive one time password
const one_time_sms_string = "We will send you a one time SMS. Carrier rate may apply";

//scenes/client/scenes/authentication/scenes/sp-registration/components/sp-reg-1.js
const picture_upload_title = "Selfie time! Upload your selfie or clear visible picture as profile picture"
// Picture not uploaded then mandantorily upload it
const picture_string = "Profile Picture helps to make first impression on your clients.";
//Due to some problem picture is not uploded
const picture_error_string = "Sorry, Couldn't upload profile picture. Please try again.";

const picture_size_exceeded_title = "File size exceeded the limit of 10 Mb.";

const picture_size_exceeded_message = "Please upload an image of size 10 Mb or less."

const confirm_logout_message = "You are half way through. Are you sure you want to logout?"
const confirm_logout_string = "Are you sure you want to logout?"
//scenes/client/scenes/authentication/scenes/sp-registration/components/sp-reg-2.js
// services provided and work done is described
const about_you_string = "Write about your services and expertise.";
// Profile intro is compulsory of sp
const profile_intro_req_string = "Profile Introduction is required";
//Work exp is required
const work_exp_req_string = "Work experience is required";
const work_exp_exceeded_string = "Must be less than 60";
const negative_not_allowed = "Cannot be negative";
const market_profile = "Educational and professional qualification helps to market the profile";
//client/scenes/authentication/scenes/sp-registration/components/sp-reg-3-part1.js
const select_mode_string = "Select mode of service: ";
//Category of services is selected and confirmed
const confirm_mode_string = "Confirm mode of service as ";
// Whta do you mean by mode service
const mode_string = "What is mode of service ?";

//scenes/client/scenes/authentication/scenes/sp-registration/components/sp-reg-3-part2-fixed.js
//Add the location during registration
const add_store_loc_string = "Add your store/service location/s:";
// If no location is added
const store_loc_string = "No store/service locations added";
// Whatever location is added is shown
const added_store_loc_string = "You have added the following store location/s:";
// If could'nt connect to server then try again
const no_connect_to_server_string = "Sorry, horrible traffic, couldn't reach the server. Please try again.";
// add or change the location
const add_change_loc_string = "Add/change locations";

//scenes/client/scenes/authentication/scenes/sp-registration/components/sp-reg-3-part3.js
//scenes/client/scenes/authentication/scenes/sp-registration/components/sp-reg-3-part4.js
const if_string = "If ";

const quote_eligible_string = "Quotation eligible";
const quote_eligible_what_string = "What is Quotation eligible ?";
const quote_eligible_explanation_string_p2 = " is enabled, you will be required to provide a quotation with service detail and cost breakdown."

const off_hours_enable_string = "Off-hours service eligible";
const off_hours_enable_what_string = "What is off-hours service eligible ?";
const off_hours_enable_explanation_string_p2 = "If enabled, you may receive service requests from customers for off-working hours. Please see off-hour schedule and rate card.";
//scenes/sp/scenes/profile/scenes/manage-services/index.js
//scenes/sp/scenes/set-service-range/index.js
// Any server related error
const server_error_string = "Sorry, this is on us! Server error. Please try again.";
// Particular service can be removed from profile of sp and after that service request is disabled
const remove_service_alert_string = "If you remove this service from your portfolio, you will not be eligible to receive requests for this service. Are you sure you want to remove ?";
const remove_service_alet_title = "Remove Service ?"

//You provide these services string
const your_current_services = "You are currently providing following services :";

//Add More services string
const add_more_services = "Want to add more services to your portfolio ?";

const remove_service_constraint = "You must have  at least one service in your portfolio.";
const remove_service_success = "Service was successfully removed !"

//scenes/client/scenes/authentication/scenes/sp-registration/components/sp-reg-3-part3.js
//scenes/client/scenes/authentication/scenes/sp-registration/components/sp-reg-3-part4.js
// When registration is successfully done
const successfully_reg_string = "Registered successfully.Kindly find the terms of service on your phone.";

//scenes/client/scenes/authentication/scenes/sp-registration/components/sp-reg-3-part3.js
//Visiting Charge Required
const visting_charge_req = "Visiting charge required";
//You have selected the services
const selected_services_string = "You have selected the following services:";
// Atleast one service should be added
const select_one_service_string = "Please select  at least one service";
//Service selection guidance string
const service_selection_guidance = "If you are done with service selection, click on the button below to provide further details about selected services";

//scenes/client/scenes/booking/scenes/question-list/index.js
// Service details or description is shown
const service_details_string = "Service items details or description";
// if any problem related to anything can be written here
const problem_details_string = "Do you have any other details to share?";
const problem_details_string_constraint = "Service Requirement should be minimum 25 characters";
const problem_details_hint_string = "Service requirements";
const upload_content_for_sp_string = "Attach pics to get better estimate from service provider."
//Add pics/ short video to get better estimate
//scenes/client/scenes/booking/scenes/advert/components/booking-completed/index.js
// when order is completed means quotation is accepted
const completed_order_string = "Hurray! Your booking is confirmed";

//client/scenes/booking/scenes/advert/components/booking-your-cart/index.js
//scenes/client/scenes/booking/scenes/cart/index.js
// Booking is confirmed then this message is dislayed
const booking_confirm_string = "Hurray! Your booking is confirmed";

//scenes/client/scenes/booking/scenes/booking-history/components/no-booking-history/index.js
// when intailly no history is available
const no_history_string = "No history available";

//scenes/client/scenes/booking/scenes/quotation/components/no-quotation/index.js
//scenes/sp/scenes/booking/scenes/quotation/components/no-quotation/index.js
// no quotation is available with sp or client
const no_quotation = "No quotation available";

//scenes/client/scenes/booking/scenes/sp-list-map/components/service providerMap/index.js
//scenes/client/scenes/booking/scenes/sp-list-map/index.js
// While searching service provider near you
const loading_sp = "Loading service provider";

//scenes/client/scenes/authentication/scenes/sp-registration/components/sp-reg-3-part3.js
//src/scenes/client/scenes/authentication/scenes/sp-registration/components/sp-reg-3-part4.js
//scenes/client/scenes/booking/index.js
//scenes/sp/scenes/profile/scenes/manage-services/index.js
//scenes/sp/scenes/set-service-range/index.js
//If any Server error this message is displayed
const server_error = "Sorry, looks like a traffic jam! Server failed to respond. Please try after some time";
const check_form_fields = "Please recheck all fields and try again.";

//scenes/client/scenes/home/components/add-to-home-screen/index.js
// App can be added to home screen
const add_To_home = "Add OlaGate to homescreen";

//scenes/client/scenes/profile/components/not-signed-profile/components/card/index.js
//scenes/sp/scenes/profile/components/not-signed-profile/components/card/index.js
//We can service provider near around your place
const find_sp = "Find a service provider in neighbourhood and avail their services at discounted rate.";

//scenes/client/scenes/profile/components/profile-view/components/notification-permission/index.js
//scenes/sp/scenes/profile/components/profile-view/components/notification-permission/index.js
// If the notification is blocked then location cannot be tracked
//https://www.fileformat.info/info/unicode/char/22ee/browsertest.htm
const block_notification_string = "You have block the notification to enable it \n Tap on menu (⋮) \n Setting->Site setting->Notification->Block->click on https://olagate.app ->Click on clear and reset";

//scenes/not-found/index.js
//src/utils/handle-address-duplication.js
// Following page not found
const not_found = "404 Page Not Found";

//scenes/sp/scenes/booking/components/no-booking/index.js
// no active booking available
const no_active_book = "No Active Bookings Found.";

//scenes/sp/scenes/booking/components/not-signed/index.js
// if not signed in then sign in to continue for booking
const not_sign_in = "Not Signed In";
const sign_gruhap = "To get notifications, sign in to OlaGate";
//scenes/sp/scenes/profile/scenes/manage-services/index.js
// After successfully updating the portfolio
const update_portfolio = "Succesfully updated your service portfolio";

//scenes/sp/scenes/schedule/components/no-schedule/index.js
// If no schedule is available
const no_schedule_string = "Create your schedule";

//scenes/client/scenes/home/scenes/property-address/components/fixed-marker/index.js
// Showing error for non available address services.
const service_unavailable_area = "Sorry, No service provider currently available in your area. \nSign up with us & we will notify you as soon as service provider get available";
const service_unavailable = "Sorry, No service providers available for requested date and time."
const confirm_request_invoice = "No Invoice! Would you like to request for an invoice from the service provider ?";
const confirm_request_quote = "Would you like to request for an quote from the service provider ?";


const client_paid_invoice = "Have you received full payment from client ?";
const sp_paid_invoice = "Have you paid full payment to service provider ?";

const accept_incoice_string = "By clicking yes, you confirm that the invoice is free of any discrepancy and you wish to accept it.";
const request_invoice_successfull = "Request for invoice sent successfully.";
const request_quote_successfull = "Request for quote sent successfully.";
const an_error_occurred = "Sorry, Bumpy ride! An error occurred. Please try again.";

//cancelReason
const booking_cancel_reasons_client = ["Service provider denied", "Expensive service", "Time not available", "No need of service", "Any emergency?"];
const booking_cancel_reasons_sp = ["Time not available", "Location unavailable", "Any emergency?"];
const quotation_reject_reasons = ["Service provider denied", "Expensive service", "Other service provider provides better quotations"];
const invoice_reject_reasons = ["Prices differ from quotations", "Expensive service"];
const booking_reschedule_reasons_client = ["Medical/Health emergency", "Family emgergency", "Unexpected travel plan", "Time issue"];
const booking_reschedule_reasons_sp = ["Medical/Health emergency", "Family emgergency", "Unexpected travel plan", "Callback from office", "Time issue"];

// Secondary Phone change should be of  at least 10 digit
const sec_error = "Secondary phone number should be  at least 10 digits";

//Dialog while email is sent for confirmation or otp sent
const otp_link_sent = "OTP and Link is sent to your registered/primary Phone. ";
const otp_sent = "OTP is sent to your registered/primary Phone. ";
const otp_sent_email = "OTP is sent to your Email."
const email_sent = "Confirmation email sent to your mail for verification. ";
const otp_parameters_missing = "One or more parameters missing to resend otp";

//Whenever password will be changed user will get a dialog
const pass_change = "Your password has been changed.";

const valid_phone = "Please enter your phone number";

// forgot-password
const not_recieve_link = "Didn't receive the link?";
const link_on_phone = "Link and OTP has been sent to your phone.";
const otp_on_staff_phone = "OTP has been sent to your staff phone.";
const link_on_email = "Email verification link sent to your email-id.";
const unable_to_send_link = "Unable to send link";
const forgot_link_email = "Forgot password link has been send to your email-id";
const forgot_link_phone = "Forgot password link and OTP has been send to your phone";
const link_on_phone_and_email = link_on_phone + ' and ' + link_on_email.toLowerCase();

//booking-history/submitted review
const post_success = "Review posted successfully!";
const review_req = "Review is mandatory";
const char_req = "Should be minimum 25 characters";
const rate_req = "Rating is mandatory";

//write-review 
const thankstring = "Thank you for your feedback!";

//authentucation/forgot-password
const string1 = "Enter your registered email or phone number."
// const string1 = "Enter your registered Phone number."
const string2 = "We will send you a link to reset your password."

//Edit profile~ Change phone number Strings
// Third Party signUp
const change_phone_string1 = "Just sit back and relax.";
const change_phone_string2 = "Only one step remaining to explore our App.";

//Edit profile ~ Change password Strings
const change_pass_string1 = "To change your password,";
const change_pass_string2 = "Provide following details and click the button below to proceed.";

//Edit profile ~ Change email address
const change_email_string1 = "You can use the changed email address for login after verification.";
const new_email_string = "You can use the new email address for login after verification.";

// Change Email component (edit profile-cl,sp)
// If client or service provider want to change their email if for login purpose
const email_ver = "Your email address has been verified";

//edit-profile / upload documents feature text
const upload_docs = "This feature will be coming soon !"
const react_error = "React error"

// client/profile/scenes/edit-profile/scenes/change-phone/index.js
//Changing the primary phone number can change your login credentails
const change_phone_string = "If you change your primary phone number, it will become your login id.";
const change_phone_string_native = "Please make sure your entered number is correct. You will be using this phone number for login.";
const NonEditable_string = "This field is non-editable, if you want to change go to => Change email/phone";

//scenes/client/scenes/profile/scenes/edit-profile/scenes/change-password/index.js
// changed password is matched with new password
const mismatch_pass = "Error: New password should match confirm password.";
const pass_length = "Error: Your password must contain at least eight characters!";
const check_pass = "Error: Your password must contain one letter, one number and should start with an alphabet!"
const pass_mandatory = "Password is mandatory."

//Link expired
const expString1 = "Link expired !";
const expString2 = "Password reset link is valid for only 15 minutes.";
const expString3 = "Registration link is valid for 24 Hours.";

//Account Suspended
const acnt_suspend_string1 = "Your account has been temporarily suspended.";
const acnt_suspend_string2 = "Admin has suspended your account because you provided wrong information too many times.";
const acnt_suspend_string3 = "To activate your account, please contact us at ";
const acnt_suspend_string4 = "or write to us at ";

//Contact Us
const contact_us_leave_details_string = "Leave your message, our Team will reach you shortly.";

//scenes/booking/scenes/quote and invoice
const confirm_send_invoice_to_client = "Send invoice to client ?";
const confirm_send_quote_to_client = "Send quotation to client ?";

const cannot_send_empty_invoice = "Sorry, cannot send invoice without a service charge.";
const invalid_rate_provided = "Invalid rate/s provided."
const cannot_send_empty_quote = "Sorry, cannot send quotation without a service charge.";

const required_field = "Please fill out required field !";

const invoice_disclaimer_string = "Disclaimer: This is suggested invoice and cannot be used for the tax or gst purpose.";
const invoice_disclaimer_contact_sp_string = "Please contact the service provider for the tax/gst invoice.";

// components/error-components
const error_401_default_string = "Authorization required";
const error_404_default_string = "Unable to find response for your request";
const error_500_default_string = "Server error";

// rate factor dialog text
const rate_factor_dialog_title = "Puzzled! Why higher rates?";
const rate_factor_dialog_text = "Check below, to see timeslots & respective rate factor.";
const rate_factor_text = "We think service provider deserve little rewards for their flexibility.";

const Select_address = "Please select property address"

// When Location of Particular user not fetched (find sp near me -error page)
const no_location = "Sorry, unable to fetch your location."
const no_location_step = "In order to provide better service , please turn on location in settings."

const check_referal = "Do you have refferal code ?"
const check_realm = "Do you want to sign in as service provider?"

const create_schedule = "An active schedule helps you to get visibility to current and future dated service requests. Please create your work schedule from here."

const share_ref_string = "Invite your friends and family by sharing referral code to earn rewards."

//Rate Factor Card service provider Registration
const rate_factor_note = "OlaGate automatically raises your service rate based on service time. Click to know more.";
const rate_factor_example_sp = "For eg. if your rate for a service is 100, your rate will automatically change to 110 (100 + 10%) for booking time between 6pm - 9pm.";

const minimum_visiting_charge_explanation = "Visiting charge is applicable if your service requires onsite visit for the inspection of work item or issue.";

//src/scenes/client/scenes/profile/components/not-signed-profile/index.js
const click_here = " Click here to";
const not_signed_in_string1 = "SIGN IN or REGISTER";
const not_signed_in_string2 = "Your Digital Neighborhood Platform";
const not_signed_in_string3 = " Free forever, Free For Everyone";
const not_signed_in_string4 = "OneStop solution for apartment RWA & Residents";
const not_signed_in_string5 = "Deliver Your Expertise @ 0% Commision Charges";
const not_signed_in_string6 = " Fast, Light, Secure & Stealth";
const suggestion_string = " We would love to hear your suggestions, recommendations or issues. Your feedback is very important for the improvement of this platform."
const terms_condition = () => `By signing up you indicate that you have read and agree to ${isDNhoodEnv() ? "DNhood's" : "OlaGate's"}`;
const gdpr_compliance_string = () => `We use cookies to ensure you have the best browsing experience on our website. By using our site, you acknowledge that you have read and understood our `;
const write_us = "Write to us";
const opinion_string = "What is your overall opinion about";
const feedback_submit = "Your feedback was submitted successfully !"
// no-notification-permision
const no_notification_string1 = "Notification Permission Blocked";
const no_notification_string2 = "Without Notification permission you cannot login.";
const no_notification_string3 = "Notification Permission Unavailable";
const no_notification_string4 = " UNBLOCK PERMISSION STEPS";
const no_notification_string5 = "Allow Notification Permission";
const notification_permission_unblock_string1 = " to unblock notifications (if you have blocked notifications during permission)";
const notification_permission_unblock_string2 = "Check out steps";
const notification_permission_unblock_string3 = "CONTINUE WITHOUT NOTIFICATIONS";
const notification_permission_unblock_string4 = "Notification Permission Unavailable";
const notification_permission_unblock_string5 = "Are you sure to go back? Your login details will be lost.";

const notification_step_string1 = "Click lock icon on address bar";
const notification_step_string2 = "You will get pop up, click on Site Settings";
const notification_step_string3 = "Then click on Notifications (which will be shown as block)";
const notification_step_string4 = "Change permission to Allow / Ask";
const notification_step_string5 = "Refresh App";

const notification_step_string6 = "You will get pop up, click on edit site settings";
const notification_step_string7 = "Then check notifications (which will be shown as never)";
const notification_step_string8 = "Click on clear button";
const valid_zip = 'Enter valid zipcode';

const no_active_string = "No Active Booking";
const book_service = "To book a service click on book a service";
const no_sp = "No service provider available";
const not_signed_string1 = "Not Signed In";
const not_signed_string2 = "To book a service, Sign In to OlaGate";
const property_address = "Please select property address";

const no_notif = "No Notifications Available";
const imp_notif = "Important notifications lives here.";

const question1 = "What is OlaGate ?";
const ans1 = "Your Digital Neighborhood Platform, first of its kind.";
const ans1_string1 = "It's a network of gRhapa/gRuhap. 'gRhapa' pronounced as gRu-hap, a sanskrit word for 'Property Care Taker'.";
const ans1_string2 = "We envisioned to provide this unified services platform for both service consumers and providers. Platform is for any qualified individual. We just expect every member on this platform to be honest, transparent and respectful to each other. Use this platform to help each other for the social and economic values.";

const question2 = "Who can be a service provider ?";
const ans2 = "Any individual who has the skill set, ability & willingness to work, can be a service provider on this platform. This platform encourages and helps you, to run your Services like a Business and help you to “Be Your Own Boss”. Only expectation, is to pass the benefits of this free platform to the Service Consumers in terms of a discount.";

const question3 = "Who are Service Consumer ?";
const ans3 = "Any legally qualified adult, including the service providers.";

const question4 = "How do we plan to support this platform in the long run ?";
const ans4_string1 = "To support this platform, we expect to generate revenue only through advertisements.";
const ans4_string2 = "So the more you use and refer OlaGate to your friends & family, we will be able to keep this platform running for you.";
const ans4_string3 = "We want everyone to enjoy the social & economic value of this platform.";

const new_ser_string = "Below, provide basic details of your new service.";
const service_req_send = " Your new service request is being processed. A OlaGate representative will connect with you on your registered number for further details.";
const provide_service = "Provide a short description about this service";
const save_changes_string = "Are you sure you want to save the changes?";
const enter_zip = "Enter your zipcode";
const give_feedback = "Give feedback on this service provider";
const report_admin_string = " We use your feedback to help us learn when something's not right"
const help_string = " Help us understand the problem.";
const signin_gruhaps = "To use app, sign in to OlaGate";

const review_info = "Review your information once and click below to complete registration"
const reg_success = "Registration request sent successfully !";
const upload_clear_img = "Please upload a clear visible picture of the following document"
const upload_clear_gumasta = "Please upload a clear visible picture of your applicable store registration document such as Gumasta etc."
const profile_pic_string1 = "All fields marked with";
const profile_pic_string2 = "are compulsory. Fill these to continue."
const serv_req = "Your new service request is being processed. A OlaGate representative will connect with you on your registered number for further details."
const get_serv_acc = "Select all the services that you would like to provide and price them accordingly"
const expertise_fall_cat_string = "Do you think your expertise doesn't fall into any of the existing services"
const submit_new_req = "Click below to submit a new service request in this category"
const no_serv = "No services found";
const select_one_serv = "Please select  at least one service.";
const select_loc = "Select a central location (usually your home or work place) and set a range around it. You will receive customer requests for your service in this area."
const select_loc_range = "Select your store location and set a range around it. You will receive customer requests for doorstep service in this area.";
const get_started = "Click below to get started";
const select_mode = "Select your Mode of Service";

const updating_language = "Updating language";

const compulsory_string1 = "All fields marked with";
const compulsory_string2 = "are compulsory. Fill these to continue.";
const full_name_constraint = "Same as your legal document such as Driving Licence etc."
const inc_string1 = "Your Service Rates will automatically be increased by";
const inc_string2 = "before you submit the invoice";

// Discount message
const discount_offered_system = " (Offered by OlaGate)";
const discount_offered_sp = " (Offered by provider)";
const discount_offered = " (Offered by ";
const discount_reimburse_as_reward = "Discount amount will be reimbursed to you as rewards";
const discount_required_err_string = "Required";
const discount_select_type_err_string = "Please select discount type";
const discount_code_min_max_err_string = (min_len, max_len) => `Discount Code should be minimum ${min_len} and maximum ${max_len} characters`;
const discount_amount_min_0_err_string = "Discount Amount should be greater than 0";
const discount_amount_max_err_string = (max_amount) => `Discount Amount should be maximum ${max_amount}`;
const discount_max_amount_min_0_err_string = "Maximum discount should be greater than 0";
const discount_max_amount_max_inv_err_string = (max_pct) => `Maximum invoice % cannot exceed ${max_pct}`;
const discount_max_amount_pct_min_0_err_string = "Maximum Discount (in %) should be greater than 0";
const discount_max_amount_pct_max_err_string = (max_pct) => `Maximum Discount (in %) should be ${max_pct}`;
const discount_max_amount_max_err_string = (max_amount) => `Maximum discount cannot exceed ${max_amount}`;
const discount_max_times_min_0_err_string = "Maximum times per client should be greater than 0";
const discount_max_times_max_err_string = (max_times) => `Maximum times per client should be maximum ${max_times}`;
const discount_start_date_err_string = "Start date-time should be greater than current time";
const discount_end_date_start_date_err_string = "End date-time should be greater than start date-time";
const discount_end_date_err_string = "End date-time should be greater than current time";
const discount_share_pct_string = (pct, max_amount) => `${pct}% off upto ₹${max_amount}`;
const discount_share_abs_string = (discount) => `flat ₹${discount} off`;
const discount_share_start_date_string = (date) => ', starting from ' + date + '.';
const discount_share_book_string = (discount_code, discount_amount_msg, discount_start_msg) => `Book me on OlaGate using discount code : ${discount_code} and get ${discount_amount_msg || 'discount'}${discount_start_msg}`;
const discount_type_automatic_msg = "Automatically Applied";
const discount_type_manual_msg = "Manually Enter Code";
const discount_abs_string = (discount, max_discount) => `Flat ₹${discount}/- off or max upto ${max_discount}% of invoice`;
const discount_pct_string = (discount, max_discount) => `${discount}% off upto ₹${max_discount}`;
const discount_upto_string = (max_discount) => `Upto ₹${max_discount} off`;
const discount_code_not_applicable_string = "Coupon code is not applicable anymore";

// service provider payment in booking details message
const no_credits_booking_action_string = "Buy credits from OlaGate to take action on booking.";
const no_credits_booking_accept_string = "Buy credits from OlaGate to accept the booking.";
const no_credits_booking_reschedule_string = "Buy credits from OlaGate to reschedule the booking.";
const low_credits_booking_action_string = "Balance running low, recharge soon.\n\nYou have enough credit to take action for this booking.";
const low_credits_booking_accept_string = "Balance running low, recharge soon.\n\nYou have enough credit to accept this booking.";
const low_credits_booking_reschedule_string = "Balance running low, recharge soon.\n\nYou have enough credit to reschedule this booking.";
const sorry_less_credits_action_booking_string = "Sorry, you do not have sufficient credits to take action for this booking";
const sorry_less_credits_accept_booking_string = "Sorry, you do not have sufficient credits to accept this booking";
const sorry_less_credits_reschedule_booking_string = "Sorry, you do not have sufficient credits to reschedule this booking";

// service provider plans screen
const sp_cancelled_payment_string = "You cancelled payment";
const purchase_successful_string = "Purchase Successful";
const do_not_refresh_payment_string = "Please wait and do not refresh the page while we are processing your payment.";
const can_take_few_minutes_string = "This can take a few minutes.";
const retrying_payment_request_string = "Retrying your payment request ...";
const payment_failed_string = "Sorry your payment failed.";
const payment_wrong_admin_string = (payment_id) => `We are sorry, something went ${payment_id ? `wrong with payment id ${payment_id}` : ''}. OlaGate admin are working on it.`;

const err_buy_credits_string = "Please select a Bulk Buy or enter a valid number of credits to purchase.";
const err_buy_only_credits_string = "Enter a valid number of credits to purchase.";
const err_buy_only_bulk_credits_string = "Please select a Bulk Buy.";
const err_min_buy_credits_string = (val) => val <= 1 ? `You need to purchase minimum ${val} credit.` : `You need to purchase minimum ${val} credits.`;
const err_max_buy_credits_string = (val) => val <= 1 ? `You cannot purchase more than ${val} credit in one transaction.` : `You cannot purchase more than ${val} credits in one transaction.`
const err_max_buy_credits_bulk_buy_string = (val) => val <= 1 ? `Please select a bulk buy plan to purchase more than ${val} credit.` : `Please select a bulk buy plan to purchase more than ${val} credits.`
const err_plan_changed_string = "Oops! Looks like there are some changes in page, click to update page with new data.";

// service provider Set Profile url
const enter_url_string = 'Please enter url';
const minimum_url_string = 'Url should have minimum 5 characters';
const improper_format_url = 'Improper format of url';
const maximum_url_string = 'Url should not exceed 25 characters';
const url_not_available = 'Url not available';
const premium_custom_url_text = 'Being a Premium User, you can set your custom url to showcase your profile.';
const create_public_url = 'Create your OlaGate custom url below !';
const public_url_constraints = 'Url should not end with special characters ( _ )';
const url_checking_availability = 'Checking availability';
const available_string = 'Available';
const unavailable_string = 'Not Available';
const refer_below_video_public_url = 'Refer below video to know more';
const find_public_url = 'You can find and share your public url in';
const find_public_url_path = 'Profile';

const online_broadcast_string = "You will receive urgent bookings.";
const offline_broadcast_string = "You will not receive urgent bookings.";
const online_string = "You will receive new bookings for today.";
const offline_string = "You will not receive new bookings for today.";


const error_chunk_string = "Error while loading";
const timeout_chunk_string = "Taking long time to load";

const select_address_service = "Please select address wherever you want your service.";
const service_address_unavailable = "Service Provider does not provide service in your area.";
const sp_address_unavailable = "Service Provider does not provide service in your area.";
const upcoming_services_registration = "SP Registration in progress";

//Client broadcast

const client_broadcast_string = "Booking is broadcasted to all available service providers.";
const client_broadcast_sp_string = "You haven't responded the booking, so client requested other service providers.";
const client_broadcast_declined_string = "Service provider declined the booking. Do you want to broadcast this booking to other service providers ?";
const client_broadcast_booked_string = "Service provider haven't responded the booking. Do you want to broadcast this booking to other service providers ?";

// Team
const teams_size_desc_string = "Team size is number of people in your team who serves customers. Depending on your team size, you will able to get multiple bookings for same time on OlaGate.";

// Society
// Entry pass
const no_entry_pass_family_resident_string = "Create permanent code for the family members or others who share this property unit with you. Add them here.";
const no_entry_pass_family_non_resident_string = "Have a non resident extended family? Make their visit seamless. Add and indentify them here to create permanent code for them.";
const no_entry_pass_regular_visitor_string = "We all love our friends and enjoy their company. Make their visit haselfree, get then permanent code for them. Add and identify them here.";
const no_entry_pass_staff_string = "We all need help and their delay can be unwanted stress for us. Help your staff or domestic help to help you on time. Add, indentify and authenticate your staff.";

// Visitor
const visitor_arrival_date_err_string = "Arrival date-time should be greater than current time";
const visitor_depart_date_arrival_date_err_string = "Departure date-time should be greater than arrival date-time";
const visitor_depart_date_err_string = "Departure date-time should be greater than current time";

const tagLine = () => isDNhoodEnv() ? "The Digital Neighborhood" : "Your Digital Neighborhood Platform"
const ogCartTagLine = "Stay Local * Buy Local * Help Local";
const ogCareTagLine = "Stay Local * Hire Local * Help Local";

export default {
    tagLine,
    ogCartTagLine,
    ogCareTagLine,
    not_signed_string,
    client_broadcast_declined_string,
    client_broadcast_booked_string,
    client_broadcast_string,
    client_broadcast_sp_string,
    enter_st_date_end_date_string,
    online_string,
    online_broadcast_string,
    offline_broadcast_string,
    offline_string,
    inc_string1,
    inc_string2,
    full_name_constraint,
    compulsory_string1,
    compulsory_string2,
    ans1_string1,
    ans1_string2,
    ans4_string1,
    ans4_string2,
    ans4_string3,
    select_mode,
    get_started,
    select_loc_range,
    select_loc,
    visting_charge_req,
    select_one_serv,
    no_serv,
    submit_new_req,
    expertise_fall_cat_string,
    get_serv_acc,
    profile_pic_string1,
    serv_req,
    profile_pic_string2,
    upload_clear_gumasta,
    upload_clear_img,
    reg_success,
    review_info,
    signin_gruhaps,
    give_feedback,
    help_string,
    report_admin_string,
    enter_zip,
    save_changes_string,
    provide_service,
    service_req_send,
    new_ser_string,
    opinion_string,
    feedback_submit,
    question1,
    question2,
    question3,
    question4,
    ans1,
    ans2,
    ans3,
    no_notif,
    suggestion_string,
    write_us,
    otp_link_sent,
    imp_notif,
    share_button_string,
    add_to_home_screen_string,
    service_grid_string,
    no_address_available_string,
    add_address_string,
    saved_addresses_string,
    max_address_limit_title,
    max_address_limit_desc,
    block_address_removal_title,
    block_address_removal_desc,
    service_not_in_area,
    service_not_in_country_title,
    service_not_in_country_desc,
    service_not_available_area_title,
    service_not_available_area_desc,
    service_not_in_area_string,
    service_not_available_service_category,
    unable_fetch_services,
    upcoming_services_string,
    choose_items_string,
    inpection_item_title,
    inpection_item_desc,
    visiting_charge_applicable,
    charge_applicable,
    unable_to_find_service_items_string,
    cart_further_contact_string,
    cart_booking_confirm_string,
    no_quotation_string,
    no_review_string,
    sp_list_mobile_string,
    sp_list_fixed_string,
    reject_quote_string,
    client_sign_up_string,
    sp_sign_up_string,
    verification_link_expired,
    invalid_parameters,
    not_found_string,
    set_service_range_string,
    schedule_name_already_exist,
    one_day_schedule_string,
    atleast_one_working_day_string,
    went_wrong,
    check_st_date_end_date_string,
    check_st_time_end_time_string,
    check_st_time_string,
    check_end_time_string,
    same_editted_times_string,
    check_rs_time_start_time_string,
    check_rs_time_end_time_string,
    check_rs_time_start_time_end_time_string,
    select_rs_time_dur_string,
    select_working_day_string,
    select_day_string,
    unselect_holiday_string,
    new_schedule,
    new_schedule_constarint,
    update_schedule,
    new_schedule_string,
    create_schedule_string,
    update_schedule_string,
    error_sch_booking_string,
    edit_particular_date_schedule,
    booking_on_particular_date,
    reach_sch_limit,
    reach_sch_limit_string,
    activate_sch_string,
    remove_sch_string,
    no_sch_string,
    active_sch_string,
    remaining_sch_string,
    sp_not_working_string,
    sp_booking_serviced_message,
    sp_serviced_confirmation_string,
    service_time_expired_reschedule1_string,
    service_time_expired_reschedule_string,
    create_sch_without_update_string,
    not_signed_in_string,
    find_peer_sp_string,
    svc_in_svc_cat_string,
    svc_added_string,
    add_svc_details,
    add_svc_to_portfolio,
    change_svc_category_warning,
    book_booked_ts,
    invalid_server_response,
    no_history_description_string,
    no_invoice_string,
    no_invoice,
    successfully_submitted,
    facebook_error_string,
    google_error_string,
    duplicate_add_alert_string,
    login_failed,
    email_string,
    valid_mobile_string,
    valid_email_string,
    valid_email_phone,
    valid_email_phone_pass,
    pass_string,
    dispname_help_string,
    name_string,
    referalcode_help_string,
    referral_code_string,
    mobile_string,
    sp_mobile_string,
    sp_fixed_string,
    discard_changes_alert_string,
    provide_under_category_string,
    review_add_service_string,
    other_in_category_string,
    no_existing_in_category_string,
    new_service_review_gruhap_string,
    every_service_in_category_string,
    portfolio_images_heading_string,
    cl_advisory_bkg_msg,
    sent_admin_request,
    set_bkg_team_string,
    bkg_team_assigned_string,
    booking_string,
    service_duration_string,
    quotation_string,
    invoice_string,
    payment_string,
    review_string,
    update_payment_status,
    invoice_requested_string,
    invoice_not_sent_string,
    invoice_accepted_string,
    invoice_rejected_string,
    booking_canceled_string,
    booking_expired_string,
    booking_client_message_act_1_string,
    booking_client_message_act_2_string,
    booking_sp_message_act_1_string,
    booking_sp_message_act_2_string,
    booking_rejected_string,
    booking_declined_string,
    booking_serviced_string,
    booking_settled_string,
    booking_indispute_string,
    revice_quote_string,
    booking_reschedule_string,
    booking_accepted_string,
    booking_confirmed_string,
    booking_completed_string,
    quotation_rejected_string,
    genrate_quotation_sp,
    request_for_quotation_cl,
    client_requested_quotation_cl,
    client_requested_quotation_sp,
    not_submit_quote_string_sp,
    not_submit_quote_string_cl,
    action_quote_review_string_cl,
    action_quote_string_sp,
    action_quote_string_cl,
    request_for_invoice_cl,
    client_requested_invoice_cl,
    client_requested_invoice_sp,
    action_invoice_review_string_cl,
    action_invoice_string_client,
    not_submit_invoice_string_sp,
    action_raised_invoice_string_sp,
    action_invoice_string_sp,
    revise_quote_string,
    revise_invoice_string,
    booking_not_accepted_string,
    duration_not_defined_string,
    suggested_date_time_string,
    client_suggested_date_time_string,
    sp_suggested_date_time_string,
    view_media_string,
    client_collect_invoice_string,
    sp_generate_invoice_string,
    bkg_expired_quote_string,
    client_give_review_string,
    sp_give_review_string,
    cancel_warning_header,
    cancel_booking_message,
    reject_booking_message,
    cancel_warning_message,
    reschedule_warning_header,
    reschedule_warning_message,
    accept_confirmation_title,
    reschedule_confirmation_title,
    booking_broadcasted_to_sp_string,
    booking_sp_cancel_brodacast_string,
    booking_sp_no_response_brodacast_string,
    broadcast_booking_missed_string,
    broadcast_booking_rejected_string,
    broadcast_booking_expired_string,
    urgent_request_pending,
    attend_service_pending,
    no_work_string,
    slot_already_booked_string,
    no_time_string_client,
    no_time_string_sp,
    no_timeslot_string,
    no_timeslot_string_client,
    no_timeslot_string_sp,
    available_timeslot_string,
    reschedule_timeslot_same_booking_timeslot_string,
    reschedule_timeslot_same_clproposed_timeslot_string_sp,
    reschedule_timeslot_same_clproposed_timeslot_string_client,
    reschedule_timeslot_same_sppropsped_timeslot_string_client,
    reschedule_timeslot_same_sppropsped_timeslot_string_sp,
    show_disable_time_dialog_title,
    selected_timeslot_overlaps_recess_time,
    selected_timeslot_exceeds_end_time,
    reschedule_issue,
    valid_number_string,
    proper_valid_mobile_string,
    display_pass,
    notification_benefits,
    notification_benefits_reasons,
    notification_permission_unavailable_reason,
    notification_permission_unavailable_reasons,
    denied_string,
    allow_permission_string,
    valid_otp_string,
    not_recieved_otp_string,
    change_no_string,
    change_email_string,
    change_prim_phone_string,
    phone_succ_verified,
    email_succ_verified,
    one_time_sms_string,
    picture_upload_title,
    picture_string,
    picture_error_string,
    picture_size_exceeded_title,
    picture_size_exceeded_message,
    confirm_logout_message,
    confirm_logout_string,
    about_you_string,
    profile_intro_req_string,
    work_exp_req_string,
    work_exp_exceeded_string,
    negative_not_allowed,
    market_profile,
    select_mode_string,
    confirm_mode_string,
    mode_string,
    add_store_loc_string,
    store_loc_string,
    added_store_loc_string,
    no_connect_to_server_string,
    add_change_loc_string,
    if_string,
    quote_eligible_string,
    quote_eligible_what_string,
    quote_eligible_explanation_string_p2,
    off_hours_enable_string,
    off_hours_enable_what_string,
    off_hours_enable_explanation_string_p2,
    server_error_string,
    remove_service_alert_string,
    remove_service_alet_title,
    your_current_services,
    add_more_services,
    remove_service_constraint,
    remove_service_success,
    successfully_reg_string,
    selected_services_string,
    select_one_service_string,
    service_selection_guidance,
    service_details_string,
    problem_details_string,
    problem_details_string_constraint,
    problem_details_hint_string,
    upload_content_for_sp_string,
    completed_order_string,
    booking_confirm_string,
    no_history_string,
    no_quotation,
    loading_sp,
    server_error,
    check_form_fields,
    add_To_home,
    find_sp,
    block_notification_string,
    not_found,
    no_active_book,
    not_sign_in,
    sign_gruhap,
    update_portfolio,
    no_schedule_string,
    service_unavailable_area,
    service_unavailable,
    confirm_request_invoice,
    confirm_request_quote,
    client_paid_invoice,
    sp_paid_invoice,
    accept_incoice_string,
    request_invoice_successfull,
    request_quote_successfull,
    an_error_occurred,
    booking_cancel_reasons_client,
    booking_cancel_reasons_sp,
    quotation_reject_reasons,
    invoice_reject_reasons,
    booking_reschedule_reasons_client,
    booking_reschedule_reasons_sp,
    sec_error,
    otp_sent,
    otp_sent_email,
    email_sent,
    otp_parameters_missing,
    pass_change,
    valid_phone,
    not_recieve_link,
    link_on_phone,
    otp_on_staff_phone,
    link_on_email,
    unable_to_send_link,
    forgot_link_email,
    forgot_link_phone,
    link_on_phone_and_email,
    post_success,
    review_req,
    char_req,
    rate_req,
    thankstring,
    string1,
    string2,
    change_phone_string1,
    change_phone_string2,
    change_pass_string1,
    change_pass_string2,
    change_email_string1,
    new_email_string,
    email_ver,
    upload_docs,
    react_error,
    change_phone_string,
    change_phone_string_native,
    NonEditable_string,
    mismatch_pass,
    pass_length,
    check_pass,
    pass_mandatory,
    expString1,
    expString2,
    expString3,
    acnt_suspend_string1,
    acnt_suspend_string2,
    acnt_suspend_string3,
    acnt_suspend_string4,
    contact_us_leave_details_string,
    confirm_send_invoice_to_client,
    confirm_send_quote_to_client,
    cannot_send_empty_invoice,
    invalid_rate_provided,
    cannot_send_empty_quote,
    required_field,
    invoice_disclaimer_string,
    invoice_disclaimer_contact_sp_string,
    error_401_default_string,
    error_404_default_string,
    error_500_default_string,
    rate_factor_dialog_title,
    rate_factor_dialog_text,
    rate_factor_text,
    Select_address,
    no_location,
    no_location_step,
    check_referal,
    check_realm,
    create_schedule,
    share_ref_string,
    rate_factor_note,
    rate_factor_example_sp,
    minimum_visiting_charge_explanation,
    click_here,
    not_signed_in_string1,
    not_signed_in_string2,
    not_signed_in_string3,
    not_signed_in_string4,
    not_signed_in_string5,
    not_signed_in_string6,
    join_gruhaps_string,
    send_verification,
    phone_email_string,
    email_max_limit_reached,
    terms_condition,
    gdpr_compliance_string,
    no_notification_string1,
    no_notification_string2,
    no_notification_string3,
    no_notification_string4,
    no_notification_string5,
    notification_permission_unblock_string1,
    notification_permission_unblock_string2,
    notification_permission_unblock_string3,
    notification_permission_unblock_string4,
    notification_permission_unblock_string5,
    notification_step_string1,
    notification_step_string2,
    notification_step_string3,
    notification_step_string4,
    notification_step_string5,
    notification_step_string6,
    notification_step_string7,
    notification_step_string8,
    valid_zip,
    book_service,
    no_active_string,
    no_sp,
    not_signed_string1,
    not_signed_string2,
    property_address,
    photo_id_p1,
    photo_id_p2,
    id_purpose,
    complete_registration_message,
    updating_language,
    discount_offered_system,
    discount_offered_sp,
    discount_offered,
    discount_reimburse_as_reward,
    discount_required_err_string,
    discount_select_type_err_string,
    discount_code_min_max_err_string,
    discount_amount_min_0_err_string,
    discount_amount_max_err_string,
    discount_max_amount_min_0_err_string,
    discount_max_amount_max_inv_err_string,
    discount_max_amount_pct_min_0_err_string,
    discount_max_amount_pct_max_err_string,
    discount_max_amount_max_err_string,
    discount_max_times_min_0_err_string,
    discount_max_times_max_err_string,
    discount_start_date_err_string,
    discount_end_date_start_date_err_string,
    discount_end_date_err_string,
    discount_share_pct_string,
    discount_share_abs_string,
    discount_share_start_date_string,
    discount_share_book_string,
    discount_type_automatic_msg,
    discount_type_manual_msg,
    discount_abs_string,
    discount_pct_string,
    discount_upto_string,
    discount_code_not_applicable_string,

    no_credits_booking_action_string,
    no_credits_booking_accept_string,
    no_credits_booking_reschedule_string,
    low_credits_booking_action_string,
    low_credits_booking_accept_string,
    low_credits_booking_reschedule_string,
    sorry_less_credits_action_booking_string,
    sorry_less_credits_accept_booking_string,
    sorry_less_credits_reschedule_booking_string,

    sp_cancelled_payment_string,
    purchase_successful_string,
    do_not_refresh_payment_string,
    can_take_few_minutes_string,
    retrying_payment_request_string,
    payment_failed_string,
    payment_wrong_admin_string,

    err_buy_credits_string,
    err_buy_only_credits_string,
    err_buy_only_bulk_credits_string,
    err_min_buy_credits_string,
    err_max_buy_credits_string,
    err_max_buy_credits_bulk_buy_string,
    err_plan_changed_string,

    booking_expired_reschedule_expire_messagesp,
    booking_expired_reschedule_expire_messagecl,

    booking_expired_reschedule_expire_message1cl,
    booking_expired_reschedule_expire_message1sp,

    booking_expired_reschedule_expire_message2cl,
    booking_expired_reschedule_expire_message2sp,

    booking_expired_reschedule_expire_message3cl,
    booking_expired_reschedule_expire_message3sp,

    booking_expired_reschedule_expire_message4cl,
    booking_expired_reschedule_expire_message4sp,

    reschedule_request_sp_message_cl,
    reschedule_request_cl_message_sp,

    reschedule_expire_message,

    reschedule_expire_message1cl,
    reschedule_expire_message1sp,

    reschedule_expire_message2cl,
    reschedule_expire_message2sp,

    reschedule_expire_message3cl,
    reschedule_expire_message3sp,

    reschedule_expire_message4cl,
    reschedule_expire_message4sp,
    resch_expire,

    enter_url_string,
    minimum_url_string,
    improper_format_url,
    maximum_url_string,
    url_not_available,
    premium_custom_url_text,
    create_public_url,
    public_url_constraints,
    url_checking_availability,
    available_string,
    unavailable_string,
    refer_below_video_public_url,
    find_public_url,
    find_public_url_path,

    error_chunk_string,
    timeout_chunk_string,
    select_address_service,
    service_address_unavailable,
    sp_address_unavailable,
    upcoming_services_registration,
    teams_size_desc_string,

    no_entry_pass_family_resident_string,
    no_entry_pass_family_non_resident_string,
    no_entry_pass_regular_visitor_string,
    no_entry_pass_staff_string,

    visitor_arrival_date_err_string,
    visitor_depart_date_arrival_date_err_string,
    visitor_depart_date_err_string,
};