import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { clearSocietyMsgBoardCache } from 'actions'

import SectionHeader from '../../section-header'
import SectionHorizontalScroll from '../../section-horizontal-scroll'

import SectionItemPostPreview, { SectionItemEmptyPostPreview, SectionItemPremiumPostPreview } from '../../section-item-post-preview'

import { societyMsgBoardRoute } from 'routes-parameters/config';

function SectionNoticeBoard({ history, notices, msgTypeLabels, feedTypes, clearSocietyMsgBoardCache, title, disable }) {
    return (
        <div>
            <SectionHeader
                title={title || "Board Feed"}
                onActionClick={() => {
                    clearSocietyMsgBoardCache()
                    history.push(`/${societyMsgBoardRoute}`)
                }}
            />
            <SectionHorizontalScroll
                data={renderData(notices, msgTypeLabels, history, feedTypes, disable)}
                maxHorizontalItems={1}
            />
        </div>
    )
}

function renderData(notices, msgTypeLabels, history, feedTypes, disable) {
    if (disable) {
        return [<SectionItemPremiumPostPreview history={history} route={societyMsgBoardRoute} key="Premium" />]
    }

    if (notices && notices.length > 0) {
        return notices.map(data =>
            <SectionItemPostPreview
                key={data.msgb_id}
                {...data}
                fullWidth={notices.length === 1}
                msgTypeLabels={msgTypeLabels}
                history={history}
                route={societyMsgBoardRoute}
                feedTypes={feedTypes}
            />
        )
    }

    return [<SectionItemEmptyPostPreview key="Empty" />]
}

function mapStateToProps({ user_home_screen }) {
    const { notice_board, boardFeedTypes } = user_home_screen;

    return {
        notices: notice_board || [],
        feedTypes: boardFeedTypes
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ clearSocietyMsgBoardCache }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(SectionNoticeBoard)