import { REHYDRATE } from 'redux-persist'

import ApiError, { handleNetworkError } from '../../utils/api-error'
import {
    LOGOUT_RESET, SOCIETY_SETTLEMENTS, SOCIETY_SETTLEMENTS_ERROR,
    SOCIETY_SETTLEMENTS_MORE, SOCIETY_SETTLEMENTS_SCROLL, SOCIETY_SETTLEMENTS_SUCCESS, SOCIETY_SETTLEMENTS_CLEAR_CACHE
} from '../../actions/types';
import { convertMomentObjectToUTCISOString } from 'utils/date-time-conversion';

const INITIAL_STATE = {
    settlement_list: [],
    pending_settlement_amount: null,
    cache: null,
    last_created_at: undefined,
    can_load_more: false,
    scroll_offset: 0,
    error: undefined,
    loading: true,
    is_logout: false
}

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        // on hard reload, call api - so clearing cache
        case REHYDRATE: if (action.payload && action.payload.society_settlements) return { ...action.payload.society_settlements, cache: INITIAL_STATE.cache }; else return INITIAL_STATE;

        case SOCIETY_SETTLEMENTS: return { ...INITIAL_STATE, loading: true };
        case SOCIETY_SETTLEMENTS_MORE: return { ...state, loading: true, is_logout: false };

        case SOCIETY_SETTLEMENTS_SUCCESS:
            const apiData = action.payload && action.payload.apiData;
            const cache = action.payload && action.payload.cache;
            const batchSize = action.payload && action.payload.batchSize;

            if (apiData !== undefined && apiData !== null) {
                let settlement_list = ApiError(apiData.statusCode, apiData.message, apiData.gsRespData, "settlements", []) || [];

                if (settlement_list && settlement_list.constructor === Array) {
                    const gsRespData = apiData.gsRespData || {};
                    let pending_settlement_amount = gsRespData.unsettled_amount || gsRespData.unsettled_amount === 0 ? gsRespData.unsettled_amount : state.pending_settlement_amount;

                    const state_list = state.settlement_list && state.settlement_list.constructor === Array ? state.settlement_list : [];
                    const req_list = [...state_list, ...settlement_list];

                    let last_created_at = undefined;
                    if (req_list.length > 0) {
                        if (req_list[req_list.length - 1].createdat) {
                            last_created_at = req_list[req_list.length - 1].createdat;
                            last_created_at = convertMomentObjectToUTCISOString(0, last_created_at, null, null, true);
                        }
                    }

                    let loadMore = false;
                    if (settlement_list.length === batchSize) {
                        loadMore = true;
                    }


                    return { ...state, pending_settlement_amount, loading: false, is_logout: false, settlement_list: req_list, cache, last_created_at, can_load_more: loadMore }

                } else {
                    return { ...state, loading: false, is_logout: false, error: settlement_list, can_load_more: false };
                }

            } else {
                return { ...state, loading: false, is_logout: false };
            }

        case SOCIETY_SETTLEMENTS_ERROR:
            let hNE_res = handleNetworkError(action);
            if (hNE_res.error)
                return { ...INITIAL_STATE, ...hNE_res, loading: false, is_logout: false };
            else if (action.payload && action.payload.response && action.payload.response.data && action.payload.response.data.error) {
                if (action.payload.response.data.error.statusCode === 401)
                    return { ...INITIAL_STATE, error: action.payload.response.data.error, loading: false, is_logout: true };
                else
                    return { ...INITIAL_STATE, error: action.payload.response.data.error, loading: false, is_logout: false };
            }
            else
                return { ...INITIAL_STATE, error: action.payload, loading: false, is_logout: false };

        case SOCIETY_SETTLEMENTS_SCROLL:
            return { ...state, scroll_offset: action.payload.scroll_offset };

        case SOCIETY_SETTLEMENTS_CLEAR_CACHE:
            return { ...state, cache: INITIAL_STATE.cache };

        case LOGOUT_RESET:
            return { ...INITIAL_STATE };

        default: return state;
    }
}