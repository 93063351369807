import React from 'react';
import { visitor_types } from '../visitor/config';
import gs_color from 'config/colors.css';

export const notification_type = {
    property: 'PropertyMsg',
    boardMember: 'SocietyBoardMsg',
    society: 'SocietyMsg',
    gatekeeper: 'GatekeeperMsg',
    property_payment: 'PropertyPaymentMsg',
    client: 'ClientMsg'
};

export const type = {
    PROPERTY_EXPECTED_VISITOR: 'PROPERTY_EXPECTED_VISITOR',
    PROPERTY_UNEXPECTED_VISITOR: 'PROPERTY_UNEXPECTED_VISITOR',
    PROPERTY_UNEXPECTED_VISITOR_VERIFICATION: 'PROPERTY_UNEXPECTED_VISITOR_VERIFICATION',
    SOCIETY_BOARD_NEW_GK_REQUEST: 'SOCIETY_BOARD_NEW_GK_REQUEST',
    SOCIETY_BOARD_NEW_TXN_PENDING: 'SOCIETY_BOARD_NEW_TXN_PENDING',
    PROPERTY_CHILD_EXIT_REQUEST: 'PROPERTY_CHILD_EXIT_REQUEST',
    PROPERTY_CHILD_EXIT_REQUEST_VERIFICATION: 'PROPERTY_CHILD_EXIT_REQUEST_VERIFICATION',
    PROPERTY_CHILD_EXIT_NOTIFY: 'PROPERTY_CHILD_EXIT_NOTIFY',
    PROPERTY_CHILD_ENTRY_NOTIFY: 'PROPERTY_CHILD_ENTRY_NOTIFY',
    SOCIETY_BOARD_NEW_STAFF_REQUEST: 'SOCIETY_BOARD_NEW_STAFF_REQUEST',
    PROPERTY_EINTERCOM: 'PROPERTY_EINTERCOM',
    SOCIETY_BOARD_EMEETING: 'SOCIETY_BOARD_EMEETING',
    SOCIETY_GENERAL_EMEETING: 'SOCIETY_GENERAL_EMEETING',
    SOCIETY_BOARD_NEW_RS_REQUEST: 'SOCIETY_BOARD_NEW_RS_REQUEST',
    PROPERTY_RESIDENT_VERIFICATION: 'PROPERTY_RESIDENT_VERIFICATION',
    SOCIETY_MSG_BOARD: 'SOCIETY_MSG_BOARD',
    SOCIETY_MSG_BOARD_COMMENT: 'SOCIETY_MSG_BOARD_COMMENT',
    SOCIETY_NEW_COMPLAINT: 'SOCIETY_NEW_COMPLAINT',
    SOCIETY_COMPLAINT_ACTION: 'SOCIETY_COMPLAINT_ACTION',
    SOCIETY_EINTERCOM_UPDATE: 'SOCIETY_EINTERCOM_UPDATE',
    GATEKEEPER_EMERGENCY_ALERT: 'GATEKEEPER_EMERGENCY_ALERT',
    SOCIETY_NEW_POLL: 'SOCIETY_NEW_POLL',
    USER_EMERGENCY_ALERT: 'USER_EMERGENCY_ALERT',
    PROPERTY_NEW_PARCEL_FDESK: 'PROPERTY_NEW_PARCEL_FDESK',
};

export const visit_status = {
    ACTIVE: "active",
    PENDING: "pending",
    REJECTED: "rejected"
}

const status = {
    ACTIVE: "active",
    PENDING: "pending",
    REJECTED: "rejected",
    REMOVED: "removed"
}

export function getNotificationString(notificationItem, userId) {
    const TYPE = notificationItem.type ? notificationItem.type.toUpperCase() : "";

    if (TYPE === type.SOCIETY_BOARD_NEW_TXN_PENDING) {
        let first = "New transaction reported"
        let second = <span style={{ color: gs_color.primaryColor }}>approval pending</span>

        if (notificationItem.verified_by) {
            if (notificationItem.status === status.ACTIVE) {
                second = <span style={{ color: '#43A047' }}>
                    {`approved by ${notificationItem.verified_by === userId ? "you" : notificationItem.verified_by_name}`}
                </span>
            } else if (notificationItem.status === status.REJECTED) {
                second = <span style={{ color: '#e53935' }}>
                    {`rejected by ${notificationItem.verified_by === userId ? "you" : notificationItem.verified_by_name}`}
                </span>
            } else if (notificationItem.status === status.REMOVED) {
                second = <span style={{ color: '#e53935' }}>
                    {`removed by ${notificationItem.verified_by === userId ? "you" : notificationItem.verified_by_name}`}
                </span>
            }
        }

        return (
            <span>
                {first}, {second}
            </span>
        )
    } else if (TYPE === type.PROPERTY_UNEXPECTED_VISITOR) {
        let first = "Visitor is at the gate"
        let second = <span style={{ color: gs_color.primaryColor }}>approval pending</span>
        if (notificationItem.visitor_type === visitor_types.guest) {
            if (notificationItem.visitor_name) {
                first = notificationItem.visitor_name + " (Guest)" + " at the gate"
            }
        } else if (notificationItem.visitor_type === visitor_types.guest) {
            if (notificationItem.delivery_type) {
                first = notificationItem.delivery_type + " (Delivery)" + " at the gate"
            }
        } else if (notificationItem.visitor_type === visitor_types.cab) {
            if (notificationItem.visitor_name) {
                first = "Cab is at the gate"
            }
        }

        if (notificationItem.verified_by) {
            if (notificationItem.visit_status === visit_status.ACTIVE) {
                second = <span style={{ color: '#43A047' }}>
                    {`approved by ${notificationItem.verified_by === userId ? "you" : notificationItem.verified_by_name}`}
                </span>
            } else if (notificationItem.visit_status === visit_status.REJECTED) {
                second = <span style={{ color: '#e53935' }}>
                    {`rejected by ${notificationItem.verified_by === userId ? "you" : notificationItem.verified_by_name}`}
                </span>
            }
        }

        return (
            <span>
                {first}, {second}
            </span>
        )
    } else if (TYPE === type.SOCIETY_BOARD_NEW_GK_REQUEST) {
        let first = "New Gatekeeper request"
        let second = <span style={{ color: gs_color.primaryColor }}>approval pending</span>
        if (notificationItem.person_name) {
            first = notificationItem.person_name + " - " + first;
        }

        if (notificationItem.verified_by) {
            if (notificationItem.status === status.ACTIVE) {
                second = <span style={{ color: '#43A047' }}>
                    {`approved by ${notificationItem.verified_by === userId ? "you" : notificationItem.verified_by_name}`}
                </span>
            } else if (notificationItem.status === status.REJECTED) {
                second = <span style={{ color: '#e53935' }}>
                    {`rejected by ${notificationItem.verified_by === userId ? "you" : notificationItem.verified_by_name}`}
                </span>
            } else if (notificationItem.status === status.REMOVED) {
                second = <span style={{ color: '#e53935' }}>
                    {`removed by ${notificationItem.verified_by === userId ? "you" : notificationItem.verified_by_name}`}
                </span>
            }
        }

        return (
            <span>
                {first}, {second}
            </span>
        )
    } else if (TYPE === type.PROPERTY_CHILD_EXIT_REQUEST) {
        let first = "Child is trying to leave society"
        let second = <span style={{ color: gs_color.primaryColor }}>approval pending</span>

        if (notificationItem.verified_by) {
            if (notificationItem.visit_status === visit_status.ACTIVE) {
                second = <span style={{ color: '#43A047' }}>
                    {`approved by ${notificationItem.verified_by === userId ? "you" : notificationItem.verified_by_name}`}
                </span>
            } else if (notificationItem.visit_status === visit_status.REJECTED) {
                second = <span style={{ color: '#e53935' }}>
                    {`rejected by ${notificationItem.verified_by === userId ? "you" : notificationItem.verified_by_name}`}
                </span>
            }
        }

        return (
            <span>
                {first}, {second}
            </span>
        )

    } else if (TYPE === type.SOCIETY_BOARD_NEW_STAFF_REQUEST) {
        let first = "New Staff Request"
        let second = <span style={{ color: gs_color.primaryColor }}>approval pending</span>
        if (notificationItem.person_name) {
            first = notificationItem.person_name + " - " + first;
        }

        if (notificationItem.verified_by) {
            if (notificationItem.status === status.ACTIVE) {
                second = <span style={{ color: '#43A047' }}>
                    {`approved by ${notificationItem.verified_by === userId ? "you" : notificationItem.verified_by_name}`}
                </span>
            } else if (notificationItem.status === status.REJECTED) {
                second = <span style={{ color: '#e53935' }}>
                    {`rejected by ${notificationItem.verified_by === userId ? "you" : notificationItem.verified_by_name}`}
                </span>
            } else if (notificationItem.status === status.REMOVED) {
                second = <span style={{ color: '#e53935' }}>
                    {`removed by ${notificationItem.verified_by === userId ? "you" : notificationItem.verified_by_name}`}
                </span>
            }
        }

        return (
            <span>
                {first}, {second}
            </span>
        )

    } else if (TYPE === type.SOCIETY_BOARD_NEW_RS_REQUEST) {
        let first = "New Resident Request"
        let second = <span style={{ color: gs_color.primaryColor }}>approval pending</span>
        if (notificationItem.person_name) {
            first = notificationItem.person_name + " - " + first;
        }

        if (notificationItem.verified_by) {
            if (notificationItem.status === status.ACTIVE) {
                second = <span style={{ color: '#43A047' }}>
                    {`approved by ${notificationItem.verified_by === userId ? "you" : notificationItem.verified_by_name}`}
                </span>
            } else if (notificationItem.status === status.REJECTED) {
                second = <span style={{ color: '#e53935' }}>
                    {`rejected by ${notificationItem.verified_by === userId ? "you" : notificationItem.verified_by_name}`}
                </span>
            }
        }

        return (
            <span>
                {first}, {second}
            </span>
        )

    } else if (TYPE === type.SOCIETY_MSG_BOARD) {
        let first = <span>New <span style={{ color: gs_color.primaryColor }}>{notificationItem.msgb_type}</span> post on message board</span>

        return (
            <span>
                {first}
            </span>
        )

    } else if (TYPE === type.SOCIETY_MSG_BOARD_COMMENT) {
        let first = "New comment on your post"
        let second = <span style={{ color: gs_color.primaryColor }}>by {notificationItem.msgb_commented_by_name}</span>

        return (
            <span>
                {first} {second}
            </span>
        )

    } else if (TYPE === type.SOCIETY_COMPLAINT_ACTION) {
        let first = "New update on"
        let second = <span style={{ color: gs_color.primaryColor }}>: Ticket Id - #{notificationItem.complaint_ticket_id}</span>

        return (
            <span>
                {first} {second}
            </span>
        )

    } else if (TYPE === type.SOCIETY_NEW_COMPLAINT) {
        let first = notificationItem.complaint_is_assigned ? "New ticket posted" : "No assignee found for this ticket"
        let second = <span style={{ color: gs_color.primaryColor }}>: Ticket Id - #{notificationItem.complaint_ticket_id}</span>

        return (
            <span>
                {first} {second}
            </span>
        )

    } else if (TYPE === type.SOCIETY_NEW_POLL) {
        let first = notificationItem.poll_title ? notificationItem.poll_title : ""
        let second = <span>New poll created</span>

        return (
            <span>
                {first} {second}
            </span>
        )

    } else if (TYPE === type.PROPERTY_NEW_PARCEL_FDESK) {
        let first = "New mail / package at front desk. Please collect it."

        return (
            <span>
                {first}
            </span>
        )

    } else {
        return "";
    }
}