import { REHYDRATE } from 'redux-persist'

import ApiError, { handleNetworkError } from '../../utils/api-error'
import {
    LOGOUT_RESET, PROPERTY_TRANSACTIONS, PROPERTY_TRANSACTIONS_MORE, PROPERTY_TRANSACTIONS_SUCCESS,
    PROPERTY_TRANSACTIONS_ERROR, PROPERTY_TRANSACTIONS_SCROLL, PROPERTY_TRANSACTIONS_CLEAR_CACHE
} from '../../actions/types';
import { convertMomentObjectToUTCISOString } from 'utils/date-time-conversion';

const INITIAL_STATE = {
    transaction_list: [],
    balance: null,
    debit_sum: null,
    credit_sum: null,
    cache: null,
    last_created_at: undefined,
    can_load_more: false,
    scroll_offset: 0,
    error: undefined,
    loading: true,
    is_logout: false
}

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        // on hard reload, call api - so clearing cache
        case REHYDRATE: if (action.payload && action.payload.property_transactions) return { ...action.payload.property_transactions, cache: INITIAL_STATE.cache }; else return INITIAL_STATE;

        case PROPERTY_TRANSACTIONS: return { ...INITIAL_STATE, loading: true };
        case PROPERTY_TRANSACTIONS_MORE: return { ...state, loading: true, is_logout: false };

        case PROPERTY_TRANSACTIONS_SUCCESS:
            const apiData = action.payload && action.payload.apiData;
            const cache = action.payload && action.payload.cache;
            const batchSize = action.payload && action.payload.batchSize;

            if (apiData !== undefined && apiData !== null) {
                let transaction_list = ApiError(apiData.statusCode, apiData.message, apiData.gsRespData, "transactions", []);

                if (transaction_list && transaction_list.constructor === Array) {
                    const gsRespData = apiData.gsRespData || {};
                    let balance = gsRespData.balance || gsRespData.balance === 0 ? gsRespData.balance : state.balance;
                    let debit_sum = gsRespData.debit_sum || gsRespData.debit_sum === 0 ? gsRespData.debit_sum : state.debit_sum;
                    let credit_sum = gsRespData.credit_sum || gsRespData.credit_sum === 0 ? gsRespData.credit_sum : state.credit_sum;

                    const state_list = state.transaction_list && state.transaction_list.constructor === Array ? state.transaction_list : [];
                    const req_list = [...state_list, ...transaction_list];

                    let last_created_at = undefined;
                    if (req_list.length > 0) {
                        if (req_list[req_list.length - 1].createdat) {
                            last_created_at = req_list[req_list.length - 1].createdat;
                            last_created_at = convertMomentObjectToUTCISOString(0, last_created_at, null, null, true);
                        }
                    }

                    let loadMore = false;
                    if (transaction_list.length === batchSize) {
                        loadMore = true;
                    }


                    return { ...state, balance, debit_sum, credit_sum, loading: false, is_logout: false, transaction_list: req_list, cache, last_created_at, can_load_more: loadMore }

                } else {
                    return { ...state, loading: false, is_logout: false, error: transaction_list, can_load_more: false };
                }

            } else {
                return { ...state, loading: false, is_logout: false };
            }

        case PROPERTY_TRANSACTIONS_ERROR:
            let hNE_res = handleNetworkError(action);
            if (hNE_res.error)
                return { ...INITIAL_STATE, ...hNE_res, loading: false, is_logout: false };
            else if (action.payload && action.payload.response && action.payload.response.data && action.payload.response.data.error) {
                if (action.payload.response.data.error.statusCode === 401)
                    return { ...INITIAL_STATE, error: action.payload.response.data.error, loading: false, is_logout: true };
                else
                    return { ...INITIAL_STATE, error: action.payload.response.data.error, loading: false, is_logout: false };
            }
            else
                return { ...INITIAL_STATE, error: action.payload, loading: false, is_logout: false };

        case PROPERTY_TRANSACTIONS_SCROLL:
            return { ...state, scroll_offset: action.payload.scroll_offset };

        case PROPERTY_TRANSACTIONS_CLEAR_CACHE:
            return { ...state, cache: INITIAL_STATE.cache };

        case LOGOUT_RESET:
            return { ...INITIAL_STATE };

        default: return state;
    }
}