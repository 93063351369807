import axios from 'middleware/axios';
import { API_LINK } from 'config/config';
import { cl_access_token } from 'config/storage-variables';

export function editPropertyDetails(editProperty, cb) {
    let access_token = localStorage.getItem(cl_access_token);
    axios.post(`${API_LINK}/gs_Community/gs_postEditCommunityPropertyDetails?access_token=${access_token}`, editProperty)
        .then(res => {
            if (res && res.data && res.data.statusCode.toString().slice(0, 2) == 20) {
                cb(res.data.gsRespData, null);
            } else {
                cb(null, res.data);
            }
        })
        .catch(err => {
            if (err.response && err.response.data && err.response.data.error) {
                if (err.response.data.error.statusCode === 401) {
                    cb(null, { ...err.response.data.error, is_logout: true });
                } else {
                    cb(null, err.response.data.error);
                }
            } else {
                cb(null, err);
            }
        })
}

export function migrateUserProperty(migrateUserPropDetails, cb) {
    let access_token = localStorage.getItem(cl_access_token);
    axios.post(`${API_LINK}/gs_Community/gs_postMigrateUserCommunityProperty?access_token=${access_token}`, migrateUserPropDetails)
        .then(res => {
            if (res && res.data && res.data.statusCode.toString().slice(0, 2) == 20) {
                cb(res.data.gsRespData, null);
            } else {
                cb(null, res.data);
            }
        })
        .catch(err => {
            if (err.response && err.response.data && err.response.data.error) {
                if (err.response.data.error.statusCode === 401) {
                    cb(null, { ...err.response.data.error, is_logout: true });
                } else {
                    cb(null, err.response.data.error);
                }
            } else {
                cb(null, err);
            }
        })
}

export function resubmitPropertyDetails(data, cb) {
    let access_token = localStorage.getItem(cl_access_token);
    axios.post(`${API_LINK}/gs_Community/gs_postResubmitCommunityPropertyDetails?access_token=${access_token}`, data)
        .then(res => {
            if (res && res.data && res.data.statusCode.toString().slice(0, 2) == 20) {
                cb(res.data.gsRespData, null);
            } else {
                cb(null, res.data);
            }
        })
        .catch(err => {
            if (err.response && err.response.data && err.response.data.error) {
                if (err.response.data.error.statusCode === 401) {
                    cb(null, { ...err.response.data.error, is_logout: true });
                } else {
                    cb(null, err.response.data.error);
                }
            } else {
                cb(null, err);
            }
        })
}