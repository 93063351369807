
import AboutUsIcon from 'material-ui/svg-icons/action/info';
import HomeIcon from '../../../../../components/bottom-navigation/assets/home.svg';
import HomeSelIcon from '../../../../../components/bottom-navigation/assets/home-sel.svg';
import ProfileIcon from '../../../../../components/bottom-navigation/assets/user.svg';
import ProfileSelIcon from '../../../../../components/bottom-navigation/assets/user-sel.svg';
import OrdersIcon from '../../../../../components/bottom-navigation/assets/shopping-bag.svg';
import OrdersSelIcon from '../../../../../components/bottom-navigation/assets/shopping-bag-sel.svg';
import BookingsIcon from '../../../../../components/bottom-navigation/assets/booking.svg';
import BookingsSelIcon from '../../../../../components/bottom-navigation/assets/booking-sel.svg';
import EduIcon from '../../../../../components/bottom-navigation/assets/online-course.svg';
import EduSelIcon from '../../../../../components/bottom-navigation/assets/online-course-sel.svg';
import ExploreIcon from '../../../../../components/bottom-navigation/assets/marker.svg';
import ExploreSelIcon from '../../../../../components/bottom-navigation/assets/marker-sel.svg';
import FoodIcon from '../../../../../components/bottom-navigation/assets/grocery.svg';
import FoodSelIcon from '../../../../../components/bottom-navigation/assets/grocery-sel.svg';
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons';

import {
    aboutUsRoute, homeRoute, profileRoute, bookingRoute,
    spBookingRoute, spHomeRoute, spProfileRoute, timeslotRoute,
    contactUsRoute, productsRoute, ordersRoute, exploreRoute,
    ogcartRoute, ogcareRoute, exploreAppsRoute, ogfoodRoute,
} from 'routes-parameters/config';
import { isDNhoodEnv } from 'utils/helper-functions';
import { cl_signedIn } from 'config/storage-variables';

const menu = "menu";
const cta = "call-to-action";

export const navMenuType = {
    menu,
    cta
};

function clientItemData(is_ogcart, is_ogcare) {
    const is_signed_in = localStorage.getItem(cl_signedIn) === 'true';
    const is_dnhood_env = isDNhoodEnv();

    let tabs_data = [];
    tabs_data.push({
        title: "Home",
        svgIcon: HomeIcon,
        svgSelIcon: HomeSelIcon,
        route: is_ogcart ? ogcartRoute : is_ogcare ? ogcareRoute : homeRoute,
        type: menu,
        cssId: is_signed_in ? 'home' : 'not-signed-home'
    });
    if (!(is_ogcart || is_ogcare)) {
        tabs_data.push({
            title: "Neighborhood",
            svgIcon: ExploreIcon,
            svgSelIcon: ExploreSelIcon,
            route: is_ogcart || is_ogcare ? exploreAppsRoute : exploreRoute,
            type: menu,
            cssId: 'explore',
        });
        // tabs_data.push({
        //     title: "Food",
        //     svgIcon: FoodIcon,
        //     svgSelIcon: FoodSelIcon,
        //     route: ogfoodRoute,
        //     type: menu,
        //     cssId: 'food',
        // });
    }
    if (is_dnhood_env) {
        tabs_data.push({
            title: "KidsEdu",
            svgIcon: EduIcon,
            svgSelIcon: EduSelIcon,
            route: productsRoute,
            type: menu,
            cssId: 'kidsEdu'
        });
        tabs_data.push({
            title: "Orders",
            svgIcon: OrdersIcon,
            svgSelIcon: OrdersSelIcon,
            route: ordersRoute,
            type: menu,
            cssId: 'orders'
        });
    }
    else if (is_ogcart || is_ogcare) {
        tabs_data.push({
            title: "Orders",
            svgIcon: OrdersIcon,
            svgSelIcon: OrdersSelIcon,
            route: ordersRoute,
            type: menu,
            cssId: 'orders'
        });
        tabs_data.push({
            title: "Bookings",
            svgIcon: BookingsIcon,
            svgSelIcon: BookingsSelIcon,
            route: bookingRoute,
            type: menu,
            cssId: 'bookings'
        });
    }
    tabs_data.push({
        title: is_signed_in ? "Account" : "Sign In / Register",
        svgIcon: ProfileIcon,
        svgSelIcon: ProfileSelIcon,
        route: profileRoute,
        type: menu,
        cssId: is_signed_in ? 'account' : 'not-signed-account'
    });

    return tabs_data;
}

const spItemData = [
    {
        title: "Home",
        svgIcon: HomeIcon,
        svgSelIcon: HomeSelIcon,
        route: spHomeRoute,
        type: menu,
        cssId: 'sp-home'
    },
    {
        title: "Bookings",
        svgIcon: OrdersIcon,
        svgSelIcon: OrdersSelIcon,
        route: spBookingRoute,
        type: menu,
        cssId: 'sp-bookings'
    },
    {
        title: "Calendar",
        faIcon: faCalendarAlt,
        route: timeslotRoute,
        type: menu,
        cssId: 'calendar'
    },
    {
        title: "Account",
        svgIcon: ProfileIcon,
        svgSelIcon: ProfileSelIcon,
        route: spProfileRoute,
        type: menu,
        cssId: 'sp-account'
    },
    {
        title: "About Us",
        icon: AboutUsIcon,
        route: aboutUsRoute,
        type: menu,
        cssId: 'about-us'
    }
]

const minorItemData = [
    {
        title: "Contact Us",
        route: contactUsRoute
    }
]

export default {
    clientItemData,
    spItemData,
    minorItemData
}