import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getUserAddresses } from 'actions';

import TextField from 'material-ui/TextField';
import SelectField from 'material-ui/SelectField';
import MenuItem from 'material-ui/MenuItem';
import Chip from 'material-ui/Chip';
import Avatar from 'material-ui/Avatar';
import RaisedButton from 'material-ui/RaisedButton';
import CircularProgress from 'material-ui/CircularProgress';
import Dialog from 'material-ui/Dialog';
import FlatButton from 'material-ui/FlatButton';

import AddIcon from 'material-ui/svg-icons/content/add';

import MyAppBar from 'components/app-bar';
import BackButton from 'components/back-button';
import SelectProperty from '../../../transactions/components/add-transaction/SelectProperty';
import { cl_selected_society_property, cl_call_prop_api, cl_user_id } from 'config/storage-variables';
import { unit_detail_types, resident_types, property_types, occupancy_types } from '../../../select-society/scenes/register-property/config';
import { addBoardMemberObjectAdapter } from '../../../board-members/components/add-board-member/util';
import { homeRoute } from 'routes-parameters/config';
import { UNITNUMLIMITMAX } from 'config/config';
import { editPropertyDetails, migrateUserProperty, resubmitPropertyDetails } from './api';
import { reportDispute } from '../../../select-society/scenes/register-property/api';
import { styles } from '../../../select-society/scenes/register-property/styles';
import gs_color from 'config/colors.css';
import { isSocietySchemaExists } from '../../../../utils';

const Compulsory = <span style={{ color: '#D32F2F', fontSize: '16px', display: 'inline-block' }} >*</span>;

const residentTypes = [
    {
        type: "",
        label: "Select One"
    },
    ...resident_types
]
const propertyTypes = [
    {
        type: "",
        label: "Select One"

    },
    ...property_types
]
const occupancyTypes = [
    {
        type: "",
        label: "Select One"

    },
    ...occupancy_types
]

const unitDetails = unit_detail_types.map(detail => {
    return { number: "", ...detail }
})

class EditProperty extends PureComponent {
    constructor(props) {
        super(props)

        let initial_selected_prop_details = {};
        if (isSocietySchemaExists(props.comm_schema_fields)) {
            let prop_obj = {};
            if (this.props.propDetails) {
                prop_obj = {
                    prop_id: this.props.propDetails.prop_id,
                    prop_unit_name: this.props.propDetails.prop_unit_name,
                    prop_type: this.props.propDetails.prop_type,
                    prop_details: this.props.propDetails.prop_details
                }
            }

            initial_selected_prop_details = { ...addBoardMemberObjectAdapter(prop_obj), prop_unit_name: prop_obj.prop_unit_name, prop_details: prop_obj.prop_details };
        }

        this.state = {
            propId: this.props.propDetails.prop_id,
            newPropId: null,
            unit_number: this.props.propDetails.prop_unit_name ? this.props.propDetails.prop_unit_name : "",
            unit_number_error: "",
            property_type: this.props.propDetails.prop_type ? this.props.propDetails.prop_type : "",
            property_type_error: "",
            resident_type: this.props.residentType ? this.props.residentType : "",
            resident_type_error: "",
            occupancy_type: this.props.propDetails.occpy_type ? this.props.propDetails.occpy_type : "",
            occupancy_type_error: "",
            selected_unit_details: this.parseUnitDetailsFromProps(),
            submitLoader: false,
            showDisputeDialog: false,
            disputeMessage: "",
            showErrorDialog: false,
            errorMessage: "",
            disputeApiLoader: false,
            prop_details: initial_selected_prop_details,
            open_select_prop: false,
        }
    }

    handleHashChange = () => {
        if (window.location.hash !== "#selectProperty")
            this.setState({ open_select_prop: false });
        else if (window.location.hash === "#selectProperty")
            this.setState({ open_select_prop: true });
    }

    componentDidMount() {
        if (window.location.hash === "#selectProperty") {
            this.removeHash();
        }

        window.addEventListener('hashchange', this.handleHashChange, false);
    }

    parseUnitDetailsFromProps = () => {
        let selected_details = [];

        if (this.props && this.props.propDetails && this.props.propDetails.prop_details) {
            Object.keys(this.props.propDetails.prop_details)
                .map(key => {
                    unitDetails.map(detail => {
                        if (detail.type === key) {
                            detail.number = this.props.propDetails.prop_details[key];
                            selected_details.push(detail);
                        }
                    })
                })
        }

        return selected_details;
    }

    handleUnitNumberChange = event => {
        const newValue = event.target.value;
        if (newValue.length <= UNITNUMLIMITMAX) {
            this.setState({ unit_number: newValue, unit_number_error: "" });
        }
    }

    handlePropertyTypeChange = (event, index, value) => this.setState({ property_type: value, property_type_error: "" });

    handleResidentTypeChange = (event, index, value) => this.setState({ resident_type: value, resident_type_error: "" });

    handleOccupancyTypeChange = (event, index, value) => this.setState({ occupancy_type: value, occupancy_type_error: "" });

    onChipAdd = unitDetail => {
        const details = [...this.state.selected_unit_details];
        unitDetail.number = "";
        details.push(unitDetail);
        this.setState({ selected_unit_details: details });
    }

    onChipDelete = unitDetail => {
        const state_details = [...this.state.selected_unit_details];
        const details = state_details.filter(detail => detail.type !== unitDetail.type);
        this.setState({ selected_unit_details: details });
    }

    getChip = unitDetail => {
        if (this.state.selected_unit_details.indexOf(unitDetail) === -1) {
            return (
                <Chip
                    id={"property-" + unitDetail.type + "-chip"}
                    key={unitDetail.type}
                    style={styles.Chip}
                    backgroundColor={"#fff"}
                    onClick={() => this.onChipAdd(unitDetail)}
                >
                    <Avatar
                        size={18}
                        color={gs_color.primaryColor}
                        backgroundColor={"#fff"}
                        icon={<AddIcon />}
                    />
                    <span style={{ color: gs_color.primaryColor }}>{unitDetail.label}</span>
                </Chip>
            )

        } else {
            return (
                <Chip
                    id={"property-" + unitDetail.type + "-chip"}
                    key={unitDetail.type}
                    style={styles.Chip}
                    backgroundColor={gs_color.primaryColor}
                    onRequestDelete={() => this.onChipDelete(unitDetail)}
                    deleteIconStyle={{ color: "#fff", fill: "#fff" }}
                >
                    <span style={{ color: "#fff" }}>{unitDetail.label}</span>
                </Chip>
            )
        }
    }

    onUnitTextFieldChange = (event, unitDetail) => {
        const text = event.target.value.trim();
        if (text.length <= UNITNUMLIMITMAX) {
            const details = [...this.state.selected_unit_details];
            const newDetails = details.map(detail => {
                if (detail.type === unitDetail.type) {
                    detail.number = text;
                }

                return detail;
            })

            this.setState({ selected_unit_details: newDetails });
        }
    }

    getUnitTextField = unitDetail => {
        return (
            <div key={unitDetail.label}>
                <div><label style={{ marginTop: '8px', marginBottom: '2px' }}>{`${unitDetail.label} Number`}</label></div>
                <TextField
                    id={"property-" + unitDetail.type}
                    name={`${unitDetail.label} Number`}
                    value={unitDetail.number}
                    onChange={event => this.onUnitTextFieldChange(event, unitDetail)}
                    hintText=""
                    underlineShow={false}
                    style={styles.FullTextField}
                    disabled={this.props.isSocietySchema}
                />
            </div>
        )
    }

    onSubmitClick = () => {
        const unitNumber = this.state.unit_number.trim()
        const propertyType = this.state.property_type;
        const residentType = this.state.resident_type;
        const occupancyType = this.state.occupancy_type;

        if (this.validatePropertyType(propertyType)
            && this.validateResidentType(residentType)
            && this.validateOccupancyType(occupancyType)
            && this.validateUnitNumber(unitNumber)) {

            const prop_unit = unitNumber;
            const prop_type = propertyType;
            const resident_type = residentType;
            const occupancy_type = occupancyType;

            let prop_details = {};
            if (this.state.selected_unit_details.length > 0) {
                this.state.selected_unit_details.map(detail => prop_details[detail.type] = detail.number);
            }

            if (this.props.reSubmit) {
                this.callResubmit(prop_unit, prop_type, prop_details, resident_type, occupancy_type);
            } else {
                this.callEditDetails(prop_unit, prop_type, prop_details, resident_type, occupancy_type);
            }
        }
    }

    callEditDetails = (prop_unit, prop_type, prop_details, resident_type, occpy_type) => {
        this.setState({ submitLoader: true });

        const editProperty = {
            prop_unit,
            prop_type,
            resident_type,
            prop_details,
            prop_id: this.state.propId,
            occpy_type
        }

        editPropertyDetails(editProperty, (res, err) => {
            if (res) {
                sessionStorage.removeItem(cl_call_prop_api);
                this.props.history.goBack();
                this.props.refreshPage();
                this.props.getUserAddresses();
            } else {
                if (err && err.gsRespData && err.gsRespData.code && err.gsRespData.code === "OTHER_PROPERTY_CONFLICT") {
                    this.showDisputeDialog(err.gsRespData);
                } else if (err && err.message && err.message.constructor === String) {
                    this.setState({ errorMessage: err.message, showErrorDialog: true });
                }

                this.setState({ submitLoader: false });
            }
        });
    }

    callResubmit = (prop_unit, prop_type, prop_details, resident_type, occpy_type) => {
        this.setState({ submitLoader: true });

        const userId = localStorage.getItem(cl_user_id)

        const editProperty = {
            prop_unit,
            prop_type,
            resident_type,
            prop_details,
            prop_id: this.state.propId,
            occpy_type,
            resident_userid: userId
        }

        resubmitPropertyDetails(editProperty, (res, err) => {
            if (res) {
                sessionStorage.removeItem(cl_call_prop_api);
                this.props.history.goBack();
                this.props.refreshPage();
                this.props.getUserAddresses();
            } else {
                if (err && err.gsRespData && err.gsRespData.code && err.gsRespData.code === "OTHER_PROPERTY_CONFLICT") {
                    this.showDisputeDialog(err.gsRespData);
                } else if (err && err.message && err.message.constructor === String) {
                    this.setState({ errorMessage: err.message, showErrorDialog: true });
                }

                this.setState({ submitLoader: false });
            }
        });
    }

    callRegisterWithPropId = () => {
        const community_id = this.props.societyId;
        const resident_type = this.state.resident_type;
        const old_prop_id = this.state.propId;
        const new_prop_id = this.state.newPropId;
        const occpy_type = this.state.occupancy_type;

        let prop_details = null;
        if (this.state.selected_unit_details.length > 0) {
            prop_details = {};
            this.state.selected_unit_details.map(detail => prop_details[detail.type] = detail.number);
        }

        this.callRegisterProperty(community_id, resident_type, old_prop_id, new_prop_id, occpy_type);
    }

    callRegisterProperty = (community_id, resident_type, old_prop_id, new_prop_id, occpy_type) => {
        this.setState({ showDisputeDialog: false, submitLoader: true });
        const migrateUserPropDetails = {
            community_id,
            old_prop_id,
            new_prop_id,
            resident_type,
            occpy_type,
            is_resubmit: this.props.reSubmit ? true : undefined
        }

        migrateUserProperty(migrateUserPropDetails, (res, err) => {
            if (res) {
                this.finishUp(res.prop_id);

            } else {
                if (err && err.message && err.message.constructor === String) {
                    this.setState({ errorMessage: err.message, showErrorDialog: true });
                }
                this.setState({ submitLoader: false });
            }
        })
    }

    finishUp = currentPropId => {
        localStorage.setItem(cl_selected_society_property, currentPropId);
        sessionStorage.removeItem(cl_call_prop_api);
        this.setState({ submitLoader: false });
        this.props.getUserAddresses();

        if (this.props.reSubmit) {
            this.props.history.goBack();
            this.props.refreshPage();

        } else {
            this.props.history.replace(`/${homeRoute}`);
        }
    }

    showDisputeDialog = property => {
        const user_names = property.user_list && property.user_list.join();
        const message = `This property was found associated with the following user(s): ${user_names}\n
                        By continuing, you accept that you share this property with the above users.\n
                        If you feel this is an error on our end, Please report this discrepancy and our representative will get back to you.`;

        this.setState({ newPropId: property.prop_id, disputeMessage: message, showDisputeDialog: true });
    }

    handleDisputeClick = () => {
        this.setState({ disputeApiLoader: true });

        reportDispute(this.state.newPropId, (res, err) => {
            this.setState({ showDisputeDialog: false, submitLoader: false, disputeApiLoader: false });
        })
    }

    validateUnitNumber = unitNumber => {
        const character_regex = /^[a-zA-Z0-9.\s]*$/;

        if (!unitNumber || unitNumber.length === 0) {
            this.setState({ unit_number_error: "Required" });

        } else if (!isSocietySchemaExists(this.props.comm_schema_fields) && !character_regex.test(unitNumber)) {
            this.setState({ unit_number_error: "Only alphabets and numbers allowed" });

        } else {
            return true;
        }

        return false;
    }

    validatePropertyType = type => {
        if (!type || type.length === 0) {
            this.setState({ property_type_error: "Required" });
            return false;
        } else {
            return true;
        }
    }

    validateResidentType = type => {
        if (!type || type.length === 0) {
            this.setState({ resident_type_error: "Required" });
            return false;
        } else {
            return true;
        }
    }

    validateOccupancyType = type => {
        if (!type || type.length === 0) {
            this.setState({ occupancy_type_error: "Required" });
            return false;
        } else {
            return true;
        }
    }

    removeHash() {
        if (this.props.history) {
            this.props.history.goBack();
        }
    }

    openPropSelect() {
        window.location.hash = "selectProperty";
    }

    handlePropSelect(prop_details) {
        let selected_unit_details = [];
        if (prop_details && prop_details.prop_details) {
            Object.keys(prop_details.prop_details).forEach(key => {
                selected_unit_details.push({ type: key, number: prop_details.prop_details[key] });
            });
        }

        let property_type = this.state.property_type;
        if (prop_details && prop_details.secondaryText) {
            for (let i = 0; i < property_types.length; i++) {
                if (property_types[i].label === prop_details.secondaryText) {
                    property_type = property_types[i].type;
                    break;
                }
            }
        }

        this.setState({ prop_details, selected_unit_details, property_type, unit_number: prop_details.prop_unit_name, unit_number_error: '', property_type_error: '' });
        this.removeHash();
    }

    renderPropertyType(disabled) {
        return (
            <>
                <div><label style={{ marginTop: '0px', marginBottom: '2px' }}>Property Type</label>{Compulsory}</div>
                <SelectField
                    id="select-property-type"
                    value={this.state.property_type}
                    onChange={this.handlePropertyTypeChange}
                    underlineShow={false}
                    disabled={!!disabled}
                    style={!this.state.property_type_error ? styles.SelectField : styles.SelectFieldError}
                    errorStyle={{ paddingTop: '6px' }}
                    errorText={this.state.property_type_error ? this.state.property_type_error : ""}
                >
                    {propertyTypes.map(property => <MenuItem key={property.type} value={property.type} primaryText={property.label} />)}
                </SelectField>
            </>
        );
    }

    renderSchemaRegistration() {
        return (
            <>
                {this.state.property_type && this.renderPropertyType(true)}
                <div><label style={{ marginTop: '0px', marginBottom: '2px' }}>Select Property</label>{Compulsory}</div>
                <div
                    id="select-property"
                    style={Object.assign({}, !this.state.unit_number_error ? styles.FullTextField : styles.FullTextFieldError, { width: '256px', display: 'flex', alignItems: 'center', textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' })}
                    onClick={() => this.openPropSelect()}
                >
                    {
                        this.state.prop_details && this.state.prop_details.primaryText
                    }
                </div>
                {
                    this.state.unit_number_error ?
                        <div style={{ color: 'red' }}>
                            {this.state.unit_number_error}
                        </div>
                        : null
                }
            </>
        );
    }

    renderOpenPropRegistration() {
        return (
            <>
                {this.renderPropertyType()}
                <div><label style={{ marginBottom: '0px' }}>Unit Number</label>{Compulsory}</div>
                <div style={{ color: '#757575', marginBottom: '2px', fontSize: '12px' }}>(Flat Number, Shop Number etc)</div>
                <TextField
                    id="property-unit-number"
                    name="Unit Number"
                    hintText=""
                    underlineShow={false}
                    value={this.state.unit_number}
                    onChange={this.handleUnitNumberChange}
                    style={!this.state.unit_number_error ? styles.FullTextField : styles.FullTextFieldError}
                    errorStyle={{ paddingTop: '6px' }}
                    errorText={this.state.unit_number_error ? this.state.unit_number_error : ""}
                />
                <div><label style={{ marginTop: '12px', marginBottom: '2px' }}>Addl. Unit Detail</label></div>
                <div style={styles.ChipWrapper}>
                    {unitDetails.map(detail => this.getChip(detail))}
                </div>
                {this.state.selected_unit_details.map(detail => this.getUnitTextField(detail))}
            </>
        );
    }

    renderForm() {
        return (
            <div>
                <div><label style={{ marginTop: '4px', marginBottom: '2px' }}>Resident Type</label>{Compulsory}</div>
                <SelectField
                    id="select-resident-type"
                    value={this.state.resident_type}
                    onChange={this.handleResidentTypeChange}
                    underlineShow={false}
                    style={!this.state.resident_type_error ? styles.SelectField : styles.SelectFieldError}
                    errorStyle={{ paddingTop: '6px' }}
                    errorText={this.state.resident_type_error ? this.state.resident_type_error : ""}
                >
                    {residentTypes.map(resident => <MenuItem key={resident.type} value={resident.type} primaryText={resident.label} />)}
                </SelectField>
                <div><label style={{ marginTop: '4px', marginBottom: '2px' }}>Occupancy Type</label>{Compulsory}</div>
                <SelectField
                    id="select-occupancy-type"
                    value={this.state.occupancy_type}
                    onChange={this.handleOccupancyTypeChange}
                    underlineShow={false}
                    style={!this.state.occupancy_type_error ? styles.SelectField : styles.SelectFieldError}
                    errorStyle={{ paddingTop: '6px' }}
                    errorText={this.state.occupancy_type_error ? this.state.occupancy_type_error : ""}
                >
                    {occupancyTypes.map(occupant => <MenuItem key={occupant.type} value={occupant.type} primaryText={occupant.label} />)}
                </SelectField>
                {
                    isSocietySchemaExists(this.props.comm_schema_fields) ?
                        this.renderSchemaRegistration()
                        :
                        this.renderOpenPropRegistration()
                }
            </div>
        );
    }

    render() {
        if (this.state.open_select_prop) {
            return (
                <SelectProperty
                    selected_filter_values={(this.state.prop_details && this.state.prop_details.prop_details) ? this.state.prop_details.prop_details : null}
                    onSelect={(prop_details) => this.handlePropSelect(prop_details)}
                    history={this.props.history}
                    societyId={this.props.societyId}
                    comm_schema_fields={this.props.comm_schema_fields}
                    showNestedItems={false}
                    API_TYPE="REGISTRATION"
                />
            );
        }

        const disputeActions = [
            <FlatButton
                label="Report Discrepancy"
                labelStyle={{ color: 'red' }}
                onClick={() => this.handleDisputeClick()}
            />,
            <FlatButton
                label="Continue Registration"
                primary={true}
                onClick={() => this.callRegisterWithPropId()}
            />
        ]

        const disputeActionsLoader = [
            <CircularProgress
                size={22}
            />
            ,
            <FlatButton
                label="Continue Registration"
                primary={true}
                disabled={true}
            />
        ]

        const errorActions = [
            <FlatButton
                label="Ok"
                primary={true}
                onClick={() => this.setState({ showErrorDialog: false })}
            />
        ]

        return (
            <div className="nonAppBar">
                <MyAppBar
                    className="myAppBar"
                    title="Edit Property"
                    elementLeft={<BackButton onClick={() => this.props.history.goBack()} />}
                />
                <div className="nonBottomNavigation" style={{ marginTop: '20px', padding: '16px 24px' }}>
                    {this.renderForm()}
                    <span className="FixedButtonStyle" style={{ backgroundColor: 'white', padding: '12px 24px', marginLeft: '-24px' }}>
                        {
                            this.state.submitLoader
                                ? <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                    <CircularProgress size={24} color={gs_color.accentColor} />
                                </div>
                                : <RaisedButton
                                    id='edit-property'
                                    label={this.props.reSubmit ? "Submit" : "Save Changes"}
                                    primary={true}
                                    fullWidth={true}
                                    onClick={() => this.onSubmitClick()}
                                />
                        }
                    </span>
                    {
                        this.state.showDisputeDialog &&
                        <Dialog
                            title="Property Already Exists !"
                            actions={this.state.disputeApiLoader ? disputeActionsLoader : disputeActions}
                            modal={this.state.disputeApiLoader}
                            open={this.state.showDisputeDialog}
                            onRequestClose={() => this.setState({ showDisputeDialog: false, submitLoader: false })}
                            titleClassName="dialogTitleClassName"
                            bodyClassName="dialogBodyClassName"
                            contentClassName="DialogNewStyle"
                        >
                            {
                                this.state.disputeMessage.constructor === String &&
                                this.state.disputeMessage.split("\n").map((string, i) => {
                                    return (
                                        <div key={i} style={{ color: gs_color.textColor, fontSize: '12px' }}>
                                            <span>{string}</span><br />
                                        </div>
                                    )
                                })
                            }
                        </Dialog>
                    }
                    {
                        this.state.showErrorDialog &&
                        <Dialog
                            actions={errorActions}
                            open={this.state.showErrorDialog}
                            autoScrollBodyContent={true}
                            onRequestClose={() => this.setState({ showErrorDialog: false })}
                            titleClassName="dialogTitleClassName"
                            bodyClassName="dialogBodyClassName"
                            contentClassName="DialogNewStyle"
                        >
                            {
                                this.state.errorMessage.constructor === String &&
                                this.state.errorMessage.split("\n").map((string, i) => {
                                    return (
                                        <div key={i} style={{ color: gs_color.textColor }}>
                                            <span>{string}</span><br />
                                        </div>
                                    )
                                })
                            }
                        </Dialog>
                    }
                </div>
            </div>
        )
    }

    componentWillUnmount() {
        window.removeEventListener('hashchange', this.handleHashChange, false);
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ getUserAddresses }, dispatch);
}

EditProperty.displayName = 'EditProperty';
export default connect(null, mapDispatchToProps)(EditProperty);