import { MAX_BOOKING_DATE } from 'config/config';

// booking status.
export const booked = "booked";
export const scheduled = "scheduled";
export const accepted = "accepted";
export const rejected = "rejected";
export const declined = "declined";
export const reschedule = "reschedule";
export const confirmed = "confirmed";
export const serviced = "serviced";
export const completed = "completed";
export const settled = "settled";
export const indispute = "indispute";
export const canceled = "canceled";
export const replaced = "replaced";
export const passover = "passover";
export const expired = "expired";
export const unserviced = "unserviced";
export const broadcasted = "broadcasted";


// quote status
export const quote_generated = "generated";
export const quote_submitted = "submitted";
export const quote_accepted = "accepted";
export const quote_rejected = "rejected";
export const quote_revision1 = "revision1";
export const quote_revision1_req = "revision1_req";
export const quote_revision2 = "revision2";
export const quote_revision2_req = "revision2_req";

export const quote_requested = [quote_revision1_req, quote_revision2_req];
export const quote_sub = [quote_submitted, quote_revision1, quote_revision2];
export const chat_enabled_bkg_status = [booked, accepted, confirmed, serviced, reschedule, completed];

//invoice status
export const invoice_generated = "generated";
export const invoice_requested = "requested";
export const invoice_raised = "raised";
export const invoice_revision = "revision";
export const invoice_resubmit = "resubmit";
export const invoice_accepted = "accepted";
export const invoice_paid = "paid";
export const invoice_rejected = "rejected";
export const invoice_defaulted = "defaulted";


//Broadcast

export const post_no_response = "post_no_response";
export const post_cancel = "post_cancel";
export const on_demand = "on_demand";
export const broadcast_post_cancel = "broadcast_post_cancel";
export const post_admin_assign = "post_admin_assign";
export const post_future_date = "post_future_date";

//Broadcast status

export const open = "open";
export const closed = "closed";
export const nosp = "nosp";
export const assigned = "assigned";


export function bookingIsQuoteEligible(bkg_status) {
    let is_eligible = false;
    switch (bkg_status) {
        case accepted: is_eligible = true; break;
        case reschedule: is_eligible = true; break;
        case confirmed: is_eligible = true; break;
        case serviced: is_eligible = true; break;
        case completed: is_eligible = true; break;
        case settled: is_eligible = true; break;
        case indispute: is_eligible = true; break;
        case rejected: is_eligible = true; break;
        default: is_eligible = false; break;
    }
    return is_eligible;
}

export const service_specific_flow_object = {
    booking_flow_type: "booking_flow_type",
    product_category_filter: "product_category_filter",
    is_deposit_applicable: "is_deposit_applicable",
    is_rate_factor_applicable: "is_rate_factor_applicable",
    offhour_applicable: "offhour_applicable",
    service_items_or_sp_list: "service_items_or_sp_list",
    show_que_or_service_items: "show_que_or_service_items",
    question_set_available: "question_set_available",
    after_question_show_service_mapped_items: "after_question_show_service_mapped_items",
    show_requirements: "show_requirements",
    is_quantity_applicable: "is_quantity_applicable",
    screen_type: "screen_type",
    client_specify_budget: "client_specify_budget",
    svc_place: "svc_place",
    ondemand_applicable: "ondemand_applicable",
    ondemand_duration_applicable: "ondemand_duration_applicable",
    broadcast_future_day_applicable: "broadcast_future_day_applicable",
    future_day_applicable: "future_day_applicable",
    intraday_applicable: "intraday_applicable",
    show_profile_pic_unauthenticated: "show_profile_pic_unauthenticated",
    show_profile_pic_booking_status: "show_profile_pic_booking_status",
    quote_negotiation_attempts: "quote_negotiation_attempts",
    store_amenities: "store_amenities",
    max_days_allowed: "max_days_allowed",
    new_booking_after_duration: "new_booking_after_duration",
    is_pickup_and_dropoff: "is_pickup_and_dropoff",
    is_subscription: "is_subscription",
    is_inspection: "is_inspection",
    is_reschedule_applicable: "is_reschedule_applicable",
    is_quotation_applicable: "is_quotation_applicable",
    is_quotation_compulsory: "is_quotation_compulsory",
    is_invoice_applicable: "is_invoice_applicable",
    is_invoice_compulsory: "is_invoice_compulsory",
    is_prepaid_subscription_service: "is_prepaid_subscription_service",
    cancel_charges: "cancel_charges",
    visiting_charges: "visiting_charges",
    reschedule_period: "reschedule_period",
    cancel_period: "cancel_period",
    default_bkg_dur: "default_bkg_dur",
    booking_duration_factor: "booking_duration_factor",
    display_title: "display_title",
    schedule_min_duration: "schedule_min_duration",
    schedule_max_duration: "schedule_max_duration",
    plan_or_product_type: "plan_or_product_type",
    auto_confirm: "auto_confirm",
    is_client_booking_duration_applicable: "is_client_booking_duration_applicable",
    default_client_booking_duration: "default_client_booking_duration",
    is_inspection_date_applicable: "is_inspection_date_applicable",
    is_discount_applicable: "is_discount_applicable",
    banner_ad: "banner_ad",
    disclaimer: "disclaimer",
    default_visiting_charge: "default_visiting_charge",
    rewards_applicable: "rewards_applicable"
};

export const service_specific_flow_object_default = {
    [service_specific_flow_object.booking_flow_type]: "service",
    [service_specific_flow_object.is_deposit_applicable]: false,
    [service_specific_flow_object.is_rate_factor_applicable]: false,
    [service_specific_flow_object.offhour_applicable]: false,
    [service_specific_flow_object.service_items_or_sp_list]: "sp_list",
    [service_specific_flow_object.show_que_or_service_items]: "service_items",
    [service_specific_flow_object.question_set_available]: false,
    [service_specific_flow_object.after_question_show_service_mapped_items]: false,
    [service_specific_flow_object.show_requirements]: "all",
    [service_specific_flow_object.is_quantity_applicable]: false,
    [service_specific_flow_object.screen_type]: "text",
    [service_specific_flow_object.svc_place]: "all",
    [service_specific_flow_object.ondemand_applicable]: true,
    [service_specific_flow_object.ondemand_duration_applicable]: 180,
    [service_specific_flow_object.broadcast_future_day_applicable]: false,
    [service_specific_flow_object.future_day_applicable]: true,
    [service_specific_flow_object.show_profile_pic_unauthenticated]: false,
    [service_specific_flow_object.show_profile_pic_booking_status]: "all",
    [service_specific_flow_object.quote_negotiation_attempts]: 3,
    [service_specific_flow_object.max_days_allowed]: MAX_BOOKING_DATE,
    [service_specific_flow_object.new_booking_after_duration]: 180,
    [service_specific_flow_object.is_pickup_and_dropoff]: false,
    [service_specific_flow_object.is_subscription]: false,
    [service_specific_flow_object.is_inspection]: false,
    [service_specific_flow_object.is_reschedule_applicable]: true,
    [service_specific_flow_object.is_quotation_applicable]: true,
    [service_specific_flow_object.is_quotation_compulsory]: false,
    [service_specific_flow_object.is_invoice_applicable]: true,
    [service_specific_flow_object.is_invoice_compulsory]: false,
    [service_specific_flow_object.is_prepaid_subscription_service]: false,
    [service_specific_flow_object.reschedule_period]: 180,
    [service_specific_flow_object.cancel_period]: 180,
    [service_specific_flow_object.booking_duration_factor]: 30,
    [service_specific_flow_object.display_title]: {},
    [service_specific_flow_object.schedule_min_duration]: 30,
    [service_specific_flow_object.schedule_max_duration]: 1430,
    [service_specific_flow_object.plan_or_product_type]: "all",
    [service_specific_flow_object.auto_confirm]: "no",
    [service_specific_flow_object.is_client_booking_duration_applicable]: false,
    [service_specific_flow_object.default_client_booking_duration]: {},
    [service_specific_flow_object.is_inspection_date_applicable]: false,
    [service_specific_flow_object.is_discount_applicable]: false,
    [service_specific_flow_object.banner_ad]: [],
    [service_specific_flow_object.disclaimer]: null,
    [service_specific_flow_object.rewards_applicable]: true,
};

export const service_specific_display_title_object = {
    inspection_title: "inspection_title",
    booking_date_title: "booking_date_title",
    delev_date_title: "delev_date_title",
    visiting_charge: "visiting_charge",
    service_requirements: "service_requirements",
    book_timeslot: "book_timeslot"
};

export const svc_place_enum = {
    mobile: "mobile",
    fixed: "fixed",
    both: "both",
    all: "all"
};