import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';
import { updateRoute, updateMiscellaneousComponents, getOGFoodDataListApi, getNearbyServicesApi, getUserAddresses, getCustomBannerData } from 'actions';

import Paper from 'material-ui/Paper';
import Avatar from 'material-ui/Avatar';
import { Tabs, Tab } from 'material-ui/Tabs';

import ListIcon from 'material-ui/svg-icons/action/list';
import OrderIcon from 'material-ui/svg-icons/action/shop';
import VerticalHorizontalSwipe from './component/verticals/vertical-horizontal-swipe';
import Verticals from './component/verticals';

import { APP_LINK, OG_CART_META_TAGS } from 'config/config';
import LocationAddress from '../../../../../food/components/location-address';
import SelectLocation from '../../../../../food/components/select-location';
import RestaurantList from '../../../../../food/restaurant-list';
import ServiceList from '../../components/service-list';
import ProductList from '../../components/product-list';
import NoServiceProducts from './component/no-service-products';
import BottomNav from 'components/bottom-navigation';
import CustomBanners from 'components/custom-banners'
import { buildAddressWithSocietyDetails, getFormattedAddress, getCommunityIdFromAddress } from '../../../products/config';
import { isNullOrEmptyObject } from 'utils/helper-functions';
import { cl_user_id, is_home_screen_flow, cl_selected_address, cl_society_properties, cl_new_ogfood_order_created,cl_jsk_enabled } from 'config/storage-variables';
import { bookingRoute, ogfoodOrdersRoute,ordersRoute, janSevaRoute, ogcartRoute, productsRoute, selectSocietyRoute} from 'routes-parameters/config';
import gs_color from 'config/colors.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import JskMainIcon from 'assets/JSK_Main_SD.png';
import ApartmentIcon from 'assets/apartments.png';
import { faCartArrowDown, faUsers, faBriefcase, faShoppingBag, faShoppingBasket, faShoppingCart, faBuilding } from '@fortawesome/free-solid-svg-icons';

const styles = {
    tabsStyle: {
        backgroundColor: 'white'
    },
    selectedTabStyle: {
        backgroundColor: gs_color.primaryColor,
        color: gs_color.accentColor,
        fontWeight: 'bold',        
        borderTopLeftRadius: '8px',
        borderTopRightRadius: '8px',
        border: `1px solid ${gs_color.primaryColor}`,
        textTransform: 'capitalize',
        margin: '0px 2px',
    },
    unSelectedTabStyle: {
        color: '#ffffff',
        backgroundColor: gs_color.accentColor,
        fontWeight: 'bold',
        borderTopLeftRadius: '8px',
        borderTopRightRadius: '8px',
        border: `1px solid ${gs_color.accentColor}`,
        textTransform: 'capitalize',
        margin: '0px 2px',
    }
}

export const EXPLORE_TABS = {
    OGFOOD: 0,
    OGCART: 1,
    OGCARE: 2
}

class NewHome extends PureComponent {
    constructor(props) {
        super(props)

        this.state = {
            rerender: false,
            showAddressSelect: false,
            loading: true,
        }
    }

    componentDidMount() {
        this.user_id = localStorage.getItem(cl_user_id);
        sessionStorage.setItem(is_home_screen_flow, 'true');

        if (sessionStorage.getItem(cl_new_ogfood_order_created)) {
            sessionStorage.removeItem(cl_new_ogfood_order_created);
        }

        if (this.props.user_addresses_loading) {
            this.props.getUserAddresses();
        }

        window.scrollTo(0, 0);
        if (window.location.hash !== "") {
            this.props.history.goBack();
        }
        window.addEventListener('hashchange', this.handleHashChange, false);

        if (localStorage.getItem(cl_selected_address)) {
            this.getNearbyListLocation(JSON.parse(localStorage.getItem(cl_selected_address)));
        }

        this.setState({ loading: false });
    }

    handleTabChange = value => {
        const custom_banners_cache = this.props.custom_banners_cache
        this.props.updateMiscellaneousComponents({ explore_screen_tab: value })
        this.loadCustomBanners(
            value,
            custom_banners_cache && custom_banners_cache.prop_id ? custom_banners_cache.prop_id : undefined,
            custom_banners_cache && custom_banners_cache.zipcode ? custom_banners_cache.zipcode : undefined,
            custom_banners_cache && custom_banners_cache.country ? custom_banners_cache.country : undefined,
        )
    }

    getNearbyListLocation(address) {
        const restaurant_list_cache = this.props.restaurant_list_cache || {};
        const nearby_splist_cache = this.props.nearby_splist_cache || {};
        const country_code = address && address.g_address && address.g_address.address && address.g_address.address.country_code ? address.g_address.address.country_code : null;
        const lat = address && address.g_address && address.g_address.geo_location && address.g_address.geo_location.lat ? address.g_address.geo_location.lat : null;
        const lng = address && address.g_address && address.g_address.geo_location && address.g_address.geo_location.lng ? address.g_address.geo_location.lng : null;
        const zipcode = address && address.g_address && address.g_address.address && address.g_address.address.zipcode ? address.g_address.address.zipcode : null;
        //get community id from the selected address. 
        //This will return null if it;s not a community address
        const community_id = getCommunityIdFromAddress(address);

        if (!lat || !lng) {
            return;
        }

        if (!restaurant_list_cache || !restaurant_list_cache.lat || !restaurant_list_cache.lng || lat !== restaurant_list_cache.lat || lng !== restaurant_list_cache.lng) {
            let new_cache = { ...restaurant_list_cache, lat, lng, country_code };
            if (community_id) {
                new_cache = { ...restaurant_list_cache, lat, lng, country_code, community_id };
            }
            else if (new_cache.community_id) {
                delete new_cache.community_id;
            }
            delete new_cache.offset;
            delete new_cache.last_opuid;
            this.callOGFoodRestaurantApi(new_cache);
        }
        if (!nearby_splist_cache || !nearby_splist_cache.lat || !nearby_splist_cache.lng || lat !== nearby_splist_cache.lat || lng !== nearby_splist_cache.lng) {
            let new_cache = { ...nearby_splist_cache, lat, lng, country_code, zipcode };
            delete new_cache.offset;
            delete new_cache.last_sp_id;
            this.callNearbySPListApi(new_cache);
        }
        this.loadCustomBanners(
            (this.props.miscellaneous_components && this.props.miscellaneous_components.explore_screen_tab) || EXPLORE_TABS.OGFOOD,
            address.selectedPropId,
            zipcode,
            country_code
        )
    }

    loadCustomBanners = (explore_screen_tab, prop_id, zipcode, country) => {
        const custom_banners_cache = this.props.custom_banners_cache
        //const screen_name = explore_screen_tab === EXPLORE_TABS.OGFOOD ? 'nbh-ogfood' : explore_screen_tab === EXPLORE_TABS.OGCARE ? 'nbh-ogcare' : 'nbh-ogcart';
        const screen_name = 'nbh-common';
        if (
            !custom_banners_cache ||
            custom_banners_cache.screen_name !== screen_name ||
            (prop_id && (!custom_banners_cache.prop_id || custom_banners_cache.prop_id !== prop_id)) ||
            (zipcode && (!custom_banners_cache.zipcode || custom_banners_cache.zipcode !== zipcode)) ||
            (country && (!custom_banners_cache.country || custom_banners_cache.country !== country))
        ) {
            let filters = { screen_name };
            if (prop_id) {
                filters.prop_id = prop_id
            }
            if (zipcode) {
                filters.zipcode = zipcode
            }
            if (country) {
                filters.country = country
            }
            this.props.getCustomBannerData(filters)
        }
    }

    callOGFoodRestaurantApi(filters) {
        if (filters && Object.keys(filters).length > 0) {
            this.props.getOGFoodDataListApi(filters);
        }
        else {
            this.props.getOGFoodDataListApi();
        }
    }

    callNearbySPListApi(filters) {
        if (filters && Object.keys(filters).length > 0) {
            this.props.getNearbyServicesApi(filters);
        }
        else {
            this.props.getNearbyServicesApi();
        }
    }

    handleHashChange = () => {
        const hash = window.location.hash;

        if (hash.includes('#select-address')) {
            this.setState({ showAddressSelect: true });
        } else {
            this.setState({ showAddressSelect: false });
        }
    }

    onSaveClick() {
        if (window.location.hash.includes('select-address')) {
            this.props.history.goBack();
        } else {
            this.setState({ rerender: !this.state.rerender });
        }
    }

    renderButton(style, Icon, text, route, badge_value) {
        return (
            <div style={Object.assign({}, { flex: 1, flexDirection: 'row', alignItems: 'center' }, style || {})}>
                <Link style={{ textDecoration: "none" }} to={`/${route}`}>
                    <Paper style={{ padding: '8px', borderRadius: '6px', fontSize: '14px', display: 'flex', flexDirection: 'row', alignItems: 'center', backgroundColor: gs_color.accentColor }}>
                        <Icon color={gs_color.gradientLightColor} />
                        <div style={{ marginLeft: '8px', color: gs_color.gradientLightColor, marginRight: badge_value ? '5px' : '0px' }}>{text}</div>
                        {
                            badge_value &&
                            <Avatar style={{ left: '8px', marginLeft: 'auto' }} size={24} backgroundColor='#FFF' color={gs_color.accentColor}>
                                {parseInt(badge_value, 10) > 99 ? "99+" : badge_value}
                            </Avatar>
                        }
                    </Paper>
                </Link>
            </div>
        );
    }

    renderQuickAccess(lb_icon, label_name, route_name) {
        return (
            <div className="action-outer" style={{ maxWidth: '120px' }} onClick={() => this.props.history.push(route_name)}>
                <div className="action-icon-outer">
                    <div className="action-icon action-icon-background">
                        <FontAwesomeIcon className='svg-inline--fa' icon={lb_icon} color={gs_color.accentColor} style={{ height: '14px', width: '14px' }} />
                    </div>
                </div>
                <div className="action-label" style={{ textTransform:'inherit' }}>
                    {label_name}
                </div>
            </div>
        );
    }

    renderTabName(title, desc) {
        return (
            <div>
                <div style={{ fontWeight: '800',  fontSize: 12 }}>{title}</div>
                <div style={{ marginTop: 4, fontSize: 12 }}>{desc}</div>
            </div>
        )
    }

    renderRestaurant() {
        const slideIndex = (this.props.miscellaneous_components && this.props.miscellaneous_components.explore_screen_tab) || EXPLORE_TABS.OGFOOD;
        if (this.state.loading || slideIndex !== EXPLORE_TABS.OGFOOD) {
            return null;
        }

        return (
            <RestaurantList
                refreshPage={filters => this.callOGFoodRestaurantApi(filters)}
                history={this.props.history}
            />
        );
    }

    renderServices() {
        //const slideIndex = (this.props.miscellaneous_components && this.props.miscellaneous_components.explore_screen_tab) || EXPLORE_TABS.OGFOOD;
        const slideIndex = (this.props.miscellaneous_components && this.props.miscellaneous_components.explore_screen_tab) || EXPLORE_TABS.OGCARE;
        
        if (this.state.loading || slideIndex !== EXPLORE_TABS.OGCARE) {
            return null;
        }

        return (
            <ServiceList
                refreshPage={filters => this.callNearbySPListApi(filters)}
                history={this.props.history}
            />
        );
    }

    renderOGCart() {
        const slideIndex = (this.props.miscellaneous_components && this.props.miscellaneous_components.explore_screen_tab) || EXPLORE_TABS.OGFOOD;
        if (this.state.loading || slideIndex !== EXPLORE_TABS.OGCART) {
            return null;
        }

        return (
            <ProductList
                history={this.props.history}
            />
        );
    }


    render() {
        const selected_address = localStorage.getItem(cl_selected_address) ?
            JSON.parse(localStorage.getItem(cl_selected_address)) : null;
        
        let is_registered_property = localStorage.getItem(cl_society_properties);

        if (isNullOrEmptyObject(selected_address) || this.state.showAddressSelect) {
            return (
                <LocationAddress
                    history={this.props.history}
                    onSaveClick={this.onSaveClick.bind(this)}
                />
            )
        }

        const slideIndex = (this.props.miscellaneous_components && this.props.miscellaneous_components.explore_screen_tab) || EXPLORE_TABS.OGFOOD;
        const formatted_address = selected_address ? getFormattedAddress(buildAddressWithSocietyDetails(selected_address)) : null;
        const jskEnabled  = (selected_address.jsk_enabled ||
                                    (localStorage.getItem(cl_jsk_enabled) && localStorage.getItem(cl_jsk_enabled) === 'true')? true: false
                             );
        return (
            <div className="nonBottomNavigation">
                {/* <div style={{ display: 'flex', margin: '0px 12px', marginTop: '8px', alignItems: 'center', flexWrap: 'wrap' }}>
                    <SelectLocation
                        address={formatted_address}
                        onClick={() => window.location.hash = 'select-address'}
                        style={{ flex: 1, marginBottom: '8px', display: 'flex', maxWidth: '100%' }}
                    />
                    <div style={{ display: 'flex', flex: 1, flexGrow: 1, justifyContent: 'space-between', marginBottom: '8px' }}>
                        {this.renderButton({}, OrderIcon, "Orders", ogfoodOrdersRoute)}
                        {this.renderButton({ marginLeft: '4px' }, ListIcon, "Bookings", bookingRoute, this.props.service_notification_count)}
                    </div>
                </div> */}
                <div style={{ display: 'flex', margin: '0px 12px', marginTop: '8px', alignItems: 'center', flexWrap: 'wrap' }}>
                    <SelectLocation
                        address={formatted_address}
                        onClick={() => window.location.hash = 'select-address'}
                        style={{ flex: 1, marginBottom: '8px', display: 'flex', maxWidth: '100%' }}
                    />
                </div>
                <div style={{ display: 'flex', margin: '0px 8px', marginTop: '4px', alignItems: 'center', flexWrap: 'wrap' }}>
                    <div style={{ display: 'flex', flex: 1, flexGrow: 1, justifyContent: 'space-around', marginBottom: '2px', marginLeft: '4px', marginRigt: '4px' }}>
                        {/* {this.renderButton({}, OrderIcon, "Orders", ordersRoute)}
                            {this.renderButton({ marginLeft: '4px' }, ListIcon, "Bookings", bookingRoute, this.props.service_notification_count)} */}
                        {!is_registered_property && this.renderQuickAccess(faBuilding, "Register Property", selectSocietyRoute)}
                        
                        { /* disabled OGFresh for now : Dec 2022 */}                        
                        {this.renderQuickAccess(faShoppingBasket, "OGFood Orders", ogfoodOrdersRoute)}                        
                        
                        {/*selected_address.og_delivery && */}
                        { /* disabled OGFresh for now : Sept 2022*/}
                           {this.renderQuickAccess(faShoppingCart, "OGCart Orders", ordersRoute)}                                                   
                        
                       
                        {this.renderQuickAccess(faBriefcase, "OGCare Bookings", bookingRoute)}
                    {/*
                        {jskEnabled && this.renderQuickAccess(faUsers, "Corporator Office", janSevaRoute)}
                    */}
                    </div>
                </div>
                <div style={{ margin: '2px 2px 0px 0px', paddingBottom: '4px' }}>
        		    <CustomBanners history={this.props.history} />
                </div>
                <div>
                    {/*
                    <div style={{ display: 'flex', flex: 1, flexGrow: 1, justifyContent: 'space-evenly', marginBottom: '4px', marginLeft: '4px', marginRight: '4px' }}>
                    
                        {jskEnabled &&                      
                        <Link to={janSevaRoute} onClick={() => this.props.history.push(janSevaRoute)} >
                            <Paper style={{ display:'flex', justifyContent:'center', width: '100%', marginTop: '4px', marginLeft: '4%', marginBottom: '12px', borderRadius: '5px', position: 'relative', backgroundColor: gs_color.primaryColor }}>
                                <div>
                                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', paddingTop: '4px' }}>                                    
                                        <img src={JskMainIcon} style={{ width: '40px', height: '40px' }} />                                                                                                            
                                    </div>
                                    <div style={{ whiteSpace:'nowrap', textAlign: 'center', padding:'2px 4px 4px 4px',fontWeight: '800',  color: gs_color.accentColor, fontSize: '12px' }}>
                                        {"Corporator Office" || ""}
                                    </div>
                                </div>
                            </Paper>
                        </Link>                        
                        }      
                        {!is_registered_property &&
                            <Link to={selectSocietyRoute} onClick={() => this.props.history.push(selectSocietyRoute)} >
                            <Paper style={{ display:'flex', justifyContent:'center', width: '100%', marginTop: '4px', marginLeft: '4%', marginBottom: '12px', borderRadius: '5px', position: 'relative', backgroundColor: gs_color.primaryColor }}>
                                <div>                                
                                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', paddingTop: '4px' }}>                                    
                                        <img src={ApartmentIcon} style={{ width: '40px', height: '40px' }} />                                                                                                            
                                    </div>
                                    <div style={{ whiteSpace:'nowrap', textAlign: 'center', padding:'2px 4px 4px 4px', fontWeight: '800',  color: gs_color.accentColor, fontSize: '12px' }}>
                                        {"Register Property" || ""}
                                    </div>
                                </div>
                            </Paper>
                            </Link>
                        }
                    
                    </div>
                    */}            
                </div>
                <Tabs
                    onChange={(value) => this.handleTabChange(value)}
                    value={slideIndex}
                    inkBarStyle={{ backgroundColor: 'transparent' }}
                    tabItemContainerStyle={{ backgroundColor: '#fff' }}                      
                    style={styles.tabsStyle}
                >
                    
                    <Tab label={this.renderTabName("Local Shopping", "@ OGFOOD")} value={EXPLORE_TABS.OGFOOD} buttonStyle={slideIndex === EXPLORE_TABS.OGFOOD ? styles.selectedTabStyle : styles.unSelectedTabStyle}>
                        <div style={{ paddingLeft: '8px', paddingRight: '8px', paddingTop: '2px' }}>                           
                            {this.renderRestaurant()}
                        </div>
                    </Tab>                 
                                                
                    {/* Temp FIX to auto load OGFood: index:0
                    <Tab label={this.renderTabName("Get Neighborhood Service Providers", "OGCARE")} value={EXPLORE_TABS.OGFOOD} buttonStyle={slideIndex === EXPLORE_TABS.OGFOOD ? styles.selectedTabStyle : styles.unSelectedTabStyle}>
                        <div style={{ paddingLeft: '8px', paddingRight: '8px', paddingTop: '2px' }}>                           
                            {this.renderServices()}
                        </div>
                    </Tab>                        
                    */}   

                    {/* Comment from */}                            
                    
                        title="Society General is a OlaGate initiative for collective shopping of quality products. \
                                As this point it's a controlled roll out & it may not be active in your pincode. But it is coming soon.";
                        description="Please note, OGCart is only available for delivery at apartment address by our dedicated team. \
                                    If your delivery address is not same as prop address, in that case you will not see OGCart Items.\ 
                                    If you know it's active, please check delivery address from top address dropdown of this screen.";

                    {/* disabled OGFresh for now : Sept 2022                */}
                    <Tab label={this.renderTabName("Society Mart", "@ OGCART")} value={EXPLORE_TABS.OGCART} buttonStyle={slideIndex === EXPLORE_TABS.OGCART ? styles.selectedTabStyle : styles.unSelectedTabStyle}>
                        {selected_address.og_delivery ? 
                            <div style={{ paddingLeft: '8px', paddingRight: '8px', paddingTop: '2px' }}>
                                {/*<CustomBanners history={this.props.history} /> */}
                                {this.renderOGCart()}
                            </div>
                        :
                            <div style={{ padding: '5px 16px 60px 16px' }}>
                                <NoServiceProducts
                                
                                    title="Sorry, we're not in your neighborhood yet! But don't worry, we'll be there in your pincode/zipcode soon."                                              
                                    description="NaBeCart by NaBeGo: Enjoy farm-fresh veggies, fruits, and daily essentials right at your doorstep. Support local farmers with us."                                                                                        
                                />
                            </div>    
                        }
                    </Tab>
                    {/* Comment till here*/}
                   {/* //keep selected by default. Reverrt back when reactivated ogfood and ogfresh tabs
                    <Tab label={this.renderTabName("Service Provider", "OGCARE")} value={EXPLORE_TABS.OGCARE} buttonStyle={slideIndex === EXPLORE_TABS.OGCARE ? styles.selectedTabStyle : styles.unSelectedTabStyle}>                   
                    <Tab label={this.renderTabName("Service Provider", "OGCARE")} value={EXPLORE_TABS.OGCARE} buttonStyle={styles.selectedTabStyle}>
                   */}
                    <Tab label={this.renderTabName("Local Services", "@ OPEN OGCARE")} value={EXPLORE_TABS.OGCARE} buttonStyle={slideIndex === EXPLORE_TABS.OGCARE ? styles.selectedTabStyle : styles.unSelectedTabStyle}>                   
                        <div style={{ paddingLeft: '8px', paddingRight: '8px', paddingTop: '2px' }}>                            
                            {this.renderServices()}
                        </div>
                    </Tab>
                    {/* */}
                </Tabs>

                <BottomNav
                    selectedIndex={this.props.selectedIndex}
                    history={this.props.history}
                />
            </div>
        );
    }

    componentDidUpdate(prevProps, prevState) {
        if (
            // (prevState.showAddressSelect !== this.state.showAddressSelect && !this.state.showAddressSelect) ||
            (prevProps.user_selected_address !== this.props.user_selected_address && this.props.user_selected_address)
        ) {
            if (localStorage.getItem(cl_selected_address)) {
                this.getNearbyListLocation(JSON.parse(localStorage.getItem(cl_selected_address)));
            }
        }
    }

    componentWillUnmount() {
        window.removeEventListener('hashchange', this.handleHashChange, false);
    }
}

function mapStateToProps({ restaurant_list, nearby_splist, miscellaneous_components, user_addresses, custom_banners }) {
    const restaurant_list_loading = restaurant_list.loading;
    const restaurant_list_error = restaurant_list.error;
    const restaurant_list_cache = restaurant_list.cache;

    const nearby_splist_loading = nearby_splist.loading;
    const nearby_splist_error = nearby_splist.error;
    const nearby_splist_cache = nearby_splist.cache;

    const user_addresses_loading = user_addresses.loading;
    const user_selected_address = user_addresses.selected_address;

    const custom_banners_cache = custom_banners.cache

    return {
        miscellaneous_components,
        restaurant_list_loading,
        restaurant_list_error,
        restaurant_list_cache,
        nearby_splist_loading,
        nearby_splist_error,
        nearby_splist_cache,
        user_addresses_loading,
        user_selected_address,
        custom_banners_cache,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        updateRoute,
        updateMiscellaneousComponents,
        getOGFoodDataListApi,
        getNearbyServicesApi,
        getUserAddresses,
        getCustomBannerData,
    }, dispatch);
}

NewHome.displayName = 'NewHome';
export default connect(mapStateToProps, mapDispatchToProps)(NewHome);