const styles = {
    container: {
        margin: '24px 0px',
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
    },
    imgStyle: {
        // height: '100%',
        objectFit: 'cover',
        borderRadius: '5px',
        height: '80px',
        width: '80px'
    },
    starIconStyle: {
        width: '12px',
        height: '12px',
        color: 'grey',
        marginRight: '5px'
    },
    outerStyle: {
        marginLeft: '16px',
        marginRight: '16px',
        flex: 1,
        maxWidth: 'calc(100% - 72px)'
    },
    headingStyle: {
        fontSize: '1.2em',
        fontWeight: 'bold',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflow: 'hidden'
    },
    addressContainer: {
        marginTop: '5px',
        color: '#757575',
        display: 'flex',
        justifyContent: 'space-between'
    },
    addressStyle: {
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        flexShrink: 1
    },
    separatorStyle: {
        margin: '0px 5px'
    },
    distanctStyle: {
        // width: '90px'
    },
    innerStyle: {
        marginTop: '5px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    categoryStyle: {
        //color: '#004aad',
    },
    serviceStyle: {
        fontSize: '1.0em',
        fontWeight: 'bold',
        //color: '#004aad',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflow: 'hidden'
    },
    ratingStyle: {
        display: 'flex',
        alignItems: 'center',
    }
}

export default styles;