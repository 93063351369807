export function isProductOutOfStock(product_obj) {
    let isOutOfStock = true;

    if (product_obj && product_obj.psku_list && product_obj.psku_list.constructor === Array) {
        for (let i = 0; i < product_obj.psku_list.length; i++) {
            if (product_obj.psku_list[i] && product_obj.psku_list[i].sku_price && product_obj.psku_list[i].sku_price.quantity > 0) {
                isOutOfStock = false;
                break;
            }
        }
    }

    return isOutOfStock;
}