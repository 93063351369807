// import * as firebase from 'firebase/app';

// import 'firebase/messaging';

const fb = () => import(/* webpackChunkName: "firebase_app" */ 'firebase/app');
const fbmsg = () => import(/* webpackChunkName: "firebase_messaging" */ 'firebase/messaging');

var messaging = null;
var firebaseConfig = {
    'messagingSenderId': '185337869306'
};

// await firebase.initializeApp(firebaseConfig);

// try {
//     messaging = await firebase.messaging();
// }
// catch (err) {
//     console.log("Error in calling firebase initialisation");
// }

export async function initializeFirebase() {
    const { default: firebase } = await fb();
    await fbmsg();

    firebase.initializeApp(firebaseConfig);

    try {
        messaging = firebase.messaging();
    }
    catch (err) {
        console.log("Error in calling firebase initialisation");
    }

    return messaging;
}

export async function setServiceWorkerToFirebase(sw_reg) {
    messaging = await initializeFirebase();

    messaging.useServiceWorker(sw_reg);

    messaging.onMessage(function (payload) {
        console.log("on message payload : ", payload);
        // alert(JSON.stringify(payload));
        var data = {}, booking_id = '', community_id = '', notificationTitle = '', notificationBody = 'You have new notifications', tag = '';
        var options = {
            icon: "./LOGO192.png",
            // badge: "%PUBLIC_URL%/badge.png",
            vibrate: [500],
            subtitle: "A New Message Arrived from OlaGate",
            body: notificationBody,
        };

        if (payload && payload.data)
            data = payload.data;
        if (data && data.booking_id)
            booking_id = data.booking_id;
        if (data && data.community_id)
            community_id = data.community_id;

        if (data) {
            if (data.image) {
                options.image = data.image;
            }

            if (data && data.notification_type === "AdminMsg") {
                var adminEvent = document.createEvent('Event');
                // Define that the event name is 'build'.
                adminEvent.initEvent('adminEvent', true, true);
                adminEvent.n_type = data.type;
                adminEvent.acnt_status = data.acnt_status;
                document.dispatchEvent(adminEvent);

                var title = '', body = '';
                if (data.type === "VerificationUpdate") {
                    if (data.acnt_status === "active") {
                        title = "Account verification update";
                        body = "Congratulations! Your account is now active";
                    }
                    else if (data.acnt_status === "pending") {
                        title = "Account verification update";
                        body = "Your application needs some revise. Kindly resubmit details.";
                    }
                    else if (data.acnt_status === "suspended" || data.acnt_status === "disabled") {
                        title = "Account verification update";
                        body = "Your application has been suspended. Kindly follow us for further procedure.";
                    }
                }
                notificationTitle = title;
                notificationBody = body;
                tag = data.type;
                data.route = '';
            }
            else if (community_id) {

                var event = document.createEvent('Event');
                // Define that the event name is 'build'.
                event.initEvent('newSocietyNotification', true, true);
                event.community_id = community_id;
                document.dispatchEvent(event);

                tag = community_id;
                var type;
                if (data.type) {
                    type = data.type;
                }

                var prop_id;
                if (data.prop_id) {
                    prop_id = data.prop_id;
                }

                if (data.notification_type === "PropertyMsg") {
                    if (data.title && data.body) {
                        notificationTitle = data.title;
                        notificationBody = data.body;
                    }
                    else if (type && type.toUpperCase() === 'PROPERTY_UNEXPECTED_VISITOR') {
                        notificationTitle = 'New Visitor';
                        notificationBody = "Visitor is waiting at the gate, approval pending.";
                    }
                    else if (type && type.toUpperCase() === 'PROPERTY_EXPECTED_VISITOR') {
                        notificationTitle = 'New Visitor';
                        notificationBody = "Expected visitor has arrived.";
                    }
                    else if (type && type.toUpperCase() === 'PROPERTY_CHILD_EXIT_REQUEST') {
                        notificationTitle = 'Child exit request';
                        notificationBody = 'Child wants to leave society, approve request';
                    }
                    else if (type && type.toUpperCase() === 'PROPERTY_CHILD_EXIT_NOTIFY') {
                        notificationTitle = 'Child checked-out';
                        notificationBody = 'Child checkout / left society.';
                    }
                    else if (type && type.toUpperCase() === 'PROPERTY_CHILD_ENTRY_NOTIFY') {
                        notificationTitle = 'Child checked-in';
                        notificationBody = 'Child checkin / entered society';
                    }
                    else if (type && type.toUpperCase() === 'PROPERTY_NEW_PARCEL_FDESK') {
                        notificationTitle = 'New mail / package';
                        notificationBody = "New mail / package at the front desk";
                    }

                    var propEvent = document.createEvent('Event');
                    propEvent.initEvent('propEvent', true, true);
                    if (data.vlog_id) {
                        propEvent.vlog_id = data.vlog_id;
                    }
                    if (prop_id) {
                        propEvent.prop_id = prop_id;
                    }
                    propEvent.community_id = community_id;
                    if (type) {
                        propEvent.notification_subtype = type.toUpperCase();
                    }
                    document.dispatchEvent(propEvent);
                    options = null;
                }
                else if (data.notification_type === "SocietyMsg") {
                    var societyEvent = document.createEvent('Event');
                    societyEvent.initEvent('societyEvent', true, true);
                    if (prop_id) {
                        societyEvent.prop_id = prop_id;
                    }
                    societyEvent.community_id = community_id;
                    document.dispatchEvent(societyEvent);
                    options = null;
                }
                else if (data.notification_type === "SocietyBoardMsg") {
                    if (type && type.toUpperCase() === 'SOCIETY_BOARD_NEW_TXN_PENDING') {
                        notificationTitle = 'New Transaction';
                        notificationBody = "New Transaction reported, approval pending.";
                    }
                    else if (type && type.toUpperCase() === 'SOCIETY_BOARD_NEW_GK_REQUEST') {
                        notificationTitle = 'New Gatekeeper';
                        notificationBody = "New Gatekeeper request, approval pending.";
                    }

                    var boardEvent = document.createEvent('Event');
                    boardEvent.initEvent('boardEvent', true, true);
                    if (prop_id) {
                        boardEvent.prop_id = prop_id;
                    }
                    if (data.txn_id) {
                        boardEvent.txn_id = data.txn_id;
                    }
                    boardEvent.community_id = community_id;
                    document.dispatchEvent(boardEvent);
                    options = null;
                }
            }
            else if (booking_id) {
                var event = document.createEvent('Event');
                // Define that the event name is 'build'.
                event.initEvent('newNotification', true, true);
                event.booking_id = booking_id;
                document.dispatchEvent(event);

                notificationTitle = booking_id;
                notificationBody = "You have new notifications from this booking.";
                tag = booking_id;

                if (data.notification_type === "ChatMsg" && booking_id === sessionStorage.getItem('current_chat_id')) {
                    var chatEvent = document.createEvent('Event');
                    chatEvent.initEvent('chatEvent', true, true);
                    chatEvent.booking_id = booking_id;
                    document.dispatchEvent(chatEvent);
                    options = null;
                }
                else if ((data.notification_type === "SPReviewMsg" || data.notification_type === "CLReviewMsg") && booking_id === sessionStorage.getItem('current_review_id')) {
                    var reviewEvent = document.createEvent('Event');
                    reviewEvent.initEvent('reviewEvent', true, true);
                    reviewEvent.booking_id = booking_id;
                    document.dispatchEvent(reviewEvent);
                    options = null;
                }
                else if ((data.notification_type === "BookingMsg" || data.notification_type === "QuoteMsg" || data.notification_type === "InvoiceMsg" || data.notification_type === "ChatMsg") && booking_id === sessionStorage.getItem('current_booking_id')) {
                    var bookingEvent = document.createEvent('Event');
                    bookingEvent.initEvent('bookingEvent', true, true);
                    bookingEvent.booking_id = booking_id;
                    document.dispatchEvent(bookingEvent);
                    options = null;
                }
                else if (data.notification_type === "BroadcastMsg") {
                    var broadcastEvent = document.createEvent('Event');
                    broadcastEvent.initEvent('broadcastEvent', true, true);
                    broadcastEvent.booking_id = booking_id;
                    document.dispatchEvent(broadcastEvent);
                    options = null;
                }
            }

            if (options) {
                if (data.title)
                    notificationTitle = data.title;
                if (data.body)
                    notificationBody = data.body;
                if (data.tag)
                    tag = data.tag;

                if (tag)
                    options.tag = tag;

                var notificationData = Object.assign({}, data);
                delete notificationData.title;
                delete notificationData.body;

                options.body = notificationBody;
                options.data = notificationData;
                sw_reg.showNotification(notificationTitle, options);
            }
        }
    });
}

export function notifyMe() {
    if (!messaging) {
        pushUriNotAvailableEvent();
        return;
    }

    messaging.requestPermission()
        .then(function () {
            //Remove notification permission further blocking on sign in.
            // localStorage.removeItem('blockNotificationPermission');
            document.removeEventListener('openNotificationPermission', function () { });

            messaging.getToken()
                .then(function (currentToken) {
                    if (currentToken) {
                        // Create the event.
                        var event = document.createEvent('Event');
                        // Define that the event name is 'build'.
                        event.initEvent('closeNotificationPermissionBlocker', true, true);
                        document.dispatchEvent(event);

                        var localStorage_token = localStorage.getItem('pushUriId');
                        ////console.log("localStorage_token: ", localStorage_token);
                        if (localStorage_token === undefined || localStorage_token === null) {
                            // Call PushURI add API here.
                            localStorage.setItem('pushUriId', currentToken);
                        }
                        else if (localStorage_token !== currentToken) {
                            localStorage.setItem('pushUriId', currentToken);
                            sessionStorage.setItem('pushUriUpdate', 'true');
                            // Call PushURI add edit here.
                        }
                    } else {
                        pushUriNotAvailableEvent();
                        // Show permission request.
                        // Show permission UI.
                        //console.log("No token");
                    }
                })
                .catch(function (err) {
                    pushUriNotAvailableEvent();
                });
        })
        .catch(function (err) {
            // var event = document.createEvent('Event');
            // // Define that the event name is 'build'.
            // event.initEvent('userBlockedNotificationPermission', true, true);
            // document.dispatchEvent(event);
            pushUriNotAvailableEvent();
            // localStorage.setItem('blockNotificationPermission', 'true');
            //console.log("block value to local storage");
        });
}

export function pushUriNotAvailableEvent() {
    var event = document.createEvent('Event');
    // Define that the event name is 'build'.
    event.initEvent('pushUriNotAvailable', true, true);
    document.dispatchEvent(event);
}