import { NAMELIMITMIN, ONELINELIMITMAX, INTROLIMITMAX } from 'config/config';

export function validations(stateObj) {
    let errorState = {
        title_err: '',
        desc_err: '',
        participantListError: false,
    };
    let is_error = false;

    if (!stateObj.title) {
        errorState.title_err = 'Required';
        is_error = true;
    }
    else if (stateObj.title.trim().length < NAMELIMITMIN) {
        errorState.title_err = `Minimum ${NAMELIMITMIN} characters required`
        is_error = true;
    }
    else if (stateObj.title.trim().length > ONELINELIMITMAX) {
        errorState.title_err = `Maximum ${ONELINELIMITMAX} characters allowed`
        is_error = true;
    }

    if (!stateObj.desc) {
        errorState.desc_err = 'Required';
        is_error = true;
    }
    else if (stateObj.desc.trim().length < NAMELIMITMIN) {
        errorState.desc_err = `Minimum ${NAMELIMITMIN} characters required`
        is_error = true;
    }
    else if (stateObj.desc.trim().length > INTROLIMITMAX) {
        errorState.desc_err = `Maximum ${INTROLIMITMAX} characters allowed`
        is_error = true;
    }

    if (!stateObj.msg_type) {
        errorState.msg_type_err = 'Required';
        is_error = true;
    }

    if (!stateObj.selectedParticipants.length) {
        errorState.participantListError = true;
        is_error = true;
    }

    return [errorState, is_error];
}

export function parseFilters(feedTypes, msgTypeLabels) {
    let filters = {}

    if (feedTypes && msgTypeLabels) {
        Object.keys(feedTypes)
            .forEach(type => filters[type] = msgTypeLabels[feedTypes[type]])
    }

    return filters
}

export function getPostType(type_id, feedTypes) {
    if (type_id && feedTypes) {
        for (const key in feedTypes) {
            if (feedTypes[key] === type_id) {
                return key;
            }
        }
    }
    return null;
}

export function getColorForFilter(msgtype_id) {
    if (!isNaN(msgtype_id)) {
        var randomColor = ["#607d8b", "#795548", "#ffb300", "#4caf50", "#00bcd4", "#3f51b5", "#ab47bc", "#e91e63"];

        return randomColor[parseInt(msgtype_id, 10) % randomColor.length];
    }

    return '#184e9a';
}