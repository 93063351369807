import { REHYDRATE } from 'redux-persist';
import { SERVICES_ITEMS, SERVICES_ITEMS_SUCCESS, SERVICES_ITEMS_ERROR, LOGOUT_RESET } from '../../actions/types';
import { handleNetworkError } from '../../utils/api-error';

const INITIAL_STATE = {
    service_items_list: [],
    error: undefined,
    loading: true,
    is_logout: false
};


export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case REHYDRATE: if (action.payload && action.payload.service_items) return { ...action.payload.service_items }; else return INITIAL_STATE;
        case SERVICES_ITEMS: return { ...INITIAL_STATE, loading: true, is_logout: false };
        case SERVICES_ITEMS_SUCCESS:
            if (action.payload !== undefined && action.payload !== null) {
                if (action.payload.length > 0) {
                    return { ...INITIAL_STATE, service_items_list: action.payload, loading: false, is_logout: false };
                }
                else
                    return { ...INITIAL_STATE, loading: false, is_logout: false };
            }
            else
                return { ...state, loading: false, is_logout: false };


        case SERVICES_ITEMS_ERROR:
            let hNE_res = handleNetworkError(action);
            if (hNE_res.error)
                return { ...INITIAL_STATE, ...hNE_res, loading: false, is_logout: false };
            else if (action.payload && action.payload.response && action.payload.response.data && action.payload.response.data.error) {
                if (action.payload.response.data.error.statusCode === 401)
                    return { ...INITIAL_STATE, error: action.payload.response.data.error, loading: false, is_logout: true };
                else
                    return { ...INITIAL_STATE, error: action.payload.response.data.error, loading: false, is_logout: false };
            }
            else
                return { ...INITIAL_STATE, error: action.payload, loading: false, is_logout: false };
        case LOGOUT_RESET: return { ...INITIAL_STATE };
        default: return state;
    }
}