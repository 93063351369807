import { REHYDRATE } from 'redux-persist';
import { SPSCHEDULELIST, SPSCHEDULELIST_SUCCESS, SPSCHEDULELIST_ERROR, LOGOUT_RESET } from '../../actions/types';
import { handleNetworkError } from '../../utils/api-error';

const INITIAL_STATE = {
    schedule_list: [],
    error: undefined,
    loading: true,
    is_logout: false
};

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case REHYDRATE: if (action.payload && action.payload.sp_schedule_list) return { ...action.payload.sp_schedule_list }; else return INITIAL_STATE;
        case SPSCHEDULELIST: return { ...INITIAL_STATE, loading: true, is_logout: false };
        case SPSCHEDULELIST_SUCCESS:
            if (action.payload !== undefined && action.payload !== null) {
                let processed_resp = [...action.payload];
                processed_resp.forEach(element => {
                    let temp_arr = JSON.parse("[" + element.weekday_schedule + "]");
                    let new_weekday_schedule = [0, 0, 0, 0, 0, 0, 0];
                    temp_arr.forEach(item => new_weekday_schedule[item] = 1);
                    element.weekday_schedule = new_weekday_schedule;
                });

                return { ...INITIAL_STATE, schedule_list: processed_resp, loading: false, is_logout: false };
            }
            else
                return { ...state, loading: false, is_logout: false };
        case SPSCHEDULELIST_ERROR:
            let hNE_res = handleNetworkError(action);
            if (hNE_res.error)
                return { ...INITIAL_STATE, ...hNE_res, loading: false, is_logout: false };
            else if (action.payload && action.payload.response && action.payload.response.data && action.payload.response.data.error) {
                if (action.payload.response.data.error.statusCode === 401)
                    return { ...INITIAL_STATE, error: action.payload.response.data.error, loading: false, is_logout: true };
                else
                    return { ...INITIAL_STATE, error: action.payload.response.data.error, loading: false, is_logout: false };
            }
            else
                return { ...INITIAL_STATE, error: action.payload, loading: false, is_logout: false };
        case LOGOUT_RESET: return { ...INITIAL_STATE };
        default: return state;
    }
}