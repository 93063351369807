import React, { PureComponent } from 'react';

import Dialog from 'material-ui/Dialog';
import { List, ListItem } from 'material-ui/List';
import Avatar from 'material-ui/Avatar';

import DownArrow from 'material-ui/svg-icons/hardware/keyboard-arrow-down';
import AddIcon from 'material-ui/svg-icons/content/add';
import PropertyIcon from 'material-ui/svg-icons/communication/business';
import { selectSocietyRoute } from 'routes-parameters/config';

import gs_color from 'config/colors.css';

const styles = {
    Switcher: {
        border: `1px solid #E0E0E0`,
        borderRadius: '5px',
        background: `linear-gradient(90deg, ${gs_color.gradientLightColor}, ${gs_color.gradientDarkColor})`,
        padding: '8px 16px',
        color: gs_color.textColor,
        display: 'flex',
        flex: 1,
        alignItems: 'center',
        cursor: 'pointer',
        minWidth: 0
    }
}

class PropertySwitcher extends PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            showSwitcherDialog: false
        }
    }

    getProperties = () => {
        const allPropNotifData = this.props.allPropNotificationData && this.props.allPropNotificationData.constructor === Array
            ? this.props.allPropNotificationData : []

        let array = this.props.activeProperties.map(property => {
            const propertyNotification = allPropNotifData.filter(data => data.prop_id === property.prop_id);
            const count = propertyNotification && propertyNotification.length > 0 && propertyNotification[0].count ? propertyNotification[0].count : 0;

            return (
                <ListItem
                    key={property.prop_id}
                    leftAvatar={<Avatar icon={<PropertyIcon />} backgroundColor={this.props.selectedPropId === property.prop_id ? gs_color.primaryColor : '#bcbcbc'} />}
                    primaryText={property.prop_name}
                    secondaryText={property.community_name}
                    rightAvatar={
                        count === 0 ? null :
                            <Avatar size={24} backgroundColor={'red'} color={'white'} >
                                {count > 99 ? "99+" : count}
                            </Avatar>
                    }
                    onClick={() => this.onPropertyClick(property)}
                />
            )
        })

        if (this.props.disableAdd !== true) {
            array.push(
                <ListItem
                    key="Add Property"
                    leftAvatar={<Avatar icon={<AddIcon />} />}
                    primaryText="Add Property"
                    onClick={() => this.props.history.push(`/${selectSocietyRoute}`)}
                />
            )
        }

        return (
            <List>
                {array}
            </List>
        )
    }

    onPropertyClick = property => {
        this.setState({ showSwitcherDialog: false })
        this.props.onPropertyChanged(property);
    }

    render() {
        const selectedProperty = this.props.activeProperties.filter(prop => prop.prop_id === this.props.selectedPropId);
        return (
            <div style={{ flex: 1, display: 'flex', minWidth: 0 }}>
                <div id="property-switcher" style={styles.Switcher} onClick={() => this.setState({ showSwitcherDialog: true })}>
                    <div style={{ flex: 1, display: 'inline-flex', alignItems: 'center', minWidth: 0 }}>
                        <div style={{ fontSize: '15px', fontWeight: 'bold' }}>
                            {`${selectedProperty && selectedProperty.length > 0 && selectedProperty[0].prop_name} :`}
                        </div>
                        <div style={{ fontSize: '12px', marginTop: '4px', marginLeft: '8px', marginRight: '8px', marginBottom: '4px', flex: 1, textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}>
                            {selectedProperty && selectedProperty.length > 0 && selectedProperty[0].community_name}
                        </div>
                        {
                            this.props.activeProperties.length > 1 && 
                            this.props.allPropNotificationCount !== undefined && this.props.allPropNotificationCount !== null &&
                            this.props.allPropNotificationCount > 0 &&
                            <Avatar size={24} backgroundColor='#FFF' color={gs_color.textColor}>
                                {this.props.allPropNotificationCount > 99 ? "99+" : this.props.allPropNotificationCount}
                            </Avatar>
                        }
                        <div style={{ display: 'flex', marginTop: '2px' }}>
                            <DownArrow size={30} color={gs_color.backgroundDarkColor} />
                        </div>
                    </div>
                </div>
                {
                    this.state.showSwitcherDialog &&
                    <Dialog
                        open={this.state.showSwitcherDialog}
                        onRequestClose={() => this.setState({ showSwitcherDialog: false })}
                        autoScrollBodyContent={true}
                        titleClassName="dialogTitleClassName"
                        bodyClassName="dialogBodyClassName"
                        contentClassName="DialogNewStyle"
                    >
                        {this.getProperties()}
                    </Dialog>
                }
            </div>
        )
    }
}

export default PropertySwitcher;