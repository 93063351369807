
import moment from 'moment';
import { cl_user_id,cl_selected_address } from 'config/storage-variables';
import { buildCartAddressData, getCommunityIdFromAddress } from './product-address-config';
import { getMaxWeightUnit, kgLbConversion } from './product-weight-limit-config';
import { city } from 'config/storage-variables';
import { isNullOrEmptyObject, isNullOrEmptyArray, displayWithCurrency } from 'utils/helper-functions';
import { DEFAULT_DELIVERY_TIME_SLOT, COMM_PGWT_FEES } from 'config/config';

export const createSaveCartData = (reducerState) => {
    const { cart_order, cart_items, city_id, community_id, product_address, shpinfo_id, cp_prop_id } = parseReducerState(reducerState);

    let data = {
        owner_id: localStorage.getItem(cl_user_id),
        city_id: city_id,
        community_id: community_id,
        cp_prop_id: cp_prop_id ? cp_prop_id : undefined,
        shpinfo_id: shpinfo_id ? shpinfo_id : undefined
    };

    data = buildCartAddressData(data, product_address);

    const items = [];
    if (!isNullOrEmptyObject(cart_items) && !isNullOrEmptyArray(cart_order)) {
        for (const product_id of cart_order) {
            const product = cart_items[product_id];

            if (product.psku_cart) {
                for (const psku_id of Object.keys(product.psku_cart)) {
                    const psku = product.psku_cart[psku_id];
                    items.push({
                        psku_id: psku_id,
                        quantity: psku.cartCount,
                        pprice_id: psku.sku_price && psku.sku_price.pprice_id,
                        pdiscnt_id: psku.sku_price && psku.sku_price.pdiscount_id,
                        pinvt_id: psku.sku_price && psku.sku_price.inventory_id
                    })
                }
            }
        }
    }

    if (!isNullOrEmptyObject(data.delv_address)) {
        delete data.cp_prop_id;
        delete data.shpinfo_id;
        delete data.community_id;
    }

    data.cart_items = items;

    return data;
}

export const createCheckoutCartData = (reducerState, email) => {
    const { cart_id, city_id, community_id, product_address, order_total, payment_amount, discount_total, item_count, delivery_date, isCOD, useRewards } = parseReducerState(reducerState);

    let data = {
        customer_id: localStorage.getItem(cl_user_id),
        cart_id,
        city_id,
        community_id,
        order_total: payment_amount,
        discount_total,
        item_count,
        delivery_date,
        isCOD,
        email,
        is_cl_redeem_reward: useRewards
    };

    data = buildCartAddressData(data, product_address);

    return data;
}

export function parseReducerState(state) {
    let city_name = localStorage.getItem(city) ? JSON.parse(localStorage.getItem(city)) : null;

    let cart_order = [];
    let cart_items = {};
    let city_id = city_name && city_name.id;
    let product_address = undefined;

    const { product_cart, client_addresses } = state;

    //let selected_address = client_addresses && client_addresses.selected_address ? client_addresses.selected_address : JSON.parse(sessionStorage.getItem("new_shipping_address"));
    let selected_address = client_addresses && client_addresses.selected_address ? client_addresses.selected_address : 
                            (localStorage.getItem(cl_selected_address) ? JSON.parse(localStorage.getItem(cl_selected_address)) : undefined);

    let community_id = getCommunityIdFromAddress(selected_address);

    return {
        cart_order: product_cart && product_cart.cart_order ? product_cart.cart_order : cart_order,
        cart_items: product_cart && product_cart.cart_items ? product_cart.cart_items : cart_items,
        city_id: city_id,
        community_id: community_id ? community_id : product_cart && product_cart.community_id,
        product_address: selected_address? selected_address : product_address,
        order_total: product_cart && product_cart.orderTotal,
        payment_amount: product_cart && product_cart.paymentAmount,
        discount_total: product_cart && product_cart.discountTotal,
        item_count: product_cart && product_cart.totalItems,
        cart_id: product_cart && product_cart.cart_id,
        delivery_date: product_cart && product_cart.deliveryDate,
        shpinfo_id: product_cart && product_cart.shpinfo_id,
        cp_prop_id: product_cart && product_cart.cp_prop_id,
        isCOD: product_cart && !product_cart.onlineRedirect,
        useRewards: product_cart.useRewards
    }
}

export function getReducerAddressFilter(state) {
    const { product_cart } = state;

    if (!product_cart) {
        return undefined;
    }

    return {
        community_id: product_cart.community_id,
        shpinfo_id: product_cart.shpinfo_id,
        cp_prop_id: product_cart.cp_prop_id
    }
}

export const shouldSaveLocally = (local_last_updated_at, pcart) => {
    if (pcart) {
        if (local_last_updated_at) {
            const online_last_updated_at = pcart.last_updatedat;
            if (online_last_updated_at) {
                const localMoment = moment(local_last_updated_at).add(3, 'seconds');
                const onlineMoment = moment(online_last_updated_at).set({ millisecond: 0 });
                return onlineMoment.isAfter(localMoment)
            }
        }

        return true;
    }

    return false;
}

export const parseOnlineCart = pcart => {
    if (pcart) {
        const owner_id = pcart.owner_id;
        const cart_id = pcart.pcart_id;
        const city_id = pcart.city_id;
        const community_id = pcart.community_id;
        const shpinfo_id = pcart.shpinfo_id;
        const cp_prop_id = pcart.pcart_info && pcart.pcart_info.cp_prop_id;
        const prop_id = pcart.pcart_info && pcart.pcart_info.prop_id;
        const last_updatedat = moment.utc().format();
        const deliveryTimeSlot = pcart.PORules && pcart.PORules.DeliveryTimeslot ? pcart.PORules.DeliveryTimeslot : DEFAULT_DELIVERY_TIME_SLOT;
        const deliveryDate = pcart.PORules && pcart.PORules.minimumDeliveryDate ? pcart.PORules.minimumDeliveryDate : null;
        const minimumDeliveryDate = pcart.PORules && pcart.PORules.minimumDeliveryDate ? pcart.PORules.minimumDeliveryDate : null;
        const maximumDeliveryDate = pcart.PORules && pcart.PORules.maximumDeliveryDate ? pcart.PORules.maximumDeliveryDate : null;
        const convenienceFeesRate = pcart.PORules && !isNaN(pcart.PORules.ConvenienceFees) ? +parseFloat(parseFloat(pcart.PORules.ConvenienceFees) * 100).toFixed(2) : COMM_PGWT_FEES
        const deliveryCharges = pcart.PORules && !Object.is(parseFloat(pcart.PORules.DeliveryCharges), NaN) ? +parseFloat(pcart.PORules.DeliveryCharges).toFixed(2) : null;
        const totalItems = pcart.total_items ? pcart.total_items : 0;
        const orderTotal = pcart.order_total ? pcart.order_total : 0;
        const postDiscountTotal = pcart.postdiscount_total ? pcart.postdiscount_total : 0;
        const priceTotal = pcart.price_total ? pcart.price_total : 0;
        const discountTotal = pcart.discount_total ? pcart.discount_total : 0;
        const taxInfo = !isNullOrEmptyObject(pcart.tax_info) ? pcart.tax_info : null;
        const isCODEnabled = isPaymentOptionEnabled(pcart.pay_options && pcart.pay_options.COD);
        const isUpiEnabled = isPaymentOptionEnabled(pcart.pay_options && pcart.pay_options.UPI);
        const isPgwtEnabled = isPaymentOptionEnabled(pcart.pay_options && pcart.pay_options.PGWTY);
        const minOrderAmt = pcart.PORules && pcart.PORules.MinOrderAmount ? pcart.PORules.MinOrderAmount : 0;
        const minFreeDelvAmt = pcart.PORules && pcart.PORules.MinOrderAmountNoDelvCharges ? pcart.PORules.MinOrderAmountNoDelvCharges : 0;
        const minOrderAmtBrandWise = pcart.PORules && pcart.PORules.MinOrderAmountBrandWise ? pcart.PORules.MinOrderAmountBrandWise : null;
        const tipEditable = pcart.PORules && pcart.PORules.DeliveryTipOptions && !!pcart.PORules.DeliveryTipOptions.user_editable;
        const tipAmountList = pcart.PORules && pcart.PORules.DeliveryTipOptions && pcart.PORules.DeliveryTipOptions.options;
        const tipDefaultAmount = pcart.PORules && pcart.PORules.DeliveryTipOptions && pcart.PORules.DeliveryTipOptions.default;
        let maxWeightAllowed = pcart.PORules && pcart.PORules.MaxWeightPerOrder;
        const redeemRewardMultipler = pcart.PORules && pcart.PORules.RedeemRewardMultipler ? parseFloat(pcart.PORules.RedeemRewardMultipler) : 100;
        const maxRedeemableRewardDiscount = pcart.PORules && pcart.PORules.MaxRedeemableRewardDiscount ? parseFloat(pcart.PORules.MaxRedeemableRewardDiscount) : null;
        const cl_rewards_obj = !isNullOrEmptyObject(pcart.cl_rewards_obj) ? pcart.cl_rewards_obj : null;
        if (cl_rewards_obj && cl_rewards_obj.redeemable_amount) {
            const order_reward_discount = parseInt((orderTotal * redeemRewardMultipler) / 100, 10);
            const max_reward_discount = maxRedeemableRewardDiscount !== null && order_reward_discount > maxRedeemableRewardDiscount ? maxRedeemableRewardDiscount : order_reward_discount;
            if (cl_rewards_obj.redeemable_amount > max_reward_discount) {
                const reward_valuation = (cl_rewards_obj.redeemable_rewards / cl_rewards_obj.redeemable_amount);
                cl_rewards_obj.redeemable_rewards = Math.floor(max_reward_discount * reward_valuation);
                cl_rewards_obj.redeemable_amount = Math.floor(cl_rewards_obj.redeemable_rewards / reward_valuation);
            }
        }

        const unit = getMaxWeightUnit();
        if (unit === "lb") {
            // Convert maxWeightAllowed into lb

            maxWeightAllowed = kgLbConversion(1, maxWeightAllowed);
            maxWeightAllowed = parseInt(maxWeightAllowed, 10);
        }

        let cart_order = [];
        let cart_items = {};
        let brandWiseTotal = {};

        if (!isNullOrEmptyArray(pcart.cart_items)) {
            for (const item of pcart.cart_items) {
                const product_id = item.product_id;
                if (!cart_order.includes(product_id)) {
                    cart_order.push(product_id);
                }

                let psku_title = "";
                let psku_icon = "";
                let psize = "";
                let punit_code = "";
                let pbrand_id = null;
                let pbrand_name = "";
                let orderLimit = 1;

                const psku_disp_details = item.psku_disp_details;
                if (!cart_items[product_id]) {
                    cart_items[product_id] = {
                        product_id: product_id,
                        product_title: psku_disp_details && psku_disp_details.product_title,
                        product_desc: psku_disp_details && psku_disp_details.product_desc,
                        product_icon: psku_disp_details && psku_disp_details.product_icon,
                        psku_cart: {}
                    }
                }

                psku_title = psku_disp_details && psku_disp_details.product_title;
                psku_icon = psku_disp_details && psku_disp_details.product_icon;
                psize = psku_disp_details && psku_disp_details.psize;
                punit_code = psku_disp_details && psku_disp_details.Unit;
                pbrand_id = psku_disp_details && psku_disp_details.pbrand_id;
                pbrand_name = psku_disp_details && psku_disp_details.Brand;
                orderLimit = psku_disp_details && psku_disp_details.OrderLimit ? psku_disp_details.OrderLimit : 1;

                const psku_id = item.psku_id;
                const psku = {
                    psku_id: psku_id,
                    product_id: product_id,
                    psize: psize,
                    punit_code: punit_code,
                    psku_title: psku_title,
                    psku_icon: psku_icon,
                    pbrand_id: pbrand_id,
                    pbrand_name: pbrand_name,
                    sku_price: {
                        price: item.price,
                        discount_price: item.discounted_price,
                        discount: item.discount,
                        pprice_id: item.pprice_id,
                        pdiscount_id: item.pdiscnt_id,
                        inventory_id: item.pint_id,
                        quantity: orderLimit
                    },
                    cartCount: item.quantity
                }

                cart_items[product_id].psku_cart[psku_id] = psku

                if (!brandWiseTotal[psku.pbrand_id]) {
                    brandWiseTotal[psku.pbrand_id] = {
                        brand_name: psku.pbrand_name,
                        total: 0
                    }
                }
                brandWiseTotal[psku.pbrand_id].total += psku.sku_price.discount_price * psku.cartCount;
            }
        }

        return {
            owner_id,
            cart_id,
            cart_items,
            cart_order,
            brandWiseTotal,
            community_id,
            shpinfo_id,
            cp_prop_id,
            prop_id,
            city_id,
            last_updatedat,
            deliveryTimeSlot,
            deliveryDate,
            minimumDeliveryDate,
            maximumDeliveryDate,
            convenienceFeesRate,
            deliveryCharges,
            totalItems,
            orderTotal,
            paymentAmount: orderTotal,
            priceTotal,
            postDiscountTotal,
            discountTotal,
            taxInfo,
            isCODEnabled,
            isUpiEnabled,
            isPgwtEnabled,
            minOrderAmt,
            minFreeDelvAmt,
            minOrderAmtBrandWise,
            tipEditable,
            tipAmountList,
            tipDefaultAmount,
            maxWeightAllowed,
            redeemRewardMultipler,
            maxRedeemableRewardDiscount,
            cl_rewards_obj
        }
    }

    return null;
}

export const getCartAddressDataFromCart = product_cart => {
    return {
        community_id: product_cart.community_id,
        shpinfo_id: product_cart.shpinfo_id,
        cp_prop_id: product_cart.cpt_id,
        prop_id: product_cart.prop_id
    }
}

function isPaymentOptionEnabled(flag) {
    if (flag) {
        if (flag.constructor === Boolean) {
            return flag;

        } else if (flag.constructor === String) {
            return flag.toLowerCase() === "true";
        }
    }

    return false;
}