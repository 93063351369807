import React from 'react';
import Badge from 'material-ui/Badge';
import SocialNotifications from 'material-ui/svg-icons/social/notifications';
import Spin from 'material-ui/svg-icons/notification/sync';
import Lock from 'material-ui/svg-icons/action/lock';

function NotificationBell(props) {
    return (
        <div style={{ cursor: 'pointer' }} onClick={() => props.handleClick()}>
            <Badge
                badgeContent={
                    props.not_signed ?
                        <span><Lock style={{ color: 'white', height: "16px", width: "16px" }} /></span>
                        : props.loading ?
                            <div><span><Spin style={{ color: 'white', height: "16px", width: "16px" }} /></span></div>
                            : props.count
                }
                style={{ paddingTop: "13px" }}
                badgeStyle={{ top: 5, right: 8, fontWeight: "600", background: "rgb(220, 0, 78)", color: "white", fontStyle: "13px" }}
            >
                <SocialNotifications />
            </Badge>
        </div>
    );
}
export default NotificationBell;