import React from 'react';
import { Link } from 'react-router-dom';
import AppBar from 'material-ui/AppBar';
import { tags } from 'tags';
import DNhoodLogo from 'assets/dnhood-logo.png';
import CompanyLogo from 'assets/Logo.svg';
import { aboutUsRoute } from 'routes-parameters/config';
import { isDNhoodEnv } from 'utils/helper-functions';

class MyAppBar extends React.PureComponent {
  static defaultProps = {
    iconStyleLeft: {},
    iconStyleRight: {},
    titleStyle: {},
    style: {}
  };

  render() {
    const companyLogo = <Link to={`/${aboutUsRoute}`}><img src={isDNhoodEnv() ? DNhoodLogo : CompanyLogo} style={{ width: '30px', height: '30px', marginLeft: '5px', marginRight: '7px' }} className="myAppBarLeftIcon" alt="OlaGate" /></Link>;

    return (
      <AppBar
        title={this.props.title !== null && this.props.title !== undefined ? this.props.title : tags.appName()}
        className={this.props.className}
        iconElementLeft={this.props.elementLeft === "none" ? null : this.props.elementLeft ? this.props.elementLeft : companyLogo}
        iconElementRight={this.props.elementRight}
        iconStyleLeft={{ margin: 'auto', width: '24px', height: '24px', ...(this.props.iconStyleLeft || {}), ...(this.props.elementLeft === "none" ? { display: 'none' } : {}) }}
        iconStyleRight={Object.assign({ margin: 'auto' }, this.props.iconStyleRight)}
        titleStyle={Object.assign({ height: 'unset', lineHeight: 'unset', margin: 'auto', marginLeft: '5%' }, this.props.titleStyle)}
        style={Object.assign({ paddingRight: '0px' }, this.props.style)}
      />
    );
  }
}

MyAppBar.displayName = 'MyAppBar';
export default MyAppBar;