import { REHYDRATE } from 'redux-persist';
import { PRODUCT_LIST, PRODUCT_LIST_MORE, PRODUCT_LIST_SUCCESS, PRODUCT_LIST_ERROR } from '../../actions/types';
import ApiError, { handleNetworkError } from '../../utils/api-error';
import moment from 'moment';

const INITIAL_STATE = {
    product_list: [],
    cache: null,
    cache_ts: null,
    last_product_id: undefined,
    total_count: 0,
    can_load_more: false,
    error: undefined,
    loading: false,
    is_logout: false
};

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case REHYDRATE: if (action.payload && action.payload.product_list) return { ...action.payload.product_list }; else return INITIAL_STATE;
        case PRODUCT_LIST: return { ...INITIAL_STATE, loading: true, is_logout: false };
        case PRODUCT_LIST_MORE: return { ...state, loading: true, is_logout: false };
        case PRODUCT_LIST_SUCCESS:
            const apiData = action.payload && action.payload.apiData;
            const cache = action.payload && action.payload.cache;
            const cache_ts = moment.utc();
            const batchSize = action.payload && action.payload.batchSize;

            if (apiData !== undefined && apiData !== null) {
                const product_list = ApiError(apiData.statusCode, apiData.message, apiData.gsRespData, "product_list", []);
                if (product_list && product_list.constructor === Array) {
                    const state_list = state.product_list && state.product_list.constructor === Array ? state.product_list : [];
                    const req_list = [...state_list, ...product_list];

                    let last_id = undefined;
                    if (req_list.length > 0) {
                        last_id = req_list[req_list.length - 1].product_id;
                    }

                    let loadMore = false;
                    if (product_list.length === batchSize) {
                        loadMore = true;
                    }

                    const totalCount = apiData.gsRespData.total_count ? apiData.gsRespData.total_count : 0;

                    return { ...state, loading: false, is_logout: false, product_list: req_list, cache: cache, cache_ts: cache_ts, last_product_id: last_id, can_load_more: loadMore, total_count: totalCount }

                } else {
                    return { ...state, loading: false, is_logout: false, error: product_list, can_load_more: false };
                }

            } else {
                return { ...state, loading: false, is_logout: false };
            }
        case PRODUCT_LIST_ERROR:
            let hNE_res = handleNetworkError(action);
            if (hNE_res.error)
                return { ...INITIAL_STATE, ...hNE_res, loading: false, is_logout: false };
            else if (action.payload && action.payload.response && action.payload.response.data && action.payload.response.data.error) {
                if (action.payload.response.data.error.statusCode === 401)
                    return { ...INITIAL_STATE, error: action.payload.response.data.error, loading: false, is_logout: true };
                else
                    return { ...INITIAL_STATE, error: action.payload.response.data.error, loading: false, is_logout: false };
            }
            else
                return { ...INITIAL_STATE, error: action.payload, loading: false, is_logout: false };
        default: return state;
    }
}