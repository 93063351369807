export default function PermissionDialog() {
    function handleDialog(event) {
        if (event.type === "favDialog") {
            //console.log("Dialog Opened");
            document.getElementById("favDialog")
        }
        else if (event.type === "DialogAllow") {
            //console.log("Dialog2 Opened");
            document.getElementById("DialogAllow")
        }
    }
    // window.addEventListener('favDialog', handleDialog);
    window.addEventListener('DialogAllow', handleDialog);

} 