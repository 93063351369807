import axios from 'middleware/axios';
import { API_LINK } from 'config/config';

export default function handleNetworkChange() {
    function handleConnectionChange(event) {
        if (event.type === "offline") {
            if (document.getElementById("offline") !== null || document.getElementById("online") !== null) {
                document.getElementById("offline").style.display = "block";
                document.getElementById("online").style.display = "none";
            }
        }
        if (event.type === "online") {
            if (document.getElementById("offline") !== null || document.getElementById("online") !== null) {
                document.getElementById("offline").style.display = "none";
                document.getElementById("online").style.display = "block";
                setTimeout(() => {
                    document.getElementById('online').style.display = 'none';
                }, 3000);
            }
            window.location.reload();
        }
    }

    axios.get(`${API_LINK}/gs_Services_Category?filter[limit]=1`)
        .then((res) => { })
        .catch((err) => {
            if (document.getElementById("offline") !== null || document.getElementById("online") !== null) {
                document.getElementById("offline").style.display = "block";
                document.getElementById("online").style.display = "none";
            }
        });
    window.addEventListener('online', handleConnectionChange);
    window.addEventListener('offline', handleConnectionChange);

}