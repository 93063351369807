import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { clearSocietyMsgBoardCache } from 'actions'

import Paper from 'material-ui/Paper'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faComment } from '@fortawesome/free-regular-svg-icons';
import AttachmentIcon from 'material-ui/svg-icons/editor/attach-file';

import { convertTimseStampToText } from 'utils/date-time-conversion'

import { getColorForFilter, getPostType } from '../../../society/scenes/message-board/utils'
import { societyMsgDetailsRoute } from 'routes-parameters/config';
import './styles.css'
import gs_color from 'config/colors.css'

export function SectionItemEmptyPostPreview() {
    return (
        <div style={{
            height: '100px',
            margin: '8px',
            padding: '8px 8px',
            borderRadius: '5px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            color: '#757575',
            border: '1px dashed #e0e0e0'
        }}>
            <div>No New Posts</div>
        </div>
    )
}

export function SectionItemPremiumPostPreview({ history, route }) {
    return (
        <div style={{
            height: '100px',
            margin: '8px',
            padding: '8px 8px',
            borderRadius: '5px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            color: '#757575',
            border: '1px dashed #e0e0e0'
        }}
            onClick={() => history.push(`${route}`)}
        >
            <div style={{ backgroundColor: gs_color.primaryColor75, padding: '4px 6px' }}>
                PREMIUM
            </div>
        </div>
    )
}

function SectionItemPostPreview({
    msgb_id, bulletin, posted_at, fullWidth, msgtype_id, feedTypes, msgTypeLabels,
    history, route, isEmergency, clearSocietyMsgBoardCache, no_of_comments
}) {
    const title = (bulletin && bulletin.title) || ""
    const postedAt = convertTimseStampToText(posted_at, 4)
    const msgBody = (bulletin && bulletin.msg_body) || ""
    const noOfDocs = (bulletin && bulletin.media && bulletin.media.doc && bulletin.media.doc.length) || 0
    const noOfPics = (bulletin && bulletin.media && bulletin.media.img && bulletin.media.img.length) || 0
    const noOfAttachments = noOfDocs + noOfPics
    const displayText = msgTypeLabels && msgTypeLabels[msgtype_id]
    const color = isEmergency ? "red" : getColorForFilter(msgtype_id)

    const handlePostTypeClick = e => {
        e.stopPropagation();
        const postType = getPostType(msgtype_id, feedTypes);
        if (postType) {
            clearSocietyMsgBoardCache()
            history.push(`${route}/${postType}`);
        }
    }

    return (
        <Paper
            onClick={() => history.push(`${societyMsgDetailsRoute}/${msgb_id}`)}
            style={{ height: 'auto', width: fullWidth ? 'calc(100% - 8px)' : 'calc(80% - 8px)', margin: '4px', padding: '6px 6px 2px 6px', borderRadius: '5px', display: 'inline-flex', flexDirection: 'column', cursor: 'pointer' }}>
            <div style={{ color: '#212121', fontWeight: 'bold', display: 'inline-block', wordBreak: 'break-all', overflow: 'hidden', textOverflow: 'ellipsis', height: '20px', textAlign: 'left' }}>
                {title}
            </div>
            <div style={{ display: 'flex', height: '12px', flexDirection: 'row', alignItems: 'center' }}>
                <div
                    onClick={handlePostTypeClick}
                    className="post-type-label"
                    style={{ fontSize: '10px', color: color, display: 'flex', flexDirection: 'row', alignItems: 'center' }}
                >
                    {displayText}
                </div>
                <div style={{ fontSize: '10px', marginLeft: '8px', color: "#757575" }}>
                    {postedAt}
                </div>
            </div>
            <div style={{ color: '#212121', display: 'inline-block', wordBreak: 'break-all', overflow: 'hidden', textOverflow: 'ellipsis', marginTop: '4px', height: '18px', textAlign: 'left' }}>
                {msgBody}
            </div>
            <div style={{ display: 'flex', marginTop: '4px' }}>
                <div style={{ color: gs_color.accentColor, display: 'flex', alignItems: 'center' }}>
                    <FontAwesomeIcon className='svg-inline--fa' icon={faComment} color={gs_color.accentColor} style={{ width: '15px', height: '15px' }} />
                    <span style={{ marginLeft: '5px' }}>{no_of_comments || 0}</span>
                </div>
                <div style={{ marginLeft: '20px', color: gs_color.accentColor, display: 'flex', alignItems: 'center' }}>
                    <AttachmentIcon style={{ width: '15px', height: '15px' }} color={gs_color.accentColor} />
                    <span style={{ marginLeft: '5px' }}>{noOfAttachments || 0}</span>
                </div>
            </div>
        </Paper>
    )
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ clearSocietyMsgBoardCache }, dispatch);
}

export default connect(null, mapDispatchToProps)(SectionItemPostPreview)