import { REHYDRATE } from 'redux-persist';
import { SERVICES, SERVICES_SUCCESS, SERVICES_ERROR } from '../../actions/types';
import { ss_services } from 'config/storage-variables';
import { handleNetworkError } from '../../utils/api-error';

const INITIAL_STATE = {
    service_list: [],
    error: undefined,
    loading: true,
    is_logout: false
};

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case REHYDRATE: if (action.payload && action.payload.services) return { ...action.payload.services }; else return INITIAL_STATE;
        case SERVICES: return { ...INITIAL_STATE, loading: true, is_logout: false };
        case SERVICES_SUCCESS:
            if (action.payload !== undefined && action.payload !== null) {
                if (action.payload.gsRespData && action.payload.gsRespData.service_list && action.payload.gsRespData.service_list.length > 0) {
                    sessionStorage.setItem(ss_services, JSON.stringify(action.payload.gsRespData.service_list));
                    return { ...INITIAL_STATE, service_list: action.payload.gsRespData.service_list, loading: false, is_logout: false };
                }
                else if (action.payload.gsRespData) {
                    sessionStorage.setItem(ss_services, JSON.stringify([]));
                    return { ...INITIAL_STATE, loading: false, is_logout: false };
                }
            }
            else
                return { ...state, loading: false, is_logout: false };
            break;
        case SERVICES_ERROR:
            let hNE_res = handleNetworkError(action);
            if (hNE_res.error)
                return { ...INITIAL_STATE, ...hNE_res, loading: false, is_logout: false };
            else if (action.payload && action.payload.response && action.payload.response.data && action.payload.response.data.error) {
                if (action.payload.response.data.error.statusCode === 401)
                    return { ...INITIAL_STATE, error: action.payload.response.data.error, loading: false, is_logout: true };
                else
                    return { ...INITIAL_STATE, error: action.payload.response.data.error, loading: false, is_logout: false };
            }
            else
                return { ...INITIAL_STATE, error: action.payload, loading: false, is_logout: false };
        default: return state;
    }
}