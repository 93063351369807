import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { clearSocietyMsgBoardCache } from 'actions'

import SectionHeader from '../../section-header'
import SectionHorizontalScroll from '../../section-horizontal-scroll'

import SectionItemPostPreview, { SectionItemEmptyPostPreview, SectionItemPremiumPostPreview } from '../../section-item-post-preview'

import { societyMsgBoardRoute } from 'routes-parameters/config'

function SectionEmergencyView({ history, communityPosts, msgTypeLabels, feedTypes, clearSocietyMsgBoardCache, disable }) {
    return (
        <div>
            <SectionHeader
                title="EMERGENCY"
                titleColor="red"
                onActionClick={() => {
                    clearSocietyMsgBoardCache()
                    history.push(`/${societyMsgBoardRoute}/emergency`)
                }}
            />
            <SectionHorizontalScroll
                data={renderData(communityPosts, msgTypeLabels, history, feedTypes, disable)}
                maxHorizontalItems={1}
            />
        </div>
    )
}

function renderData(communityPosts, msgTypeLabels, history, feedTypes, disable) {
    if (disable) {
        return [<SectionItemPremiumPostPreview history={history} route={societyMsgBoardRoute} key="Premium" />]
    }

    if (communityPosts && communityPosts.length > 0) {
        return communityPosts.map(data =>
            <SectionItemPostPreview
                key={data.msgb_id}
                {...data}
                msgTypeLabels={msgTypeLabels}
                history={history}
                route={societyMsgBoardRoute}
                isEmergency={true}
                feedTypes={feedTypes}
            />
        )
    }

    return [<SectionItemEmptyPostPreview key="Empty" />]
}

function mapStateToProps({ user_home_screen }) {
    const { alert_posts, boardFeedTypes } = user_home_screen;

    return {
        communityPosts: alert_posts || [],
        feedTypes: boardFeedTypes
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ clearSocietyMsgBoardCache }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(SectionEmergencyView)