import React from 'react';
import NoServiceFoundIcon from 'scenes/client/scenes/home/scenes/upcoming-services/assets/info.svg';
import { strings } from 'strings';

function NoServiceProducts({ title, description }) {
    return (
        <div>
            <div style={{ paddingTop: "120px", textAlign: "center" }}>
                <img width="82px" height="82px" src={NoServiceFoundIcon} alt="No Service Found" />
                <div style={{ paddingTop: '20px', fontWeight: '500', fontSize: '17px' }}>{title ? title : strings.service_not_in_area}</div>
                <div style={{ paddingTop: '10px', fontSize: '13px' }}>{description ? description : strings.service_not_in_area_string}</div>
            </div>
        </div>
    );
}

export default NoServiceProducts;