import React from 'react';
import { BottomNavigation } from 'material-ui/BottomNavigation';
import HomeIcon from '../assets/home.svg';
import HomeSelIcon from '../assets/home-sel.svg';
import ProfileIcon from '../assets/user.svg';
import ProfileSelIcon from '../assets/user-sel.svg';
import BookingsIcon from '../assets/shopping-bag.svg';
import BookingsSelIcon from '../assets/shopping-bag-sel.svg';

import Paper from 'material-ui/Paper';
import './styles.css';
import { tags } from 'tags';
import { spBookingRoute, spHomeRoute, spProfileRoute, timeslotRoute } from 'routes-parameters/config';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt as faCalendarOutline } from '@fortawesome/free-regular-svg-icons';
import gs_color from 'config/colors.css';

class OldBottomNav extends React.PureComponent {
  static defaultProps = {
    push: false
  }

  constructor() {
    super();
  }

  Clicked(val) {
    if (val === 0) {
      if (this.props.push) {
        this.props.history.push(`/${spHomeRoute}`);
      }
      else {
        this.props.history.replace(`/${spHomeRoute}`);
      }
    }
    else if (val === 1) {
      if (this.props.push) {
        this.props.history.push(`/${spBookingRoute}`);
      }
      else {
        this.props.history.replace(`/${spBookingRoute}`);
      }
    }
    else if (val === 2) {
      if (this.props.push) {
        this.props.history.push(`/${timeslotRoute}`);
      }
      else {
        this.props.history.replace(`/${timeslotRoute}`);
      }
    }
    else if (val === 3) {
      if (this.props.push) {
        this.props.history.push(`/${spProfileRoute}`);
      }
      else {
        this.props.history.replace(`/${spProfileRoute}`);
      }
    }
  }

  render() {
    return (
      <Paper zDepth={1} className="mobileOnly">
        <BottomNavigation selectedIndex={this.props.selectedIndex} className="BottomNav">
          <div
            style={{ padding: '8px 0px', textAlign: 'center', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}
            onClick={() => this.Clicked(0)}
          >
            <div>
              <img
                src={this.props.selectedIndex === 0 ? HomeSelIcon : HomeIcon}
                style={{ width: '25px', height: '25px' }}
              />
              <div style={{
                fontSize: '11px',
                color: this.props.selectedIndex === 0 ? gs_color.primaryColor : gs_color.textColor,
                fontWeight: this.props.selectedIndex === 0 ? 'bold' : 'normal'
              }}>{tags.home}</div>
              <Link to={`/${spHomeRoute}`} onClick={(event) => { event.preventDefault() }}></Link>
            </div>
          </div>
          <div
            style={{ padding: '8px 0px', textAlign: 'center', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}
            onClick={() => this.Clicked(1)}
          >
            <div>
              <img
                src={this.props.selectedIndex === 1 ? BookingsSelIcon : BookingsIcon}
                style={{ width: '25px', height: '25px' }}
              />
              <div style={{
                fontSize: '11px',
                color: this.props.selectedIndex === 1 ? gs_color.primaryColor : gs_color.textColor,
                fontWeight: this.props.selectedIndex === 1 ? 'bold' : 'normal'
              }}>{tags.booking}</div>
              <Link action="replace" to={`/${spBookingRoute}`} onClick={(event) => { event.preventDefault() }}></Link>
            </div>
          </div>
          <div
            style={{ padding: '8px 0px', textAlign: 'center', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}
            onClick={() => this.Clicked(2)}
          >
            <div>
              <FontAwesomeIcon className='svg-inline--fa' icon={faCalendarOutline} aria-hidden="true" style={{ width: "100%", height: "22px", color: this.props.selectedIndex === 2 ? gs_color.primaryColor : gs_color.textColor }} />
              <div style={{
                fontSize: '11px',
                color: this.props.selectedIndex === 2 ? gs_color.primaryColor : gs_color.textColor,
                fontWeight: this.props.selectedIndex === 2 ? 'bold' : 'normal'
              }}>Calendar</div>
            </div>
          </div>

          <div
            style={{ padding: '8px 0px', textAlign: 'center', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}
            onClick={() => this.Clicked(3)}
          >
            <div>
              <img
                src={this.props.selectedIndex === 3 ? ProfileSelIcon : ProfileIcon}
                style={{ width: '25px', height: '25px' }}
              />
              <div style={{
                fontSize: '11px',
                color: this.props.selectedIndex === 3 ? gs_color.primaryColor : gs_color.textColor,
                fontWeight: this.props.selectedIndex === 3 ? 'bold' : 'normal'
              }}>Account</div>
              <Link action="replace" to={`/${spProfileRoute}`} onClick={(event) => { event.preventDefault() }}></Link>
            </div>
          </div>
        </BottomNavigation>
      </Paper>
    );
  }
}

OldBottomNav.displayName = 'OldBottomNav';
export default OldBottomNav;