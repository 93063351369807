import React from 'react'

import SectionHeader from '../../section-header'
import SectionHorizontalScroll from '../../section-horizontal-scroll'
import SectionItemPollPreview, { SectionItemEmptyPollPreview } from '../../section-item-poll-preview'
import { SectionItemPremiumPostPreview } from '../../section-item-post-preview'

import { pollsRoute } from 'routes-parameters/config'
import { parsePollObj } from '../../../../society/scenes/polls/utils';

function SectionPolls({ history, pollList, title, disable }) {
    return (
        <div>
            <SectionHeader
                title={title || "Polls"}
                onActionClick={() => {
                    history.push(`/${pollsRoute}`)
                }}
            />
            <SectionHorizontalScroll
                data={renderData(history, pollList, disable)}
                maxHorizontalItems={1}
            />
        </div>
    )
}

function renderData(history, pollList, disable) {
    if (disable) {
        return [<SectionItemPremiumPostPreview history={history} route={pollsRoute} key="Premium" />]
    }

    const list = pollList;
    if (list && list.length > 0) {
        return list.map(poll => {
            const pollObj = parsePollObj(poll);
            return (
                <SectionItemPollPreview
                    key={poll.poll_id}
                    pollObj={pollObj}
                    history={history}
                    route={pollsRoute}
                />
            )
        }
        )
    }

    return [<SectionItemEmptyPollPreview key="Empty" />]
}

export default SectionPolls