import { REHYDRATE } from 'redux-persist'

import ApiError from '../../utils/api-error'
import {
    LOGOUT_RESET, SETTLEMENT_DETAILS, SETTLEMENT_DETAILS_SUCCESS,
    SETTLEMENT_DETAILS_ERROR, SETTLEMENT_DETAILS_CLEAR_CACHE
} from '../../actions/types';

const INITIAL_STATE = {
    settlement_details: {},
    loading: true
}

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case REHYDRATE: if (action.payload && action.payload.settlement_details) return { ...action.payload.settlement_details }; else return INITIAL_STATE;

        case SETTLEMENT_DETAILS: return { ...state, loading: true };

        case SETTLEMENT_DETAILS_SUCCESS:
            const apiData = action.payload && action.payload.apiData;

            if (apiData !== undefined && apiData !== null) {
                let settlement_details = ApiError(apiData.statusCode, apiData.message, apiData.gsRespData, "settlement_details", {});

                if (settlement_details && typeof settlement_details === "object") {
                    let state_settlement_details = { ...state.settlement_details };

                    if (action.payload.is_pending) {
                        state_settlement_details["pending"] = settlement_details;
                    }
                    else {
                        state_settlement_details[settlement_details.grs_settle_id] = settlement_details;
                    }

                    return { ...state, loading: false, settlement_details: state_settlement_details }

                } else {
                    return { ...state, loading: false };
                }

            } else {
                return { ...state, loading: false };
            }

        case SETTLEMENT_DETAILS_ERROR:
            return { ...state, loading: false };

        case SETTLEMENT_DETAILS_CLEAR_CACHE:
            let settlement_details = { ...state.settlement_details };
            if (action.payload.settlement_id) {
                delete settlement_details[action.payload.settlement_id];
            }
            else {
                settlement_details = {};
            }
            return { ...state, settlement_details: settlement_details };

        case LOGOUT_RESET:
            return { ...INITIAL_STATE };

        default: return state;
    }
}