import { REHYDRATE } from 'redux-persist';
import { HOME_SCREEN_DATA, HOME_SCREEN_DATA_SUCCESS, HOME_SCREEN_DATA_FAILURE } from '../../actions/types';
import ApiError, { handleNetworkError } from '../../utils/api-error';
import { ads_fetched } from 'config/storage-variables';

const INITIAL_STATE = {
    data: [],
    error: undefined,
    loading: true,
    is_logout: false,
    city_id: null
};

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case REHYDRATE: if (action.payload && action.payload.home_screen) return { ...action.payload.home_screen }; else return INITIAL_STATE;
        case HOME_SCREEN_DATA: return { ...INITIAL_STATE, loading: true, is_logout: false };
        case HOME_SCREEN_DATA_SUCCESS:
            if (action.payload !== undefined && action.payload !== null) {
                let resp = ApiError(action.payload.statusCode, action.payload.message, action.payload.gsRespData, "data", []);
                if (resp && resp.length > 0) {
                    return { ...INITIAL_STATE, data: resp, city_id: action.city_id, loading: false, is_logout: false };
                }
                else if (resp) {
                    return { ...INITIAL_STATE, city_id: action.city_id, loading: false, is_logout: false };
                }
            }
            else
                return { ...state, loading: false, is_logout: false };
            break;
        case HOME_SCREEN_DATA_FAILURE:
            let hNE_res = handleNetworkError(action);
            if (hNE_res.error)
                return { ...INITIAL_STATE, ...hNE_res, loading: false, is_logout: false };
            else if (action.payload && action.payload.response && action.payload.response.data && action.payload.response.data.error) {
                if (action.payload.response.data.error.statusCode === 401)
                    return { ...INITIAL_STATE, error: action.payload.response.data.error, loading: false, is_logout: true };
                else
                    return { ...INITIAL_STATE, error: action.payload.response.data.error, loading: false, is_logout: false };
            }
            else
                return { ...INITIAL_STATE, error: action.payload, loading: false, is_logout: false };
        default: return state;
    }
}