import React from 'react';
import Loadable from 'react-loadable';
import FallbackUI from './imports-ui/fallback';
import ErrorChunkUI from './imports-ui/error';
import TimeoutChunkUI from './imports-ui/time-out';

function Loading(props) {
    if (props.error) {
        return <ErrorChunkUI {...props} />;
    } else if (props.timedOut) {
        return <TimeoutChunkUI {...props} />;
    } else if (props.pastDelay) {
        return <FallbackUI />;
    } else {
        return null;
    }
}

export default function MyLoadable(opts) {
    return Loadable(Object.assign({
        loading: Loading,
        delay: 200,
        timeout: 30 * 1000,         // 30 sec
    }, opts));
};