import { REHYDRATE } from 'redux-persist';
import { UPDATE_CHECKOUT_OPTIONS } from '../../../actions/types';

const INITIAL_STATE = {
    delivery_type: "",
    instructions: "",
    payment_mode: "",
    distance_api_called: false,
    address_distance: undefined,
};

const CheckoutOptions = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case REHYDRATE:
            return action.payload && action.payload.checkout_options
                ? { ...action.payload.checkout_options }
                : INITIAL_STATE;

        case UPDATE_CHECKOUT_OPTIONS:
            const data = action.payload;
            if (data) {
                const newState = {
                    delivery_type: data.delivery_type === undefined ? state.delivery_type : data.delivery_type,
                    instructions: data.instructions === undefined ? state.instructions : data.instructions,
                    payment_mode: data.payment_mode === undefined ? state.payment_mode : data.payment_mode,
                    distance_api_called: data.distance_api_called === undefined ? state.distance_api_called : data.distance_api_called,
                    address_distance: data.address_distance === undefined ? state.address_distance : data.address_distance
                };

                return newState;
            } else {
                return {
                    ...state,
                };
            }

        default:
            return state;
    }
};

export default CheckoutOptions;
