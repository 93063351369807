import { REHYDRATE } from 'redux-persist'

import ApiError, { handleNetworkError } from '../../utils/api-error'
import { SOCIETY_COMPLAINTS_DETAILS, SOCIETY_COMPLAINTS_DETAILS_SUCCESS, SOCIETY_COMPLAINTS_DETAILS_ERROR, LOGOUT_RESET } from '../../actions/types';

const INITIAL_STATE = {
    complaint_details: undefined,
    fetch_location: false,
    error: undefined,
    loading: true,
    is_logout: false
}

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case REHYDRATE: if (action.payload && action.payload.society_complaint_details) return { ...action.payload.society_complaint_details }; else return INITIAL_STATE;

        case SOCIETY_COMPLAINTS_DETAILS: return { ...INITIAL_STATE, loading: true };

        case SOCIETY_COMPLAINTS_DETAILS_SUCCESS:
            const apiData = action.payload;

            if (apiData !== undefined && apiData !== null) {
                let complaint_details = ApiError(apiData.statusCode, apiData.message, apiData.gsRespData, "complaint_details", {});
                const fetch_location = !!(apiData.gsRespData && apiData.gsRespData.fetch_location);

                if (complaint_details && complaint_details.ticket_id) {
                    return { ...state, loading: false, is_logout: false, complaint_details: complaint_details, fetch_location }

                } else {
                    return { ...state, loading: false, is_logout: false, error: complaint_details };
                }

            } else {
                return { ...state, loading: false, is_logout: false };
            }

        case SOCIETY_COMPLAINTS_DETAILS_ERROR:
            let hNE_res = handleNetworkError(action);
            if (hNE_res.error)
                return { ...INITIAL_STATE, ...hNE_res, loading: false, is_logout: false };
            else if (action.payload && action.payload.response && action.payload.response.data && action.payload.response.data.error) {
                if (action.payload.response.data.error.statusCode === 401)
                    return { ...INITIAL_STATE, error: action.payload.response.data.error, loading: false, is_logout: true };
                else
                    return { ...INITIAL_STATE, error: action.payload.response.data.error, loading: false, is_logout: false };
            }
            else
                return { ...INITIAL_STATE, error: action.payload, loading: false, is_logout: false };

        case LOGOUT_RESET:
            return { ...INITIAL_STATE };

        default: return state;
    }
}