import { REHYDRATE } from 'redux-persist';
import { SPSCHEDULEDETAILS, SPSCHEDULEDETAILS_SUCCESS, SPSCHEDULEDETAILS_ERROR, LOGOUT_RESET } from '../../actions/types';
import { handleNetworkError } from '../../utils/api-error';

const INITIAL_STATE = {
    schedule_details: [],
    booking_dates: [],
    error: undefined,
    loading: true,
    is_logout: false
};

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case REHYDRATE: if (action.payload && action.payload.sp_schedule_details) return { ...action.payload.sp_schedule_details }; else return INITIAL_STATE;
        case SPSCHEDULEDETAILS: return { ...INITIAL_STATE, loading: true, is_logout: false };
        case SPSCHEDULEDETAILS_SUCCESS:
            if (action.payload !== undefined && action.payload !== null) {
                let temp_schedule_details = {}, temp_booking_dates = {},
                    override_schedule_resp, booking_dates_resp, resp = action.payload.gsRespData;

                if (!resp) {
                    override_schedule_resp = [];
                    booking_dates_resp = [];
                }
                else {
                    if (!resp.override_schedule)
                        override_schedule_resp = [];
                    else
                        override_schedule_resp = resp.override_schedule;

                    if (!resp.booking_dates)
                        booking_dates_resp = [];
                    else
                        booking_dates_resp = resp.booking_dates;
                }

                state.schedule_details.forEach(element => {
                    temp_schedule_details[element.sch_date] = element;
                });

                override_schedule_resp.forEach(element => {
                    temp_schedule_details[element.sch_date] = element;
                });

                let new_schedule_details = [];
                for (const key in temp_schedule_details) {
                    new_schedule_details.push(temp_schedule_details[key]);
                }


                state.booking_dates.forEach(element => {
                    temp_booking_dates[element.start_time] = element;
                });

                booking_dates_resp.forEach(element => {
                    temp_booking_dates[element.start_time] = element;
                });

                let new_bookings_dates = [];
                for (const key in temp_booking_dates) {
                    new_bookings_dates.push(temp_booking_dates[key]);
                }

                return { ...INITIAL_STATE, schedule_details: new_schedule_details, booking_dates: new_bookings_dates, loading: false, is_logout: false };
            }
            else
                return { ...state, loading: false, is_logout: false };
        case SPSCHEDULEDETAILS_ERROR:
            let hNE_res = handleNetworkError(action);
            if (hNE_res.error)
                return { ...INITIAL_STATE, ...hNE_res, loading: false, is_logout: false };
            else if (action.payload && action.payload.response && action.payload.response.data && action.payload.response.data.error) {
                if (action.payload.response.data.error.statusCode === 401)
                    return { ...INITIAL_STATE, error: action.payload.response.data.error, loading: false, is_logout: true };
                else
                    return { ...INITIAL_STATE, error: action.payload.response.data.error, loading: false, is_logout: false };
            }
            else
                return { ...INITIAL_STATE, error: action.payload, loading: false, is_logout: false };
        case LOGOUT_RESET: return { ...INITIAL_STATE };
        default: return state;
    }
}