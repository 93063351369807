import { REHYDRATE } from 'redux-persist'

import ApiError, { handleNetworkError } from '../../utils/api-error'
import {
    LOGOUT_RESET, SOCIETY_BILLS, SOCIETY_BILLS_ERROR,
    SOCIETY_BILLS_MORE, SOCIETY_BILLS_SCROLL, SOCIETY_BILLS_SUCCESS, SOCIETY_BILLS_CLEAR_CACHE
} from '../../actions/types';

const INITIAL_STATE = {
    bill_list: [],
    cache: null,
    last_bill_id: undefined,
    can_load_more: false,
    scroll_offset: 0,
    error: undefined,
    loading: true,
    is_logout: false
}

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        // on hard reload, call api - so clearing cache
        case REHYDRATE: if (action.payload && action.payload.society_bills) return { ...action.payload.society_bills, cache: INITIAL_STATE.cache }; else return INITIAL_STATE;

        case SOCIETY_BILLS: return { ...INITIAL_STATE, loading: true };
        case SOCIETY_BILLS_MORE: return { ...state, loading: true, is_logout: false };

        case SOCIETY_BILLS_SUCCESS:
            const apiData = action.payload && action.payload.apiData;
            const cache = action.payload && action.payload.cache;
            const batchSize = action.payload && action.payload.batchSize;

            if (apiData !== undefined && apiData !== null) {
                let bill_list = ApiError(apiData.statusCode, apiData.message, apiData.gsRespData, "bills", []);

                if (bill_list && bill_list.constructor === Array) {
                    const state_list = state.bill_list && state.bill_list.constructor === Array ? state.bill_list : [];
                    const req_list = [...state_list, ...bill_list];

                    let last_bill_id = undefined;
                    if (req_list.length > 0) {
                        last_bill_id = req_list[req_list.length - 1].bill_id;
                    }

                    let loadMore = false;
                    if (bill_list.length === batchSize) {
                        loadMore = true;
                    }

                    return { ...state, loading: false, is_logout: false, bill_list: req_list, cache, last_bill_id, can_load_more: loadMore }

                } else {
                    return { ...state, loading: false, is_logout: false, error: bill_list, can_load_more: false };
                }

            } else {
                return { ...state, loading: false, is_logout: false };
            }

        case SOCIETY_BILLS_ERROR:
            let hNE_res = handleNetworkError(action);
            if (hNE_res.error)
                return { ...INITIAL_STATE, ...hNE_res, loading: false, is_logout: false };
            else if (action.payload && action.payload.response && action.payload.response.data && action.payload.response.data.error) {
                if (action.payload.response.data.error.statusCode === 401)
                    return { ...INITIAL_STATE, error: action.payload.response.data.error, loading: false, is_logout: true };
                else
                    return { ...INITIAL_STATE, error: action.payload.response.data.error, loading: false, is_logout: false };
            }
            else
                return { ...INITIAL_STATE, error: action.payload, loading: false, is_logout: false };

        case SOCIETY_BILLS_SCROLL:
            return { ...state, scroll_offset: action.payload.scroll_offset };

        case SOCIETY_BILLS_CLEAR_CACHE:
            return { ...state, cache: INITIAL_STATE.cache };

        case LOGOUT_RESET:
            return { ...INITIAL_STATE };

        default: return state;
    }
}