import { persistCombineReducers } from 'redux-persist';
import sessionStorage from 'redux-persist/lib/storage/session'; // Session Storage to store Redux
import ADS from './services/ads_data';
import AgentDetail from './services/agent_details';
import AllServices from './services/allservices';
import BookingDetails from './services/booking-details';
import ClientDetails from './common/client-details';
import UserProfileDetails from './common/user-profile-details';
import Services from './services/get-services';
import ServicesItems from './services/get-service-items';
import ServicesItemGroups from './services/get-service-item-groups';
import BookingFlowData from './services/booking_flow';
import SPList from './services/get-SP-list';
import Invoice from './services/invoice';
import MyBookingHistory from './services/my-booking-history';
import MyBooking from './services/my-booking';
import QuestionTree from './services/question-tree';
import Quotationdetails from './services/quotation_details';
import Quotation from './services/quotation';
import ServiceProvider from './services/service_provider';
import ServiceCategory from './services/service-category';
import ServiceSpecificJSON from './services/service-specific';
import SpConfirmedDetails from './services/sp-confirm-booking';
import ExternalDetails from './services/external_booking';
import SpDetails from './services/sp-details';
import SpProfile from './services/sp-profile';
import SPScheduleDetails from './services/sp-schedule-details';
import SPScheduleList from './services/sp-schedule-list';
import SPSchedule from './services/sp-schedule';
import RouteParameters from './common/route-parameters';
import RehydrateLoading from './common/rehydrate-loading';
import InvoiceMediaData from './services/invoice_media_data';
import QuoteMediaData from './services/quote_media_data';
import ProcessingBooking from './services/processing_booking';
import CompletedBooking from './services/completed_booking';
import CanceledBooking from './services/canceled_booking';
import BroadCastList from './services/broadcast_list';
import ClientBlockingList from './services/client_blocking_list';
import BroadCastBookingDetails from './services/broadcast-booking-details';
import showBroadcastScreen from './services/show_broadcast_blocking_screen'
import ServiceDiscounts from './services/svc-discounts';
import ProductCategory from './products/product-category';
import ProductSubCategory from './products/product-sub-category';
import ProductTypes from './products/product_types';
import ProductList from './products/product_list';
import ProductCart from './products/product_cart';
import ProductDetails from './products/product_details';
import ClientAddresses from './products/client-addresses';
import ProductCommunity from './products/product_community';
import ProductScrollDetails from './products/product-scroll-details';
import HomeScreen from './common/home_screen';
import SocietyMsgBoardList from './society/society-msg-board-list';
import SocietyGateApprovalList from './society/society-gate-approval-list';
import UserHomeScreen from './common/user-home-screen';
import SocietyComplaintList from './society/society-complaints-list';
import SocietyComplaintDetails from './society/society-complaints-details';
import SocietyMessageDetails from './society/society-message-details';
import SocietyDepartments from './society/society-departments';
import SocietyEMeetingList from './society/society-e-meeting-list';
import MiscellaneousComponents from './common/miscellaneous-components';
import SocietyPollList from './society/society-poll-list';
import SocietyTransactions from './society/society-transactions';
import PettyCashTransactions from './society/pettycash-transactions';
import PropertyTransactions from './society/property-transactions';
import TransactionDetails from './society/transaction-details';
import SocietyBills from './society/society-bills';
import SocietyInvoices from './society/society-invoices';
import SocietySettlements from './society/society-settlements';
import SettlementDetails from './society/settlement-details';
import JSKDetails from './services/jsk_details';
import EmergencyContacts from './services/emergency-contacts';
import JSKSPList from './services/get-JSK-SP-list';
import GeonamesStateList from './common/geonames-state-list';
import GeonamesCityList from './common/geonames-city-list';
import GeonamesOtherCityData from './common/geonames-other-city-data';
import Restaurant from './food/restaurant';
import CheckoutOptions from './food/checkout-options';
import FoodCart from './food/food-cart';
import FoodCustomization from './food/food-customization';
import FoodDeliveryData from './food/food-delivery-data';
import FoodDiscountCodeList from './food/food-discount-code-list';
import RestaurantList from './food/restaurant-list';
import FoodOrderList from './food/order';
import FoodOrderDetails from './food/order-details';
import NearbySPList from './services/nearby-splist';
import UserAddresses from './common/user-addresses';
import CustomBanners from './common/custom-banners';

const config = {
  key: 'primary',
  storage: sessionStorage
}

const rootReducer = persistCombineReducers(config, {
  ads: ADS,
  agents: AgentDetail,
  bookings: MyBooking,
  booking_flow_data: BookingFlowData,
  invoice_media_data: InvoiceMediaData,
  quote_media_data: QuoteMediaData,
  show_broadcast_screen: showBroadcastScreen,
  booking_history: MyBookingHistory,
  processing_booking: ProcessingBooking,
  broadcast_list: BroadCastList,
  client_blocking_list: ClientBlockingList,
  completed_booking: CompletedBooking,
  canceled_booking: CanceledBooking,
  booking_details: BookingDetails,
  broadcast_details: BroadCastBookingDetails,
  sp_confirmed_details: SpConfirmedDetails,
  external_details: ExternalDetails,
  client_details: ClientDetails,
  user_profile_details: UserProfileDetails,
  invoice: Invoice,
  sp_details: SpDetails,
  sp_profile: SpProfile,
  sp_schedule_list: SPScheduleList,
  sp_schedule: SPSchedule,
  sp_schedule_details: SPScheduleDetails,
  service_provider: ServiceProvider,
  service_category: ServiceCategory,
  service_specific: ServiceSpecificJSON,
  allServices: AllServices,
  services: Services,
  services_items: ServicesItems,
  services_item_groups: ServicesItemGroups,
  SPList: SPList,
  allServicesDiscounts: ServiceDiscounts,
  quotation_details: Quotationdetails,
  quotation: Quotation,
  question_tree: QuestionTree,
  route_parameters: RouteParameters,
  rehydrate_loading: RehydrateLoading,
  product_category: ProductCategory,
  product_sub_category: ProductSubCategory,
  product_types: ProductTypes,
  product_list: ProductList,
  product_cart: ProductCart,
  product_details: ProductDetails,
  client_addresses: ClientAddresses,
  product_community: ProductCommunity,
  product_scroll_details: ProductScrollDetails,
  home_screen: HomeScreen,
  society_msg_board_list: SocietyMsgBoardList,
  society_gate_approval_list: SocietyGateApprovalList,
  user_home_screen: UserHomeScreen,
  society_complaint_list: SocietyComplaintList,
  society_complaint_details: SocietyComplaintDetails,
  society_message_details: SocietyMessageDetails,
  society_departments: SocietyDepartments,
  society_e_meeting_list: SocietyEMeetingList,
  miscellaneous_components: MiscellaneousComponents,
  society_poll_list: SocietyPollList,
  society_transactions: SocietyTransactions,
  property_transactions: PropertyTransactions,
  transaction_details: TransactionDetails,
  society_bills: SocietyBills,
  society_invoices: SocietyInvoices,
  society_settlements: SocietySettlements,
  settlement_details: SettlementDetails,
  jsk_details: JSKDetails,
  emergency_contacts: EmergencyContacts,
  jskSPList: JSKSPList,
  geonames_state_list: GeonamesStateList,
  geonames_city_list: GeonamesCityList,
  geonames_other_city_data: GeonamesOtherCityData,
  restaurant: Restaurant,
  checkout_options: CheckoutOptions,
  food_cart: FoodCart,
  food_customization: FoodCustomization,
  food_delivery_data: FoodDeliveryData,
  food_discount_code_list: FoodDiscountCodeList,
  restaurant_list: RestaurantList,
  food_order_list: FoodOrderList,
  food_order_details: FoodOrderDetails,
  nearby_splist: NearbySPList,
  user_addresses: UserAddresses,
  custom_banners: CustomBanners,
});

export default rootReducer;