import React, { PureComponent } from 'react';
import LinearProgress from 'material-ui/LinearProgress';
import gs_color from 'config/colors.css';

class PropertyListUI extends PureComponent {
    handleDefaultListItemClick(prop_id, groupByFloor) {
        if (typeof this.props.onClick === "function") {
            this.props.onClick(prop_id, groupByFloor);
        }
    }

    renderDefaultListItem(property) {
        return (
            <div key={property.prop_id} onClick={() => this.handleDefaultListItemClick(property.prop_id, this.props.groupByFloor)}>

            </div>
        );
    }

    renderFloorLabel(type,value) {
        let disp_value = (type + " - " + value);
        if (!value) {
            disp_value = "<- NA ->";
        }

        return (
            <div style={{ padding: '5px', borderRadius: '5px', backgroundColor: gs_color.accentColor, margin: '0px auto', minWidth: '60px', width: 'max-content', color: 'white', textAlign: 'center', marginTop: '20px' }} key={disp_value}>
                {disp_value}
            </div>
        );
    }

    renderListItem(prop, index, floor) {
        let prop_data = { ...prop };
        if (this.props.board_id) {
            prop_data.board_id = this.props.board_id;
        }

        if (typeof this.props.renderItemView === "function") {
            let filter_data = {};
            if (this.props.filter_data) {
                filter_data = { ...this.props.filter_data };
            }
            if (floor && floor !== "NA") {
                filter_data.floor = floor;
            }
            return this.props.renderItemView(prop_data, filter_data, index);
        }
        else {
            return this.renderDefaultListItem(prop_data);
        }
    }

    renderGroupByFloorList(data) {
        const showFloor = data.length > 1 || (data[0] && data[0].value);

        return data.map(item => {
            const prop_list = (item.data && item.data.length > 0 ? item.data : []);

            return (
                <div key={item.type + '$$' + item.value}>
                    {
                        showFloor &&
                        this.renderFloorLabel(item.type, item.value)
                    }
                    {
                        prop_list.map((prop, i) => this.renderListItem(prop, i, item.value))
                    }
                </div>
            )
        });
    }

    renderNonGroupByFloorList(data) {
        return data.map((prop, i) => this.renderListItem(prop, i));
    }

    render() {
        if (this.props.loading) {
            return (
                <div style={{ paddingTop: '20px', textAlign: 'center' }}>
                    <LinearProgress color={gs_color.accentColor} mode="indeterminate" />
                </div>
            );
        }
        else if (this.props.groupByFloor) {
            return (
                <div style={{ overflow: 'hidden' }}>
                    {this.renderGroupByFloorList(this.props.data)}
                </div>
            );
        }
        else {
            return (
                <div style={{ overflow: 'hidden' }}>
                    {this.renderNonGroupByFloorList(this.props.data)}
                </div>
            );
        }
    }
}

export default PropertyListUI;