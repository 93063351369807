import moment from 'moment';
import momentTZ from 'moment-timezone';

export function toISODate(date) {
    date = moment(date);
    let year = date.year();
    let month = date.month() + 1;
    let dt = date.date();

    if (dt < 10) {
        dt = '0' + dt;
    }
    if (month < 10) {
        month = '0' + month;
    }
    return (year + '-' + month + '-' + dt);
}

export function toISOTime(date) {
    date = moment(date);
    let hour = date.hour();
    let min = date.minute();
    let sec = date.second();

    if (hour < 10) {
        hour = '0' + hour;
    }
    if (min < 10) {
        min = '0' + min;
    }
    if (sec < 10) {
        sec = '0' + sec;
    }
    return (hour + ':' + min + ':' + sec);
}

function dayNumberToString(dayIndex) {
    var weekdays = new Array(7);
    weekdays[0] = "Sun";
    weekdays[1] = "Mon";
    weekdays[2] = "Tue";
    weekdays[3] = "Wed";
    weekdays[4] = "Thu";
    weekdays[5] = "Fri";
    weekdays[6] = "Sat";
    return weekdays[dayIndex];
}

export function dateStringToJson(date) {
    let dateJson = { dd: '', mm: '', yyyy: '', day: '' };
    let momentDate = moment(date);
    dateJson.day = dayNumberToString(momentDate.day());
    dateJson.dd = momentDate.date();
    dateJson.mm = momentDate.month() + 1;
    dateJson.yyyy = momentDate.year();
    return dateJson;
}

export function compareDate(date1, date2, action_type = 0) {
    if (action_type === 0) {
        date1 = moment(date1);
        date2 = moment(date2);

        if (date1.year() < date2.year())
            return -1;
        else if (date1.year() > date2.year())
            return 1;
        else if (date1.month() < date2.month())
            return -1;
        else if (date1.month() > date2.month())
            return 1;
        else if (date1.date() < date2.date())
            return -1;
        else if (date1.date() > date2.date())
            return 1;
        else return 0;
    }
    else {
        date2 = moment(date2);
        if (date1.yyyy < date2.year())
            return -1;
        else if (date1.yyyy > date2.year())
            return 1;
        else if (date1.mm - 1 < date2.month())
            return -1;
        else if (date1.mm - 1 > date2.month())
            return 1;
        else if (date1.dd < date2.date())
            return -1;
        else if (date1.dd > date2.date())
            return 1;
        else return 0;
    }
}

export function compareTime(date1, date2, action_type = 0) {
    if (action_type === 0) {
        date1 = moment(date1);
        date2 = moment(date2);

        if (date1.hour() < date2.hour())
            return -1;
        else if (date1.hour() > date2.hour())
            return 1;
        else if (date1.minute() < date2.minute())
            return -1;
        else if (date1.minute() > date2.minute())
            return 1;
        else if (date1.second() < date2.second())
            return -1;
        else if (date1.second() > date2.second())
            return 1;
        else return 0;
    }
    else {
        date2 = moment(date2);
        if (date1.hour < date2.hour())
            return -1;
        else if (date1.hour > date2.hour())
            return 1;
        else if (date1.min - 1 < date2.minute())
            return -1;
        else if (date1.min - 1 > date2.minute())
            return 1;
        else if (date1.sec < date2.second())
            return -1;
        else if (date1.sec > date2.second())
            return 1;
        else return 0;
    }
}

export function compareDateTime(date1, date2, action_type = 0) {
    const compare_date = compareDate(date1, date2, action_type);
    if (compare_date !== 0) {
        return compare_date;
    }
    else {
        const compare_time = compareTime(date1, date2, action_type);
        return compare_time;
    }
}

export function setTimeToDate(date, time) {
    let temp_date = moment(date);
    time = moment(time);

    temp_date.hour(time.hour());
    temp_date.minute(time.minute());
    temp_date.second(0);
    return temp_date;
}

export function dateDiffInDays(date1, date2) {
    // returns days difference between two date objects. Takes input in any object.

    let d1 = moment(moment(date1).format("MM-DD-YYYY"), "MM-DD-YYYY");
    let d2 = moment(moment(date2).format("MM-DD-YYYY"), "MM-DD-YYYY");
    return Math.abs(d2.diff(d1, 'days'));
}

export function convertISOtoUTCDate(date_string) {
    var date = moment(date_string).utc();
    var new_date = moment(date);
    new_date.year(date.year());
    new_date.month(date.month());
    new_date.date(date.date());
    return new_date;
}

export function convertRenderTimeToISOTime(time_string) {
    // Converts 9:00 AM / 9:00 am to 21:00:00.
    // takes input as 9am, 9Am, 9:00 am
    // returns 21:00:00 if same as input.

    time_string = time_string.toLowerCase();

    let amORpm = time_string.match(/[ap]m/);
    if (amORpm === null) {
        return time_string;
    }
    else {
        amORpm = amORpm[0];
    }
    let time = time_string.split(/[\s]*?[ap]m/)[0];

    let hr, min, sec = "00";
    let str_arr = time.split(':');

    if (str_arr.length === 1) {
        hr = str_arr[0];
        min = "00";
    }
    else {
        hr = str_arr[0];
        min = str_arr[1];
    }

    if (amORpm === "pm") {
        if (parseInt(hr) !== 12)
            hr = parseInt(hr) + 12;
        return hr + ":" + min + ":" + sec;
    }
    else {
        if (parseInt(hr) === 12)
            hr = 0;
        if (parseInt(hr) < 10) {
            hr = "0" + parseInt(hr);
        }
        return hr + ":" + min + ":" + sec;
    }
}

export function convertISOTimeToRenderTime(time_string, type = 0, uppercase = false) {
    // type 0 -> input : 18:00:00 -> 6 pm
    // type 1 -> input : 18:00:00 -> 6:00 pm
    // type 2 -> input : 18:00:00 -> 06:00 pm
    // if input is having am or pm returns same.
    // //console.log("time_string : ", time_string);
    let hr, min, amORpm;
    amORpm = time_string.match(/[ap]m/);
    if (amORpm !== null) {
        return time_string;
    }
    hr = time_string.split(":")[0];
    min = time_string.split(":")[1];

    hr = parseInt(hr);
    if (hr >= 12) {
        amORpm = "pm";
        if (hr !== 12)
            hr -= 12;
    }
    else {
        if (hr === 0) {
            hr = 12;
        }
        amORpm = "am";
    }

    if (uppercase)
        amORpm = amORpm.toUpperCase();

    hr += "";

    switch (type) {
        case 0: return hr + (parseInt(min) !== 0 ? ":" + min : '') + " " + amORpm;
        case 1: return hr + ":" + min + " " + amORpm;
        case 2: return (hr.length < 2 ? ("0" + hr) : hr) + (min !== "00" ? ":" + min : ":" + "00") + " " + amORpm;
        default: return hr + ":" + min + " " + amORpm;
    }
}

export function convertJsonDateToStringDate(dd, mm, yyyy) {
    //converts dd,mm,yyyy to yyyy-mm-dd.

    //to convert into string.
    mm += '';
    dd += '';

    //to make date and month double digit.
    if (mm.length < 2) mm = '0' + mm;
    if (dd.length < 2) dd = '0' + dd;

    //to join with '-'.
    return [yyyy, mm, dd].join('-');
}

export function convertJsonDateToTextDate(dd, mm, yyyy, smallYear = false) {
    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
        "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
    ];

    if(smallYear) {
        yyyy = ("" + yyyy).substring(2)
    }

    return dd + " " + monthNames[mm - 1] + " " + yyyy;
}

export function convertJsonDateToLongTextDate(dd, mm, yyyy) {
    const monthNames = ["January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"
    ];

    return dd + " " + monthNames[mm - 1] + " " + yyyy;
}

export function convertTimseStampToText(timestamp, type = 0, timeType = 0, uppercase = false, timezone = null) {
    // type 0 -> convert 2018-03-27 13:30:00 -> 27 Mar 2018
    // type 1 -> convert 2018-03-27 13:30:00 -> 1:30 PM (follows conditions same as convertISOTimeToRenderTime).
    // type 2 -> convert 2018-03-27 13:30:00 -> 27 Mar 2018, 1:30 PM
    // type 3 -> convert 2018-03-27 13:30:00 -> 27 March 2018
    // type 4 -> convert 2018-03-27 13:30:00 -> 1:30 PM, 27 Mar

    var date_obj = momentWithTimezone(timestamp, 0, timezone);
    if (type === 0) {
        return convertJsonDateToTextDate(date_obj.date(), date_obj.month() + 1, date_obj.year());
    }
    else if (type === 1) {
        let time_string = date_obj.format("HH:mm:ss");
        return convertISOTimeToRenderTime(time_string, timeType, uppercase);
    }
    else if (type === 2) {
        let time_string = date_obj.format("HH:mm:ss")
        return convertJsonDateToTextDate(date_obj.date(), date_obj.month() + 1, date_obj.year()) + ", " + convertISOTimeToRenderTime(time_string, timeType, uppercase);
    }
    else if (type === 3) {
        return convertJsonDateToLongTextDate(date_obj.date(), date_obj.month() + 1, date_obj.year());
    }
    else if(type === 4) {
        let time_string = date_obj.format("HH:mm:ss")
        return convertISOTimeToRenderTime(time_string, timeType, uppercase) + ", " + convertJsonDateToTextDate(date_obj.date(), date_obj.month() + 1, date_obj.year(), true);
    }
}

export function mergeDateTime(date, time) {
    //merges the date of format yyyy-mm-dd with time of format hh:mm:ss.

    let date_obj;
    if (date instanceof moment)
        date_obj = moment(date);
    else
        date_obj = date;

    date_obj = toISODate(date_obj);

    let time_obj;
    if (time instanceof moment)
        time_obj = moment(time);
    else
        time_obj = time;

    if (time_obj instanceof moment || !moment(time_obj, 'HH:mm:ss', true).isValid()) {
        if (moment(time_obj, "YYYY-MM-DD HH:mm:ss Z", true).isValid()) {
            time_obj = moment(time_obj).utcOffset(moment.parseZone(time_obj, "YYYY-MM-DD HH:mm:ss Z", true).utcOffset()).format('h:mm a');
        }
        else if (moment(time_obj, "YYYY-MM-DDTHH:mm:ssZ", true).isValid()) {
            time_obj = moment(time_obj).utc().format('h:mm a');
        }
        else if (!moment(time_obj, ['h:mm a', 'h a', 'HH:mm a'], true).isValid()) {
            time_obj = moment(time_obj).format('h:mm a');
        }

        time_obj = convertRenderTimeToISOTime(time_obj);
    }


    return date_obj + " " + time_obj;
}

export function convertDateToLocalStringDate(date) {
    let iso_date = toISODate(date);
    let iso_time = toISOTime(date);
    return iso_date + " " + iso_time;
}

export function convertDateToLocalISOStringDate(date) {
    var tzo = -date.getTimezoneOffset(),
        dif = tzo >= 0 ? '+' : '-',
        pad = function (num) {
            var norm = Math.floor(Math.abs(num));
            return (norm < 10 ? '0' : '') + norm;
        };
    return date.getFullYear() +
        '-' + pad(date.getMonth() + 1) +
        '-' + pad(date.getDate()) +
        'T' + pad(date.getHours()) +
        ':' + pad(date.getMinutes()) +
        ':' + pad(date.getSeconds()) +
        dif + pad(tzo / 60) +
        ':' + pad(tzo % 60);
}

export function momentWithTimezone(date, action_type = 3, timezone = momentTZ.tz.guess() || "Asia/Kolkata", format = null) {
    // format - 0 -> timezone.
    // format - 1 -> moment format.
    // format - 2 -> moment format with timezone.
    // format - 3 -> simple moment object.
    // format - 4 -> moment format -> create moment object with format

    switch (action_type) {
        case 0: if (timezone === null) return moment(date);
        else return momentTZ.tz(date, timezone);
        case 1: if (format === null) return moment(date);
        else return moment(date).format(format);
        case 2: if (timezone === null && format === null) return moment(date);
        else if (format === null) return momentTZ.tz(date, timezone);
        else if (timezone === null) return moment(date).format(format);
        else return momentTZ.tz(date, timezone).format(format);
        case 3: return moment(date);
        case 4: if (format === null) return moment(date);
        else return moment(date, format);
        default: break;
    }
}

export function currentTimeMoment(action_type = 3, timezone = momentTZ.tz.guess() || "Asia/Kolkata", format = null) {
    // format - 0 -> timezone.
    // format - 1 -> moment format.
    // format - 2 -> moment format with timezone.
    // format - 3 -> simple moment object.

    // //console.log("current timezone : ", action_type, timezone, momentTZ().tz(timezone));

    return momentWithTimezone(moment(), action_type, timezone, format);
}

export function convertMomentObjectToUTCISOString(action_type = 0, date = null, time = null, timezone = null, toUTC = false) {
    // action_type == 0 -> date-time to utc iso string.
    // action_type == 1 -> date to utc iso string.
    // action type == 2 -> merge date and time -> to utc iso string.
    // takes input as moment object -> 2018-05-14T02:30:00Z.
    // toUTC == true -> set input date & time as UTC date-time.

    let date_obj = date;
    if (action_type === 1 || action_type === 2) {
        if (!moment(date, 'YYYY-MM-DD', true).isValid())
            date_obj = toISODate(date);
    }

    switch (action_type) {
        case 0:
            if (!toUTC)
                if (timezone !== null)
                    return momentWithTimezone(date, 0, timezone).utc().format();
                else
                    return moment(date).utc().format();
            else
                return momentTZ.tz(date, "UTC").format();
        case 1:
            if (!toUTC)
                if (timezone !== null)
                    return momentWithTimezone(date_obj, 0, timezone).utc().format();
                else
                    return moment(date_obj).utc().format();
            else
                return momentTZ.tz(date_obj, "UTC").format();
        case 2:
            if (!toUTC)
                if (timezone !== null)
                    return momentWithTimezone(mergeDateTime(date_obj, time), 0, timezone).utc().format();
                else
                    return momentWithTimezone(mergeDateTime(date_obj, time)).utc().format();
            else
                return momentTZ.tz(mergeDateTime(date_obj, time), "UTC").format();
        default:
            if (!toUTC)
                if (timezone !== null)
                    return momentWithTimezone(date, 0, timezone).utc().format();
                else
                    return moment(date).utc().format();
            else
                return momentTZ.tz(date, "UTC").format();
    }
}

export function addDurationToTime(date, duration = null) {
    let date_obj = moment(date);
    if (duration && duration.constructor === Object) {
        if (duration.day)
            date_obj.add(duration.day, 'days');
        if (duration.hour)
            date_obj.add(duration.hour, 'hours');
        if (duration.min)
            date_obj.add(duration.min, 'minutes');
    }
    return date_obj;
}

export function convertFloatTimeToISOTime(time) {
    if (!isNaN(time)) {
        time = parseFloat(time);
    }
    if (typeof time !== "number") {
        return time;
    }

    let new_time_format = parseInt(time);

    if (parseInt(time) < 10) {
        new_time_format = "0" + new_time_format;
    }

    if (parseInt(time) !== time) {
        new_time_format += ":30:00";
    }
    else {
        new_time_format += ":00:00";
    }
    return new_time_format;
}

export const MONTH_NAMES_FULL = {
    0: "January",
    1: "February",
    2: "March",
    3: "April",
    4: "May",
    5: "June",
    6: "July",
    7: "August",
    8: "September",
    9: "October",
    10: "November",
    11: "December",
}