import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar, faStoreAlt } from '@fortawesome/free-solid-svg-icons';
import { downloadMedia } from 'utils/handle-media';
import styles from './styles';

function RestaurantListItem({ opunit, cat_list_json, handleClick }) {
    const cat = cat_list_json && cat_list_json[opunit.cat_id] ? cat_list_json[opunit.cat_id].cat_name || null : null;

    return (
        <div onClick={() => handleClick()} style={styles.container}>
            {
                opunit.pic ?
                    <img style={styles.imgStyle} src={downloadMedia(opunit.pic)} />
                    :
                    <FontAwesomeIcon className='svg-inline--fa' color="grey" icon={faStoreAlt} style={styles.imgStyle} />
            }
            <div style={styles.outerStyle}>
                <div style={styles.headingStyle}>{opunit.unit_name}</div>
                <div style={styles.addressContainer}>
                    <span style={styles.addressStyle}>{opunit.address}</span>
                    <span style={styles.separatorStyle}>|</span>
                    <span style={styles.distanctStyle}>{opunit.distance} KM</span>
                </div>
                <div style={styles.innerStyle}>
                    <div><FontAwesomeIcon className='svg-inline--fa' icon={faStar} style={styles.starIconStyle} />   {opunit.avg_rating || "NA"}</div>
                    <div style={styles.categoryStyle}>{cat}</div>
                </div>
            </div>
        </div>
    );
}

export default RestaurantListItem;