export const visitor_types = {
    "delivery": "delivery",
    "guest": "guest",
    "cab": "cab",
    "resident": "resident",
    "staff": "staff",
    "family": "family",
    "regular_visitor": "regular_visitor",
    "child": "child"
};

export const delivery_time_options = {
    1: '1 Hour',
    2: '2 Hours',
    4: '4 Hours',
    8: '8 Hours',
    12: '12 Hours',
    24: '24 Hours'
};