import { REHYDRATE } from 'redux-persist';
import { GET_NEARBY_SERVICES, GET_NEARBY_SERVICES_MORE, GET_NEARBY_SERVICES_SUCCESS, GET_NEARBY_SERVICES_ERROR } from '../../actions/types';
import ApiError, { handleNetworkError } from '../../utils/api-error';
import { cl_jsk_enabled } from 'config/storage-variables';

const INITIAL_STATE = {
    cache: undefined,
    sp_list: [],
    error: undefined,
    loading: true,    
    is_logout: false,
    can_load_more: true,
    jsk_enabled: false //localStorage.getItem(cl_jsk_enabled) ? JSON.parse(localStorage.getItem(cl_jsk_enabled)) : false,
};

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case REHYDRATE:
            if (action.payload && action.payload.nearby_splist)
                return { ...action.payload.nearby_splist };
            else
                return INITIAL_STATE;

        case GET_NEARBY_SERVICES:
            return { ...INITIAL_STATE, loading: true, is_logout: false };

        case GET_NEARBY_SERVICES_MORE: {
            return {
                ...state,
                loading: true,
            }
        }

        case GET_NEARBY_SERVICES_SUCCESS:
            const { cache } = action;
            const apiData = action.payload;
            const payload = ApiError(apiData.statusCode, apiData.message, apiData.gsRespData, null, {});

            if (payload.error) {
                return {
                    ...state,
                    error: payload.error,
                    loading: false
                }
            }

            let category_json = {};
            let jskEnabled = false;
            if(payload){
                jskEnabled = payload.jsk_enabled;
            }
            if (payload && payload.cat_list && payload.cat_list.constructor === Array) {
                payload.cat_list.forEach(item => {
                    category_json[item.cat_id] = item;
                });
            }
            let sp_list = [];
            if (payload && payload.sp_list && payload.sp_list.constructor === Array) {
                sp_list = payload.sp_list.map(sp => ({
                    ...sp,
                    category_name: category_json[sp.category_id] ? category_json[sp.category_id].cat_name : null
                }));
            }
            const can_load_more = sp_list.length === ((cache && cache.batch_size) || 10);
            if (cache && cache.last_sp_id) {
                sp_list = state.sp_list.concat(sp_list);
            }
            else {
                sp_list = [].concat(sp_list);
            }

            localStorage.setItem(cl_jsk_enabled, jskEnabled);
            let newState = {
                ...INITIAL_STATE,
                loading: false,
                error: undefined,
                sp_list: sp_list,
                can_load_more,
                cache: cache,
                jsk_enabled: jskEnabled
            };

            return newState;

        case GET_NEARBY_SERVICES_ERROR:
            let hNE_res = handleNetworkError(action);
            if (hNE_res.error)
                return { ...INITIAL_STATE, ...hNE_res, loading: false, is_logout: false };
            else if (action.payload && action.payload.response && action.payload.response.data && action.payload.response.data.error) {
                if (action.payload.response.data.error.statusCode === 401)
                    return { ...INITIAL_STATE, error: action.payload.response.data.error, loading: false, is_logout: true };
                else
                    return { ...INITIAL_STATE, error: action.payload.response.data.error, loading: false, is_logout: false };
            }
            else
                return { ...INITIAL_STATE, error: action.payload, loading: false, is_logout: false };

        default: return state;
    }
}