import React from 'react';
import NavigationClose from 'material-ui/svg-icons/navigation/close';
import ShareButtonCard from './components/share-button-card';
import './styles.css';

function ShareComponent(props) {
    return (
        <div className="movetxt" style={props.style}>
            <div className="closeButton" style={{ margin: "15px" }}>
                <NavigationClose onClick={() => props.handleClose()} />
            </div>
            <ShareButtonCard
                myHandl={() => props.handleClose()}
                ref_code={props.ref_code}
                name={props.name || null}
                public_url={props.public_url || null}
                share_url={props.share_url || null}
                title={props.title || null}
            />
        </div>
    );
}

ShareComponent.displayName = 'ReferralCode';
export default ShareComponent;