import MessageIcon from './assets/private-social-network.svg';
import EIntercomIcon from './assets/e-intercom.svg';
import BillIcon from './assets/bill-payment.svg';
import VisitorIcon from './assets/visitor-management.svg';
import EBooksIcon from './assets/e-books.svg';
import ElectronicPollIcon from './assets/electronic-poll.svg';
import KidsSecurityIcon from './assets/kids-security.svg';
import FaciltyManagementIcon from './assets/facilty-management.svg';
import OGDeskIcon from 'assets/ogdesk.png';
import OGCareIcon from 'assets/OGCARE_HD.png';
import OGCartIcon from 'assets/ogcart.png';
import OGFoodIcon from 'assets/OGFOOD_HD.png';
import OGFreshIcon from 'assets/OGFRESH_HD.png';
import OGShopsIcon from 'assets/OGSHOPS_SD.png';
import OGCareSPIcon from 'assets/OGCARESP_SD.png';

export const topFeaturesData = [
    {
        svgIcon: OGShopsIcon,
        intro: 'Shop Owners, Get App with built-in POS',
        title: 'Download OGFood App',
        weight: 2,
        tabIdx: -1,
        redirect_url:'https://play.google.com/store/apps/details?id=com.grhapa.ogfood'
    },
    {
        svgIcon: OGCareSPIcon,
        intro: 'Service Providers, Get a services business tool',
        title: 'Download OGCareSP App',
        weight: 2,
        tabIdx: -1,
        redirect_url:'https://play.google.com/store/apps/details?id=com.grhapa.gruhapssp'
    },
]

const featureData = [
    /*{
        svgIcon: OGDeskIcon,
        title: 'Help Desk / Complaint Management',
        weight: 3
    },*/
    {
        svgIcon: OGFoodIcon,
        title: 'Shop with Local Stores online',
        weight: 3,
        tabIdx: 0
    },
    {
        svgIcon: OGFreshIcon,
        title: 'Get Veggies & Fruits doorstep',
        weight: 3,
        tabIdx: 1
    },
    {
        svgIcon: OGCareIcon,
        title: 'Find Service Providers',
        weight: 3,
        tabIdx: 2
    },
    {
        svgIcon: MessageIcon,
        title: 'Community Connect',
        tabIdx: -1
    },
    {
        svgIcon: VisitorIcon,
        title: 'Visitor Management',
        tabIdx: -1
    },
    {
        svgIcon: ElectronicPollIcon,
        title: 'Electronic poll',
        tabIdx: -1
    },
    {
        svgIcon: EBooksIcon,
        title: 'ERP Accounting'
    },
    // {
    //     svgIcon: FaciltyManagementIcon,
    //     title: 'Facility Management',
    //     tabIdx: -1
    // },
    // {
    //     svgIcon: BillIcon,
    //     title: 'Bill Payment'
    // },
    // {
    //     svgIcon: KidsSecurityIcon,
    //     title: 'Kids Security'
    // },
    // {
    //     svgIcon: EIntercomIcon,
    //     title: 'E-Intercom'
    // },
    // {
    //     svgIcon: EBooksIcon,
    //     title: 'E-Books & Record'
    // },
]

export default featureData;