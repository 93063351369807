export function isValidDigit(input) {
    const regex = /^\d+$/;
    return regex.test(input);
}

export function isValidFloat(input) {
    const regex = /^\d+(\.\d{1,2})?$/;
    return regex.test(input);
}

export function isValidMobile(phone) {
    const mobilenore = /^\d+$/;
    return mobilenore.test(phone);
}

export function isValidMobileNumber(phone) {
    const mobilenore = /^\d+$/;
    return mobilenore.test(phone) && ((phone) + '').length === 10;
}

export function isValidEmail(email) {
    const emailre = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return emailre.test(email);
}

export function isValidVehicleNumber(input) {
    const regex = /^[A-Z]{2}[0-9]{1,2}(?:[A-Z])?(?:[A-Z]*)?[0-9]{1,4}$/;
    return regex.test(input);
}