import { REHYDRATE } from 'redux-persist';
import { SVC_DISCOUNTS, SVC_DISCOUNTS_SUCCESS, SVC_DISCOUNTS_ERROR } from '../../actions/types';
import { ss_all_svc_discounts } from 'config/storage-variables';
import ApiError, { handleNetworkError } from '../../utils/api-error';

const discount_list_all = [{
    discount_code: 'HNY50',
    type: 'category_id',
    type_id: '13',
    discount_type: 'pct',
    discount: 50,
    max_discount: 50,
    starttime: '2019-11-01 18:30:00+00',
    endtime: '2019-11-29 18:30:00+00',
    offered_by: 'system',
    maxtimes: 3,
    createdat: '2019-01-01 18:30:00+00',
    updatedat: '2019-01-01 18:30:00+00',
    id: 27,
    is_default: true,
    city_id: 1,
    status: 'applied'
},
{
    discount_code: 'HNY50',
    type: 'category_id',
    type_id: '14',
    discount_type: 'pct',
    discount: 50,
    max_discount: 50,
    starttime: '2019-11-01 18:30:00+00',
    endtime: '2019-11-29 18:30:00+00',
    offered_by: 'system',
    maxtimes: 3,
    createdat: '2019-01-01 18:30:00+00',
    updatedat: '2019-01-01 18:30:00+00',
    id: 27,
    is_default: true,
    city_id: 1,
    status: 'applied'
},
{
    discount_code: 'HNY50',
    type: 'category_id',
    type_id: '22',
    discount_type: 'pct',
    discount: 50,
    max_discount: 50,
    starttime: '2019-11-01 18:30:00+00',
    endtime: '2019-11-29 18:30:00+00',
    offered_by: 'system',
    maxtimes: 3,
    createdat: '2019-01-01 18:30:00+00',
    updatedat: '2019-01-01 18:30:00+00',
    id: 27,
    is_default: true,
    city_id: 1,
    status: 'applied'
},
{
    discount_code: 'HNY50',
    type: 'category_id',
    type_id: '7',
    discount_type: 'pct',
    discount: 50,
    max_discount: 50,
    starttime: '2019-11-01 18:30:00+00',
    endtime: '2019-11-29 18:30:00+00',
    offered_by: 'system',
    maxtimes: 3,
    createdat: '2019-01-01 18:30:00+00',
    updatedat: '2019-01-01 18:30:00+00',
    id: 27,
    is_default: true,
    city_id: 1,
    status: 'applied'
},
{
    discount_code: 'HNY50',
    type: 'category_id',
    type_id: '17',
    discount_type: 'pct',
    discount: 50,
    max_discount: 50,
    starttime: '2019-11-01 18:30:00+00',
    endtime: '2019-11-29 18:30:00+00',
    offered_by: 'system',
    maxtimes: 3,
    createdat: '2019-01-01 18:30:00+00',
    updatedat: '2019-01-01 18:30:00+00',
    id: 27,
    is_default: true,
    city_id: 1,
    status: 'applied'
},
{
    discount_code: 'HNY50',
    type: 'category_id',
    type_id: '8',
    discount_type: 'pct',
    discount: 50,
    max_discount: 50,
    starttime: '2019-11-01 18:30:00+00',
    endtime: '2019-11-29 18:30:00+00',
    offered_by: 'system',
    maxtimes: 3,
    createdat: '2019-01-01 18:30:00+00',
    updatedat: '2019-01-01 18:30:00+00',
    id: 27,
    is_default: true,
    city_id: 1,
    status: 'applied'
},
{
    discount_code: 'HNY50',
    type: 'category_id',
    type_id: '9',
    discount_type: 'pct',
    discount: 50,
    max_discount: 50,
    starttime: '2019-11-01 18:30:00+00',
    endtime: '2019-11-29 18:30:00+00',
    offered_by: 'system',
    maxtimes: 3,
    createdat: '2019-01-01 18:30:00+00',
    updatedat: '2019-01-01 18:30:00+00',
    id: 27,
    is_default: true,
    city_id: 1,
    status: 'applied'
},
{
    discount_code: 'HNY50',
    type: 'category_id',
    type_id: '12',
    discount_type: 'pct',
    discount: 50,
    max_discount: 50,
    starttime: '2019-11-01 18:30:00+00',
    endtime: '2019-11-29 18:30:00+00',
    offered_by: 'system',
    maxtimes: 3,
    createdat: '2019-01-01 18:30:00+00',
    updatedat: '2019-01-01 18:30:00+00',
    id: 27,
    is_default: true,
    city_id: 1,
    status: 'applied'
},
{
    discount_code: 'HNY50',
    type: 'category_id',
    type_id: '6',
    discount_type: 'pct',
    discount: 50,
    max_discount: 50,
    starttime: '2019-11-01 18:30:00+00',
    endtime: '2019-11-29 18:30:00+00',
    offered_by: 'system',
    maxtimes: 3,
    createdat: '2019-01-01 18:30:00+00',
    updatedat: '2019-01-01 18:30:00+00',
    id: 27,
    is_default: true,
    city_id: 1,
    status: 'applied'
},
{
    discount_code: 'HNY50',
    type: 'category_id',
    type_id: '18',
    discount_type: 'pct',
    discount: 50,
    max_discount: 50,
    starttime: '2019-11-01 18:30:00+00',
    endtime: '2019-11-29 18:30:00+00',
    offered_by: 'system',
    maxtimes: 3,
    createdat: '2019-01-01 18:30:00+00',
    updatedat: '2019-01-01 18:30:00+00',
    id: 27,
    is_default: true,
    city_id: 1,
    status: 'applied'
},
{
    discount_code: 'HNY50',
    type: 'category_id',
    type_id: '16',
    discount_type: 'pct',
    discount: 50,
    max_discount: 50,
    starttime: '2019-11-01 18:30:00+00',
    endtime: '2019-11-29 18:30:00+00',
    offered_by: 'system',
    maxtimes: 3,
    createdat: '2019-01-01 18:30:00+00',
    updatedat: '2019-01-01 18:30:00+00',
    id: 27,
    is_default: true,
    city_id: 1,
    status: 'applied'
},
{
    discount_code: 'HNY50',
    type: 'category_id',
    type_id: '26',
    discount_type: 'pct',
    discount: 50,
    max_discount: 50,
    starttime: '2019-11-01 18:30:00+00',
    endtime: '2019-11-29 18:30:00+00',
    offered_by: 'system',
    maxtimes: 3,
    createdat: '2019-01-01 18:30:00+00',
    updatedat: '2019-01-01 18:30:00+00',
    id: 27,
    is_default: true,
    city_id: 1,
    status: 'applied'
},
{
    discount_code: 'HNY50',
    type: 'category_id',
    type_id: '1',
    discount_type: 'pct',
    discount: 50,
    max_discount: 50,
    starttime: '2019-11-01 18:30:00+00',
    endtime: '2019-11-29 18:30:00+00',
    offered_by: 'system',
    maxtimes: 3,
    createdat: '2019-01-01 18:30:00+00',
    updatedat: '2019-01-01 18:30:00+00',
    id: 27,
    is_default: true,
    city_id: 1,
    status: 'applied'
},
{
    discount_code: 'HNY50',
    type: 'category_id',
    type_id: '20',
    discount_type: 'pct',
    discount: 50,
    max_discount: 500,
    starttime: '2019-11-01 18:30:00+00',
    endtime: '2019-11-29 18:30:00+00',
    offered_by: 'system',
    maxtimes: 3,
    createdat: '2019-01-01 18:30:00+00',
    updatedat: '2019-01-01 18:30:00+00',
    id: 27,
    is_default: true,
    city_id: 1,
    status: 'applied'
}];

const discount_list = [
    {
        "discount_code": "HNY50",
        "type": "category_id",
        "type_id": "1",
        "discount_type": "pct",
        "discount": 50,
        "max_discount": 50,
        "starttime": "2019-11-01 18:30:00+00",
        "endtime": "2019-11-29 18:30:00+00",
        "offered_by": "system",
        "maxtimes": 3,
        "createdat": "2019-01-01 18:30:00+00",
        "updatedat": "2019-01-01 18:30:00+00",
        "id": 27,
        "is_default": true,
        "city_id": 1,
        "status": "applied"
    },
    {
        "discount_code": "HNY50",
        "type": "category_id",
        "type_id": "8",
        "discount_type": "pct",
        "discount": 50,
        "max_discount": 50,
        "starttime": "2019-11-01 18:30:00+00",
        "endtime": "2019-11-29 18:30:00+00",
        "offered_by": "system",
        "maxtimes": 3,
        "createdat": "2019-01-01 18:30:00+00",
        "updatedat": "2019-01-01 18:30:00+00",
        "id": 27,
        "is_default": true,
        "city_id": 1,
        "status": "applied"
    }
];

const INITIAL_STATE = {
    discount_list: [],
    error: undefined,
    loading: true,
    is_logout: false
};

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case REHYDRATE: if (action.payload && action.payload.allServicesDiscounts) return { ...action.payload.allServicesDiscounts }; else return INITIAL_STATE;
        case SVC_DISCOUNTS: return { ...INITIAL_STATE, loading: true, is_logout: false };
        case SVC_DISCOUNTS_SUCCESS:
            if (action.payload !== undefined && action.payload !== null) {
                let resp = ApiError(action.payload.statusCode, action.payload.message, action.payload.gsRespData, "coupon_list", []);
                if (resp && resp.length > 0) {
                    sessionStorage.setItem(ss_all_svc_discounts, JSON.stringify(resp));
                    return { ...INITIAL_STATE, discount_list: resp, loading: false, is_logout: false };
                }
                else {
                    sessionStorage.setItem(ss_all_svc_discounts, JSON.stringify([]));
                    return { ...INITIAL_STATE, loading: false, is_logout: false };
                }
            }
            else
                return { ...state, loading: false, is_logout: false };
            break;
        case SVC_DISCOUNTS_ERROR:
            let hNE_res = handleNetworkError(action);
            if (hNE_res.error)
                return { ...INITIAL_STATE, ...hNE_res, loading: false, is_logout: false };
            else if (action.payload && action.payload.response && action.payload.response.data && action.payload.response.data.error) {
                if (action.payload.response.data.error.statusCode === 401)
                    return { ...INITIAL_STATE, error: action.payload.response.data.error, loading: false, is_logout: true };
                else
                    return { ...INITIAL_STATE, error: action.payload.response.data.error, loading: false, is_logout: false };
            }
            else
                return { ...INITIAL_STATE, error: action.payload, loading: false, is_logout: false };
        default: return state;
    }
}