import React, { PureComponent } from 'react'

import ShareComponent from 'components/share-component'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faShareAlt } from '@fortawesome/free-solid-svg-icons'

import { APP_LINK } from 'config/config'
import gs_color from 'config/colors.css'
import './styles.css'

class ActionShare extends PureComponent {
    constructor(props) {
        super(props)

        this.state = {
            openShare: false,
            shareMessage: ''
        }
    }

    generateLink = () => {
        let link = `${APP_LINK}/s=t/dac`, params = '';

        if (this.props.selectedCommunityCode) {
            if (params) {
                params += '&';
            }
            params += `soc=${this.props.selectedCommunityCode}`
        }

        if (params) {
            link = link + '?' + params;
        }

        return link;
    }

    generateShareMessage() {
        return `Hello fellow resident, we are happy to announce that we have onboarded our Society on OlaGate, Your Digital Neighborhood Platform. This App is well equipped with all features required for apartment residents like us. Along with the property management & security, you can order your groceries from the local farmers/producers/vendors, and also find & book a local service professional for any home & personal care services, right from OlaGate App & keep track of all expenses at one place.\nUse this link for Sign Up:`;
    }

    handleShareClick = () => {
        const shareMessage = this.generateShareMessage();

        this.setState({ openShare: true, shareMessage })
    }

    render() {
        return (
            <>
                <div className="action-outer" onClick={this.handleShareClick}>
                    <div className="action-icon-outer">
                        <div className="action-icon-background">
                            <FontAwesomeIcon className='svg-inline--fa' icon={faShareAlt} color={gs_color.accentColor} style={{ height: '14px', width: '14px' }} />
                        </div>
                    </div>
                    <div className="action-label">
                        Share
                    </div>
                </div>
                {
                    this.state.openShare &&
                    <ShareComponent
                        handleClose={() => this.setState({ openShare: !this.state.openShare })}
                        title={this.state.shareMessage}
                        share_url={this.generateLink()}
                        style={{ marginLeft: '-14px' }}
                    />
                }
            </>
        )
    }
}

export default ActionShare