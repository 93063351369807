import React from 'react'

import Paper from 'material-ui/Paper'
import ProfilePic from 'components/profile-pic';

import { pollsRoute } from 'routes-parameters/config'

export function SectionItemEmptyPollPreview() {
    return (
        <div style={{
            height: '100px',
            margin: '8px',
            padding: '8px 8px',
            borderRadius: '5px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            color: '#757575',
            border: '1px dashed #e0e0e0'
        }}>
            <div>No New Polls</div>
        </div>
    )
}

function SectionItemPollPreview({ pollObj, history }) {
    const totalVotes = pollObj.totalVotes || 0;
    const isLive = !!pollObj.isLive;
    const endTime = typeof pollObj.endTime === "string" ? pollObj.endTime.split(',')[0] : pollObj.endTime || "";
    const question = pollObj.question || "";
    const profilePic = pollObj.profilePic || "";
    const displayName = pollObj.displayName || "";
    const subTitle = pollObj.subTitle || "";

    return (
        <Paper
            onClick={() => history.push(`${pollsRoute}`)}
            style={{ height: '100px', width: 'calc(80% - 8px)', margin: '8px', padding: '8px 12px', borderRadius: '5px', display: 'inline-flex', flexDirection: 'column', cursor: 'pointer' }}>
            {
                displayName ?
                    <div style={{ display: 'flex', marginBottom: '8px' }}>
                        <div>
                            <ProfilePic display_name={displayName} profile_pic={profilePic} size={30} />
                        </div>
                        <div style={{ marginLeft: '8px', textAlign: 'left' }}>
                            <div>
                                {displayName}
                            </div>
                            <div style={{ color: '#757575' }}>
                                {subTitle}
                            </div>
                        </div>
                    </div>
                    : null
            }
            <div style={{ wordBreak: 'break-all', overflow: 'hidden', textOverflow: 'ellipsis', textAlign: 'left' }}>
                {question}
            </div>
            <div style={{ marginTop: '4px', color: '#757575', display: 'flex', justifyContent: 'space-between' }}>
                <div>
                    {`${totalVotes} votes`}
                </div>
                <div style={{ marginLeft: '16px', color: isLive ? 'green' : 'red', fontWeight: 'bold', marginRight: '4px' }}>
                    {isLive ? 'Live' : 'Closed'}
                </div>
                {
                    isLive && endTime &&
                    <div style={{ marginLeft: '4px', fontSize: '12px', marginTop: '1px' }}>
                        {`Ends on ${endTime}`}
                    </div>
                }
            </div>
        </Paper>
    )
}

export default SectionItemPollPreview