export const property_types = [
    {
        type: "condo",
        label: "Condo"
    },
    {
        type: "town_house",
        label: "Town House"
    },
    {
        type: "single_family",
        label: "Single Family"
    },
    {
        type: "multi_family",
        label: "Multi Family"
    },
    {
        type: "flat",
        label: "Flat"
    },
    {
        type: "villa",
        label: "Villa"
    },
    {
        type: "bungalow",
        label: "Bunglow"
    },
    {
        type: "plot",
        label: "Plot"
    },
    {
        type: "row_house",
        label: "Row House"
    },
    {
        type: "penthouse",
        label: "Penthouse"
    },
    {
        type: "shop",
        label: "Shop"
    },
    {
        type: "storage",
        label: "Storage"
    },
    {
        type: "office",
        label: "Office"
    },
    {
        type: "suite",
        label: "Suite"
    }
]

export const occupancy_types = [
    {
        type: "family",
        label: "Family"
    },
    {
        type: "shared",
        label: "Shared"
    }
]

export const ResidentType = {
    OWNER_SELF_OCC: {
        type: "self_ocp",
        label: "Owner (Self Occupied)"
    },
    OWNER_NON_RES: {
        type: "owner",
        label: "Owner (Non-Resident)"
    },
    CO_OWNER: {
        type: "coowner",
        label: "Co-Owner"
    },
    TENANT: {
        type: "tenant",
        label: "Tenant"
    },
    LESSEE: {
        type: "lessee",
        label: "Lessee"
    },
    SUB_LESSEE: {
        type: "sub_lessee",
        label: "Sub Lessee"
    },    
    EMPLOYEE: {
        type: "employee",
        label: "Employee"
    },
}

export const resident_types = [
    ResidentType.OWNER_SELF_OCC,
    ResidentType.OWNER_NON_RES,
    ResidentType.CO_OWNER,
    ResidentType.TENANT,
    ResidentType.LESSEE,
    ResidentType.SUB_LESSEE,
    ResidentType.EMPLOYEE,
]

export const unit_detail_types = [
    {
        type: "phase",
        label: "Phase"
    },
    {
        type: "block",
        label: "Block"
    },
    {
        type: "tower",
        label: "Tower"
    },
    {
        type: "wing",
        label: "Wing"
    },
    {
        type: "floor",
        label: "Floor"
    },
    {
        type: "building",
        label: "Building"
    },    
    {
        type: "lane",
        label: "Lane"
    },
    {
        type: "sector",
        label: "Sector"
    },
    {
        type: "main",
        label: "Main"
    },
    {
        type: "cross",
        label: "Cross"
    },
    {
        type: "road",
        label: "Road"
    },
    {
        type: "drive",
        label: "Drive"
    },
    {
        type: "street",
        label: "Street"
    },
    {
        type: "place",
        label: "Place"
    },       
    {
        type: "layout",
        label: "Layout"
    },
    {
        type: "plan",
        label: "Plan"
    },
    {
        type: "branch",
        label: "Branch"
    },    
    {
        type: "dept",
        label: "Department"
    },
]

export const board_member_roles = [
    {
        type: "president",
        label: "President"
    },
    {
        type: "vicepresident",
        label: "Vice President"
    },
    {
        type: "treasurer",
        label: "Treasurer"
    },
    {
        type: "secretary",
        label: "Secretary"
    },
    {
        type: "member",
        label: "Member"
    },
    {
        type: "chairman",
        label: "Chairman"
    },
    {
        type: "manager",
        label: "Manager"
    },
]

export function parsePropAddlDetails(details, comm_schema_fields) {
    let reqStr = "";

    if (details) {
        let obj = {};
        if (comm_schema_fields && typeof comm_schema_fields === "string") {
            let fields = comm_schema_fields.split(",");
            fields.forEach(field => {
                if (details[field]) {
                    obj[field] = details[field];
                }
            });

            Object.keys(details).forEach(key => {
                if (!fields.includes(key)) {
                    obj[key] = details[key];
                }
            });
        }
        else {
            obj = { ...details };
        }

        Object.keys(obj).map(key => {
            const keyType = unit_detail_types.filter(detType => detType.type === key);
            if (keyType && keyType.length > 0) {
                const str = `${keyType && keyType.length > 0 ? keyType[0].label : key} - ${obj[key]}`;

                if (reqStr === "") {
                    reqStr += str;
                } else {
                    reqStr += ", " + str;
                }
            }
        })
    }

    return reqStr;
}

export function parsePropertyType(propertyType) {
    let reqStr = "";

    if (propertyType) {
        const keyType = property_types.filter(detType => detType.type === propertyType);
        if (keyType && keyType.length > 0) {
            reqStr = keyType[0].label;

        } else {
            reqStr = propertyType;
        }
    }

    return reqStr;
}

export function parseResidentType(residentType) {
    let reqStr = "";

    if (residentType) {
        const keyType = resident_types.filter(detType => detType.type === residentType);
        if (keyType && keyType.length > 0) {
            reqStr = keyType[0].label;

        } else {
            reqStr = residentType;
        }
    }

    return reqStr;
}

export function parseBoardMemberRole(memberRole) {
    let reqStr = "";

    if (memberRole) {
        const keyType = board_member_roles.filter(detType => detType.type === memberRole);
        if (keyType && keyType.length > 0) {
            reqStr = keyType[0].label;

        } else {
            reqStr = memberRole;
        }
    }

    return reqStr;
}

export function parseOccupancyType(occpyType) {
    let reqStr = "";

    if (occpyType) {
        const keyType = occupancy_types.filter(detType => detType.type === occpyType);
        if (keyType && keyType.length > 0) {
            reqStr = keyType[0].label;

        } else {
            reqStr = occpyType;
        }
    }

    return reqStr;
}