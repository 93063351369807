import { REHYDRATE } from 'redux-persist';
import { GEONAMES_OTHER_CITY_DATA, GEONAMES_OTHER_CITY_DATA_SUCCESS, GEONAMES_OTHER_CITY_DATA_ERROR, GEONAMES_OTHER_CITY_DATA_UDPATE_CACHE, GEONAMES_OTHER_CITY_DATA_CLEAR_CACHE } from '../../actions/types';
import ApiError, { handleNetworkError } from '../../utils/api-error';

const INITIAL_STATE = {
    city_sp_list: [],
    error: undefined,
    loading: false,
    is_logout: false,
    cache: {},
};

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case REHYDRATE: if (action.payload && action.payload.geonames_other_city_data) return { ...action.payload.geonames_other_city_data }; else return INITIAL_STATE;
        case GEONAMES_OTHER_CITY_DATA: return { ...INITIAL_STATE, cache: state.cache, loading: true, is_logout: false };
        case GEONAMES_OTHER_CITY_DATA_SUCCESS:
            const apiData = action.payload && action.payload.apiData;
            if (apiData !== undefined && apiData !== null) {
                const city_sp_list = ApiError(apiData.statusCode, apiData.message, apiData.gsRespData, "sp_list", []);
                if (city_sp_list && city_sp_list.constructor === Array) {
                    return { ...state, loading: false, is_logout: false, city_sp_list: city_sp_list }

                } else {
                    return { ...state, loading: false, is_logout: false, error: city_sp_list };
                }

            } else {
                return { ...state, loading: false, is_logout: false };
            }
        case GEONAMES_OTHER_CITY_DATA_ERROR:
            let hNE_res = handleNetworkError(action);
            if (hNE_res.error)
                return { ...INITIAL_STATE, cache: state.cache, ...hNE_res, loading: false, is_logout: false };
            else if (action.payload && action.payload.response && action.payload.response.data && action.payload.response.data.error) {
                if (action.payload.response.data.error.statusCode === 401)
                    return { ...INITIAL_STATE, cache: state.cache, error: action.payload.response.data.error, city_sp_list: [], loading: false, is_logout: true };
                else
                    return { ...INITIAL_STATE, cache: state.cache, error: action.payload.response.data.error, city_sp_list: [], loading: false, is_logout: false };
            }
            else
                return { ...INITIAL_STATE, cache: state.cache, error: action.payload, city_sp_list: [], loading: false, is_logout: false };
        case GEONAMES_OTHER_CITY_DATA_UDPATE_CACHE:
            const payload = action.payload;
            return { cache: { ...state.cache, ...payload } };
        case GEONAMES_OTHER_CITY_DATA_CLEAR_CACHE:
            return INITIAL_STATE;
        default: return state;
    }
}