import { displayWithCurrency } from 'utils/helper-functions';

export const CheckoutItemTypes = {
    cart: "Shopping Items",
    shippingAddress: "Shipping Address",
    deliveryDate: "Delivery Date",
    tipOption: "Add a Tip (Optional)",
    placeOrder: "Place Order"
}

export function isTotalLessThanMinOrderAmtBrandWise(brandWiseTotal, minOrderAmtBrandWise) {
    let message = '';
    if (!brandWiseTotal || !minOrderAmtBrandWise) {
        return message;
    }

    Object.keys(brandWiseTotal).forEach(brand_id => {
        if (!isNaN(minOrderAmtBrandWise[brand_id]) && minOrderAmtBrandWise[brand_id] > brandWiseTotal[brand_id].total) {
            message = `Min. Order of ${displayWithCurrency(parseFloat(minOrderAmtBrandWise[brand_id]))} for brand ${brandWiseTotal[brand_id].brand_name} needed for checkout.`
        }
    });

    return message;
}