import { REHYDRATE } from 'redux-persist';
import { QUESTION_TREE, QUESTION_TREE_SUCCESS, QUESTION_TREE_ERROR } from '../../actions/types';
import ApiError, { handleNetworkError } from '../../utils/api-error';

// const electricalData = {
//     "root_que_id": 47,
//     "questions": [
//         {
//             "que_id": 47,
//             "qtree_path": "47",
//             "que_text": "Where you need a service?",
//             "que_desc": null,
//             "que_type": "tree_que",
//             "grpitem_exists": false,
//             "status": "active",
//             "root_que_id": null,
//             "option_type": "uni_select",
//             "children": [
//                 {
//                     "que_id": 49,
//                     "qtree_path": "47.49",
//                     "que_text": "Commercial",
//                     "que_desc": "Commercial",
//                     "que_type": "option",
//                     "grpitem_exists": false,
//                     "status": "active",
//                     "root_que_id": null,
//                     "option_type": "uni_select",
//                     "children": []
//                 },
//                 {
//                     "que_id": 48,
//                     "qtree_path": "47.48",
//                     "que_text": "Residential",
//                     "que_desc": "Residential",
//                     "que_type": "option",
//                     "grpitem_exists": false,
//                     "status": "active",
//                     "root_que_id": null,
//                     "option_type": "uni_select",
//                     "children": []
//                 },
//                 {
//                     "que_id": 50,
//                     "qtree_path": "47.50",
//                     "que_text": "What type of service ?",
//                     "que_desc": "Type of Service",
//                     "que_type": "tree_que",
//                     "grpitem_exists": false,
//                     "status": "active",
//                     "root_que_id": null,
//                     "option_type": "uni_select",
//                     "children": [
//                         {
//                             "que_id": 52,
//                             "qtree_path": "47.50.52",
//                             "que_text": "New Electrical Work",
//                             "que_desc": "New Electrical Work",
//                             "que_type": "tree_option",
//                             "grpitem_exists": true,
//                             "status": "active",
//                             "root_que_id": null,
//                             "option_type": "uni_select",
//                             "children": [
//                                 {
//                                     "que_id": 69,
//                                     "qtree_path": "47.50.52.69",
//                                     "que_text": "Is it remodel or new construction ?",
//                                     "que_desc": "Remodel or New Construction",
//                                     "que_type": "tree_que",
//                                     "grpitem_exists": false,
//                                     "status": "active",
//                                     "root_que_id": null,
//                                     "option_type": "uni_select",
//                                     "children": [
//                                         {
//                                             "que_id": 70,
//                                             "qtree_path": "47.50.52.69.70",
//                                             "que_text": "Remodel",
//                                             "que_desc": "Remdel",
//                                             "que_type": "tree_option",
//                                             "grpitem_exists": false,
//                                             "status": "active",
//                                             "root_que_id": null,
//                                             "option_type": "uni_select",
//                                             "children": [
//                                                 {
//                                                     "que_id": 72,
//                                                     "qtree_path": "47.50.52.69.70.72",
//                                                     "que_text": "What is approximate size of remodeled area?",
//                                                     "que_desc": "Remodeled area in sq ft.",
//                                                     "que_type": "tree_que",
//                                                     "grpitem_exists": false,
//                                                     "status": "active",
//                                                     "root_que_id": null,
//                                                     "option_type": "uni_select",
//                                                     "children": [
//                                                         {
//                                                             "que_id": 73,
//                                                             "qtree_path": "47.50.52.69.72.73",
//                                                             "que_text": "Enter approimate remodel are in sq ft",
//                                                             "que_desc": "Area",
//                                                             "que_type": "ui_number",
//                                                             "grpitem_exists": false,
//                                                             "status": "active",
//                                                             "root_que_id": null,
//                                                             "option_type": "uni_select",
//                                                             "children": []
//                                                         }
//                                                     ]
//                                                 }
//                                             ]
//                                         },
//                                         {
//                                             "que_id": 71,
//                                             "qtree_path": "47.50.52.69.71",
//                                             "que_text": "New Construction",
//                                             "que_desc": "New Construction",
//                                             "que_type": "tree_option",
//                                             "grpitem_exists": false,
//                                             "status": "active",
//                                             "root_que_id": null,
//                                             "option_type": "uni_select",
//                                             "children": [
//                                                 {
//                                                     "que_id": 74,
//                                                     "qtree_path": "47.50.52.69.71.74",
//                                                     "que_text": "What is  the size of house?",
//                                                     "que_desc": "size of house",
//                                                     "que_type": "tree_que",
//                                                     "grpitem_exists": false,
//                                                     "status": "active",
//                                                     "root_que_id": null,
//                                                     "option_type": "uni_select",
//                                                     "children": [
//                                                         {
//                                                             "que_id": 75,
//                                                             "qtree_path": "47.50.52.69.71.74.75",
//                                                             "que_text": "1 BHK",
//                                                             "que_desc": "1 BHK",
//                                                             "que_type": "option",
//                                                             "grpitem_exists": false,
//                                                             "status": "active",
//                                                             "root_que_id": null,
//                                                             "option_type": "uni_select",
//                                                             "children": []
//                                                         },
//                                                         {
//                                                             "que_id": 76,
//                                                             "qtree_path": "47.50.52.69.71.74.76",
//                                                             "que_text": "2 BHK",
//                                                             "que_desc": "2 BHK",
//                                                             "que_type": "option",
//                                                             "grpitem_exists": false,
//                                                             "status": "active",
//                                                             "root_que_id": null,
//                                                             "option_type": "uni_select",
//                                                             "children": []
//                                                         },
//                                                         {
//                                                             "que_id": 77,
//                                                             "qtree_path": "47.50.52.69.71.74.77",
//                                                             "que_text": "3 BHK",
//                                                             "que_desc": "3 BHK",
//                                                             "que_type": "option",
//                                                             "grpitem_exists": false,
//                                                             "status": "active",
//                                                             "root_que_id": null,
//                                                             "option_type": "uni_select",
//                                                             "children": []
//                                                         },
//                                                         {
//                                                             "que_id": 78,
//                                                             "qtree_path": "47.50.52.69.71.74.78",
//                                                             "que_text": "3+ BHK",
//                                                             "que_desc": "3+ BHK",
//                                                             "que_type": "option",
//                                                             "grpitem_exists": false,
//                                                             "status": "active",
//                                                             "root_que_id": null,
//                                                             "option_type": "uni_select",
//                                                             "children": []
//                                                         },
//                                                         {
//                                                             "que_id": 79,
//                                                             "qtree_path": "47.50.52.69.71.74.79",
//                                                             "que_text": "How do you prefer to hire? ",
//                                                             "que_desc": "preference to hire",
//                                                             "que_type": "tree_que",
//                                                             "grpitem_exists": false,
//                                                             "status": "active",
//                                                             "root_que_id": null,
//                                                             "option_type": "uni_select",
//                                                             "children": [
//                                                                 {
//                                                                     "que_id": 81,
//                                                                     "qtree_path": "47.50.52.69.71.74.79.81",
//                                                                     "que_text": "per point",
//                                                                     "que_desc": "per point",
//                                                                     "que_type": "option",
//                                                                     "grpitem_exists": false,
//                                                                     "status": "active",
//                                                                     "root_que_id": null,
//                                                                     "option_type": "uni_select",
//                                                                     "children": []
//                                                                 },
//                                                                 {
//                                                                     "que_id": 82,
//                                                                     "qtree_path": "47.50.52.69.71.74.79.82",
//                                                                     "que_text": "contract",
//                                                                     "que_desc": "contract",
//                                                                     "que_type": "option",
//                                                                     "grpitem_exists": false,
//                                                                     "status": "active",
//                                                                     "root_que_id": null,
//                                                                     "option_type": "uni_select",
//                                                                     "children": []
//                                                                 },
//                                                                 {
//                                                                     "que_id": 80,
//                                                                     "qtree_path": "47.50.52.69.71.74.79.80",
//                                                                     "que_text": "per sq ft",
//                                                                     "que_desc": "per sq ft",
//                                                                     "que_type": "option",
//                                                                     "grpitem_exists": false,
//                                                                     "status": "active",
//                                                                     "root_que_id": null,
//                                                                     "option_type": "uni_select",
//                                                                     "children": []
//                                                                 }
//                                                             ]
//                                                         }
//                                                     ]
//                                                 }
//                                             ]
//                                         }
//                                     ]
//                                 }
//                             ]
//                         },
//                         {
//                             "que_id": 51,
//                             "qtree_path": "47.50.51",
//                             "que_text": "Installation",
//                             "que_desc": "Installation",
//                             "que_type": "tree_option",
//                             "grpitem_exists": true,
//                             "status": "active",
//                             "root_que_id": null,
//                             "option_type": "uni_select",
//                             "children": [
//                                 {
//                                     "que_id": 54,
//                                     "qtree_path": "47.50.51.54",
//                                     "que_text": "Is it new installation or replacement?",
//                                     "que_desc": "new installation or replacement?",
//                                     "que_type": "tree_que",
//                                     "grpitem_exists": false,
//                                     "status": "active",
//                                     "root_que_id": null,
//                                     "option_type": "uni_select",
//                                     "children": [
//                                         {
//                                             "que_id": 57,
//                                             "qtree_path": "47.50.51.54.57",
//                                             "que_text": "What needs to be installed ?",
//                                             "que_desc": "Installation",
//                                             "que_type": "tree_que",
//                                             "grpitem_exists": false,
//                                             "status": "active",
//                                             "root_que_id": null,
//                                             "option_type": "uni_select",
//                                             "children": [
//                                                 {
//                                                     "que_id": 58,
//                                                     "qtree_path": "47.50.51.54.57.58",
//                                                     "que_text": "Fan",
//                                                     "que_desc": "Fan",
//                                                     "que_type": "leaf",
//                                                     "grpitem_exists": false,
//                                                     "status": "active",
//                                                     "root_que_id": null,
//                                                     "option_type": "uni_select",
//                                                     "children": []
//                                                 },
//                                                 {
//                                                     "que_id": 59,
//                                                     "qtree_path": "47.50.51.54.57.59",
//                                                     "que_text": "Cooler",
//                                                     "que_desc": "Cooler",
//                                                     "que_type": "leaf",
//                                                     "grpitem_exists": false,
//                                                     "status": "active",
//                                                     "root_que_id": null,
//                                                     "option_type": "uni_select",
//                                                     "children": []
//                                                 },
//                                                 {
//                                                     "que_id": 60,
//                                                     "qtree_path": "47.50.51.54.57.60",
//                                                     "que_text": "Light",
//                                                     "que_desc": "Light",
//                                                     "que_type": "leaf",
//                                                     "grpitem_exists": false,
//                                                     "status": "active",
//                                                     "root_que_id": null,
//                                                     "option_type": "uni_select",
//                                                     "children": []
//                                                 },
//                                                 {
//                                                     "que_id": 61,
//                                                     "qtree_path": "47.50.51.54.57.61",
//                                                     "que_text": "Switch Board",
//                                                     "que_desc": "Switch Board",
//                                                     "que_type": "leaf",
//                                                     "grpitem_exists": false,
//                                                     "status": "active",
//                                                     "root_que_id": null,
//                                                     "option_type": "uni_select",
//                                                     "children": []
//                                                 },
//                                                 {
//                                                     "que_id": 62,
//                                                     "qtree_path": "47.50.51.54.57.62",
//                                                     "que_text": "Water Pump",
//                                                     "que_desc": "Water Pump",
//                                                     "que_type": "tree_option",
//                                                     "grpitem_exists": false,
//                                                     "status": "active",
//                                                     "root_que_id": null,
//                                                     "option_type": "uni_select",
//                                                     "children": [
//                                                         {
//                                                             "que_id": 66,
//                                                             "qtree_path": "47.50.51.54.57.62.66",
//                                                             "que_text": "Type of water pump",
//                                                             "que_desc": "Type of water pump",
//                                                             "que_type": "tree_que",
//                                                             "grpitem_exists": false,
//                                                             "status": "active",
//                                                             "root_que_id": null,
//                                                             "option_type": "uni_select",
//                                                             "children": [
//                                                                 {
//                                                                     "que_id": 68,
//                                                                     "qtree_path": "47.50.51.54.57.62.66.68",
//                                                                     "que_text": "Submersibles",
//                                                                     "que_desc": "Submersibles",
//                                                                     "que_type": "leaf",
//                                                                     "grpitem_exists": false,
//                                                                     "status": "active",
//                                                                     "root_que_id": null,
//                                                                     "option_type": "uni_select",
//                                                                     "children": []
//                                                                 },
//                                                                 {
//                                                                     "que_id": 67,
//                                                                     "qtree_path": "47.50.51.54.57.62.66.67",
//                                                                     "que_text": "Induction ",
//                                                                     "que_desc": "Induction ",
//                                                                     "que_type": "leaf",
//                                                                     "grpitem_exists": false,
//                                                                     "status": "active",
//                                                                     "root_que_id": null,
//                                                                     "option_type": "uni_select",
//                                                                     "children": []
//                                                                 }
//                                                             ]
//                                                         }
//                                                     ]
//                                                 },
//                                                 {
//                                                     "que_id": 63,
//                                                     "qtree_path": "47.50.51.54.57.63",
//                                                     "que_text": "MCB",
//                                                     "que_desc": "MCB",
//                                                     "que_type": "leaf",
//                                                     "grpitem_exists": false,
//                                                     "status": "active",
//                                                     "root_que_id": null,
//                                                     "option_type": "uni_select",
//                                                     "children": []
//                                                 },
//                                                 {
//                                                     "que_id": 64,
//                                                     "qtree_path": "47.50.51.54.57.64",
//                                                     "que_text": "Intervter & Batteries",
//                                                     "que_desc": "Intervter & Batteries",
//                                                     "que_type": "leaf",
//                                                     "grpitem_exists": false,
//                                                     "status": "active",
//                                                     "root_que_id": null,
//                                                     "option_type": "uni_select",
//                                                     "children": []
//                                                 },
//                                                 {
//                                                     "que_id": 65,
//                                                     "qtree_path": "47.50.51.54.57.65",
//                                                     "que_text": "Other",
//                                                     "que_desc": "Other",
//                                                     "que_type": "leaf",
//                                                     "grpitem_exists": false,
//                                                     "status": "active",
//                                                     "root_que_id": null,
//                                                     "option_type": "uni_select",
//                                                     "children": []
//                                                 }
//                                             ]
//                                         },
//                                         {
//                                             "que_id": 56,
//                                             "qtree_path": "47.50.51.54.56",
//                                             "que_text": "Replacement",
//                                             "que_desc": "Replacement",
//                                             "que_type": "option",
//                                             "grpitem_exists": false,
//                                             "status": "active",
//                                             "root_que_id": null,
//                                             "option_type": "uni_select",
//                                             "children": []
//                                         },
//                                         {
//                                             "que_id": 55,
//                                             "qtree_path": "47.50.51.54.55",
//                                             "que_text": "New Installation",
//                                             "que_desc": "New Installation",
//                                             "que_type": "option",
//                                             "grpitem_exists": false,
//                                             "status": "active",
//                                             "root_que_id": null,
//                                             "option_type": "uni_select",
//                                             "children": []
//                                         }
//                                     ]
//                                 }
//                             ]
//                         },
//                         {
//                             "que_id": 53,
//                             "qtree_path": "47.50.53",
//                             "que_text": "Repair",
//                             "que_desc": "Repair",
//                             "que_type": "tree_option",
//                             "grpitem_exists": false,
//                             "status": "active",
//                             "root_que_id": null,
//                             "option_type": "uni_select",
//                             "children": [
//                                 {
//                                     "que_id": 83,
//                                     "qtree_path": "47.50.53.83",
//                                     "que_text": "What is giving you hard time today ?",
//                                     "que_desc": "Repair issues",
//                                     "que_type": "tree_que",
//                                     "grpitem_exists": false,
//                                     "status": "active",
//                                     "root_que_id": null,
//                                     "option_type": "uni_select",
//                                     "children": [
//                                         {
//                                             "que_id": 84,
//                                             "qtree_path": "47.50.53.83.84",
//                                             "que_text": "Intervter & Batteries",
//                                             "que_desc": "Intervter & Batteries",
//                                             "que_type": "tree_option",
//                                             "grpitem_exists": true,
//                                             "status": "active",
//                                             "root_que_id": null,
//                                             "option_type": "uni_select",
//                                             "children": [
//                                                 {
//                                                     "que_id": 90,
//                                                     "qtree_path": "47.50.53.83.84.90",
//                                                     "que_text": "What is wrong with it inverter/ battery?",
//                                                     "que_desc": "inverter/ battery issue",
//                                                     "que_type": "tree_que",
//                                                     "grpitem_exists": false,
//                                                     "status": "active",
//                                                     "root_que_id": null,
//                                                     "option_type": "uni_select",
//                                                     "children": [
//                                                         {
//                                                             "que_id": 93,
//                                                             "qtree_path": "47.50.53.83.84.90.93",
//                                                             "que_text": "Battery not getting charge",
//                                                             "que_desc": "Battery not getting charge",
//                                                             "que_type": "leaf",
//                                                             "grpitem_exists": false,
//                                                             "status": "active",
//                                                             "root_que_id": null,
//                                                             "option_type": "uni_select",
//                                                             "children": []
//                                                         },
//                                                         {
//                                                             "que_id": 91,
//                                                             "qtree_path": "47.50.53.83.84.90.91",
//                                                             "que_text": "Low backup time",
//                                                             "que_desc": "Low backup time",
//                                                             "que_type": "leaf",
//                                                             "grpitem_exists": false,
//                                                             "status": "active",
//                                                             "root_que_id": null,
//                                                             "option_type": "uni_select",
//                                                             "children": []
//                                                         },
//                                                         {
//                                                             "que_id": 92,
//                                                             "qtree_path": "47.50.53.83.84.90.92",
//                                                             "que_text": "Fully Dead",
//                                                             "que_desc": "Fully Dead",
//                                                             "que_type": "leaf",
//                                                             "grpitem_exists": false,
//                                                             "status": "active",
//                                                             "root_que_id": null,
//                                                             "option_type": "uni_select",
//                                                             "children": []
//                                                         },
//                                                         {
//                                                             "que_id": 94,
//                                                             "qtree_path": "47.50.53.83.84.90.94",
//                                                             "que_text": "Noise from the system",
//                                                             "que_desc": "Noise from the system",
//                                                             "que_type": "leaf",
//                                                             "grpitem_exists": false,
//                                                             "status": "active",
//                                                             "root_que_id": null,
//                                                             "option_type": "uni_select",
//                                                             "children": []
//                                                         }
//                                                     ]
//                                                 }
//                                             ]
//                                         },
//                                         {
//                                             "que_id": 85,
//                                             "qtree_path": "47.50.53.83.85",
//                                             "que_text": "Lights",
//                                             "que_desc": "Lights",
//                                             "que_type": "tree_option",
//                                             "grpitem_exists": true,
//                                             "status": "active",
//                                             "root_que_id": null,
//                                             "option_type": "uni_select",
//                                             "children": []
//                                         },
//                                         {
//                                             "que_id": 86,
//                                             "qtree_path": "47.50.53.83.86",
//                                             "que_text": "Switch Board",
//                                             "que_desc": "Switch Board",
//                                             "que_type": "tree_option",
//                                             "grpitem_exists": true,
//                                             "status": "active",
//                                             "root_que_id": null,
//                                             "option_type": "uni_select",
//                                             "children": []
//                                         },
//                                         {
//                                             "que_id": 87,
//                                             "qtree_path": "47.50.53.83.87",
//                                             "que_text": "Fan",
//                                             "que_desc": "Fan",
//                                             "que_type": "tree_option",
//                                             "grpitem_exists": true,
//                                             "status": "active",
//                                             "root_que_id": null,
//                                             "option_type": "uni_select",
//                                             "children": [
//                                                 {
//                                                     "que_id": 95,
//                                                     "qtree_path": "47.50.53.83.87.95",
//                                                     "que_text": "What is wrong with the fan ?",
//                                                     "que_desc": "Fan issue",
//                                                     "que_type": "tree_que",
//                                                     "grpitem_exists": false,
//                                                     "status": "active",
//                                                     "root_que_id": null,
//                                                     "option_type": "uni_select",
//                                                     "children": [
//                                                         {
//                                                             "que_id": 96,
//                                                             "qtree_path": "47.50.53.83.87.95.96",
//                                                             "que_text": "Not working",
//                                                             "que_desc": "Not working",
//                                                             "que_type": "leaf",
//                                                             "grpitem_exists": false,
//                                                             "status": "active",
//                                                             "root_que_id": null,
//                                                             "option_type": "uni_select",
//                                                             "children": []
//                                                         },
//                                                         {
//                                                             "que_id": 97,
//                                                             "qtree_path": "47.50.53.83.87.95.97",
//                                                             "que_text": "Noise",
//                                                             "que_desc": "Noise",
//                                                             "que_type": "leaf",
//                                                             "grpitem_exists": false,
//                                                             "status": "active",
//                                                             "root_que_id": null,
//                                                             "option_type": "uni_select",
//                                                             "children": []
//                                                         },
//                                                         {
//                                                             "que_id": 98,
//                                                             "qtree_path": "47.50.53.83.87.95.98",
//                                                             "que_text": "Slow Speed",
//                                                             "que_desc": "Slow Speed",
//                                                             "que_type": "leaf",
//                                                             "grpitem_exists": false,
//                                                             "status": "active",
//                                                             "root_que_id": null,
//                                                             "option_type": "uni_select",
//                                                             "children": []
//                                                         },
//                                                         {
//                                                             "que_id": 99,
//                                                             "qtree_path": "47.50.53.83.87.95.99",
//                                                             "que_text": "No Speed Change",
//                                                             "que_desc": "No Speed Change",
//                                                             "que_type": "leaf",
//                                                             "grpitem_exists": false,
//                                                             "status": "active",
//                                                             "root_que_id": null,
//                                                             "option_type": "uni_select",
//                                                             "children": []
//                                                         },
//                                                         {
//                                                             "que_id": 100,
//                                                             "qtree_path": "47.50.53.83.87.95.100",
//                                                             "que_text": "Wobbling",
//                                                             "que_desc": "Wobbling",
//                                                             "que_type": "leaf",
//                                                             "grpitem_exists": false,
//                                                             "status": "active",
//                                                             "root_que_id": null,
//                                                             "option_type": "uni_select",
//                                                             "children": []
//                                                         }
//                                                     ]
//                                                 }
//                                             ]
//                                         },
//                                         {
//                                             "que_id": 88,
//                                             "qtree_path": "47.50.53.83.88",
//                                             "que_text": "Cooler",
//                                             "que_desc": "Cooler",
//                                             "que_type": "tree_option",
//                                             "grpitem_exists": true,
//                                             "status": "active",
//                                             "root_que_id": null,
//                                             "option_type": "uni_select",
//                                             "children": [
//                                                 {
//                                                     "que_id": 101,
//                                                     "qtree_path": "47.50.53.83.88.101",
//                                                     "que_text": "What is wrong with the cooler ?",
//                                                     "que_desc": "Cooler Issue",
//                                                     "que_type": "tree_que",
//                                                     "grpitem_exists": false,
//                                                     "status": "active",
//                                                     "root_que_id": null,
//                                                     "option_type": "uni_select",
//                                                     "children": [
//                                                         {
//                                                             "que_id": 102,
//                                                             "qtree_path": "47.50.53.83.88.101.102",
//                                                             "que_text": "Fan Not working",
//                                                             "que_desc": "Not working",
//                                                             "que_type": "leaf",
//                                                             "grpitem_exists": false,
//                                                             "status": "active",
//                                                             "root_que_id": null,
//                                                             "option_type": "uni_select",
//                                                             "children": []
//                                                         },
//                                                         {
//                                                             "que_id": 103,
//                                                             "qtree_path": "47.50.53.83.88.101.103",
//                                                             "que_text": "Slow Speed",
//                                                             "que_desc": "Slow Speed",
//                                                             "que_type": "leaf",
//                                                             "grpitem_exists": false,
//                                                             "status": "active",
//                                                             "root_que_id": null,
//                                                             "option_type": "uni_select",
//                                                             "children": []
//                                                         },
//                                                         {
//                                                             "que_id": 104,
//                                                             "qtree_path": "47.50.53.83.88.101.104",
//                                                             "que_text": "WaterPump not working",
//                                                             "que_desc": "WaterPump not working",
//                                                             "que_type": "leaf",
//                                                             "grpitem_exists": false,
//                                                             "status": "active",
//                                                             "root_que_id": null,
//                                                             "option_type": "uni_select",
//                                                             "children": []
//                                                         },
//                                                         {
//                                                             "que_id": 105,
//                                                             "qtree_path": "47.50.53.83.88.101.105",
//                                                             "que_text": "Water Leakage",
//                                                             "que_desc": "Water Leakage",
//                                                             "que_type": "leaf",
//                                                             "grpitem_exists": false,
//                                                             "status": "active",
//                                                             "root_que_id": null,
//                                                             "option_type": "uni_select",
//                                                             "children": []
//                                                         },
//                                                         {
//                                                             "que_id": 106,
//                                                             "qtree_path": "47.50.53.83.88.101.106",
//                                                             "que_text": "Low cooling",
//                                                             "que_desc": "Low cooling",
//                                                             "que_type": "leaf",
//                                                             "grpitem_exists": false,
//                                                             "status": "active",
//                                                             "root_que_id": null,
//                                                             "option_type": "uni_select",
//                                                             "children": []
//                                                         }
//                                                     ]
//                                                 }
//                                             ]
//                                         },
//                                         {
//                                             "que_id": 89,
//                                             "qtree_path": "47.50.53.83.89",
//                                             "que_text": "Water Pump",
//                                             "que_desc": "Water Pump",
//                                             "que_type": "tree_option",
//                                             "grpitem_exists": true,
//                                             "status": "active",
//                                             "root_que_id": null,
//                                             "option_type": "uni_select",
//                                             "children": [
//                                                 {
//                                                     "que_id": 107,
//                                                     "qtree_path": "47.50.53.83.89.107",
//                                                     "que_text": "What type of  pump?",
//                                                     "que_desc": "Pump type",
//                                                     "que_type": "tree_que",
//                                                     "grpitem_exists": false,
//                                                     "status": "active",
//                                                     "root_que_id": null,
//                                                     "option_type": "uni_select",
//                                                     "children": [
//                                                         {
//                                                             "que_id": 108,
//                                                             "qtree_path": "47.50.53.83.89.107.108",
//                                                             "que_text": "Induction ",
//                                                             "que_desc": "Induction ",
//                                                             "que_type": "tree_option",
//                                                             "grpitem_exists": false,
//                                                             "status": "active",
//                                                             "root_que_id": null,
//                                                             "option_type": "uni_select",
//                                                             "children": [
//                                                                 {
//                                                                     "que_id": 110,
//                                                                     "qtree_path": "47.50.53.83.89.107.108.110",
//                                                                     "que_text": "What is the problem with indiction motor?",
//                                                                     "que_desc": "Induction motor issue",
//                                                                     "que_type": "tree_que",
//                                                                     "grpitem_exists": false,
//                                                                     "status": "active",
//                                                                     "root_que_id": null,
//                                                                     "option_type": "uni_select",
//                                                                     "children": [
//                                                                         {
//                                                                             "que_id": 114,
//                                                                             "qtree_path": "47.50.53.83.89.107.108.110.114",
//                                                                             "que_text": "Start but no flow of water",
//                                                                             "que_desc": "Start but no flow of water",
//                                                                             "que_type": "leaf",
//                                                                             "grpitem_exists": false,
//                                                                             "status": "active",
//                                                                             "root_que_id": null,
//                                                                             "option_type": "uni_select",
//                                                                             "children": []
//                                                                         },
//                                                                         {
//                                                                             "que_id": 111,
//                                                                             "qtree_path": "47.50.53.83.89.107.108.110.111",
//                                                                             "que_text": "Motor not working",
//                                                                             "que_desc": "Motor not working",
//                                                                             "que_type": "leaf",
//                                                                             "grpitem_exists": false,
//                                                                             "status": "active",
//                                                                             "root_que_id": null,
//                                                                             "option_type": "uni_select",
//                                                                             "children": []
//                                                                         },
//                                                                         {
//                                                                             "que_id": 112,
//                                                                             "qtree_path": "47.50.53.83.89.107.108.110.112",
//                                                                             "que_text": "Excessive noise",
//                                                                             "que_desc": "Excessive noise",
//                                                                             "que_type": "leaf",
//                                                                             "grpitem_exists": false,
//                                                                             "status": "active",
//                                                                             "root_que_id": null,
//                                                                             "option_type": "uni_select",
//                                                                             "children": []
//                                                                         },
//                                                                         {
//                                                                             "que_id": 113,
//                                                                             "qtree_path": "47.50.53.83.89.107.108.110.113",
//                                                                             "que_text": "Water leakage",
//                                                                             "que_desc": "Water leakage",
//                                                                             "que_type": "leaf",
//                                                                             "grpitem_exists": false,
//                                                                             "status": "active",
//                                                                             "root_que_id": null,
//                                                                             "option_type": "uni_select",
//                                                                             "children": []
//                                                                         }
//                                                                     ]
//                                                                 }
//                                                             ]
//                                                         },
//                                                         {
//                                                             "que_id": 109,
//                                                             "qtree_path": "47.50.53.83.89.107.109",
//                                                             "que_text": "Submersibles",
//                                                             "que_desc": "Submersibles",
//                                                             "que_type": "tree_option",
//                                                             "grpitem_exists": false,
//                                                             "status": "active",
//                                                             "root_que_id": null,
//                                                             "option_type": "uni_select",
//                                                             "children": [
//                                                                 {
//                                                                     "que_id": 115,
//                                                                     "qtree_path": "47.50.53.83.89.107.109.115",
//                                                                     "que_text": "What is the problem with submersible?",
//                                                                     "que_desc": "Submersibles issue",
//                                                                     "que_type": "tree_que",
//                                                                     "grpitem_exists": false,
//                                                                     "status": "active",
//                                                                     "root_que_id": null,
//                                                                     "option_type": "uni_select",
//                                                                     "children": [
//                                                                         {
//                                                                             "que_id": 117,
//                                                                             "qtree_path": "47.50.53.83.89.107.109.115.117",
//                                                                             "que_text": "Water leakage",
//                                                                             "que_desc": "Water leakage",
//                                                                             "que_type": "leaf",
//                                                                             "grpitem_exists": false,
//                                                                             "status": "active",
//                                                                             "root_que_id": null,
//                                                                             "option_type": "uni_select",
//                                                                             "children": []
//                                                                         },
//                                                                         {
//                                                                             "que_id": 116,
//                                                                             "qtree_path": "47.50.53.83.89.107.109.115.116",
//                                                                             "que_text": "Motor not working",
//                                                                             "que_desc": "Motor not working",
//                                                                             "que_type": "leaf",
//                                                                             "grpitem_exists": false,
//                                                                             "status": "active",
//                                                                             "root_que_id": null,
//                                                                             "option_type": "uni_select",
//                                                                             "children": []
//                                                                         },
//                                                                         {
//                                                                             "que_id": 118,
//                                                                             "qtree_path": "47.50.53.83.89.107.109.115.118",
//                                                                             "que_text": "Start but no flow of water",
//                                                                             "que_desc": "Start but no flow of water",
//                                                                             "que_type": "leaf",
//                                                                             "grpitem_exists": false,
//                                                                             "status": "active",
//                                                                             "root_que_id": null,
//                                                                             "option_type": "uni_select",
//                                                                             "children": []
//                                                                         },
//                                                                         {
//                                                                             "que_id": 119,
//                                                                             "qtree_path": "47.50.53.83.89.107.109.115.119",
//                                                                             "que_text": "Starter noise",
//                                                                             "que_desc": "Starter noise",
//                                                                             "que_type": "leaf",
//                                                                             "grpitem_exists": false,
//                                                                             "status": "active",
//                                                                             "root_que_id": null,
//                                                                             "option_type": "uni_select",
//                                                                             "children": []
//                                                                         },
//                                                                         {
//                                                                             "que_id": 120,
//                                                                             "qtree_path": "47.50.53.83.89.107.109.115.120",
//                                                                             "que_text": "Starter not working",
//                                                                             "que_desc": "Starter not working",
//                                                                             "que_type": "leaf",
//                                                                             "grpitem_exists": false,
//                                                                             "status": "active",
//                                                                             "root_que_id": null,
//                                                                             "option_type": "uni_select",
//                                                                             "children": []
//                                                                         }
//                                                                     ]
//                                                                 }
//                                                             ]
//                                                         }
//                                                     ]
//                                                 }
//                                             ]
//                                         }
//                                     ]
//                                 }
//                             ]
//                         }
//                     ]
//                 }
//             ]
//         }
//     ]
// };

// const cleaningData = {
//     // "root_que_id": 1,
//     "questions": [
//         {
//             "que_id": 1,
//             "qtree_path": "1",
//             "que_text": "What is your home type?",
//             "que_desc": "home type",
//             "que_type": "tree_que",
//             "grpitem_exists": false,
//             "status": "active",
//             "root_que_id": null,
//             "option_type": "uni_select",
//             "children": [
//                 {
//                     "que_id": 3,
//                     "qtree_path": "1.3",
//                     "que_text": "Individual House ",
//                     "que_desc": "Individual House ",
//                     "que_type": "option",
//                     "grpitem_exists": false,
//                     "status": "active",
//                     "root_que_id": null,
//                     "option_type": "uni_select",
//                     "children": []
//                 },
//                 {
//                     "que_id": 4,
//                     "qtree_path": "1.4",
//                     "que_text": "Is full house or selective cleaning ?",
//                     "que_desc": "full or partial",
//                     "que_type": "tree_que",
//                     "grpitem_exists": false,
//                     "status": "active",
//                     "root_que_id": null,
//                     "option_type": "uni_select",
//                     "children": [
//                         {
//                             "que_id": 6,
//                             "qtree_path": "1.4.6",
//                             "que_text": "selective cleaning",
//                             "que_desc": "selective cleaning",
//                             "que_type": "tree_option",
//                             "grpitem_exists": false,
//                             "status": "active",
//                             "root_que_id": null,
//                             "option_type": "uni_select",
//                             "children": [
//                                 {
//                                     "que_id": 11,
//                                     "qtree_path": "1.4.6.11",
//                                     "que_text": "What is up for cleaning?",
//                                     "que_desc": "full cleaning",
//                                     "que_type": "tree_que",
//                                     "grpitem_exists": false,
//                                     "status": "active",
//                                     "root_que_id": null,
//                                     "option_type": "uni_select",
//                                     "children": [
//                                         {
//                                             "que_id": 12,
//                                             "qtree_path": "1.4.6.11.12",
//                                             "que_text": "full kitchen deep cleaning",
//                                             "que_desc": "full kitchen deep cleaning",
//                                             "que_type": "tree_option",
//                                             "grpitem_exists": true,
//                                             "status": "active",
//                                             "root_que_id": null,
//                                             "option_type": "uni_select",
//                                             "children": [
//                                                 {
//                                                     "que_id": 22,
//                                                     "qtree_path": "1.4.6.11.12.22",
//                                                     "que_text": "What is the Size of Kitchen?",
//                                                     "que_desc": "Size of Kitchen",
//                                                     "que_type": "tree_que",
//                                                     "grpitem_exists": false,
//                                                     "status": "active",
//                                                     "root_que_id": null,
//                                                     "option_type": "uni_select",
//                                                     "children": [
//                                                         {
//                                                             "que_id": 35,
//                                                             "qtree_path": "1.4.6.11.12.22.35",
//                                                             "que_text": "Enter the Approximate Size of Kitchen",
//                                                             "que_desc": "",
//                                                             "que_type": "option",
//                                                             "grpitem_exists": false,
//                                                             "status": "active",
//                                                             "root_que_id": null,
//                                                             "option_type": "uni_select",
//                                                             "children": []
//                                                         }
//                                                     ]
//                                                 }
//                                             ]
//                                         },
//                                         {
//                                             "que_id": 13,
//                                             "qtree_path": "1.4.6.11.13",
//                                             "que_text": "bathroom/Toilet deep cleaning",
//                                             "que_desc": "bathroom/Toilet deep cleaning",
//                                             "que_type": "tree_option",
//                                             "grpitem_exists": true,
//                                             "status": "active",
//                                             "root_que_id": null,
//                                             "option_type": "uni_select",
//                                             "children": [
//                                                 {
//                                                     "que_id": 23,
//                                                     "qtree_path": "1.4.6.11.13.23",
//                                                     "que_text": "What is the number Bathroom?",
//                                                     "que_desc": "Number of Bathroom",
//                                                     "que_type": "tree_que",
//                                                     "grpitem_exists": false,
//                                                     "status": "active",
//                                                     "root_que_id": null,
//                                                     "option_type": "ui_number",
//                                                     "children": [
//                                                         {
//                                                             "que_id": 24,
//                                                             "qtree_path": "1.4.6.11.13.23.24",
//                                                             "que_text": "What is the Size of Bathroom?",
//                                                             "que_desc": "Size of Bathroom",
//                                                             "que_type": "tree_que",
//                                                             "grpitem_exists": false,
//                                                             "status": "active",
//                                                             "root_que_id": null,
//                                                             "option_type": "uni_select",
//                                                             "children": [
//                                                                 {
//                                                                     "que_id": 37,
//                                                                     "qtree_path": "1.4.6.11.14.24.37",
//                                                                     "que_text": "Enter the Approximate Size of Bathroom",
//                                                                     "que_desc": "",
//                                                                     "que_type": "option",
//                                                                     "grpitem_exists": false,
//                                                                     "status": "active",
//                                                                     "root_que_id": null,
//                                                                     "option_type": "uni_select",
//                                                                     "children": []
//                                                                 }
//                                                             ]
//                                                         },
//                                                         {
//                                                             "que_id": 36,
//                                                             "qtree_path": "1.4.6.11.13.23.36",
//                                                             "que_text": "Enter the Number of Bathroom",
//                                                             "que_desc": "",
//                                                             "que_type": "option",
//                                                             "grpitem_exists": false,
//                                                             "status": "active",
//                                                             "root_que_id": null,
//                                                             "option_type": "uni_select",
//                                                             "children": []
//                                                         }
//                                                     ]
//                                                 }
//                                             ]
//                                         },
//                                         {
//                                             "que_id": 14,
//                                             "qtree_path": "1.4.6.11.14",
//                                             "que_text": "bedroom deep cleaning",
//                                             "que_desc": "bedroom deep cleaning",
//                                             "que_type": "tree_option",
//                                             "grpitem_exists": true,
//                                             "status": "active",
//                                             "root_que_id": null,
//                                             "option_type": "uni_select",
//                                             "children": [
//                                                 {
//                                                     "que_id": 25,
//                                                     "qtree_path": "1.4.6.11.14.25",
//                                                     "que_text": "What is the number Bedroom?",
//                                                     "que_desc": "Number of Bedroom",
//                                                     "que_type": "tree_que",
//                                                     "grpitem_exists": false,
//                                                     "status": "active",
//                                                     "root_que_id": null,
//                                                     "option_type": "ui_integer",
//                                                     "children": [
//                                                         {
//                                                             "que_id": 38,
//                                                             "qtree_path": "1.4.6.11.14.25.38",
//                                                             "que_text": "Enter the Number of Bedroom",
//                                                             "que_desc": "",
//                                                             "que_type": "option",
//                                                             "grpitem_exists": false,
//                                                             "status": "active",
//                                                             "root_que_id": null,
//                                                             "option_type": "uni_select",
//                                                             "children": []
//                                                         },
//                                                         {
//                                                             "que_id": 26,
//                                                             "qtree_path": "1.4.6.11.14.25.26",
//                                                             "que_text": "What is the Size of Bedroom?",
//                                                             "que_desc": "Size of Bedroom",
//                                                             "que_type": "tree_que",
//                                                             "grpitem_exists": false,
//                                                             "status": "active",
//                                                             "root_que_id": null,
//                                                             "option_type": "ui_text",
//                                                             "children": [
//                                                                 {
//                                                                     "que_id": 39,
//                                                                     "qtree_path": "1.4.6.11.14.25.26.39",
//                                                                     "que_text": "Enter the Approximate Size of all Bedroom",
//                                                                     "que_desc": "",
//                                                                     "que_type": "option",
//                                                                     "grpitem_exists": false,
//                                                                     "status": "active",
//                                                                     "root_que_id": null,
//                                                                     "option_type": "uni_select",
//                                                                     "children": []
//                                                                 }
//                                                             ]
//                                                         }
//                                                     ]
//                                                 }
//                                             ]
//                                         },
//                                         {
//                                             "que_id": 15,
//                                             "qtree_path": "1.4.6.11.15",
//                                             "que_text": "Hall Deep Cleaning",
//                                             "que_desc": "Hall Deep Cleaning",
//                                             "que_type": "tree_option",
//                                             "grpitem_exists": true,
//                                             "status": "active",
//                                             "root_que_id": null,
//                                             "option_type": "uni_select",
//                                             "children": [
//                                                 {
//                                                     "que_id": 27,
//                                                     "qtree_path": "1.4.6.11.15.27",
//                                                     "que_text": "What is the Size of Hall/Living Room?",
//                                                     "que_desc": "Size of Hall/Living Room",
//                                                     "que_type": "tree_que",
//                                                     "grpitem_exists": false,
//                                                     "status": "active",
//                                                     "root_que_id": null,
//                                                     "option_type": "uni_select",
//                                                     "children": [
//                                                         {
//                                                             "que_id": 40,
//                                                             "qtree_path": "1.4.6.11.15.27.40",
//                                                             "que_text": "Enter the Approximate Size of Hall",
//                                                             "que_desc": "",
//                                                             "que_type": "option",
//                                                             "grpitem_exists": false,
//                                                             "status": "active",
//                                                             "root_que_id": null,
//                                                             "option_type": "uni_select",
//                                                             "children": []
//                                                         }
//                                                     ]
//                                                 }
//                                             ]
//                                         },
//                                         {
//                                             "que_id": 16,
//                                             "qtree_path": "1.4.6.11.16",
//                                             "que_text": "sofa deep cleaning",
//                                             "que_desc": "sofa deep cleaning",
//                                             "que_type": "tree_option",
//                                             "grpitem_exists": true,
//                                             "status": "active",
//                                             "root_que_id": null,
//                                             "option_type": "uni_select",
//                                             "children": [
//                                                 {
//                                                     "que_id": 28,
//                                                     "qtree_path": "1.4.6.11.16.28",
//                                                     "que_text": "What is the Type Of Sofa?",
//                                                     "que_desc": "Type Of Sofa",
//                                                     "que_type": "tree_que",
//                                                     "grpitem_exists": false,
//                                                     "status": "active",
//                                                     "root_que_id": null,
//                                                     "option_type": "uni_select",
//                                                     "children": [
//                                                         {
//                                                             "que_id": 29,
//                                                             "qtree_path": "1.4.6.11.16.28.29",
//                                                             "que_text": "Leather",
//                                                             "que_desc": "Leather",
//                                                             "que_type": "option",
//                                                             "grpitem_exists": false,
//                                                             "status": "active",
//                                                             "root_que_id": null,
//                                                             "option_type": "uni_select",
//                                                             "children": []
//                                                         },
//                                                         {
//                                                             "que_id": 30,
//                                                             "qtree_path": "1.4.6.11.16.28.30",
//                                                             "que_text": "Micro Fibre",
//                                                             "que_desc": "Micro Fibre",
//                                                             "que_type": "option",
//                                                             "grpitem_exists": false,
//                                                             "status": "active",
//                                                             "root_que_id": null,
//                                                             "option_type": "uni_select",
//                                                             "children": []
//                                                         },
//                                                         {
//                                                             "que_id": 31,
//                                                             "qtree_path": "1.4.6.11.16.28.31",
//                                                             "que_text": "Cloth",
//                                                             "que_desc": "Cloth",
//                                                             "que_type": "option",
//                                                             "grpitem_exists": false,
//                                                             "status": "active",
//                                                             "root_que_id": null,
//                                                             "option_type": "uni_select",
//                                                             "children": []
//                                                         },
//                                                         {
//                                                             "que_id": 32,
//                                                             "qtree_path": "1.4.6.11.16.28.32",
//                                                             "que_text": "What is the size of Sofa?",
//                                                             "que_desc": "Size of Sofa",
//                                                             "que_type": "tree_que",
//                                                             "grpitem_exists": false,
//                                                             "status": "active",
//                                                             "root_que_id": null,
//                                                             "option_type": "uni_select",
//                                                             "children": [
//                                                                 {
//                                                                     "que_id": 33,
//                                                                     "qtree_path": "1.4.6.11.16.28.32.33",
//                                                                     "que_text": "2 Chair/Lovesteat",
//                                                                     "que_desc": "2 Chair/Lovesteat",
//                                                                     "que_type": "option",
//                                                                     "grpitem_exists": false,
//                                                                     "status": "active",
//                                                                     "root_que_id": null,
//                                                                     "option_type": "uni_select",
//                                                                     "children": []
//                                                                 },
//                                                                 {
//                                                                     "que_id": 34,
//                                                                     "qtree_path": "1.4.6.11.16.28.32.34",
//                                                                     "que_text": "3+2 Chair/ Family Sofa",
//                                                                     "que_desc": "3+2 Chair/ Family Sofa",
//                                                                     "que_type": "option",
//                                                                     "grpitem_exists": false,
//                                                                     "status": "active",
//                                                                     "root_que_id": null,
//                                                                     "option_type": "uni_select",
//                                                                     "children": []
//                                                                 }
//                                                             ]
//                                                         }
//                                                     ]
//                                                 }
//                                             ]
//                                         }
//                                     ]
//                                 }
//                             ]
//                         },
//                         {
//                             "que_id": 5,
//                             "qtree_path": "1.4.5",
//                             "que_text": "full house cleaning",
//                             "que_desc": "full house cleaning",
//                             "que_type": "tree_option",
//                             "grpitem_exists": true,
//                             "status": "active",
//                             "root_que_id": null,
//                             "option_type": "uni_select",
//                             "children": [
//                                 {
//                                     "que_id": 7,
//                                     "qtree_path": "1.4.5.7",
//                                     "que_text": "What type of cleaning ?",
//                                     "que_desc": "type of cleaning",
//                                     "que_type": "tree_que",
//                                     "grpitem_exists": false,
//                                     "status": "active",
//                                     "root_que_id": null,
//                                     "option_type": "uni_select",
//                                     "children": [
//                                         {
//                                             "que_id": 10,
//                                             "qtree_path": "1.4.5.7.10",
//                                             "que_text": "Occasional Cleaning",
//                                             "que_desc": "Occasional Cleaning",
//                                             "que_type": "option",
//                                             "grpitem_exists": false,
//                                             "status": "active",
//                                             "root_que_id": null,
//                                             "option_type": "uni_select",
//                                             "children": []
//                                         },
//                                         {
//                                             "que_id": 17,
//                                             "qtree_path": "1.4.5.7.17",
//                                             "que_text": "What is the size of house/appartment?",
//                                             "que_desc": "Size of house/appartment",
//                                             "que_type": "tree_que",
//                                             "grpitem_exists": false,
//                                             "status": "active",
//                                             "root_que_id": null,
//                                             "option_type": "uni_select",
//                                             "children": [
//                                                 {
//                                                     "que_id": 19,
//                                                     "qtree_path": "1.4.5.7.17.19",
//                                                     "que_text": "2 BHK",
//                                                     "que_desc": "2 BHK",
//                                                     "que_type": "leaf",
//                                                     "grpitem_exists": false,
//                                                     "status": "active",
//                                                     "root_que_id": null,
//                                                     "option_type": "uni_select",
//                                                     "children": []
//                                                 },
//                                                 {
//                                                     "que_id": 20,
//                                                     "qtree_path": "1.4.5.7.17.20",
//                                                     "que_text": "3 BHK",
//                                                     "que_desc": "3 BHK",
//                                                     "que_type": "leaf",
//                                                     "grpitem_exists": false,
//                                                     "status": "active",
//                                                     "root_que_id": null,
//                                                     "option_type": "uni_select",
//                                                     "children": []
//                                                 },
//                                                 {
//                                                     "que_id": 21,
//                                                     "qtree_path": "1.4.5.7.17.21",
//                                                     "que_text": "4 BHK",
//                                                     "que_desc": "4 BHK",
//                                                     "que_type": "leaf",
//                                                     "grpitem_exists": false,
//                                                     "status": "active",
//                                                     "root_que_id": null,
//                                                     "option_type": "uni_select",
//                                                     "children": []
//                                                 },
//                                                 {
//                                                     "que_id": 18,
//                                                     "qtree_path": "1.4.5.7.17.18",
//                                                     "que_text": "1 BHK",
//                                                     "que_desc": "1 BHK",
//                                                     "que_type": "leaf",
//                                                     "grpitem_exists": false,
//                                                     "status": "active",
//                                                     "root_que_id": null,
//                                                     "option_type": "uni_select",
//                                                     "children": []
//                                                 }
//                                             ]
//                                         },
//                                         {
//                                             "que_id": 8,
//                                             "qtree_path": "1.4.5.7.8",
//                                             "que_text": "Regular cleaning",
//                                             "que_desc": "Regular cleaning",
//                                             "que_type": "option",
//                                             "grpitem_exists": false,
//                                             "status": "active",
//                                             "root_que_id": null,
//                                             "option_type": "uni_select",
//                                             "children": []
//                                         },
//                                         {
//                                             "que_id": 9,
//                                             "qtree_path": "1.4.5.7.9",
//                                             "que_text": "Move-in / Move-out cleaning",
//                                             "que_desc": "Move-in / Move-out cleaning",
//                                             "que_type": "option",
//                                             "grpitem_exists": false,
//                                             "status": "active",
//                                             "root_que_id": null,
//                                             "option_type": "uni_select",
//                                             "children": []
//                                         }
//                                     ]
//                                 }
//                             ]
//                         }
//                     ]
//                 },
//                 {
//                     "que_id": 2,
//                     "qtree_path": "1.2",
//                     "que_text": "Apartment ",
//                     "que_desc": "Apartment ",
//                     "que_type": "option",
//                     "grpitem_exists": false,
//                     "status": "active",
//                     "root_que_id": null,
//                     "option_type": "uni_select",
//                     "children": []
//                 }
//             ]
//         }
//     ]
// };

// const religiousData = {
//     "root_que_id": 47,
//     "questions": [
//         {
//             "que_id": 35,
//             "qtree_path": "35",
//             "que_text": "What is the occasion",
//             "que_desc": null,
//             "que_type": "tree_que",
//             "grpitem_exists": false,
//             "status": "active",
//             "root_que_id": null,
//             "option_type": "uni_select",
//             "children": [
//                 {
//                     "que_id": 36,
//                     "qtree_path": "35.36",
//                     "que_text": "Gruha Pravesh",
//                     "que_desc": "Gruha Pravesh",
//                     "que_type": "option",
//                     "grpitem_exists": true,
//                     "status": "active",
//                     "root_que_id": null,
//                     "option_type": "uni_select",
//                     "children": []
//                 },
//                 {
//                     "que_id": 37,
//                     "qtree_path": "35.37",
//                     "que_text": "Vastu Shanti",
//                     "que_desc": "Vastu Shanti",
//                     "que_type": "option",
//                     "grpitem_exists": true,
//                     "status": "active",
//                     "root_que_id": null,
//                     "option_type": "uni_select",
//                     "children": []
//                 },
//                 {
//                     "que_id": 38,
//                     "qtree_path": "35.38",
//                     "que_text": "Festival pooja",
//                     "que_desc": "Festival pooja",
//                     "que_type": "option",
//                     "grpitem_exists": true,
//                     "status": "active",
//                     "root_que_id": null,
//                     "option_type": "uni_select",
//                     "children": []
//                 },
//                 {
//                     "que_id": 39,
//                     "qtree_path": "35.39",
//                     "que_text": "Marriage Ceremony",
//                     "que_desc": "Marriage Ceremony",
//                     "que_type": "option",
//                     "grpitem_exists": true,
//                     "status": "active",
//                     "root_que_id": null,
//                     "option_type": "uni_select",
//                     "children": []
//                 },
//                 {
//                     "que_id": 40,
//                     "qtree_path": "35.40",
//                     "que_text": "Birthday",
//                     "que_desc": "Birthday",
//                     "que_type": "option",
//                     "grpitem_exists": true,
//                     "status": "active",
//                     "root_que_id": null,
//                     "option_type": "uni_select",
//                     "children": []
//                 },
//                 {
//                     "que_id": 41,
//                     "qtree_path": "35.41",
//                     "que_text": "Last rites cremation",
//                     "que_desc": "Last rites cremation",
//                     "que_type": "option",
//                     "grpitem_exists": true,
//                     "status": "active",
//                     "root_que_id": null,
//                     "option_type": "uni_select",
//                     "children": []
//                 },
//                 {
//                     "que_id": 42,
//                     "qtree_path": "35.42",
//                     "que_text": "Engagement",
//                     "que_desc": "Engagement",
//                     "que_type": "option",
//                     "grpitem_exists": true,
//                     "status": "active",
//                     "root_que_id": null,
//                     "option_type": "uni_select",
//                     "children": []
//                 },
//                 {
//                     "que_id": 43,
//                     "qtree_path": "35.43",
//                     "que_text": "Other",
//                     "que_desc": "Other",
//                     "que_type": "option",
//                     "grpitem_exists": true,
//                     "status": "active",
//                     "root_que_id": null,
//                     "option_type": "uni_select",
//                     "children": [
//                         {
//                             "que_id": 45,
//                             "qtree_path": "35.43.45",
//                             "que_text": "Pooja",
//                             "que_desc": "Pooja",
//                             "que_type": "leaf",
//                             "grpitem_exists": false,
//                             "status": "active",
//                             "root_que_id": null,
//                             "option_type": "uni_select",
//                             "children": []
//                         },
//                         {
//                             "que_id": 46,
//                             "qtree_path": "35.43.46",
//                             "que_text": "Havan",
//                             "que_desc": "Havan",
//                             "que_type": "leaf",
//                             "grpitem_exists": false,
//                             "status": "active",
//                             "root_que_id": null,
//                             "option_type": "uni_select",
//                             "children": []
//                         }
//                     ]
//                 },
//                 {
//                     "que_id": 44,
//                     "qtree_path": "35.44",
//                     "que_text": "What type of service you need?",
//                     "que_desc": "Service Required",
//                     "que_type": "tree_que",
//                     "grpitem_exists": false,
//                     "status": "active",
//                     "root_que_id": null,
//                     "option_type": "uni_select",
//                     "children": []
//                 }
//             ]
//         }
//     ]
// };

// const nested_options = {
//     // "root_que_id": 1,
//     "questions": [
//         {
//             "que_id": 1,
//             "qtree_path": "1",
//             "que_text": "What is your home type?",
//             "que_desc": "home type",
//             "que_type": "tree_que",
//             "grpitem_exists": false,
//             "status": "active",
//             "root_que_id": null,
//             "option_type": "uni_select",
//             "children": [
//                 {
//                     "que_id": 3,
//                     "qtree_path": "1.3",
//                     "que_text": "Individual House ",
//                     "que_desc": "Individual House ",
//                     "que_type": "option",
//                     "grpitem_exists": false,
//                     "status": "active",
//                     "root_que_id": null,
//                     "option_type": "uni_select",
//                     "children": []
//                 },
//                 {
//                     "que_id": 11,
//                     "qtree_path": "1.11",
//                     "que_text": "What is up for cleaning?",
//                     "que_desc": "full cleaning",
//                     "que_type": "tree_que",
//                     "grpitem_exists": false,
//                     "status": "active",
//                     "root_que_id": null,
//                     "option_type": "uni_select",
//                     "children": [
//                         {
//                             "que_id": 13,
//                             "qtree_path": "1.11.13",
//                             "que_text": "bathroom/Toilet deep cleaning",
//                             "que_desc": "bathroom/Toilet deep cleaning",
//                             "que_type": "tree_option",
//                             "grpitem_exists": true,
//                             "status": "active",
//                             "root_que_id": null,
//                             "option_type": "all_select",
//                             "children": [
//                                 {
//                                     "que_id": 23,
//                                     "qtree_path": "1.11.13.23",
//                                     "que_text": "Enter Bathroom Details?",
//                                     "que_desc": "Bathroom Details",
//                                     "que_type": "tree_que",
//                                     "grpitem_exists": false,
//                                     "status": "active",
//                                     "root_que_id": null,
//                                     "option_type": "uni_select",
//                                     "children": [
//                                         {
//                                             "que_id": 24,
//                                             "qtree_path": "1.11.13.23.24",
//                                             "que_text": "Enter number of Bathroom?",
//                                             "que_desc": "No of Bathroom",
//                                             "que_type": "tree_option",
//                                             "grpitem_exists": false,
//                                             "status": "active",
//                                             "root_que_id": null,
//                                             "option_type": "ui_integer",
//                                             "children": [
//                                                 {
//                                                     "que_id": 25,
//                                                     "qtree_path": "1.11.13.23.24.25",
//                                                     "que_text": "What is the Size of Bathroom?",
//                                                     "que_desc": "Size of Bathroom",
//                                                     "que_type": "tree_option",
//                                                     "grpitem_exists": false,
//                                                     "status": "active",
//                                                     "root_que_id": null,
//                                                     "option_type": "ui_text",
//                                                     "children": []
//                                                 }
//                                             ]
//                                         }
//                                     ]
//                                 }
//                             ]
//                         }
//                     ]
//                 }
//             ]
//         },
//         {
//             "que_id": 2,
//             "qtree_path": "1.2",
//             "que_text": "Apartment ",
//             "que_desc": "Apartment ",
//             "que_type": "option",
//             "grpitem_exists": false,
//             "status": "active",
//             "root_que_id": null,
//             "option_type": "uni_select",
//             "children": []
//         }
//     ]
// };

// const all_options = {
//     // "root_que_id": 1,
//     "questions": [
//         {
//             "que_id": 1,
//             "qtree_path": "1",
//             "que_text": "What is your home type?",
//             "que_desc": "home type",
//             "que_type": "tree_que",
//             "grpitem_exists": false,
//             "status": "active",
//             "root_que_id": null,
//             "option_type": "uni_select",
//             "children": [
//                 {
//                     "que_id": 3,
//                     "qtree_path": "1.3",
//                     "que_text": "Individual House ",
//                     "que_desc": "Individual House ",
//                     "que_type": "option",
//                     "grpitem_exists": false,
//                     "status": "active",
//                     "root_que_id": null,
//                     "option_type": "uni_select",
//                     "children": []
//                 },
//                 {
//                     "que_id": 11,
//                     "qtree_path": "1.11",
//                     "que_text": "What is up for cleaning?",
//                     "que_desc": "full cleaning",
//                     "que_type": "tree_que",
//                     "grpitem_exists": false,
//                     "status": "active",
//                     "root_que_id": null,
//                     "option_type": "uni_select",
//                     "children": [
//                         {
//                             "que_id": 13,
//                             "qtree_path": "1.11.13",
//                             "que_text": "Full house deep cleaning",
//                             "que_desc": "Full house deep cleaning",
//                             "que_type": "tree_option",
//                             "grpitem_exists": true,
//                             "status": "active",
//                             "root_que_id": null,
//                             "option_type": "all_select",
//                             "children": [
//                                 {
//                                     "que_id": 23,
//                                     "qtree_path": "1.11.13.23",
//                                     "que_text": "What is the Size of Bathroom?",
//                                     "que_desc": "Size of Bathroom",
//                                     "que_type": "tree_option",
//                                     "grpitem_exists": false,
//                                     "status": "active",
//                                     "root_que_id": null,
//                                     "option_type": "ui_text",
//                                     "children": []
//                                 },
//                                 {
//                                     "que_id": 24,
//                                     "qtree_path": "1.11.13.24",
//                                     "que_text": "What is the Size of Bedroom?",
//                                     "que_desc": "Size of Bedroom",
//                                     "que_type": "tree_option",
//                                     "grpitem_exists": false,
//                                     "status": "active",
//                                     "root_que_id": null,
//                                     "option_type": "ui_text",
//                                     "children": []
//                                 },
//                                 {
//                                     "que_id": 25,
//                                     "qtree_path": "1.11.13.25",
//                                     "que_text": "What is the Size of Kitchen?",
//                                     "que_desc": "Size of Kitchen",
//                                     "que_type": "tree_option",
//                                     "grpitem_exists": false,
//                                     "status": "active",
//                                     "root_que_id": null,
//                                     "option_type": "ui_text",
//                                     "children": []
//                                 }
//                             ]
//                         }
//                     ]
//                 }
//             ]
//         },
//         {
//             "que_id": 2,
//             "qtree_path": "1.2",
//             "que_text": "Apartment ",
//             "que_desc": "Apartment ",
//             "que_type": "option",
//             "grpitem_exists": false,
//             "status": "active",
//             "root_que_id": null,
//             "option_type": "uni_select",
//             "children": []
//         }
//     ]
// };

// const nested_lists = {
//     // "root_que_id": 1,
//     "questions": [
//         {
//             "que_id": 1,
//             "qtree_path": "1",
//             "que_text": "What is issue?",
//             "que_desc": "issue",
//             "que_type": "tree_que",
//             "grpitem_exists": false,
//             "status": "active",
//             "root_que_id": null,
//             "option_type": "uni_select",
//             "children": [
//                 {
//                     "que_id": 3,
//                     "qtree_path": "1.3",
//                     "que_text": "Car Issue",
//                     "que_desc": "Car Issue",
//                     "que_type": "option",
//                     "grpitem_exists": false,
//                     "status": "active",
//                     "root_que_id": null,
//                     "option_type": "uni_select",
//                     "children": [
//                         {
//                             "que_id": 13,
//                             "qtree_path": "1.11.13",
//                             "que_text": "Select vehicle",
//                             "que_desc": "Select vehicle",
//                             "que_type": "tree_option",
//                             "grpitem_exists": true,
//                             "status": "active",
//                             "root_que_id": null,
//                             "option_type": "uni_select",
//                             "children": [
//                                 {
//                                     "que_id": 23,
//                                     "qtree_path": "1.11.13.23",
//                                     "que_text": "Select Year",
//                                     "que_desc": "year",
//                                     "que_type": "tree_option",
//                                     "grpitem_exists": false,
//                                     "status": "active",
//                                     "root_que_id": null,
//                                     "option_type": "ui_list",
//                                     "children": [
//                                         {
//                                             "que_id": 24,
//                                             "qtree_path": "1.11.13.23.24",
//                                             "que_text": "Select Brand",
//                                             "que_desc": "brand",
//                                             "que_type": "option",
//                                             "grpitem_exists": false,
//                                             "status": "active",
//                                             "root_que_id": null,
//                                             "option_type": "ui_list",
//                                             "children": [
//                                                 {
//                                                     "que_id": 25,
//                                                     "qtree_path": "1.11.13.23.24.25",
//                                                     "que_text": "Select Model",
//                                                     "que_desc": "model",
//                                                     "que_type": "option",
//                                                     "grpitem_exists": false,
//                                                     "status": "active",
//                                                     "root_que_id": null,
//                                                     "option_type": "ui_list",
//                                                     "children": [],
//                                                     "options": ['Swift', 'Baleno', 'WagonR']
//                                                 },
//                                             ],
//                                             "options": ['Maruti', 'Hyundai', 'BMW']
//                                         },
//                                     ],
//                                     "options": [2019, 2018, 2017]
//                                 },
//                             ]
//                         }
//                     ]
//                 }
//             ]
//         },
//         {
//             "que_id": 2,
//             "qtree_path": "1.2",
//             "que_text": "Apartment ",
//             "que_desc": "Apartment ",
//             "que_type": "option",
//             "grpitem_exists": false,
//             "status": "active",
//             "root_que_id": null,
//             "option_type": "uni_select",
//             "children": []
//         }
//     ]
// };

const INITIAL_STATE = {
    question_tree: {},
    error: undefined,
    loading: true,
    is_logout: false
};

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case REHYDRATE: if (action.payload && action.payload.question_tree) return { ...action.payload.question_tree }; else return INITIAL_STATE;
        case QUESTION_TREE: return INITIAL_STATE;
        case QUESTION_TREE_SUCCESS:
            if (action.payload !== undefined && action.payload !== null) {
                let data = action.payload;
                let resp = ApiError(data.statusCode, data.message, data.gsRespData, "get_svcquetree", []);
                return { ...INITIAL_STATE, question_tree: { questions: resp }, loading: false, is_logout: false };
            }
            else
                return { ...state, loading: false, is_logout: false };
        case QUESTION_TREE_ERROR:
            let hNE_res = handleNetworkError(action);
            if (hNE_res.error)
                return { ...INITIAL_STATE, ...hNE_res, loading: false, is_logout: false };
            else if (action.payload && action.payload.response && action.payload.response.data && action.payload.response.data.error) {
                if (action.payload.response.data.error.statusCode === 401)
                    return { ...INITIAL_STATE, error: action.payload.response.data.error, loading: false, is_logout: true };
                else
                    return { ...INITIAL_STATE, error: action.payload.response.data.error, loading: false, is_logout: false };
            }
            else
                return { ...INITIAL_STATE, error: action.payload, loading: false, is_logout: false };
        default: return state;
    }
}


// const data = {
//     "root_que_id": 1,
//     "questions": [
//         {
//             "id": 1,
//             "text": "What type of appliance",
//             "options": [
//                 {
//                     "option_id": "a1",
//                     "option_text": "Fan",
//                     "map_ques_id": 6
//                 },
//                 {
//                     "option_id": "a2",
//                     "option_text": "Refrigerator"
//                 },
//                 {
//                     "option_id": "a3",
//                     "option_text": "Microwave"
//                 },
//                 {
//                     "option_id": "a4",
//                     "option_text": "RO"
//                 },
//                 {
//                     "option_id": "a5",
//                     "option_text": "AC"
//                 },
//                 {
//                     "option_id": "a6",
//                     "option_text": "Washing Machine",
//                     "map_ques_id": 2
//                 }
//             ],
//             "option_type": "uni_select"
//         },
//         {
//             "id": 2,
//             "text": "What's the type of machine",
//             "options": [
//                 {
//                     "option_id": "b1",
//                     "option_text": "Semi automatic"
//                 },
//                 {
//                     "option_id": "b2",
//                     "option_text": "Not sure semi automatic"
//                 },
//                 {
//                     "option_id": "b3",
//                     "option_text": "Front loaded automatic"
//                 },
//                 {
//                     "option_id": "b4",
//                     "option_text": "Top loaded automatic"
//                 }
//             ],
//             "option_type": "uni_select",
//             "add_on": [
//                 3
//             ]
//         },
//         {
//             "id": 3,
//             "text": "What type of service do you need",
//             "options": [
//                 {
//                     "option_id": "c1",
//                     "option_text": "Installation",
//                     "map_ques_id": 4
//                 },
//                 {
//                     "option_id": "c2",
//                     "option_text": "Repair",
//                     "map_ques_id": 5
//                 }
//             ],
//             "option_type": "uni_select"
//         },
//         {
//             "id": 4,
//             "text": "Installation washing machine",
//             "options": [
//                 {
//                     "option_id": "d1",
//                     "option_text": "New Machine"
//                 },
//                 {
//                     "option_id": "d2",
//                     "option_text": "Replace old Machine"
//                 }
//             ],
//             "option_type": "uni_select"
//         },
//         {
//             "id": 5,
//             "text": "What is the issue",
//             "options": [
//                 {
//                     "option_id": "e1",
//                     "option_text": "Full dead"
//                 },
//                 {
//                     "option_id": "e2",
//                     "option_text": "Spin not working"
//                 },
//                 {
//                     "option_id": "e3",
//                     "option_text": "Making noise"
//                 },
//                 {
//                     "option_id": "e4",
//                     "option_text": "Not washing properly"
//                 }
//             ],
//             "option_type": "uni_select"
//         },
//         {
//             "id": 6,
//             "text": "What is the issue",
//             "options": [
//                 {
//                     "option_id": "f1",
//                     "option_text": "Full dead"
//                 },
//                 {
//                     "option_id": "f2",
//                     "option_text": "Spin not working"
//                 },
//                 {
//                     "option_id": "f3",
//                     "option_text": "Making noise"
//                 },
//                 {
//                     "option_id": "f4",
//                     "option_text": "Not washing properly"
//                 }
//             ],
//             "option_type": "quantity",
//             "quantity_factor": 5,
//             "max_quantity": 50
//         }
//     ]
// };

// const new_data = {
//     "root_que_id": 1,
//     "questions": [
//         {
//             "id": 1,
//             "text": "What is your home type?",
//             "option_type": "uni_select",
//             "options": [
//                 {
//                     "option_id": 2,
//                     "option_text": "Apartment",
//                     "map_ques_id": null
//                 },
//                 {
//                     "option_id": 3,
//                     "option_text": "Individual House",
//                     "map_ques_id": null
//                 }
//             ],
//             "add_on": [
//                 4
//             ]
//         },
//         {
//             "id": 4,
//             "text": "What is the requirement?",
//             "option_type": "multi_select",
//             "options": [
//                 {
//                     "option_id": 5,
//                     "option_text": "Full home deep cleaning",
//                     "map_ques_id": 11
//                 },
//                 {
//                     "option_id": 6,
//                     "option_text": "Full kitchen deep cleaning",
//                     "map_ques_id": 17
//                 },
//                 {
//                     "option_id": 7,
//                     "option_text": "Bathroom/Toilet deep cleaning",
//                     "map_ques_id": 21
//                 },
//                 {
//                     "option_id": 8,
//                     "option_text": "Bedroom deep cleaning",
//                     "map_ques_id": 25
//                 },
//                 {
//                     "option_id": 9,
//                     "option_text": "Hall/Living Room deep Cleaning",
//                     "map_ques_id": 29
//                 },
//                 {
//                     "option_id": 10,
//                     "option_text": "Sofa deep cleaning",
//                     "map_ques_id": 33
//                 }
//             ],
//             "add_on": null
//         },
//         {
//             "id": 11,
//             "text": "What is the size of house/appartment?",
//             "option_type": "uni_select",
//             "options": [
//                 {
//                     "option_id": 12,
//                     "option_text": "1 BHK",
//                     "map_ques_id": null
//                 },
//                 {
//                     "option_id": 13,
//                     "option_text": "2 BHK",
//                     "map_ques_id": null
//                 },
//                 {
//                     "option_id": 14,
//                     "option_text": "3 BHK",
//                     "map_ques_id": null
//                 },
//                 {
//                     "option_id": 15,
//                     "option_text": "4 BHK",
//                     "map_ques_id": null
//                 },
//                 {
//                     "option_id": 16,
//                     "option_text": "4+ BHK",
//                     "map_ques_id": null
//                 }
//             ],
//             "add_on": null
//         },
//         {
//             "id": 17,
//             "text": "What is the Size of Kitchen?",
//             "option_type": "uni_select",
//             "options": [
//                 {
//                     "option_id": 18,
//                     "option_text": "Small",
//                     "map_ques_id": null
//                 },
//                 {
//                     "option_id": 19,
//                     "option_text": "Medium",
//                     "map_ques_id": null
//                 },
//                 {
//                     "option_id": 20,
//                     "option_text": "Large",
//                     "map_ques_id": null
//                 }
//             ],
//             "add_on": null
//         },
//         {
//             "id": 21,
//             "text": "What is the Size Bathroom/Toilet?",
//             "option_type": "uni_select",
//             "options": [
//                 {
//                     "option_id": 22,
//                     "option_text": "Small",
//                     "map_ques_id": null
//                 },
//                 {
//                     "option_id": 23,
//                     "option_text": "Medium",
//                     "map_ques_id": null
//                 },
//                 {
//                     "option_id": 24,
//                     "option_text": "Large",
//                     "map_ques_id": null
//                 }
//             ],
//             "add_on": null
//         },
//         {
//             "id": 25,
//             "text": "What is the Size Bedroom?",
//             "option_type": "uni_select",
//             "options": [
//                 {
//                     "option_id": 26,
//                     "option_text": "Small",
//                     "map_ques_id": null
//                 },
//                 {
//                     "option_id": 27,
//                     "option_text": "Medium",
//                     "map_ques_id": null
//                 },
//                 {
//                     "option_id": 28,
//                     "option_text": "Large",
//                     "map_ques_id": null
//                 }
//             ],
//             "add_on": null
//         },
//         {
//             "id": 29,
//             "text": "What is the Size Hall/Living Room?",
//             "option_type": "uni_select",
//             "options": [
//                 {
//                     "option_id": 30,
//                     "option_text": "Small",
//                     "map_ques_id": null
//                 },
//                 {
//                     "option_id": 31,
//                     "option_text": "Medium",
//                     "map_ques_id": null
//                 },
//                 {
//                     "option_id": 32,
//                     "option_text": "Large",
//                     "map_ques_id": null
//                 }
//             ],
//             "add_on": null
//         },
//         {
//             "id": 33,
//             "text": "What is the type Sofa?",
//             "option_type": "uni_select",
//             "options": [
//                 {
//                     "option_id": 34,
//                     "option_text": "Leather",
//                     "map_ques_id": null
//                 },
//                 {
//                     "option_id": 35,
//                     "option_text": "Cloth",
//                     "map_ques_id": null
//                 },
//                 {
//                     "option_id": 36,
//                     "option_text": "Micro Fibre",
//                     "map_ques_id": null
//                 }
//             ],
//             "add_on": [
//                 37
//             ]
//         },
//         {
//             "id": 37,
//             "text": "What is the size of Sofa?",
//             "option_type": "uni_select",
//             "options": [
//                 {
//                     "option_id": 38,
//                     "option_text": "Sofa Chair",
//                     "map_ques_id": null
//                 },
//                 {
//                     "option_id": 39,
//                     "option_text": "Loveseat/Two Chair",
//                     "map_ques_id": null
//                 },
//                 {
//                     "option_id": 40,
//                     "option_text": "Three Chair Sofa",
//                     "map_ques_id": null
//                 },
//                 {
//                     "option_id": 41,
//                     "option_text": "Family/3+2 Sofa",
//                     "map_ques_id": null
//                 }
//             ],
//             "add_on": null
//         }
//     ]
// }