import { REHYDRATE } from 'redux-persist';
import moment from 'moment';
import ApiError, { handleNetworkError } from '../../utils/api-error';
import {
    PRODUCT_CART_ADD, PRODUCT_CART_MINUS, PRODUCT_CART_CLEAR,
    PRODUCT_CART_SYNC, PRODUCT_CART_SYNC_SUCCESS, PRODUCT_CART_SYNC_FAILURE,
    PRODUCT_CART_SAVE, PRODUCT_CART_SAVE_SUCCESS, PRODUCT_CART_SAVE_FAILURE,
    PRODUCT_CART_REFRESH, PRODUCT_CART_REFRESH_SUCCESS, PRODUCT_CART_REFRESH_FAILURE, PRODUCT_CART_ONLINE_REDIRECT,
    PRODUCT_CART_CHECKOUT, PRODUCT_CART_CHECKOUT_SUCCESS, PRODUCT_CART_CHECKOUT_FAILURE,
    PRODUCT_DELIVERY_DATE_SELECT,
    PRODUCT_CART_TIP_AMOUNT, PRODUCT_CART_USE_REWARDS
} from '../../actions/types';
import { isNullOrEmptyObject } from 'utils/helper-functions';
import { product_cart_cache, product_cart_updated, cl_user_id, city } from 'config/storage-variables';
import { parseOnlineCart, shouldSaveLocally } from '../../scenes/client/scenes/products/config';
import { DEFAULT_DELIVERY_TIME_SLOT, COMM_PGWT_FEES } from 'config/config';

const INITIAL_STATE = {
    owner_id: null,
    cart_items: {},
    cart_order: [],
    brandWiseTotal: {},
    community_id: null,
    shpinfo_id: null,
    cp_prop_id: null,
    prop_id: null,
    city_id: null,
    last_updatedat: null,
    error: null,
    loading: false,
    deliveryTimeSlot: DEFAULT_DELIVERY_TIME_SLOT,
    deliveryDate: null,
    deliveryDate: null,
    deliveryDate: null,
    convenienceFeesRate: COMM_PGWT_FEES,
    deliveryCharges: null,
    totalItems: 0,
    orderTotal: 0,
    paymentAmount: 0,
    postDiscountTotal: 0,
    discountTotal: 0,
    taxInfo: null,
    isCODEnabled: false,
    isUpiEnabled: false,
    isPgwtEnabled: false,
    onlineRedirect: null,
    checkoutLoading: false,
    checkoutError: false,
    checkoutErrorMessage: '',
    checkoutSuccess: false,
    order_id: null,
    minOrderAmt: 0,
    minFreeDelvAmt: 0,
    minOrderAmtBrandWise: null,
    tipAmountList: [],
    tipAmount: 0,
    useRewards: false
}

export default function (state = INITIAL_STATE, action) {
    const ownerId = localStorage.getItem(cl_user_id);
    let city_name = localStorage.getItem(city) ? JSON.parse(localStorage.getItem(city)) : null;
    const cityId = city_name && city_name.id;

    switch (action.type) {
        case REHYDRATE:
            if (action.payload && action.payload.product_cart)
                return { ...action.payload.product_cart };
            else
                return { ...INITIAL_STATE, owner_id: ownerId, city_id: cityId };

        case PRODUCT_CART_ADD:
            if (action.payload) {
                const product = action.payload && action.payload.product;
                const psku_id = action.payload && action.payload.psku_id;

                let cart_items = state.cart_items ? { ...state.cart_items } : {};
                let cart_order = state.cart_order ? state.cart_order : [];
                let brandWiseTotal = {};

                if (!cart_items[product.product_id]) {
                    cart_items[product.product_id] = { ...product, psku_list: undefined, product_images: undefined, product_info: undefined, psku_cart: {} };
                    cart_order = [product.product_id, ...state.cart_order]
                }
                if (!cart_items[product.product_id].psku_cart[psku_id]) {
                    cart_items[product.product_id].psku_cart[psku_id] = { ...product.psku_list.filter(psku => psku.psku_id === psku_id)[0] }
                }

                let count = cart_items[product.product_id].psku_cart[psku_id].cartCount ? cart_items[product.product_id].psku_cart[psku_id].cartCount : 0;
                cart_items[product.product_id].psku_cart[psku_id] = Object.assign({}, cart_items[product.product_id].psku_cart[psku_id], { cartCount: count + 1 });

                let totalItems = 0;
                for (const product_id of cart_order) {
                    const pkart = cart_items[product_id].psku_cart;
                    for (const psku_id of Object.keys(pkart)) {
                        totalItems += pkart[psku_id].cartCount;

                        if (!brandWiseTotal[pkart[psku_id].pbrand_id]) {
                            brandWiseTotal[pkart[psku_id].pbrand_id] = {
                                brand_name: pkart[psku_id].pbrand_name,
                                total: 0
                            }
                        }
                        brandWiseTotal[pkart[psku_id].pbrand_id].total += pkart[psku_id].sku_price.discount_price * pkart[psku_id].cartCount;
                    }
                }

                const updatedState = { ...state, cart_items: { ...cart_items }, cart_order: cart_order, totalItems, last_updatedat: moment.utc().format(), error: null, owner_id: ownerId, city_id: cityId, brandWiseTotal };

                localStorage.setItem(product_cart_cache, JSON.stringify(updatedState));
                localStorage.setItem(product_cart_updated, "true");

                return updatedState;
            }
            return state;

        case PRODUCT_CART_MINUS:
            if (action.payload) {
                const product = action.payload && action.payload.product;
                const psku_id = action.payload && action.payload.psku_id;

                let cart_items = state.cart_items ? { ...state.cart_items } : {};
                let cart_order = state.cart_order ? state.cart_order : [];
                let brandWiseTotal = {};

                if (cart_items[product.product_id] && cart_items[product.product_id].psku_cart[psku_id]) {
                    let count = cart_items[product.product_id].psku_cart[psku_id].cartCount ? cart_items[product.product_id].psku_cart[psku_id].cartCount : 0;
                    if (count > 1) {
                        cart_items[product.product_id].psku_cart[psku_id].cartCount = count - 1;

                    } else {
                        delete cart_items[product.product_id].psku_cart[psku_id];
                        if (isNullOrEmptyObject(cart_items[product.product_id].psku_cart)) {
                            delete cart_items[product.product_id];
                            cart_order = [...state.cart_order.filter(id => id !== product.product_id)]
                        }
                    }

                    let totalItems = 0;
                    for (const product_id of cart_order) {
                        const pkart = cart_items[product_id].psku_cart;
                        for (const psku_id of Object.keys(pkart)) {
                            totalItems += pkart[psku_id].cartCount;

                            if (!brandWiseTotal[pkart[psku_id].pbrand_id]) {
                                brandWiseTotal[pkart[psku_id].pbrand_id] = {
                                    brand_name: pkart[psku_id].pbrand_name,
                                    total: 0
                                }
                            }
                            brandWiseTotal[pkart[psku_id].pbrand_id].total += pkart[psku_id].sku_price.discount_price * pkart[psku_id].cartCount;
                        }
                    }

                    const updatedState = { ...state, cart_items: { ...cart_items }, cart_order: cart_order, totalItems, last_updatedat: moment.utc().format(), error: null, owner_id: ownerId, city_id: cityId, brandWiseTotal };

                    localStorage.setItem(product_cart_cache, JSON.stringify(updatedState));
                    localStorage.setItem(product_cart_updated, "true");

                    return updatedState;
                }
            }
            return state;

        case PRODUCT_CART_SYNC:
            if (!ownerId || !cityId) {
                return { ...INITIAL_STATE, loading: true, owner_id: ownerId, city_id: cityId }
            } else if ((state.owner_id && state.owner_id !== ownerId) || (state.city_id && state.city_id !== cityId)) {
                return { ...INITIAL_STATE, loading: true, owner_id: ownerId, city_id: cityId }
            } else {
                return { ...state, loading: true, owner_id: ownerId, city_id: cityId }
            }

        case PRODUCT_CART_SYNC_SUCCESS:
            const apiData = action.payload;

            if (apiData) {
                const pcart = ApiError(apiData.statusCode, apiData.message, apiData.gsRespData, "pcart", {});
                let localState = localStorage.getItem(product_cart_cache) ? JSON.parse(localStorage.getItem(product_cart_cache)) : null;
                localState = localState && localState.owner_id === ownerId ? localState : null;

                if (!pcart.error && pcart.pcart_id && pcart.city_id && pcart.city_id !== cityId) {
                    return { ...INITIAL_STATE, loading: false, owner_id: ownerId, city_id: cityId }

                } else if (!pcart.error && pcart.pcart_id && shouldSaveLocally(localState && localState.last_updatedat, pcart)) {
                    let updatedState = { ...state, ...parseOnlineCart(pcart), last_updatedat: moment.utc().format(), loading: false, error: null, owner_id: ownerId };
                    if (state.useRewards) {
                        if (!updatedState.cl_rewards_obj || !updatedState.cl_rewards_obj.redeemable_amount) {
                            updatedState.useRewards = false;
                        }
                        else {
                            let paymentAmount = updatedState.paymentAmount - updatedState.cl_rewards_obj.redeemable_amount;
                            if (paymentAmount < 0) {
                                paymentAmount = 0;
                            }
                            updatedState.paymentAmount = paymentAmount;
                        }
                    }
                    localStorage.setItem(product_cart_cache, JSON.stringify(updatedState));
                    return updatedState;

                } else if (!isNullOrEmptyObject(localState && localState.cart_items)) {
                    //Only Local Cart Exists
                    const { minOrderAmt, minFreeDelvAmt, maxWeightAllowed, minOrderAmtBrandWise } = parseOnlineCart(pcart);
                    return { ...state, ...localState, minOrderAmt, minFreeDelvAmt, minOrderAmtBrandWise, loading: false, error: null, owner_id: ownerId, maxWeightAllowed }
                }

            }
            return { ...state, loading: false, owner_id: ownerId }

        case PRODUCT_CART_SYNC_FAILURE:
            let localState = localStorage.getItem(product_cart_cache) ? JSON.parse(localStorage.getItem(product_cart_cache)) : null;
            localState = localState && localState.owner_id === ownerId ? localState : null;
            if (!isNullOrEmptyObject(localState && localState.cart_items)) {
                return { ...state, ...localState, loading: false, error: null }
            }
            return { ...state, loading: false, owner_id: ownerId }

        case PRODUCT_CART_SAVE:
            return { ...state, loading: true, owner_id: ownerId }

        case PRODUCT_CART_SAVE_SUCCESS:
            return { ...state, loading: false, owner_id: ownerId }

        case PRODUCT_CART_SAVE_FAILURE:
            return { ...state, loading: false, owner_id: ownerId }

        case PRODUCT_CART_REFRESH:
            return { ...state, loading: true, owner_id: ownerId }

        case PRODUCT_CART_REFRESH_SUCCESS:
            if (action.payload) {
                const apiData = action.payload;
                const pcart = ApiError(apiData.statusCode, apiData.message, apiData.gsRespData, "pcart", {});
                if (!pcart.error && pcart.pcart_id) {
                    let updatedState = { ...state, ...parseOnlineCart(pcart), last_updatedat: moment.utc().format(), loading: false, error: null, owner_id: ownerId };
                    if (state.useRewards) {
                        if (!updatedState.cl_rewards_obj || !updatedState.cl_rewards_obj.redeemable_amount) {
                            updatedState.useRewards = false;
                        }
                        else {
                            let paymentAmount = updatedState.paymentAmount - updatedState.cl_rewards_obj.redeemable_amount;
                            if (paymentAmount < 0) {
                                paymentAmount = 0;
                            }
                            updatedState.paymentAmount = paymentAmount;
                        }
                    }
                    localStorage.setItem(product_cart_cache, JSON.stringify(updatedState));
                    return updatedState;

                } else if ((pcart.error && pcart.error.statusCode && pcart.error.statusCode === 404) || !isNullOrEmptyObject(pcart)) {
                    const { minOrderAmt, minFreeDelvAmt, minOrderAmtBrandWise } = parseOnlineCart(pcart);
                    const updatedState = { ...INITIAL_STATE, minOrderAmt, minFreeDelvAmt, minOrderAmtBrandWise, loading: false, owner_id: ownerId, city_id: cityId };
                    localStorage.setItem(product_cart_cache, JSON.stringify(updatedState));
                    return updatedState;
                }
                return { ...state, error: pcart.error ? pcart.error : true, loading: false, owner_id: ownerId }
            }
            return { ...state, error: true, loading: false, owner_id: ownerId }

        case PRODUCT_CART_REFRESH_FAILURE:
            return { ...state, error: true, loading: false, owner_id: ownerId }

        case PRODUCT_CART_CLEAR:
            const cachedState = localStorage.getItem(product_cart_cache) ? JSON.parse(localStorage.getItem(product_cart_cache)) : null;
            if (!action.payload && ownerId && cachedState && cachedState.owner_id === ownerId) {
                localStorage.setItem(product_cart_updated, "true");
                return { ...cachedState };
            } else {
                localStorage.removeItem(product_cart_cache);
                return { ...INITIAL_STATE, owner_id: ownerId };
            }

        case PRODUCT_CART_ONLINE_REDIRECT:
            return { ...state, onlineRedirect: action.payload, owner_id: ownerId }

        case PRODUCT_CART_CHECKOUT:
            return { ...state, checkoutLoading: true, checkoutSuccess: false, checkoutError: false, checkoutErrorMessage: '', owner_id: ownerId }

        case PRODUCT_CART_CHECKOUT_SUCCESS:
            if (action.payload && action.payload.statusCode && action.payload.statusCode.toString().slice(0, 2) == 20) {
                return { ...state, checkoutLoading: false, checkoutSuccess: true, checkoutError: false, checkoutErrorMessage: '', order_id: action.payload.gsRespData && action.payload.gsRespData.order_id, owner_id: ownerId }
            } else {
                let message = 'Something went wrong';
                if (action.payload && action.payload.message && typeof action.payload.message === "string") {
                    message = message + ' - ' + action.payload.message;
                }
                return { ...state, checkoutLoading: false, checkoutSuccess: false, checkoutError: true, checkoutErrorMessage: message, owner_id: ownerId }
            }

        case PRODUCT_CART_CHECKOUT_FAILURE:
            let message = 'Something went wrong';
            if (action.payload && action.payload.message && typeof action.payload.message === "string") {
                message = message + ' - ' + action.payload.message;
            }
            return { ...state, checkoutLoading: false, checkoutSuccess: false, checkoutError: true, checkoutErrorMessage: message, owner_id: ownerId }

        case PRODUCT_DELIVERY_DATE_SELECT:
            return { ...state, deliveryDate: action.payload }

        case PRODUCT_CART_TIP_AMOUNT:
            return { ...state, tipAmount: action.payload ? action.payload : 0 }

        case PRODUCT_CART_USE_REWARDS:
            const useRewards = !!action.payload;
            let paymentAmount = state.orderTotal;
            if (useRewards && state.cl_rewards_obj && state.cl_rewards_obj.redeemable_amount) {
                paymentAmount = paymentAmount - state.cl_rewards_obj.redeemable_amount;
                if (paymentAmount < 0) {
                    paymentAmount = 0;
                }
            }
            return { ...state, useRewards: !!action.payload, paymentAmount }

        default: return state;
    }
}