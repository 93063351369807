export const styles = {
    root: {
        padding: '0 15px',
    },
    slideContainer: {
        padding: '0 3px',
    },
    slide: {
        padding: 15,
        // minHeight: 100,
        color: '#fff',
    }
};