function getLocalStore(key) {
    return localStorage.getItem(key);
}

function setLocalStore(key, value) {
    localStorage.setItem(key, value)
}

function removeLocalStore(key) {
    return localStorage.removeItem(key);
}

function getSessionStore(key) {
    return sessionStorage.getItem(key);
}

function setSessionStore(key, value) {
    sessionStorage.setItem(key, value)
}

function removeSessionStore(key) {
    return sessionStorage.removeItem(key);
}

export default {
    getLocal: getLocalStore,
    setLocal: setLocalStore,
    removeLocal: removeLocalStore,
    getSession: getSessionStore,
    setSession: setSessionStore,
    removeSession: removeSessionStore
}