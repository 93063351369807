import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import Badge from 'material-ui/Badge';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import gs_color from 'config/colors.css';

import styles from './styles';

const selectedTextStyle = {
    color: gs_color.primaryColor,
    background: gs_color.lightPrimaryColor
}

const disabledStyle = {
    color: styles.disabledColor,
    cursor: 'default'
}

class SideNavItem extends React.PureComponent {
    getImage = (props, selected) => {
        if (props.icon) {
            return (
                <props.icon
                    style={styles.ItemImage}
                    className="menuIconTab"
                    color={
                        selected
                            ? gs_color.primaryColor
                            : props.enabled
                                ? styles.enabledColor : styles.disabledColor
                    } />
            )

        } else if (props.faIcon) {
            const faStyle = Object.assign({}, styles.ItemImage, { height: '28px', width: '22px' })
            return (
                <FontAwesomeIcon
                    icon={props.faIcon}
                    style={faStyle}
                    className="menuIconFaTab"
                    color={
                        selected
                            ? gs_color.primaryColor
                            : props.enabled
                                ? styles.enabledColor : styles.disabledColor
                    } />
            )
        } else if (props.svgIcon) {
            const svgStyle = { height: '28px', width: '28px' };
            return <img src={selected ? props.svgSelIcon : props.svgIcon} style={svgStyle} />
        }
    }

    renderWithBadge(children, include_badge) {
        if (include_badge) {
            return (
                <Badge
                    badgeContent={<div style={{ background: 'red', width: '7px', height: '7px', borderRadius: '50%' }}></div>}
                    style={{ padding: '0px 10px 0px 0px' }}
                    badgeStyle={{ width: 'unset', height: 'unset', top: "13px", right: "5px" }}
                >
                    {children}
                </Badge>
            )
        }
        else {
            return children;
        }
    }

    render() {
        const selected = this.props.selected === this.props.title;
        const outerStyle =
            selected
                ? Object.assign({}, styles.ItemOuterDiv, selectedTextStyle)
                : this.props.enabled
                    ? styles.ItemOuterDiv
                    : Object.assign({}, styles.ItemOuterDiv, disabledStyle);

        return (
            <Link
                action="replace"
                to={`/${this.props.route}`}
                style={{ textDecoration: 'none' }}
                onClick={(e) =>
                    this.props.enabled
                        ? this.props.setSelected(this.props.title)
                        : e.preventDefault()}
                id={this.props.cssId}>

                {
                    this.renderWithBadge(
                        <div style={outerStyle}>
                            {this.getImage(this.props, selected)}
                            <div className="hideTabView" style={styles.ItemText}>{this.props.title}</div>
                        </div>
                        , this.props.cssId === 'account' && this.props.show_email_highlight
                    )
                }
            </Link>
        )
    }
}

function mapStateToProps({ miscellaneous_components }) {
    const { show_email_highlight } = miscellaneous_components;

    return {
        show_email_highlight,
    };
}

export default connect(mapStateToProps)(SideNavItem);