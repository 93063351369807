import { REHYDRATE } from 'redux-persist';
import { PRODUCT_SUB_CATEGORY, PRODUCT_SUB_CATEGORY_SUCCESS, PRODUCT_SUB_CATEGORY_ERROR } from '../../actions/types';
import ApiError, { handleNetworkError } from '../../utils/api-error';
import { productSubCategoryApiAdapter } from 'scenes/client/scenes/products/config';

const INITIAL_STATE = {
  product_sub_categories: {},
  cache: null,
  error: undefined,
  loading: false,
  is_logout: false
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case REHYDRATE: if (action.payload && action.payload.product_sub_category) return { ...action.payload.product_sub_category }; else return INITIAL_STATE;
    case PRODUCT_SUB_CATEGORY: return { ...INITIAL_STATE, loading: true, is_logout: false };
    case PRODUCT_SUB_CATEGORY_SUCCESS:
      const apiData = action.payload && action.payload.apiData;
      const cache = action.payload && action.payload.cache;
      if (apiData !== undefined && apiData !== null) {
        const pscat_list = ApiError(apiData.statusCode, apiData.message, apiData.gsRespData, "pscat_list", []);
        if (pscat_list && pscat_list.constructor === Array) {
          const cat_data = productSubCategoryApiAdapter(pscat_list);
          return { ...state, loading: false, is_logout: false, product_sub_categories: cat_data, cache: cache }
        } else {
          return { ...state, error: pscat_list, product_sub_categories: {}, loading: false, is_logout: true };
        }
      } else {
        return { ...state, loading: false, is_logout: false };
      }
    case PRODUCT_SUB_CATEGORY_ERROR:
      let hNE_res = handleNetworkError(action);
      if (hNE_res.error)
        return { ...INITIAL_STATE, ...hNE_res, loading: false, is_logout: false };
      else if (action.payload && action.payload.response && action.payload.response.data && action.payload.response.data.error) {
        if (action.payload.response.data.error.statusCode === 401)
          return { ...INITIAL_STATE, error: action.payload.response.data.error, product_sub_categories: {}, loading: false, is_logout: true };
        else
          return { ...INITIAL_STATE, error: action.payload.response.data.error, product_sub_categories: {}, loading: false, is_logout: false };
      }
      else
        return { ...INITIAL_STATE, error: action.payload, product_sub_categories: {}, loading: false, is_logout: false };
    default: return state;
  }
}