import {
    parsePropAddlDetails, parsePropertyType, parseResidentType, parseBoardMemberRole
} from '../../../select-society/scenes/register-property/config';

export function addBoardMemberObjectAdapter(prop, hidePropAddlDetails = false, user_roles = null, filterData = false) {
    let property = {};

    if (prop.prop_details && !hidePropAddlDetails) {
        property.primaryText = `${prop.prop_unit_name} (${parsePropAddlDetails(prop.prop_details)})`

    } else {
        property.primaryText = `${prop.prop_unit_name}`
    }

    property.secondaryText = `${parsePropertyType(prop.prop_type)}`
    property.open = false
    property.id = prop.prop_id
    property.board_id = prop.board_id

    property.userList = [];

    if (prop.user_list && prop.user_list.length > 0) {
        prop.user_list.map(user => {
            if (user.status === "active") {
                let userObj = {};

                let role = '';

                if (user_roles) {
                    Object.keys(user_roles).map(user_id => {
                        if (user_id === user.user_id) {
                            role = parseBoardMemberRole(user_roles[user_id]);
                        }
                    })
                }

                if (role === '') {
                    userObj.primaryText = `${user.display_name}`

                } else {
                    userObj.primaryText = `${user.display_name} (${role})`
                }

                userObj.displayName = user.display_name
                userObj.role = role
                userObj.secondaryText = `${parseResidentType(user.resident_type)}`
                userObj.profilePic = user.profile_pic
                userObj.id = user.user_id

                property.userList.push(userObj);
            }
        })

        if (filterData && property.userList.length > 0) {
            return property;
        }
    }

    if (!filterData) {
        return property;
    }

    return null;
}

export function addBoardMemberAdapter(prop_list, hidePropAddlDetails = false, user_roles = null, filterData = false) {
    let reqArray = [];
    if (prop_list && prop_list.length > 0) {
        prop_list.map(prop => {
            let property = addBoardMemberObjectAdapter(prop, hidePropAddlDetails, user_roles, filterData);
            if (property) {
                reqArray.push(property);
            }
        })
    }

    return reqArray;
}

export function intercomObjectAdapter(prop, hidePropAddlDetails = false) {
    let property = {};

    if (prop.prop_details && !hidePropAddlDetails) {
        property.primaryText = `${prop.prop_unit_name} (${parsePropAddlDetails(prop.prop_details)})`

    } else {
        property.primaryText = `${prop.prop_unit_name}`
    }

    property.secondaryText = `${parsePropertyType(prop.prop_type)}`
    property.open = false
    property.id = prop.prop_id

    property.userList = [];

    if (prop.user_list && prop.user_list.length > 0) {
        prop.user_list.map(user => {
            if (user.status === "active") {
                let userObj = {};

                userObj.primaryText = `${user.display_name}`

                userObj.displayName = user.display_name
                userObj.secondaryText = `${parseResidentType(user.resident_type)}`
                userObj.profilePic = user.profile_pic
                userObj.id = user.user_id

                property.userList.push(userObj);
            }
        })

    }

    return property;
}

export function intercomAdapter(prop_list, hidePropAddlDetails = false) {
    let reqArray = [];
    if (prop_list && prop_list.length > 0) {
        prop_list.map(prop => {
            let property = intercomObjectAdapter(prop, hidePropAddlDetails);
            if (property) {
                reqArray.push(property);
            }
        })
    }

    return reqArray;
}