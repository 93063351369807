// FIXME - Block urls specific to features if disabled.
// Need to block on App New.

export const UI_CONFIG = {
    e_meeting: 'e_meeting',
    e_intercom: 'e_intercom',
    resident_e_intercom_feature: 'resident_e_intercom_feature',
    board_e_intercom_feature: 'board_e_intercom_feature',
    gk_e_intercom_feature: 'gk_e_intercom_feature',
    e_intercom_res_res: 'e_intercom_res_res',
    e_intercom_res_board: 'e_intercom_res_board',
    e_intercom_res_gk: 'e_intercom_res_gk',
    e_intercom_board_res: 'e_intercom_board_res',
    e_intercom_board_board: 'e_intercom_board_board',
    e_intercom_board_gk: 'e_intercom_board_gk',
    e_intercom_gk_res: 'e_intercom_gk_res',
    e_intercom_gk_board: 'e_intercom_gk_board',
    e_intercom_gk_gk: 'e_intercom_gk_gk',
    poll: 'poll',
    resident_poll: 'resident_poll',
    board_poll: 'board_poll',
    complaint: 'complaint',
    notice_board: 'notice_board',
    society_feed: 'society_feed',
    board_feed: 'board_feed',
    export_logs: 'export_logs',
    entry_pass: 'entry_pass',
    entry_pass_board: 'entry_pass_board',
    transactions: 'transactions',
    property_ledger: 'property_ledger',
    society_ledger: 'society_ledger',
    bills_invoices: 'bills_invoices',
    settlements: 'settlements',
    visitors: 'visitors',
    gate_approvals: 'gate_approvals',
    gate_approvals_board: 'gate_approvals_board',
    my_pass: 'my_pass',
    amenities_feature: 'amenities_feature',
    amenities: 'amenities',
    amenities_board: 'amenities_board',
    manage_amentites: 'manage_amentites',
    vehicle: 'vehicle',
    vehicle_board_office: 'vehicle_board_office',
    parcel_delivery: 'parcel_delivery',
    parcel_delivery_board_office: 'parcel_delivery_board_office',
    patrol_tracker: 'patrol_tracker',
    my_property: 'my_property',
    my_society: 'my_society',
    board_office: 'board_office',
    board_member_zone: 'board_member_zone',
    society_details: 'society_details',
    society_properties: 'society_properties',
    board_members: 'board_members',
    society_staff: 'society_staff',
    society_security: 'society_security',
    new_resident_requests: 'new_resident_requests',
    board_complaints: 'board_complaints',
    emergency: 'emergency'
};

export const UI_DISPLAY_TYPES = {
    SHOW: 'show',
    HIDE: 'hide',
    DISABLE: 'disable'
}

export const getUIOverride = (key, ui_config = {}) => {
    let display = UI_DISPLAY_TYPES.SHOW;
    if (ui_config[key]) {
        if (typeof ui_config[key].hide === "boolean") {
            display = ui_config[key].hide ? UI_DISPLAY_TYPES.HIDE : UI_DISPLAY_TYPES.SHOW;
        }
        else if (typeof ui_config[key].hide === "string") {
            display = ui_config[key].hide;
        }
    }

    return {
        display: display,
        title: !ui_config[key] || !ui_config[key].title ? undefined : ui_config[key].title
    }
}