import { REHYDRATE } from 'redux-persist'

import ApiError from '../../utils/api-error'
import {
    LOGOUT_RESET, TRANSACTION_DETAILS, TRANSACTION_DETAILS_SUCCESS,
    TRANSACTION_DETAILS_ERROR, TRANSACTION_DETAILS_CLEAR_CACHE
} from '../../actions/types';

const INITIAL_STATE = {
    transaction_details: {},
    loading: true
}

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case REHYDRATE: if (action.payload && action.payload.transaction_details) return { ...action.payload.transaction_details }; else return INITIAL_STATE;

        case TRANSACTION_DETAILS: return { ...state, loading: true };

        case TRANSACTION_DETAILS_SUCCESS:
            const apiData = action.payload && action.payload.apiData;

            if (apiData !== undefined && apiData !== null) {
                let transaction_details = ApiError(apiData.statusCode, apiData.message, apiData.gsRespData, "transaction_details", {});

                if (transaction_details && typeof transaction_details === "object") {
                    let state_transaction_details = { ...state.transaction_details };

                    state_transaction_details[`${transaction_details.txn_id}-${transaction_details.txn_type}`] = transaction_details;

                    return { ...state, loading: false, transaction_details: state_transaction_details }

                } else {
                    return { ...state, loading: false };
                }

            } else {
                return { ...state, loading: false };
            }

        case TRANSACTION_DETAILS_ERROR:
            return { ...state, loading: false };

        case TRANSACTION_DETAILS_CLEAR_CACHE:
            let transaction_details = { ...state.transaction_details };
            if (action.payload.txn_id) {
                delete transaction_details[action.payload.txn_id];
            }
            else {
                transaction_details = {};
            }
            return { ...state, transaction_details: transaction_details };

        case LOGOUT_RESET:
            return { ...INITIAL_STATE };

        default: return state;
    }
}