import React from 'react';
import { Link } from 'react-router-dom';

import RaisedButton from 'material-ui/RaisedButton';

import FloatingActionButton from 'material-ui/FloatingActionButton';
import ContentAdd from 'material-ui/svg-icons/content/add';

import styles from './styles';

class SideNavCta extends React.PureComponent {
    render() {
        return (
            <Link
                action="replace"
                to={`/${this.props.route}`}
                style={{ textDecoration: 'none' }}
                onClick={(e) =>
                    this.props.enabled
                        ? {}
                        : e.preventDefault()}>
                <>
                    <RaisedButton
                        className="hideTabView"
                        label={this.props.title}
                        style={styles.longButtonOuterStyle}
                        buttonStyle={styles.longButtonStyle}
                        labelStyle={styles.longButtonLabelStyle}
                    />
                    <FloatingActionButton
                        className="hideDesktopView"
                        style={styles.fabStyle}
                        backgroundColor={styles.fabBackgroundColor} >
                        <ContentAdd />
                    </FloatingActionButton>
                </>
            </Link>
        )
    }
}

export default SideNavCta;