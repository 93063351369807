// These apis are called by home screen and their components.
// Its kept in different file because to avoid the unnecessary import of actions.
// Which in turn directly or indirectly imports moment and moment-timezone.
// Causing initial node modules file larger, hence causing initial load slower.

import axios from 'middleware/axios';
import { API_LINK } from 'config/config';
import { cl_access_token, cl_user_id, cl_signedIn, cl_property_address } from 'config/storage-variables';
import {
    UPDATE_ROUTE,
    LOGOUT_RESET, LOGIN_TRIGGER_ACTION,
    SOCIETY_AVAILABLE_TRIGGER_ACTION, SOCIETY_NOT_AVAILABLE_TRIGGER_ACTION,
    USER_HOME_SCREEN_DATA, USER_HOME_SCREEN_DATA_SUCCESS, USER_HOME_SCREEN_DATA_FAILURE,
    SOCIETY_POLL_LIST, SOCIETY_POLL_LIST_MORE, SOCIETY_POLL_LIST_SUCCESS, SOCIETY_POLL_LIST_ERROR,
    HOME_SCREEN_DATA, HOME_SCREEN_DATA_SUCCESS, HOME_SCREEN_DATA_FAILURE,
    SERVICE_CATEGORY, SERVICE_CATEGORY_SUCCESS, SERVICE_CATEGORY_ERROR,
    ALLSERVICES, ALLSERVICES_SUCCESS, ALLSERVICES_ERROR,
    USER_ADDRESSES, USER_ADDRESSES_SUCCESS, USER_ADDRESSES_ERROR, USER_ADDRESSES_SELECT, USER_ADDRESSES_CLEAR_CACHE,
    ADD_NEW_USER_ADDRESS, EDIT_USER_ADDRESS, REMOVE_USER_ADDRESS,
    JSK_DETAILS, JSK_DETAILS_SUCCESS, JSK_DETAILS_ERROR,
    EMERGENCY_CONTACTS, EMERGENCY_CONTACTS_SUCCESS, EMERGENCY_CONTACTS_ERROR,
    CUSTOM_BANNERS, CUSTOM_BANNERS_SUCCESS, CUSTOM_BANNERS_ERROR, USER_HOME_SCREEN_SET_BOARD_FEED_BADGE, USER_HOME_SCREEN_SET_SOCIETY_FEED_BADGE,
} from './types';

import store from '../reducer-store';    // Get the store object created in the called file.

export function logoutResetReducer() {
    store.dispatch({ type: LOGOUT_RESET, payload: { initial: true } });   // Using as direct function from calling file. Hence dispatching directly to store.
    // Do not use inside connect and mapDispatchToProps.
}

export function loginTriggerAction() {
    store.dispatch({ type: LOGIN_TRIGGER_ACTION });   // Using as direct function from calling file. Hence dispatching directly to store.
    // Do not use inside connect and mapDispatchToProps.
}

export function societyAvailableTriggerAction() {
    store.dispatch({ type: SOCIETY_AVAILABLE_TRIGGER_ACTION });   // Using as direct function from calling file. Hence dispatching directly to store.
    // Do not use inside connect and mapDispatchToProps.
}

export function societyNotAvailableTriggerAction() {
    store.dispatch({ type: SOCIETY_NOT_AVAILABLE_TRIGGER_ACTION });   // Using as direct function from calling file. Hence dispatching directly to store.
    // Do not use inside connect and mapDispatchToProps.
}

export function updateRoute(pathname, state) {
    return (dispatch) => {
        dispatch({ type: UPDATE_ROUTE, data: { [pathname]: state } });
    };
}

export function getUserHomeScreenData(community_id, prop_id) {
    let data = `community_id=${community_id}&prop_id=${prop_id}`;

    let access_token = localStorage.getItem(cl_access_token);
    if (access_token) {
        data += `&access_token=${access_token}`;
    }

    let request = axios.get(`${API_LINK}/gs_Open_City_Services/gs_getUserHomeScreenData?${data}`);

    return dispatch => {
        dispatch({ type: USER_HOME_SCREEN_DATA })

        request
            .then(res => dispatch({ type: USER_HOME_SCREEN_DATA_SUCCESS, payload: { ...res.data, cache: { community_id, prop_id } } }))
            .catch(err => dispatch({ type: USER_HOME_SCREEN_DATA_FAILURE, payload: err }));
    }
}

export function getUserHomeScreenDataWithoutDispatch(community_id, prop_id) {
    let data = `community_id=${community_id}&prop_id=${prop_id}`;

    let access_token = localStorage.getItem(cl_access_token);
    if (access_token) {
        data += `&access_token=${access_token}`;
    }

    let request = axios.get(`${API_LINK}/gs_Open_City_Services/gs_getUserHomeScreenData?${data}`);

    store.dispatch({ type: USER_HOME_SCREEN_DATA })

    request
        .then(res => store.dispatch({ type: USER_HOME_SCREEN_DATA_SUCCESS, payload: { ...res.data, cache: { community_id, prop_id } } }))
        .catch(err => store.dispatch({ type: USER_HOME_SCREEN_DATA_FAILURE, payload: err }));
}

export function getPollList(req) {
    const { community_id, board_id, board_only, status, poll_type, visibility, my_polls, last_poll_start_date, batch_size } = req;
    const batchSize = batch_size || 10;

    let data = `batch_size=${batchSize}`;
    if (community_id) {
        data += `&community_id=${community_id}`;
    }
    if (board_id) {
        data += `&board_id=${board_id}`;
    }
    if (board_only) {
        data += `&board_only=${board_only}`;
    }
    if (status) {
        data += `&status=${status}`;
    }
    if (poll_type) {
        data += `&poll_type=${poll_type}`
    }
    if (visibility) {
        data += `&visibility=${visibility}`
    }
    if (my_polls) {
        data += `&my_polls=${my_polls}`;
    }
    if (last_poll_start_date) {
        data += `&last_poll_start_date=${last_poll_start_date}`
    }
    let access_token = localStorage.getItem(cl_access_token);
    if (access_token) {
        data += `&access_token=${access_token}`;
    }

    let request = axios.get(`${API_LINK}/gs_cmnt_poll/gs_getPollList?${data}`)

    return dispatch => {
        if (last_poll_start_date) {
            dispatch({ type: SOCIETY_POLL_LIST_MORE })
        } else {
            dispatch({ type: SOCIETY_POLL_LIST })
        }

        request
            .then(res => dispatch({ type: SOCIETY_POLL_LIST_SUCCESS, payload: { apiData: res.data, batchSize: batchSize, cache: req } }))
            .catch(err => dispatch({ type: SOCIETY_POLL_LIST_ERROR, payload: err }));
    }
}

export function getHomeScreenData(city_id) {
    let data = `city_id=${city_id}`;

    let access_token = localStorage.getItem(cl_access_token);
    if (access_token) {
        data += `&access_token=${access_token}`;
    }

    let request = axios.get(`${API_LINK}/gs_Open_City_Services/getHomeScreenDataForCity?${data}`);

    return dispatch => {
        dispatch({ type: HOME_SCREEN_DATA })

        request
            .then(res => dispatch({ type: HOME_SCREEN_DATA_SUCCESS, payload: res.data, city_id }))
            .catch(err => dispatch({ type: HOME_SCREEN_DATA_FAILURE, payload: err }));
    }
}

export function category(city_id) {
    let request = axios.get(`${API_LINK}/gs_Open_City_Services/getServicesOrCategoriesForCity?city_id=${city_id}`);

    return (dispatch) => {
        dispatch({ type: SERVICE_CATEGORY });

        request
            .then(res => dispatch({ type: SERVICE_CATEGORY_SUCCESS, payload: res.data }))
            .catch(err => dispatch({ type: SERVICE_CATEGORY_ERROR, payload: err }));
    };
}

export function getAllServices(city_id, display_service) {
    let request = axios.get(`${API_LINK}/gs_Open_City_Services/getServicesOrCategoriesForCity?city_id=${city_id}&display_service=${display_service}`);
    return (dispatch) => {
        dispatch({ type: ALLSERVICES });

        request
            .then(res => dispatch({ type: ALLSERVICES_SUCCESS, payload: res.data }))
            .catch(err => dispatch({ type: ALLSERVICES_ERROR, payload: err }));
    };
}

export function getJSKServices(city_id) {
    let data = `city_id=${city_id}`;
    let access_token = localStorage.getItem(cl_access_token);
    if (access_token) {
        data += `&access_token=${access_token}`;
    }

    let request = axios.get(`${API_LINK}/gs_Services/gs_getJSKData?${data}`);
    return (dispatch) => {
        dispatch({ type: JSK_DETAILS });

        request
            .then(res => dispatch({ type: JSK_DETAILS_SUCCESS, payload: res.data, cache: { city_id } }))
            .catch(err => dispatch({ type: JSK_DETAILS_ERROR, payload: err }));
    };
}

export function getNearbyEmergencyContacts(zipcode) {
    let data = `zipcode=${zipcode}`;
    let access_token = localStorage.getItem(cl_access_token);
    if (access_token) {
        data += `&access_token=${access_token}`;
    }

    let request = axios.get(`${API_LINK}/gs_Users/gs_getNearbyEmergencyContacts?${data}`);
    return (dispatch) => {
        dispatch({ type: EMERGENCY_CONTACTS });

        request
            .then(res => dispatch({ type: EMERGENCY_CONTACTS_SUCCESS, payload: res.data, cache: { zipcode } }))
            .catch(err => dispatch({ type: EMERGENCY_CONTACTS_ERROR, payload: err }));
    };
}


export function getUserAddresses() {
    let user_id = undefined, access_token = undefined;

    if (localStorage.getItem(cl_signedIn) === 'true') {
        user_id = localStorage.getItem(cl_user_id);
        access_token = localStorage.getItem(cl_access_token);
    }

    let prop_list = localStorage.getItem(cl_property_address) ? JSON.parse(localStorage.getItem(cl_property_address)) : [];
    prop_list = JSON.stringify(prop_list);

    return dispatch => {
        if (!user_id || !access_token) {
            dispatch({ type: LOGOUT_RESET });
        }
        else {
            dispatch({ type: USER_ADDRESSES });

            let request = axios.post(`${API_LINK}/gs_Users/${user_id}/gs_PostClientPortfolio?access_token=${access_token}`, { prop_list: prop_list })
            request
                .then(res => dispatch({ type: USER_ADDRESSES_SUCCESS, payload: { apiData: res.data, prop_list } }))
                .catch(err => dispatch({ type: USER_ADDRESSES_ERROR, payload: err }));
        }
    }
}

export function selectUserAddress(address) {
    return dispatch => {
        dispatch({ type: USER_ADDRESSES_SELECT, payload: address });
    }
}

export function addNewUserAddress(address) {
    return dispatch => {
        dispatch({ type: ADD_NEW_USER_ADDRESS, payload: address });
    }
}

export function editUserAddress(address) {
    return dispatch => {
        dispatch({ type: EDIT_USER_ADDRESS, payload: address });
    }
}

export function removeUserAddress(address) {
    return dispatch => {
        dispatch({ type: REMOVE_USER_ADDRESS, payload: address });
    }
}

export function getCustomBannerData(filters) {
    if (!filters) {
        filters = {};
    }

    if (localStorage.getItem(cl_access_token) === 'true') {
        filters.access_token = localStorage.getItem(cl_access_token);
    }

    let data = '';
    if (filters) {
        Object.keys(filters).forEach(key => {
            if (data) {
                data += '&';
            }
            if (typeof filters[key] === "object") {
                data += key + '=' + encodeURI(JSON.stringify(filters[key]));
            }
            else {
                data += key + '=' + filters[key];
            }
        });
    }
    if (data) {
        data = '?' + data;
    }

    let request = axios.get(`${API_LINK}/bc_inapp_ads_config/gs_inAppAdDetails${data}`);

    return (dispatch) => {
        dispatch({ type: CUSTOM_BANNERS });

        request
            .then(res => dispatch({ type: CUSTOM_BANNERS_SUCCESS, payload: res.data, cache: filters }))
            .catch(err => dispatch({ type: CUSTOM_BANNERS_ERROR, payload: err }));
    };
}

export function setBoardFeedBadge(show) {
    return dispatch => {
        dispatch({ type: USER_HOME_SCREEN_SET_BOARD_FEED_BADGE, payload: show });
    }
}

export function setSocietyFeedBadge(show) {
    return dispatch => {
        dispatch({ type: USER_HOME_SCREEN_SET_SOCIETY_FEED_BADGE, payload: show });
    }
}