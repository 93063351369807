import React from 'react'
import ContentLoader from 'react-content-loader';

function TopBarLoader() {
    return (
        <ContentLoader viewBox="0 0 380 70" height={50}>
            <rect x="0" y="8" rx="4" ry="4" width="340" height="25" />
            <circle cx="370" cy="20" r="12" />
        </ContentLoader>
    )
}

function QuickActionsLoader() {
    return (
        <ContentLoader viewBox="0 0 380 70" height={50}>
            <circle cx="20" cy="20" r="16" />
            <circle cx="100" cy="20" r="16" />
            <circle cx="190" cy="20" r="16" />
            <circle cx="280" cy="20" r="16" />
            <circle cx="370" cy="20" r="16" />
        </ContentLoader>
    )
}

function SectionFeedLoader() {
    return (
        <ContentLoader viewBox="0 0 380 70" height={120}>
            <rect x="0" y="15" rx="4" ry="4" width="120" height="15" />
            <rect x="0" y="40" rx="4" ry="4" width="320" height="70" />
            <rect x="330" y="40" rx="4" ry="4" width="320" height="70" />
        </ContentLoader>
    )
}

function SectionActionsLoader() {
    return (
        <ContentLoader viewBox="0 0 380 70" height={120}>
            <rect x="0" y="15" rx="4" ry="4" width="120" height="15" />
            <rect x="0" y="40" rx="4" ry="4" width="80" height="80" />
            <rect x="100" y="40" rx="4" ry="4" width="80" height="80" />
            <rect x="200" y="40" rx="4" ry="4" width="80" height="80" />
            <rect x="300" y="40" rx="4" ry="4" width="80" height="80" />
        </ContentLoader>
    )
}

export const SocietyLoader = () => (
    <div style={{ padding: '20px 5%' }}>
        <TopBarLoader />
        <QuickActionsLoader />
        <SectionFeedLoader />
        <SectionFeedLoader />
        <SectionActionsLoader />
    </div>
)
