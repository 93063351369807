import React, { PureComponent } from 'react';
import { downloadMedia } from 'utils/handle-media';
import { intermediate_explore_route } from 'config/storage-variables';
import { exploreRoute } from 'routes-parameters/config';
import '../../../../../../home-society/components/quick-actions/styles.css';

class VerticalHomeScreenComponent extends PureComponent {
    handleCardClick(route, event) {
        if (typeof this.props.handleCardClick === "function") {
            if (route) {
                sessionStorage.setItem(intermediate_explore_route, route);
                this.props.history.push(`/${exploreRoute}`);
            }
            this.props.handleCardClick(route, event);
        }
    }

    renderItem = datum => {
        return (
            <div className="action-outer" style={{ width: '20%', padding: '8px', maxWidth: '100px', marginBottom: '12px' }} onClick={(event) => this.handleCardClick(datum.redirectUrl, event)} key={datum.id}>
                <div className="action-icon-outer">
                    <div className="action-icon" style={{ height: '60px', width: '60px' }}>
                        <img src={downloadMedia(datum.icon)} style={{ height: '60px', width: '60px' }} />
                    </div>
                </div>
                <div className="action-label" style={{ paddingRight: '8px', fontSize: '10px', textTransform: 'capitalize' }}>
                    {datum.title}
                </div>
            </div>
        );
    }

    renderData = data => {
        if (data && data.length > 0) {
            return data.map(datum => this.renderItem(datum));
        }
        else {
            return null;
        }
    }

    render() {
        return (
            <div className="quick-actions" style={{ marginTop: '8px' }}>
                {this.renderData(this.props.data)}
            </div>
        )
    }
}

export default VerticalHomeScreenComponent;