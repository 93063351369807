import { REHYDRATE } from 'redux-persist';
import {
    ALLSERVICES, ALLSERVICES_SUCCESS, ALLSERVICES_ERROR,
    GET_NEARBY_SERVICES, GET_NEARBY_SERVICES_SUCCESS, GET_NEARBY_SERVICES_ERROR
} from '../../actions/types';
import { ss_allservices } from 'config/storage-variables';
import ApiError, { handleNetworkError } from '../../utils/api-error';

const INITIAL_STATE = {
    service_list: [],
    error: undefined,
    loading: true,
    is_logout: false
};

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case REHYDRATE:
            if (action.payload && action.payload.allServices)
                return { ...action.payload.allServices };
            else
                return INITIAL_STATE;

        case GET_NEARBY_SERVICES:
        case ALLSERVICES:
            return { ...INITIAL_STATE, loading: true, is_logout: false };

        case GET_NEARBY_SERVICES_SUCCESS:
            const apiData = action.payload;
            const payload = ApiError(apiData.statusCode, apiData.message, apiData.gsRespData, null, {});

            if (payload.error) {
                return {
                    ...state,
                    error: payload.error,
                    loading: false
                }
            }

            let category_json = {};
            if (payload && payload.cat_list && payload.cat_list.constructor === Array) {
                payload.cat_list.forEach(item => {
                    category_json[item.cat_id] = item;
                });
            }
            let service_list = [];
            if (payload && payload.svc_list && payload.svc_list.constructor === Array) {
                service_list = payload.svc_list.map(item => ({
                    category_icon: category_json[item.cat_id] ? category_json[item.cat_id].cat_icon : null,
                    category_id: category_json[item.cat_id] ? category_json[item.cat_id].cat_id : null,
                    category_name: category_json[item.cat_id] ? category_json[item.cat_id].cat_name : null,
                    redirection_url: null,
                    service_description: item.svc_desc,
                    service_icon: item.svc_icon,
                    service_id: item.service_id,
                    service_name: item.svc_name,
                    status: 'active'
                }));
            }
            sessionStorage.setItem(ss_allservices, JSON.stringify(service_list));

            let newState = {
                ...INITIAL_STATE,
                loading: false,
                error: undefined,
                service_list: service_list,
            };

            return newState;

        case ALLSERVICES_SUCCESS:
            if (action.payload !== undefined && action.payload !== null) {
                let resp = ApiError(action.payload.statusCode, action.payload.message, action.payload.gsRespData, null, []);
                if (resp && resp.length > 0) {
                    sessionStorage.setItem(ss_allservices, JSON.stringify(resp));
                    return { ...INITIAL_STATE, service_list: resp, loading: false, is_logout: false };
                }
                else if (resp) {
                    sessionStorage.setItem(ss_allservices, JSON.stringify([]));
                    return { ...INITIAL_STATE, loading: false, is_logout: false };
                }
            }
            else
                return { ...state, loading: false, is_logout: false };
            break;

        case GET_NEARBY_SERVICES_ERROR:
        case ALLSERVICES_ERROR:
            let hNE_res = handleNetworkError(action);
            if (hNE_res.error)
                return { ...INITIAL_STATE, ...hNE_res, loading: false, is_logout: false };
            else if (action.payload && action.payload.response && action.payload.response.data && action.payload.response.data.error) {
                if (action.payload.response.data.error.statusCode === 401)
                    return { ...INITIAL_STATE, error: action.payload.response.data.error, loading: false, is_logout: true };
                else
                    return { ...INITIAL_STATE, error: action.payload.response.data.error, loading: false, is_logout: false };
            }
            else
                return { ...INITIAL_STATE, error: action.payload, loading: false, is_logout: false };

        default: return state;
    }
}