import {
    societyTransactionsRoute,
    pettycashTransactionsRoute,
    mySocietyRoute,
    boardSocietyMsgBoardRoute,
    complaintHelpDeskRoute,
    societyBillsInvoicesRoute,
    societySettlementsRoute,
    patrolTrackerRoute,
    exportDataRoute,
    societyDetailsRoute, 
    societyPropertiesRoute, 
    gatekeepersInfoRoute,
    societyStaffRoute, 
    residentRequestsRoute, 
    boardMembersRoute    
} from 'routes-parameters/config';

import SocietyIcon from '../../../../society/assets/society.svg';
import SocietyTransactionIcon from '../../../../society/assets/ledger.svg';
import PettyCashTransactionIcon from '../../../../society/assets/petty-cash.svg';
import PettyCashTransactionIconUS from '../../../../society/assets/petty-cash-us.svg';
import BillsInvoicesIcon from '../../../../society/assets/bills-invoice.svg';
import HelpDeskIcon from '../../../../society/assets/complaint-helpdesk.svg';
import MessageBoardIcon from '../../../../society/scenes/message-board/assets/message.svg';
import SettlementIcon from '../../../../society/assets/settlement.svg';
import SettlementIconUS from '../../../../society/assets/settlement_us.svg';
import PatrolTrackerIcon from '../../../../society/assets/patrolling_gaurd.svg';
import ExportDataIcon from '../../../../society/assets/export-data.svg';

import BoardMemberIcon from '../../../../society/assets/board-member.svg';
import SocietyDetailsIcon from '../../../../society/assets/society-details.svg';
import SocietyPropertiesIcon from '../../../../society/assets/property.svg';
import SecurityIcon from '../../../../society/assets/guard.svg';
import StaffIcon from '../../../../society/assets/staff.svg';
import ResidentIcon from '../../../../society/assets/residents.svg';

import { UI_CONFIG, getUIOverride, UI_DISPLAY_TYPES } from '../../../config';
import { sortFeatureCards } from '../../../util';
 

export function societyBoardCards(isd_code, ui_config, isSuperBM) {
    if (!ui_config) {
        ui_config = {};
    }
    let cards = [];
    if ([UI_DISPLAY_TYPES.SHOW, UI_DISPLAY_TYPES.DISABLE].includes(getUIOverride(UI_CONFIG.transactions, ui_config).display) &&
        [UI_DISPLAY_TYPES.SHOW, UI_DISPLAY_TYPES.DISABLE].includes(getUIOverride(UI_CONFIG.society_ledger, ui_config).display)) {
        cards.push({
            title: getUIOverride(UI_CONFIG.society_ledger, ui_config).title || "Balancesheet & Payments",
            image: "",
            svgImage: SocietyTransactionIcon,
            color: "",
            route: `/${societyTransactionsRoute}`,
            disable: getUIOverride(UI_CONFIG.transactions, ui_config).display === UI_DISPLAY_TYPES.DISABLE ||
                getUIOverride(UI_CONFIG.society_ledger, ui_config).display === UI_DISPLAY_TYPES.DISABLE
        });
    }
    if ([UI_DISPLAY_TYPES.SHOW, UI_DISPLAY_TYPES.DISABLE].includes(getUIOverride(UI_CONFIG.transactions, ui_config).display) &&
    [UI_DISPLAY_TYPES.SHOW, UI_DISPLAY_TYPES.DISABLE].includes(getUIOverride(UI_CONFIG.pettycash_ledger, ui_config).display)) {
    cards.push({
        title: getUIOverride(UI_CONFIG.pettycash_ledger, ui_config).title || "Petty Cash Ledger",
        image: "",
        svgImage: isd_code !== '91' ? PettyCashTransactionIconUS: PettyCashTransactionIcon,
        color: "",
        route: `/${pettycashTransactionsRoute}`,
        disable: getUIOverride(UI_CONFIG.transactions, ui_config).display === UI_DISPLAY_TYPES.DISABLE ||
            getUIOverride(UI_CONFIG.pettycash_ledger, ui_config).display === UI_DISPLAY_TYPES.DISABLE
    });
}
    if ([UI_DISPLAY_TYPES.SHOW, UI_DISPLAY_TYPES.DISABLE].includes(getUIOverride(UI_CONFIG.transactions, ui_config).display) &&
        [UI_DISPLAY_TYPES.SHOW, UI_DISPLAY_TYPES.DISABLE].includes(getUIOverride(UI_CONFIG.bills_invoices, ui_config).display)) {
        cards.push({
            title: getUIOverride(UI_CONFIG.bills_invoices, ui_config).title || "Property Bills/Invoices",
            image: "",
            svgImage: BillsInvoicesIcon,
            color: "",
            route: `/${societyBillsInvoicesRoute}`,
            disable: getUIOverride(UI_CONFIG.transactions, ui_config).display === UI_DISPLAY_TYPES.DISABLE ||
                getUIOverride(UI_CONFIG.bills_invoices, ui_config).display === UI_DISPLAY_TYPES.DISABLE
        });
    }
    if (isSuperBM &&
        [UI_DISPLAY_TYPES.SHOW, UI_DISPLAY_TYPES.DISABLE].includes(getUIOverride(UI_CONFIG.transactions, ui_config).display) &&
        [UI_DISPLAY_TYPES.SHOW, UI_DISPLAY_TYPES.DISABLE].includes(getUIOverride(UI_CONFIG.settlements, ui_config).display)) {
        cards.push({
            title: getUIOverride(UI_CONFIG.settlements, ui_config).title || "OlaGate Settlements",
            image: "",
            svgImage: isd_code !== '91' ? SettlementIconUS: SettlementIcon,
            color: "",
            route: `/${societySettlementsRoute}`,
            disable: getUIOverride(UI_CONFIG.transactions, ui_config).display === UI_DISPLAY_TYPES.DISABLE ||
                getUIOverride(UI_CONFIG.settlements, ui_config).display === UI_DISPLAY_TYPES.DISABLE
        });
    }
    /*
    if ([UI_DISPLAY_TYPES.SHOW, UI_DISPLAY_TYPES.DISABLE].includes(getUIOverride(UI_CONFIG.transactions, ui_config).display) &&
        [UI_DISPLAY_TYPES.SHOW, UI_DISPLAY_TYPES.DISABLE].includes(getUIOverride(UI_CONFIG.society_ledger, ui_config).display)) {
        cards.push({
            title: getUIOverride(UI_CONFIG.society_ledger, ui_config).title || "Report Income",
            image: "",
            svgImage: SocietyTransactionIcon,
            color: "",
            route: `/${societyTransactionsRoute}`,
            disable: getUIOverride(UI_CONFIG.transactions, ui_config).display === UI_DISPLAY_TYPES.DISABLE ||
                getUIOverride(UI_CONFIG.society_ledger, ui_config).display === UI_DISPLAY_TYPES.DISABLE
        });
    }    
    if ([UI_DISPLAY_TYPES.SHOW, UI_DISPLAY_TYPES.DISABLE].includes(getUIOverride(UI_CONFIG.transactions, ui_config).display) &&
        [UI_DISPLAY_TYPES.SHOW, UI_DISPLAY_TYPES.DISABLE].includes(getUIOverride(UI_CONFIG.society_ledger, ui_config).display)) {
        cards.push({
            title: getUIOverride(UI_CONFIG.society_ledger, ui_config).title || "Report Expense",
            image: "",
            svgImage: SocietyTransactionIcon,
            color: "",
            route: `/${societyTransactionsRoute}`,
            disable: getUIOverride(UI_CONFIG.transactions, ui_config).display === UI_DISPLAY_TYPES.DISABLE ||
                getUIOverride(UI_CONFIG.society_ledger, ui_config).display === UI_DISPLAY_TYPES.DISABLE
        });
    }
    */
    if ([UI_DISPLAY_TYPES.SHOW, UI_DISPLAY_TYPES.DISABLE].includes(getUIOverride(UI_CONFIG.notice_board, ui_config).display) &&
        [UI_DISPLAY_TYPES.SHOW, UI_DISPLAY_TYPES.DISABLE].includes(getUIOverride(UI_CONFIG.board_feed, ui_config).display)) {
        cards.push({
            title: getUIOverride(UI_CONFIG.board_feed, ui_config).title || "Message Board",
            image: "",
            svgImage: MessageBoardIcon,
            color: "",
            route: `/${boardSocietyMsgBoardRoute}`,
            disable: getUIOverride(UI_CONFIG.notice_board, ui_config).display === UI_DISPLAY_TYPES.DISABLE ||
                getUIOverride(UI_CONFIG.board_feed, ui_config).display === UI_DISPLAY_TYPES.DISABLE
        });
    }
    if (
        [UI_DISPLAY_TYPES.SHOW, UI_DISPLAY_TYPES.DISABLE].includes(getUIOverride(UI_CONFIG.board_complaints, ui_config).display)) {
        cards.push({
            title: getUIOverride(UI_CONFIG.board_complaints, ui_config).title || "Mgmt HelpDesk",
            image: "",
            svgImage: HelpDeskIcon,
            color: "",
            route: `/${complaintHelpDeskRoute}`,
            disable: getUIOverride(UI_CONFIG.board_complaints, ui_config).display === UI_DISPLAY_TYPES.DISABLE
        });
    }
    if ([UI_DISPLAY_TYPES.SHOW, UI_DISPLAY_TYPES.DISABLE].includes(getUIOverride(UI_CONFIG.new_resident_requests, ui_config).display)) {
        cards.push({
            title: getUIOverride(UI_CONFIG.new_resident_requests, ui_config).title || "New Resident Requests" ,
            image: "",
            svgImage: ResidentIcon ,
            color: "",            
            route: `/${residentRequestsRoute}`,            
            disable: getUIOverride(UI_CONFIG.new_resident_requests, ui_config).display === UI_DISPLAY_TYPES.DISABLE            
        });
    }
    if ([UI_DISPLAY_TYPES.SHOW, UI_DISPLAY_TYPES.DISABLE].includes(getUIOverride(UI_CONFIG.patrol_tracker, ui_config).display)) {
        cards.push({
            title: getUIOverride(UI_CONFIG.patrol_tracker, ui_config).title || "Patrol Tracker",
            image: "",
            svgImage: PatrolTrackerIcon,
            color: "",
            route: `/${patrolTrackerRoute}`,
            disable: getUIOverride(UI_CONFIG.patrol_tracker, ui_config).display === UI_DISPLAY_TYPES.DISABLE
        });
    }
    ////////////////////////////////////////////////////////////////////////////////////////////////    
    if ([UI_DISPLAY_TYPES.SHOW, UI_DISPLAY_TYPES.DISABLE].includes(getUIOverride(UI_CONFIG.society_details, ui_config).display)) {
        cards.push({
            title: getUIOverride(UI_CONFIG.society_details, ui_config).title || "Society Details" ,
            image: "",
            svgImage: SocietyDetailsIcon ,
            color: "",            
            route: `/${societyDetailsRoute}`,            
            disable: getUIOverride(UI_CONFIG.society_details, ui_config).display === UI_DISPLAY_TYPES.DISABLE            
        });
    }      
    if ([UI_DISPLAY_TYPES.SHOW, UI_DISPLAY_TYPES.DISABLE].includes(getUIOverride(UI_CONFIG.board_members, ui_config).display)) {
        cards.push({
            title: getUIOverride(UI_CONFIG.board_members, ui_config).title || "Board Members" ,
            image: "",
            svgImage: BoardMemberIcon ,
            color: "",            
            route: `/${boardMembersRoute}`,            
            disable: getUIOverride(UI_CONFIG.board_members, ui_config).display === UI_DISPLAY_TYPES.DISABLE            
        });
    }
    if ([UI_DISPLAY_TYPES.SHOW, UI_DISPLAY_TYPES.DISABLE].includes(getUIOverride(UI_CONFIG.society_properties, ui_config).display)) {
        cards.push({
            title: getUIOverride(UI_CONFIG.society_properties, ui_config).title || "Society Properties" ,
            image: "",
            svgImage: SocietyPropertiesIcon ,
            color: "",            
            route: `/${societyPropertiesRoute}`,            
            disable: getUIOverride(UI_CONFIG.society_properties, ui_config).display === UI_DISPLAY_TYPES.DISABLE            
        });
    }
    if ([UI_DISPLAY_TYPES.SHOW, UI_DISPLAY_TYPES.DISABLE].includes(getUIOverride(UI_CONFIG.society_security, ui_config).display)) {
        cards.push({
            title: getUIOverride(UI_CONFIG.society_security, ui_config).title || "Security" ,
            image: "",
            svgImage: SecurityIcon ,
            color: "",            
            route: `/${gatekeepersInfoRoute}`,            
            disable: getUIOverride(UI_CONFIG.society_security, ui_config).display === UI_DISPLAY_TYPES.DISABLE            
        });
    }
    if ([UI_DISPLAY_TYPES.SHOW, UI_DISPLAY_TYPES.DISABLE].includes(getUIOverride(UI_CONFIG.society_staff, ui_config).display)) {
        cards.push({
            title: getUIOverride(UI_CONFIG.society_staff, ui_config).title || "Staff" ,
            image: "",
            svgImage: StaffIcon ,
            color: "",            
            route: `/${societyStaffRoute}`,            
            disable: getUIOverride(UI_CONFIG.society_staff, ui_config).display === UI_DISPLAY_TYPES.DISABLE            
        });
    }
    if ([UI_DISPLAY_TYPES.SHOW, UI_DISPLAY_TYPES.DISABLE].includes(getUIOverride(UI_CONFIG.export_logs, ui_config).display)) {
        cards.push({
            title: getUIOverride(UI_CONFIG.export_logs, ui_config).title || "Export Logs",
            image: "",
            svgImage: ExportDataIcon,
            color: "",
            route: `/${exportDataRoute}`,
            disable: getUIOverride(UI_CONFIG.export_logs, ui_config).display === UI_DISPLAY_TYPES.DISABLE
        });
    }
    /*
    cards.push({
        title: getUIOverride(UI_CONFIG.my_society, ui_config).title || "My Society",
        image: "",
        svgImage: SocietyIcon,
        color: "",
        route: `/${mySocietyRoute}`,
        disable: getUIOverride(UI_CONFIG.my_society, ui_config).display === UI_DISPLAY_TYPES.DISABLE
    });
    */
    cards = sortFeatureCards(cards);

    return cards
}

