const styles = {
    container: {        
        //padding: '20px 5%', //original
        padding: '15px 0%',
        borderBottomColor: 'black',
        borderBottomWidth: 1,
    },
    filterContainer: {
        marginBottom: '16px'        
    }
}

export default styles;