import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { clearBookingFlowData, updateBookingFlowData, updateMiscellaneousComponents } from 'actions';
import { booking_flow_object } from 'scenes/client/scenes/booking/config';
import { ogfoodRoute, exploreRoute, spPageparentRoute, spDetailsPageRoute } from 'routes-parameters/config';

import RenderCards from './components/renderCards';
import { cl_new_booking_created, bkg_call_sp_profile_api } from 'config/storage-variables';

class CustomBanner extends PureComponent {
    onClickAd(ad, redirect_obj) {
        /*
            "redirect_url": "",
            "tab": "ogcare/ogfood",
            "ogfood_catid": "",
            "ogfood_opunit": "",
            "ogcare_catid": "",
            "ogcare_catname": "",
            "ogcare_svcid": "",
            "ogcare_spid": ""
        */
        let redirect = {};
        if (ad.redirect && Object.keys(ad.redirect).length > 0) {
            redirect = { ...ad.redirect }
        } else if (redirect_obj && Object.keys(redirect_obj).length > 0) {
            redirect = { ...redirect_obj }
        }

        if (redirect.redirect_url) {
            window.open(redirect.redirect_url, '_blank');
        }
        else if (redirect.tab) {
            if (redirect.tab === 'ogcare') {
                this.props.updateMiscellaneousComponents({
                    explore_screen_tab: 2
                })
                this.props.history.push(`/${exploreRoute}`);
            } else if (redirect.tab === 'ogcart') {
                this.props.updateMiscellaneousComponents({
                    explore_screen_tab: 1
                })
                this.props.history.push(`/${exploreRoute}`);            
            } else if (redirect.tab === 'ogfood') {
                this.props.updateMiscellaneousComponents({
                    explore_screen_tab: 0
                })
                this.props.history.push(`/${exploreRoute}`);
            }
        }
        else if (redirect.ogfood_opunit) {
            this.props.history.push(`/${ogfoodRoute}/${redirect.ogfood_opunit}`)
        }
        else if (redirect.ogfood_catid) {
            this.props.updateMiscellaneousComponents({
                explore_screen_tab: 0,          // select ogfood tab on explore screen
                selected_ogfood_cat_id: redirect.ogfood_catid,          // select category on explore screen
            })
            this.props.history.push(`/${exploreRoute}`);
        }
        else if (redirect.ogcare_spid) {
            this.props.clearBookingFlowData("all");
            this.props.updateBookingFlowData({
                [booking_flow_object.sp_id]: redirect.ogcare_spid,
            });

            sessionStorage.removeItem(cl_new_booking_created);
            sessionStorage.setItem(bkg_call_sp_profile_api, 'true');

            this.props.history.push(`/${spPageparentRoute}/${spDetailsPageRoute}`);
        }
        else if (redirect.ogcare_svcid && redirect.ogcare_catid) {
            this.props.updateMiscellaneousComponents({
                explore_screen_tab: 1,          // select service tab on explore screen
                selected_cat_id: redirect.ogcare_catid,          // select category on explore screen
                selected_svc_id: redirect.ogcare_svcid,          // select service on explore screen
            })
            this.props.history.push(`/${exploreRoute}`);
        }
        else if (redirect.ogcare_catid) {
            this.props.updateMiscellaneousComponents({
                explore_screen_tab: 1,          // select service tab on explore screen
                selected_cat_id: redirect.ogcare_catid,          // select category on explore screen
            })
            this.props.history.push(`/${exploreRoute}`);
        }
    }

    render() {
        const banner_data = this.props.banner_data
        if (this.props.loading || !this.props.banner_data) {
            return null;
        }

        let items = [], width = undefined, height = undefined, redirect_obj = undefined;
        if (banner_data && banner_data.creative_info && banner_data.creative_info.items) {
            items = banner_data.creative_info.items;
        }
        if (banner_data && banner_data.creative_info && banner_data.creative_info.spec) {
            if (banner_data.creative_info.spec.width) {
                width = banner_data.creative_info.spec.width;
            }
            if (banner_data.creative_info.spec.height) {
                height = banner_data.creative_info.spec.height;
            }
        }
        if (banner_data && banner_data.creative_info && banner_data.creative_info.redirect) {
            redirect_obj = banner_data.creative_info.redirect;
        }

        if (!items || items.length === 0) {
            return null;
        }
        return (
            <div style={Object.assign({}, { padding: '0px', marginTop: 8, marginBottom: 8 }, (this.props.style || {}))}>
                <RenderCards
                    autoplay={true}
                    count={items.length}
                    interval={4000}
                    onClickAd={ad => this.onClickAd(ad, redirect_obj)}
                    Ads={items}
                    history={this.props.history}
                    width={width}
                    height={height}
                />
            </div>
        );
    }
}

function mapStateToProps({ custom_banners }) {
    const { loading, error, banner_data, cache } = custom_banners;

    return {
        loading,
        error,
        banner_data,
        cache
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ clearBookingFlowData, updateBookingFlowData, updateMiscellaneousComponents }, dispatch);
}

CustomBanner.displayName = 'CustomBanner';
export default connect(mapStateToProps, mapDispatchToProps)(CustomBanner);

/*
    country_code
    city_name
    zipcode
    lat
    lng
    prop_id
    screen_name -   "home/nbh-ogfood/nbh-ogcare/nbh-ogfresh"

    positioning
        home -> below quick actions
        nbh  -> below tabs
        puledger -> below ledger summary
*/