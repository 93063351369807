import React, { PureComponent } from 'react';

import RaisedButton from 'material-ui/RaisedButton';
import SelectField from 'material-ui/SelectField';
import MenuItem from 'material-ui/MenuItem';

import { unit_detail_types } from '../../scenes/select-society/scenes/register-property/config';
import { styles } from '../../scenes/select-society/scenes/register-property/styles';
import { encodePropFilter, decodePropFilter, buildPropFilterRelation, buildInitialPropFilterObject, extractBoardIdForFilter } from '../../utils';

class PropertiesFilter extends PureComponent {
    constructor(props) {
        super(props);

        this.filter_fields = [];
        if (props.filter_fields && typeof props.filter_fields === "string") {
            this.filter_fields = props.filter_fields.split(",");
        }
        this.filter_option_fields = [].concat(this.filter_fields);
        this.filter_options = [];
        if (props.filter_options && props.filter_options.constructor === Array) {
            this.filter_options = props.filter_options;
        }

        let initial_types_value = {};
        unit_detail_types.forEach(item => {
            initial_types_value[item.type] = '';
        });

        if (props.selected_filter_values) {
            Object.keys(props.selected_filter_values).forEach(key => {
                if (this.filter_fields.includes(key)) {
                    initial_types_value[key] = props.selected_filter_values[key];
                }
            });
        }

        this.state = {
            ...initial_types_value,
            loading: true
        };
    }

    componentDidMount() {
        if (this.filter_fields.length === 0) {
            this.callPropertyListFetch();
        }
        else {
            this.prop_filter_relation = buildPropFilterRelation(this.filter_options);
            this.intial_prop_filter_obj = buildInitialPropFilterObject(this.prop_filter_relation);
            this.setState({ loading: false });

            if (this.props.selected_filter_values) {
                let flag = false;
                for (let i = 0; i < this.filter_fields.length; i++) {
                    if (this.state[this.filter_fields[i]]) {
                        const encoded_key = encodePropFilter(this.filter_fields[i], this.state[this.filter_fields[i]]);

                        if (!this.intial_prop_filter_obj[encoded_key] || this.intial_prop_filter_obj[encoded_key].length === 0) {
                            flag = true;
                            break;
                        }
                    }
                }
                if (flag) {
                    this.callPropertyListFetch({ ...this.state });
                }
            }
        }
    }

    callPropertyListFetch(state_obj) {
        if (typeof this.props.callPropertyListFetch === "function") {
            let filter_data = {};
            if (state_obj && Object.keys(state_obj).length > 0) {
                unit_detail_types.forEach(item => {
                    if (state_obj[item.type]) {
                        filter_data[item.type] = state_obj[item.type];
                    }
                });
            }

            // Extract board_id for selected filters
            const board_id = extractBoardIdForFilter(this.filter_options, filter_data, this.props.common_board_id);
            this.props.callPropertyListFetch(filter_data, board_id);
        }
    }

    shouldDisableSubmit(state_obj = this.state) {
        let flag = true;
        if (state_obj && Object.keys(state_obj).length > 0) {
            for (let i = 0; i < unit_detail_types.length; i++) {
                const item = unit_detail_types[i];
                if (state_obj[item.type]) {
                    flag = false;
                    break;
                }
            };
        }
        return flag;
    }

    handleChangeFilterValue(value, field) {
        if (this.state[field] === value) {
            return;
        }

        let newState = { [field]: value };
        let pos = null;
        for (let i = 0; i < this.filter_fields.length; i++) {
            if (this.filter_fields[i] === field) {
                pos = i;
            }
            else if (pos !== null) {
                // Reset all filters after updated one in the list
                newState[this.filter_fields[i]] = '';
            }
        }
        const encoded_key = encodePropFilter(field, value);

        // Reorganize filter option fields based on value selected.
        let filter_option_fields = [];
        for (let i = 0; i <= pos; i++) {
            if (newState[this.filter_fields[i]] || this.state[this.filter_fields[i]]) {
                filter_option_fields.push(this.filter_fields[i]);
            }
        }
        const next_field = Object.keys(this.prop_filter_relation).filter(key => this.prop_filter_relation[key] === encoded_key);
        if (next_field.length > 0) {
            const [type, value] = decodePropFilter(next_field[0]);
            filter_option_fields.push(type);
        }
        this.filter_option_fields = filter_option_fields;

        // Selected filter is last one, so call fetching property list api.
        if (!this.props.partialFiltersAllowed && (pos === null || pos === this.filter_option_fields.length - 1 || !this.intial_prop_filter_obj[encoded_key] || this.intial_prop_filter_obj[encoded_key].length === 0)) {
            this.callPropertyListFetch({ ...this.state, ...newState });
        }

        this.setState({ ...newState });
    }

    renderDropDown(field, encoded_key) {
        if (!this.intial_prop_filter_obj || !this.intial_prop_filter_obj[encoded_key] || this.intial_prop_filter_obj[encoded_key].length === 0) {
            return null;
        }

        const data_list = this.intial_prop_filter_obj[encoded_key];

        return (
            <div style={{ margin: '0px auto', width: 'fit-content' }} key={field}>
                <div style={{ width: 'fit-content' }}><label style={{ marginTop: '0px', marginBottom: '2px', textTransform: 'capitalize' }}>{field}</label></div>
                <SelectField
                    id={field}
                    value={this.state[field]}
                    onChange={(event, index, value) => this.handleChangeFilterValue(value, field)}
                    underlineShow={false}
                    style={Object.assign({}, styles.SelectField, this.props.is_filter_selected ? { width: 'fit-content' } : {})}
                >
                    {data_list.map(item => <MenuItem key={item.key} id={field + '-' + item.value} value={item.value} primaryText={item.value} />)}
                </SelectField>
            </div>
        );
    }

    renderList() {
         return this.filter_option_fields.map((field, i) => {
            if (i === 0) {
                return this.renderDropDown(field, 'start');
            }
            else {
                if (this.state[this.filter_option_fields[i - 1]]) {
                    return this.renderDropDown(field, encodePropFilter(this.filter_option_fields[i - 1], this.state[this.filter_option_fields[i - 1]]));
                }
                else {
                    return null;
                }
            }
        });
    }

    render() {
        if (this.state.loading) {
            return null;
        }

        if (this.filter_fields.length === 0) {
            return null;
        }

        const style = this.props.is_filter_selected ? { display: 'flex', flexWrap: 'wrap', position: 'sticky', marginTop: '-20px', top: '40px', paddingTop: '20px', backgroundColor: 'white', zIndex: 2 } : {};

        return (
            <>
                <div style={style}>
                    {this.renderList()}
                </div>
                {
                    this.props.partialFiltersAllowed &&
                    <div style={{ marginTop: '20px', textAlign: 'center' }}>
                        <RaisedButton
                            label={this.props.filterButtonLabel || "Submit"}
                            primary={true}
                            disabled={this.shouldDisableSubmit()}
                            onClick={() => this.callPropertyListFetch({ ...this.state })}
                        />
                    </div>
                }
            </>
        );
    }
}

export default PropertiesFilter;