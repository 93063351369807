export default function ApiError(statusCode, message, gsRespData, field, type) {
    let sc = statusCode.toString().slice(0, 2);
    let res = type instanceof Array ? [] : {};
    if (sc == 20 || sc == 30) {
        if (field === null)
            res = gsRespData === undefined ? type instanceof Array ? [] : {} : gsRespData;
        else
            res = gsRespData[field] === undefined ? type instanceof Array ? [] : {} : gsRespData[field];
    }
    else if (sc == 40) {
        res = { error: { statusCode: statusCode, message: message } };
    }
    else if (sc == 50) {
        res = { error: { statusCode: statusCode, message: message } };
    }
    else {
        res = { error: { statusCode: 700, message: "Server failed to responds" } }
    }
    return res;
}

export function handleNetworkError(action) {
    if (action.payload.message === "Network Error") {
        return { error: { statusCode: 601, message: "Network Error" } };
    }
    else
        return {};
}

export function parseError(action) {
    let hNE_res = handleNetworkError(action);
    if (hNE_res.error)
        return { ...hNE_res, loading: false, is_logout: false };
    else if (action.payload && action.payload.response && action.payload.response.data && action.payload.response.data.error) {
        if (action.payload.response.data.error.statusCode === 401)
            return { error: action.payload.response.data.error, loading: false, is_logout: true };
        else
            return { error: action.payload.response.data.error, loading: false, is_logout: false };
    }
    else
        return { error: action.payload, loading: false, is_logout: false };
}
