import { REHYDRATE } from 'redux-persist';
import { GET_OGFOOD_DATA_LIST, GET_OGFOOD_DATA_LIST_MORE, GET_OGFOOD_DATA_LIST_SUCCESS, GET_OGFOOD_DATA_LIST_ERROR } from '../../../actions/types';
import ApiError, { parseError } from '../../../utils/api-error';

const INITIAL_STATE = {
    loading: true,
    error: undefined,
    can_load_more: false,
    cat_list: [],
    cat_list_json: {},
    opunit_list: [],
    cache: undefined
}


const RestaurantList = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case REHYDRATE:
            return action.payload && action.payload.restaurant_list
                ? { ...action.payload.restaurant_list }
                : INITIAL_STATE;

        case GET_OGFOOD_DATA_LIST: {
            return {
                ...INITIAL_STATE,
                loading: true,
            }
        }

        case GET_OGFOOD_DATA_LIST_MORE: {
            return {
                ...state,
                loading: true,
            }
        }

        case GET_OGFOOD_DATA_LIST_SUCCESS: {
            const { cache } = action;
            const apiData = action.payload;

            const payload = ApiError(apiData.statusCode, apiData.message, apiData.gsRespData, null, {});

            if (payload.error) {
                return {
                    ...state,
                    error: payload.error,
                    loading: false
                }
            }

            let cat_list = [], cat_list_json = {};
            if (payload && payload.cat_list && payload.cat_list.constructor === Array && payload.cat_list.length > 0) {
                cat_list = payload.cat_list;
                cat_list_json = cat_list.reduce((arr, cur) => {
                    arr[cur.cat_id] = cur;
                    return arr;
                }, {});
            }
            else {
                cat_list = state.cat_list;
                cat_list_json = state.cat_list_json;
            }

            let opunit_list = [];
            if (payload && payload.opunit_list && payload.opunit_list.constructor === Array) {
                opunit_list = payload.opunit_list.map(opunit => {
                    const pic = opunit.media && opunit.media.pic && opunit.media.pic[0] ? opunit.media.pic[0] : null;
                    if (pic) {
                        opunit.pic = pic;
                    }
                    delete opunit.media;
                    return opunit;
                })
            }
            const can_load_more = opunit_list.length === ((cache && cache.batch_size) || 10);
            if (cache && cache.last_opuid) {
                opunit_list = state.opunit_list.concat(opunit_list);
            }
            else {
                opunit_list = [].concat(opunit_list);
            }

            let newState = {
                loading: false,
                error: undefined,
                can_load_more: can_load_more,
                cat_list: cat_list,
                cat_list_json: cat_list_json,
                opunit_list: opunit_list,
                cache: cache
            };

            return newState;
        }

        case GET_OGFOOD_DATA_LIST_ERROR: {
            return {
                ...state,
                ...parseError(action)
            }
        }

        default:
            return state;
    }
};

export default RestaurantList;