import { encodeUrl } from 'utils/helper-functions';
import { getProductCategories } from '../api';

export const productCategoryApiAdapter = catList => {
    return catList;
}

export const getCategory = (city_id, pcat_name, pcat_data, pcat_cache) => {
    const name = encodeUrl(pcat_name);
  
    return new Promise((resolve, reject) => {
      const catData = pcat_cache && pcat_cache.city_id === city_id && pcat_data;
      if (catData && catData.length > 0) {
        resolve(searchCategory(catData, name))
      } else {
        getProductCategories(city_id, (res, err) => {
          if (res) {
            resolve(searchCategory(res, name))
          } else {
            resolve(null)
          }
        })
      }
    });
  
  }
  
  function searchCategory(catData, name) {
    if(catData && catData.length > 0) {
      for (const category of catData) {
        if (encodeUrl(category.pcat_name) === name) {
          return category;
        }
      }
    }
  
    return null;
  }

export function dummyPCatApiData() {
    return {
        "pcat_list": [
            { "pcat_id": 1, "pcat_name": "Grocery", "pcat_desc": "Grocery", "pcat_type": "non_perishable", "pcat_icon": "https://storage.cloud.google.com/gruhaps-gcs-public/grs.icons/grocery_dailyneeds.png" },
            { "pcat_id": 2, "pcat_name": "Household", "pcat_desc": "Household", "pcat_type": "non_perishable", "pcat_icon": "https://storage.cloud.google.com/gruhaps-gcs-public/grs.icons/grocery_dailyneeds.png" },
            { "pcat_id": 3, "pcat_name": "Dairy & Eggs", "pcat_desc": "Dairy & Eggs", "pcat_type": "perishable", "pcat_icon": "https://storage.cloud.google.com/gruhaps-gcs-public/grs.icons/grocery_dailyneeds.png" },
            { "pcat_id": 4, "pcat_name": "Beauty", "pcat_desc": "Beauty", "pcat_type": "non_perishable", "pcat_icon": "https://storage.cloud.google.com/gruhaps-gcs-public/grs.icons/grocery_dailyneeds.png" },
            { "pcat_id": 5, "pcat_name": "Fresh Meat", "pcat_desc": "Fresh Meat", "pcat_type": "perishable", "pcat_icon": "https://storage.cloud.google.com/gruhaps-gcs-public/grs.icons/grocery_dailyneeds.png" },
            { "pcat_id": 6, "pcat_name": "Frozen Meat & Food", "pcat_desc": "Frozen Meat & Food", "pcat_type": "perishable", "pcat_icon": "https://storage.cloud.google.com/gruhaps-gcs-public/grs.icons/grocery_dailyneeds.png" },
            { "pcat_id": 7, "pcat_name": "Vegetables & Fruits", "pcat_desc": "Vegetables & Fruits", "pcat_type": "perishable", "pcat_icon": "https://storage.cloud.google.com/gruhaps-gcs-public/grs.icons/grocery_dailyneeds.png" },
            { "pcat_id": 8, "pcat_name": "Organic Farm Fresh", "pcat_desc": "Organic Farm Fresh", "pcat_type": "perishable", "pcat_icon": "https://storage.cloud.google.com/gruhaps-gcs-public/grs.icons/grocery_dailyneeds.png" },
            { "pcat_id": 9, "pcat_name": "Imported Fruits", "pcat_desc": "Imported Fruits", "pcat_type": "perishable", "pcat_icon": "https://storage.cloud.google.com/gruhaps-gcs-public/grs.icons/grocery_dailyneeds.png" },
            { "pcat_id": 10, "pcat_name": "Bakery-Cakes-Dairy", "pcat_desc": "Bakery-Cakes-Dairy", "pcat_type": "perishable", "pcat_icon": "https://storage.cloud.google.com/gruhaps-gcs-public/grs.icons/grocery_dailyneeds.png" },
            { "pcat_id": 11, "pcat_name": "Beverages", "pcat_desc": "Beverages", "pcat_type": "non_perishable", "pcat_icon": "https://storage.cloud.google.com/gruhaps-gcs-public/grs.icons/grocery_dailyneeds.png" },
            { "pcat_id": 12, "pcat_name": "Branded Foods", "pcat_desc": "Branded Foods", "pcat_type": "perishable", "pcat_icon": "https://storage.cloud.google.com/gruhaps-gcs-public/grs.icons/grocery_dailyneeds.png" },
            { "pcat_id": 13, "pcat_name": "Baby Care", "pcat_desc": "Baby Care", "pcat_type": "non_perishable", "pcat_icon": "https://storage.cloud.google.com/gruhaps-gcs-public/grs.icons/grocery_dailyneeds.png" },
            { "pcat_id": 14, "pcat_name": "Personal Care", "pcat_desc": "Personal Care", "pcat_type": "non_perishable", "pcat_icon": "https://storage.cloud.google.com/gruhaps-gcs-public/grs.icons/grocery_dailyneeds.png" },
        ]
    }
}