import { parseMeetingLogData } from './meeting-log-config'

const MEETING_TYPE = {
    INDIVIDUAL: "one-to-one",
    GENERAL: "general",
    BOARD: "board"
}

const EINTERCOM_EVENT_TYPE = {
    PARTICIPANT_LEFT: 'PARTICIPANT_LEFT'
};

export {
    MEETING_TYPE,
    parseMeetingLogData,
    EINTERCOM_EVENT_TYPE
}