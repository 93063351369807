import axios from 'middleware/axios';
import { API_LINK } from 'config/config';
import { cl_access_token, cl_user_id } from 'config/storage-variables';

export function getCommunityPropertyDetails(community_id, prop_id = null, board_id = null, cb) {
    let access_token = localStorage.getItem(cl_access_token);

    let request = '';

    if (prop_id) {
        request = `community_id=${community_id}&prop_id=${prop_id}`;

    } else {
        request = `community_id=${community_id}`;
    }
    if (board_id) {
        request += `&board_id=${board_id}`;
    }

    axios.get(`${API_LINK}/gs_Community/gs_getCommunityPropDetails?` + request + `&access_token=${access_token}`)
        .then(res => {
            if (res && res.data && res.data.statusCode.toString().slice(0, 2) == 20) {
                cb(res.data.gsRespData, null);
            } else {
                cb(null, res.data);
            }
        })
        .catch(err => {
            if (err.response && err.response.data && err.response.data.error) {
                if (err.response.data.error.statusCode === 401) {
                    cb(null, { ...err.response.data.error, is_logout: true });
                } else {
                    cb(null, err.response.data.error);
                }
            } else {
                cb(null, err);
            }
        })
}

export function postRemoveProperty(community_id, prop_id, cb) {
    let access_token = localStorage.getItem(cl_access_token);
    const data = {
        community_id,
        prop_id
    }

    axios.post(`${API_LINK}/gs_Community/gs_postRemoveCommunityProperty?access_token=${access_token}`, data)
        .then(res => {
            if (res && res.data && res.data.statusCode.toString().slice(0, 2) == 20) {
                cb(res.data.gsRespData, null);
            } else {
                cb(null, res.data);
            }
        })
        .catch(err => {
            if (err.response && err.response.data && err.response.data.error) {
                if (err.response.data.error.statusCode === 401) {
                    cb(null, { ...err.response.data.error, is_logout: true });
                } else {
                    cb(null, err.response.data.error);
                }
            } else {
                cb(null, err);
            }
        })
}

export function postRemovePropertyUser(community_id, prop_id, user_id, cb) {
    let access_token = localStorage.getItem(cl_access_token);
    const data = {
        community_id,
        prop_id,
        user_id
    }

    axios.post(`${API_LINK}/gs_Community/gs_postRemoveCommunityProperty?access_token=${access_token}`, data)
        .then(res => {
            if (res && res.data && res.data.statusCode.toString().slice(0, 2) == 20) {
                cb(res.data.gsRespData, null);
            } else {
                cb(null, res.data);
            }
        })
        .catch(err => {
            if (err.response && err.response.data && err.response.data.error) {
                if (err.response.data.error.statusCode === 401) {
                    cb(null, { ...err.response.data.error, is_logout: true });
                } else {
                    cb(null, err.response.data.error);
                }
            } else {
                cb(null, err);
            }
        })
}

export function updatePropertySettings(prop_id, prop_details, cb) {
    let access_token = localStorage.getItem(cl_access_token);

    let data = {
        prop_id,
        prop_details
    };

    axios.post(`${API_LINK}/gs_Community/gs_postEditCommunityPropertyDetailsSettings?access_token=${access_token}`, data)
        .then(res => {
            if (res && res.data && res.data.statusCode.toString().slice(0, 2) == 20) {
                cb(res.data, null);
            } else {
                cb(null, res.data);
            }
        })
        .catch(err => {
            if (err.response && err.response.data && err.response.data.error) {
                if (err.response.data.error.statusCode === 401) {
                    cb(null, { ...err.response.data.error, is_logout: true });
                } else {
                    cb(null, err.response.data.error);
                }
            } else {
                cb(null, err);
            }
        })
}