import { REHYDRATE } from 'redux-persist';

import {
    GET_OGFOOD_ORDER_LIST,
    GET_OGFOOD_ORDER_LIST_MORE,
    GET_OGFOOD_ORDER_LIST_SUCCESS,
    GET_OGFOOD_ORDER_LIST_ERROR,
    CLEAR_OGFOOD_ORDER_LIST_CACHE,
} from '../../../actions/types';
import ApiError, { parseError } from '../../../utils/api-error';

const INITIAL_STATE = {
    loading: false,
    error: undefined,
    order_list: [],
    cache: undefined,
    can_load_more: true,
};

const OGFoodOrderList = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case REHYDRATE:
            return action.payload && action.payload.food_order_list
                ? { ...action.payload.food_order_list }
                : INITIAL_STATE;

        case GET_OGFOOD_ORDER_LIST:
            return { ...INITIAL_STATE, loading: true };

        case GET_OGFOOD_ORDER_LIST_MORE:
            return { ...state, loading: true };

        case GET_OGFOOD_ORDER_LIST_SUCCESS:
            const cache = { ...action.cache };

            const apiData = action.payload;
            const payload = ApiError(apiData.statusCode, apiData.message, apiData.gsRespData, null, {});

            if (payload.error) {
                return {
                    ...state,
                    error: payload.error,
                    loading: false
                }
            }

            let order_list = [];
            if (payload && payload.order_list) {
                order_list = payload.order_list;
            }
            const can_load_more = order_list.length === ((cache && cache.batch_size) || 10);
            if (cache && cache.last_opuid) {
                order_list = state.order_list.concat(order_list);
            }
            else {
                order_list = [].concat(order_list);
            }

            let newState = {
                loading: false,
                error: undefined,
                order_list: order_list || [],
                cache: cache,
                can_load_more: can_load_more,
            };

            return newState;

        case GET_OGFOOD_ORDER_LIST_ERROR:
            return {
                ...state,
                ...parseError(action)
            };

        case CLEAR_OGFOOD_ORDER_LIST_CACHE:
            return {
                ...state,
                cache: undefined,
            }

        default:
            return state;
    }
};

export default OGFoodOrderList;
