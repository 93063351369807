import { REHYDRATE } from 'redux-persist';
import { JSK_DETAILS, JSK_DETAILS_SUCCESS, JSK_DETAILS_ERROR } from '../../actions/types';
import ApiError, { handleNetworkError } from '../../utils/api-error';

const INITIAL_STATE = {
    jsk_service_list: [],
    error: undefined,
    loading: true,
    is_logout: false,
    cache: null
};

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case REHYDRATE: if (action.payload && action.payload.jsk_details) return { ...action.payload.jsk_details }; else return INITIAL_STATE;
        case JSK_DETAILS: return { ...INITIAL_STATE, loading: true, is_logout: false };
        case JSK_DETAILS_SUCCESS:
            if (action.payload !== undefined && action.payload !== null) {
                let resp = ApiError(action.payload.statusCode, action.payload.message, action.payload.gsRespData, "service_list", []);
                if (resp && resp.length > 0) {
                    return { ...INITIAL_STATE, jsk_service_list: resp, cache: action.cache, loading: false, is_logout: false };
                }
                else {
                    return { ...INITIAL_STATE, loading: false, cache: action.cache, is_logout: false };
                }
            }
            else
                return { ...state, loading: false, is_logout: false };
        case JSK_DETAILS_ERROR:
            let hNE_res = handleNetworkError(action);
            if (hNE_res.error)
                return { ...INITIAL_STATE, ...hNE_res, loading: false, is_logout: false };
            else if (action.payload && action.payload.response && action.payload.response.data && action.payload.response.data.error) {
                if (action.payload.response.data.error.statusCode === 401)
                    return { ...INITIAL_STATE, error: action.payload.response.data.error, loading: false, is_logout: true };
                else
                    return { ...INITIAL_STATE, error: action.payload.response.data.error, loading: false, is_logout: false };
            }
            else
                return { ...INITIAL_STATE, error: action.payload, loading: false, is_logout: false };
        default: return state;
    }
}