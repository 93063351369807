import { isString, isNullOrEmptyObject, mergeObjects, sortObject, displayWithCurrency, isDNhoodEnv } from 'utils/helper-functions';

const PDetailsObjKeysPriority = ['About', 'Best Before', 'Ingredients', 'OtherInfo', 'Other Info'];

export const parseSku = psku => {
    const { quantity, effectivePrice, origPrice, discount } = parsePrices(psku.sku_price);

    return {
        pSkuId: psku.psku_id,
        brandName: psku.pbrand_name,
        skuText: `${psku.psize} ${psku.punit_code}`,
        skuTitle: psku.psku_title,
        skuIcon: psku.psku_icon,
        quantity: quantity ? quantity : 0,
        effectivePrice: effectivePrice ? displayWithCurrency(effectivePrice) : isDNhoodEnv() ? displayWithCurrency(0) : null,
        origPrice: origPrice ? displayWithCurrency(origPrice) : isDNhoodEnv() ? displayWithCurrency(0) : null,
        discount: discount ? displayWithCurrency(discount) : null,
        effectivePriceVal: effectivePrice,
        oriPriceVal: origPrice,
        discountVal: discount,
        skuInfo: psku.psku_info
    }
}

export const getMaxQuantity = psku => psku && psku.sku_price && psku.sku_price.quantity ? psku.sku_price.quantity : 0;

function parsePrices(sku_price) {
    if (!sku_price) {
        return {
            effectivePrice: null,
            oriPrice: null,
            discount: null,
            effectivePriceVal: 0,
            oriPriceVal: 0,
            discountVal: 0
        }
    }

    const price = sku_price.price && parseFloat(sku_price.price) !== 0 ? +parseFloat(sku_price.price).toFixed(2) : null;
    const effectivePrice = sku_price.discount_price && parseFloat(sku_price.discount_price) !== 0 ? +parseFloat(sku_price.discount_price).toFixed(2) : null;
    const discount = sku_price.discount && parseFloat(sku_price.discount) !== 0 ? +parseFloat(sku_price.discount).toFixed(2) : null;

    return {
        quantity: sku_price.quantity,
        effectivePrice: effectivePrice,
        origPrice: price,
        discount: discount
    }
}

export const getSkuProductTitle = (product_title, skuInfo) => {
    if (skuInfo && isString(skuInfo.psku_title)) {
        return skuInfo.psku_title;
    }

    if (isString(product_title)) {
        return product_title;
    }

    return ""
}

export const getSkuImages = (images, sku) => {
    const skuImages = sku && sku.skuInfo && sku.skuInfo.public_info && sku.skuInfo.public_info.product_images;
    if (!isNullOrEmptyObject(skuImages)) {
        return {
            big_images: skuImages.big,
            small_images: skuImages.small
        }
    }

    return images;
}

export const getSkuPublicInfo = (product_info, sku) => {
    const productInfo = !isNullOrEmptyObject(product_info) ? product_info : {};
    let skuPublicInfo = {};
    if (sku && sku.skuInfo && !isNullOrEmptyObject(sku.skuInfo.public_info)) {
        skuPublicInfo = { ...sku.skuInfo.public_info };
        delete skuPublicInfo.product_images;
    }

    return {
        ...productInfo,
        public_info: sortObject(mergeObjects(productInfo.public_info, skuPublicInfo), PDetailsObjKeysPriority)
    }
}