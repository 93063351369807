import gs_color from 'config/colors.css';

const styles = {
    outerPaper: {
        width: '100%',
        padding: '12px 8px',
        display: 'flex',
        minHeight: '120px',
        backgroundColor: 'white',
        borderBottom: `1px solid #acacac`
    },
    firstDiv: {
        display: 'flex',
        flexGrow: 1,
        paddingRight: '4px'
    },
    secondDiv: {
        padding: '4px',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center'
        //borderLeft: `2px solid #acacac`
        //borderLeft: `2px solid ${gs_color.backgroundSecondaryColor}`
    },
    productImgDiv: {
        width: '50%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'start',
        justifyContent: 'center',
    },
    productDetailsDiv: {
        width: '50%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
    },
    productCatHeader: {
        fontSize: '18px',
        fontWeight: 'bold',
        color: gs_color.textColor,
        padding: '0px 8px',
    },
    productCatDesc: {
        fontSize: '12px',
        color: '#757575',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        lineHeight: '1.6',
        maxHeight: '3.2em',
        padding: '0px 8px',
    },
    arrowIcon: {
        transition: 'all .3s linear'
        //backgroundColor: gs_color.backgroundSecondaryColor
    },
    outerSubCategoriesPaper: {
        display: 'flex',
        flexWrap: 'wrap',
        alignItems: 'start',
        padding: '0px 0px 4px',
        backgroundColor: 'white',
        borderBottom: `4px double ${gs_color.primaryColor}`
    },
    outerSubCategoryItem: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'start',
        justifyContent: 'center',
        width: '33.3%',
        padding: '16px 16px',
        boxSizing: 'border-box',
        marginTop: '6px',
        marginBottom: '6px'
    },
    subCategoryImage: {
        height: '70px',
        width: '100px'
    },
    subCategoryTitle: {
        marginTop: '16px',
        textAlign: 'center',
        fontWeight: 600,
        color: gs_color.textColor
    }
}

export default styles;