import { REHYDRATE } from 'redux-persist';
import { UPDATE_PRODUCT_SCROLL, CLEAR_PRODUCT_SCROLL } from '../../actions/types';

const INITIAL_STATE = {};

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case REHYDRATE: if (action.payload && action.route_parameters) return { ...action.payload.product_scroll_details }; else return INITIAL_STATE;
        case UPDATE_PRODUCT_SCROLL: return { ...state, ...action.data };
        case CLEAR_PRODUCT_SCROLL: let new_state = { ...state };
            if (action.data) {
                if (action.data === "all") {
                    new_state = INITIAL_STATE;
                }
                else if (action.data.constructor === Array) {
                    action.data.forEach(key => delete new_state[key])
                }
            }
            return new_state;
        default: return state;
    }
}