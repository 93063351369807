import gs_color from 'config/colors.css';

const enabledColor = gs_color.textColor;
const disabledColor = "#BDBDBD";

const SideNavItemMinorOuterDiv = {
    marginTop: '4px',
    padding: '4px',
    fontSize: '15px',
    color: enabledColor,
    cursor: 'pointer'
}

export default {
    SideNavItemMinorOuterDiv,
    enabledColor,
    disabledColor
}