import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Waypoint } from 'react-waypoint';
import { clearBookingFlowData, updateBookingFlowData, updateMiscellaneousComponents } from 'actions';
import DatePicker from 'material-ui/DatePicker';
import DropDownMenu from 'material-ui/DropDownMenu';
import MenuItem from 'material-ui/MenuItem';
import RestaurantListLoader from '../../../../../food/restaurant-list/restaurant-list-loader';
import SPListItem from '../sp-list-item';
import NoServiceProducts from '../../scenes/new-home/component/no-service-products';
import PostFilters, { styles as postFiltersStyles } from '../../../society/scenes/message-board/components/post-filters';
import { spDetailsPageRoute, selectSPServiceGroupsRoute, spPageparentRoute, getItemsRoute } from 'routes-parameters/config';
import { bkg_call_sp_profile_api, bkg_call_service_specific_api, bkg_call_questionnaire_api, bkg_call_sp_details_api, cl_new_booking_created } from 'config/storage-variables';
import { booking_flow_object, booking_search_flow_enum } from 'scenes/client/scenes/booking/config';
import { compareDate, toISODate } from 'utils/date-time-conversion';
import { encodeUrl } from 'utils/helper-functions';
import { styles as registrationStyles } from '../../../society/scenes/select-society/scenes/register-property/styles';
import styles from '../../../../../food/restaurant-list/styles';
import gs_color from 'config/colors.css';

class ServiceList extends PureComponent {
    constructor() {
        super();

        this.state = {
            categoryFilters: [],
            //svcFilters: ['Select Service'],
            svcFilters: 'Select Service',
            selected_svc: {},
            selected_date: undefined,
            selected_date_error: ''
            
        }

        this.today = new Date();
        this.tomorrow = new Date((new Date(this.today)).setDate(this.today.getDate() + 1));
        this.todayPlus1Month = new Date((new Date(this.today)).setMonth(this.today.getMonth() + 1));
    }

    componentDidMount() {
        let new_cache = { ...(this.props.cache || {}) }
        if (this.props.miscellaneous_components && this.props.miscellaneous_components.selected_cat_id) {
            const categoryFilters = [this.props.miscellaneous_components.selected_cat_id];
            this.setState({ categoryFilters });
            new_cache.cat_id = this.props.miscellaneous_components.selected_cat_id
        }
        if (this.props.miscellaneous_components && this.props.miscellaneous_components.selected_svc_id) {
            const svcFilters = [this.props.miscellaneous_components.selected_svc_id];
            this.setState({ svcFilters });
            new_cache.svc_id = this.props.miscellaneous_components.selected_svc_id
        }
        if (this.props.miscellaneous_components && this.props.miscellaneous_components.selected_date) {
            this.setState({ selected_date: new Date(this.props.miscellaneous_components.selected_date) });
            new_cache.svc_date = this.props.miscellaneous_components.selected_date
        }

        if (
            (!this.props.cache && Object.keys(new_cache).length > 0) ||
            (this.props.cache && (
                this.props.cache.cat_id !== new_cache.cat_id ||
                this.props.cache.svc_id !== new_cache.svc_id ||
                this.props.cache.svc_date !== new_cache.svc_date
            ))
        ) {
            this.props.refreshPage(new_cache);
        }
    }

    onSPSelected(sp_details) {
        if (!this.state.selected_date) {
            this.showDateError();
            return;
        }

        this.props.clearBookingFlowData("all");
        this.props.updateBookingFlowData({
            [booking_flow_object.sp_id]: sp_details.sp_id,
            [booking_flow_object.category_name]: sp_details.category_name,
            [booking_flow_object.category_id]: sp_details.category_id,
            [booking_flow_object.booking_search_flow]: booking_search_flow_enum.sp_name     // Taking sp name flow
        });

        //Special case for Service Desk.
        if (sp_details.service_id){
            this.props.updateBookingFlowData({
                [booking_flow_object.svc_id]: sp_details.service_id,
                [booking_flow_object.svc_name]: sp_details.service_name,
            });
        }
        
        sessionStorage.removeItem(cl_new_booking_created);
        sessionStorage.setItem(bkg_call_sp_profile_api, 'true');

        if (sp_details.sp_page_name) {
            this.props.history.push({
                pathname: `/${spPageparentRoute}/${sp_details.sp_page_name}`
            });
        }
        else {
            this.props.history.push({
                pathname: `/${spPageparentRoute}/${spDetailsPageRoute}`
                ///pathname: `/${selectSPServiceGroupsRoute}`
            });
        }
    }

    handleFormatDate(date) {
        if (compareDate(date, this.today) === 0) {
            return "Today";
        }
        else if (compareDate(date, this.tomorrow) === 0) {
            return "Tomorrow";
        }
        else {
            return [date.getDate(), date.getMonth() + 1, date.getFullYear()].join('-');
        }
    }

    onDateChange = (date) => {
        this.setState({ selected_date: date, selected_date_error: '' });
        const isoDate = toISODate(date);
        this.props.updateMiscellaneousComponents({ selected_date: isoDate });

        let filters = { ...(this.props.cache || {}) };
        filters.svc_date = isoDate;
        delete filters.last_sp_id;
        delete filters.offset;
        this.props.refreshPage(filters);
    }

    showDateError = () => {
        this.setState({ selected_date_error: 'Required' });
        if (this.datePickerRef) {
            this.datePickerRef.scrollIntoView({ behaviour: 'smooth', block: 'center' });
        }
    }

    addCategoryFilter = type => {
        if (!this.state.selected_date) {
            this.showDateError();
            return;
        }

        const categoryFilters = [type], svcFilters = [];
        this.props.updateMiscellaneousComponents({ selected_cat_id: type, selected_svc_id: undefined });
        this.setState({ categoryFilters, svcFilters });

        let filters = { ...(this.props.cache || {}) };
        filters.cat_id = type;
        delete filters.svc_id;
        delete filters.last_sp_id;
        delete filters.offset;
        this.props.refreshPage(filters);

        if (this.el) {
            this.el.scrollIntoView({ behaviour: 'smooth', block: 'center' });
        }
    }

    removeCategoryFilter = type => {
        const categoryFilters = [], svcFilters = [];
        this.setState({ categoryFilters, svcFilters });
        this.props.updateMiscellaneousComponents({ selected_cat_id: undefined, selected_svc_id: undefined });
        let filters = { ...(this.props.cache || {}) };
        delete filters.cat_id;
        delete filters.svc_id;
        delete filters.last_sp_id;
        delete filters.offset;
        this.props.refreshPage(filters);
    }

    addServiceFilter = type => {
        const svcFilters = [type];
        this.props.updateMiscellaneousComponents({ selected_svc_id: type });
        this.setState({ svcFilters });

        let filters = { ...(this.props.cache || {}) };
        filters.svc_id = type;
        delete filters.last_sp_id;
        delete filters.offset;
        this.props.refreshPage(filters);

        return;
        // 7/11/2021 - Flow changed, don't redirect to service page now.

        const service = this.props.service_list.filter(item => item.service_id === type)[0];

        if (!service) {
            return;
        }

        // Redirect to other url if exists.
        if (service.redirection_url) {
            window.open(service.redirection_url, '_blank');
            return;
        }

        if (this.props.booking_flow_data[booking_flow_object.booking_svc_obj] !== JSON.stringify(service)) {
            this.props.clearBookingFlowData("all");

            sessionStorage.setItem(bkg_call_sp_details_api, 'true');
            sessionStorage.removeItem(bkg_call_service_specific_api);
            sessionStorage.removeItem(bkg_call_questionnaire_api);
            sessionStorage.removeItem(cl_new_booking_created);
        }

        // Keep this to clear the session data of booking creation flow on service change.
        this.props.updateBookingFlowData({
            [booking_flow_object.booking_svc_obj]: JSON.stringify(service),
            [booking_flow_object.category_name]: service.category_name,
            [booking_flow_object.booking_search_flow]: booking_search_flow_enum.sp_list     // Taking sp list flow
        });

        const encoded_service_name = encodeUrl(service.service_name);

        // Always redirect to getItems, there it can decide if redirection.
        this.props.history.push({
            pathname: `/${encoded_service_name}/${getItemsRoute}`
        });
    }

    removeServiceFilter = type => {
        const svcFilters = [];
        this.setState({ svcFilters });
        this.props.updateMiscellaneousComponents({ selected_svc_id: undefined });
        let filters = { ...(this.props.cache || {}) };
        delete filters.svc_id;
        delete filters.last_sp_id;
        delete filters.offset;
        this.props.refreshPage(filters);
    }

    getLoader() {
        return <RestaurantListLoader />
    }

    renderLoadMore = () => {
        return (
            <div onClick={this.fetchMore} style={{ padding: '8px', textAlign: 'center', borderRadius: '10px', margin: '4px 8px', backgroundColor: gs_color.gradientLightColor, color: gs_color.primaryColor, cursor: 'pointer' }}>
                Load More
            </div>
        )
    }

    fetchMore = () => {
        if (!this.props.can_load_more || this.props.loading) {
            return;
        }

        let filters = { ...(this.props.cache || {}) };
        filters.offset = (this.props.sp_list && this.props.sp_list.length) || 0;
        if (this.props.sp_list && this.props.sp_list[this.props.sp_list.length - 1]) {
            filters.last_sp_id = this.props.sp_list[this.props.sp_list.length - 1].sp_id;
        }

        this.props.refreshPage(filters);
    }

    handleServiceChange = (event, index, value) => {        
        const svcFilters = [value];
        this.props.updateMiscellaneousComponents({ selected_svc_id: value });
        this.setState({ svcFilters });
        
        let filters = { ...(this.props.cache || {}) };
        filters.svc_id = value;

        delete filters.last_sp_id;
        delete filters.offset;
        this.props.refreshPage(filters);

    }

    handleSvcChange = (serviceId, serviceName) => {
        //console.log("GS_DEBUG : handleSvcChange - Service ID:", serviceId);
        //console.log("GS_DEBUG : handleSvcChange - Service Name:", serviceName);
      
        //set service name in the dropdown.
        const svcFilters = serviceName;
        this.setState({ svcFilters });
        
        this.props.updateMiscellaneousComponents({ selected_svc_id: serviceId });        
        
        let filters = { ...(this.props.cache || {}) };
        filters.svc_id = serviceId;

        delete filters.last_sp_id;
        delete filters.offset;
        this.props.refreshPage(filters);
    };


    render() {
        
        const selected_cat_id = this.state.categoryFilters[0];
        const cat_list_filters = this.props.service_category.map(item => ({ id: item.category_id, label: item.category_name }));
        const svc_list_filters = this.props.service_list.filter(item => selected_cat_id && item.category_id == selected_cat_id).map(item => ({ id: item.service_id, label: item.service_name }));
        const svc_list_filters_sorted  = svc_list_filters.sort((a, b) => a.label.localeCompare(b.label));

        const { svcFilters } = this.state;

        const arrowIconStyle = {
            position: 'absolute',
            right: '0px',
            top: '50%',
            transform: 'translateY(-50%)'            
          };
    
        return (
            <div style={styles.container}>
                <div ref={el => { this.datePickerRef = el }} style={{ ...postFiltersStyles.container, ...styles.filterContainer }}>
                    <div style={postFiltersStyles.labelStyle}>Service Date</div>
                    <DatePicker
                        hintText="Select Service Date"
                        hintStyle={{ top: 6, bottom: 6 }}
                        minDate={this.today}
                        maxDate={this.todayPlus1Month}
                        value={this.state.selected_date || null}
                        onChange={(event, date) => this.onDateChange(date)}
                        formatDate={date => this.handleFormatDate(date)}
                        textFieldStyle={!this.state.selected_date_error ? { ...registrationStyles.FullTextField, width: '256px', border: `1px solid ${gs_color.gradientLightColor}` } : { ...registrationStyles.FullTextFieldError, width: '256px' }}
                        errorStyle={{ paddingTop: '6px' }}
                        underlineShow={false}
                        errorText={this.state.selected_date_error}
                    />
                </div>
                {
                    cat_list_filters && cat_list_filters.length > 0 &&
                    <div style={styles.filterContainer}>
                        <PostFilters
                            selected={this.state.categoryFilters}
                            filters={cat_list_filters}
                            addFilter={this.addCategoryFilter}
                            removeFilter={this.removeCategoryFilter}
                            label="Select Category"
                        />
                    </div>
                }
                <div ref={el => { this.el = el }} />
                {/*   
                    //replace this with drop down and search
                    selected_cat_id && (
                        svc_list_filters && svc_list_filters.length > 0 ?
                            <div style={styles.filterContainer}>
                                <PostFilters
                                    selected={this.state.svcFilters}
                                    filters={svc_list_filters}
                                    addFilter={this.addServiceFilter}
                                    removeFilter={this.removeServiceFilter}
                                    label="Services"
                                />
                            </div>
                            : null
                    )
                */} 
                {
                svc_list_filters_sorted && svc_list_filters_sorted.length > 0 ? (                                
                    <div style={{ marginLeft: 8, marginTop: -8, marginBottom: 4, fontSize: 14, fontWeight: 'bold' }}>
                        Select Service
                    </div>
                )
                : null
                }
                {
                    selected_cat_id && (
                    svc_list_filters_sorted && svc_list_filters_sorted.length > 0 ?
                    <div style={{ margin: 'auto', display: 'flex' }}>                        
                        <DropDownMenu
                            value={svcFilters}                            
                            onChange={this.handleSvcChange}
                            labelStyle={{ minWidth: '260px' , height: '35px', lineHeight: '33px', textAlign: 'justify', paddingLeft: 8, paddingRight: 0 }}
                            style={{ height: '35px',  borderRadius: '5px',  border: `1px solid ${gs_color.gradientLightColor}` }}
                            underlineStyle={{ border: 'none' }}                            
                            iconStyle={arrowIconStyle} // Set the icon style to position it on the right-center
                            targetOrigin={{ vertical: 'center', horizontal: 'left'}}                            
                        >                            
                            {/* Render the list of service options */}
                            {
                                svc_list_filters_sorted && svc_list_filters_sorted.constructor === Array ?
                                svc_list_filters_sorted.map(opt => <MenuItem key={opt.id} value={opt.label} primaryText={opt.label}
                                                        onClick={() => this.handleSvcChange(opt.id, opt.label)} // Use a closure to pass parameters
                                                        />)
                                : 
                                (<MenuItem primaryText="No Service Found" />)
                            }
                        </DropDownMenu>
                    </div>
                    : null
                    )
                }                
                {
                    (this.props.sp_list && this.props.sp_list.length > 0) ?
                        this.props.sp_list.map(sp => (
                            <SPListItem
                                sp={sp}
                                key={sp.sp_id}
                                handleClick={() => this.onSPSelected(sp)}
                            />
                        ))
                        : !this.props.loading ?
                            <div style={{ paddingBottom: '80px' }}>
                                <NoServiceProducts
                                    title="No service provider found in your neighborhood"
                                    description="Please check after sometime"
                                />
                            </div>
                            : null
                }
                {this.props.can_load_more && !this.props.loading && this.renderLoadMore()}
                {this.props.loading && this.getLoader()}
                <Waypoint onEnter={this.fetchMore} />
            </div>
        );
    }
}

function mapStateToProps({ service_category, allServices, nearby_splist, miscellaneous_components, booking_flow_data }) {
    const { loading, error, cache, sp_list, can_load_more } = nearby_splist;

    return {
        loading,
        error,
        cache,
        sp_list,
        service_category: service_category.service_category || [],
        service_list: allServices.service_list || [],
        can_load_more,
        miscellaneous_components,
        booking_flow_data,
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        clearBookingFlowData,
        updateBookingFlowData,
        updateMiscellaneousComponents
    }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(ServiceList);
