import { REHYDRATE } from 'redux-persist';
import { UPDATE_ROUTE, CLEAR_ROUTE, LOGOUT_RESET } from '../../actions/types';

const INITIAL_STATE = {};

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case REHYDRATE: if (action.payload && action.route_parameters) return { ...action.payload.route_parameters }; else return INITIAL_STATE;
        case UPDATE_ROUTE: return { ...state, ...action.data };
        case LOGOUT_RESET:
            if (action.payload && action.payload.initial) {
                return state;
            }
            else {
                return { ...INITIAL_STATE };
            }
        case CLEAR_ROUTE: let new_state = { ...state };
            if (action.data) {
                if (action.data === "all") {
                    new_state = INITIAL_STATE;
                }
                else if (action.data.constructor === Array) {
                    action.data.forEach(key => delete new_state[key])
                }
            }
            return new_state;
        default: return state;
    }
}