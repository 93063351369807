import { REHYDRATE } from 'redux-persist';
import { CLIENTDETAILS, CLIENTDETAILS_SUCCESS, CLIENTDETAILS_ERROR, LOGOUT_RESET } from '../../actions/types';
import { handleNetworkError } from '../../utils/api-error';

const INITIAL_STATE = {
    client_details: {},
    error: undefined,
    loading: true,
    is_logout: false
};

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case REHYDRATE: if (action.payload && action.payload.client_details) return { ...action.payload.client_details }; else return INITIAL_STATE;
        case CLIENTDETAILS: return { ...INITIAL_STATE, loading: true, is_logout: false };
        case CLIENTDETAILS_SUCCESS:
            if (action.payload !== undefined && action.payload !== null) {
                return { ...INITIAL_STATE, client_details: action.payload, loading: false, is_logout: false };
            }
            else
                return { ...state, loading: false, is_logout: false };
        case CLIENTDETAILS_ERROR:
            let hNE_res = handleNetworkError(action);
            if (hNE_res.error)
                return { ...INITIAL_STATE, ...hNE_res, loading: false, is_logout: false };
            else if (action.payload && action.payload.response && action.payload.response.data && action.payload.response.data.error) {
                if (action.payload.response.data.error.statusCode === 401)
                    return { ...INITIAL_STATE, error: action.payload.response.data.error, loading: false, is_logout: true };
                else
                    return { ...INITIAL_STATE, error: action.payload.response.data.error, loading: false, is_logout: false };
            }
            else
                return { ...INITIAL_STATE, error: action.payload, loading: false, is_logout: false };
        case LOGOUT_RESET: return { ...INITIAL_STATE };
        default: return state;
    }
}