import {
    cl_selected_society_property, cl_society_properties, cl_call_prop_api
} from 'config/storage-variables'

import { getUserActiveProperties } from '../../society/api'
import { getPropNotificationCount } from './society-notification'
import { UI_CONFIG } from '../config'

export const fetchSocietiesData = (forceFetch) => new Promise((resolve, reject) => {
    if (!forceFetch && sessionStorage.getItem(cl_call_prop_api)) {
        let activePropertiesStr = localStorage.getItem(cl_society_properties)
        if (activePropertiesStr) {
            resolve(parseSocietyData(JSON.parse(activePropertiesStr)))
            return
        }
    }

    getUserActiveProperties((res, err) => {
        if (res) {
            if (res.active_props && res.active_props.length > 0) {
                const activeProperties = res.active_props
                const lsPropId = localStorage.getItem(cl_selected_society_property) ? localStorage.getItem(cl_selected_society_property) : null;
                let flag = false;
                if (lsPropId) {
                    for (let i = 0; i < activeProperties.length; i++) {
                        if (activeProperties[i].prop_id === lsPropId) {
                            flag = true;
                            break;
                        }
                    }
                }
                const propId = flag ? lsPropId : activeProperties[0].prop_id

                localStorage.setItem(cl_society_properties, JSON.stringify(activeProperties))
                localStorage.setItem(cl_selected_society_property, propId)
                sessionStorage.setItem(cl_call_prop_api, 'true')

                let share_soc_banner = !!sessionStorage.getItem(share_soc_banner)
                sessionStorage.removeItem(share_soc_banner)

                resolve({
                    share_soc_banner,
                    ...parseSocietyData(activeProperties, propId)
                })

            } else {
                localStorage.removeItem(cl_society_properties)
                localStorage.removeItem(cl_selected_society_property)

                resolve({ noSocietiesFound: true })
            }

        } else {
            reject(err)
        }
    })

})

export const selectProperty = (activeProperties, propertyId) => {
    localStorage.setItem(cl_selected_society_property, propertyId);

    return ({
        notification_count_data: getPropNotificationCount(propertyId),
        ...parseSocietyData(activeProperties, propertyId)
    })
}

export const clearSelectedProperty = () => localStorage.removeItem(cl_selected_society_property)

function parseSocietyData(activeProperties, propertyId) {
    const propId = propertyId || (localStorage.getItem(cl_selected_society_property) ? localStorage.getItem(cl_selected_society_property) : activeProperties[0].prop_id)
    const filter_prop = activeProperties.filter(property => property.prop_id === propId)
    let isBoardMember = false
    let communityCode = ""
    let status = ""
    let rejectionComment = null
    let residentType = ""
    let societyId = null
    let unread_mbpost = false
    let comm_schema_fields = null
    let boardId = null
    let cityId = null
    let isd_code = null

    if (filter_prop && filter_prop.length > 0) {
        isBoardMember = filter_prop[0].is_board_member
        communityCode = filter_prop[0].community_code
        status = filter_prop[0].status
        rejectionComment = filter_prop[0].rejection_comment
        residentType = filter_prop[0].resident_type
        societyId = filter_prop[0].community_id
        unread_mbpost = filter_prop[0].unread_mbpost
        comm_schema_fields = filter_prop[0].comm_schema_fields
        boardId = filter_prop[0].board_id
        cityId = filter_prop[0].city_id
        isd_code = filter_prop[0].isd_code
    }

    return ({
        selectedPropId: propId,
        selectedCommunityCode: communityCode,
        activeProperties: activeProperties,
        isBoardMember: isBoardMember,
        status: status,
        rejectionComment: rejectionComment,
        residentType: residentType,
        societyId: societyId,
        unread_mbpost: unread_mbpost,
        comm_schema_fields: comm_schema_fields,
        boardId: boardId,
        cityId: cityId,
        isd_code: isd_code,
    })
}

export function parseUIConfig(app_config) {
    let ui_config = {};

    if (app_config && app_config.ui_config && typeof app_config.ui_config === "object") {
        Object.keys(app_config.ui_config).forEach(key => {
            if (UI_CONFIG[key] && app_config.ui_config) {
                ui_config[key] = app_config.ui_config[key];
            }
        });
    }

    return ui_config;
}

export function showOGCHomeData(home_screen_data) {
    let flag = false;
    if (home_screen_data && home_screen_data.length > 0) {
        home_screen_data.forEach(item => {
            if (item.redirectUrl && ['SERVICES', 'PRODUCTS'].includes(item.key)) {
                flag = true;
            }
        });
    }
    return flag;
}