export const SERVICE_CATEGORY = "category";
export const SERVICE_CATEGORY_SUCCESS = "category_success";
export const SERVICE_CATEGORY_ERROR = "category_error";

export const SERVICES = "services";
export const SERVICES_SUCCESS = "services_success";
export const SERVICES_ERROR = "services_error";

export const SERVICES_ITEMS = "services_items";
export const SERVICES_ITEMS_SUCCESS = "services_items_success";
export const SERVICES_ITEMS_ERROR = "services_items_error";

export const SERVICES_ITEMS_GROUP = "services_items_group";
export const SERVICES_ITEMS_GROUP_SUCCESS = "services_items_group_success";
export const SERVICES_ITEMS_GROUP_ERROR = "services_items_group_error";

export const ALLSERVICES = "allservices";
export const ALLSERVICES_SUCCESS = "allservices_success";
export const ALLSERVICES_ERROR = "allservices_error";

export const ADS = "ads";
export const ADS_SUCCESS = "ads_success";
export const ADS_ERROR = "ads_error";

export const GETSPLIST = "getSPList";
export const GETSPLIST_SUCCESS = "getSPList_success";
export const GETSPLIST_ERROR = "getSPList_error";

export const SERVICE_PROVIDER = "service_provider";
export const SERVICE_PROVIDER_SUCCESS = "service_provider_success";
export const SERVICE_PROVIDER_ERROR = "service_provider_error";

export const CLIENTDETAILS = "client_details";
export const CLIENTDETAILS_SUCCESS = "client_details_success";
export const CLIENTDETAILS_ERROR = "client_details_error";


export const USER_PROFILE_DETAILS = "user_profile_details";
export const USER_PROFILE_DETAILS_UPDATE = "user_profile_details_update";
export const USER_PROFILE_DETAILS_SUCCESS = "user_profile_details_success";
export const USER_PROFILE_DETAILS_ERROR = "user_profile_details_error";

export const SPDETAILS = "sp_details";
export const SPDETAILS_SUCCESS = "sp_details_success";
export const SPDETAILS_ERROR = "sp_details_error";

export const SPPROFILE = "sp_profile";
export const SPPROFILE_SUCCESS = "sp_profile_success";
export const SPPROFILE_ERROR = "sp_profile_error";

export const SPSCHEDULELIST = "spschedulelist";
export const SPSCHEDULELIST_SUCCESS = "spschedulelist_success";
export const SPSCHEDULELIST_ERROR = "spschedulelist_error";

export const SPSCHEDULE = "spschedule";
export const SPSCHEDULE_SUCCESS = "spschedule_success";
export const SPSCHEDULE_ERROR = "spschedule_error";

export const SPSCHEDULEDETAILS = "spscheduledetails";
export const SPSCHEDULEDETAILS_SUCCESS = "spscheduledetails_success";
export const SPSCHEDULEDETAILS_ERROR = "spscheduledetails_error";

export const SPCONFIRMBOOKINGS = "sp_confirm_bookings";
export const SPCONFIRMBOOKINGS_SUCCESS = "sp_confirm_bookings_success";
export const SPCONFIRMBOOKINGS_ERROR = "sp_confirm_bookings_error";

export const EXTERNALBOOKING = "external_booking";
export const EXTERNALBOOKING_SUCCESS = "external_booking_success";
export const EXTERNALBOOKING_ERROR = "external_booking_error";

export const BOOKINGDETAILS = "booking_details";
export const BOOKINGDETAILS_SUCCESS = "booking_details_success";
export const BOOKINGDETAILS_ERROR = "booking_details_error";
export const BOOKINGDETAILS_CLEAR = "booking_details_clear";

export const BROADCASTBOOKINGDETAILS = "broadcast_details";
export const BROADCASTBOOKINGDETAILS_SUCCESS = "broadcast_details_success";
export const BROADCASTBOOKINGDETAILS_ERROR = "broadcast_details_error";

export const SHOWBROADCASTSCREEN = "show_broadcast_screen";

export const MYBOOKINGS = "bookings";
export const MYBOOKINGS_SUCCESS = "bookings_success";
export const MYBOOKINGS_ERROR = "bookings_error";

export const MYBOOKINGHISTORY = "booking_history";
export const MYBOOKINGHISTORY_SUCCESS = "booking_history_success";
export const MYBOOKINGHISTORY_ERROR = "booking_history_error";

export const PROCESSING_BOOKING = "processing_booking";
export const PROCESSING_BOOKING_SUCCESS = "processing_booking_success";
export const PROCESSING_BOOKING_ERROR = "procesing_booking_error";

export const BROADCAST_LIST = "broadcast_list";
export const BROADCAST_LIST_SUCCESS = "broadcast_list_success";
export const BROADCAST_LIST_ERROR = "broadcast_list_error";

export const CLIENT_BLOCKING_DATA = "client_blocking_data";
export const CLIENT_BLOCKING_DATA_SUCCESS = "client_blocking_data_success";
export const CLIENT_BLOCKING_DATA_ERROR = "client_blocking_data_error";

export const COMPLETED_BOOKING = "completed_booking";
export const COMPLETED_BOOKING_SUCCESS = "completed_booking_success";
export const COMPLETED_BOOKING_ERROR = "completed_booking_error";

export const CANCELED_BOOKING = "canceled_booking";
export const CANCELED_BOOKING_SUCCESS = "canceled_booking_success";
export const CANCELED_BOOKING_ERROR = "canceled_booking_error";

export const QUOTATION_LIST = "quotation_list";
export const QUOTATION_LIST_SUCCESS = "quotation_list_success";
export const QUOTATION_LIST_ERROR = "quotation_list_error";

export const QUOTATION_DETAILS = "quotation_deatils";
export const QUOTATION_DETAILS_SUCCESS = "quotation_deatils_success";
export const QUOTATION_DETAILS_ERROR = "quotation_deatils_error";

export const INVOICE_DETAILS = "invoice_deatils";
export const INVOICE_DETAILS_SUCCESS = "invoice_deatils_success";
export const INVOICE_DETAILS_ERROR = "invoice_deatils_error";

export const UPDATE_ROUTE = "update_route";
export const CLEAR_ROUTE = "clear_route";

export const BOOKING_FLOW_DATA = "booking_flow_data";
export const INVOICE_MEDIA_DATA = "invoice_media_data";
export const QUOTE_MEDIA_DATA = "quote_media_data";

export const CLEAR_BOOKING_FLOW_DATA = "clear_booking_flow_data";
export const CLEAR_INVOICE_MEDIA_DATA = "clear_invoice_media_data";
export const CLEAR_QUOTE_MEDIA_DATA = "clear_quote_media_data";

export const BROADCAST_BOOKING_LIST_DATA = "broadcast_booking_list_data";
export const CLIENT_BLOCKING_LIST_DATA = "client_blocking_list_data";


export const LOGOUT_RESET = "logout_reset";
export const LOGIN_TRIGGER_ACTION = "login_trigger_action";
export const SOCIETY_AVAILABLE_TRIGGER_ACTION = "society_available_trigger_action";
export const SOCIETY_NOT_AVAILABLE_TRIGGER_ACTION = "society_not_available_trigger_action";

export const SERVICE_SPECIFIC_FLOW = "service_specific_flow";
export const SERVICE_SPECIFIC_FLOW_SUCCESS = "service_specific_flow_success";
export const SERVICE_SPECIFIC_FLOW_ERROR = "service_specific_flow_error";

export const QUESTION_TREE = "question_tree";
export const QUESTION_TREE_SUCCESS = "question_tree_success";
export const QUESTION_TREE_ERROR = "question_tree_error";

export const SVC_DISCOUNTS = "svc_discounts";
export const SVC_DISCOUNTS_SUCCESS = "svc_discounts_success";
export const SVC_DISCOUNTS_ERROR = "svc_discounts_error";

export const PRODUCT_CATEGORY = "product_category";
export const PRODUCT_CATEGORY_SUCCESS = "product_category_success";
export const PRODUCT_CATEGORY_ERROR = "product_category_error";

export const PRODUCT_SUB_CATEGORY = "product_sub_category";
export const PRODUCT_SUB_CATEGORY_SUCCESS = "product_sub_category_success";
export const PRODUCT_SUB_CATEGORY_ERROR = "product_sub_category_error";

export const PRODUCT_TYPES = "product_types";
export const PRODUCT_TYPES_SUCCESS = "product_types_success";
export const PRODUCT_TYPES_ERROR = "product_types_error";

export const PRODUCT_LIST = "product_list";
export const PRODUCT_LIST_MORE = "product_list_more";
export const PRODUCT_LIST_SUCCESS = "product_list_success";
export const PRODUCT_LIST_ERROR = "product_list_error";

export const PRODUCT_CART_ADD = "product_cart_add";
export const PRODUCT_CART_MINUS = "product_cart_minus";
export const PRODUCT_CART_CLEAR = "product_cart_clear";
export const PRODUCT_CART_SYNC = "product_cart_sync";
export const PRODUCT_CART_SYNC_SUCCESS = "product_cart_sync_success";
export const PRODUCT_CART_SYNC_FAILURE = "product_cart_sync_failure";
export const PRODUCT_CART_SAVE = "product_cart_save";
export const PRODUCT_CART_SAVE_SUCCESS = "product_cart_save_success";
export const PRODUCT_CART_SAVE_FAILURE = "product_cart_save_failure";
export const PRODUCT_CART_REFRESH = "product_cart_refresh";
export const PRODUCT_CART_REFRESH_SUCCESS = "product_cart_refresh_success";
export const PRODUCT_CART_REFRESH_FAILURE = "product_cart_refresh_failure";
export const PRODUCT_CART_ONLINE_REDIRECT = "product_cart_online_redirect";
export const PRODUCT_CART_CHECKOUT = "product_checkout_save";
export const PRODUCT_CART_CHECKOUT_SUCCESS = "product_checkout_save_success";
export const PRODUCT_CART_CHECKOUT_FAILURE = "product_checkout_save_failure";
export const PRODUCT_CART_TIP_AMOUNT = "product_checkout_tip_amount";
export const PRODUCT_CART_USE_REWARDS = "product_checkout_use_rewards";

export const PRODUCT_DETAILS = "product_details";
export const PRODUCT_DETAILS_SUCCESS = "product_details_success";
export const PRODUCT_DETAILS_ERROR = "product_details_error";
export const PRODUCT_DETAILS_SELECT_SKU = "product_details_select_sku";

export const CLIENT_ADDRESSES = "cl_addresses";
export const CLIENT_ADDRESSES_SUCCESS = "cl_addresses_success";
export const CLIENT_ADDRESSES_ERROR = "cl_addresses_error";
export const CLIENT_ADDRESSES_SHIPPING_SELECT = "cl_addresses_shiping_select";
export const CLIENT_ADDRESSES_CLEAR_CACHE = "cl_addresses_clear_cache";
export const PRODUCT_DELIVERY_DATE_SELECT = "product_delivery_date";

export const EMPTY_ACTION = "empty_action";

export const PRODUCT_COMMUNITY = "product_community";

export const UPDATE_PRODUCT_SCROLL = "update_product_scroll";
export const CLEAR_PRODUCT_SCROLL = "clear_product_scroll";

export const HOME_SCREEN_DATA = "home_screen_data";
export const HOME_SCREEN_DATA_SUCCESS = "home_screen_data_success";
export const HOME_SCREEN_DATA_FAILURE = "home_screen_data_failure";

export const SOCIETY_MSG_BOARD_LIST = "society_msg_board_list";
export const SOCIETY_MSG_BOARD_LIST_SCROLL = "society_msg_board_list_scroll";
export const SOCIETY_MSG_BOARD_LIST_MORE = "society_msg_board_list_more";
export const SOCIETY_MSG_BOARD_LIST_SUCCESS = "society_msg_board_list_success";
export const SOCIETY_MSG_BOARD_LIST_ERROR = "society_msg_board_list_error";
export const CLEAR_SOCIETY_MSG_BOARD_LIST = "clear_society_msg_board_list";

export const SOCIETY_GATE_APPROVAL_LIST = "society_gate_approval_list";
export const SOCIETY_GATE_APPROVAL_LIST_SUCCESS = "society_gate_approval_list_success";
export const SOCIETY_GATE_APPROVAL_LIST_ERROR = "society_gate_approval_list_error";
export const SOCIETY_GATE_APPROVAL_LIST_IGNORE_ENTRY = "society_gate_approval_list_ignore_entry";
export const SOCIETY_GATE_APPROVAL_LIST_REMOVE_ENTRY = "society_gate_approval_list_remove_entry";
export const HIDE_SOCIETY_GATE_APPROVAL = "hide_society_gate_approval";
export const SHOW_SOCIETY_GATE_APPROVAL = "show_society_gate_approval";

export const USER_HOME_SCREEN_DATA = "user_home_screen_data";
export const USER_HOME_SCREEN_DATA_SUCCESS = "user_home_screen_data_success";
export const USER_HOME_SCREEN_DATA_FAILURE = "user_home_screen_data_failure";

export const SOCIETY_COMPLAINTS_LIST = "society_complaints_list";
export const SOCIETY_COMPLAINTS_LIST_SCROLL = "society_complaints_list_scroll";
export const SOCIETY_COMPLAINTS_LIST_MORE = "society_complaints_list_more";
export const SOCIETY_COMPLAINTS_LIST_SUCCESS = "society_complaints_list_success";
export const SOCIETY_COMPLAINTS_LIST_ERROR = "society_complaints_list_error";
export const CLEAR_SOCIETY_COMPLAINTS_LIST = "clear_society_complaints_list";

export const SOCIETY_COMPLAINTS_DETAILS = "society_complaints_details";
export const SOCIETY_COMPLAINTS_DETAILS_SUCCESS = "society_complaints_details_success";
export const SOCIETY_COMPLAINTS_DETAILS_ERROR = "society_complaints_details_error";

export const SOCIETY_MESSAGE_DETAILS = "society_message_details";
export const SOCIETY_MESSAGE_DETAILS_SUCCESS = "society_message_details_success";
export const SOCIETY_MESSAGE_DETAILS_ERROR = "society_message_details_error";
export const SOCIETY_MESSAGE_NEW_COMMENT = "society_message_new_comment";

export const SOCIETY_DEPARTMENTS = "society_departments";
export const SOCIETY_DEPARTMENTS_SUCCESS = "society_departments_success";
export const SOCIETY_DEPARTMENTS_ERROR = "society_departments_error";

export const SOCIETY_EMEETING_LIST = "society_emeeting_list";
export const SOCIETY_EMEETING_LIST_SUCCESS = "society_emeeting_list_success";
export const SOCIETY_EMEETING_LIST_ERROR = "society_emeeting_list_error";
export const SOCIETY_EMEETING_LIST_IGNORE_MEETING = "society_emeeting_list_ignore_meeting";
export const SOCIETY_EMEETING_LIST_REMOVE_MEETING = "society_emeeting_list_remove_meeting";
export const HIDE_SOCIETY_EMEETING_REQUEST = "hide_society_emeeting_request";
export const SHOW_SOCIETY_EMEETING_REQUEST = "show_society_emeeting_request";

export const HIDE_MISCELLANEOUS_COMPONENTS = "hide_miscellaneous_components";
export const SHOW_MISCELLANEOUS_COMPONENTS = "show_miscellaneous_components";
export const UPDATE_EMAIL_HIGHLIGHT = "update_email_highlight";
export const UPDATE_MISCELLANEOUS_COMPONENTS = "UPDATE_MISCELLANEOUS_COMPONENTS";

export const SOCIETY_POLL_LIST = "society_poll_list";
export const SOCIETY_POLL_LIST_SCROLL = "society_poll_list_scroll";
export const SOCIETY_POLL_LIST_MORE = "society_poll_list_more";
export const SOCIETY_POLL_LIST_SUCCESS = "society_poll_list_success";
export const SOCIETY_POLL_LIST_ERROR = "society_poll_list_error";
export const CLEAR_SOCIETY_POLL_LIST = "clear_society_poll_list";

export const SOCIETY_TRANSACTIONS = "society_transactions";
export const SOCIETY_TRANSACTIONS_MORE = "society_transactions_more";
export const SOCIETY_TRANSACTIONS_SUCCESS = "society_transactions_success";
export const SOCIETY_TRANSACTIONS_ERROR = "society_transactions_error";
export const SOCIETY_TRANSACTIONS_SCROLL = "society_transactions_scroll";
export const SOCIETY_TRANSACTIONS_CLEAR_CACHE = "society_transactions_clear_cache";

export const PETTYCASH_TRANSACTIONS = "pettycash_transactions";
export const PETTYCASH_TRANSACTIONS_MORE = "pettycash_transactions_more";
export const PETTYCASH_TRANSACTIONS_SUCCESS = "pettycash_transactions_success";
export const PETTYCASH_TRANSACTIONS_ERROR = "pettycash_transactions_error";
export const PETTYCASH_TRANSACTIONS_SCROLL = "pettycash_transactions_scroll";
export const PETTYCASH_TRANSACTIONS_CLEAR_CACHE = "pettycash_transactions_clear_cache";

export const PROPERTY_TRANSACTIONS = "property_transactions";
export const PROPERTY_TRANSACTIONS_MORE = "property_transactions_more";
export const PROPERTY_TRANSACTIONS_SUCCESS = "property_transactions_success";
export const PROPERTY_TRANSACTIONS_ERROR = "property_transactions_error";
export const PROPERTY_TRANSACTIONS_SCROLL = "property_transactions_scroll";
export const PROPERTY_TRANSACTIONS_CLEAR_CACHE = "property_transactions_clear_cache";

export const TRANSACTION_DETAILS = "transaction_details";
export const TRANSACTION_DETAILS_SUCCESS = "transaction_details_success";
export const TRANSACTION_DETAILS_ERROR = "transaction_details_error";
export const TRANSACTION_DETAILS_CLEAR_CACHE = "transaction_details_clear_cache";

export const SOCIETY_BILLS = "society_bills";
export const SOCIETY_BILLS_MORE = "society_bills_more";
export const SOCIETY_BILLS_SUCCESS = "society_bills_success";
export const SOCIETY_BILLS_ERROR = "society_bills_error";
export const SOCIETY_BILLS_SCROLL = "society_bills_scroll";
export const SOCIETY_BILLS_CLEAR_CACHE = "society_bills_clear_cache";

export const SOCIETY_INVOICES = "society_invoices";
export const SOCIETY_INVOICES_MORE = "society_invoices_more";
export const SOCIETY_INVOICES_SUCCESS = "society_invoices_success";
export const SOCIETY_INVOICES_ERROR = "society_invoices_error";
export const SOCIETY_INVOICES_SCROLL = "society_invoices_scroll";
export const SOCIETY_INVOICES_CLEAR_CACHE = "society_invoices_clear_cache";
export const SOCIETY_INVOICES_SELECTION = "society_invoices_selection";

export const SOCIETY_SETTLEMENTS = "society_settlements";
export const SOCIETY_SETTLEMENTS_MORE = "society_settlements_more";
export const SOCIETY_SETTLEMENTS_SUCCESS = "society_settlements_success";
export const SOCIETY_SETTLEMENTS_ERROR = "society_settlements_error";
export const SOCIETY_SETTLEMENTS_SCROLL = "society_settlements_scroll";
export const SOCIETY_SETTLEMENTS_CLEAR_CACHE = "society_settlements_clear_cache";

export const SETTLEMENT_DETAILS = "settlement_details";
export const SETTLEMENT_DETAILS_SUCCESS = "settlement_details_success";
export const SETTLEMENT_DETAILS_ERROR = "settlement_details_error";
export const SETTLEMENT_DETAILS_CLEAR_CACHE = "settlement_details_clear_cache";

export const GEONAMES_STATE_LIST = "geonames_state_list";
export const GEONAMES_STATE_LIST_SUCCESS = "geonames_state_list_success";
export const GEONAMES_STATE_LIST_ERROR = "geonames_state_list_error";
export const GEONAMES_STATE_LIST_CLEAR_CACHE = "geonames_state_list_clear_cache";

export const GEONAMES_CITY_LIST = "geonames_city_list";
export const GEONAMES_CITY_LIST_SUCCESS = "geonames_city_list_success";
export const GEONAMES_CITY_LIST_ERROR = "geonames_city_list_error";
export const GEONAMES_CITY_LIST_CLEAR_CACHE = "geonames_city_list_clear_cache";

export const GEONAMES_OTHER_CITY_DATA = "geonames_other_city_data";
export const GEONAMES_OTHER_CITY_DATA_SUCCESS = "geonames_other_city_data_success";
export const GEONAMES_OTHER_CITY_DATA_ERROR = "geonames_other_city_data_error";
export const GEONAMES_OTHER_CITY_DATA_UDPATE_CACHE = "geonames_other_city_data_update_cache";
export const GEONAMES_OTHER_CITY_DATA_CLEAR_CACHE = "geonames_other_city_data_clear_cache";

export const GET_RESTAURANT_DATA = "GET_RESTAURANT_DATA";
export const GET_RESTAURANT_DATA_SUCCESS = "GET_RESTAURANT_DATA_SUCCESS";
export const GET_RESTAURANT_DATA_ERROR = "GET_RESTAURANT_DATA_ERROR";
export const FILTER_RESTAURANT_DATA = "FILTER_RESTAURANT_DATA";

export const UPDATE_CHECKOUT_OPTIONS = "UPDATE_CHECKOUT_OPTIONS";

export const ADD_CART = "ADD_CART";
export const REMOVE_CART = "REMOVE_CART";
export const CLEAR_CART = "CLEAR_CART";
export const UPDATE_COUPON_CODE = "UPDATE_COUPON_CODE";
export const CLEAR_COUPON_CODE = "CLEAR_COUPON_CODE";

export const MENU_ITEM_CUSTOMIZATION = "MENU_ITEM_CUSTOMIZATION";

export const GET_DELIVERY_DATA = "GET_DELIVERY_DATA";
export const GET_DELIVERY_DATA_SUCCESS = "GET_DELIVERY_DATA_SUCCESS";
export const GET_DELIVERY_DATA_ERROR = "GET_DELIVERY_DATA_ERROR";

export const GET_DISCOUNT_CODE_LIST = "GET_DISCOUNT_CODE_LIST";
export const GET_DISCOUNT_CODE_LIST_SUCCESS = "GET_DISCOUNT_CODE_LIST_SUCCESS";
export const GET_DISCOUNT_CODE_LIST_ERROR = "GET_DISCOUNT_CODE_LIST_ERROR";
export const CLEAR_DISCOUNT_CODE_LIST_CACHE = "CLEAR_DISCOUNT_CODE_LIST_CACHE";

export const GET_OGFOOD_DATA_LIST = "GET_OGFOOD_DATA_LIST";
export const GET_OGFOOD_DATA_LIST_MORE = "GET_OGFOOD_DATA_LIST_MORE";
export const GET_OGFOOD_DATA_LIST_SUCCESS = "GET_OGFOOD_DATA_LIST_SUCCESS";
export const GET_OGFOOD_DATA_LIST_ERROR = "GET_OGFOOD_DATA_LIST_ERROR";

export const GET_OGFOOD_ORDER_LIST = "GET_OGFOOD_ORDER_LIST";
export const GET_OGFOOD_ORDER_LIST_MORE = "GET_OGFOOD_ORDER_LIST_MORE";
export const GET_OGFOOD_ORDER_LIST_SUCCESS = "GET_OGFOOD_ORDER_LIST_SUCCESS";
export const GET_OGFOOD_ORDER_LIST_ERROR = "GET_OGFOOD_ORDER_LIST_ERROR";
export const CLEAR_OGFOOD_ORDER_LIST_CACHE = "CLEAR_OGFOOD_ORDER_LIST_CACHE";

export const GET_OGFOOD_ORDER_DETAILS = "GET_OGFOOD_ORDER_DETAILS";
export const GET_OGFOOD_ORDER_DETAILS_SUCCESS = "GET_OGFOOD_ORDER_DETAILS_SUCCESS";
export const GET_OGFOOD_ORDER_DETAILS_ERROR = "GET_OGFOOD_ORDER_DETAILS_ERROR";
export const CLEAR_OGFOOD_ORDER_DETAILS_CACHE = "CLEAR_OGFOOD_ORDER_DETAILS_CACHE";

export const GET_NEARBY_SERVICES = "GET_NEARBY_SERVICES";
export const GET_NEARBY_SERVICES_MORE = "GET_NEARBY_SERVICES_MORE";
export const GET_NEARBY_SERVICES_SUCCESS = "GET_NEARBY_SERVICES_SUCCESS";
export const GET_NEARBY_SERVICES_ERROR = "GET_NEARBY_SERVICES_ERROR";

export const USER_ADDRESSES = "USER_ADDRESSES";
export const USER_ADDRESSES_SUCCESS = "USER_ADDRESSES_SUCCESS";
export const USER_ADDRESSES_ERROR = "USER_ADDRESSES_ERROR";
export const USER_ADDRESSES_SELECT = "USER_ADDRESSES_SELECT";
export const ADD_NEW_USER_ADDRESS = "ADD_NEW_USER_ADDRESS";
export const EDIT_USER_ADDRESS = "EDIT_USER_ADDRESS";
export const REMOVE_USER_ADDRESS = "REMOVE_USER_ADDRESS";
export const USER_ADDRESSES_CLEAR_CACHE = "USER_ADDRESSES_CLEAR_CACHE";

export const JSK_DETAILS = "jsk_details";
export const JSK_DETAILS_SUCCESS = "jsk_details_success";
export const JSK_DETAILS_ERROR = "jsk_details_error";

export const EMERGENCY_CONTACTS = "emergency_contacts";
export const EMERGENCY_CONTACTS_SUCCESS = "emergency_contacts_success";
export const EMERGENCY_CONTACTS_ERROR = "emergency_contacts_error";

export const GETJSKSPLIST = "getJSKSPList";
export const GETJSKSPLIST_SUCCESS = "getJSKSPList_success";
export const GETJSKSPLIST_ERROR = "getJSKSPList_error";

export const CUSTOM_BANNERS = "CUSTOM_BANNERS";
export const CUSTOM_BANNERS_SUCCESS = "CUSTOM_BANNERS_SUCCESS";
export const CUSTOM_BANNERS_ERROR = "CUSTOM_BANNERS_ERROR";

export const USER_HOME_SCREEN_SET_BOARD_FEED_BADGE = "USER_HOME_SCREEN_SET_BOARD_FEED_BADGE";
export const USER_HOME_SCREEN_SET_SOCIETY_FEED_BADGE = "USER_HOME_SCREEN_SET_SOCIETY_FEED_BADGE";