import { REHYDRATE } from 'redux-persist';
import {
    SOCIETY_MSG_BOARD_LIST, SOCIETY_MSG_BOARD_LIST_MORE, SOCIETY_MSG_BOARD_LIST_SUCCESS, SOCIETY_MSG_BOARD_LIST_ERROR,
    CLEAR_SOCIETY_MSG_BOARD_LIST, LOGOUT_RESET, SOCIETY_MSG_BOARD_LIST_SCROLL, SOCIETY_MESSAGE_NEW_COMMENT
} from '../../actions/types';
import ApiError, { handleNetworkError } from '../../utils/api-error';
import { parseMsgBoardApiData } from '../../scenes/client/scenes/society/scenes/message-board/config';

const INITIAL_STATE = {
    message_list: [],
    cache: null,
    last_message_id: undefined,
    can_load_more: false,
    scroll_offset: 0,
    error: undefined,
    loading: false,
    is_logout: false
};

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case REHYDRATE: if (action.payload && action.payload.society_msg_board_list) return { ...action.payload.society_msg_board_list }; else return INITIAL_STATE;
        case SOCIETY_MSG_BOARD_LIST: return { ...INITIAL_STATE, loading: true, is_logout: false };
        case SOCIETY_MSG_BOARD_LIST_MORE: return { ...state, loading: true, is_logout: false };
        case SOCIETY_MSG_BOARD_LIST_SUCCESS:
            const apiData = action.payload && action.payload.apiData;
            const cache = action.payload && action.payload.cache;
            const batchSize = action.payload && action.payload.batchSize;

            if (apiData !== undefined && apiData !== null) {
                let message_list = ApiError(apiData.statusCode, apiData.message, apiData.gsRespData, "message_list", []);
                if (message_list && message_list.constructor === Array) {
                    message_list = parseMsgBoardApiData(message_list);
                    const state_list = state.message_list && state.message_list.constructor === Array ? state.message_list : [];
                    const req_list = [...state_list, ...message_list];

                    let last_id = undefined;
                    if (req_list.length > 0) {
                        last_id = req_list[req_list.length - 1].message_id;
                    }

                    let loadMore = false;
                    if (message_list.length === batchSize) {
                        loadMore = true;
                    }

                    return { ...state, loading: false, is_logout: false, message_list: req_list, cache, last_message_id: last_id, can_load_more: loadMore }

                } else {
                    return { ...state, loading: false, is_logout: false, error: message_list, can_load_more: false };
                }

            } else {
                return { ...state, loading: false, is_logout: false };
            }
        case SOCIETY_MSG_BOARD_LIST_ERROR:
            let hNE_res = handleNetworkError(action);
            if (hNE_res.error)
                return { ...INITIAL_STATE, ...hNE_res, loading: false, is_logout: false };
            else if (action.payload && action.payload.response && action.payload.response.data && action.payload.response.data.error) {
                if (action.payload.response.data.error.statusCode === 401)
                    return { ...INITIAL_STATE, error: action.payload.response.data.error, loading: false, is_logout: true };
                else
                    return { ...INITIAL_STATE, error: action.payload.response.data.error, loading: false, is_logout: false };
            }
            else
                return { ...INITIAL_STATE, error: action.payload, loading: false, is_logout: false };

        case SOCIETY_MSG_BOARD_LIST_SCROLL:
            return { ...state, scroll_offset: action.payload.scroll_offset };

        case SOCIETY_MESSAGE_NEW_COMMENT:
            let message_list = [...state.message_list];
            message_list = message_list.map(message => {
                if (message.message_id == action.payload.message_id) {
                    message.no_of_comments = action.payload.no_of_comments;
                }
                return message;
            });
            return { ...state, message_list };

        case CLEAR_SOCIETY_MSG_BOARD_LIST:
        case LOGOUT_RESET:
            return { ...INITIAL_STATE };
        default: return state;
    }
}