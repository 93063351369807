import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Code } from 'react-content-loader';

import ApiErrorRetry from 'components/api-error-retry';
import ProductCategory from '../../../products/components/product-category';
import NoProductCategories from '../../../products/components/no-product-categories';

import { getProductCategories, getProductSubCategories, clearProductCart, syncCart, saveCart, clearClientAddressCache, updateProductScroll, clearProductScroll } from 'actions';
import { product_cart_updated, cl_user_id, product_clear_data, cl_selected_address } from 'config/storage-variables';
import { CART_SAVE_TIME_LIMIT } from 'config/config';
import { getReducerState } from 'utils/get-reducer-state';
import { createSaveCartData } from '../../../products/config';
import { isNullOrEmptyObject, isNullOrEmptyArray } from 'utils/helper-functions';
import { smoothScroll } from 'utils/override-window-functions';
import { productListingRoute, productsRoute } from 'routes-parameters/config';
import styles from '../../../../../food/restaurant-list/styles';
import { getCityIdFromAddress, getCommunityIdFromAddress } from '../../../products/config';
import { getAddress } from '../../../products/config/product-address-config';

class ProductList extends PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            city_name: '',
            city_id: null,
            initial_category_open_index: null,
        }

        this.category_div_ref = [];
    }

    componentDidMount() {
        if (this.props.product_scroll_details && this.props.product_scroll_details[productsRoute]) {
            let scroll_details = this.props.product_scroll_details[productsRoute];
            window.scrollTo(0, (this.category_div_ref[scroll_details.selected_category_index] ? this.category_div_ref[scroll_details.selected_category_index].offsetTop : null) || scroll_details.scroll_offset || 0);
            this.setState({ initial_category_open_index: scroll_details.selected_category_index });
            this.scroll_offset = scroll_details.scroll_offset;
            this.selected_category_index = scroll_details.selected_category_index;
        }
        else {
            window.scrollTo(0, 25);
        }

        if (localStorage.getItem(product_clear_data) === "true") {
            this.props.clearProductCart(false);
            localStorage.removeItem(product_clear_data);
        }

        const address = this.props.user_selected_address;
        if (address) {
            this.initCity(address);
        }

        this.startSaveTimer();

        if (this.city_id && (isNullOrEmptyObject(this.props.cart_items)
            || this.props.owner_id !== localStorage.getItem(cl_user_id)
            || this.props.cart_city_id !== this.city_id)) {
            const community_id = getCommunityIdFromAddress(address);
            const city_id = getCityIdFromAddress(address);

            this.props.syncCart(false, city_id, community_id);
            this.props.clearClientAddressCache();
        }
    }

    getLoader = () => {
        return (
            <div style={{ paddingLeft: '30px' }}>
                <div style={{ marginTop: '100px' }}><Code /></div>
                <div style={{ marginTop: '50px' }}><Code /></div>
                <div style={{ marginTop: '50px' }}><Code /></div>
            </div>
        );
    }

    initCity = (address) => {
        this.city_id = getCityIdFromAddress(address)
        const address_obj = getAddress(address)
        const city_name = address_obj && address_obj.city ? address_obj.city : ""
        this.setState({
            city_name: city_name,
            city_id: this.city_id
        })
        this.fetchProductData(this.city_id)
    }

    fetchProductData = city_id => {
        this.fetchProductCategories(city_id)
        this.fetchProductSubCategories(city_id)
    }

    fetchProductCategories = city_id => {
        const cache = this.props.product_category_cache;
        if (!cache || cache.city_id != city_id) {
            this.props.getProductCategories(city_id);
        }
    }

    fetchProductSubCategories = city_id => {
        const cache = this.props.product_sub_category_cache;
        if (!cache || cache.city_id != city_id) {
            this.props.getProductSubCategories(city_id);
        }
    }

    handleAddressChange = address => {
        this.initCity(address);
        this.props.clearProductCart();
        this.props.clearProductScroll("all");

        // Resetting scroll details on city change
        this.resetScrollDetails();
    }

    resetScrollDetails() {
        this.setState({ initial_category_open_index: null });
        this.scroll_offset = null;
        this.selected_category_index = null;
    }

    onCategoryClick = (isOpen, index) => {    
        if (!isOpen) {
            return;
        }
        
        // Scroll to top of clicked category div
        if (this.category_div_ref[index]) {
            // this.category_div_ref[index].scrollIntoView({ behaviour: 'smooth', block: 'start', inline: 'nearest' });
            this.scroll_offset = this.category_div_ref[index].offsetTop;

            setTimeout(() => {
                smoothScroll(this.scroll_offset, 100);
            }, 300);
        }
        this.selected_category_index = index;
    };

    onSubCategoryRedirection = (index) => {
        if (this.category_div_ref[index]) {
            this.scroll_offset = this.category_div_ref[index].offsetTop;
        }
        this.selected_category_index = index;
    };

    startSaveTimer = () => {
        this.timerId = this.saveTimer();
    }

    saveTimer = () => setTimeout(() => {
        if (localStorage.getItem(product_cart_updated) === "true") {
            this.props.saveCart(createSaveCartData(getReducerState()));
            localStorage.removeItem(product_cart_updated);
        }
        this.startSaveTimer();
    }, CART_SAVE_TIME_LIMIT);

    renderProducts() {
        if (this.props.loading) {
            return (
                <div>
                    {this.getLoader()}
                </div>
            )
        }

        const num_of_categories = this.props.product_categories ? this.props.product_categories.length : 0;

        return (
            <div>
                <div style={{ backgroundColor: this.props.catLoader || !!this.props.product_category_error || isNullOrEmptyArray(this.props.product_categories) ? "white" : "#eee" }}>
                    {this.props.catLoader && this.getLoader()}
                    {!this.props.catLoader && this.props.product_category_error && <ApiErrorRetry onClick={() => this.fetchProductData(this.city_id)} style={{ marginTop: '120px' }} />}
                    {
                        !this.props.catLoader && 
                        !this.props.product_category_error && 
                        isNullOrEmptyArray(this.props.product_categories) && 
                        !Array.isArray(this.props.product_categories) &&                        
                        <div style={{ paddingTop: '95px' }}>
                            <NoProductCategories />
                        </div>
                    }
                    {
                        !this.props.catLoader && this.props.product_categories && Array.isArray(this.props.product_categories) && this.state.city_id &&
                        <>
                            {
                                this.props.product_categories
                                    .map((category, index) =>
                                        <ProductCategory
                                            key={category.pcat_id}
                                            {...category}
                                            subCategories={this.props.product_sub_categories && this.props.product_sub_categories[category.pcat_id]}
                                            city_name={this.state.city_name}
                                            history={this.props.history}
                                            onCategoryClick={(isOpen) => this.onCategoryClick(isOpen, index)}
                                            onSubCategoryRedirection={() => this.onSubCategoryRedirection(index)}
                                            getRef={el => { this.category_div_ref[index] = el }}
                                            open={this.state.initial_category_open_index === index}                                            
                                        />
                                    )
                            }
                            <div ref={el => { this.category_div_ref[num_of_categories] = el }} />
                        </>
                    }
                </div>
            </div>
        )
    }

    render() {
        return (
            <div style={styles.container}>
                {this.renderProducts()}
            </div>
        );
    }

    componentDidUpdate(prevProps) {
        if (
            (prevProps.user_selected_address !== this.props.user_selected_address && this.props.user_selected_address)
        ) {
            if (localStorage.getItem(cl_selected_address)) {
                this.handleAddressChange(JSON.parse(localStorage.getItem(cl_selected_address)));
            }
        }
    }

    componentWillUnmount() {
        if (this.timerId) {
            clearTimeout(this.timerId);
        }

        if (this.props.history.action === "PUSH" && this.props.history.location && this.props.history.location.pathname.includes(`/${productListingRoute}`)) {
            this.props.updateProductScroll(productsRoute, {
                selected_category_index: this.selected_category_index,
                scroll_offset: this.scroll_offset
            });
        } else {
            this.props.clearProductScroll(productsRoute);
        }
    }
}

function mapStateToProps({ product_category, product_sub_category, product_cart, product_scroll_details, user_addresses }) {
    const user_selected_address = user_addresses.selected_address;

    return {
        catLoader: product_category.loading || product_cart.loading,
        product_categories: product_category.product_categories,
        product_category_cache: product_category.cache,
        product_sub_categories: product_sub_category.product_sub_categories,
        product_sub_category_cache: product_sub_category.cache,
        product_category_error: product_category.error,
        cart_items: product_cart.cart_items,
        owner_id: product_cart.owner_id,
        cart_city_id: product_cart.city_id,
        product_scroll_details: product_scroll_details,
        user_selected_address
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        getProductCategories,
        getProductSubCategories,
        clearProductCart,
        saveCart,
        syncCart,
        clearClientAddressCache,
        updateProductScroll,
        clearProductScroll,
    }, dispatch);
}

ProductList.displayName = 'ProductList';
export default connect(mapStateToProps, mapDispatchToProps)(ProductList);