import { REHYDRATE } from 'redux-persist';
import { PRODUCT_DETAILS, PRODUCT_DETAILS_SUCCESS, PRODUCT_DETAILS_ERROR, PRODUCT_DETAILS_SELECT_SKU } from '../../actions/types';
import ApiError, { handleNetworkError } from '../../utils/api-error';
import { productDetailsApiAdapter } from 'scenes/client/scenes/products/config';

const INITIAL_STATE = {
    product_details: null,
    selected_sku: null,
    cache: null,
    error: undefined,
    loading: false,
    is_logout: false
};

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case REHYDRATE: if (action.payload && action.payload.product_details) return { ...action.payload.product_details }; else return INITIAL_STATE;
        case PRODUCT_DETAILS: return { ...INITIAL_STATE, loading: true, is_logout: false };
        case PRODUCT_DETAILS_SUCCESS:
            const apiData = action.payload && action.payload.apiData;
            const cache = action.payload && action.payload.cache;
            if (apiData !== undefined && apiData !== null) {
                const product_detail = ApiError(apiData.statusCode, apiData.message, apiData.gsRespData, "product_detail", {});
                if (product_detail) {
                    const details = productDetailsApiAdapter(product_detail)

                    return { ...state, loading: false, is_logout: false, product_details: details, cache: cache }

                } else {
                    return { ...state, loading: false, is_logout: false, error: product_detail };
                }

            } else {
                return { ...state, loading: false, is_logout: false };
            }
        case PRODUCT_DETAILS_ERROR:
            let hNE_res = handleNetworkError(action);
            if (hNE_res.error)
                return { ...INITIAL_STATE, ...hNE_res, loading: false, is_logout: false };
            else if (action.payload && action.payload.response && action.payload.response.data && action.payload.response.data.error) {
                if (action.payload.response.data.error.statusCode === 401)
                    return { ...INITIAL_STATE, error: action.payload.response.data.error, loading: false, is_logout: true };
                else
                    return { ...INITIAL_STATE, error: action.payload.response.data.error, loading: false, is_logout: false };
            }
            else
                return { ...INITIAL_STATE, error: action.payload, loading: false, is_logout: false };

        case PRODUCT_DETAILS_SELECT_SKU: return { ...state, selected_sku: action.payload }

        default: return state;
    }
}