import axios from 'middleware/axios';
import { SSE_API_LINK, GRS_ENV_CLIENT, GRS_ENV_SP } from 'config/config';
import { sp_access_token, sp_user_id, cl_access_token, cl_user_id, pushUriId, device_id, cl_selected_society_property, cl_call_prop_api, last_fetch_all_props_notification_count } from 'config/storage-variables';
import { CLEAR_SOCIETY_MSG_BOARD_LIST, SOCIETY_GATE_APPROVAL_LIST_REMOVE_ENTRY } from '../actions/types';
import { getUserHomeScreenDataWithoutDispatch } from 'actions/home-action';
import store from '../reducer-store';
import { type as SOCIETY_NOTIFICATION_TYPES, notification_type as NOTIFICATION_TYPES } from '../scenes/client/scenes/society/scenes/notifications/config';

const isLocalhost = Boolean(
    window.location.hostname === 'localhost' ||
    // [::1] is the IPv6 localhost address.
    window.location.hostname === '[::1]' ||
    // 127.0.0.1/8 is considered localhost for IPv4.
    window.location.hostname.match(
        /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
    )
);

export function sendSSERequestToServer() {
    if (isLocalhost)
        return null;

    if (localStorage.getItem(pushUriId) && process.env.REACT_APP_GRS_ENV !== GRS_ENV_CLIENT && process.env.REACT_APP_GRS_ENV !== GRS_ENV_SP) {
        return null;
    }

    let access_token = null, user_id = null;
    if (localStorage.getItem(sp_access_token) && localStorage.getItem(sp_user_id)) {
        access_token = localStorage.getItem(sp_access_token);
        user_id = localStorage.getItem(sp_user_id);
    }
    else if (localStorage.getItem(cl_access_token) && localStorage.getItem(cl_user_id)) {
        access_token = localStorage.getItem(cl_access_token);
        user_id = localStorage.getItem(cl_user_id);
    }

    if (!access_token)
        return null;

    if (!localStorage.getItem(device_id))
        return null;

    // access_token = "N1T0Rcq9tjncZCSzH0UI38hDL56kOh62SFW8LUK1L2pG2WD6lxcblqjG2zmPPMm1";

    const url = `${SSE_API_LINK}/sse?aT=${access_token}&user_id=${user_id}&device_id=${localStorage.getItem(device_id)}`;
    connectSSE(url);
}

function connectSSE(url) {
    var es = new EventSource(url);
    es.onmessage = function (event) {
        // //console.log(event.data);
        sendNotificationEvent(event.data);
    };
}

function callHomeScreenApis(community_id, prop_id) {
    getUserHomeScreenDataWithoutDispatch(community_id, prop_id);
}

function sendNotificationEvent(payload) {
    let data = {}, booking_id = '', community_id = '';
    console.log("on message payload : ", payload);

    if (payload && payload.constructor === String) {
        data = JSON.parse(payload);
    }
    else if (payload && payload.data)
        data = payload.data;

    if (data && data.booking_id)
        booking_id = data.booking_id;
    if (data && data.community_id)
        community_id = data.community_id;

    if (data && data.notification_type === "AdminMsg") {
        var adminEvent = document.createEvent('Event');
        // Define that the event name is 'build'.
        adminEvent.initEvent('adminEvent', true, true);
        adminEvent.n_type = data.type;
        adminEvent.acnt_status = data.acnt_status;
        document.dispatchEvent(adminEvent);
    }
    else if (community_id) {
        var type;
        if (data.type) {
            type = data.type;
        }

        var event = document.createEvent('Event');
        event.initEvent('newSocietyNotification', true, true);
        event.community_id = community_id;
        if (type) {
            event.notification_subtype = type.toUpperCase();
        }
        document.dispatchEvent(event);
        sessionStorage.removeItem(last_fetch_all_props_notification_count);

        var prop_id;
        if (data.prop_id) {
            prop_id = data.prop_id;
        }

        if (data.notification_type === NOTIFICATION_TYPES.property) {
            var propEvent = document.createEvent('Event');
            propEvent.initEvent('propEvent', true, true);
            if (data.vlog_id) {
                propEvent.vlog_id = data.vlog_id;
            }
            if (prop_id) {
                propEvent.prop_id = prop_id;
            }
            propEvent.community_id = community_id;
            if (type) {
                propEvent.notification_subtype = type.toUpperCase();
            }
            if (type.toUpperCase() === SOCIETY_NOTIFICATION_TYPES.PROPERTY_EINTERCOM) {
                propEvent.meeting_id = data.meeting_id;
            }
            document.dispatchEvent(propEvent);
        }
        else if (data.notification_type === NOTIFICATION_TYPES.society) {
            var societyEvent = document.createEvent('Event');
            societyEvent.initEvent('societyEvent', true, true);
            if (prop_id) {
                societyEvent.prop_id = prop_id;
            }
            societyEvent.community_id = community_id;
            if (type) {
                societyEvent.notification_subtype = type.toUpperCase();
            }

            if (type.toUpperCase() === SOCIETY_NOTIFICATION_TYPES.SOCIETY_MSG_BOARD) {
                store.dispatch({ type: CLEAR_SOCIETY_MSG_BOARD_LIST });   // Using as direct function from calling file. Hence dispatching directly to store.
            }
            else if (type.toUpperCase() === SOCIETY_NOTIFICATION_TYPES.SOCIETY_COMPLAINT_ACTION) {
                societyEvent.complaint_id = data.complaint_id;
            }
            else if (type.toUpperCase() === SOCIETY_NOTIFICATION_TYPES.SOCIETY_MSG_BOARD_COMMENT) {
                societyEvent.msgb_id = data.msgb_id;
            }
            else if (type.toUpperCase() === SOCIETY_NOTIFICATION_TYPES.SOCIETY_GENERAL_EMEETING) {
                societyEvent.meeting_id = data.meeting_id;
            }
            else if (type.toUpperCase() === SOCIETY_NOTIFICATION_TYPES.SOCIETY_EINTERCOM_UPDATE) {
                societyEvent.meeting_id = data.meeting_id;
                societyEvent.eintercom_event_type = data.eintercom_event_type;
            }
            else if (type.toUpperCase() === SOCIETY_NOTIFICATION_TYPES.SOCIETY_NEW_POLL) {
                societyEvent.poll_id = data.poll_id;
            }

            document.dispatchEvent(societyEvent);
        }
        else if (data.notification_type === NOTIFICATION_TYPES.boardMember) {
            var boardEvent = document.createEvent('Event');
            boardEvent.initEvent('boardEvent', true, true);
            if (prop_id) {
                boardEvent.prop_id = prop_id;
            }
            if (data.txn_id) {
                boardEvent.txn_id = data.txn_id;
            }
            if (type.toUpperCase() === SOCIETY_NOTIFICATION_TYPES.SOCIETY_NEW_COMPLAINT) {
                boardEvent.complaint_id = data.complaint_id;
            }
            if (type.toUpperCase() === SOCIETY_NOTIFICATION_TYPES.SOCIETY_NEW_POLL) {
                boardEvent.poll_id = data.poll_id;
            }
            else if (type.toUpperCase() === SOCIETY_NOTIFICATION_TYPES.SOCIETY_BOARD_EMEETING) {
                boardEvent.meeting_id = data.meeting_id;
            }
            boardEvent.community_id = community_id;
            document.dispatchEvent(boardEvent);
        }
        else if (data.notification_type === NOTIFICATION_TYPES.property_payment) {
            var propPaymentEvent = document.createEvent('Event');
            propPaymentEvent.initEvent('propPaymentEvent', true, true);
            if (data.grs_txn_id) {
                propPaymentEvent.grs_txn_id = data.grs_txn_id;
            }
            if (data.txn_status) {
                propPaymentEvent.txn_status = data.txn_status;
            }
            if (data.txn_amount) {
                propPaymentEvent.txn_amount = data.txn_amount;
            }
            if (prop_id) {
                propPaymentEvent.prop_id = prop_id;
            }
            propPaymentEvent.community_id = community_id;
            document.dispatchEvent(propPaymentEvent);
        }
        else if (data.notification_type === NOTIFICATION_TYPES.gatekeeper) {
            if (type.toUpperCase() === SOCIETY_NOTIFICATION_TYPES.PROPERTY_UNEXPECTED_VISITOR_VERIFICATION || type.toUpperCase() === SOCIETY_NOTIFICATION_TYPES.PROPERTY_CHILD_EXIT_REQUEST_VERIFICATION) {
                store.dispatch({ type: SOCIETY_GATE_APPROVAL_LIST_REMOVE_ENTRY, payload: { vlog_id: data.vlog_id } });   // Using as direct function from calling file. Hence dispatching directly to store.
            }
        }

        if ([SOCIETY_NOTIFICATION_TYPES.SOCIETY_MSG_BOARD].includes(type.toUpperCase())) {
            let selected_prop_id = localStorage.getItem(cl_selected_society_property);
            if (prop_id && community_id && selected_prop_id && prop_id === selected_prop_id) {
                callHomeScreenApis(community_id, prop_id);
            }
        }
        else if (type.toUpperCase() === SOCIETY_NOTIFICATION_TYPES.PROPERTY_RESIDENT_VERIFICATION) {
            sessionStorage.removeItem(cl_call_prop_api);
        }
    }
    else if (booking_id) {
        var event = document.createEvent('Event');
        // Define that the event name is 'build'.
        event.initEvent('newNotification', true, true);
        event.booking_id = booking_id;
        document.dispatchEvent(event);

        // //console.log("on message payload : ", data);

        if (data.notification_type === "ChatMsg" && booking_id === sessionStorage.getItem('current_chat_id')) {
            var chatEvent = document.createEvent('Event');
            chatEvent.initEvent('chatEvent', true, true);
            chatEvent.booking_id = booking_id;
            document.dispatchEvent(chatEvent);
        }
        else if ((data.notification_type === "SPReviewMsg" || data.notification_type === "CLReviewMsg") && booking_id === sessionStorage.getItem('current_review_id')) {
            var reviewEvent = document.createEvent('Event');
            reviewEvent.initEvent('reviewEvent', true, true);
            reviewEvent.booking_id = booking_id;
            document.dispatchEvent(reviewEvent);
        }
        else if ((data.notification_type === "BookingMsg" || data.notification_type === "QuoteMsg" || data.notification_type === "InvoiceMsg") && booking_id === sessionStorage.getItem('current_booking_id')) {
            var bookingEvent = document.createEvent('Event');
            bookingEvent.initEvent('bookingEvent', true, true);
            bookingEvent.booking_id = booking_id;
            document.dispatchEvent(bookingEvent);
        }
        else if (data.notification_type === "BroadcastMsg") {
            var broadcastEvent = document.createEvent('Event');
            broadcastEvent.initEvent('broadcastEvent', true, true);
            broadcastEvent.booking_id = booking_id;
            document.dispatchEvent(broadcastEvent);
        }
    }
}

export function sendSSERemoveRequestToServer() {
    return new Promise((resolve, reject) => {
        if (isLocalhost)
            return resolve({ message: "Local host detected" });

        if (localStorage.getItem(pushUriId) && process.env.REACT_APP_GRS_ENV !== GRS_ENV_CLIENT && process.env.REACT_APP_GRS_ENV !== GRS_ENV_SP) {
            return resolve({ message: "Push uri found" });
        }

        let access_token = null, user_id = null;
        if (localStorage.getItem(sp_access_token) && localStorage.getItem(sp_user_id)) {
            access_token = localStorage.getItem(sp_access_token);
            user_id = localStorage.getItem(sp_user_id);
        }
        else if (localStorage.getItem(cl_access_token) && localStorage.getItem(cl_user_id)) {
            access_token = localStorage.getItem(cl_access_token);
            user_id = localStorage.getItem(cl_user_id);
        }

        if (!access_token)
            return reject({ message: "Access token not found" });

        if (!localStorage.getItem(device_id))
            return reject({ message: "Device id not found" });

        // access_token = "N1T0Rcq9tjncZCSzH0UI38hDL56kOh62SFW8LUK1L2pG2WD6lxcblqjG2zmPPMm1";

        const url = `${SSE_API_LINK}/sseRemove?aT=${access_token}&user_id=${user_id}&device_id=${localStorage.getItem(device_id)}`;

        axios.get(url)
            .then(res => resolve({ message: "Successfully removed SSE token" }))
            .catch(err => reject({ message: "Error while removing SSE token" }));
    });
}

/*
if (!!window.EventSource) {
  var source = new EventSource('/path/to/sse/')

  source.addEventListener('message', function(e) {
    //console.log(e.data)
  }, false)

  source.addEventListener('open', function(e) {
    //console.log("Connection was opened")
  }, false)

  source.addEventListener('error', function(e) {
    if (e.readyState == EventSource.CLOSED) {
      //console.log("Connection was closed")
    }
  }, false)
}
*/
