import React from 'react';
import ReactDOM from 'react-dom';

import SocialMediaPlugin from './components/social-media-plugin';

import './styles.css';

class RightPanel extends React.Component {
    render() {
        return ReactDOM.createPortal(
            <SocialMediaPlugin {...this.props} />,
            document.querySelector('#right-panel')
        )
    }
}

export default RightPanel;