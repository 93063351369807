import { REHYDRATE } from 'redux-persist';
import { GETSPLIST, GETSPLIST_SUCCESS, GETSPLIST_ERROR, LOGOUT_RESET } from '../../actions/types';
import ApiError, { handleNetworkError } from '../../utils/api-error';

const INITIAL_STATE = {
  sp_list: {},
  error: undefined,
  loading: true,
  is_logout: false
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case REHYDRATE: if (action.payload && action.payload.SPList) return { ...action.payload.SPList }; else return INITIAL_STATE;
    case GETSPLIST: return { ...INITIAL_STATE, loading: true, is_logout: false };
    case GETSPLIST_SUCCESS:
      if (action.payload !== undefined && action.payload !== null) {
        let data = action.payload;
        let resp = {};
        resp.disp_unit = data.gsRespData.disp_unit ? data.gsRespData.disp_unit : '';
        resp.sp_list = ApiError(data.statusCode, data.message, data.gsRespData, "sp_list", []);
        return { ...INITIAL_STATE, sp_list: resp, loading: false, is_logout: false };
      }
      else
        return { ...state, loading: false, is_logout: false };
    case GETSPLIST_ERROR:
      let hNE_res = handleNetworkError(action);
      if (hNE_res.error)
        return { ...INITIAL_STATE, ...hNE_res, loading: false, is_logout: false };
      else if (action.payload && action.payload.response && action.payload.response.data && action.payload.response.data.error) {
        if (action.payload.response.data.error.statusCode === 401)
          return { ...INITIAL_STATE, error: action.payload.response.data.error, loading: false, is_logout: true };
        else
          return { ...INITIAL_STATE, error: action.payload.response.data.error, loading: false, is_logout: false };
      }
      else
        return { ...INITIAL_STATE, error: action.payload, loading: false, is_logout: false };
    case LOGOUT_RESET: return { ...INITIAL_STATE };
    default: return state;
  }
}