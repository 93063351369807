import axios from 'middleware/axios';
import { API_LINK, CLIENT_MEDIA_UPLOAD, GCS_BUCKET, CLOUD_FILE_TYPE } from 'config/config';
import { sp_user_id, sp_access_token, cl_user_id, cl_access_token } from 'config/storage-variables';
import gcsFileName from 'utils/gcs-fileName';
import uploadToCloud from 'utils/google_cloud_upload';

export function getUserDetails(cb) {
    let sp_id = localStorage.getItem(sp_user_id);
    let access_token = localStorage.getItem(sp_access_token);

    // axios.get(`${API_LINK}/gs_Users/${sp_id}?filter[fields][reg_info]=true&filter[fields][sp_sch_id]=true&filter[fields][acnt_status]=true&access_token=${access_token}`)
    axios.post(`${API_LINK}/gs_Users/${sp_id}/gs_getUserStatus?access_token=${access_token}`)
        .then((res) => {
            if (res.data.statusCode.toString().slice(0, 2) == 20) {
                if (res.data.gsRespData) {
                    cb(res.data.gsRespData, null);
                }
                else
                    cb(null, res.data);
            }
            else {
                if (res.data.gsRespData) {
                    cb(null, res.data.gsRespData);
                }
                else
                    cb(null, res.data);
            }
        })
        .catch((err) => {
            if (err.response && err.response.data && err.response.data.error) {
                if (err.response.data.error.statusCode === 401) {
                    cb(null, { ...err.response.data.error, is_logout: true });
                }
                else
                    cb(null, err.response.data.error);
            }
            else
                cb(null, err);
        })
}

export function setUserDetails(user_details, cb) {
    let sp_id = localStorage.getItem(sp_user_id);
    let access_token = localStorage.getItem(sp_access_token);

    axios.post(`${API_LINK}/gs_Users/${sp_id}/gs_setUserDetails?access_token=${access_token}`, { userDetails: user_details })
        .then((res) => {
            if (res.status.toString().slice(0, 2) == 20) {
                cb(res.data, null);
            }
            else {
                cb(null, res.data);
            }
        })
        .catch((err) => {
            if (err.response && err.response.data && err.response.data.error) {
                if (err.response.data.error.statusCode === 401) {
                    cb(null, { ...err.response.data.error, is_logout: true });
                }
                else
                    cb(null, err.response.data.error);
            }
            else {
                cb(null, err);
            }
        })
}
export function setProfilePic(file_type, file, cb) {
    let sp_id = localStorage.getItem(sp_user_id);
    let cl_id = localStorage.getItem(cl_user_id);
    let access_token, id;
    if (sp_id) {
        access_token = localStorage.getItem(sp_access_token);
        id = sp_id;
    }
    if (cl_id) {
        access_token = localStorage.getItem(cl_access_token);
        id = cl_id;
    }

    if (CLIENT_MEDIA_UPLOAD) {
        var fileExtension = file.type.split('/')[1];
        fileExtension = fileExtension.split('+')[0];
        fileExtension = '.' + fileExtension;

        // This API just saves link on bucket and returns url, not saves on database.
        // So call either google bucket upload or server api call.
        uploadToCloud(GCS_BUCKET, file, gcsFileName('in', id, CLOUD_FILE_TYPE[file_type].caption, fileExtension))
            .then(res => {
                let link = res.response.gsRespData.link;

                cb({ location: link }, null);
            })
            .catch(err => {
                //console.log("picture upload error : ", err);
                // console.log("error in uploading");
            });

    }
    else {
        const data = new FormData();
        data.append('file', file);

        axios.post(`${API_LINK}/gs_Users/${id}/gs_setProfilePic?access_token=${access_token}&file_type=${file_type}`, data)
            .then((res) => {
                if (res.data.statusCode.toString().slice(0, 2) == 20) {
                    cb(res.data.gsRespData, null);
                }
                else {
                    cb(null, res.gsRespData);
                }
            })
            .catch((err) => {
                if (err.response && err.response.data && err.response.data.error) {
                    if (err.response.data.error.statusCode === 401) {
                        cb(null, { ...err.response.data.error, is_logout: true });
                    }
                    else
                        cb(null, err.response.data.error);
                }
                else {
                    cb(null, err);
                }
            });
    }
}

export function completeRegistration(cb) {
    let sp_id = localStorage.getItem(sp_user_id);
    let access_token = localStorage.getItem(sp_access_token);

    axios.post(`${API_LINK}/gs_Users/${sp_id}/gs_UserRegistration?access_token=${access_token}`)
        .then((res) => {
            if (res.data.statusCode.toString().slice(0, 2) == 20) {
                cb(res.data, null);
            }
            else {
                cb(null, res.data);
            }
        })
        .catch((err) => {
            if (err.response && err.response.data && err.response.data.error) {
                if (err.response.data.error.statusCode === 401) {
                    cb(null, { ...err.response.data.error, is_logout: true });
                }
                else
                    cb(null, err.response.data.error);
            }
            else {
                cb(null, err);
            }
        })
}

export function setCorrectedDetails(corrected_data, cb) {
    let sp_id = localStorage.getItem(sp_user_id);
    let access_token = localStorage.getItem(sp_access_token);

    axios.post(`${API_LINK}/gs_Users/${sp_id}/gs_setCorrectedDetails?access_token=${access_token}`, { correctedData: corrected_data })
        .then((res) => {
            if (res.data.statusCode.toString().slice(0, 2) == 20) {
                cb(res.data, null);
            }
            else {
                cb(null, res.data);
            }
        })
        .catch((err) => {
            if (err.response && err.response.data && err.response.data.error) {
                if (err.response.data.error.statusCode === 401) {
                    cb(null, { ...err.response.data.error, is_logout: true });
                }
                else
                    cb(null, err.response.data.error);
            }
            else {
                cb(null, err);
            }
        })
}


export function getVisitingChargeDetails(data, cb) {
    let access_token;
    if (localStorage.getItem(sp_user_id)) {
        access_token = localStorage.getItem(sp_access_token);
    }
    if (localStorage.getItem(cl_user_id)) {
        access_token = localStorage.getItem(cl_access_token);
    }
    axios.get(`${API_LINK}/gs_Users/gs_getVisitingChargeLimits?access_token=${access_token}&query_obj=${data}`)
        .then((res) => {
            if (res.data.statusCode.toString().slice(0, 2) == 20) {
                cb(res.data, null);
            }
            else {
                cb(null, res.data);
            }
        })
        .catch((err) => {
            if (err.response && err.response.data && err.response.data.error) {
                if (err.response.data.error.statusCode === 401) {
                    cb(null, { ...err.response.data.error, is_logout: true });
                }
                else
                    cb(null, err.response.data.error);
            }
            else {
                cb(null, err);
            }
        })
}