import { REHYDRATE } from 'redux-persist';
import {
    ADD_CART,
    REMOVE_CART,
    CLEAR_CART,
    UPDATE_COUPON_CODE,
    CLEAR_COUPON_CODE
} from '../../../actions/types';
import { compareNumberArray, calculateCouponDiscount } from '../../../utils/helper-functions';

const INITIAL_STATE = {
    addedIds: [],
    quantityById: {},
    total_items: 0,
    item_total: 0,
    item_tax: 0,
    total_amount: 0,
    tax_items: [],
    coupon_code: "",
    opunit_id: undefined,
    user_id: undefined,
    page_url: undefined
};


const addItem = (state, action) => {
    let addedIds = [...state.addedIds];
    let quantityById = { ...state.quantityById };
    let total_items = state.total_items;
    let item_total = state.item_total;
    let total_amount = state.total_amount;
    const { product_id, cat_id, price, price_id, customization, applicable_tax_items, opunit_id, page_url, user_id } = action.payload;

    if (product_id) {
        if (!addedIds.includes(product_id)) {
            addedIds.push(product_id);
        }

        let quantityObj = quantityById[product_id] || {}, index = null;
        quantityObj.cat_id = cat_id;
        quantityObj.count = (quantityObj.count || 0) + 1;
        if (!quantityObj.customizations) {
            quantityObj.customizations = [];
        }
        for (let i = 0; i < quantityObj.customizations.length; i++) {
            const obj = quantityObj.customizations[i];
            if (!customization && !obj.customization_object) {
                index = i;
                break;
            }
            else if (customization && obj.customization_object) {
                if (customization.size === obj.customization_object.size && compareNumberArray(customization.add_ons, obj.customization_object.add_ons)) {
                    index = i;
                    break;
                }
            }
        }
        if (index !== null) {
            let obj = { ...quantityObj.customizations[index] };
            obj.quantity = obj.quantity + 1;
            quantityObj.customizations[index] = obj;
            item_total = item_total + obj.price;
        }
        else {
            let obj = { quantity: 1, price, price_id: price_id };
            if (customization) {
                obj.customization_object = customization;
            }
            quantityObj.customizations.push(obj);
            item_total = item_total + obj.price;
        }

        total_items = total_items + 1;
        quantityById[product_id] = quantityObj;
    }

    total_amount = item_total;

    let tax_items = [];
    let item_tax = 0;
    if (applicable_tax_items && applicable_tax_items.length > 0) {
        applicable_tax_items.forEach((applicable_tax_item) => {
            if (applicable_tax_item.tax_type === "abs") {
                tax_items.push({ title: applicable_tax_item.title, tax: applicable_tax_item.value });
                item_tax += applicable_tax_item.value;
            } else {
                let tax = (item_total * applicable_tax_item.value / 100);
                tax = Math.round(tax * 100) / 100;
                tax_items.push({ title: applicable_tax_item.title, tax: tax });
                item_tax += tax;
            }
        });
    }

    total_amount += item_tax;
    item_total = Math.round(item_total * 100) / 100;
    const coupon_discount = state.coupon_code_obj ? calculateCouponDiscount(state.coupon_code_obj, quantityById, item_total) : 0;
    total_amount = total_amount - coupon_discount;
    total_amount = Math.round(total_amount * 100) / 100;

    return { ...state, addedIds, quantityById, total_items, item_total, total_amount, tax_items, coupon_discount, item_tax, opunit_id, page_url, user_id };
}

const removeItem = (state, action) => {
    let addedIds = [...state.addedIds];
    let quantityById = { ...state.quantityById };
    let total_items = state.total_items;
    let item_total = state.item_total;
    let total_amount = state.total_amount;
    const { product_id, applicable_tax_items } = action.payload;

    if (product_id) {
        if (addedIds.includes(product_id)) {
            let quantityObj = quantityById[product_id] || {};
            const customizations = quantityObj.customizations || [];
            const new_count = quantityObj.count - 1;
            if (new_count === 0) {
                addedIds = addedIds.filter(id => id !== product_id);
                const last_obj = customizations.length > 0 ? customizations[[customizations.length - 1]] : undefined;
                item_total = item_total - (last_obj ? last_obj.price : 0);
                delete quantityById[product_id];

            } else {
                quantityObj.count = new_count;
                const last_obj = customizations.length > 0 ? customizations[[customizations.length - 1]] : undefined;
                if (last_obj && last_obj.quantity > 1) {
                    quantityObj.customizations[quantityObj.customizations.length - 1].quantity = last_obj.quantity - 1;
                    item_total = item_total - last_obj.price;

                } else {
                    item_total = item_total - (last_obj ? last_obj.price : 0);
                    if (customizations.length > 0) {
                        quantityObj.customizations.pop();
                        if (quantityObj.customizations.length === 0) {
                            delete quantityObj.customizations;
                        }
                    }
                }
                quantityById[product_id] = quantityObj;
            }
            total_items = total_items - 1;
        }
    }

    total_amount = item_total;

    let tax_items = [];
    let item_tax = 0;
    if (applicable_tax_items && applicable_tax_items.length > 0) {
        applicable_tax_items.forEach((applicable_tax_item) => {
            if (applicable_tax_item.tax_type === "abs") {
                tax_items.push({ title: applicable_tax_item.title, tax: applicable_tax_item.value });
                item_tax += applicable_tax_item.value;
            } else {
                let tax = (item_total * applicable_tax_item.value / 100);
                tax = Math.round(tax * 100) / 100;
                tax_items.push({ title: applicable_tax_item.title, tax: tax });
                item_tax += tax;
            }
        });
    }

    total_amount += item_tax;
    item_total = Math.round(item_total * 100) / 100;
    const coupon_discount = state.coupon_code_obj ? calculateCouponDiscount(state.coupon_code_obj, quantityById, item_total) : 0;
    total_amount = total_amount - coupon_discount;
    total_amount = Math.round(total_amount * 100) / 100;

    return { ...state, addedIds, quantityById, total_items, item_total, total_amount, tax_items, coupon_discount, item_tax, opunit_id: addedIds.length > 0 ? state.opunit_id : undefined };
}

const FoodCart = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case REHYDRATE:
            return action.payload && action.payload.food_cart
                ? { ...action.payload.food_cart }
                : INITIAL_STATE;

        case ADD_CART:
            return addItem(state, action);

        case REMOVE_CART:
            return removeItem(state, action);

        case UPDATE_COUPON_CODE:
            const coupon_discount = calculateCouponDiscount(action.payload.coupon_code_obj, state.quantityById, state.item_total);
            let total_amount = state.total_amount + (state.coupon_discount || 0) - coupon_discount;
            total_amount = Math.round(total_amount * 100) / 100;
            return { ...state, ...action.payload, coupon_discount: coupon_discount, total_amount };

        case CLEAR_COUPON_CODE:
            let new_total_amount = state.total_amount + (state.coupon_discount || 0);
            new_total_amount = Math.round(new_total_amount * 100) / 100;
            return { ...state, coupon_code: "", coupon_discount: undefined, coupon_code_obj: undefined, manually_added_coupon_code: undefined, total_amount: new_total_amount };

        case CLEAR_CART:
            return INITIAL_STATE;

        default:
            return state;
    }
};

export default FoodCart;