import uuidv1 from 'uuid/v1';

export function generateUniqueId() {
    return uuidv1();
}

export default function gcsFileName(base_path = 'in', id, file_padding = '', type = '') {
    //id can be user id, booking id or empty...

    let filename = '';

    if (base_path)
        filename += base_path + '/';

    // Append filename to form folder structure
    if (id)
        filename += id + '/';

    // Append file_padding before unique id generated
    if (file_padding)
        filename += file_padding + '_';

    // Generate unique filename
    filename += uuidv1();

    // Append file type at back
    if (type)
        filename += type;

    return filename;
}