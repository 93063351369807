import gs_color from 'config/colors.css';

export const styles = {
    FullTextField: {
        border: `1px solid #E0E0E0`,
        borderRadius: '5px',
        height: '36px',
        padding: '0px 8px'
    },
    FullTextFieldError: {
        border: `1px solid #f44336`,
        borderRadius: '5px',
        height: '36px',
        padding: '0px 8px'
    },
    FullTextMultiLineField: {
        border: `1px solid #E0E0E0`,
        borderRadius: '5px',
        padding: '0px 8px'
    },
    FullTextMultiLineFieldError: {
        border: `1px solid #f44336`,
        borderRadius: '5px',
        padding: '0px 8px'
    },
    SelectField: {
        border: `1px solid #E0E0E0`,
        borderRadius: '5px',
        height: '48px',
        padding: '0px 8px',
        fontSize: '14px'
    },
    SelectFieldError: {
        border: `1px solid #f44336`,
        borderRadius: '5px',
        height: '48px',
        padding: '0px 8px',
        fontSize: '14px'
    },
    Chip: {
        margin: '4px',
        border: `1px solid ${gs_color.primaryColor}`,
        cursor: 'pointer',
    },
    ChipWrapper: {
        display: 'flex',
        flexWrap: 'wrap'
    },
    FullTextFieldWidth: {
        width: "100%",
        maxWidth: "256px"
    },
};