import React, { PureComponent } from "react";
import QrCode from 'qrcode';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faSync } from '@fortawesome/free-solid-svg-icons';
import ProfilePic from 'components/profile-pic';
import gs_color from 'config/colors.css';

import Dialog from 'material-ui/Dialog';

class PassCodeDialog extends PureComponent {
    constructor(props) {
        super(props)

        this.state = {
            qrCodeUrl: ""
        }
    }

    componentDidMount() {
        let text = this.props.qrCodeText

        if (text && text.constructor === Number) {
            text = text + '';
        }

        if (text && text.constructor === String) {
            const uppercase = text.toUpperCase();
            QrCode.toDataURL(uppercase)
                .then(url => this.setState({ qrCodeUrl: url }))
                .catch(err => console.log("Dialog Error ", err))
        }
    }

    render() {
        return (
            <Dialog
                open={this.props.show}
                onRequestClose={() => this.props.onCloseDialog()}
                titleClassName="dialogTitleClassName"
                bodyClassName="dialogBodyClassName"
                contentClassName="DialogNewStyle"
            >
                <div style={{ textAlign: 'center' }}>
                    <div style={{ display: 'flex' }}>
                        {
                            this.props.showDetails && this.props.showRegenerate &&
                            <span style={{ marginRight: 'auto' }}>
                                <FontAwesomeIcon className='svg-inline--fa' icon={faSync} color={gs_color.backgroundDarkColor} style={{ fontSize: '16px', cursor: 'pointer' }} onClick={() => this.props.handleRegenerate()} />
                            </span>
                        }
                        {
                            this.props.showDetails && this.props.showRemove &&
                            <span style={{ marginLeft: 'auto' }}>
                                <FontAwesomeIcon className='svg-inline--fa' icon={faTrash} color="red" style={{ fontSize: '16px', cursor: 'pointer' }} onClick={() => this.props.handleRemove()} />
                            </span>
                        }
                    </div>
                    {
                        !this.props.hideProfilePic &&
                        <ProfilePic
                            profile_pic={this.props.profPicLink}
                            display_name={this.props.displayName}
                            size={60}
                            style={{ marginTop: '12px' }}
                            typezoom={true}
                            // typezoom={!this.props.showDetails}
                            history={this.props.history}
                        />
                    }
                    <div style={{ color: gs_color.textColor, fontSize: '17px', fontWeight: 'bold', marginTop: '12px' }}>
                        {this.props.displayName}
                    </div>
                    <img src={this.state.qrCodeUrl} />
                    <div style={{ color: gs_color.accentColor, fontSize: '17px', fontWeight: 'bold' }}>
                        {`#${this.props.permanentCode}`}
                    </div>
                </div>
            </Dialog>
        )
    }
}

export default PassCodeDialog;