import React from 'react';
import NoNetworkIcon from 'scenes/not-found/assets/NotFound.svg';

function NetworkError(props) {
    return (
        <div>
            <div style={{ paddingTop: "150px", textAlign: "center" }}>
                <img width="82px" height="82px" src={NoNetworkIcon} alt="No Network" />
                <div style={{ paddingTop: '20px', fontWeight: '500', fontSize: '17px', visibility: props.hideErrorTitle ? 'hidden' : 'unset' }}>You are offline</div>
                <div style={{ paddingTop: '10px', fontSize: '13px' }}>Turn on your network to proceed.</div>
            </div>
        </div>
    );
}

NetworkError.displayName = 'NetworkError';
export default NetworkError;