import { REHYDRATE } from 'redux-persist';
import { BOOKING_FLOW_DATA, CLEAR_BOOKING_FLOW_DATA, LOGOUT_RESET } from '../../actions/types';

const INITIAL_STATE = {};

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case REHYDRATE: if (action.payload && action.payload.booking_flow_data) return { ...action.payload.booking_flow_data }; else return INITIAL_STATE;
        case BOOKING_FLOW_DATA: return { ...state, ...action.data };
        case LOGOUT_RESET: return { ...INITIAL_STATE };
        case CLEAR_BOOKING_FLOW_DATA: let new_state = { ...state };
            if (action.data) {
                if (action.data === "all") {
                    new_state = INITIAL_STATE;
                }
                else if (action.data.constructor === Array) {
                    action.data.forEach(key => delete new_state[key])
                }
            }
            return new_state;
        default: return state;
    }
}