import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { SocietyLoader } from '../../components/loaders'
import PropertySwitcher from '../../../society/components/property-switcher'
import NotificationBell from '../../../society/components/notification-bell'
import PropertyViewActive from '../../components/property-view-active'
import PropertyViewInActive from '../../components/property-view-inactive'
import EditProperty from '../../../society/scenes/property-details/components/edit-property'
import ErrorComponent from 'components/api-error-retry'
import BottomNav from 'components/bottom-navigation'
import Verticals from '../../../home/scenes/new-home/component/verticals';

import { getUserHomeScreenData, getPollList, getHomeScreenData, category, getAllServices, getUserAddresses, getCustomBannerData } from 'actions/home-action'
import { fetchSocietiesData, fetchNotificationData, selectProperty, clearSelectedProperty } from '../../util'
import { getCommunityPropertyDetails, postRemoveProperty } from '../../../society/scenes/property-details/api'
import { city_list } from 'config/storage-variables';
import { societyNotificationRoute } from 'routes-parameters/config'
import { residentStatus } from '../../../society/config'
import { type } from '../../../society/scenes/notifications/config'
import { logout } from '../../../authentication/api'
import { isDNhoodEnv } from 'utils/helper-functions';
import { setDefaultCountry } from '../../../products/config/city-config'
import { getCityList } from '../../../home/api'
import SectionHeader from '../../components/section-header'
import { showOGCHomeData } from '../../util/society-util'

const selectedIndex = 0
class HSociety extends PureComponent {
    constructor(props) {
        super(props)

        this.state = {
            fullScreenLoader: 0,
            notificationLoader: false,
            status: "",
            activeProperties: [],
            notification_count_data: 0,
            allPropNotificationCount: 0,
            allPropNotificationData: [],
            showEditProperty: false,
            city_list: sessionStorage.getItem(city_list) ? JSON.parse(sessionStorage.getItem(city_list)) : null,
        }

        this.newSocietyNotificationEventHandler = this.newSocietyNotificationEventHandler.bind(this);
        this.propEventHandler = this.propEventHandler.bind(this);
    }

    handleHashChange = () => {
        if (window.location.hash === "#editProperty") {
            this.setState({ showEditProperty: true });

        } else if (window.location.hash !== "#selectProperty") {
            this.setState({ showEditProperty: false });

        }
    }

    componentDidMount() {
        window.scrollTo(0, 0)

        if (!this.state.city_list)
            this.getCityList();

        // Specific for selectProperty because it hash inside has, on refresh causing issue.
        if (window.location.hash === "#selectProperty") {
            this.props.history.go(-2);
        }
        else if (window.location.hash !== "") {
            this.props.history.goBack();
        }

        window.addEventListener('hashchange', this.handleHashChange, false)

        this.city_id = null;
        this.loadSocietiesData();

        if (this.props.user_addresses_loading) {
            this.props.getUserAddresses();
        }

        document.addEventListener("newSocietyNotification", this.newSocietyNotificationEventHandler, false);
        document.addEventListener("propEvent", this.propEventHandler, false);
    }

    getCityList() {
        getCityList((res, err) => {
            if (res !== null) {
                const resCities = res.city_list;
                const countrySelected = isDNhoodEnv() ? "us" : res.default_country && typeof res.default_country === 'string' && res.default_country.toLowerCase();
                setDefaultCountry(countrySelected);
                if (resCities && resCities.constructor === Array && resCities.length > 0) {
                    sessionStorage.setItem(city_list, JSON.stringify(resCities));
                    this.setState({ city_list: resCities });
                }
            }
        });
    }

    propEventHandler(event) {
        if (event && event.notification_subtype === type.PROPERTY_RESIDENT_VERIFICATION) {
            this.loadSocietiesData(true)
        }
    }

    newSocietyNotificationEventHandler(event) {
        if (event && event.notification_subtype !== type.PROPERTY_RESIDENT_VERIFICATION) {
            this.loadSocietiesData()
        }
    }

    loadSocietiesData = (forceFetch = false) => {
        this.incrementFullScreenLoader()
        fetchSocietiesData(forceFetch)
            .then(data => {
                if (data.noSocietiesFound) {
                    this.props.setDisplaySociety(false)
                }
                else {
                    // Avoiding memory leak - updating state of unmounted component.

                    if (data.status === residentStatus.REJECTED) {
                        this.fetchPropertyDetails(data.societyId, data.selectedPropId, data.boardId)
                        this.loadNotificationData(data.selectedPropId)

                    } else if (data.status === residentStatus.ACTIVE) {
                        this.loadNotificationData(data.selectedPropId)
                        this.loadHomeScreenData(data.societyId, data.selectedPropId);
                        this.loadPollList(data.societyId, data.boardId);
                        this.city_id = data.cityId;
                        this.loadHomeScreenOGCData(data.cityId)
                        this.loadCustomBanners(data.selectedPropId)
                    }

                    this.setState({ ...data })
                    this.decrementFullScreenLoader()
                }
            })
            .catch(err => {
                if (err.statusCode === 401) {
                    logout(null, (res) => { });
                    this.props.setDisplaySociety(false);
                }
                if (err && err.message && err.message.constructor === String) {
                    this.props.showErrorDialog(err.message)
                }
                this.setState({ errorObj: err || true })
                this.decrementFullScreenLoader()
            })
    }

    loadNotificationData = propId => {
        this.setState({ notificationLoader: true })
        fetchNotificationData(propId).then(data => this.setState({ notificationLoader: false, ...data }))
    }

    loadHomeScreenData(societyId, propId) {
        if (!this.props.cache || this.props.cache.community_id !== societyId || this.props.cache.prop_id !== propId) {
            this.props.getUserHomeScreenData(societyId, propId);
        }
    }

    loadPollList = (societyId, boardId) => {
        if (!this.props.poll_cache || this.props.poll_cache.community_id !== societyId || this.props.poll_cache.board_id !== boardId) {
            this.props.getPollList({ community_id: societyId, board_id: boardId });
        }
    }

    loadHomeScreenOGCData = city_id => {
        if (city_id) {
            let flag = false;
            if (this.state.city_list && this.state.city_list.length > 0) {
                this.state.city_list.forEach(list => {
                    if (list.id === city_id) {
                        flag = true;
                    }
                });
            }
            if (flag && (!this.props.home_screen_city_id || this.props.home_screen_city_id !== city_id)) {
                this.props.getHomeScreenData(city_id);
                this.props.category(city_id);
                this.props.getAllServices(city_id, 1);
            }
        }
    }

    loadCustomBanners = prop_id => {
        if (!this.props.custom_banners_cache || !this.props.custom_banners_cache.prop_id || this.props.custom_banners_cache.screen_name !== 'home' || this.props.custom_banners_cache.prop_id !== prop_id) {
            this.props.getCustomBannerData({ prop_id, screen_name: 'home' })
        }
    }

    onPropertyChanged = property => {
        const propId = property.prop_id
        const { activeProperties } = this.state
        const selectedProp = selectProperty(activeProperties, propId);
        this.setState({ ...selectedProp });
        this.loadHomeScreenData(selectedProp.societyId, propId);
        this.loadPollList(selectedProp.societyId, selectedProp.boardId);
        this.city_id = selectedProp.cityId;
        this.loadHomeScreenOGCData(selectedProp.cityId)
        this.loadCustomBanners(propId)
    }

    incrementFullScreenLoader = () => {
        const { fullScreenLoader } = this.state
        this.setState({ fullScreenLoader: fullScreenLoader + 1 })
    }

    decrementFullScreenLoader = () => {
        const { fullScreenLoader } = this.state
        this.setState({ fullScreenLoader: fullScreenLoader - 1 })
    }

    fetchPropertyDetails = (societyId, propertyId, boardId) => {
        this.incrementFullScreenLoader()
        getCommunityPropertyDetails(societyId, propertyId, boardId, (res, err) => {
            if (res) {
                this.setState({ propDetails: res.prop_details })
                this.decrementFullScreenLoader()

            } else {
                if (err && err.message && err.message.constructor === String) {
                    this.props.showErrorDialog(err.message)
                }

                this.setState({ errObj: err ? err : true })
                this.decrementFullScreenLoader()
            }
        })
    }

    callRemoveProperty = () => {
        this.incrementFullScreenLoader()
        postRemoveProperty(this.state.societyId, this.state.selectedPropId, (res, err) => {
            if (res) {
                clearSelectedProperty()
                this.decrementFullScreenLoader()
                this.loadSocietiesData(true)

            } else {
                if (err && err.message && err.message.constructor === String) {
                    this.props.showErrorDialog(err.message);
                }
                this.decrementFullScreenLoader()
            }
        })
    }

    render() {
        if (this.state.fullScreenLoader > 0 || (this.state.status === residentStatus.ACTIVE && this.props.loading)) {
            return <SocietyLoader />
        }

        if (this.state.errorObj) {
            return (
                <div style={{ marginTop: '60px' }}>
                    <ErrorComponent error={this.state.errorObj} showIcon={true} {...this.props} onClick={() => this.loadSocietiesData()} />
                </div>
            )
        }

        if (this.state.showEditProperty) {
            return (
                <EditProperty
                    societyId={this.state.societyId}
                    comm_schema_fields={this.state.comm_schema_fields}
                    propDetails={this.state.propDetails}
                    residentType={this.state.residentType}
                    refreshPage={() => this.loadSocietiesData(true)}
                    reSubmit={true}
                    {...this.props}
                />
            )
        }

        const show_OGCHomeData = showOGCHomeData(this.props.home_screen_data);

        return (
            <div>
                <div style={{ display: 'flex', margin: '8px 12px 4px 12px', marginRight: '0px', alignItems: 'center' }}>
                    <PropertySwitcher
                        selectedPropId={this.state.selectedPropId}
                        activeProperties={this.state.activeProperties}
                        onPropertyChanged={this.onPropertyChanged}
                        allPropNotificationCount={this.state.allPropNotificationCount}
                        allPropNotificationData={this.state.allPropNotificationData}
                        {...this.props}
                    />
                    <NotificationBell
                        loading={this.state.notificationLoader}
                        count={this.state.notification_count_data}
                        handleClick={() => this.props.history.push(`/${societyNotificationRoute}`)}
                    />
                </div>
                <div className="nonBottomNavigation" style={{ margin: '0 12px' }}>
                    {
                        this.state.status === residentStatus.ACTIVE ?
                            <>
                                <PropertyViewActive
                                    {...this.state}
                                    {...this.props}
                                />
                                {
                                    show_OGCHomeData &&
                                    <div style={{ padding: '12px 0px' }}>
                                        <SectionHeader title="Neighborhood Quick Connect" />
                                        <div style={{ margin: '8px' }} />
                                        <Verticals
                                            is_home_screen={true}
                                            history={this.props.history}
                                            handleCardClick={(route, event) => { }}
                                        />
                                    </div>
                                }
                            </>
                            : null
                    }
                    {
                        (this.state.status === residentStatus.PENDING || this.state.status === residentStatus.REJECTED) &&
                        <PropertyViewInActive
                            callRemoveProperty={this.callRemoveProperty}
                            {...this.state}
                            {...this.props}
                        />
                    }
                </div>
                <BottomNav
                    selectedIndex={selectedIndex}
                    history={this.props.history}
                />
            </div>
        )
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.is_logout !== this.props.is_logout && this.props.is_logout) {
            logout(null, (res) => { });
            this.props.setDisplaySociety(false);
        }
        if (!prevState.city_list && this.state.city_list && this.city_id) {
            this.loadHomeScreenOGCData(this.city_id);
        }
    }

    componentWillUnmount() {
        window.removeEventListener('hashchange', this.handleHashChange, false)

        document.removeEventListener("newSocietyNotification", this.newSocietyNotificationEventHandler, false);
        document.removeEventListener("propEvent", this.propEventHandler, false);
    }
}

function mapStateToProps({ user_home_screen, society_poll_list, home_screen, user_addresses, custom_banners }) {
    const {
        cache,
        userDetails,
        authCode,
        notice_board,
        community_posts,
        alert_posts,
        loading,
        msgTypeLabels,
        is_logout,
        society_ui_config,
        can_manage_department_acl,
        showBoardFeedBadge,
        showSocietyFeedBadge,
    } = user_home_screen;

    const poll_cache = society_poll_list.cache;
    const poll_loading = society_poll_list.loading;

    const {
        poll_list,
        active_poll_list
    } = society_poll_list;

    const home_screen_city_id = home_screen.city_id;
    const home_screen_data = home_screen.data || [];

    const user_addresses_loading = user_addresses.loading;
    const user_selected_address = user_addresses.selected_address;

    const custom_banners_cache = custom_banners.cache

    return {
        cache,
        userDetails,
        authCode,
        notice_board,
        community_posts,
        alert_posts,
        loading,
        msgTypeLabels,
        is_logout,
        society_ui_config,
        can_manage_department_acl,
        poll_cache,
        poll_loading,
        poll_list,
        active_poll_list,
        home_screen_city_id,
        home_screen_data,
        user_addresses_loading,
        user_selected_address,
        custom_banners_cache,
        showBoardFeedBadge,
        showSocietyFeedBadge,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ getUserHomeScreenData, getPollList, getHomeScreenData, category, getAllServices, getUserAddresses, getCustomBannerData }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(HSociety);