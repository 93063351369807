const OuterDiv = {
    display: "flex",
    height: "100%", 
    flexDirection: "column", 
    alignItems: 'flex-start',
    paddingTop: '8px',
    marginLeft: '24px'
}

export default {
    OuterDiv
}