import { REHYDRATE } from 'redux-persist';
import { PRODUCT_TYPES, PRODUCT_TYPES_SUCCESS, PRODUCT_TYPES_ERROR } from '../../actions/types';
import ApiError, { handleNetworkError } from '../../utils/api-error';

const INITIAL_STATE = {
    product_type_list: [],
    cache: null,
    error: undefined,
    loading: false,
    is_logout: false
};

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case REHYDRATE: if (action.payload && action.payload.product_types) return { ...action.payload.product_types }; else return INITIAL_STATE;
        case PRODUCT_TYPES: return { ...INITIAL_STATE, loading: true, is_logout: false };
        case PRODUCT_TYPES_SUCCESS:
            const apiData = action.payload && action.payload.apiData;
            const cache = action.payload && action.payload.cache;
            if (apiData !== undefined && apiData !== null) {
                const ptype_list = ApiError(apiData.statusCode, apiData.message, apiData.gsRespData, "ptype_list", []);
                if (ptype_list && ptype_list.constructor === Array) {
                    return { ...state, loading: false, is_logout: false, product_type_list: ptype_list, cache: cache }

                } else {
                    return { ...state, loading: false, is_logout: false, error: ptype_list };
                }

            } else {
                return { ...state, loading: false, is_logout: false };
            }
        case PRODUCT_TYPES_ERROR:
            let hNE_res = handleNetworkError(action);
            if (hNE_res.error)
                return { ...INITIAL_STATE, ...hNE_res, loading: false, is_logout: false };
            else if (action.payload && action.payload.response && action.payload.response.data && action.payload.response.data.error) {
                if (action.payload.response.data.error.statusCode === 401)
                    return { ...INITIAL_STATE, error: action.payload.response.data.error, product_type_list: [], loading: false, is_logout: true };
                else
                    return { ...INITIAL_STATE, error: action.payload.response.data.error, product_type_list: [], loading: false, is_logout: false };
            }
            else
                return { ...INITIAL_STATE, error: action.payload, product_type_list: [], loading: false, is_logout: false };
        default: return state;
    }
}