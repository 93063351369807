import { Workbox } from 'workbox-window';


if (Workbox && 'serviceWorker' in navigator && process && process.env && process.env.NODE_ENV === 'production') {
    const wb = new Workbox(`${process.env.PUBLIC_URL}/sw.js`);

    // Add an event listener to detect when the registered
    // service worker has installed but is waiting to activate.
    wb.addEventListener('waiting', (event) => {
        // `event.wasWaitingBeforeRegister` will be false if this is
        // the first time the updated service worker is waiting.
        // When `event.wasWaitingBeforeRegister` is true, a previously
        // updated same service worker is still waiting.
        // You may want to customize the UI prompt accordingly.

        // Assumes your app has some sort of prompt UI element
        // that a user can either accept or reject.

        if (document.getElementById("reload") !== null) {
            var x = document.getElementById("reload");
            var z = document.getElementById("refresh_button");

            x.style.display = "block";
            if (z) {
                z.addEventListener("click", async () => {
                    // Assuming the user accepted the update, set up a listener
                    // that will reload the page as soon as the previously waiting
                    // service worker has taken control.

                    wb.addEventListener('controlling', (event) => {
                        x.style.display = "none";
                        window.location.reload();
                    });

                    // Send a message telling the service worker to skip waiting.
                    // This will trigger the `controlling` event handler above.
                    // Note: for this to work, you have to add a message
                    // listener in your service worker. See below.
                    wb.messageSW({ type: 'SKIP_WAITING' });
                });
            }
        }
    });

    wb.register();
}
