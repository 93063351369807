import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { clearSocietyMsgBoardCache, setBoardFeedBadge, setSocietyFeedBadge } from 'actions'

import Paper from 'material-ui/Paper'
import RaisedButton from 'material-ui/RaisedButton';
import { vaccineAvailabilityRoute } from 'routes-parameters/config'
import QuickActions from '../quick-actions'
import CustomBanners from 'components/custom-banners'
import SectionNoticeBoard from '../sections/section-notice-board'
import SectionSocietyFeed from '../sections/section-society-feed'
import SectionProperty from '../sections/section-property'
import SectionBoardView from '../sections/section-board-view'
import SectionEmergencyView from '../sections/section-emergency-view'
import SectionPolls from '../sections/section-polls'
import SectionNeighborhood from '../sections/section-neighborhood'
import { societyMsgBoardRoute, societyFeedRoute } from 'routes-parameters/config';

import { UI_CONFIG, getUIOverride, UI_DISPLAY_TYPES } from '../../config';
import MessageBoardIcon from '../../../society/scenes/message-board/assets/message.svg';
import SocietyFeedIcon from '../../../society/scenes/message-board/assets/society_feed.svg';
import gs_color from 'config/colors.css'

class PropertyViewActive extends PureComponent {
    redirectToBoardFeed = () => {
        this.props.clearSocietyMsgBoardCache()
        this.props.setBoardFeedBadge(false)
        this.props.history.push(`/${societyMsgBoardRoute}`)
    }

    redirectToSocietyFeed = () => {
        this.props.clearSocietyMsgBoardCache()
        this.props.setSocietyFeedBadge(false)
        this.props.history.push(`/${societyFeedRoute}`)
    }

    renderFeedButton(title, onClick, showBadge, dispIcon) {
        return (
            <div onClick={onClick} style={{ display: 'inline-flex', minHeight: '110px', marginLeft: '8px', marginRight: '8px', width: 'calc(33% + 16px)', maxWidth: '153px' }}>
                <Paper style={{ width: '100%', margin: '8px 0', padding: '0px 8px 0px 8px', borderRadius: '5px', position: 'relative' }}>
                    {
                        showBadge &&
                        <div style={{ position: 'absolute', zIndex: 1, top: 5, right: 15, backgroundColor: 'red', width: 8, height: 8, borderRadius: 25 }}></div>
                    }
                    <div>
                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '2px 0' }}>
                            <img src={dispIcon} style={{ width: '65px', height: '65px' }} />
                            {/* title.includes('Society Feed') ?
                                <img src={SocietyFeedIcon} style={{ width: '65px', height: '65px' }} />
                                :<img src={MessageBoardIcon} style={{ width: '65px', height: '65px' }} />
                            */}
                        </div>
                        <div style={{ textAlign: 'center', paddingTop: '0px', paddingBottom: '0px', fontWeight: '600', color: gs_color.headingColor }}>
                            {title || ""}
                        </div>
                    </div>
                </Paper>
            </div>
        )
    }

    render() {
        let pollList = this.props.active_poll_list || [];
        if (pollList.length > 3) {
            pollList = pollList.slice(0, 3);
        }

        const ui_config = this.props.society_ui_config || {};

        return (
            <div>
                <QuickActions {...this.props} />                
                {
                    //FIXME: Use this space for running ads
                    // disable vaccine popup
                    //this.props.isd_code === "91" &&
                    this.props.isd_code === "0000" &&
                    <div style={{ marginTop: '15px', marginBottom: '10px', textAlign: 'center' }}>
                        <RaisedButton
                            secondary={true}
                            label="Check Vaccine Availability"
                            onClick={() => this.props.history.push(`/${vaccineAvailabilityRoute}`)}
                        />
                    </div>
                }
                {
                    [UI_DISPLAY_TYPES.SHOW, UI_DISPLAY_TYPES.DISABLE].includes(getUIOverride(UI_CONFIG.notice_board, ui_config).display) &&
                    [UI_DISPLAY_TYPES.SHOW, UI_DISPLAY_TYPES.DISABLE].includes(getUIOverride(UI_CONFIG.board_feed, ui_config).display) &&
                    this.props.alert_posts && this.props.alert_posts.length > 0 &&
                    <SectionEmergencyView
                        {...this.props}
                        disable={getUIOverride(UI_CONFIG.notice_board, ui_config).display === UI_DISPLAY_TYPES.DISABLE || getUIOverride(UI_CONFIG.board_feed, ui_config).display === UI_DISPLAY_TYPES.DISABLE}
                    />
                }
                {
                    [UI_DISPLAY_TYPES.SHOW, UI_DISPLAY_TYPES.DISABLE].includes(getUIOverride(UI_CONFIG.poll, ui_config).display) &&
                    (
                        (this.props.isBoardMember && [UI_DISPLAY_TYPES.SHOW, UI_DISPLAY_TYPES.DISABLE].includes(getUIOverride(UI_CONFIG.board_poll, ui_config).display)) ||
                        (!this.props.isBoardMember && [UI_DISPLAY_TYPES.SHOW, UI_DISPLAY_TYPES.DISABLE].includes(getUIOverride(UI_CONFIG.resident_poll, ui_config).display))
                    ) &&
                    pollList.length > 0 &&
                    <SectionPolls
                        {...this.props}
                        pollList={pollList}
                        title={getUIOverride(UI_CONFIG.poll, ui_config).title}
                        disable={
                            getUIOverride(UI_CONFIG.poll, ui_config).display === UI_DISPLAY_TYPES.DISABLE ||
                            (
                                (this.props.isBoardMember && getUIOverride(UI_CONFIG.board_poll, ui_config).display === UI_DISPLAY_TYPES.DISABLE) ||
                                (!this.props.isBoardMember && getUIOverride(UI_CONFIG.resident_poll, ui_config).display === UI_DISPLAY_TYPES.DISABLE)
                            )
                        }
                    />
                }
                {/* {
                    [UI_DISPLAY_TYPES.SHOW, UI_DISPLAY_TYPES.DISABLE].includes(getUIOverride(UI_CONFIG.notice_board, ui_config).display) &&
                    [UI_DISPLAY_TYPES.SHOW, UI_DISPLAY_TYPES.DISABLE].includes(getUIOverride(UI_CONFIG.board_feed, ui_config).display) &&
                    <SectionNoticeBoard
                        {...this.props}
                        title={getUIOverride(UI_CONFIG.board_feed, ui_config).title}
                        disable={getUIOverride(UI_CONFIG.notice_board, ui_config).display === UI_DISPLAY_TYPES.DISABLE || getUIOverride(UI_CONFIG.board_feed, ui_config).display === UI_DISPLAY_TYPES.DISABLE}
                    />
                }
                {
                    !this.props.isBoardMember &&
                    [UI_DISPLAY_TYPES.SHOW, UI_DISPLAY_TYPES.DISABLE].includes(getUIOverride(UI_CONFIG.notice_board, ui_config).display) &&
                    [UI_DISPLAY_TYPES.SHOW, UI_DISPLAY_TYPES.DISABLE].includes(getUIOverride(UI_CONFIG.society_feed, ui_config).display) &&
                    <SectionSocietyFeed
                        {...this.props}
                        title={getUIOverride(UI_CONFIG.society_feed, ui_config).title}
                        disable={getUIOverride(UI_CONFIG.notice_board, ui_config).display === UI_DISPLAY_TYPES.DISABLE || getUIOverride(UI_CONFIG.society_feed, ui_config).display === UI_DISPLAY_TYPES.DISABLE}
                    />
                } */}
                
                <CustomBanners history={this.props.history} />
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-evenly', marginTop: 6, marginBottom: 2 }}>
                    {
                        !this.props.isBoardMember &&
                        [UI_DISPLAY_TYPES.SHOW, UI_DISPLAY_TYPES.DISABLE].includes(getUIOverride(UI_CONFIG.notice_board, ui_config).display) &&
                        [UI_DISPLAY_TYPES.SHOW, UI_DISPLAY_TYPES.DISABLE].includes(getUIOverride(UI_CONFIG.board_feed, ui_config).display) &&
                        this.renderFeedButton(getUIOverride(UI_CONFIG.board_feed, ui_config).title || "Board Feed", this.redirectToBoardFeed, this.props.showBoardFeedBadge, MessageBoardIcon)
                    }
                    {
                        !this.props.isBoardMember &&
                        [UI_DISPLAY_TYPES.SHOW, UI_DISPLAY_TYPES.DISABLE].includes(getUIOverride(UI_CONFIG.notice_board, ui_config).display) &&
                        [UI_DISPLAY_TYPES.SHOW, UI_DISPLAY_TYPES.DISABLE].includes(getUIOverride(UI_CONFIG.society_feed, ui_config).display) &&
                        this.renderFeedButton(getUIOverride(UI_CONFIG.society_feed, ui_config).title || "Society Feed", this.redirectToSocietyFeed, this.props.showSocietyFeedBadge,SocietyFeedIcon)
                    }
                </div>                                                                
                {this.props.isBoardMember && <SectionBoardView {...this.props} />}                
                <SectionProperty {...this.props} />   
                <SectionNeighborhood {...this.props} />                             
            </div>
        )
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ clearSocietyMsgBoardCache, setBoardFeedBadge, setSocietyFeedBadge }, dispatch);
}

export default connect(null, mapDispatchToProps)(PropertyViewActive)