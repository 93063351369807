import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import qs from 'utils/query-string';
import { updateRoute } from 'actions/home-action';
import { homeRoute, notFoundRoute } from 'routes-parameters/config';

class Redirect extends PureComponent {
    constructor(props) {
        super(props);
        let params;
        if (props.location && props.location.search) {
            params = qs.parse(props.location.search);
            let route, new_params;
            if (params.route !== null && params.route !== undefined) {
                route = params.route;
                new_params = params;
                delete new_params.route;
                props.updateRoute(route, new_params);
                props.history.replace({
                    pathname: `/${route}`
                });
            }
            else {
                if (props.location && props.location.pathname === "/") {
                    if (params && params.constructor === Object && Object.keys(params).length > 0)
                        props.updateRoute(route, params);

                    props.history.replace(`/${homeRoute}`);
                }
                else {
                    props.history.replace(`/${notFoundRoute}`);
                }
            }
        }
        else { }
    }

    render() {
        return (
            <div>
                Loading...
            </div>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ updateRoute }, dispatch);
}

Redirect.displayName = 'Redirect';
export default connect(null, mapDispatchToProps)(Redirect);