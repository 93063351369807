import {
    propertyDetailsRoute,
    propertyTransactionsRoute,
    societyTransactionsRoute,
    pettycashTransactionsRoute,
    propertyVisitorRoute,
    mySocietyRoute,
    entryPassRoute,
    patrolTrackerRoute,
    emeetingRoute,
    exportDataRoute,
    boardSocietyMsgBoardRoute,
    complaintHelpDeskRoute,
    societyBillsInvoicesRoute,
    societySettlementsRoute,
    propertyVehiclesRoute,
    propertyParcelRoute,
    propertySettingsRoute,
} from '../../../../routes-parameters/config';

import VisitorIcon from './assets/visitor.svg';
import SocietyIcon from './assets/society.svg';
import PropertyIcon from './assets/property.svg';
import PropTransactionIcon from './assets/property-accounts.svg';
import SocietyTransactionIcon from './assets/ledger.svg';
import PettyCashTransactionIcon from './assets/petty-cash.svg';
import PettyCashTransactionIconUS from './assets/petty-cash.svg';
import EntryPassIcon from './assets/id-card.svg';
import PatrolTrackerIcon from './assets/patrolling_gaurd.svg';
import EMeetingIcon from './assets/e-meeting.svg';
import ExportDataIcon from './assets/export-data.svg';
import MessageBoardIcon from './scenes/message-board/assets/message.svg';
import HelpDeskIcon from './assets/complaint-helpdesk.svg';
import BillsInvoicesIcon from './assets/bills-invoice.svg';
import SettlementIcon from './assets/settlement.svg';
import SettlementIconUS from './assets/settlement_us.svg';
import GarageIcon from './assets/garage.png';
import ParcelDeliveryIcon from './assets/parcel.png';
import SettingsIcon from './assets/settings.png';
import { CURRENCY_MAPPING } from 'config/config';
import { UI_CONFIG, getUIOverride, UI_DISPLAY_TYPES } from '../home-society/config';
import { sortFeatureCards } from '../home-society/util';

export const myPropertyCard = {
    title: "My Property",
    image: "",
    icon: PropertyIcon,
    color: "",
    route: `/${propertyDetailsRoute}`
}

export function societyCards(ui_config) {
    if (!ui_config) {
        ui_config = {};
    }
    let cards = [];

    cards.push({
        ...myPropertyCard,
        title: getUIOverride(UI_CONFIG.my_property, ui_config).title || "My Property"
    });
    if ([UI_DISPLAY_TYPES.SHOW, UI_DISPLAY_TYPES.DISABLE].includes(getUIOverride(UI_CONFIG.transactions, ui_config).display) &&
        [UI_DISPLAY_TYPES.SHOW, UI_DISPLAY_TYPES.DISABLE].includes(getUIOverride(UI_CONFIG.property_ledger, ui_config).display)) {
        cards.push({
            title: getUIOverride(UI_CONFIG.property_ledger, ui_config).title || "Property Ledger",
            image: "",
            icon: PropTransactionIcon,
            color: "",
            route: `/${propertyTransactionsRoute}`,
            disable: getUIOverride(UI_CONFIG.transactions, ui_config).display === UI_DISPLAY_TYPES.DISABLE ||
                getUIOverride(UI_CONFIG.property_ledger, ui_config).display === UI_DISPLAY_TYPES.DISABLE
        });
    }
    if ([UI_DISPLAY_TYPES.SHOW, UI_DISPLAY_TYPES.DISABLE].includes(getUIOverride(UI_CONFIG.visitors, ui_config).display)) {
        cards.push({
            title: getUIOverride(UI_CONFIG.visitors, ui_config).title || "Visitors",
            image: "",
            icon: VisitorIcon,
            color: "",
            route: `/${propertyVisitorRoute}`,
            disable: getUIOverride(UI_CONFIG.visitors, ui_config).display === UI_DISPLAY_TYPES.DISABLE
        });
    }
    if ([UI_DISPLAY_TYPES.SHOW, UI_DISPLAY_TYPES.DISABLE].includes(getUIOverride(UI_CONFIG.entry_pass, ui_config).display)) {
        cards.push({
            title: getUIOverride(UI_CONFIG.entry_pass, ui_config).title || "Entry Pass",
            image: "",
            icon: EntryPassIcon,
            color: "",
            route: `/${entryPassRoute}`,
            disable: getUIOverride(UI_CONFIG.entry_pass, ui_config).display === UI_DISPLAY_TYPES.DISABLE
        });
    }
    if ([UI_DISPLAY_TYPES.SHOW, UI_DISPLAY_TYPES.DISABLE].includes(getUIOverride(UI_CONFIG.vehicle, ui_config).display)) {
        cards.push({
            title: getUIOverride(UI_CONFIG.vehicle, ui_config).title || "Vehicles",
            image: "",
            svgImage: GarageIcon,
            color: "",
            route: `/${propertyVehiclesRoute}`,
            disable: getUIOverride(UI_CONFIG.vehicle, ui_config).display === UI_DISPLAY_TYPES.DISABLE
        });
    }
    if ([UI_DISPLAY_TYPES.SHOW, UI_DISPLAY_TYPES.DISABLE].includes(getUIOverride(UI_CONFIG.parcel_delivery, ui_config).display)) {
        cards.push({
            title: getUIOverride(UI_CONFIG.parcel_delivery, ui_config).title || "Mail & Packages",
            image: "",
            svgImage: ParcelDeliveryIcon,
            color: "",
            route: `/${propertyParcelRoute}`,
            disable: getUIOverride(UI_CONFIG.parcel_delivery, ui_config).display === UI_DISPLAY_TYPES.DISABLE
        });
    }
    cards.push({
        title: "Settings",
        image: "",
        svgImage: SettingsIcon,
        color: "",
        route: `/${propertySettingsRoute}`
    });

    cards = sortFeatureCards(cards);

    return cards;
}

export function societyBoardCards(currency_code, ui_config, isSuperBM) {
    if (!ui_config) {
        ui_config = {};
    }
    let cards = [];

    cards.push({
        title: getUIOverride(UI_CONFIG.my_society, ui_config).title || "My Society",
        image: "",
        icon: SocietyIcon,
        color: "",
        route: `/${mySocietyRoute}`
    });
    if ([UI_DISPLAY_TYPES.SHOW, UI_DISPLAY_TYPES.DISABLE].includes(getUIOverride(UI_CONFIG.transactions, ui_config).display) &&
        [UI_DISPLAY_TYPES.SHOW, UI_DISPLAY_TYPES.DISABLE].includes(getUIOverride(UI_CONFIG.society_ledger, ui_config).display)) {
        cards.push({
            title: getUIOverride(UI_CONFIG.society_ledger, ui_config).title || "Society Ledger",
            image: "",
            icon: SocietyTransactionIcon,
            color: "",
            route: `/${societyTransactionsRoute}`,
            disable: getUIOverride(UI_CONFIG.transactions, ui_config).display === UI_DISPLAY_TYPES.DISABLE ||
                getUIOverride(UI_CONFIG.society_ledger, ui_config).display === UI_DISPLAY_TYPES.DISABLE
        });
    }
    if ([UI_DISPLAY_TYPES.SHOW, UI_DISPLAY_TYPES.DISABLE].includes(getUIOverride(UI_CONFIG.transactions, ui_config).display) &&
    [UI_DISPLAY_TYPES.SHOW, UI_DISPLAY_TYPES.DISABLE].includes(getUIOverride(UI_CONFIG.pettycash_ledger, ui_config).display)) {
    cards.push({
        title: getUIOverride(UI_CONFIG.pettycash_ledger, ui_config).title || "Petty Cash Ledger",
        image: "",
        icon: currency_code !== 'INR'?PettyCashTransactionIconUS:PettyCashTransactionIcon,
        color: "",
        route: `/${pettycashTransactionsRoute}`,
        disable: getUIOverride(UI_CONFIG.transactions, ui_config).display === UI_DISPLAY_TYPES.DISABLE ||
            getUIOverride(UI_CONFIG.pettycash_ledger, ui_config).display === UI_DISPLAY_TYPES.DISABLE
    });
    }
    if ([UI_DISPLAY_TYPES.SHOW, UI_DISPLAY_TYPES.DISABLE].includes(getUIOverride(UI_CONFIG.transactions, ui_config).display) &&
        [UI_DISPLAY_TYPES.SHOW, UI_DISPLAY_TYPES.DISABLE].includes(getUIOverride(UI_CONFIG.bills_invoices, ui_config).display)) {
        cards.push({
            title: getUIOverride(UI_CONFIG.bills_invoices, ui_config).title || "Bills & Invoices",
            image: "",
            icon: BillsInvoicesIcon,
            color: "",
            route: `/${societyBillsInvoicesRoute}`,
            disable: getUIOverride(UI_CONFIG.transactions, ui_config).display === UI_DISPLAY_TYPES.DISABLE ||
                getUIOverride(UI_CONFIG.bills_invoices, ui_config).display === UI_DISPLAY_TYPES.DISABLE
        });
    }
    if (isSuperBM && [UI_DISPLAY_TYPES.SHOW, UI_DISPLAY_TYPES.DISABLE].includes(getUIOverride(UI_CONFIG.transaction, ui_config).display) &&
        [UI_DISPLAY_TYPES.SHOW, UI_DISPLAY_TYPES.DISABLE].includes(getUIOverride(UI_CONFIG.settlements, ui_config).display)) {
        cards.push({
            title: getUIOverride(UI_CONFIG.settlements, ui_config).title || "Settlements",
            image: "",
            icon: currency_code !== 'INR'?SettlementIconUS:SettlementIcon,
            color: "",
            route: `/${societySettlementsRoute}`,
            disable: getUIOverride(UI_CONFIG.transactions, ui_config).display === UI_DISPLAY_TYPES.DISABLE ||
                getUIOverride(UI_CONFIG.settlements, ui_config).display === UI_DISPLAY_TYPES.DISABLE
        });
    }
    if ([UI_DISPLAY_TYPES.SHOW, UI_DISPLAY_TYPES.DISABLE].includes(getUIOverride(UI_CONFIG.patrol_tracker, ui_config).display)) {
        cards.push({
            title: getUIOverride(UI_CONFIG.patrol_tracker, ui_config).title || "Patrol Tracker",
            image: "",
            icon: PatrolTrackerIcon,
            color: "",
            route: `/${patrolTrackerRoute}`,
            disable: getUIOverride(UI_CONFIG.patrol_tracker, ui_config).display === UI_DISPLAY_TYPES.DISABLE
        });
    }
    if ([UI_DISPLAY_TYPES.SHOW, UI_DISPLAY_TYPES.DISABLE].includes(getUIOverride(UI_CONFIG.board_feed, ui_config).display)) {
        cards.push({
            title: getUIOverride(UI_CONFIG.board_feed, ui_config).title || "Message Board",
            image: "",
            icon: MessageBoardIcon,
            color: "",
            route: `/${boardSocietyMsgBoardRoute}`,
            disable: getUIOverride(UI_CONFIG.board_feed, ui_config).display === UI_DISPLAY_TYPES.DISABLE
        });
    }
    if ([UI_DISPLAY_TYPES.SHOW, UI_DISPLAY_TYPES.DISABLE].includes(getUIOverride(UI_CONFIG.e_meeting, ui_config).display)) {
        cards.push({
            title: getUIOverride(UI_CONFIG.e_meeting, ui_config).title || "E Meeting",
            image: "",
            icon: EMeetingIcon,
            color: "",
            route: `/${emeetingRoute}`,
            disable: getUIOverride(UI_CONFIG.e_meeting, ui_config).display === UI_DISPLAY_TYPES.DISABLE
        });
    }
    if ([UI_DISPLAY_TYPES.SHOW, UI_DISPLAY_TYPES.DISABLE].includes(getUIOverride(UI_CONFIG.board_complaints, ui_config).display)) {
        cards.push({
            title: getUIOverride(UI_CONFIG.board_complaints, ui_config).title || "HelpDesk",
            image: "",
            icon: HelpDeskIcon,
            color: "",
            route: `/${complaintHelpDeskRoute}`,
            disable: getUIOverride(UI_CONFIG.board_complaints, ui_config).display === UI_DISPLAY_TYPES.DISABLE
        });
    }
    if ([UI_DISPLAY_TYPES.SHOW, UI_DISPLAY_TYPES.DISABLE].includes(getUIOverride(UI_CONFIG.export_logs, ui_config).display)) {
        cards.push({
            title: getUIOverride(UI_CONFIG.export_logs, ui_config).title || "Export Logs",
            image: "",
            icon: ExportDataIcon,
            color: "",
            route: `/${exportDataRoute}`,
            disable: getUIOverride(UI_CONFIG.export_logs, ui_config).display === UI_DISPLAY_TYPES.DISABLE
        });
    }

    cards = sortFeatureCards(cards);

    return cards
}

export const residentStatus = {
    PENDING: "pending",
    REJECTED: "rejected",
    ACTIVE: "active"
}