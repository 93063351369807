import React from 'react';
import NotFoundIcon from 'scenes/not-found/assets/NotFound.svg';
import { strings } from 'strings';
import { tags } from 'tags';
import gs_color from 'config/colors.css';

function TimeoutError(props) {
    return (
        <div>
            <div style={{ marginTop: "35%", textAlign: "center" }}>
                <img width="82px" height="82px" src={NotFoundIcon} alt="Error" />
                <div style={{ paddingTop: '20px', fontWeight: '500', fontSize: '17px' }}>{strings.timeout_chunk_string}</div>
                <button
                    onClick={props.retry}
                    style={{ width: "auto", height: "40px", minWidth: "88px", backgroundColor: gs_color.primaryColor, color: "#fff", fontSize: "15px", marginTop: "40px", textTransform: "uppercase" }}
                >
                    {tags.retry}
                </button>
            </div>
        </div>
    );
}

TimeoutError.displayName = 'TimeoutError';
export default TimeoutError;