import axios from 'middleware/axios';
import { API_LINK } from 'config/config';
import { SUPPORT_MAIL, SUPPORT_MOBILE } from 'config/config';
import {
    cl_property_address, cl_access_token, cl_user_id, cl_signedIn, cl_display_name, cl_client_info_public, cl_common_info_public, cl_profile_pic, cl_duplicate_address, cl_existing_address, cl_phone_exists,
    sp_access_token, sp_user_id, sp_acnt_status, sp_signedIn, sp_display_name, sp_sp_info_public, sp_common_info_public, sp_profile_pic, sp_store_profile_pic, sp_online_status, sp_phone_exists,
    sp_acnt_type, sp_public_url, sp_create_schedule, sp_online_last_checked, last_fetch_sp_status,
    otp_type, pushUriId, device_id, pushUriUpdate, login_realm, last_fetch_notification_count, notification_count, notification_data, notification_booking, profile_lang, signin_realm, get_profile_lang,
    sp_premium_fees, sp_premium_base_fees, phone_number, product_type, last_fetch_all_props_notification_count,
    ss_all_svc_discounts, cl_selected_society_property, cl_society_properties, cl_call_prop_api,
    society_notification_count, society_pending_gatekeeper, last_fetch_society_notification_count,
    last_fetch_cl_home_rewards, cl_home_gr_cash, cl_home_gr_points, product_clear_data, product_cart_updated
} from 'config/storage-variables';
import { sendSSERequestToServer, sendSSERemoveRequestToServer } from 'utils/sse';
import { logoutResetReducer, loginTriggerAction } from 'actions/home-action';
import { deleteUserIdIndexedDB, addUserIdIndexedDB } from '../../../../../handle-indexeddb';
import { addUserIdCookies, deleteUserIdCookies } from '../../../../../utils/preprocess-cookies';
import { isValidEmail } from 'utils/validation';

function removeStorageAfterLogout(realm) {
    // Translate changes reverted
    // let should_default_language = false;
    // if (localStorage.getItem(profile_lang))
    //     should_default_language = true;

    if (realm === "svc_consumer") {
        localStorage.removeItem(cl_access_token);
        localStorage.removeItem(profile_lang);
        localStorage.removeItem(cl_user_id);
        localStorage.removeItem(cl_display_name);
        localStorage.removeItem(cl_client_info_public);
        localStorage.removeItem(cl_common_info_public);
        localStorage.removeItem(cl_profile_pic);
        localStorage.removeItem(cl_duplicate_address);
        localStorage.removeItem(cl_existing_address);
        localStorage.removeItem(cl_phone_exists);
        localStorage.removeItem(cl_selected_society_property);
        localStorage.removeItem(cl_society_properties);
        localStorage.removeItem(product_cart_updated);
        sessionStorage.removeItem(society_notification_count);
        sessionStorage.removeItem(society_pending_gatekeeper);
        sessionStorage.removeItem(last_fetch_society_notification_count);
        sessionStorage.removeItem(cl_call_prop_api);
        // localStorage.removeItem(phone_number);
        // localStorage.removeItem(email_id);
        localStorage.setItem(cl_signedIn, 'false');
        localStorage.setItem(product_clear_data, "true");
    }
    else if (realm === "svc_provider") {
        localStorage.removeItem(sp_access_token);
        localStorage.removeItem(profile_lang);
        localStorage.removeItem(sp_user_id);
        localStorage.removeItem(sp_acnt_status);
        localStorage.removeItem(sp_display_name);
        localStorage.removeItem(sp_sp_info_public);
        localStorage.removeItem(sp_common_info_public);
        localStorage.removeItem(sp_profile_pic);
        localStorage.removeItem(sp_store_profile_pic);
        localStorage.removeItem(product_type);
        sessionStorage.removeItem(sp_online_status);
        localStorage.removeItem(sp_phone_exists);
        localStorage.removeItem(sp_acnt_type);
        localStorage.removeItem(sp_public_url);
        localStorage.removeItem(sp_online_last_checked);
        localStorage.removeItem(sp_create_schedule);
        localStorage.setItem(sp_signedIn, 'false');
        localStorage.removeItem("CUSTOM_ITEMS");
        sessionStorage.removeItem('svc_flow_json');
        sessionStorage.removeItem(last_fetch_sp_status);
        sessionStorage.removeItem(sp_premium_fees);
        sessionStorage.removeItem(sp_premium_base_fees);

    }
    sessionStorage.removeItem(last_fetch_notification_count);
    sessionStorage.removeItem(notification_count);
    sessionStorage.removeItem(notification_data);
    sessionStorage.removeItem(notification_booking);
    sessionStorage.removeItem(get_profile_lang);
    sessionStorage.removeItem(ss_all_svc_discounts);
    sessionStorage.removeItem(last_fetch_cl_home_rewards);
    sessionStorage.removeItem(cl_home_gr_points);
    sessionStorage.removeItem(cl_home_gr_cash);
    sessionStorage.removeItem(last_fetch_all_props_notification_count);
    // Translate changes reverted
    // deleteCookie("googtrans");              // Remove google translate cookie.
    // if (should_default_language) {
    //     setToDefaultLanguage();
    // }

    // Remove pendingVerificationBanner (for safe side)
    let pendingVerificationBannerDiv = document.getElementById("pendingVerificationBanner");
    if (pendingVerificationBannerDiv && pendingVerificationBannerDiv.style.display === "block") {
        pendingVerificationBannerDiv.style.display = "none";
    }

    // Remove approvedVerificationBanner (for safe side)
    let approvedVerificationBannerDiv = document.getElementById("approvedVerificationBanner");
    if (approvedVerificationBannerDiv && approvedVerificationBannerDiv.style.display === "block") {
        approvedVerificationBannerDiv.style.display = "none";
    }

    logoutResetReducer();
    deleteUserIdIndexedDB();
    deleteUserIdCookies();
}

function processSuccessfullLogin(user_id, access_token) {
    sessionStorage.removeItem(ss_all_svc_discounts);
    loginTriggerAction();

    updatePushUri();
    sendSSERequestToServer();
    addUserIdIndexedDB(user_id, access_token);
    addUserIdCookies(user_id, access_token);
}

export function logout(realm = null, cb) {
    if (document.getElementById("pendingVerificationBanner") !== null) {
        let x = document.getElementById("pendingVerificationBanner");
        x.style.display = "none";
    }
    if (document.getElementById("approvedVerificationBanner") !== null) {
        let x = document.getElementById("approvedVerificationBanner");
        x.style.display = "none";
    }
    let access_token, user_id, push_id, push_uri;
    if (realm === null) {
        if (localStorage.getItem(cl_signedIn) === 'true') {
            realm = "svc_consumer";
            access_token = localStorage.getItem(cl_access_token);
            user_id = localStorage.getItem(cl_user_id);
        }
        else if (localStorage.getItem(sp_signedIn) === 'true') {
            realm = "svc_provider";
            access_token = localStorage.getItem(sp_access_token);
            user_id = localStorage.getItem(sp_user_id);
        }
        else {
            cb({ message: "No valid user logged in" });
        }
    }
    else if (realm === "svc_consumer") {
        access_token = localStorage.getItem(cl_access_token);
        user_id = localStorage.getItem(cl_user_id);
    }
    else {
        access_token = localStorage.getItem(sp_access_token);
        user_id = localStorage.getItem(sp_user_id);
    }

    if (realm) {

        // Call remove sse.
        sendSSERemoveRequestToServer()
            .then(res => { })
            .catch(err => { });

        push_id = localStorage.getItem(device_id);
        push_uri = localStorage.getItem(pushUriId) ? localStorage.getItem(pushUriId) : '';
        axios.post(`${API_LINK}/gs_Users/${user_id}/gs_logout?access_token=${access_token}`, { push_id, push_uri })
            .then((response) => {
                removeStorageAfterLogout(realm);
                cb(response);
            })
            .catch((err) => {
                //console.log(err);
                removeStorageAfterLogout(realm);
                if (err && err.response && err.response.data && err.response.data.error)
                    cb(err.response.data.error);
                else
                    cb(err);
            });
    }
}

export function thirdPartyLogin(third_party_data, cb) {
    axios.post(`${API_LINK}/gs_Users/gs_ThirdPartyLogin`, third_party_data)
        .then((response) => {
            if (response.data.statusCode === 200 || response.data.statusCode === 300) {
                if (response.data.gsRespData.acnt_status === "suspended" || response.data.gsRespData.acnt_status === "disabled") {
                    cb({ message: `Your account is been suspended. Contact us on ${SUPPORT_MOBILE} or Write us at ${SUPPORT_MAIL}` });
                }
                else {
                    if (third_party_data.user_realm === "svc_consumer") {
                        localStorage.setItem(cl_user_id, response.data.gsRespData.access_token.userId);
                        localStorage.setItem(cl_signedIn, 'true');
                        localStorage.setItem(cl_access_token, response.data.gsRespData.access_token.id);
                        localStorage.setItem(cl_display_name, response.data.gsRespData.display_name);

                    }
                    else if (third_party_data.user_realm === "svc_provider") {
                        localStorage.setItem(sp_user_id, response.data.gsRespData.access_token.userId);
                        localStorage.setItem(sp_acnt_status, response.data.gsRespData.acnt_status);
                        localStorage.setItem(sp_signedIn, 'true');
                        localStorage.setItem(sp_access_token, response.data.gsRespData.access_token.id);
                        localStorage.setItem(sp_display_name, response.data.gsRespData.display_name);
                        sessionStorage.setItem(sp_online_status, response.data.is_online);

                    }
                    sessionStorage.removeItem(signin_realm);
                    processSuccessfullLogin(response.data.gsRespData.access_token.userId, response.data.gsRespData.access_token.id);
                    cb("success", response.data.message, response.data.gsRespData.acnt_status, response.data.gsRespData.phone_exists, response.data.gsRespData.profile_lang);
                }
            }
            else {
                if (response.data.message === 'NO_REF_CODE')
                    cb("error", response.data.message, null, null);
                else {
                    cb("error", response.data.message, null, null);
                }
            }
        })
        .catch((err) => {
            if (err && err.response && err.response.data && err.response.data.error)
                cb("error", err.response.data.error, null, null);
            else
                cb("error", err, null, null);
        });

}

export function nativeLogin(email, realm, password, cb) {
    if (isValidEmail(email)) {
        axios.post(`${API_LINK}/gs_Users/login`, {
            email: email,
            realm: realm,
            password: password,
        })
            .then((response) => {
                if (response.data.acnt_status === "suspended" || response.data.acnt_status === "disabled") {
                    cb({ message: `Your account is been suspended. Contact us on ${SUPPORT_MOBILE} or Write us at ${SUPPORT_MAIL}` });
                }
                else {
                    if (realm === "svc_consumer") {
                        localStorage.setItem(cl_user_id, response.data.userId);
                        localStorage.setItem(cl_signedIn, 'true');
                        localStorage.setItem(cl_access_token, response.data.id);
                        localStorage.setItem(cl_display_name, response.data.display_name);
                    }
                    else if (realm === "svc_provider") {
                        localStorage.setItem(sp_user_id, response.data.userId);
                        localStorage.setItem(sp_acnt_status, response.data.acnt_status);
                        localStorage.setItem(sp_signedIn, 'true');
                        localStorage.setItem(sp_access_token, response.data.id);
                        localStorage.setItem(sp_display_name, response.data.display_name);
                        sessionStorage.setItem(sp_online_status, response.data.is_online);
                    }
                    localStorage.removeItem(login_realm);
                    sessionStorage.removeItem(signin_realm);
                    processSuccessfullLogin(response.data.userId, response.data.id);
                    cb("success", response.data.acnt_status, response.data.profile_lang);
                }
            })
            .catch((err) => {
                if (err && err.response && err.response.data && err.response.data.error)
                    cb(err.response.data.error)
                else
                    cb(err);
            });
    }
    else if (!isNaN(email.charAt(0))) {
        axios.post(`${API_LINK}/gs_Users/login`, {
            username: email,
            realm: realm,
            password: password,
        })
            .then((response) => {
                if (response.data.acnt_status === "suspended" || response.data.acnt_status === "disabled") {
                    cb({ message: `Your account is been suspended. Contact us on ${SUPPORT_MOBILE} or Write us at ${SUPPORT_MAIL}` });
                }
                else {
                    if (realm === "svc_consumer") {
                        localStorage.setItem(cl_user_id, response.data.userId);
                        localStorage.setItem(cl_signedIn, 'true');
                        localStorage.setItem(cl_access_token, response.data.id);
                        localStorage.setItem(cl_display_name, response.data.display_name);
                    }
                    else if (realm === "svc_provider") {
                        localStorage.setItem(sp_user_id, response.data.userId);
                        localStorage.setItem(sp_acnt_status, response.data.acnt_status);
                        localStorage.setItem(sp_signedIn, 'true');
                        localStorage.setItem(sp_access_token, response.data.id);
                        localStorage.setItem(sp_display_name, response.data.display_name);
                        sessionStorage.setItem(sp_online_status, response.data.is_online);
                    }
                    localStorage.removeItem(login_realm);
                    sessionStorage.removeItem(signin_realm);
                    processSuccessfullLogin(response.data.userId, response.data.id);
                    cb("success", response.data.acnt_status, response.data.profile_lang);
                }
            })
            .catch((err) => {
                if (err && err.response && err.response.data && err.response.data.error)
                    cb(err.response.data.error)
                else
                    cb(err);
            });
    }
}

export function SignUp(type, email, mobile, username, acnt_type, realm, referral_code, cb) {
    let data = {
        username: username,
        acnt_type: acnt_type,
        realm: realm
    };
    if (email)
        data = Object.assign(data, { email });
    if (mobile)
        data = Object.assign(data, { phone: mobile });
    if (referral_code)
        data = Object.assign(data, { referral_code });
    axios.post(`${API_LINK}/gs_Users`, data)
        .then((response) => {
            localStorage.setItem(login_realm, realm);
            cb("success");
        })
        .catch((err) => {
            if ((err && err.response && err.response.data && err.response.data.error) && err.response.data.error.message === "Verification Link sent") {
                cb("success");
            }
            else if (err && err.response && err.response.data && err.response.data.error) {
                cb(err.response.data.error);
            }
            else {
                cb(err);
            }
        });
}

export function verifyOTP(phone_email, type, otp, id = null, cb) {
    let realm;
    if (localStorage.getItem(cl_signedIn) === 'true')
        realm = "svc_consumer";
    if (localStorage.getItem(sp_signedIn) === 'true')
        realm = "svc_provider";
    let access_token = realm === "svc_consumer" ? localStorage.getItem(cl_access_token) : localStorage.getItem(sp_access_token);

    let data = {
        toUpdate: phone_email,
        type: type,
        otp: otp
    };

    if (id !== null)
        data = Object.assign(data, { id });

    axios.post(`${API_LINK}/gs_Users/${id}/gs_VerifyOTP?access_token=${access_token}`, data)
        .then((res) => {
            if (res.data.statusCode.toString().slice(0, 2) == 20) {
                cb(res.data, null);
            }
            else
                cb(null, res.data);
        })
        .catch((err) => {

            //console.log(err);
            if (err && err.response && err.response.data && err.response.data.error) {
                if (err.response.data.error.statusCode === 401) {
                    cb(null, { ...err.response.data.error, is_logout: true });
                }
                else

                    cb(null, err.response.data.error);
            }
            else
                cb(null, err);
        })
}

export function verifyOTPwithEmail(email, realm, otp, isForgotPassword, cb) {

    let data = {
        email,
        realm,
        otp,
        isForgotPassword
    };

    axios.post(`${API_LINK}/gs_Users/gs_VerifyOTPWithEmail`, data)
        .then((res) => {
            if (res.data.statusCode.toString().slice(0, 2) == 20) {
                cb(res.data, null);
            }
            else
                cb(null, res.data);
        })
        .catch((err) => {

            //console.log(err);
            if (err && err.response && err.response.data && err.response.data.error) {
                if (err.response.data.error.statusCode === 401) {
                    cb(null, { ...err.response.data.error, is_logout: true });
                }
                else

                    cb(null, err.response.data.error);
            }
            else
                cb(null, err);
        })
}


export function changePassword(oldPassword, newPassword, realm, cb) {
    if (localStorage.getItem(cl_signedIn) === 'true')
        realm = "svc_consumer";
    if (localStorage.getItem(sp_signedIn) === 'true')
        realm = "svc_provider";

    let access_token = realm === "svc_consumer" ? localStorage.getItem(cl_access_token) : localStorage.getItem(sp_access_token);
    axios.post(`${API_LINK}/gs_Users/change-password?access_token=${access_token}`, {
        oldPassword,
        newPassword
    })
        .then((res) => {
            if (res.status.toString().slice(0, 2) == 20) {
                cb(res.status, null);
            }
            else
                cb(null, res.status);
        })
        .catch((err) => {
            if (err && err.response && err.response.data && err.response.data.error) {
                if (err.response.data.error.statusCode === 401) {
                    cb(null, { ...err.response.data.error, is_logout: true });
                }
                else
                    cb(null, err.response.data.error);
            }
            else
                cb(null, err);
        })
}

export function updatePhone(new_phone, id, cb) {
    let access_token;
    if (localStorage.getItem(cl_signedIn) === 'true')
        access_token = localStorage.getItem(cl_access_token);
    if (localStorage.getItem(sp_signedIn) === 'true')
        access_token = localStorage.getItem(sp_access_token);
    axios.post(`${API_LINK}/gs_Users/${id}/gs_UpdatePrimaryPhone?access_token=${access_token}`, {
        new_phone: new_phone,
        id: id,
    })
        .then((res) => {
            if (res.data.statusCode.toString().slice(0, 2) == 20)
                cb(res.data, null);
            else
                cb(null, res.data);
        })
        .catch((err) => {
            //console.log(err);
            if (err && err.response && err.response.data && err.response.data.error) {
                if (err.response.data.error.statusCode === 401) {
                    cb(null, { ...err.response.data.error, is_logout: true });
                }
                else
                    cb(null, err.response.data.error);
            }
            else
                cb(null, err);
        })
}

export function updateEmail(new_email, id, cb) {
    let realm;
    if (localStorage.getItem(cl_signedIn) === 'true')
        realm = "svc_consumer";
    if (localStorage.getItem(sp_signedIn) === 'true')
        realm = "svc_provider";
    let access_token = realm === "svc_consumer" ? localStorage.getItem(cl_access_token) : localStorage.getItem(sp_access_token);
    axios.post(`${API_LINK}/gs_Users/${id}/gs_UpdatePrimaryEmail?access_token=${access_token}`,
        {
            new_email: new_email,
            id: id,
        })
        .then((res) => {
            if (res.data.statusCode.toString().slice(0, 2) == 20)
                cb(res.data, null);
            else
                cb(null, res.data);
        })
        .catch((err) => {
            //console.log(err);
            if (err && err.response && err.response.data && err.response.data.error) {
                if (err.response.data.error.statusCode === 401) {
                    cb(null, { ...err.response.data.error, is_logout: true });
                }
                else
                    cb(null, err.response.data.error);
            }
            else
                cb(null, err);
        })
}

export function forgotEmail(email, realm, cb) {

    axios.post(`${API_LINK}/gs_Users/gs_ForgotPasswordLinkOnEmail`,
        {
            email: email,
            realm: realm,
        })
        .then((res) => {
            if (res.data.statusCode.toString().slice(0, 2) == 20)
                cb(res.data, null);
            else
                cb(null, res.data);
        })
        .catch((err) => {
            cb(null, err);
        })
}

export function forgotPhone(phone, realm, cb) {
    axios.post(`${API_LINK}/gs_Users/gs_ForgotPasswordLinkOnPhone`,
        {
            phone: phone,
            realm: realm,
        })
        .then((res) => {
            if (res.data.statusCode.toString().slice(0, 2) == 20)
                cb(res.data, null);
            else
                cb(null, res.data);
        })
        .catch((err) => {

            //console.log(err);
            cb(null, err);
        })
}

export function signInWithOTP(phone, realm, cb) {

    axios.post(`${API_LINK}/gs_Users/gs_SignInWithOTP`, {
        phone: phone,
        realm: realm
    })
        .then((res) => {
            if (res.data.statusCode.toString().slice(0, 2) == 20) {
                cb(res.data, null);
            }
            else
                cb(null, res.data);
        })
        .catch((err) => {

            //console.log(err);
            cb(null, err);
        })
}

export function verifyLoginOTP(phone, realm, otp, type = 0, isForgotPassword, cb) {

    // type = 0 -> signin using otp
    // type = 1 -> signup verification

    axios.post(`${API_LINK}/gs_Users/gs_VerifyLoginOTP`, {
        phone: phone,
        realm: realm,
        otp: otp,
        isForgotPassword: isForgotPassword
    })
        .then((response) => {
            if (type === 0) {
                if (response.data.statusCode.toString().slice(0, 2) == 20) {
                    if (response.data.acnt_status === "suspended" || response.data.acnt_status === "disabled") {
                        cb({ message: `Your account is been suspended. Contact us on ${SUPPORT_MOBILE} or Write us at ${SUPPORT_MAIL}` });
                    }
                    else {
                        if (realm === "svc_consumer") {
                            localStorage.setItem(cl_user_id, response.data.userId);
                            localStorage.setItem(cl_signedIn, 'true');
                            localStorage.setItem(cl_access_token, response.data.id);
                            localStorage.setItem(cl_display_name, response.data.display_name);
                        }
                        else if (realm === "svc_provider") {
                            localStorage.setItem(sp_user_id, response.data.userId);
                            localStorage.setItem(sp_acnt_status, response.data.acnt_status);
                            localStorage.setItem(sp_signedIn, 'true');
                            localStorage.setItem(sp_access_token, response.data.id);
                            localStorage.setItem(sp_display_name, response.data.display_name);
                            sessionStorage.setItem(sp_online_status, response.data.is_online);
                        }
                        // localStorage.removeItem(otp_type);
                        localStorage.removeItem(login_realm);
                        sessionStorage.removeItem(signin_realm);
                        processSuccessfullLogin(response.data.userId, response.data.id);
                        cb(response.data, null);
                    }
                }
                else
                    cb(null, response.data);
            }
            else {
                if (response.data.statusCode.toString().slice(0, 2) == 20) {
                    cb(response.data, null);
                }
                else
                    cb(null, response.data);
            }
        })
        .catch((err) => {

            //console.log(err);
            cb(null, err);
        })
}

export function removePushUri(uri_id, push_uri, cb) {
    if (!cb) {
        cb = () => { }
    }

    console.log("inside removePushUri");

    let user_id = null, access_token = null;
    if (!uri_id) {
        uri_id = localStorage.getItem(device_id);
    }
    if (!push_uri) {
        push_uri = localStorage.getItem(pushUriId);
    }
    if (localStorage.getItem(cl_user_id) !== null) {
        user_id = localStorage.getItem(cl_user_id);
        access_token = localStorage.getItem(cl_access_token);
    }
    else if (localStorage.getItem(sp_user_id) !== null) {
        user_id = localStorage.getItem(sp_user_id);
        access_token = localStorage.getItem(sp_access_token);
    }

    if (user_id !== null && access_token !== null && uri_id !== null) {
        axios.post(`${API_LINK}/gs_Users/${user_id}/gs_ManagesPushUri?access_token=${access_token}`, {
            id: user_id,
            act_type: 0,
            uri_id,
            push_uri
        })
            .then((res) => {
                cb(res.data, null);
            })
            .catch((err) => {
                if (err && err.response && err.response.data && err.response.data.error) {
                    if (err.response.data.error.statusCode === 401) {
                        cb(null, { ...err.response.data.error, is_logout: true });
                    }
                    else
                        cb(null, err.response.data.error);
                }
                else
                    cb(null, err);
            });
    }
}

export function updatePushUri(act_type = 1, cb) {
    if (!cb) {
        cb = () => { }
    }

    let user_id = null, access_token = null, uri_id = localStorage.getItem(device_id), push_uri = localStorage.getItem(pushUriId);
    if (localStorage.getItem(cl_user_id) !== null) {
        user_id = localStorage.getItem(cl_user_id);
        access_token = localStorage.getItem(cl_access_token);
    }
    else if (localStorage.getItem(sp_user_id) !== null) {
        user_id = localStorage.getItem(sp_user_id);
        access_token = localStorage.getItem(sp_access_token);
    }

    if (user_id !== null && access_token !== null && uri_id !== null && push_uri !== null) {
        axios.post(`${API_LINK}/gs_Users/${user_id}/gs_ManagesPushUri?access_token=${access_token}`, {
            id: user_id,
            act_type,
            uri_id,
            push_uri
        })
            .then((res) => {
                if (act_type === 2)
                    sessionStorage.removeItem(pushUriUpdate);
                cb(res.data, null);
            })
            .catch((err) => {
                if (err && err.response && err.response.data && err.response.data.error) {
                    if (err.response.data.error.statusCode === 401) {
                        cb(null, { ...err.response.data.error, is_logout: true });
                    }
                    else
                        cb(null, err.response.data.error);
                }
                else
                    cb(null, err);
            });
    }
}

export function getClPortfolio(callback) {
    let user_id = localStorage.getItem(cl_user_id);
    let access_token = localStorage.getItem(cl_access_token);

    let available_address = null;
    if (localStorage.getItem(cl_property_address) !== null)
        available_address = JSON.parse(localStorage.getItem(cl_property_address));

    if (available_address === null) {
        available_address = [];
    }

    let prop_list = {};
    prop_list.prop_list = available_address;
    let input = JSON.stringify(prop_list);

    let duplicate_value = [];
    let exist_list = [];
    let is_duplicate = false;

    axios.post(`${API_LINK}/gs_Users/${user_id}/gs_PostClientPortfolio?access_token=${access_token}`, { prop_list: input })
        .then((res) => {
            if (res.data.statusCode.toString().slice(0, 2) == 20) {
                if (res.data.gsRespData.prop_list === null) {
                    is_duplicate = false;
                }
                else {
                    res.data.gsRespData.prop_list.forEach((prop_list_item, i) => {
                        if (JSON.stringify(prop_list_item.note).includes('DUPLICATE')) {
                            let required_format_of_address = {
                                g_address: prop_list_item.prop_address.g_address,
                                svc_prop_id: prop_list_item.svc_prop_id !== null ? prop_list_item.svc_prop_id : prop_list_item.prop_address.svc_prop_id,
                            };
                            let svc_cell_id_obj = {}, gcs_buckets_obj = {};
                            if (prop_list_item.svc_cell_id)
                                svc_cell_id_obj = { svc_cell_id: prop_list_item.svc_cell_id };
                            if (prop_list_item.gcs_buckets)
                                gcs_buckets_obj = { gcs_buckets: prop_list_item.gcs_buckets };

                            required_format_of_address = Object.assign(required_format_of_address, svc_cell_id_obj, gcs_buckets_obj);
                            duplicate_value.push(required_format_of_address);
                        }
                        else {
                            let required_format_of_address = {
                                g_address: prop_list_item.prop_address.g_address,
                                svc_prop_id: prop_list_item.svc_prop_id !== null ? prop_list_item.svc_prop_id : prop_list_item.prop_address.svc_prop_id,
                            };
                            let svc_cell_id_obj = {}, gcs_buckets_obj = {};
                            if (prop_list_item.svc_cell_id)
                                svc_cell_id_obj = { svc_cell_id: prop_list_item.svc_cell_id };
                            if (prop_list_item.gcs_buckets)
                                gcs_buckets_obj = { gcs_buckets: prop_list_item.gcs_buckets };

                            required_format_of_address = Object.assign(required_format_of_address, svc_cell_id_obj, gcs_buckets_obj);
                            exist_list.push(required_format_of_address);
                        }
                    });
                }
                if (duplicate_value.length !== 0) {
                    is_duplicate = true;
                }
                callback(duplicate_value, exist_list, is_duplicate, null);
            }
            else {
                callback(null, null, null, res.data);
            }
        })
        .catch(err => {
            console.error(err);
            if (err && err.response && err.response.data && err.response.data.error) {
                if (err.response.data.error.statusCode === 401) {
                    callback(null, { ...err.response.data.error, is_logout: true });
                }
                else
                    callback(null, null, null, err.response.data.error);
            }
            else
                callback(null, null, null, err);
        });
}

export function patchDuplicateAddress(address, cb) {
    let access_token = localStorage.getItem(cl_access_token);
    address = JSON.parse(address);
    axios.patch(`${API_LINK}/gs_Client_Portfolio/${address.svc_prop_id}?access_token=${access_token}`, { prop_address: address })
        .then((res) => {
            cb(res.data, null);
        })
        .catch((err) => {
            if (err && err.response && err.response.data && err.response.data.error) {
                if (err.response.data.error.statusCode === 401) {
                    cb(null, { ...err.response.data.error, is_logout: true });
                }
                else
                    cb(null, err.response.data.error);
            }
            else
                cb(null, err);
        });
}

export function StaticText(cb) {
    let access_token = localStorage.getItem(sp_access_token);
    var uri = `{"where":{"text_category":"YOUTUBE_LINKS"}}`
    var encode = encodeURI(uri);
    axios.get(`${API_LINK}/gs_Static_Text?access_token=${access_token}&filter=${encode}`)
        .then((res) => {
            if (res.status.toString().slice(0, 2) == 20) {
                cb(res.data, null);
            }
        })
        .catch((err) => {
            if (err && err.response && err.response.data && err.response.data.error) {
                if (err.response.data.error.statusCode === 401) {
                    cb(null, { ...err.response.data.error, is_logout: true });
                }
                else
                    cb(null, err.response.data.error);
            }
            else
                cb(null, err);
        });
}

export function postValidateEmail(email, cb) {
    axios.post(`${API_LINK}/gs_Users/gs_postValidateEmail`, { email: email })
        .then((res) => {
            if (res.data && res.data.statusCode.toString().slice(0, 2) == 20) {
                cb(res.data.gsRespData, null);
            }
            else {
                cb(null, res.data);
            }
        })
        .catch((err) => {
            if (err && err.response && err.response.data && err.response.data.error) {
                cb(null, err.response.data.error);
            }
            else
                cb(null, err);
        });
}