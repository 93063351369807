import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import VerticalHomeScreenComponent from './vertical-home-screen-component';
import VerticalService from './vertical-service';
import VerticalProdcut from './vertical-product';
import OlaGateAd from '../olagate-ad';

class Verticals extends PureComponent {
    handleCardClick(route, event) {
        if (typeof this.props.handleCardClick === "function") {
            this.props.handleCardClick(route, event);
        }
    }

    getVerticalComponent = datum => {
        // Hide upcoming for home screen
        if (!datum.redirectUrl && this.props.is_home_screen) {
            return null
        } else if (this.props.is_home_screen && ['SERVICES', 'PRODUCTS'].includes(datum.key)) {
            let data = datum.data || [];
            data = data.sort(() => Math.random() - 0.5);
            data = data.slice(0, 3);
            return <VerticalHomeScreenComponent key={datum.key} {...datum} data={data} handleCardClick={(route, event) => this.handleCardClick(route, event)} history={this.props.history} />

        } else if (datum.key === 'SERVICES') {
            return <VerticalService key={datum.key} {...datum} handleCardClick={(route, event) => this.handleCardClick(route, event)} history={this.props.history} />

        } else if (datum.key === 'PRODUCTS') {
            return <VerticalProdcut key={datum.key} {...datum} handleCardClick={(route, event) => this.handleCardClick(route, event)} history={this.props.history} />

        } else {
            return null
        }
    }

    renderList(data) {
        let shown_og_ad = false;
        let rows = [];

        data.map(datum => {
            if (rows.length > 0 && !shown_og_ad && this.props.shown_og_ad) {
                shown_og_ad = true;
                rows.push(<OlaGateAd key="og-ad" />);
            }
            const item = this.getVerticalComponent(datum);
            if (item) {
                rows.push(item);
            }
        });

        if (!shown_og_ad && this.props.shown_og_ad) {
            shown_og_ad = true;
            rows.push(<OlaGateAd key="og-ad" />);
        }
        return rows;
    }

    render() {
        return (
            <>
                {!this.props.loading && !this.props.error && this.renderList(this.props.data)}
                {!this.props.loading && !this.props.error && this.renderList(this.props.data)}                
            </>
        )
    }
}

function mapStateToProps({ home_screen }) {
    const { data, loading, error } = home_screen;

    return {
        data: data ? data : [],
        loading,
        error
    };
}

Verticals.displayName = 'Verticals';
export default connect(mapStateToProps)(Verticals);