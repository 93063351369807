import React from 'react';
import IconButton from 'material-ui/IconButton';
import CloseButton from 'material-ui/svg-icons/navigation/close';
import gs_color from 'config/colors.css';

class BackButton extends React.Component {
    static defaultProps = {
        color: gs_color.primaryColor,
        className: "myAppBarLeft"
    };

    BackClicked() {
        this.props.history.goBack();
    }

    render() {
        const onClick = this.props.onClick ? this.props.onClick : () => this.BackClicked();
        return (
            <IconButton style={{ padding: '0px', width: '24px', height: '24px' }} className={this.props.className} onClick={onClick} ><CloseButton color={this.props.color} /></IconButton>
        );
    }
}

BackButton.displayName = 'BackButton';
export default BackButton;