/* dev URLS */
// export const APP_LINK = "https://holagate.app";
// export const API_LINK = "https://holagate.app/api";
// export const DOMAIN = "https://holagate.app";
// export const SEC_DOMAIN = "https://gusp.co.in";
// export const SSE_API_LINK = "https://holagate.app/sse";
// export const TERMS_OF_SERVICE_LINK = "https://holagate.app/legal/terms-of-service";
// export const PRIVACY_POLICY_LINK = "https://holagate.app/legal/privacy-policy";
// export const OG_BLOG_LINK = "https://blog.olagate.com/";

/* prod URLS */
export const APP_LINK = "https://olagate.app";
export const API_LINK = "https://olagate.app/api";
export const DOMAIN = "https://olagate.app";
export const SEC_DOMAIN = "https://olagate.com";
export const SSE_API_LINK = "https://olagate.app/sse";
export const TERMS_OF_SERVICE_LINK = "https://olagate.app/legal/terms-of-service";
export const PRIVACY_POLICY_LINK = "https://olagate.app/legal/privacy-policy";
export const OG_BLOG_LINK = "https://blog.olagate.com/";
export const OGFOOD_BASE_LINK = "https://ogfood.app/";

/* uat URLS */
// export const APP_LINK = "https://gusp.co.in";
// export const API_LINK = "https://gusp.co.in/api";
// export const SEC_DOMAIN = "https://holagate.app";
// export const DOMAIN = "https://gusp.co.in";
// export const SSE_API_LINK = "https://gusp.co.in/sse";
// export const TERMS_OF_SERVICE_LINK = "https://gusp.co.in/legal/terms-of-service";
// export const PRIVACY_POLICY_LINK = "https://gusp.co.in/legal/privacy-policy";
// export const OG_BLOG_LINK = "https://blog.olagate.com/";

//Prod Key
//export const RAZOR_PAY_TEST_KEY = "rzp_live_39jI3s2XFjiL4q";        

//TEST MODE
//export const RAZOR_PAY_TEST_KEY = "rzp_test_iiBonFnQluFBR6";  //old
export const RAZOR_PAY_TEST_KEY = "rzp_test_OfQInK0NbKCtC3";  //new

//For PayTm switch following link 
//in ...\pwa_fe\public\index.html file
//
        //PayTm Staging Wallet
        //#77777 77777
        //OTP: 489871
        // Sandbox
            //loadJS("https://securegw-stage.paytm.in/merchantpgpui/checkoutjs/merchants/pgifNU18469171119255.js");
        // Live
            //loadJS("https://securegw.paytm.in/merchantpgpui/checkoutjs/merchants/zqMMcn94681553851382.js");


export const FACEBOOK_ID = "251633512344224";
export const GOOGLE_ID = "185337869306-9l3lp8pi8oma1shm6fjcjv9btpdjkai8.apps.googleusercontent.com";

//gruhaps-prod
//export const GOOGLE_MAP_API = "AIzaSyALUi2BwhSvA3RifdJvteJgdLOqEbYvo3c";
//olagate-in-gmaps - test UAT 
export const GOOGLE_MAP_API ="AIzaSyBWxNvuoU1JbombY0hscDM6kSuC7Uakym0";

export const GEONAMES_GET_ZIPCODE_LATLONG = `${API_LINK}/gs_GeoNames/gs_findNearbyPostalCodesJSON`;
export const GEONAMES_USERNAME = "gruhaps.admin";
export const SPSCHEDULELIMIT = 1;
export const MAX_CLIENT_ADDRESS = 15;
export const MAX_SCHEDULE_RECESS_DURATION = 8; // in hrs
export const SERREQLIMITMAX = 1000;
export const SERREQLIMITMIN = 50;
export const ONELINELIMITMAX = 30;
export const TWOLINELIMITMAX = 50;
export const NAMELIMITMIN = 3;
export const INTROLIMITMAX = 1000;
export const FIRSTNAMEMAX = 15;
export const LASTNAMEMAX = 15;
export const REQLIMITMIN = 25;
export const UNITNUMLIMITMAX = 25;
export const DISPLAYLIMIT = 40;
export const MESSGLIMIT = 200;
export const APP_VERSION = '2.0.0';
// export const CLIENT_DEFAULT_REFERRAL_CODE = "$GRS$";
export const CLIENT_DEFAULT_REFERRAL_CODE = "GRSADMIN";
export const SP_DEFAULT_REFERRAL_CODE = "GRSADMIN";
export const SP_REGISTRATION_SERVICE_ITEMS_BATCH = 5;
export const MAX_BOOKING_DATE = { day: 30, month: 0, year: 0 };
export const SERVICE_QUANTITY_LIMIT = 100;
export const PARTS_QUANTITY_LIMIT = 100;
export const SUPPORT_MAIL = "hello@olagate.com";
export const SUPPORT_PAYMENT_MAIL = "payment.support@olagate.com";
export const SUPPORT_MOBILE = "8484888848";
export const SUPPORT_MOBILE2 = "8411801333";
export const REF_CODE_LIMIT = "8"
export const MAX_VISITING_CHARGE = 1000;
export const MAX_SERVICE_ITEM_CHARGE = 100000;
export const MAX_PART_ITEM_CHARGE = 100000;
export const TIMESLOT_INTERVAL_MAX = 120;       // in mins
export const OTP_TIME_INTERVAL = 60;    // in secs
export const BOOKING_API_CALL_TIME = 10; // in mins
export const EXPIRED_BOOKING_TIME = 2; //days 
export const MAX_PHOTO_SERVICE_REQUIREMENT = 5;
export const MAX_VIDEO_SERVICE_REQUIREMENT = 5;
export const MAX_AUDIO_SERVICE_REQUIREMENT = 5;
export const MINUTES_RESCHEDULE_APPLICABLE = 240;       //  4hrs after service date confirmed status (expired) reschedule applicable
export const CLIENT_MEDIA_UPLOAD = false;
export const GCS_BUCKET = 'grsdev-gcs-public';
export const MAX_CLOUD_FILE_SIZE = 10200000;
export const INVOICE_TOTAL_LIMIT = 10000000;
export const SP_PUBLIC_URL_BASE = APP_LINK + "/sp";
export const SP_PUBLIC_URL_MIN_LENGTH = 5;
export const SP_PUBLIC_URL_MAX_LENGTH = 25;
export const CITY = "Bangalore";
export const BASE_PREMIUM_FEES = 1497;
export const PORTFOLIO_IMAGE_DESCRIPTION = 50;
export const MAX_PORTFOLIO_IMAGES = 15;
export const CART_SAVE_TIME_LIMIT = 10000;
export const DEFAULT_DELIVERY_TIME_SLOT = "07:00 AM to 07:00 PM"
export const COMM_PGWT_FEES = 2.12;
export const COMM_PGWT_FEES_US = 2.5;
export const COMM_UPI_FEES = 0.405;
export const COMM_VPA_FEES = 0.0;
export const COMM_FEES_GST = 18.0;
export const DNHOOD_INFO_MAIL = "info@olagatetech.com";
export const DNHOOD_SUPPORT_MAIL = "info@olagatetech.com";
export const DNHOOD_SUPPORT_MOBILE = "302-313-6637";
export const DEPARTMENT_NAME_LIMIT = 20;
export const POLL_CHOICES_LIMIT = 4;
export const POLL_QUESTION_CHAR_LIMIT = 200;
export const POLL_CHOICE_CHAR_LIMIT = 25;
export const POLL_DURATION_MAX_DAYS = 7;
export const POLL_SCHEDULE_MAX_DAYS = 7;
export const COVID_TEMPERATURE_LIMIT = 100.4;
export const PAYTM_QR_CODE = APP_LINK + "/s=t/upi_payment_link/downloadMedia";
export const NUMBER_PLATE_MAX_LENGTH = 15;

export const COUNTRY_CODE = {
    INDIA: "in",
    USA: "us"
}

export const CLOUD_FILE_TYPE = {
    SP_PROFILE_PIC: {
        caption: 'SP_PROFILE_PIC',
        maxFileSize: MAX_CLOUD_FILE_SIZE,
        maxNumberOfFiles: 1,
        compression_options: { quality: 0.5 }
    },
    SP_PROFILE_DOC: {
        caption: 'SP_PROFILE_DOC',
        maxFileSize: MAX_CLOUD_FILE_SIZE,
        maxNumberOfFiles: 1,
        compression_options: { quality: 0.5 }
    },
    STORE_PIC: {
        caption: 'STORE_PIC',
        maxFileSize: MAX_CLOUD_FILE_SIZE,
        maxNumberOfFiles: 1,
        compression_options: { quality: 0.5 }
    },
    STORE_DOC: {
        caption: 'STORE_DOC',
        maxFileSize: MAX_CLOUD_FILE_SIZE,
        maxNumberOfFiles: 1,
        compression_options: { quality: 0.5 }
    },
    CLIENT_PIC: {
        caption: 'CLIENT_PIC',
        maxFileSize: MAX_CLOUD_FILE_SIZE,
        maxNumberOfFiles: 1,
        compression_options: { quality: 0.5 }
    },
    BOOKING_REQUIREMENTS: {
        caption: 'BOOKING_REQUIREMENTS',
        maxFileSize: MAX_CLOUD_FILE_SIZE,
        maxNumberOfFiles: 5,
        compression_options: { quality: 0.5 }
    },
    QUOTE_PIC: {
        caption: 'QUOTE_PIC',
        maxFileSize: MAX_CLOUD_FILE_SIZE,
        maxNumberOfFiles: 5,
        compression_options: { quality: 0.5 }
    },
    INVOICE_PIC: {
        caption: 'INVOICE_PIC',
        maxFileSize: MAX_CLOUD_FILE_SIZE,
        maxNumberOfFiles: 5,
        compression_options: { quality: 0.5 }
    },
    CHAT_PIC: {
        caption: 'CHAT_PIC',
        maxFileSize: MAX_CLOUD_FILE_SIZE,
        maxNumberOfFiles: 1,
        compression_options: { quality: 0.5 }
    },
    REVIEW_PIC: {
        caption: 'REVIEW_PIC',
        maxFileSize: MAX_CLOUD_FILE_SIZE,
        maxNumberOfFiles: 1,
        compression_options: { quality: 0.5 }
    },
    SP_PORTFOLIO_PIC: {
        caption: 'SP_PORTFOLIO_PIC',
        maxFileSize: MAX_CLOUD_FILE_SIZE,
        maxNumberOfFiles: 15,
        compression_options: { quality: 0.5 }
    },
    SOCIETY_TXN_PIC: {
        caption: 'SOCIETY_TXN_PIC',
        maxFileSize: MAX_CLOUD_FILE_SIZE,
        maxNumberOfFiles: 5,
        compression_options: { quality: 0.5 }
    },
    SOCIETY_MSG_BOARD_PIC: {
        caption: 'SOCIETY_MSG_BOARD_PIC',
        maxFileSize: MAX_CLOUD_FILE_SIZE,
        maxNumberOfFiles: 5,
        compression_options: {
            quality: 1,
            maxWidth: 1500,
            maxHeight: 1500,
        }
    },
    SOCIETY_ADHOC_BILL_PIC: {
        caption: 'SOCIETY_ADHOC_BILL_PIC',
        maxFileSize: MAX_CLOUD_FILE_SIZE,
        maxNumberOfFiles: 5,
        compression_options: { quality: 0.5 }
    },
};

/*
{
            quality: 0.8,
            convertSize: 2000000
        }
        if (original_file.size > 2200000 && original_file.size <= 5200000) {
            options.quality = 0.6;
        }
        if (original_file.size > 5200000 && original_file.size <= 7200000) {
            options.quality = 0.4;
        }
        if (original_file.size > 7200000) {
            options.quality = 0.2;
        }
*/

// ONLY USED TILL PRODUCTION BUILD
export const GRS_ENV_CLIENT = "CLIENT";
export const GRS_ENV_SP = "SP";
export const user_type_client = "client", user_type_sp = "sp", user_type_none = "not-signed-in";


// Encryption PUBLIC KEY
export const RSA_PUBLIC_KEY = "-----BEGIN PUBLIC KEY-----\n" +
    "MIICIjANBgkqhkiG9w0BAQEFAAOCAg8AMIICCgKCAgEAsYGVjBVpwXjNb7O6KXf4\n" +
    "eralUwM3YDYwGJ6tuHEmpMfn/GNY5+QwiUUW3ri0AYHmXklteXg1M7dSApeU57lF\n" +
    "ok2XAyxgA0f6e4Il6e33ofS9+yRfB7KLOTQZM8ephO7RKht4KWNxeEggwB7tVoCo\n" +
    "8CXyR2FQvmZ/Q9JgRwYd0Oi6zx4DzjVWbXeE2OvoGRNrBOQMDckZmhSlqhhVU4Gx\n" +
    "yLxPN1LeDSuCHV+k/CL6Q8+mM10DeZT19oM1pnYSqKi7aAu0XDL8YgkeOndukodM\n" +
    "VTZD+Z6gVO9nPT4wF1SO9zzxytj/hjF1hocRkLYGipELjISztIUhfhfcDEsvsq0G\n" +
    "MH1v/JA+4m96hF2tzJjBUkJoRiGtGbdURsrEu1YBwqfjbFPXIO8jO11OUyHnrO9g\n" +
    "p6VwUyqc81SE/aDtOCobxsFKFtRFsHFaOZWsmeGNY950iBzH7uPr6nooz0h6Zy4x\n" +
    "5QvJ7QsEPBDZVjb/SSxSyStfA2VxskaKHCTIPDAuCnMBkIXpfl2IRix6G3g/QeZP\n" +
    "FCJAFfpeCHBZAbK2e/Cl0oTyq61Ou0sSvoLIjBgq1BSIM+i5SVnNrGvJw+7tn0M1\n" +
    "DWP04Fg8xg+UOTHvBusWlJ/qoiWAaQg6ihD0K5n6LygSNXGHerOugvn7Q8+Tve8+\n" +
    "Rh5eULO3HIGhhLRQmJTceRkCAwEAAQ==\n" +
    "-----END PUBLIC KEY-----";

export const OG_CART_META_TAGS = {
    title: "OGCart - Get Fresh Vegetables, Grocery, Rice, Quinoa, Meat, NonVeg, Potted plant & Gardening items delivered doorstep",
    description: "OGCart a OlaGate family App.It's an eCommerce platform for local business, shops, producers, farmers, artists. OGCare promotes & supports Buy Local & Help Local. On OGCart consumers find products like grocery, non veg ,whole grains & rice & dal, snacks & food, potted plant & gardening items, handicraft items from locals. We provide end to end solutions to find, order & pay for items. Keep track of your expenses and monthly budget and forecast.",
    keywords: "Fresh Vegetables & Food item | Rice, Whole Grains & Quinoa Delivery | Online Potted plant & Gardening"
}

export const OG_CARE_META_TAGS = {
    title: "OGCare - Find Local Service Provider, Online Home Services, Personal care Service Providers near you.",
    description: "OGCare a OlaGate family App.It's a service eCommerce platform of a local service provider. OGCare promotes & supports Hire Local & Help Local. The platform for both consumers to find & hire local service providers. We provide end to end solutions to find & book any service provider near you at your fingertips.",
    keywords: "Service Providers near Me, Online Home Services, Multi Service Provider"
}

export const MAX_EMERGENCY_CONTACTS = 5;

export const CURRENCY_MAPPING = {
    'INR': '₹',
    'USD': '$'
  }

