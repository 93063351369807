import React, { Component } from 'react';
import { connect } from 'react-redux';

import { BottomNavigation } from 'material-ui/BottomNavigation';
import Paper from 'material-ui/Paper';
import Badge from 'material-ui/Badge';

import HomeIcon from '../assets/home.svg';
import HomeSelIcon from '../assets/home-sel.svg';
import ProfileIcon from '../assets/user.svg';
import BlogIcon from '../assets/blog_icon_bw.svg';
import ProfileSelIcon from '../assets/user-sel.svg';
import OrdersIcon from '../assets/shopping-bag.svg';
import OrdersSelIcon from '../assets/shopping-bag-sel.svg';
import BookingsIcon from '../assets/booking.svg';
import BookingsSelIcon from '../assets/booking-sel.svg';
import EduIcon from '../assets/online-course.svg';
import EduSelIcon from '../assets/online-course-sel.svg';
import ExploreIcon from '../assets/marker.svg';
import ExploreSelIcon from '../assets/marker-sel.svg';
import CareIcon from '../assets/mechanic.svg';
import CareSelIcon from '../assets/mechanic-sel.svg';
import FoodIcon from '../assets/grocery.svg';
import FoodSelIcon from '../assets/grocery-sel.svg';

import {
    homeRoute, profileRoute, bookingRoute, exploreRoute,
    productsRoute, ordersRoute, ogcartRoute, ogcareRoute, exploreAppsRoute, ogfoodRoute
} from 'routes-parameters/config';
import { cl_signedIn,cl_society_properties } from 'config/storage-variables';
import { isDNhoodEnv, isOGCart, isOGCare } from 'utils/helper-functions';
import { tags } from 'tags';
import gs_color from 'config/colors.css';

class BottomNav extends Component {
    constructor(props) {
        super(props);

        this.signed_in = localStorage.getItem(cl_signedIn) === 'true';
        const is_dnhood_env = isDNhoodEnv();
        const is_ogcart = isOGCart();
        const is_ogcare = isOGCare();
        let is_registered_property = localStorage.getItem(cl_society_properties);


        let tabs_data = [];
        if (is_registered_property) {
            tabs_data.push({
                index: 0,
                cssId: this.signed_in ? 'home' : 'not-signed-home',
                selIcon: HomeSelIcon,
                nonSelIcon: HomeIcon,
                title: "Home",
                route: is_ogcart ? ogcartRoute : is_ogcare ? ogcareRoute : homeRoute,
                showBadge: false,
            });
        }
        if (!(is_ogcart || is_ogcare)) {
            // tabs_data.push({
            //     index: 2,
            //     cssId: 'explore',
            //     selIcon: ExploreSelIcon,
            //     nonSelIcon: ExploreIcon,
            //     title: "Explore",
            //     route: is_ogcart || is_ogcare ? exploreAppsRoute : exploreRoute,
            //     showBadge: false,
            // });
            tabs_data.push({
                index: 6,
                cssId: 'explore',
                selIcon: ExploreSelIcon,
                nonSelIcon: ExploreIcon,
                title: "Neighborhood",
                route: exploreRoute,
                showBadge: false,
            });
            // tabs_data.push({
            //     index: 7,
            //     cssId: 'food',
            //     selIcon: FoodSelIcon,
            //     nonSelIcon: FoodIcon,
            //     title: "Food",
            //     route: ogfoodRoute,
            //     showBadge: false,
            // });
        }
        if (is_dnhood_env) {
            tabs_data.push({
                index: 1,
                cssId: 'kidsEdu',
                selIcon: EduSelIcon,
                nonSelIcon: EduIcon,
                title: "KidsEdu",
                route: productsRoute,
                showBadge: false,
            });
            tabs_data.push({
                index: 5,
                cssId: 'orders',
                selIcon: OrdersSelIcon,
                nonSelIcon: OrdersIcon,
                title: "Orders",
                route: ordersRoute,
                showBadge: false,
            });
        }
        else if (is_ogcart || is_ogcare) {
            tabs_data.push({
                index: 5,
                cssId: 'orders',
                selIcon: OrdersSelIcon,
                nonSelIcon: OrdersIcon,
                title: "Orders",
                route: ordersRoute,
                showBadge: false,
            });
            tabs_data.push({
                index: 1,
                cssId: 'bookings',
                selIcon: BookingsSelIcon,
                nonSelIcon: BookingsIcon,
                title: "Bookings",
                route: bookingRoute,
                showBadge: false,
            });
        }
        tabs_data.push({
            index: 7,
            //cssId: this.signed_in ? 'home' : 'not-signed-home',
            selIcon: BlogIcon,
            nonSelIcon: BlogIcon,
            title: "OGBlog",
            redirect_url: 'https://blog.olagate.com/',
            //route: is_ogcart ? ogcartRoute : is_ogcare ? ogcareRoute : homeRoute,
            showBadge: false,
        });
        tabs_data.push({
            index: 3,
            cssId: this.signed_in ? 'account' : 'not_signed_in',
            selIcon: ProfileSelIcon,
            nonSelIcon: ProfileIcon,
            title: this.signed_in === "not_signed_in" ? tags.sign_in : "Account",
            route: profileRoute,
            showBadge: props.show_email_highlight,
        });
        
        this.tabs_data = tabs_data;
    }

    Clicked(route,redirect_url=null) {
        if (redirect_url){
            window.open(redirect_url, '_blank');
        }
        else{
            if (this.props.push) {
                this.props.history.push(`/${route}`);
            } else {
                this.props.history.replace(`/${route}`);
            }
        }
    }

    getSelectedIndex = index => {
        if (index === 0) {
            return 0;

        } else if (index === 1) {
            return 3;

        } else if (index === 2) {
            return 2;

        } else if (index === 3) {
            return 4;

        } else if (index === 4) {
            return 1;

        } else if (index === 5) {
            return 4;

        } else if (index === 6) {
            return 6;
        }else if (index === 7) {
            return 7;
        }
    }

    renderWithBadge(children, include_badge) {
        if (include_badge) {
            return (
                <Badge
                    badgeContent={<div style={{ background: 'red', width: '7px', height: '7px', borderRadius: '50%' }}></div>}
                    style={{ padding: '0px 10px 0px 0px' }}
                    badgeStyle={{ width: 'unset', height: 'unset', top: "0px", right: "0px" }}
                >
                    {children}
                </Badge>
            )
        }
        else {
            return children;
        }
    }

    render() {
        return (
            <Paper zDepth={1} className="mobileOnly">
                <BottomNavigation selectedIndex={this.getSelectedIndex(this.props.selectedIndex)} className="BottomNav">
                    {
                        this.tabs_data.map(item => (
                            !item.showBadge ?
                                <div
                                    style={{ padding: '8px 0px', textAlign: 'center', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}
                                    onClick={() => item.redirect_url ? this.Clicked(item.route, item.redirect_url) : this.Clicked(item.route)}
                                    id={item.cssId}
                                    key={item.index}
                                >
                                    <div>
                                        <img
                                            src={this.props.selectedIndex === item.index ? item.selIcon : item.nonSelIcon}
                                            style={{ width: '22px', height: '22px' }}
                                        />
                                        <div style={{
                                            fontSize: '10px',
                                            color: this.props.selectedIndex === item.index ? gs_color.primaryColor : gs_color.textColor,
                                            fontWeight: this.props.selectedIndex === item.index ? 'bold' : 'normal'
                                        }}>{item.title}</div>
                                    </div>
                                </div>
                                :
                                <div
                                    style={{ padding: '8px 0px', textAlign: 'center', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}
                                    onClick={() => item.redirect_url ? this.Clicked(item.route, item.redirect_url) : this.Clicked(item.route)}
                                    key={item.index}
                                >
                                    {
                                        this.renderWithBadge(
                                            <div>
                                                <img
                                                    src={this.props.selectedIndex === item.index ? item.selIcon : item.nonSelIcon}
                                                    style={{ width: '22px', height: '22px' }}
                                                />
                                                <div style={{
                                                    fontSize: '10px',
                                                    color: this.props.selectedIndex === item.index ? gs_color.primaryColor : gs_color.textColor,
                                                    fontWeight: this.props.selectedIndex === item.index ? 'bold' : 'normal'
                                                }}>{item.title}</div>
                                            </div>
                                            , true
                                        )
                                    }
                                </div>
                        ))
                    }
                </BottomNavigation>
            </Paper>
        );
    }
}

function mapStateToProps({ miscellaneous_components }) {
    const { show_email_highlight } = miscellaneous_components;

    return {
        show_email_highlight,
    };
}

export default connect(mapStateToProps)(BottomNav);