import React, { Fragment, Component } from 'react';
import PropsTypes from 'prop-types';
import momentTZ from 'moment-timezone';
import moment from 'moment';

import Avatar from 'material-ui/Avatar';
import Dialog from 'material-ui/Dialog';
import CircularProgress from 'material-ui/CircularProgress';

import AccountCircleIcon from 'material-ui/svg-icons/action/account-circle';

import { CLOUD_FILE_TYPE } from 'config/config';

import { tags } from 'tags';
import { setProfilePic } from '../../scenes/client/scenes/authentication/scenes/sp-registration/api';
import { downloadMedia, compressImage } from 'utils/handle-media';
import ImageViewer from '../image-viewer';
import './styles.css';

let timezone, offset;

class ProfilePic extends Component {
    static defaultProps = {
        editable: false,
        BUCKET_NAME: '',
        profile_pic: '',
        display_name: '',
        size: 50,
        style: {},
        gcsToken: null,
        prof_pic_loader: false,
    };

    constructor(props) {
        super(props);
        this.state = {
            open: false,
            is_logout: false,
            open_image_viewer: false
        };

        this.images = [];
        this.selected_image_index = null;

        this.uploadimage = this.uploadimage.bind(this);
    }

    handleHashChange = () => {
        if (window.location.hash === "#showImage" && this.images.length !== 0)
            this.setState({ open_image_viewer: true });
        else
            this.setState({ open_image_viewer: false });
    }

    componentDidMount() {
        window.addEventListener('hashchange', this.handleHashChange, false);
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (
            this.props.editable === nextProps.editable &&
            this.props.BUCKET_NAME === nextProps.BUCKET_NAME &&
            this.props.profile_pic === nextProps.profile_pic &&
            this.props.display_name === nextProps.display_name &&
            this.props.size === nextProps.size &&
            this.props.gcsToken === nextProps.gcsToken &&
            this.state.prof_pic_loader === nextState.prof_pic_loader &&
            this.state.open === nextState.open &&
            this.state.is_logout === nextState.is_logout &&
            this.state.open_image_viewer === nextState.open_image_viewer &&
            Object.keys(this.props.style || {}).length === Object.keys(nextProps.style || {}).length
        )
            return false;
        else
            return true;
    }

    openImageViewer(image, index) {
        let images_arr = [];

        if (index)
            this.selected_image_index = index;

        let crop_size;
        if (window && window.innerWidth) {
            let innerWidth = window.innerWidth;
            if (innerWidth <= 400) {
                crop_size = '400:';
            }
            else if (innerWidth <= 600) {
                crop_size = '600:';
            }
            else {
                crop_size = '800:';
            }
        }
        if (!crop_size)
            crop_size = ':';

        images_arr.push({
            source: {
                thumbnail: downloadMedia(image, { crop: '150:150' }),
                regular: downloadMedia(image, { crop: crop_size }),
                download: downloadMedia(image, { crop: crop_size })
            }
        })

        this.images = images_arr;

        this.setHash();
    }

    setHash() {
        window.location.hash = "showImage";
    }

    removeHash() {
        if (this.props.history) {
            this.props.history.goBack();
        }
    }

    closeImageViewer() {
        this.images = [];
        this.removeHash();
    }

    profileOnClick() {
        if (this.props.editable) {
            if (this.state.open !== true) {
                this.setState({ open: true });
            }
        }
        // typezoom ==> is used for component which is not to be zoomed
        else if (this.props.typezoom) {
        }
        else if (this.props.profile_pic) {
            this.openImageViewer(this.props.profile_pic, 0);    // component which we need to zoom
        }
    }

    getoffset() {
        offset = moment.tz(timezone).format('Z')
        var res = offset.replace(":", ".");
        var n = res.includes("+");
        if (n == true) {
            var res1 = res.replace("+", "");
            return res1;
        }
        else
            return res;
    }

    uploadimage(file) {
        this.setState({ prof_pic_loader: true });

        if (file) {
            compressImage(file, CLOUD_FILE_TYPE.CLIENT_PIC.compression_options)
                .then((result) => {

                    if (this.props.profilePicApi && typeof this.props.profilePicApi === "function") {
                        // Expected input for function is file and callback.
                        // Expected res on success should be {location: 'link of picture'}
                        this.props.profilePicApi(result, (res, err) => {
                            if (res && res.location) {
                                var profile_pic_link = res.location;
                                this.props.setProfilePic(profile_pic_link)
                                this.setState({ open: false, prof_pic_loader: false });
                            }
                            else {
                                // console.log("error: ", err)
                                this.setState({ open: false, prof_pic_loader: false })
                            }
                        });
                    }
                    else {
                        setProfilePic(CLOUD_FILE_TYPE.CLIENT_PIC.caption, result, (res, err) => {
                            if (res && res.location) {
                                var profile_pic_link = res.location;
                                this.props.setProfilePic(profile_pic_link)
                                this.setState({ open: false, prof_pic_loader: false });
                            }
                            else {
                                // console.log("error: ", err)
                                this.setState({ open: false, prof_pic_loader: false })
                            }
                        })
                    }

                })
                .catch((err) => {
                    // console.log("error catch: ", err)
                    this.setState({ open: false, prof_pic_loader: false });
                });
        }
    }

    onImageChange(event) {
        timezone = momentTZ.tz.guess();
        if (timezone === "Asia/Calcutta")
            timezone = "Asia/Kolkata";
        offset = this.getoffset();
        var file1 = event.target.files[0];
        this.uploadimage(file1);
    }

    render() {
        if (this.state.prof_pic_loader) {
            if (this.state.open !== false)
                this.setState({ open: false })
        }
        const actions = [
            <div style={{ marginRight: '30px' }}>
                <label className='updateProfilePicButton'>
                    <div style={{ padding: "5px", border: "1px solid grey", borderRadius: "5px", fontSize: "14px" }}>{this.props.dialog_button_label || "Change Photo"}</div>
                    <input style={{ display: 'none' }} id="src1" type="file"
                        onChange={this.onImageChange.bind(this)}
                        accept="image/*" />
                </label>
            </div>
        ];

        var randomColor = ["#ffab91", "#607d8b", "#bdbdbd", "#795548", "#b9f96d", "#ff5722", "#ffa726", "#ffb300", "#ffeb3b", "#c0ca33", "#8bc34a", "#4caf50", "#00bcd4", "#03a9f4", "#2196f3", "#3f51b5", "#673ab7", "#ab47bc", "#e91e63", "#f44336"]
        let color = randomColor[parseInt(Math.random() * 1000) % randomColor.length]

        const MyAvatar =
            this.props.profile_pic !== null && this.props.profile_pic !== undefined && this.props.profile_pic !== '' ?
                <div >
                    {
                        this.state.prof_pic_loader
                            ?
                            <div style={{ borderRadius: "40px", width: "80px", height: "80px", background: "#fff", display: "inline-flex", justifyContent: "center" }}>
                                <CircularProgress size={24} style={{ marginLeft: '10px', marginTop: '25px', color: "#fff", marginRight: "10px" }} />
                            </div>
                            :
                            <Avatar
                                size={this.props.size}
                                className="notranslate"
                                src={downloadMedia(this.props.profile_pic, { crop: `${this.props.size}:${this.props.size}` })}
                                onClick={() => this.profileOnClick()}
                                style={Object.assign({}, this.props.style, { color: '#FFFFFF', backgroundColor: '#FFFFFF' })}
                            />
                    }
                </div>


                : this.props.display_name !== null && this.props.display_name !== undefined && this.props.display_name !== '' ?
                    <div> {
                        this.state.prof_pic_loader
                            ?
                            <div style={{ borderRadius: "40px", width: "80px", height: "80px", background: "#fff", display: "inline-flex", justifyContent: "center" }}>
                                <CircularProgress size={24} style={{ marginLeft: '10px', marginTop: '25px', color: "#fff", marginRight: "10px" }} />
                            </div>
                            :
                            <Avatar
                                size={this.props.size}
                                className="notranslate"
                                onClick={() => this.profileOnClick()}
                                style={Object.assign({}, this.props.style, { color: '#FFFFFF', backgroundColor: color })}
                            >
                                {this.props.display_name.trim().substring(0, 1).toUpperCase()}
                            </Avatar>
                    }
                    </div>
                    :
                    <div> {
                        this.state.prof_pic_loader
                            ?
                            <div style={{ borderRadius: "40px", width: "80px", height: "80px", background: "#fff", display: "inline-flex", justifyContent: "center" }}>
                                <CircularProgress size={24} style={{ marginLeft: '10px', marginTop: '25px', color: "#fff", marginRight: "10px" }} />
                            </div>
                            :
                            <Avatar
                                size={this.props.size}
                                className="notranslate"
                                icon={this.props.defaultIcon ? this.props.defaultIcon : <AccountCircleIcon />}
                                onClick={() => this.profileOnClick()}
                                style={Object.assign({}, { color: '#FFFFFF', backgroundColor: '#FFF' }, this.props.style)}
                            />
                    }
                    </div>

        const imgViewerComp =
            this.state.open_image_viewer ?
                <ImageViewer
                    currentIndex={this.selected_image_index}
                    images={this.images}
                    showClose={true}
                    handleClose={() => this.closeImageViewer()}
                />
                : null

        if (this.props.editable === false) {
            return (
                <Fragment>
                    {MyAvatar}
                    {imgViewerComp}
                </Fragment>
            );
        }

        else {
            return (
                <span>
                    <Fragment>
                        {MyAvatar}
                        {imgViewerComp}
                    </Fragment>

                    <Dialog
                        title={<div style={{ fontSize: "14px" }}>{this.props.dialog_title || tags.update_profile}</div>}
                        actions={actions}
                        modal={false}
                        open={this.state.open}
                        titleClassName="dialogTitleClassName"
                        bodyClassName="dialogBodyClassName"
                        contentClassName="DialogNewStyle"
                        onRequestClose={() => this.setState({ open: false })}>
                    </Dialog>
                </span>
            )
        }
    }

    componentWillUnmount() {
        window.removeEventListener('hashchange', this.handleHashChange, false);
    }
}

ProfilePic.propTypes = {
    editable: PropsTypes.bool,
    BUCKET_NAME: PropsTypes.string,
    profile_pic: PropsTypes.string,
    display_name: PropsTypes.string,
    setProfilePic: PropsTypes.func
};

ProfilePic.displayName = 'ProfilePic';
export default ProfilePic;