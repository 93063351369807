import { REHYDRATE } from 'redux-persist';
import { EMERGENCY_CONTACTS, EMERGENCY_CONTACTS_SUCCESS, EMERGENCY_CONTACTS_ERROR } from '../../actions/types';
import ApiError, { handleNetworkError } from '../../utils/api-error';

const INITIAL_STATE = {
    emergency_contact_list: [],
    is_personal_emer_list: false,               //  Do user has personal emergency contact list. If yes, then send sms to them.
    error: undefined,
    loading: true,
    is_logout: false,
    cache: null
};

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case REHYDRATE: if (action.payload && action.payload.emergency_contacts) return { ...action.payload.emergency_contacts }; else return INITIAL_STATE;
        case EMERGENCY_CONTACTS: return { ...INITIAL_STATE, loading: true, is_logout: false };
        case EMERGENCY_CONTACTS_SUCCESS:
            if (action.payload !== undefined && action.payload !== null) {
                let resp = ApiError(action.payload.statusCode, action.payload.message, action.payload.gsRespData, "contact_list", []);
                let is_personal_emer_list = action.payload.gsRespData.is_personal_emer_list;
                if (resp && resp.length > 0) {
                    return { ...INITIAL_STATE, emergency_contact_list: resp, cache: action.cache, is_personal_emer_list, loading: false, is_logout: false };
                }
                else {
                    return { ...INITIAL_STATE, loading: false, cache: action.cache, is_personal_emer_list, is_logout: false };
                }
            }
            else
                return { ...state, loading: false, is_logout: false };
            break;
        case EMERGENCY_CONTACTS_ERROR:
            let hNE_res = handleNetworkError(action);
            if (hNE_res.error)
                return { ...INITIAL_STATE, ...hNE_res, loading: false, is_logout: false };
            else if (action.payload && action.payload.response && action.payload.response.data && action.payload.response.data.error) {
                if (action.payload.response.data.error.statusCode === 401)
                    return { ...INITIAL_STATE, error: action.payload.response.data.error, loading: false, is_logout: true };
                else
                    return { ...INITIAL_STATE, error: action.payload.response.data.error, loading: false, is_logout: false };
            }
            else
                return { ...INITIAL_STATE, error: action.payload, loading: false, is_logout: false };
        default: return state;
    }
}