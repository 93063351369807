export const aboutUsRoute = 'aboutUs';
export const contactUsRoute = 'ContactUs';
export const aboutgRuhapsRoute = 'aboutOlaGate';
export const openSourceLicencesRoute = 'licences';
export const userFeedbackRoute = 'feedback';
export const profileRoute = 'profile';
export const bookingRoute = 'booking';
export const spListMapRoute = 'spListMap';
export const chatRoute = 'chat';
export const getItemsRoute = 'getItems';
export const getQuestionnaireRoute = 'getQuestionnaire';
export const spListRoute = 'spList';
export const serviceProviderRoute = 'spDetails';
export const spPageparentRoute = 'sp';
export const spDetailsPageRoute = 'searchByName';
export const selectSPServiceRoute = 'selectSPService';
export const selectSPServiceGroupsRoute = 'selectSPServiceGroups';
export const selectSPTimeslotRoute = 'selectSPTimeslot';
export const reviewRoute = 'review';
export const bookTimeslotRoute = 'searchForSp';
export const selectBookingTypeRoute = 'selectBookingType';
export const selectLocationRoute = 'selectLocation';
export const bookingHistoryRoute = 'bookingHistory';
export const questionListRoute = 'questionList';
export const cartRoute = 'cart';
export const questionListCartRoute = 'questionListCart';
export const writeReviewRoute = 'writeReview';
export const submittedReviewRoute = 'submittedReview';
export const spInfoRoute = 'sp-info';
export const clientInfoRoute = 'client-info';
export const bookingDetailsRoute = 'bookingDetails';
export const cancelRoute = 'cancel';
export const rescheduleBookingRoute = 'rescheduleBooking';
export const quotationRoute = 'quotation';
export const quotationDetailsRoute = 'quotationDetails';
export const selectServiceRoute = 'selectService';
export const searchServiceRoute = 'searchService';
export const upcomingServicesRoute = 'upcomingService';
export const editProfileRoute = 'editProfile';
export const changePasswordRoute = 'changePassword';
export const changePhoneRoute = 'changePhone';
export const changeEmailRoute = 'changeEmail';
export const otpRoute = 'otp';
export const otpReceivedRoute = 'otpReceived';
export const signinRoute = 'signin';
export const signupRoute = 'signup';
export const signupClientRoute = 'signup-client';
export const signupSpRoute = 'signup-sp';
export const propertyAddressRoute = 'propertyAddress';
export const displayAddressRoute = 'displaypropertyAddress';
export const notificationsRoute = 'notifications';
export const invoiceRoute = 'invoice';
export const fixedMarkerRoute = 'fixedMarker';
export const advertRoute = 'advert';
export const forgotPasswordRoute = 'forgot-password';
export const referralCodeRoute = 'referral-code';
export const rewardsRoute = 'rewards';
export const spRewardsRoute = 'spRewards';
export const paymentHistoryRoute = 'payment-history';
export const teamRoute = 'team';
export const manageDiscountRoute = 'manageDiscount';
export const broadcastHistoryRoute = 'broadcast-history';
// export const specialOfferRoute = 'special-offer';
export const scheduleRoute = 'schedule';
export const newScheduleRoute = 'newSchedule';
export const editScheduleRoute = 'editSchedule';
export const schDetailsRoute = 'schDetails';
export const testRoute = 'test';
export const notFoundRoute = 'notFound';
export const loaderRoute = 'loader';
export const nLoaderRoute = 'nLoader';
export const linkExpiredRoute = 'linkExpired';
export const forgotOrRegisterRoute = 'forgotOrRegister';
export const accountSuspendedRoute = 'accountSuspended';
export const accountSettingsRoute = 'accountSettings';
export const languageRoute = 'language';
export const spRegistrationRoute = 'sp-registration';
export const newServiceRequestRoute = 'new-service-request';
export const thirdPartyPhoneRoute = 'thirdPartyPhone';
export const spBroadcastRequestRoute = 'spBroadcastRequest';
export const clBroadcastRequestRoute = 'clBroadcastRequest';
export const premiumFeaturesRoute = 'premiumFeatures';
export const setPublicUrlRoute = 'setPublicUrl';
export const spPremiumDetailsRoute = 'spPremiumDetails';
export const spPlansRoute = 'spPlans';
export const spHomeRoute = 'spHome';
export const spNearRoute = 'spNear';
export const spBookingRoute = 'spBooking';
export const timeslotRoute = 'spTimeslots';
export const spProfileRoute = 'spProfile';
export const editSPProfileRoute = 'editSPProfile';
export const spPortfolioImagesRoute = 'spPortfolioImages';
export const manageServiceLocationRoute = 'manageServiceLocation';
export const manageServicesRoute = 'manageServices';
export const addServicesRoute = 'addServices';
export const mySPReviewRoute = 'mySPReview';
export const addServiceRoute = 'addService';
export const setServiceRangeRoute = 'setServiceRange';
export const editQuoteRoute = 'editQuote';
export const editInvoiceRoute = 'editInvoice';
export const imageZoomRoute = 'imageZoom';
export const homeRoute = '';
export const noSocietyRoute = 'noSociety';
export const societyRoute = 'society';
export const propertyVisitorRoute = 'propertyVisitor';
export const societyStaffRoute = 'societyStaff';
export const selectSocietyRoute = 'selectSociety';
export const propertyDetailsRoute = 'propertyDetails';
export const boardMembersRoute = 'boardMembers';
export const societyPropertiesRoute = 'societyProperties';
export const propertyTransactionsRoute = 'propertyTransactions';
export const propertyTransactionsMakePaymentRoute = 'propertyTransactionsMakePayment';
export const societyTransactionsRoute = 'societyTransactions';
export const pettycashTransactionsRoute = 'pettycashTransactions';
export const societyNotificationRoute = 'societyNotification';
export const mySocietyRoute = 'mySociety';
export const gatekeepersInfoRoute = 'gatekeepersInfo';
export const societyNotificationRedirectRoute = 'societyNotificationRedirect';
export const entryPassRoute = 'entryPass';
export const gateApprovalsRoute = 'gateApprovals';
export const patrolTrackerRoute = 'patrolTracker';
export const manageCheckpointsRoute = 'manageCheckpoints';
export const productsRoute = 'products';
export const productListingRoute = 'product-list';
export const productDetailsRoute = 'product-details';
export const productCartRoute = 'product-cart';
export const productCheckoutRoute = 'product-checkout';
export const productOrderPaymentRoute = 'product-order-payment';
export const productOrderPaymentPaypalRoute = 'product-order-payment-paypal';
export const ordersRoute = 'orders';
export const productOrderDetailsRoute = 'product-order-details';
export const productFeedbackRoute = 'product-feedback';
export const helpRoute = 'help';
export const emeetingRoute = 'emeeting';
export const emeetingsRoute = 'emeetings';
export const emeetingsHistoryRoute = 'emeetings-history';
export const exportDataRoute = 'export-data';
export const residentRequestsRoute = 'resident-requests';
export const boardMemberViewRoute = 'board-member-view';
export const societyMsgBoardRoute = 'society-msg-board';
export const boardSocietyMsgBoardRoute = 'board-society-msg-board';
export const intercomRoute = 'intercom';
export const exploreRoute = 'explore';
export const societyFeedRoute = 'society-feed';
export const complaintsRoute = 'complaints';
export const complaintHelpDeskRoute = 'complaint-helpdesk';
export const societyMsgDetailsRoute = 'society-msg-details';
export const societyDepartmentsRoute = 'society-departments';
export const emailVerifiedRoute = 'emailVerified';
export const pollsRoute = 'polls';
export const myPollsRoute = 'my-polls';
export const propertyTransactionsStatusRoute = 'propertyTransactionsStatus';
export const cbillRoute = 'cbill';
export const cinvoiceRoute = 'cinvoice';
export const societyDetailsRoute = 'societyDetails';
export const propertyTransactionDetailsRoute = 'propertyTransactionDetails';
export const societyTransactionDetailsRoute = 'societyTransactionDetails';
//export const pettycashTransactionDetailsRoute = 'pettycashTransactionDetails';
export const societyBillsInvoicesRoute = 'societyBillsInvoices';
export const societySettlementsRoute = 'societySettlements';
export const societySettlementDetailsRoute = 'societySettlementDetails';
export const ogcartRoute = 'ogcart';
export const ogcareRoute = 'ogcare';
export const exploreAppsRoute = 'exploreApps';
export const emergencyRoute = 'emergency';
export const emergencyAlertNotificationRoute = 'emergencyAlertNotification';
export const vaccineAvailabilityRoute = 'vaccineAvailability';
export const userEmergencyContactsRoute = 'userEmergencyContacts';
export const userEmergencyAlertNotificationRoute = 'userEmergencyAlertNotification';
export const propertyVehiclesRoute = 'propertyVehicles';
export const propertyParcelRoute = 'propertyParcel';
export const propertySettingsRoute = 'propertySettings';
export const ogfoodRoute = 'ogfood';
export const ogfoodCartRoute = 'ogfood-cart';
export const ogfoodOrdersRoute = 'ogfood-orders';
export const ogfoodOrderDetailsRoute = 'ogfood-order-details';
export const janSevaRoute = 'janSeva';
export const emergencyContactsRoute = 'emergencyContacts';
export const jskSpListRoute = 'jskSpList';