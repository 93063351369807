import moment from 'moment';

import { rate_timeslots } from 'config/storage-variables';
import { svc_place_enum } from 'scenes/booking/config';

import EarlyMorningIcon from '../assets/icon_6am-8am.svg';
import MorningIcon from '../assets/icon_8am-12pm.svg';
import AfternoonIcon from '../assets/icon_12pm-5pm.svg';
import EveningIcon from '../assets/icon_5pm-9pm.svg';
import LateEveningIcon from '../assets/icon_9pm-12am.svg';
import EmergencyIcon from '../assets/icon_12am-6am.svg';
import { BOOKING_API_CALL_TIME } from 'config/config';
import { isSameAsEncodeUrl } from 'utils/helper-functions';
import { dcnt_types, discount_type, offered_by } from 'scenes/sp/scenes/profile/scenes/manage-discount/config';

let timeSlot_imgUrls = [
    EarlyMorningIcon,
    MorningIcon,
    AfternoonIcon,
    EveningIcon,
    LateEveningIcon,
    EmergencyIcon
];

export function parseRateFactorTimeSlots(timeslots = null) {
    if (timeslots === null)
        timeslots = sessionStorage.getItem(rate_timeslots) === null ? null : JSON.parse(sessionStorage.getItem(rate_timeslots));
    if (timeslots === null || timeslots.constructor !== Array)
        return null;

    // Extract timeslots with fields : { timeSlot: '6am - 8am', tag: 'Early Morning', imgUrl: EarlyMorningIcon, timeSlot_st: "6am", timeSlot_et: "8am", ts_rate_factor: "25" },
    let response = [];
    timeslots.forEach(timeslot => {
        let ts = timeslot.timeslot;
        let st, et, timeSlot_string;
        if (ts && ts.constructor === String) {
            let temp_st, temp_et, temp_ts;
            temp_ts = ts.split('_');

            if (temp_ts.length === 4) {
                temp_st = temp_ts[1];
                temp_et = temp_ts[3];

                st = moment(temp_st, 'HH').format('h a');
                et = moment(temp_et, 'HH').format('h a');
            }
        }
        let status = timeslot.status;
        if (status !== null && status !== undefined && status.constructor === Boolean) { }
        else { status = true; }
        if (st && et) {
            timeSlot_string = [st, '-', et].join(' ');
            response.push({
                timeSlot: timeSlot_string,
                tag: ts,
                timeSlot_st: st,
                timeSlot_et: et,
                ts_rate_factor: timeslot.ts_rate_factor,
                status: status
            });
        }
    });
    if (response.constructor === Array) {
        response.sort((a, b) => {
            let a_st, b_st;
            if (a.timeSlot_st && b.timeSlot_st) {
                a_st = moment(a.timeSlot_st, 'h a').format('HH');
                b_st = moment(b.timeSlot_st, 'h a').format('HH');
            }
            return a_st - b_st;
        });
        response.forEach((res, i) => {
            let imgUrl = timeSlot_imgUrls[i % timeSlot_imgUrls.length];
            res.imgUrl = imgUrl;
        });

    }
    return response;
}

export function getMappingRateFactorTimeslot(timeslots, time, time_format = null) {
    // type = 0 -> server response.
    // type = 1 -> parseRateFactorTimeSlots response.
    let response = null, formatted_time;
    if (time_format === null)
        formatted_time = moment(time).format('HH');
    else
        formatted_time = moment(time, time_format).format('HH');

    if (timeslots.length > 0) {
        let type = -1;
        if (timeslots[0].tag) {
            type = 0;
        }
        else if (timeslots[0].timeslot) {
            type = 1;
        }
        if (type !== -1) {
            timeslots.forEach(timeslot => {
                let ts = timeslot.timeslot;
                if (!ts)
                    ts = timeslot.tag;
                let st, et;
                if (ts && ts.constructor === String) {
                    let temp_st, temp_et, temp_ts;
                    temp_ts = ts.split('_');

                    if (temp_ts.length === 4) {
                        temp_st = temp_ts[1];
                        temp_et = temp_ts[3];

                        st = moment(temp_st, 'HH').format('HH');
                        et = moment(temp_et, 'HH').format('kk');
                    }
                    if (st && et) {
                        if (st <= formatted_time && formatted_time < et) {
                            response = ts;
                        }
                    }
                }
            });
        }
    }
    return response;
}

export function getServiceObj(svc_name, props) {
    if (svc_name && props && props.constructor === Array) {
        for (let i = 0; i < props.length; i++) {
            if (isSameAsEncodeUrl(svc_name, props[i].svc_name) || isSameAsEncodeUrl(svc_name, props[i].service_name))
                return props[i];
        }
    }
    return null;
}

function diff_minutes(dt2, dt1) {
    var date = new Date(dt2);
    var diff = (dt1.getTime() - date.getTime()) / 1000;
    diff /= 60;
    return Math.abs(Math.round(diff));

}

export function diff_days(today, date) {

    var date2 = new Date(today);
    var date1 = new Date(date);
    var timeDiff = Math.abs(date2.getTime() - date1.getTime());
    var dayDifference = Math.ceil(timeDiff / (1000 * 3600 * 24));

    return dayDifference;

}

function modify_array(mapping_data, time, value) {
    if (mapping_data && time && value) {
        mapping_data.forEach(m => {
            if (m.status === value)
                m.time = time;
        });
    }
    return mapping_data;
}


export function CallBookingApi(value) {
    var data = sessionStorage.getItem("BOOKING_API_CALLED") ? JSON.parse(sessionStorage.getItem("BOOKING_API_CALLED")) : [];
    var d = new Date();
    let status_time_mapping = {
        status: value,
        time: d
    }
    let stat = [];
    if (data && data.length > 0) {
        data.forEach(get_data1 => {
            stat.push(get_data1.status);
        });
    }
    var return_value = false;
    if (data && data.length > 0) {
        data.forEach(get_data => {
            if (stat.includes(value)) {
                let time_diff = diff_minutes(get_data.time, d);
                if (time_diff >= BOOKING_API_CALL_TIME) {
                    let array = modify_array(data, d, value);
                    sessionStorage.setItem("BOOKING_API_CALLED", JSON.stringify(array))
                    return_value = true;
                }
            }
            else {
                data.push(status_time_mapping);
                let arr = []
                let time_data = sessionStorage.getItem("BOOKING_API_CALLED") ? JSON.parse(sessionStorage.getItem("BOOKING_API_CALLED")) : [];
                if (time_data) {
                    time_data.forEach(get_data => {
                        arr.push(get_data.status);
                    });
                }
                if (!arr.includes(value)) {
                    sessionStorage.setItem("BOOKING_API_CALLED", JSON.stringify(data))
                    return_value = true;
                }
            }
        })
        return return_value;
    }
    else {
        data.push(status_time_mapping);
        sessionStorage.setItem("BOOKING_API_CALLED", JSON.stringify(data))
        return true;
    }
}

export function getSPProfilePic(svc_place, store_pic, sp_profile_pic) {
    if (svc_place === svc_place_enum.both) {
        if (store_pic)
            return store_pic;
    }
    else if (svc_place === svc_place_enum.fixed) {
        if (store_pic)
            return store_pic;
    }
    else if (svc_place === svc_place_enum.mobile) {
        if (sp_profile_pic)
            return sp_profile_pic;
    }
    return null;
}

export function getTimeslotForSpecificTime(timeslots, time) {
    if (moment(time, 'hh:mm A').isValid()) {
        time = moment(time, 'hh:mm A').format('HH');
        time = parseInt(time, 10);
    }
    else if (!isNaN(time)) {
        time = parseInt(time, 10);
    }
    else {
        return null;
    }

    if (!timeslots || timeslots.constructor !== Array || timeslots.length === 0) {
        return null;
    }

    for (let i = 0; i < timeslots.length; i++) {
        let temp_timeSlot_st_num = moment(timeslots[i].timeSlot_st, 'h a').format('HH');
        temp_timeSlot_st_num = parseInt(temp_timeSlot_st_num);
        let temp_timeSlot_et_num = moment(timeslots[i].timeSlot_et, 'h a').format('kk');
        temp_timeSlot_et_num = parseInt(temp_timeSlot_et_num);
        if (time >= temp_timeSlot_st_num && time < temp_timeSlot_et_num) {
            return timeslots[i];
        }
    }

    return null;
}

export function buildClientBroadcastAddress(address) {
    let broadcast_client_add = {};
    if (!address) {
        return broadcast_client_add;
    }

    if (address.constructor !== Object) {
        address = JSON.parse(address);
    }

    if (address) {
        if (address.g_address && address.g_address.address) {
            broadcast_client_add.city = address.g_address.address.city;
            broadcast_client_add.country_code = address.g_address.address.country_code;
            broadcast_client_add.zipcode = address.g_address.address.zipcode;
        }
        if (address.g_address && address.g_address.geo_location && address.g_address.geo_location.lat && address.g_address.geo_location.lng) {
            broadcast_client_add.lat = address.g_address.geo_location.lat;
            broadcast_client_add.lng = address.g_address.geo_location.lng;
        }
    }

    return broadcast_client_add;
}

export function groupDiscountsWRTOfferedBy(discounts) {
    let data = [];

    if (discounts && discounts.constructor === Array) {
        let offered_by_sp = null, offered_by_system = null;

        discounts.forEach(discount => {
            let discount_amount = discount.discount;
            if (discount.discount_type === discount_type.pct) {
                discount_amount = discount.max_discount;
            }
            if (discount.offered_by === "sp") {
                if (!offered_by_sp) {
                    offered_by_sp = discount;
                }
                else {
                    if (offered_by_sp.discount_type === discount_type.pct && discount_amount > offered_by_sp.max_discount) {
                        offered_by_sp = discount;
                    }
                    else if (offered_by_sp.discount_type === discount_type.abs && discount_amount > offered_by_sp.discount) {
                        offered_by_sp = discount;
                    }
                }
            }
            else if (discount.offered_by === "system") {
                if (!offered_by_system) {
                    offered_by_system = discount;
                }
                else {
                    if (offered_by_system.discount_type === discount_type.pct && discount_amount > offered_by_system.max_discount) {
                        offered_by_system = discount;
                    }
                    else if (offered_by_system.discount_type === discount_type.abs && discount_amount > offered_by_system.discount) {
                        offered_by_system = discount;
                    }
                }
            }
        });

        if (offered_by_sp) {
            data.push(offered_by_sp);
        }
        if (offered_by_system) {
            data.push(offered_by_system);
        }
    }

    return data;
}

export function groupDiscountsWRTOfferedByJson(discounts) {
    const data_arr = groupDiscountsWRTOfferedBy(discounts);
    const data = {};

    data_arr.forEach(discount => {
        if (discount.offered_by) {
            data[discount.offered_by] = discount;
        }
    });

    return data;
}

export function mergedBothOfferedDiscounts(system_data, sp_data, merge_discounts = false) {
    //  merge_discounts -> boolean
    //  when true => merge discount of sp and system to get new discount.
    //          ex. sp - 50 /- ,    system - 100 /-         =>      new discount - 150/-
    //  when false => get best of sp and system discount.
    //          ex. sp - 50 /- ,    system - 100 /-         =>      best - 100/-

    // NOT USING SET BECAUSE ITS NOT COMPATIBLE WITH OLD BROWSERS.

    let service_id_arr = [], service_id_json = {};

    if (!system_data || system_data.constructor !== Object) {
        return {};
    }
    if (!sp_data || sp_data.constructor !== Object) {
        return {};
    }

    let data = {};

    Object.keys(system_data).forEach(key => {
        if (!service_id_json[key]) {
            service_id_json[key] = true;
            service_id_arr.push(key);
        }
    });
    Object.keys(sp_data).forEach(key => {
        if (!service_id_json[key]) {
            service_id_json[key] = true;
            service_id_arr.push(key);
        }
    });

    if (merge_discounts) {
        service_id_arr.forEach(key => {
            if (system_data[key] && sp_data[key]) {
                let max_discount = 0;
                if (system_data[key].discount_type === discount_type.pct) {
                    max_discount += system_data[key].max_discount;
                }
                else if (system_data[key].discount_type === discount_type.abs) {
                    max_discount += system_data[key].discount;
                }
                if (sp_data[key].discount_type === discount_type.pct) {
                    max_discount += sp_data[key].max_discount;
                }
                else if (sp_data[key].discount_type === discount_type.abs) {
                    max_discount += sp_data[key].discount;
                }
                data[key] = { ...system_data[key], max_discount: max_discount };
                data[key].discount_type = undefined;
                // 
                //  Commenting this block, because merging 2 abs discounts is complicated now.
                //  As there is max_discount related to max % of invoice.
                //  So, show upto for now.
                // 
                // if (system_data[key].discount_type !== discount_type.abs || sp_data[key].discount_type !== discount_type.abs) {
                //     data[key].discount_type = undefined;
                // }
                // else {
                //     data[key].discount = system_data[key].discount + sp_data[key].discount;
                // }
            }
            else if (system_data[key]) {
                data[key] = system_data[key];
            }
            else if (sp_data[key]) {
                data[key] = sp_data[key];
            }
        });
    }
    else {
        service_id_arr.forEach(key => {
            if (system_data[key] && sp_data[key]) {
                if (system_data[key].max_discount > sp_data[key].max_discount) {
                    data[key] = system_data[key];
                }
                else {
                    data[key] = sp_data[key];
                }
            }
            else if (system_data[key]) {
                data[key] = system_data[key];
            }
            else if (sp_data[key]) {
                data[key] = sp_data[key];
            }
        });
    }

    return data;
}

// Build json on service_id providing by sp.
// Json should contain best per service.
export function buildDiscountJsonForSearchSP(discounts, sp_services) {
    let data = {};

    if (discounts && discounts.constructor === Array && discounts.length > 0) {
        if (sp_services && sp_services.constructor === Array && sp_services.length > 0) {
            let portfolio_svc_json = {};
            sp_services.forEach(service => {
                portfolio_svc_json[service.portfolio_id] = service;
            });

            let system_data = {}, sp_data = {};

            discounts.forEach(discount => {
                let discount_amount = discount.discount;
                if (discount.discount_type === discount_type.pct) {
                    discount_amount = discount.max_discount;
                }

                if (discount.type === dcnt_types.service_id || discount.type === dcnt_types.portfolio_id) {
                    let service_id;
                    if (discount.type === dcnt_types.service_id) {
                        service_id = discount.type_id;
                    }
                    else {
                        if (portfolio_svc_json[dcnt_types.portfolio_id]) {
                            service_id = service_id.service_id;
                        }
                    }

                    if (service_id) {
                        if (discount.offered_by === offered_by.system) {
                            if (system_data[service_id]) {
                                // Check for best discount

                                if (system_data[service_id].discount_type === discount_type.pct && discount_amount > system_data[service_id].max_discount) {
                                    system_data[service_id] = discount;
                                }
                                else if (system_data[service_id].discount_type === discount_type.abs && discount_amount > system_data[service_id].discount) {
                                    system_data[service_id] = discount;
                                }
                            }
                            else {
                                system_data[service_id] = discount;
                            }
                        }
                        else if (discount.offered_by === offered_by.sp) {
                            if (sp_data[service_id]) {
                                // Check for best discount

                                if (sp_data[service_id].discount_type === discount_type.pct && discount_amount > sp_data[service_id].max_discount) {
                                    sp_data[service_id] = discount;
                                }
                                else if (sp_data[service_id].discount_type === discount_type.abs && discount_amount > sp_data[service_id].discount) {
                                    sp_data[service_id] = discount;
                                }
                            }
                            else {
                                sp_data[service_id] = discount;
                            }
                        }
                    }
                }
                else if (discount.type === dcnt_types.sp_id || discount.type === dcnt_types.category_id || discount.type === null) {
                    sp_services.forEach(service => {
                        let service_id = service.service_id;
                        if (service_id) {
                            if (discount.offered_by === offered_by.system) {
                                if (system_data[service_id]) {
                                    // Check for best discount

                                    if (system_data[service_id].discount_type === discount_type.pct && discount_amount > system_data[service_id].max_discount) {
                                        system_data[service_id] = discount;
                                    }
                                    else if (system_data[service_id].discount_type === discount_type.abs && discount_amount > system_data[service_id].discount) {
                                        system_data[service_id] = discount;
                                    }
                                }
                                else {
                                    system_data[service_id] = discount;
                                }
                            }
                            else if (discount.offered_by === offered_by.sp) {
                                if (sp_data[service_id]) {
                                    // Check for best discount

                                    if (sp_data[service_id].discount_type === discount_type.pct && discount_amount > sp_data[service_id].max_discount) {
                                        sp_data[service_id] = discount;
                                    }
                                    else if (sp_data[service_id].discount_type === discount_type.abs && discount_amount > sp_data[service_id].discount) {
                                        sp_data[service_id] = discount;
                                    }
                                }
                                else {
                                    sp_data[service_id] = discount;
                                }
                            }
                        }
                    });
                }
            });

            data = mergedBothOfferedDiscounts(system_data, sp_data, true);
        }
    }

    return data;
}

export function buildDiscountJsonForSearchSPService(discounts, sp_services, svc_id) {
    let data = {};

    if (discounts && discounts.constructor === Array && discounts.length > 0) {
        if (sp_services && sp_services.constructor === Array && sp_services.length > 0) {
            let portfolio_svc_json = {};
            sp_services.forEach(service => {
                portfolio_svc_json[service.portfolio_id] = service;
            });

            discounts.forEach(discount => {
                let discount_amount = discount.discount;
                if (discount.discount_type === discount_type.pct) {
                    discount_amount = discount.max_discount;
                }

                if (discount.offered_by) {
                    if (discount.type === dcnt_types.service_id || discount.type === dcnt_types.portfolio_id) {
                        let service_id;
                        if (discount.type === dcnt_types.service_id) {
                            service_id = discount.type_id;
                        }
                        else {
                            if (portfolio_svc_json[dcnt_types.portfolio_id]) {
                                service_id = service_id.service_id;
                            }
                        }

                        if (service_id && service_id === svc_id) {
                            if (data[discount.offered_by]) {
                                // Check for best discount

                                if (data[discount.offered_by].discount_type === discount_type.pct && discount_amount > data[discount.offered_by].max_discount) {
                                    data[discount.offered_by] = discount;
                                }
                                else if (data[discount.offered_by].discount_type === discount_type.abs && discount_amount > data[discount.offered_by].discount) {
                                    data[discount.offered_by] = discount;
                                }
                            }
                            else {
                                data[discount.offered_by] = discount;
                            }
                        }
                    }
                    else if (discount.type === dcnt_types.sp_id || discount.type === dcnt_types.category_id || discount.type === null) {
                        if (data[discount.offered_by]) {
                            // Check for best discount

                            if (data[discount.offered_by].discount_type === discount_type.pct && discount_amount > data[discount.offered_by].max_discount) {
                                data[discount.offered_by] = discount;
                            }
                            else if (data[discount.offered_by].discount_type === discount_type.abs && discount_amount > data[discount.offered_by].discount) {
                                data[discount.offered_by] = discount;
                            }
                        }
                        else {
                            data[discount.offered_by] = discount;
                        }
                    }
                }
            });
        }
    }


    let data_arr = [];

    Object.keys(data).forEach(key => {
        data_arr.push(data[key]);
    });

    return data_arr;
}

export function mergeDiscounts(discounts) {
    let data = {};

    //  Note only use max discount from this object and discount_type to identify any percentage merged or not

    if (discounts && discounts.constructor === Array) {
        let is_absolute = true;

        discounts.forEach(discount => {
            if (discount.discount_type === discount_type.pct) {
                is_absolute = false;
            }
            if (!data.max_discount) {
                data = { ...discount };
                if (data.discount_type === discount_type.abs) {
                    data.max_discount = data.discount;
                }
            }
            else {
                if (discount.discount_type === discount_type.pct) {
                    data.max_discount += discount.max_discount;
                }
                else if (discount.discount_type === discount_type.abs) {
                    data.max_discount += discount.discount;
                }
                if (!is_absolute) {
                    data.discount_type = discount_type.pct;
                }
            }
        });
    }
    else if (discounts && discounts.constructor === Object) {
        return discounts;
    }

    return data;
}

export function parseServiceItems(service_grps) {
    let data = [];
    if (service_grps) {
        service_grps.forEach(service_grp => {
            let item = { ...service_grp, is_group: true };
            item.item_name = service_grp.group_name;
            item.item_id = service_grp.item_grp_id;
            delete item.svc_itmes;
            /*
            {
              "unit": "per visit",
              "item_id": 109,
              "unit_id": 1,
              "is_global": true,
              "item_desc": "Fan Repair",
              "item_info": {
                "units_applicable": "1,2,4,5,6,7,10"
              },
              "item_name": "Fan Repair",
              "svc_item_rate": 325
            }
            */
            if (service_grp.svc_itmes && service_grp.svc_itmes[0]) {
                item.svc_items = {
                    svc_item_rate: service_grp.svc_itmes[0].svc_item_rate,
                    unit_id: service_grp.svc_itmes[0].unit_id,
                    unit: service_grp.svc_itmes[0].unit,
                };
            }
            data.push(item);
        });
    }

    return data;
}