import { CITY } from 'config/config';
import {
    city, city_list, sp_display_name, country_selected,
    is_ogcart, is_ogcare, device_id, cl_signedIn, signin_realm,
    registration_redirect_url, cl_user_id, cl_access_token
} from 'config/storage-variables';
import { TIME_UNIT_STRINGS, QUANTITY_UNIT_STRINGS } from 'components/quote-and-invoice-helpers/config';
import { PARTS_QUANTITY_LIMIT, MAX_SERVICE_ITEM_CHARGE, COUNTRY_CODE } from 'config/config';
import { discount_type } from 'scenes/sp/scenes/profile/scenes/manage-discount/config';
import { homeRoute, ogcartRoute, ogcareRoute } from 'routes-parameters/config';
import { GRS_ENV_CLIENT } from 'config/config';
import { strings } from 'strings';
import { tags } from 'tags';

const GS_HOURS_PER_DAY = 8;
const FIELD_SERVICE = "service";
const UNSIGNED_NUMBER_REGEX = /^\d*$/;

export function isJson(str) {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
}

export function isUnsignedNumber(input) {
    return UNSIGNED_NUMBER_REGEX.test(input);
}

export function getQuantityItemString(quantity, unit) {
    if (quantity && unit) {
        var quantity_item_string = "" + quantity + " ";
        var unit_string = QUANTITY_UNIT_STRINGS[unit];
        quantity_item_string += unit_string;

        return quantity_item_string;
    }
    else if (quantity) {
        return quantity;
    }
    else {
        return "--";
    }
}

export function getTimeItemString(time, unit) {
    if (time && unit) {
        if (unit === "fixed") {
            return "--"
        }
        else {
            var time_item_string = "" + time + " ";
            var unit_string = TIME_UNIT_STRINGS[unit];
            time_item_string += unit_string;

            return time_item_string;
        }
    }
    else {
        return "--";
    }
}

export function getAmountString(amount, with_symbol = true) {
    if ((amount !== undefined) && (amount !== null) && (amount >= 0)) {
        var amt_float = parseFloat(amount).toFixed(2);
        var temp_amt_string = "" + amt_float;
        var float_amt_string = temp_amt_string.split("\.");
        var str_to_format = float_amt_string[0];

        var amount_string;

        if (str_to_format.length > 3) {
            var i = str_to_format.length - 1;
            var rev_formatted_string = "" + str_to_format[i--] + str_to_format[i--] + str_to_format[i--] + ",";

            var count = 0;
            while (i >= 0) {
                if (count == 2) {
                    rev_formatted_string += "," + str_to_format[i--];
                    count = 0;
                }
                else {
                    rev_formatted_string += str_to_format[i--];
                    count++;
                }
            }

            var formatted_string = "";
            for (var j = rev_formatted_string.length - 1; j >= 0; j--) {
                formatted_string += rev_formatted_string[j];
            }

            amount_string = formatted_string;
        }
        else {
            amount_string = str_to_format;
        }

        if (float_amt_string.length > 1) {
            amount_string += "." + float_amt_string[1];
        }

        if (with_symbol) {
            return "₹ " + amount_string;
        }
        else {
            return amount_string;
        }
    }
    else {
        return "--";
    }
}

export function isQuantityIncorrect(quantity) {
    var given_quantity = parseFloat(quantity);
    if (given_quantity) {
        if ((given_quantity < 1) || (given_quantity > PARTS_QUANTITY_LIMIT)) {
            return true;
        }
        else {
            return false;
        }
    }
    else {
        return true;
    }
}

export function isTimeIncorrect(time, unit) {
    var given_time = parseFloat(time);
    if (given_time) {
        if (given_time < 0) {
            return true;
        }
        else {
            return false;
        }
    }
    else {
        return true;
    }
}

export function isTaxCorrect(tax_rate) {
    var rate = parseFloat(tax_rate);
    if (rate !== undefined) {
        if (rate >= 0 && rate <= 100) {
            return true;
        }
        else {
            return false;
        }
    }
    else {
        return false;
    }
}

export function isRateIncorrect(rate, min_allowed_rate, max_allowed_rate) {
    var given_rate = parseInt(rate);
    if (given_rate !== null && given_rate !== undefined && given_rate !== '' && given_rate.constructor === Number) {
        if ((given_rate <= (min_allowed_rate || 0)) || (given_rate > (max_allowed_rate || MAX_SERVICE_ITEM_CHARGE))) {
            return true;
        }
        else {
            return false;
        }
    }
    else {
        return true;
    }
}

export function calculateServicePrice(time, unit, rate, rate_type) {
    if (unit === 'day' && rate_type === 'hourly') {
        rate = rate * GS_HOURS_PER_DAY;
    }
    if (unit === 'hour' && rate_type === 'daily') {
        rate = parseFloat(parseFloat(rate / GS_HOURS_PER_DAY).toFixed(2));
    }

    var amount = 0;
    if (unit === 'day' || unit === 'hour') {
        amount = parseFloat(time) * parseFloat(rate);
    }
    else if (unit === 'min') {
        amount = (parseFloat(time) / 60) * parseFloat(rate);
    }
    else {
        amount = parseFloat(rate);
    }
    return parseFloat(amount.toFixed(2));
}

export function calculateHardwarePrice(quantity, rate) {
    return parseFloat((parseFloat(quantity) * parseFloat(rate)).toFixed(2));
}

export function calculateTax(tax_rate, service_total) {
    if (tax_rate && service_total) {
        var rate = parseFloat(tax_rate);
        var svc_total = parseFloat(service_total);
        var tax_total = null;
        if (rate > 0) {
            tax_total = (rate * svc_total) / 100;
        }
        return parseFloat(tax_total.toFixed(2));
    }
    else {
        return null;
    }
}

function addToBookingDuration(booking_duration, item) {
    let day = parseFloat(booking_duration.day);
    let hour = parseFloat(booking_duration.hour);
    let min = parseFloat(booking_duration.min);

    if (item.unit === 'min') {
        min += item.time;
        hour += parseInt((min / 60));
        min = min % 60;
        if ((min > 0) && (min <= 30)) {
            min = "30";
        }
        else if (min > 30) {
            min = "00";
            hour++;
        }
    }
    else if (item.unit === 'hour') {
        let temp_hour = item.time;
        let hour_int = parseInt(temp_hour);
        let hour_decimal = temp_hour - hour_int;

        hour += hour_int;

        if ((hour_decimal > 0.0) && (hour_decimal <= 0.5)) {
            let temp_min = min;
            if (min === 0) {
                temp_min = "30";
            }
            else if (min === 30) {
                temp_min = "00";
                hour++;
            }
            min = temp_min;
        }
        else if (hour_decimal > 0.5) {
            hour++;
        }
    }
    else if (item.unit === 'day') {
        let temp_day = item.time;
        let day_int = parseInt(temp_day);
        let day_decimal = temp_day - day_int;

        day += day_int;
        if (day_decimal > 0.0) {
            day++;
        }
    }

    min = "" + min;
    if (min.length === 1) {
        min = "0" + min;
    }

    hour = "" + hour;
    if (hour.length === 1) {
        hour = "0" + hour;
    }

    day = "" + day;
    if (day.length === 1) {
        day = "0" + day;
    }

    booking_duration.day = day;
    booking_duration.hour = hour;
    booking_duration.min = min;

    return booking_duration;
}

export function getBookingDuration(items) {
    var booking_duration = {
        day: "00",
        hour: "00",
        min: "00"
    }

    items.forEach(item => {
        if (item.item_type === FIELD_SERVICE) {
            booking_duration = addToBookingDuration(booking_duration, item);
        }
    })

    return booking_duration;
}

export function getDiscount(sum_total = 0, discount_obj = null) {
    if (!sum_total || !discount_obj)
        return null;

    if (discount_obj.constructor === Array) {
        let discount_amount = 0;
        discount_obj.forEach(dcnt_obj => {
            const dcnt_amount = getDiscount(sum_total - discount_amount, dcnt_obj);
            if (dcnt_amount)
                discount_amount += dcnt_amount;
        });
        return discount_amount;
    }

    if (discount_obj.constructor === Object) {
        if (!discount_obj.discount || !discount_obj.discount_type) {
            if (discount_obj.system && discount_obj.system.constructor === Object) {
                return getDiscount(sum_total, discount_obj.system);
            }
            else if (discount_obj.sp && discount_obj.sp.constructor === Object) {
                return getDiscount(sum_total, discount_obj.sp);
            }
            else {
                return null;
            }
        }

        let discount_amount = 0;

        if (discount_obj.discount_type === discount_type.pct) {
            discount_amount = sum_total * (discount_obj.discount / 100);
            if (discount_amount > discount_obj.max_discount)
                discount_amount = discount_obj.max_discount;
        }
        else {
            discount_amount = discount_obj.discount;
            const max_pct_amount = sum_total * (discount_obj.max_discount / 100);
            if (discount_amount > max_pct_amount)
                discount_amount = max_pct_amount;
            if (discount_amount > sum_total)
                discount_amount = sum_total;
        }
        return discount_amount;
    }

    return null;
}

export function getVisitingChargeObj(item_list) {
    let visiting_charge_obj = null;
    if (item_list.constructor !== Array)
        return null;
    item_list.forEach(item => {
        if (item.is_global) {
            visiting_charge_obj = item.sp_item_rate;
        }
    });
    return visiting_charge_obj;
}

export function getDiscountOfferedMessage(discount_obj, type = "string") {
    let discount_offered_message = '';

    if (!discount_obj)
        return discount_offered_message;

    if (discount_obj.constructor === Array) {
        discount_offered_message = strings.discount_offered;
        discount_obj.forEach((dcnt_obj, i) => {
            if (i > 0)
                discount_offered_message += ', ';
            discount_offered_message += getDiscountOfferedMessage(dcnt_obj, "tag");
        });
        discount_offered_message += ')';
        return discount_offered_message;
    }

    if (discount_obj.constructor === Object) {
        if (discount_obj.offered_by && discount_obj.offered_by.constructor === String) {
            const offered_by_text = discount_obj.offered_by.toLowerCase();
            if (offered_by_text === "system") {
                if (type === "tag")
                    discount_offered_message = tags.discount_offered_system;
                else
                    discount_offered_message = strings.discount_offered_system;
            }
            else if (offered_by_text === "sp") {
                if (type === "tag")
                    discount_offered_message = tags.discount_offered_sp;
                else
                    discount_offered_message = strings.discount_offered_sp;
            }
        }
    }

    return discount_offered_message;
}

export function overrideCouponCodeOverDefaultDiscount(coupon_code_obj, discount_obj) {
    // coupon code is always JSON object and discount obj can be JSON object or JSON array.

    let dcnt_obj = null;
    if (discount_obj)
        dcnt_obj = JSON.parse(JSON.stringify(discount_obj));

    if (!dcnt_obj) {
        if (coupon_code_obj && coupon_code_obj.constructor === Object && Object.keys(coupon_code_obj).length > 0)
            dcnt_obj = coupon_code_obj;
    }
    else if (coupon_code_obj && coupon_code_obj.constructor === Object && Object.keys(coupon_code_obj).length > 0) {
        if (dcnt_obj.constructor === Object) {
            if (dcnt_obj.offered_by === coupon_code_obj.offered_by) {
                dcnt_obj = coupon_code_obj;
            }
        }
        else if (dcnt_obj.constructor === Array) {
            let found = false;
            dcnt_obj.forEach((dcnt_obj_item, i) => {
                if (dcnt_obj_item && dcnt_obj_item.constructor === Object && Object.keys(dcnt_obj_item).length > 0) {
                    if (dcnt_obj_item.offered_by === coupon_code_obj.offered_by) {
                        dcnt_obj[i] = coupon_code_obj;
                        found = true;
                    }
                }
            });
            if (!found) {
                dcnt_obj.push(coupon_code_obj);
            }
        }
    }
    return dcnt_obj;
}

export function isCouponCodeReplacableToDefaultDiscount(coupon_code_obj, discount_obj, sum_total = 100000) {
    // coupon code is always JSON object and discount obj can be JSON object or JSON array.

    if (!coupon_code_obj || coupon_code_obj.constructor !== Object || Object.keys(coupon_code_obj).length === 0)
        return false;

    if (!discount_obj || (discount_obj.constructor !== Object && discount_obj.constructor !== Array))
        return true;

    if (discount_obj.constructor === Object) {
        if (coupon_code_obj.offered_by !== discount_obj.offered_by) {
            return true;
        }
        else {
            if (coupon_code_obj.discount_type === discount_obj.discount_type) {
                if (coupon_code_obj.discount_type === "abs") {
                    if (coupon_code_obj.discount > discount_obj.discount)
                        return true;
                    else return false;
                }
                else {
                    if (coupon_code_obj.discount === discount_obj.discount) {
                        if (coupon_code_obj.max_discount > discount_obj.max_discount)
                            return true;
                        else return false;
                    }
                    else {
                        const coupon_code_val = getDiscount(sum_total, coupon_code_obj);
                        const discount_val = getDiscount(sum_total, discount_obj);
                        if (!coupon_code_val)
                            return false;
                        if (!discount_val)
                            return true;
                        if (coupon_code_val > discount_val)
                            return true;
                        else return false;
                    }
                }
            }
            else {
                const coupon_code_val = getDiscount(sum_total, coupon_code_obj);
                const discount_val = getDiscount(sum_total, discount_obj);
                if (!coupon_code_val)
                    return false;
                if (!discount_val)
                    return true;
                if (coupon_code_val > discount_val)
                    return true;
                else return false;
            }
        }
    }
    else if (discount_obj.constructor === Array) {
        let temp_discount_obj = null;
        discount_obj.forEach(dcnt_obj => {
            if (dcnt_obj && dcnt_obj.constructor === Object && Object.keys(dcnt_obj).length > 0) {
                if (coupon_code_obj.offered_by === dcnt_obj.offered_by)
                    temp_discount_obj = dcnt_obj;
            }
        });
        if (!temp_discount_obj)
            return true;
        else
            return isCouponCodeReplacableToDefaultDiscount(coupon_code_obj, temp_discount_obj, sum_total);
    }
}

export function encodeUrl(url) {
    if (url) {
        url = url.toLowerCase();
        url = url.trim();
        url = url.replace(/\ /g, '-');
    }
    return url;
}

export function isSameAsEncodeUrl(url, checkString) {
    if (checkString) {
        checkString = checkString.toLowerCase();
        checkString = checkString.trim();
        checkString = checkString.replace(/\ /g, '-');
    }
    if (url)
        url = url.trim();
    if (url === checkString)
        return true;
    return false;
}

export function setCookie(b, h, c, f, e) {
    var a;
    if (c === 0) {
        a = ""
    } else {
        var g = new Date();
        g.setTime(g.getTime() + (c * 24 * 60 * 60 * 1000));
        a = "expires=" + g.toGMTString() + "; "
    }
    let x = (typeof e === "undefined") ? "" : "; domain=" + e;
    document.cookie = b + "=" + h + "; " + a + "path=" + f + x
}

export function getCookie(d) {
    var b = d + "=";
    var a = document.cookie.split(";");
    for (var e = 0; e < a.length; e++) {
        var f = a[e].trim();
        if (f.indexOf(b) == 0) {
            return f.substring(b.length, f.length)
        }
    }
    return ""
}

export function deleteCookie(name) {
    document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:01 GMT;';
}

export function parseCityData(city_details) {
    return {
        name: city_details.city,
        id: city_details.id,
        show_new_home: true,
        ...parseDisplayFeatures(city_details)
    };
}

export function getDefaultCity() {
    let city_details = {};
    let value = CITY;
    let city_list1 = [], selected_city;
    if (localStorage.getItem(city)) {
        selected_city = JSON.parse(localStorage.getItem(city));
        value = selected_city.name;
    }
    if (sessionStorage.getItem(city_list)) {
        city_list1 = JSON.parse(sessionStorage.getItem(city_list));
    }
    if (city_list1 && city_list1.length > 0) {
        city_list1.forEach(list => {
            if (value && value.constructor === String && list.city && list.city.constructor === String && value.toLowerCase() === list.city.toLowerCase()) {
                city_details = parseCityData(list);
            }
        });

        // If city not found list. Select first city
        if (Object.keys(city_details).length === 0) {
            if (selected_city && selected_city.name) {
                return selected_city;
            }
            else {
                city_details = parseCityData(city_list1[0]);
            }
        }

        // localStorage.setItem(city, JSON.stringify(city_details));
        return city_details;
    }
    else if (selected_city && selected_city.name) {
        return selected_city;
    }
    else
        return null;
}

export function updateProfileIntro(profile_intro) {
    let updated_profile_intro = profile_intro;
    if (localStorage.getItem(sp_display_name))
        updated_profile_intro = updated_profile_intro.replace('$DISPLAY_NAME$', localStorage.getItem(sp_display_name));
    if (sessionStorage.getItem("sp_reg_personal_info")) {
        let name = JSON.parse(sessionStorage.getItem("sp_reg_personal_info"));
        if (name.full_name)
            updated_profile_intro = updated_profile_intro.replace('$FULL_NAME$', name.full_name);
    }

    if (sessionStorage.getItem("sp_reg_personal_info")) {
        let exp = JSON.parse(sessionStorage.getItem("sp_reg_personal_info"));

        if (exp.work_exp)
            updated_profile_intro = updated_profile_intro.replace('$WORK_EXP$', exp.work_exp);
    }
    if (sessionStorage.getItem("sp_reg_service_category_name")) {
        let category = sessionStorage.getItem("sp_reg_service_category_name");

        updated_profile_intro = updated_profile_intro.replace('$CATEGORY$', category);
    }
    if (sessionStorage.getItem("sp_reg_selected_services")) {
        let services = JSON.parse(sessionStorage.getItem("sp_reg_selected_services"))
        let arr = [];
        if (services) {
            Object.keys(services).forEach(ser => {
                if (services[ser] && services[ser].name)
                    arr.push(services[ser].name)
            })

            let service_name = '';
            if (arr.length > 0)
                service_name = arr.join(",");
            if (service_name)
                updated_profile_intro = updated_profile_intro.replace('$SERVICE$', service_name);
        }
    }
    if (sessionStorage.getItem("sp_service_location_center_current")) {
        let location = JSON.parse(sessionStorage.getItem("sp_service_location_center_current"))
        if (location && location.g_address && location.g_address.address) {
            let g_address = location.g_address.address
            if (g_address.city)
                updated_profile_intro = updated_profile_intro.replace('$CITY$', g_address.city);
            if (g_address.locality)
                updated_profile_intro = updated_profile_intro.replace('$LOCALITY$', g_address.locality);

        }
    }
    return updated_profile_intro;

}

export function isNullOrEmptyObject(javascriptObject) {
    if (javascriptObject && (javascriptObject.constructor === Object) && (Object.keys(javascriptObject).length > 0)) {
        return false;
    }

    return true;
}

export function isNullOrEmptyArray(javascriptArray) {
    if (javascriptArray && (javascriptArray.constructor === Array) && (javascriptArray.length > 0)) {
        return false;
    }

    return true;
}

export function isString(object) {
    return object && typeof object === "string";
}

export function isNumber(object) {
    return (object || object === 0) && typeof object === "number";
}

export function isBoolean(object) {
    return object && typeof object === "boolean";
}

export function isArray(object) {
    return object && object.constructor === Array;
}

export function mergeObjects(originalObj, overrideObj) {
    let original = isNullOrEmptyObject(originalObj) ? {} : { ...originalObj };
    let override = isNullOrEmptyObject(overrideObj) ? {} : { ...overrideObj };
    let required = {};

    for (const key of Object.keys(override)) {
        if (isString(override[key]) || isNumber(override[key]) || isBoolean(override[key]) || isArray(override[key])) {
            required[key] = override[key];
        } else {
            required[key] = mergeObjects(original[key], override[key]);
        }
    }

    for (const key of Object.keys(original)) {
        if (!required[key]) {
            required[key] = original[key];
        }
    }

    return required;
}

export function sortObject(data, keyPriority = []) {
    if (typeof data !== "object") {
        return data;
    }

    if (!keyPriority || keyPriority.constructor !== Array) {
        return data;
    }

    let keys = Object.keys(data);
    let sorted_keys = [];
    keyPriority.forEach(key => {
        if (data[key]) {
            sorted_keys.push(key);
        }
    });
    keys.forEach(key => {
        if (!sorted_keys.includes(key)) {
            sorted_keys.push(key);
        }
    });

    let sorted_data = {};

    sorted_keys.forEach(key => {
        sorted_data[key] = data[key];
    });

    return sorted_data;
}

function isInt(number) {
    return number % 1 === 0;
}

export function displayWithCurrency(number, countryCode = undefined) {
    if (!isNumber(number)) {
        return "";
    }

    let code = countryCode;
    if (!code) {
        if (localStorage.getItem(country_selected)) {
            code = localStorage.getItem(country_selected).toLowerCase();

        } else {
            code = "in";
        }
    }

    if (code === COUNTRY_CODE.USA) {
        return number.toLocaleString('en-US', {
            maximumFractionDigits: 2,
            style: 'currency',
            currency: 'USD'
        })

    } else {
        let reqStr = number.toLocaleString('en-IN', {
            maximumFractionDigits: 2,
            style: 'currency',
            currency: 'INR'
        })

        if (isInt(number)) {
            return reqStr.slice(0, -3);
        } else {
            return reqStr;
        }

    }
}

export function getCurrencySymbol(countryCode = undefined) {
    let code = countryCode;
    if (!code) {
        if (localStorage.getItem(country_selected)) {
            code = localStorage.getItem(country_selected).toLowerCase();

        } else {
            code = "in";
        }
    }

    if (code === COUNTRY_CODE.USA) {
        return "$";
    } else {
        return "₹";
    }
}

export function parseDisplayFeatures(city) {
    return {
        show_product: !!city.show_product,
        show_service: !!city.show_service,
        show_society: !!city.show_society,
    }
}

export function isDNhoodEnv() {
    return !!process.env.REACT_APP_DNHOOD_ENV;
}

export function getDisplayFeatures() {
    let city_details = getDefaultCity();
    return parseDisplayFeatures(city_details || {});
}

export function isOGCart() {
    return !!sessionStorage.getItem(is_ogcart);
}

export function isOGCare() {
    return !!sessionStorage.getItem(is_ogcare);
}

export function getEnvHomeRoute() {
    // If ogcart then ogcartroute, ogcare then ogcareroute else homeRoute

    const is_ogcart = isOGCart();
    const is_ogcare = isOGCare();

    if (is_ogcart) {
        return ogcartRoute;
    }
    else if (is_ogcare) {
        return ogcareRoute;
    }
    else {
        return homeRoute;
    }
}

export function isMobile() {
    let is_mobile = false;

    try {
        is_mobile = !!((window.navigator && window.navigator.standalone) || navigator.userAgent.match('CriOS') || navigator.userAgent.match(/mobile/i));
    } catch (ex) {
        // continue regardless of error
    }

    return is_mobile;
};

export function isNativeAndroidApp() {
    // Check REACT_APP_GRS_ENV === GRS_ENV_CLIENT and device_id starts with 'gRs-an'
    if (process.env.REACT_APP_GRS_ENV === GRS_ENV_CLIENT) {
        const device_id_ls = localStorage.getItem(device_id);
        if (device_id_ls && device_id_ls.startsWith('gRs-an-cl')) {
            return true;
        }
    }

    return false;
}

export function isNativeIOSApp() {
    // Check REACT_APP_GRS_ENV === GRS_ENV_CLIENT and device_id starts with 'gRs-ios'
    if (process.env.REACT_APP_GRS_ENV === GRS_ENV_CLIENT) {
        const device_id_ls = localStorage.getItem(device_id);
        if (device_id_ls && device_id_ls.startsWith('gRs-ios-cl')) {
            return true;
        }
    }

    return false;
}

export function compareNumberArray(array1, array2) {
    if (array1.length !== array2.length) {
        return false;
    }
    let set1 = new Set(array1), set2 = new Set(array2);
    let flag = true;
    set1.forEach(elem => {
        if (!set2.has(elem)) {
            flag = false;
        }
    });
    return flag;
}

export const calculateCouponDiscount = (coupon_obj, cartItemObj, itemTotal) => {
    if (!coupon_obj || !Object.keys(cartItemObj).length) {
        return 0;
    }

    let newItemTotal = itemTotal;

    const discount_rules = coupon_obj.discount_rules || {};
    if (discount_rules.cat_inc || discount_rules.cat_exc || discount_rules.item_inc || discount_rules.item_exc) {
        const cat_inc_list = (discount_rules.cat_inc || '').split(",").map(cat => parseInt(cat, 10));
        const cat_exc_list = (discount_rules.cat_exc || '').split(",").map(cat => parseInt(cat, 10));
        const item_inc_list = (discount_rules.item_inc || '').split(",").map(item => parseInt(item, 10));
        const item_exc_list = (discount_rules.item_exc || '').split(",").map(item => parseInt(item, 10));

        newItemTotal = 0;

        Object.keys(cartItemObj).forEach(product_id => {
            const item_id = parseInt(product_id, 10);

            if (
                (cat_inc_list.includes(cartItemObj[product_id].cat_id) || item_inc_list.includes(item_id)) &&
                (!cat_exc_list.includes(cartItemObj[product_id].cat_id) && !item_exc_list.includes(item_id))
            ) {
                if (cartItemObj[product_id].customizations) {
                    cartItemObj[product_id].customizations.forEach(customization => {
                        newItemTotal += customization.price * customization.quantity;
                    });
                }
            }
        });
    }

    if (discount_rules.min_order_amount && itemTotal < discount_rules.min_order_amount) {
        return 0;
    }

    if (newItemTotal == 0) {
        return 0;
    }

    if (coupon_obj.discount_type === 'abs') {
        let coupon_discount = newItemTotal < coupon_obj.max_discount ? newItemTotal : coupon_obj.max_discount;
        coupon_discount = Math.round(coupon_discount * 100) / 100;
        return coupon_discount;
    }
    else if (coupon_obj.discount_type === 'pct') {
        let coupon_discount = (newItemTotal * coupon_obj.discount) / 100;
        if (coupon_discount > coupon_obj.max_discount) {
            coupon_discount = coupon_obj.max_discount;
        }

        coupon_discount = Math.round(coupon_discount * 100) / 100;

        return coupon_discount;
    }

    return 0;
}

export function parseAddress(address) {
    let ad = "";
    if (!address) {
        return ad;
    }

    if (address.g_address.address.street) {
        ad += address.g_address.address.street;
    }
    if (address.g_address.address.locality) {
        if (ad) {
            ad += ", ";
        }
        ad += address.g_address.address.locality;
    }
    if (address.g_address.address.landmark) {
        if (ad) {
            ad += ", ";
        }
        ad += address.g_address.address.landmark;
    }
    if (address.g_address.address.city) {
        if (ad) {
            ad += ", ";
        }
        ad += address.g_address.address.city;
    }
    if (address.g_address.address.zipcode) {
        if (ad) {
            ad += ", ";
        }
        ad += address.g_address.address.zipcode;
    }

    return ad;
}


export const calculateDeliveryCharges = (delivery_rules, itemTotal, deliveryDistance) => {
    if (!delivery_rules) {
        return [0, 0];
    }

    let delivery_charges = 0, flag = false, delivery_tax = 0;

    for (let i = 0; i < delivery_rules.delv_rates.length; i++) {
        if (flag) {
            break;
        }
        const delv_rate = delivery_rules.delv_rates[i];
        if ((delv_rate.min_amount ? delv_rate.min_amount <= itemTotal : true) || (delv_rate.max_amount ? delv_rate.max_amount >= itemTotal : true)) {
            for (let i = 0; i < delv_rate.rates.length; i++) {
                const rate = delv_rate.rates[i];
                if (deliveryDistance === undefined || ((rate.min_dist ? rate.min_dist <= deliveryDistance : true) && (rate.max_dist ? rate.max_dist >= deliveryDistance : true))) {
                    delivery_charges = (
                        rate.type === "fixed" ? rate.rate
                            : rate.type === "perkm" ? (deliveryDistance || 0) * rate.rate
                                : 0
                    );

                    if (rate.min_rate && delivery_charges < rate.min_rate) {
                        delivery_charges = rate.min_rate;
                    }
                    flag = true;
                    break;
                }
            }
        }
    }

    if (delivery_charges && delivery_rules.tax_items && delivery_rules.tax_items.length > 0) {
        delivery_rules.tax_items.forEach(tax_item => {
            if (tax_item.tax_type === "abs") {
                delivery_tax += tax_item.value;
            } else {
                let tax = (delivery_charges * tax_item.value / 100);
                tax = Math.round(tax * 100) / 100;
                delivery_tax += tax;
            }
        });
    }

    return [delivery_charges, delivery_tax];
}


export const isForceDecimal = (number) => Number.isInteger(parseFloat(`${number}`));

export const convertToPrice = (rate) => {
    if (typeof rate === "number") {
        rate = `${rate}`;
    }

    return !isForceDecimal(rate) ? parseFloat(rate) : parseInt(rate, 10);
}

export const parsePrice = (rate) => {
    if (typeof rate === "number") {
        rate = `${rate}`;
    }

    return !isForceDecimal(rate) ? parseFloat(rate).toFixed(2) : `${parseInt(rate, 10)}`;
}

export const isClUserSignedIn = () => localStorage.getItem(cl_signedIn) === 'true';

export const setUpAuthRedirect = (redirectUrl) => {
    sessionStorage.setItem(signin_realm, 'svc_consumer');
    sessionStorage.setItem(registration_redirect_url, redirectUrl);
}


export const selectCouponDiscount = (discount_list, cartItemObj, itemTotal) => {
    if (!discount_list || discount_list.length === 0) {
        return undefined;
    }

    let coupon_obj = undefined;
    let max_coupon_discount = 0;

    discount_list.forEach(discount => {
        const coupon_discount = calculateCouponDiscount(discount, cartItemObj, itemTotal);
        if (coupon_discount > max_coupon_discount) {
            coupon_obj = discount;
            max_coupon_discount = coupon_discount;
        }
    });

    return coupon_obj;
}