import { APP_VERSION } from './config/config';

import { getIndexDB, setObjectIndexDB, deleteObjectIndexDB } from './utils/indexeddb';

export function updateAppVersionIndexedDB() {
    var dbPromise = getIndexDB();

    dbPromise
        .then(db => {
            setObjectIndexDB(db, { APP_VERSION })
                .then(res => { })
                .catch(err => { });
        })
        .catch(err => { });
}

export function updateLastSeenIndexedDB() {
    var dbPromise = getIndexDB();

    dbPromise
        .then(db => {
            setObjectIndexDB(db, { LAST_SEEN: new Date().toISOString() })
                .then(res => { })
                .catch(err => { });
        })
        .catch(err => { });
}

export function updateDeviceIdIndexedDB(device_id) {
    var dbPromise = getIndexDB();

    dbPromise
        .then(db => {
            setObjectIndexDB(db, { DEVICE_ID: device_id })
                .then(res => { })
                .catch(err => { });
        })
        .catch(err => { });
}

export function addUserIdIndexedDB(user_id, acces_token) {
    var dbPromise = getIndexDB();

    dbPromise
        .then(db => {
            setObjectIndexDB(db, { USER_ID: user_id, ACCESS_TOKEN: acces_token })
                .then(res => { })
                .catch(err => { });
        })
        .catch(err => { });
}

export function deleteUserIdIndexedDB() {
    var dbPromise = getIndexDB();

    dbPromise
        .then(db => {
            deleteObjectIndexDB(db, ["USER_ID", "ACCESS_TOKEN"])
                .then(res => { })
                .catch(err => { });
        })
        .catch(err => { });
}