import React from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faIdBadge, faPhone, faAlignLeft } from '@fortawesome/free-solid-svg-icons'
import EmergencyIcon from '../../assets/policeman.svg'
import { gateApprovalsRoute, intercomRoute, pollsRoute, emergencyRoute } from 'routes-parameters/config'

import ActionMyPass from './action-my-pass'
import ActionShare from './action-share'

import { UI_CONFIG, getUIOverride, UI_DISPLAY_TYPES } from '../../config';
import gs_color from 'config/colors.css'
import './styles.css'

function QuickActions({ history, selectedCommunityCode, userDetails, authCode, society_ui_config, isBoardMember }) {
    const { profile_pic, display_name } = (userDetails || {});
    const ui_config = society_ui_config || {};

    return (
        <div className="quick-actions">
            {
                !isBoardMember &&
                [UI_DISPLAY_TYPES.SHOW, UI_DISPLAY_TYPES.DISABLE].includes(getUIOverride(UI_CONFIG.visitors, ui_config).display) &&
                [UI_DISPLAY_TYPES.SHOW, UI_DISPLAY_TYPES.DISABLE].includes(getUIOverride(UI_CONFIG.my_pass, ui_config).display) &&
                <ActionMyPass />
            }
            {
                [UI_DISPLAY_TYPES.SHOW, UI_DISPLAY_TYPES.DISABLE].includes(getUIOverride(UI_CONFIG.visitors, ui_config).display) &&
                (
                    (isBoardMember && [UI_DISPLAY_TYPES.SHOW, UI_DISPLAY_TYPES.DISABLE].includes(getUIOverride(UI_CONFIG.gate_approvals_board, ui_config).display)) ||
                    (!isBoardMember && [UI_DISPLAY_TYPES.SHOW, UI_DISPLAY_TYPES.DISABLE].includes(getUIOverride(UI_CONFIG.gate_approvals, ui_config).display))
                ) &&
                <ActionGateApprovals history={history} title={getUIOverride(UI_CONFIG.gate_approvals, ui_config).title} />
            }
            <ActionEmergency history={history} />
            {
                [UI_DISPLAY_TYPES.SHOW, UI_DISPLAY_TYPES.DISABLE].includes(getUIOverride(UI_CONFIG.e_intercom, ui_config).display) &&
                getUIOverride(UI_CONFIG.e_intercom, ui_config).display === UI_DISPLAY_TYPES.SHOW &&
                (
                    (isBoardMember && [UI_DISPLAY_TYPES.SHOW, UI_DISPLAY_TYPES.DISABLE].includes(getUIOverride(UI_CONFIG.board_e_intercom_feature, ui_config).display)) ||
                    (!isBoardMember && [UI_DISPLAY_TYPES.SHOW, UI_DISPLAY_TYPES.DISABLE].includes(getUIOverride(UI_CONFIG.resident_e_intercom_feature, ui_config).display))
                ) &&
                <ActionIntercom history={history} title={getUIOverride(UI_CONFIG.e_intercom, ui_config).title} />
            }
            {
                [UI_DISPLAY_TYPES.SHOW, UI_DISPLAY_TYPES.DISABLE].includes(getUIOverride(UI_CONFIG.poll, ui_config).display) &&
                getUIOverride(UI_CONFIG.poll, ui_config).display === UI_DISPLAY_TYPES.SHOW &&
                (
                    (isBoardMember && [UI_DISPLAY_TYPES.SHOW, UI_DISPLAY_TYPES.DISABLE].includes(getUIOverride(UI_CONFIG.board_poll, ui_config).display)) ||
                    (!isBoardMember && [UI_DISPLAY_TYPES.SHOW, UI_DISPLAY_TYPES.DISABLE].includes(getUIOverride(UI_CONFIG.resident_poll, ui_config).display))
                ) &&
                < ActionPolls history={history} title={getUIOverride(UI_CONFIG.poll, ui_config).title} />
            }
        </div>
    )
}

function ActionGateApprovals({ history, title }) {
    return (
        <div className="action-outer" style={{ maxWidth: '100px' }} onClick={() => history.push(`/${gateApprovalsRoute}`)}>
            <div className="action-icon-outer">
                <div className="action-icon action-icon-background">
                    <FontAwesomeIcon className='svg-inline--fa' icon={faIdBadge} color={gs_color.accentColor} style={{ height: '14px', width: '14px' }} />
                </div>
            </div>
            <div className="action-label">
                {title || "Approvals"}
            </div>
        </div>
    )
} 

function ActionIntercom({ history, title }) {
    return (
        <div className="action-outer" style={{ maxWidth: '120px' }} onClick={() => history.push(`/${intercomRoute}`)}>
            <div className="action-icon-outer">
                <div className="action-icon action-icon-background">
                    <FontAwesomeIcon className='svg-inline--fa' icon={faPhone} color={gs_color.accentColor} style={{ height: '14px', width: '14px' }} />
                </div>
            </div>
            <div className="action-label">
                {title || "E-Intercom"}
            </div>
        </div>
    )
}

function ActionPolls({ history, title }) {
    return (
        <div className="action-outer" onClick={() => history.push(`/${pollsRoute}`)}>
            <div className="action-icon-outer">
                <div className="action-icon action-icon-background">
                    <FontAwesomeIcon className='svg-inline--fa' icon={faAlignLeft} color={gs_color.accentColor} style={{ height: '14px', width: '14px' }} />
                </div>
            </div>
            <div className="action-label">
                {title || "Polls"}
            </div>
        </div>
    )
}

function ActionEmergency({ history }) {
    return (
        <div className="action-outer" style={{ maxWidth: '100px' }} onClick={() => history.push(`/${emergencyRoute}`)}>
            <div className="action-icon-outer">
                <div className="action-icon action-icon-background-dark" style={{ backgroundColor: gs_color.gradientDarkColor }}>
                    <img src={EmergencyIcon} style={{ height: '14px', width: '14px' }} />
                </div>
            </div>
            <div className="action-label">
                Emergency
            </div>
        </div>
    )
}

export default QuickActions