import React from 'react';
import NotFoundIcon from 'scenes/not-found/assets/NotFound.svg';
import { strings } from 'strings';

function NotFound(props) {
    return (
        <div>
            <div style={{ paddingTop: "150px", textAlign: "center" }}>
                <img width="82px" height="82px" src={NotFoundIcon} alt="Not Found" />
                <div style={{ paddingTop: '20px', fontWeight: '500', fontSize: '17px', visibility: props.hideErrorTitle ? 'hidden' : 'unset' }}>404 Not Found</div>
                <div style={{ paddingTop: '10px', fontSize: '13px' }}>{props && props.error && props.error.message && props.error.message.constructor === String ? props.error.message : strings.error_404_default_string}</div>
            </div>
        </div>
    );
}

NotFound.displayName = 'NotFound';
export default NotFound;