import { encodeUrl } from "utils/helper-functions";
import { getProductSubCategories } from '../api';

export const productSubCategoryApiAdapter = subCatList => {
  let subCatData = {};

  if (subCatList && subCatList.constructor === Array && subCatList.length > 0) {
    for (const subCategory of subCatList) {
      if (!subCatData[subCategory.pcat_id]) {
        subCatData[subCategory.pcat_id] = {}
      }

      subCatData[subCategory.pcat_id][subCategory.pscat_id] = subCategory
    }
  }

  return subCatData;
}

export const getSubCategory = (city_id, pcat_id, pscat_name, pscat_data, pscat_cache) => {
  const name = encodeUrl(pscat_name);

  return new Promise((resolve, reject) => {
    const allSubCatData = pscat_cache && pscat_cache.city_id === city_id && pscat_data;
    const subCatData = allSubCatData && allSubCatData[pcat_id];

    if (subCatData && Object.keys(subCatData).length > 0) {
      resolve(searchSubCategory(subCatData, name))
    } else {
      getProductSubCategories(city_id, (res, err) => {
        if (res) {
          resolve(searchSubCategory(res[pcat_id], name))
        } else {
          resolve(null)
        }
      })
    }
  });

}

function searchSubCategory(subCatData, name) {
  for (const key of Object.keys(subCatData)) {
    if (encodeUrl(subCatData[key].pscat_name) === name) {
      return subCatData[key];
    }
  }

  return null;
}

export function dummyPSCatApiData() {
  return [
    {
      "pscat_id": 1,
      "pscat_name": "Lentile",
      "pscat_desc": "Lentile",
      "pcat_id": "1"
    },
    {
      "pscat_id": 2,
      "pscat_name": "Rice",
      "pscat_desc": "Rice",
      "pcat_id": "1"
    },
    {
      "pscat_id": 3,
      "pscat_name": "Dairy Product",
      "pscat_desc": "Dairy Product",
      "pcat_id": "3"
    },
    {
      "pscat_id": 4,
      "pscat_name": "Fresh Dairy",
      "pscat_desc": "Fresh Dairy",
      "pcat_id": "3"
    },
    {
      "pscat_id": 5,
      "pscat_name": "Spices",
      "pscat_desc": "Spices",
      "pcat_id": "1"
    },
    {
      "pscat_id": 6,
      "pscat_name": "Tea",
      "pscat_desc": "Tea",
      "pcat_id": "11"
    },
    {
      "pscat_id": 7,
      "pscat_name": "Egg Product",
      "pscat_desc": "Egg Product",
      "pcat_id": "3"
    },
    {
      "pscat_id": 8,
      "pscat_name": "Health Drinks",
      "pscat_desc": "Health Drinks",
      "pcat_id": "11"
    },
    {
      "pscat_id": 9,
      "pscat_name": "Soft Drink",
      "pscat_desc": "Soft Drink",
      "pcat_id": "11"
    },
    {
      "pscat_id": 10,
      "pscat_name": "Engery Drink",
      "pscat_desc": "Engery Drink",
      "pcat_id": "11"
    },
    {
      "pscat_id": 11,
      "pscat_name": "Cofee",
      "pscat_desc": "Cofee",
      "pcat_id": "11"
    },
    {
      "pscat_id": 12,
      "pscat_name": "Fresh Eggs",
      "pscat_desc": "Fresh Eggs",
      "pcat_id": "3"
    }
  ]
}