export const PollType = {
    SURVEY: "survey",
    DECISION: "decision"
}

export const PollParticipantTypes = {
    RESIDENT: 'resident',
    PROPERTY: 'property'
}

export const PollStatus = {
    ONLINE: 'online',
    CLOSED: 'closed',
    SCHEDULE: 'schedule'
}