import { isDNhoodEnv } from 'utils/helper-functions';

//scenes/client/scenes/profile/components/profile-view/index.js
const edit_profile_button_label = "Profile";
const share_refferal_button = "My Referrals";
const logout_button = "Logout";
const my_booking_history_button = "My Booking History";

//scenes/client/scenes/profile/scenes/edit-profile/index.js
const change_photo = "Change Photo";
const public_info = "Public Information";
const private_info = "Private Information";
const change_pass = "Change Password"
const my_address = "My Address"

//scenes/client/scenes/profile/scenes/edit-profile/scenes/change-password/index.js
const current_pass = "Current Password";
const new_pass = "New Password";
const confirm_pass = "Confirm Password";

//client/scenes/profile/scenes/edit-profile/scenes/change-phone/index.js
const change_phone = "Change Phone Number";
const olagate = "OlaGate";
const mobile_no = "Mobile Number";
const logout = "LOGOUT";

//client/scenes/profile/scenes/edit-profile/scenes/change-email/index.js
const change_email = "Change Email Address";
const add_email = "Add Email Address";
const new_email = "New Email Address";

//client/scenes/profile/scenes/edit-profile/scenes/index.js
const display_name = "Display Name";
const primary_phone = "Primary Phone";
const email_id = "Email Id";
const secondary_phone = "Secondary Phone Number";
const full_name = "Full Name";
const dob = "Date of Birth";
const gender = "Gender";
const edit_profile = "Edit Profile";
const change_private_info = "Change your Private Information"
const change_public_info = "Change your Public Information"
const change_profile_info = "Change your Profile Information"

//src/components/edit-information/index.js
const save = "Save Changes";
const discard = "Discard";
const edit = "Edit";

const ok = "Ok";
const cancel = "Cancel";
const close = "Close";
const done = "Done";
//scenes/client/scenes/authentication/scenes/forgot-password/index.js
const email_phone = "Email or Mobile No";
const resend = "RESEND";
const send_messg = "Send Message";
const send_mail = "Send Mail";

//not-signed-in page
const know_more = "KNOW MORE";

//scenes/client/scenes/authentication/scenes/sign-in/components/sign-in-component/index.js
const no_account = "Don't Have an Account";
const register = "Register Here";
const cont = "Continue With :";
const google = "Google";
const facebook = "Facebook";
const login = "Sign in with";
const password = "Password";
const forgot_pass = "Forgot Password ?";
const sign_in = "Sign In";
const sign_in_with_otp = "Sign In with OTP";
const otp = "OTP";
const sign_in_or_register = "Sign In / Register as Service Provider";

//client/scenes/authentication/scenes/sign-up/scenes/sign-up-client or sign-up-sp
const consumer = "CONSUMER";
const referral = "Referral code";
const terms_service = "Terms of Service";
const privacy_policy = "Privacy Policy";
const get_verification_link = "Get OTP/Link";
const service_provider = "SERVICE PROVIDER";
const phone_number = "Phone Number";
const email = "Email Address";
const resend_code = "RESEND CODE";
const og_blog = "OGBlog";

//scenes/client/scenes/home/scenes/property-address/index.js
//Home screen tags or labels
const tap_on = "Tap on";
const add_or_edit_add = "ADD/EDIT ADDRESS";
const upcoming_services = "UPCOMING SERVICES";
const show = "Show";
const more = "More";
const less = "Less";
const search_service = "Search Service";
const show_on_map = "Show on Map";
const remove = "Remove";
const edit_address = "Edit Property Address";
const address = "Addresses";
const property_address = "Property Address Location";
const landmark = "Landmark:";
const loading_map = "Loading Map";
const save_address = "SAVE ADDRESS";
const enter_zip = "Enter your zipcode";

// About US
const about_us = "About Us";
const faq = "FAQ's";
const feedback = "Feedback";
const license = "Open Source Licences";
const version = "Version v";
const about = "About";
const click_here = "Click Here";
const info_mail = "hello@olagate.com";

const property_address_tag = "PROPERTY ADDRESS";
const state_reason = "Reason";
const other_reason = "Other Reason";
const comment = "Comments";
const submit = "Submit";

const book_service = "Booking Serviced"
const book_serv = "Book Service"
const confirm = "CONFIRM"
const processing = "PROCESSING";
const accept = "ACCEPT";
const booking_expired = "Booking expired";
const reshedule_booking = "Reschedule booking";
const time_acceptance = "New Time Acceptance";
const confirmed = "Confirmed";
const out_service = "Out for Service";
const serviced = "Serviced";
const booked = "Booked";
const expired = "Expired";
const accepted = "Accepted";
const quote = "Quote";
const send_quote = "Send Quote";
const quote_sent = "Quote Sent";
const quote_revision = "Quote Revision Requested";
const quote_revision_sent = "Quote Revision Sent";
const rescheduled = "Reschedule";
const completed = "Completed";
const settled = "Settled";
const canceled = "Canceled";
const declined = "Declined";
const indispute = "Indispute";
const rejected = "Rejected";
const review_quote = "Review your Quote";
const days = "Days";
const hours = "Hours";
const mins = "Mins";
const today = "TODAY";
const upcoming = "UPCOMING"

//writre_review, submitted review
const edit_review = "Edit your reviews";
const char_left = "Characters Remaining";
const type_message = "Type your message here";
const camera = "Camera";
const video = "Video";
const audio = "Audio";
const images_upload = "Images to upload :"
const video_upload = "Video to upload :";
const audio_upload = "Audio to upload :";
const share_review = "Please share your reviews"
const drop_review = "Drop a Review";
const rate_cl = "Rate this Client"
const rate_sp = "Rate this Service Provider";
const write_review = "Write Review"

const select_timeslot = "Select Timeslot";
const select_service_groups = "Select Service Groups";
const select_service = "Select Service";

//booking/cart
const booking_history = "Booking History";
const final_details = "Final Booking Details";
const selected_date = "Selected Date";
const selected_items = "Items Selected";
const items = "Items";
const rate = "Rate";
const quantity = "Quantity";
const item_total = "Item Total";
const service_requirement = "Service Requirements";
const uploaded_images = "Uploaded Images";

const service_items = "Service Items";
const continue_tag = "Continue";
const service_details = "Service Items details or description";
const greater_than_zero = "Invalid Quantity";
const other_items = "Other Items Provided by SP";
const add_photo = "ADD PHOTO";
const add = "ADD";
const requested_date = "Your Requested Date";
const available_date = "Other Available Dates";
const profile_intro = " Service Provider Brief profile intro.";
const expertise = "Expertise";
const book_a_slot = "BOOK A SLOT";
const book = "Book";
const sp_details = "Service Provider Details";
const yr_exp = "Years Experience";
const review = "Review";
const intro = "Introduction";
const show_breakdown = "Show Breakdown";

//link-expired
const go_to_signin = "Go To Signin";
const regenerate_link = "Regenerate link";
const client_info = "Client Information";
const sp_info = "Service Provider Information";
const reschedule_app = "Reschedule Appointment";
const booking_detail = "Booking Details";
const selected_items_list = "Selected Items List";
const decline = "Decline";
const agree = "Agree";
const payment_rec = 'Payment Recieved';
const no = "No";
const yes = "Yes";
const req_invoice = 'Request Invoice';
const sug_invoice = 'Invoice';
const invoice = 'Invoice';
const request = "Request";
const revise_invoice = 'Revise Invoice';
const gen_invoice = 'Generate Invoice'
const my_review = "My Review";
const sp_review = "SP's Review";
const client_review = "Client's Review";
const reject_book = 'Reject Booking';
const cancel_book = 'Cancel Booking';
const scheduled = "scheduled";
const replaced = "replaced";
const passover = "passover";
const uploaded_media = "Uploaded Media";
const accept_resch = "ACCEPT RESCHEDULE BOOKING";
const accept_book = "ACCEPT BOOKING";
const warning = "Warning";

//invoice, quote
const add_item = "Add Item";
const sub_total = "Sub-Total";
const edit_invoice = "Edit Invoice";
const send = "Send";
const got_it = "Got it";
const service_charges = "Service Charges";
const qty = "Qty";
const amount = "Amount"
const other_charges = "Other Charges";
const discount = "Discount"
const total = "Total";
const view_all = "View All";
const view_less = "View Less";
const submit_invoice = "Submit Invoice";
const note_client = "Note from Client";
const invoice_id = "Invoice Id:";
const serviced_on = "Serviced On:";
const bill_to = "Bill To:";
const bill_from = "Bill From:";
const service_add = "Service Address:";
const ph = "PH:";
const gstn = "GSTN:";
const service = "Service:";
const edit_quote = "Edit Quotation";
const time = "Time";
const submitted = "Submitted";
const action_pending = "Action Pending";
const not_submitted = "Not Submitted";
const revision_req = "Revision Requested";
const final_ver = "final version";
const tax = "Tax";
const view_quote_detail = "View Detail Quotation";
const active = "Active";
const previous = "Previous";
const pending = "Pending";
const booking = "Booking";
const quotation_list = "Quotation List";
const quotation_detail = "Quotation Details";
const rate_factor = "Rate Factor"
const reschedule_book = "Reschedule Booking";
const reschedule = "Reschedule";

//notification
const delete_label = "Delete";
const mark_as_read = "Mark as Read";
const mark_all_read = "Mark All Read";
const delete_all = "Delete All";
const notification = "Notifications";

//feedback
const feedback_type = "Feedback Type";
const suggestion = "Suggestion";
const issue = "Issue";
const complaint = "Complaint";
const other = "Other";
const name = "Name"
const hate_it = "Hate it"
const dislike_it = "Dislike it"
const its_ok = "It's OK"
const like_it = "Like it"
const love_it = "Love it"
const req_ser = `Request New Service`

const book_req = "Booking Requests";
const serv_desp = "Service Description";
const serv_name = "Service Name";
const serv_cat = "Service Category";
const my_service = "My Services";
const my_serv_loc = "My Service Location";
const serv_loc = "Store Location";
const my_booking_his = "My Booking History";
const my_sch = "My Schedule"
const recess_dur = "Recess Duration";
const choose_schedule = "Choose schedule"
const start_date = "Start Date";
const end_date = "End Date";
const start_time = "Start Time";
const end_time = "End Time";
const rest_dur = "Rest Duration";
const select_working_day = "Select working days";
const schedule_name = "Schedule Name";
const optional = "optional";
const want_rest = "Want Rest Time";
const sun = 'Sun';
const mon = 'Mon';
const tue = 'Tue';
const wed = 'Wed';
const thurs = 'Thu';
const fri = 'Fri';
const sat = 'Sat';
const set_active = "Set As Active";
const start_tag = "START";
const end_tag = "END";
const rest = "REST TIME";
const dur = "DUR";
const min = "Min";
const hr = "Hr";
const no_rest = "NO REST";
const time_alloted = "TIME ALLOTED";
const working_days = "Working days";
const view_sch = "View Schedule";
const manage_sch = "Manage Schedule";
const date = "Date";
const working = "Working";
const holiday = "Holiday"
const no_work = "NO WORK ON THIS DAY";
const recess = "Recess";
const no_recess_alloted = "NO RECESS TIME ALLOTED"
const edit_sch = "Edit Schedule for this Day"
const want_recess = "Want Recess";
const sch_details = "Schedule Details"
const discard_changes = "Discard Changes";
const change_sch = "Change Schedule";
const working_date = "Working on this date";
const not_working = "Not Working day";
const set_loc = "SET LOCATION";
const set_range = "Set Service Range";
const set_serv_range = "Set your service range";
const whats_this = "What is this";
const service_range = "Service Range";
const peer_sp = "Peer SPs around you";
const copy = "Copy";
const copied_ref = "Referral Code Copied!";
const share_referral_button = 'Referrals';
const avg_rate = "Average Rate";
const desc = "Description";
const title = "Title";
const price = "Price";
const update_profile = "Update your Profile Picture";
const choose_duration = "Choose Duration"
const home = "Home"
const chat = "Chat";
const call = "Call";
const req_submit = "Request submitted to OlaGate"
const select_cat = "Select Service Category"
const change = "Change";
const sp_reg = "Service Provider Registration";
const edit_schedule = "Edit Schedule";
const driving_licence = "Driving Licence";
const adhar_card = "Aadhaar Card";
const election_card = "Election Card";
const pan_card = "PAN Card";
const passport = "Passport";
const work_exp = "Work Experience";
const profile_intro_tag = "Profile Introduction";
const mode_of_service = "Mode of Service";
const area_of_service = "Area of Service"
const minimum_vc = "Minimum Visiting Charge";
const visiting_charge = "Visiting Charge";
const complete_reg = "Complete Registration";
const back = "Back";
const male = "Male";
const female = "Female";
const full_name_req = "Full Name Required"
const photo_id = "Photo Identification";
const reg_doc = "Store Registration Document";
const profile_pic = "Profile Picture"
const finish = "FINISH";
const profile_info = "Profile Information";
const services = "Services";
const click_here_select = "Click Here to Select"
const next = "Next";
const set_area_serv = "Set your Area of Service";
const set_serv_loc = "Set your Service Location ";
const ser_mode = "Service Mode";
const personal_info = "Personal Information";
const not_blank = "Cannot be blank";
const valid_mobile = "Enter valid mobile number";
const exp_greater_than_60 = "Experience should be less than 60";
const accountSettings = "Account Settings";
const language = "Language";
const deactivate_acnt = "Deactivate Account";

const street = "Street";
const locality = "Locality";
const change_loc = "Change Location";
const doorstep = "Doorstep";
const atstore = "At Store";
const show_more = "Show More";
const note = "Note";

const discount_offered_system = "OlaGate";
const discount_offered_sp = "Provider";

const retry = "Retry";

const dnhood = "DNhood";

const appName = () => isDNhoodEnv() ? dnhood : olagate

export default {
    appName,
    home,
    note,
    show_more,
    doorstep,
    atstore,
    street,
    change_loc,
    locality,
    done,
    book_serv,
    not_blank,
    valid_mobile,
    exp_greater_than_60,
    ser_mode,
    personal_info,
    click_here_select,
    set_area_serv,
    set_serv_loc,
    services,
    next,
    profile_info,
    reg_doc,
    finish,
    profile_pic,
    photo_id,
    full_name_req,
    male,
    female,
    complete_reg,
    profile_intro_tag,
    mode_of_service,
    area_of_service,
    minimum_vc,
    visiting_charge,
    back,
    work_exp,
    driving_licence,
    edit_schedule,
    adhar_card,
    election_card,
    pan_card,
    passport,
    sp_reg,
    change,
    select_cat,
    req_submit,
    chat,
    call,
    choose_duration,
    view_all,
    update_profile,
    price,
    desc,
    title,
    avg_rate,
    copied_ref,
    share_referral_button,
    copy,
    peer_sp,
    service_range,
    whats_this,
    set_serv_range,
    set_range,
    set_loc,
    not_working,
    working_date,
    change_sch,
    discard_changes,
    sch_details,
    want_recess,
    edit_sch,
    no_recess_alloted,
    recess,
    no_work,
    holiday,
    working,
    date,
    manage_sch,
    working_days,
    view_sch,
    no_rest,
    time_alloted,
    dur,
    min,
    hr,
    rest,
    start_tag,
    end_tag,
    set_active,
    mon,
    sat,
    sun,
    thurs,
    tue,
    wed,
    fri,
    optional,
    want_rest,
    select_working_day,
    schedule_name,
    rest_dur,
    start_time,
    end_time,
    end_date,
    start_date,
    choose_schedule,
    recess_dur,
    my_sch,
    serv_desp,
    serv_name,
    req_ser,
    my_booking_his,
    my_service,
    my_serv_loc,
    serv_loc,
    book_req,
    today,
    upcoming,
    serv_cat,
    hate_it,
    like_it,
    love_it,
    its_ok,
    dislike_it,
    suggestion,
    issue,
    complaint,
    other,
    name,
    feedback_type,
    click_here,
    info_mail,
    notification,
    delete_all,
    delete_label,
    mark_as_read,
    mark_all_read,
    reschedule_book,
    reschedule,
    rate_factor,
    tax,
    pending,
    previous,
    quotation_detail,
    booking,
    active,
    quotation_list,
    view_quote_detail,
    final_ver,
    action_pending,
    not_submitted,
    revision_req,
    submitted,
    time,
    edit_quote,
    ph,
    gstn,
    service,
    invoice_id,
    serviced_on,
    bill_to,
    bill_from,
    service_add,
    accept,
    note_client,
    submit_invoice,
    view_less,
    edit_profile_button_label,
    share_refferal_button,
    logout_button,
    my_booking_history_button,
    change_photo,
    public_info,
    private_info,
    change_pass,
    my_address,
    current_pass,
    new_pass,
    confirm_pass,
    change_phone,
    olagate,
    mobile_no,
    logout,
    change_email,
    add_email,
    new_email,
    edit,
    display_name,
    primary_phone,
    email_id,
    secondary_phone,
    full_name,
    dob,
    gender,
    edit_profile,
    save,
    discard,
    change_private_info,
    change_public_info,
    change_profile_info,
    know_more,
    ok,
    cancel,
    close,
    email_phone,
    resend,
    send_mail,
    send_messg,
    no_account,
    register,
    cont,
    google,
    facebook,
    login,
    password,
    forgot_pass,
    sign_in,
    sign_in_with_otp,
    otp,
    sign_in_or_register,
    consumer,
    referral,
    terms_service,
    privacy_policy,
    og_blog,
    get_verification_link,
    service_provider,
    phone_number,
    email,
    resend_code,
    tap_on,
    add_or_edit_add,
    upcoming_services,
    show,
    more,
    less,
    search_service,
    show_on_map,
    remove,
    edit_address,
    address,
    property_address,
    landmark,
    loading_map,
    save_address,
    enter_zip,
    about_us,
    feedback,
    faq,
    license,
    version,
    about,
    property_address_tag,
    state_reason,
    other_reason,
    comment,
    submit,
    book_service,
    confirm,
    processing,
    booking_expired,
    reshedule_booking,
    time_acceptance,
    confirmed,
    out_service,
    serviced,
    booked,
    accepted,
    quote,
    send_quote,
    quote_sent,
    quote_revision,
    quote_revision_sent,
    rescheduled,
    completed,
    settled,
    canceled,
    declined,
    indispute,
    rejected,
    review_quote,
    days,
    hours,
    mins,
    edit_review,
    char_left,
    type_message,
    camera,
    video,
    audio,
    images_upload,
    video_upload,
    audio_upload,
    share_review,
    drop_review,
    rate_cl,
    rate_sp,
    write_review,
    select_timeslot,
    select_service_groups,
    select_service,
    booking_history,
    final_details,
    selected_date,
    selected_items,
    items,
    rate,
    quantity,
    item_total,
    service_requirement,
    uploaded_images,
    service_items,
    continue_tag,
    service_details,
    greater_than_zero,
    add_photo,
    other_items,
    add,
    requested_date,
    available_date,
    profile_intro,
    expertise,
    book_a_slot,
    book,
    sp_details,
    yr_exp,
    review,
    intro,
    show_breakdown,
    go_to_signin,
    regenerate_link,
    client_info,
    sp_info,
    reschedule_app,
    booking_detail,
    selected_items_list,
    decline,
    agree,
    payment_rec,
    no,
    yes,
    invoice,
    request,
    revise_invoice,
    gen_invoice,
    my_review,
    sp_review,
    client_review,
    reject_book,
    cancel_book,
    scheduled,
    replaced,
    passover,
    uploaded_media,
    accept_resch,
    accept_book,
    warning,
    req_invoice,
    sug_invoice,
    add_item,
    sub_total,
    edit_invoice,
    send,
    got_it,
    service_charges,
    qty,
    amount,
    other_charges,
    discount,
    total,
    accountSettings,
    language,
    deactivate_acnt,
    discount_offered_system,
    discount_offered_sp,
    expired,
    retry
};