import React from 'react';
import IconButton from 'material-ui/IconButton';
import ArrowBack from 'material-ui/svg-icons/navigation/arrow-back';
import gs_color from 'config/colors.css';
import { spHomeRoute } from 'routes-parameters/config';
import { sp_signedIn } from 'config/storage-variables';
import { getEnvHomeRoute } from 'utils/helper-functions';

class BackButton extends React.PureComponent {
  static defaultProps = {
    color: gs_color.primaryColor,
    clickDisabled: false,
    className: "myAppBarLeft",
    iconButtonStyle: { padding: '0px', width: '24px', height: '24px' }
  };

  BackClicked() {
    let default_redirect = false;

    if (this.props.history.length > 1) {
      if (this.props.history.location && !this.props.history.location.key)
        default_redirect = true;
    }
    else {
      default_redirect = true;
    }

    if (default_redirect) {
      if (localStorage.getItem(sp_signedIn) === "true") {
        this.props.history.replace(`/${spHomeRoute}`);
      }
      else {
        const envHomeRoute = getEnvHomeRoute();
        this.props.history.replace(`/${envHomeRoute}`);
      }
    }
    else {
      this.props.history.goBack();
    }
  }

  render() {
    return (
      <IconButton style={this.props.iconButtonStyle} className={this.props.className} onClick={this.props.clickDisabled ? null : this.props.onClick ? () => this.props.onClick() : () => this.BackClicked()} ><ArrowBack color={this.props.color} /></IconButton>
    );
  }
}

BackButton.displayName = 'BackButton';
export default BackButton;