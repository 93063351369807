import { parseSku } from './index';

export const productDetailsApiAdapter = product_detail => {
    let detailsData = {};

    if (product_detail && product_detail.product_info && product_detail.product_info.public_info) {
        let public_info = { ...product_detail.product_info.public_info };
        let product_images = { ...public_info.product_images };
        let big_images = [];
        let small_images = [];

        if (product_images.big && product_images.big.constructor === Array && product_images.big.length > 0) {
            for (const big_image of product_images.big) {
                big_images.push(big_image);
            }
        }

        if (product_images.small && product_images.small.constructor === Array && product_images.small.length > 0) {
            for (const small_image of product_images.small) {
                small_images.push(small_image);
            }
        }

        if (big_images.length === 0) {
            if (small_images.length > 0) {
                big_images = [...small_images];

            } else if (product_detail.product_icon) {
                big_images = [product_detail.product_icon];
            }
        }

        if (small_images.length === 0) {
            if (big_images.length > 0) {
                small_images = [...big_images];

            } else if (product_detail.product_icon) {
                small_images = [product_detail.product_icon];
            }
        }

        delete public_info.product_images;
        delete public_info.filter;

        const product_info = { ...product_detail.product_info, public_info: { ...product_detail.product_info.public_info, ...public_info } }

        detailsData = { ...product_detail, product_info: product_info, product_images: { small_images, big_images } };

    } else if (product_detail) {
        detailsData = { ...product_detail };
    }

    return detailsData;
}