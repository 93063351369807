import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Waypoint } from 'react-waypoint';
import { updateMiscellaneousComponents } from 'actions';

import RestaurantListLoader from './restaurant-list-loader';
import RestaurantListItem from './restaurant-list-item';
import { ogfoodRoute } from 'routes-parameters/config';
import { OGFOOD_BASE_LINK } from 'config/config';
import PostFilters from '../../client/scenes/society/scenes/message-board/components/post-filters';
import NoServiceProducts from '../../client/scenes/home/scenes/new-home/component/no-service-products';
import styles from './styles';
import gs_color from 'config/colors.css';

class RestaurantList extends PureComponent {
    constructor() {
        super();

        this.state = {
            selectedFilters: []
        }
    }

    componentDidMount() {
        if (this.props.miscellaneous_components && this.props.miscellaneous_components.selected_ogfood_cat_id) {
            const selectedFilters = [this.props.miscellaneous_components.selected_ogfood_cat_id];
            this.setState({ selectedFilters });
            if (!this.props.cache || this.props.cache.cat_id !== this.props.miscellaneous_components.selected_ogfood_cat_id) {
                let new_cache = { ...(this.props.cache || {}), cat_id: this.props.miscellaneous_components.selected_ogfood_cat_id }
                this.props.refreshPage(new_cache);
            }
        }
    }

    redirectToRestaurant(opunit_id) {
        this.props.history.push(`/${ogfoodRoute}/${opunit_id}`)
    }

    openRestaurantLink(opunit_page_url) {
        let store_url = OGFOOD_BASE_LINK + opunit_page_url;
        window.open(store_url, '_blank');
    }


    addFilter = type => {
        const selectedFilters = [type];
        this.props.updateMiscellaneousComponents({ selected_ogfood_cat_id: type });
        this.setState({ selectedFilters });
        let filters = { ...(this.props.cache || {}) };
        filters.cat_id = parseInt(type, 10);
        delete filters.last_opuid;
        delete filters.offset;
        this.props.refreshPage(filters);
    }

    removeFilter = type => {
        const selectedFilters = [];
        this.props.updateMiscellaneousComponents({ selected_ogfood_cat_id: undefined });
        this.setState({ selectedFilters });
        let filters = { ...(this.props.cache || {}) };
        delete filters.cat_id;
        delete filters.last_opuid;
        delete filters.offset;
        this.props.refreshPage(filters);
    }

    getLoader() {
        return <RestaurantListLoader />
    }

    renderLoadMore = () => {
        return (
            <div onClick={this.fetchMore} style={{ padding: '8px', textAlign: 'center', borderRadius: '10px', margin: '4px 8px', backgroundColor: gs_color.gradientLightColor, color: gs_color.primaryColor, cursor: 'pointer' }}>
                Load More
            </div>
        )
    }

    fetchMore = () => {
        if (!this.props.can_load_more || this.props.loading) {
            return;
        }

        let filters = { ...(this.props.cache || {}) };
        filters.offset = (this.props.opunit_list && this.props.opunit_list.length) || 0;
        if (this.props.opunit_list && this.props.opunit_list[this.props.opunit_list.length - 1]) {
            filters.last_opuid = this.props.opunit_list[this.props.opunit_list.length - 1].opunit_id;
        }

        this.props.refreshPage(filters);
    }

    render() {
        const cat_list_filters = this.props.cat_list.map(item => ({ id: item.cat_id, label: item.cat_name }));

        return (
            <div style={styles.container}>
                {
                    this.props.cat_list && this.props.cat_list.length > 0 &&
                    <div style={styles.filterContainer}>
                        <PostFilters
                            selected={this.state.selectedFilters}
                            filters={cat_list_filters}
                            addFilter={this.addFilter}
                            removeFilter={this.removeFilter}
                        />
                    </div>
                }
                {
                    (this.props.opunit_list && this.props.opunit_list.length > 0) ?
                        this.props.opunit_list.map(opunit => (
                            <RestaurantListItem
                                opunit={opunit}
                                key={opunit.opunit_id}
                                cat_list_json={this.props.cat_list_json}
                                //handleClick={() => this.redirectToRestaurant(opunit.opunit_id)}
                                handleClick={() => this.openRestaurantLink(opunit.page_url)}
                            />
                        ))
                        : !this.props.loading ?
                            <NoServiceProducts
                                title="No seller found in your neighborhood"
                                description="Please check after sometime"
                            />
                            : null
                }
                {this.props.can_load_more && !this.props.loading && this.renderLoadMore()}
                {this.props.loading && this.getLoader()}
                <Waypoint onEnter={this.fetchMore} />
            </div>
        );
    }
}


function mapStateToProps({ restaurant_list, miscellaneous_components }) {
    const { loading, error, cache, cat_list, cat_list_json, opunit_list, can_load_more } = restaurant_list;

    return {
        loading,
        error,
        cache,
        cat_list,
        cat_list_json,
        opunit_list,
        can_load_more,
        miscellaneous_components
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        updateMiscellaneousComponents
    }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(RestaurantList);