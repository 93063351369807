import { cl_user_id } from 'config/storage-variables';
import { convertTimseStampToText } from 'utils/date-time-conversion';

export function parseMsgBoardApiData(apiData) {
    let data = [];
    let user_id = localStorage.getItem(cl_user_id);

    if (apiData && apiData.constructor === Array) {
        apiData.forEach(item => {
            data.push({
                msgb_threadid: item.msgb_threadid,
                message_id: item.msgb_id,
                message_title: item.bulletin ? item.bulletin.title : null,
                message_desc: item.bulletin ? item.bulletin.msg_body : null,
                message_link: item.bulletin ? item.bulletin.link : null,
                message_pics: item.bulletin && item.bulletin.media ? item.bulletin.media.img : null,
                message_pdfs: null,
                message_docs: item.bulletin && item.bulletin.media ? item.bulletin.media.doc : null,
                message_posted_by: item.posted_by,
                message_posted_by_name: item.posted_by_name,
                message_posted_on: item.posted_at ? convertTimseStampToText(item.posted_at, 2) : null,
                message_type_id: item.msgtype_id,
                is_author: item.posted_by === user_id,
                visibility_level: item.bulletin && item.bulletin.visibility_level ? item.bulletin.visibility_level : null,
                no_of_comments: item.no_of_comments
            });
        });
    }

    return data;
}

export function parseMsgDetailsApiData(apiData) {
    let data = {};
    let user_id = localStorage.getItem(cl_user_id);

    if (apiData && typeof apiData === "object") {
        const item = apiData;
        data = {
            message_id: item.msgb_id,
            message_title: item.bulletin ? item.bulletin.title : null,
            message_desc: item.bulletin ? item.bulletin.msg_body : null,
            message_link: item.bulletin ? item.bulletin.link : null,
            message_pics: item.bulletin && item.bulletin.media ? item.bulletin.media.img : null,
            message_pdfs: null,
            message_docs: item.bulletin && item.bulletin.media ? item.bulletin.media.doc : null,
            message_posted_by: item.posted_by,
            message_posted_by_name: item.posted_by_name,
            message_posted_on: item.posted_at ? convertTimseStampToText(item.posted_at, 2) : null,
            message_type_id: item.msgtype_id,
            is_author: item.posted_by === user_id,
            visibility_level: item.bulletin && item.bulletin.visibility_level ? item.bulletin.visibility_level : null,
        }

        if (item.thread_list && item.thread_list.length > 0) {
            let thread_list = [];
            item.thread_list.forEach(thread => {
                thread_list.push({
                    msgb_threadid: thread.msgb_threadid,
                    message_desc: thread.bulletin ? thread.bulletin.msg_body : null,
                    message_link: item.bulletin ? item.bulletin.link : null,
                    message_pics: thread.bulletin && thread.bulletin.media ? thread.bulletin.media.img : null,
                    message_pdfs: null,
                    message_docs: thread.bulletin && thread.bulletin.media ? thread.bulletin.media.doc : null,
                    message_posted_by: thread.posted_by,
                    message_posted_by_name: thread.posted_by_name,
                    message_posted_on: thread.posted_at ? convertTimseStampToText(thread.posted_at, 2) : null,
                    is_author: thread.posted_by === user_id,
                });
            });

            data.thread_list = thread_list;
        }
    }

    return data;
}