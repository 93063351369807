import React, { PureComponent } from 'react'
import { cl_signedIn, cl_society_properties } from 'config/storage-variables';

import Dialog from 'material-ui/Dialog'
import FlatButton from 'material-ui/FlatButton'

import BottomNav from 'components/bottom-navigation'
import NoSociety from './scenes/no-society'
import Society from './scenes/society'
import NewHome from '../home/scenes/new-home';
import { exploreRoute,homeRoute } from 'routes-parameters/config';
import { getEnvHomeRoute } from 'utils/helper-functions';

import gs_color from 'config/colors.css'

// React loadable imports
import MyLoadable from '../../../../dynamic-component-load';

const CommonImport = MyLoadable({
    loader: () => import(/* webpackChunkName: "CommonImport" */ '../../../../imports/common'),
});
const ClSocietyImport = MyLoadable({
    loader: () => import(/* webpackChunkName: "ClSocietyImport" */ '../../../../imports/cl-society'),
});
const AuthenticationImport = MyLoadable({
    loader: () => import(/* webpackChunkName: "AuthenticationImport" */ '../../../../imports/authentication'),
});

const selectedIndex = 0
class HomeSociety extends PureComponent {
    constructor(props) {
        super(props)

        this.state = {
            displaySociety: true,
            showErrorDialog: false,
            errorMessage: "",
        }

        this.isSignedIn = localStorage.getItem(cl_signedIn) === 'true'
    }

    preloadChunks(chunks, interval) {
        if (!chunks || !chunks.length) {
            return;
        }

        setTimeout(() => {
            chunks[0].preload();

            this.preloadChunks(chunks.slice(1, chunks.length), interval);
        }, interval);
    }

    componentDidMount() {
        let is_registered_property = localStorage.getItem(cl_society_properties);

        // Preload ClSocietyImport chunk
        // Preload CommonImport chunk
        // Preload AuthenticationImport chunk

        /*
            For signed in users - priority 
                If resident => Society stuff (ClSocietyImport) then CommonImport
                If not resident => CommonImport(profile, explore) then Society stuff (ClSocietyImport)

            For not signed in users - priority
                Signup/signin stuff (AuthenticationImport) then CommonImport(profile, explore) then Society stuff (ClSocietyImport)
        */

        let chunks = [];

        setTimeout(() => {
            if (!this.isSignedIn) {
                chunks.push(AuthenticationImport);
                chunks.push(CommonImport);
                chunks.push(ClSocietyImport);
            }
            else {
                if (is_registered_property) {
                    chunks.push(ClSocietyImport);
                    chunks.push(CommonImport);
                    chunks.push(AuthenticationImport);
                }
                else {
                    chunks.push(CommonImport);
                    chunks.push(ClSocietyImport);
                    chunks.push(AuthenticationImport);
                }
            }
            this.preloadChunks(chunks, 2000);
        }, 5000);    
    }
/*
    componentDidUpdate(prevProps, prevState) {    
        let is_registered_property = localStorage.getItem(cl_society_properties);
        if (!is_registered_property ) {
            this.props.history.push(`/${exploreRoute}`)
        }          
    }
*/   
    setDisplaySociety = display => this.setState({ displaySociety: display })

    showErrorDialog = message => this.setState({ errorMessage: message, showErrorDialog: true })

    render() {
        if (this.isSignedIn && this.state.displaySociety) {
            const errorActions = [
                <FlatButton
                    label="Ok"
                    primary={true}
                    onClick={() => this.setState({ showErrorDialog: false })}
                />
            ]
            return (
                <>
                    <Society
                        {...this.props}
                        setDisplaySociety={this.setDisplaySociety}
                        showErrorDialog={this.showErrorDialog}
                    />
                    {
                        this.state.showErrorDialog &&
                        <Dialog
                            actions={errorActions}
                            open={this.state.showErrorDialog}
                            onRequestClose={() => this.setState({ showErrorDialog: false })}
                            titleClassName="dialogTitleClassName"
                            bodyClassName="dialogBodyClassName"
                            contentClassName="DialogNewStyle"
                        >
                            {
                                this.state.errorMessage.constructor === String &&
                                this.state.errorMessage.split("\n").map((string, i) => {
                                    return (
                                        <div key={i} style={{ color: gs_color.textColor }}>
                                            <span>{string}</span><br />
                                        </div>
                                    )
                                })
                            }
                        </Dialog>
                    }
                </>
            )
        }    
        else if (this.isSignedIn && !this.state.displaySociety) {
            //If non society user take neighborhood
            //FIXME: home becoming nbh.
            return (
                <NewHome
                    {...this.state}
                    selectedIndex={typeof this.props.selectedIndex === "number" ? this.props.selectedIndex : selectedIndex}
                    history={this.props.history}
                    service_notification_count={this.state.service_notification_count}
                />
            )
        }

        return (
            <>
                <NoSociety {...this.props} />                         
                <BottomNav
                    selectedIndex={selectedIndex}
                    history={this.props.history}
                />
            </>
        )
    }
}

HomeSociety.displayName = 'HomeSociety';
export default HomeSociety