import axios from 'middleware/axios';
import { API_LINK, APP_LINK } from 'config/config';
import { cl_access_token, cl_user_id } from 'config/storage-variables';
import {
    productCategoryApiAdapter, dummyPCatApiData, productSubCategoryApiAdapter, dummyPSCatApiData
} from '../config';

export function getProductCategories(city_id, cb) {
    let access_token = localStorage.getItem(cl_access_token);

    // setTimeout(() => cb(productCategoryApiAdapter(dummyPCatApiData()), null), 500)

    let data = `city_id=${city_id}&batch_size=100`;
    if (access_token) {
        data += `&access_token=${access_token}`;
    }

    axios.get(`${API_LINK}/gs_Product_Category/gs_GetCatListForCity?${data}`)
        .then(res => {
            if (res && res.data) {
                cb(productCategoryApiAdapter(res.data.gsRespData && res.data.gsRespData.pcat_list), null);
            } else {
                cb(null, res.data);
            }
        })
        .catch(err => {
            if (err.response && err.response.data && err.response.data.error) {
                if (err.response.data.error.statusCode === 401) {
                    cb(null, { ...err.response.data.error, is_logout: true });
                } else {
                    cb(null, err.response.data.error);
                }
            } else {
                cb(null, err);
            }
        })
}

export function getProductSubCategories(city_id, cb) {
    let access_token = localStorage.getItem(cl_access_token);

    // setTimeout(() => cb(productSubCategoryApiAdapter(dummyPSCatApiData()), null), 500)

    let data = `city_id=${city_id}&batch_size=500`;
    if (access_token) {
        data += `&access_token=${access_token}`;
    }

    axios.get(`${API_LINK}/gs_Product_SubCategory/gs_GetSubCatListForCity?${data}`)
        .then(res => {
            if (res && res.data) {
                cb(productSubCategoryApiAdapter(res.data.gsRespData && res.data.gsRespData && res.data.gsRespData.pscat_list), null);
            } else {
                cb(null, res.data);
            }
        })
        .catch(err => {
            if (err.response && err.response.data && err.response.data.error) {
                if (err.response.data.error.statusCode === 401) {
                    cb(null, { ...err.response.data.error, is_logout: true });
                } else {
                    cb(null, err.response.data.error);
                }
            } else {
                cb(null, err);
            }
        })
}

export function getProductTypes(city_id, pscat_id, cb) {
    let access_token = localStorage.getItem(cl_access_token);

    let data = `city_id=${city_id}`;
    data += `pscat_id=${pscat_id}`;
    if (access_token) {
        data += `&access_token=${access_token}`;
    }

    axios.get(`${API_LINK}/gs_Product_Type/gs_getActiveProductType?${data}`)
        .then(res => {
            if (res && res.data) {
                cb(res.data.gsRespData, null);
            } else {
                cb(null, res.data);
            }
        })
        .catch(err => {
            if (err.response && err.response.data && err.response.data.error) {
                if (err.response.data.error.statusCode === 401) {
                    cb(null, { ...err.response.data.error, is_logout: true });
                } else {
                    cb(null, err.response.data.error);
                }
            } else {
                cb(null, err);
            }
        })
}

export function getProductFeedbackForm(cb) {
    let access_token = localStorage.getItem(cl_access_token);
    var uri = `{"where":{"text_category":"PRODUCT_FEATURE_FEEDBACK"}}`
    var encode = encodeURI(uri);
    axios.get(`${API_LINK}/gs_Static_Text?access_token=${access_token}&filter=${encode}`)
        .then((res) => {
            if (res.status.toString().slice(0, 2) == 20) {
                cb(res.data, null);
            } else {
                cb(null, res.data);
            }
        })
        .catch((err) => {
            if (err && err.response && err.response.data && err.response.data.error) {
                if (err.response.data.error.statusCode === 401) {
                    cb(null, { ...err.response.data.error, is_logout: true });
                }
                else
                    cb(null, err.response.data.error);
            }
            else
                cb(null, err);
        });
}

export function submitProductFeedbackForm(order_id, form, cb) {
    let access_token = localStorage.getItem(cl_access_token);

    const data = {
        order_id: order_id,
        feedback_data: form
    }

    axios.post(`${API_LINK}/gs_Product_Order/gs_postOrderFeedback?access_token=${access_token}`, data)
        .then((res) => {
            if (res.status.toString().slice(0, 2) == 20) {
                cb(res.data, null);
            } else {
                cb(null, res.data);
            }
        })
        .catch((err) => {
            if (err && err.response && err.response.data && err.response.data.error) {
                if (err.response.data.error.statusCode === 401) {
                    cb(null, { ...err.response.data.error, is_logout: true });
                }
                else
                    cb(null, err.response.data.error);
            }
            else
                cb(null, err);
        });
}

// Function to send payment info to server
export function sendProductPayPalPaymentInfoToServer(data, access_token, cb) {
    const url = APP_LINK + '/ps/paypal_product_capture?access_token' + access_token;
    // Using my server endpoints to capture the payment
    axios.post(url, data)
        .then(res => {
            if (res.data.statusCode.toString().slice(0, 2) == 20) {
                if (res.data.gsRespData) {
                    cb(res.data.gsRespData, null);
                }
                else
                    cb(null, res.data);
            }
            else {
                if (res.data.gsRespData) {
                    cb(null, res.data.gsRespData);
                }
                else
                    cb(null, res.data);
            }
        })
        .catch(err => {
            if (err && err.response && err.response.data && err.response.data.error)
                cb(null, err.response.data.error);
            else
                cb(null, err);
        });
}

export function verifyCouponCode(code, cb) {
    setTimeout(() => {
        cb(true, null)
    }, 2000)
}