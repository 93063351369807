import gs_color from '../../../../../config/colors.css'

const longButtonOuterStyle = {
    margin: '16px 12px 8px 12px',
    borderRadius: '100px',
    padding: '8px 12px 8px 12px',
    backgroundColor: gs_color.accentColor
}

const longButtonStyle = {
    borderRadius: '100px',
    backgroundColor: gs_color.accentColor
}

const longButtonLabelStyle = {
    color: '#FFF',
    fontSize: '16px',
    fontWeight: 'bold'
}

const fabStyle = {
    margin: '12px 4px 8px 4px'
}

const fabBackgroundColor = gs_color.accentColor;

export default {
    longButtonOuterStyle,
    longButtonStyle,
    longButtonLabelStyle,
    fabStyle,
    fabBackgroundColor
}