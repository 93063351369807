import axios from 'middleware/axios';
import { API_LINK } from 'config/config';
import { cl_signedIn, cl_access_token, sp_signedIn, sp_access_token } from 'config/storage-variables';

export default function uploadToCloud(BUCKET, file, fileName, token = null) {
    //console.log("file", fileName)
    return new Promise((resolve, reject) => {

        const UPLOAD_URL = `https://www.googleapis.com/upload/storage/v1/b/${BUCKET}/o`;
        const DOWNLOAD_URL = `https://storage.googleapis.com/${BUCKET}/${fileName}`;

        // using the simple media upload as per the spec here: https://cloud.google.com/storage/docs/json_api/v1/how-tos/simple-upload
        const queryParams1 = 'uploadType=media';
        const queryParams2 = 'name=' + fileName;
        const URL = UPLOAD_URL + '?' + queryParams1 + '&' + queryParams2;
        //console.log("URL : ", URL);

        if (token !== null) {

            // do the upload
            // headers are required, and here, we're naively getting the MIME type from the file extension
            fetch(URL, {
                method: 'POST',
                headers: new Headers({
                    'Content-Type': file.type,
                    'Content-Length': file.size,
                    'Authorization': `Bearer ${token}`,
                }),
                body: file,
            })
                .then(r => r.json())
                .then(resp => {
                    // //console.log("resp : ", resp);
                    if (resp.error) {
                        reject({ response: resp.error });
                    }
                    else {
                        resolve({
                            response: {
                                status: 204,
                                gsRespData: {
                                    link: DOWNLOAD_URL
                                }
                            }
                        });
                    }
                })
                .catch(err => reject({ response: err }));
        }
        else {
            const access_token = localStorage.getItem(cl_signedIn) === 'true' ? localStorage.getItem(cl_access_token) : localStorage.getItem(sp_signedIn) === 'true' ? localStorage.getItem(sp_access_token) : null;
            if (access_token !== null) {
                axios.get(`${API_LINK}/gs_Users/gs_GetRWGCSToken?bucket=${BUCKET}&access_token=${access_token}`)
                    .then(res => {
                        if (res.data.gsRespData && res.data.gsRespData.token)
                            token = res.data.gsRespData.token;

                        if (token !== null) {

                            // do the upload
                            // headers are required, and here, we're naively getting the MIME type from the file extension
                            fetch(URL, {
                                method: 'POST',
                                headers: new Headers({
                                    'Content-Type': file.type,
                                    'Content-Length': file.size,
                                    'Authorization': `Bearer ${token}`,
                                }),
                                body: file,
                            })
                                .then(r => r.json())
                                .then(resp => {
                                    resolve({
                                        response: {
                                            status: 204,
                                            gsRespData: {
                                                link: DOWNLOAD_URL
                                            }
                                        }
                                    });
                                })
                                .catch(err => reject({ response: err }));
                        }

                    })
                    .catch(err => reject({ response: err }));

            }
            else {
                reject({
                    response: {
                        status: 401,
                        message: "Authorization Required"
                    }
                });
            }
        }
    });
}

export function getGCSToken(BUCKET, access_token, cb) {
    axios.get(`${API_LINK}/gs_Users/gs_GetRWGCSToken?bucket=${BUCKET}&access_token=${access_token}`)
        .then((res) => {
            if (res.data.statusCode.toString().slice(0, 2) == 20) {
                cb(res.data, null);
            }
            else {
                cb(null, res.data);
            }
        })
        .catch((err) => {
            if (err && err.response && err.response.data && err.response.data.error) {
                if (err.response.data.error.statusCode === 401) {
                    cb(null, { ...err.response.data.error, is_logout: true });
                }
                else
                    cb(null, err.response.data.error);
            }
            else
                cb(null, err);
        });
}

/*

const BUCKET = 'staging.gruhaps.appspot.com';

      // get the file object
      const selectedFile = document.querySelector('#file').files[0];
      //console.log("selectedFile : ", selectedFile);
      // get an encoded file name - either from the name input or from the file itself
      const fileName = encodeURIComponent(document.querySelector('#name').value || selectedFile.name);
      //console.log("fileName : ", fileName);

      const BASE_URL = `https://www.googleapis.com/upload/storage/v1/b/${BUCKET}/o`;
      // using the simple media upload as per the spec here: https://cloud.google.com/storage/docs/json_api/v1/how-tos/simple-upload
      const queryParams1 = 'uploadType=media';
      const queryParams2 = 'name=' + fileName;
      const URL = BASE_URL + '?' + queryParams1 + '&' + queryParams2;
      //console.log("URL : ", URL);

      // do the upload
      // headers are required, and here, we're naively getting the MIME type from the file extension
      fetch(URL, {
        method: 'POST',
        headers: new Headers({
          'Content-Type': selectedFile.type,
          'Content-Length': selectedFile.size,
          'Authorization': `Bearer ${token}`,
        }),
        body: selectedFile,
      })
      .then(r => r.json())
      .then(resp => {})     //console.log(resp))
      .catch(err => {});     //console.log(err));

*/