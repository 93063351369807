import axios from 'middleware/axios';
import { API_LINK } from 'config/config';
import { cl_access_token } from 'config/storage-variables';

export function getAddressTimeZone(countryCode, cb) {
    axios.get(`${API_LINK}/gs_GeoNames/gs_CountryDetails?country_a2_code=${countryCode}`)
        .then((res) => {
            if (res.data.statusCode.toString().slice(0, 2) == 20)
                if (res.data.gsRespData && res.data.gsRespData.country_details)
                    cb(res.data.gsRespData.country_details, null);
                else
                    cb(null, res.data);
            else
                cb(null, res.data);
        })
        .catch((err) => {
            if (err.response && err.response.data && err.response.data.error)

                cb(null, err.response.data.error);
            else
                cb(null, err);
        });
}

export function getMapGeonameData(zipcode, countryCode, cb) {
    let filter = { zipcode, country_code: countryCode };
    filter = JSON.stringify(filter);
    let encode = encodeURI(filter);

    axios.get(`${API_LINK}/gs_Users/gs_GetMapGeonameData?data_obj=${encode}`)
        .then((res) => {
            if (res.data.statusCode.toString().slice(0, 2) == 20)
                if (res.data.gsRespData)
                    cb(res.data.gsRespData, null);
                else
                    cb(null, res.data);
            else
                cb(null, res.data);
        })
        .catch((err) => {
            if (err.response && err.response.data && err.response.data.error)

                cb(null, err.response.data.error);
            else
                cb(null, err);
        });
}

export function getServicesForCategory(category_id, cb) {
    axios.get(`${API_LINK}/gs_Services_Category/${category_id}/Services`)
        .then(res => {
            cb(res.data, null);
        })
        .catch(err => {
            cb(null, err);
        });
}

export function getCityList(cb) {
    axios.get(`${API_LINK}/gs_Cities/gs_getOpenCityList?status=active`)
        .then((res) => {
            if (res.data.statusCode.toString().slice(0, 2) == 20)
                if (res.data.gsRespData)
                    cb(res.data.gsRespData, null);
                else
                    cb(null, res.data);
            else
                cb(null, res.data);
        })
        .catch((err) => {
            if (err.response && err.response.data && err.response.data.error)
                cb(null, err.response.data.error);
            else
                cb(null, err);
        });
}

export function postUserEmergencySMS(geo_location, title, zipcode, cb) {
    let access_token = localStorage.getItem(cl_access_token);

    axios.post(`${API_LINK}/gs_Users/gs_postUserEmergencySMS?access_token=${access_token}`, { geo_location, title, zipcode })
        .then((res) => {
            if (res.data.statusCode.toString().slice(0, 2) == 20)
                if (res.data.gsRespData)
                    cb(res.data.gsRespData, null);
                else
                    cb(null, res.data);
            else
                cb(null, res.data);
        })
        .catch((err) => {
            if (err.response && err.response.data && err.response.data.error)
                cb(null, err.response.data.error);
            else
                cb(null, err);
        });
}