import React from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import Paper from 'material-ui/Paper';
import CopyClipboardIcon from './assets/clipboard.png';
import './styles.css';
import { APP_LINK, SP_PUBLIC_URL_BASE } from 'config/config';

import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  TelegramShareButton,
  WhatsappShareButton,
  RedditShareButton,
  EmailShareButton,

  FacebookIcon,
  TwitterIcon,
  TelegramIcon,
  WhatsappIcon,
  LinkedinIcon,
  RedditIcon,
  EmailIcon,
} from 'react-share';

var open = true;
const style = {
  display: "flex",
  flexWrap: "wrap",
  borderRadius: "4px",
  borderTopRightRadius: "15px",
  borderTopLeftRadius: "15px",
  visibility: open ? 'visible' : 'hidden',
};

function ShareButtonCard(props) {
  let shareUrl = APP_LINK;

  let title = "Hey, checkout this Digital Neighborhood Platform, OlaGate";
  let title1 = "Hey, checkout this Digital Neighborhood Platform, OlaGate @ " + APP_LINK;

  if(props.share_url){
    shareUrl = props.share_url;
  }

  if (props.title) {
    title = props.title;
    title1 = title + " " + shareUrl;
  }
  else {
    if (props.public_url) {
      /* Share Public Url */
      shareUrl = props.public_url;

      title = "Hi! ";
      if (props.name)
        title += "I am " + props.name;
      title += ", Please visit my profile on my personal page @";

      title1 = title + " " + shareUrl;
    }
    else if (props.ref_code) {
      /* Share Referral Code */
      shareUrl = shareUrl + '/profile?ref_code=' + props.ref_code;

      if (props.name) {
        title = "Hi! ";
        title += "I am " + props.name;
        title += ", Explore and book my services on OlaGate @";

        shareUrl = SP_PUBLIC_URL_BASE + '/' + props.ref_code;

        title1 = title + " " + shareUrl;
      }
      else {
        title = "Use this Referral Code : " + props.ref_code + " for Sign Up into ";
        title1 = "Use this Referral Code : " + props.ref_code + " for Sign Up into " + shareUrl;
      }
    }
  }

  return (
    <div>
      <Paper style={style} zDepth={2}>
        <div>
          <div className="Demo__container">
            <div className="Demo__some-network">
              <WhatsappShareButton
                url={shareUrl}
                title={title}
                onShareWindowClose={props.myHandl}
                className="Demo__some-network__share-button">
                <WhatsappIcon size={45} round />
              </WhatsappShareButton>
              <div className="Demo__some-network__share-count">
                WhatsApp
              </div>
            </div>

            <div className="Demo__some-network">
              <FacebookShareButton
                url={shareUrl}
                quote={title}
                onShareWindowClose={props.myHandl}
                className="Demo__some-network__share-button">
                <FacebookIcon
                  size={45}
                  round />
              </FacebookShareButton>
              <div className="Demo__some-network__share-count">
                Facebook
              </div>
            </div>

            <div className="Demo__some-network">
              <EmailShareButton
                url={shareUrl}
                subject={"Join OlaGate - Your Digital Neighborhood Platform"}
                body={title1}
                onShareWindowClose={props.myHandl}
                className="Demo__some-network__share-button">
                <EmailIcon
                  size={45}
                  round />
              </EmailShareButton>
              <div className="Demo__some-network__share-count">
                Email
              </div>
            </div>

            <div className="Demo__some-network">
              <div style={{ cursor: "pointer", margin: "auto", width: "max-content" }}>
                <CopyToClipboard text={title1}
                  onCopy={props.myHandl}
                >
                  <img src={CopyClipboardIcon}
                    width={45}
                    height={45}
                    className="clipIconClass"
                    alt="Copy to clipboard"
                  />
                </CopyToClipboard></div>
              <div className="Demo__some-network__share-count">
                Copy to Clipboard
              </div>
            </div>

            <div className="Demo__some-network">
              <TwitterShareButton
                url={shareUrl}
                title={title}
                onShareWindowClose={props.myHandl}
                className="Demo__some-network__share-button">
                <TwitterIcon
                  size={45}
                  round />
              </TwitterShareButton>
              <div className="Demo__some-network__share-count">
                Twitter
              </div>
            </div>

            <div className="Demo__some-network">
              <LinkedinShareButton
                url={shareUrl}
                title={title}
                onShareWindowClose={props.myHandl}
                windowWidth={745}
                windowHeight={450}
                className="Demo__some-network__share-button">
                <LinkedinIcon
                  size={45}
                  round />
              </LinkedinShareButton>
              <div className="Demo__some-network__share-count">
                Linkedin
              </div>
            </div>

            <div className="Demo__some-network">
              <TelegramShareButton
                url={shareUrl}
                title={title}
                onShareWindowClose={props.myHandl}
                className="Demo__some-network__share-button">
                <TelegramIcon size={45} round />
              </TelegramShareButton>
              <div className="Demo__some-network__share-count">
                Telegram
              </div>
            </div>

            <div className="Demo__some-network ">
              <RedditShareButton
                url={shareUrl}
                title={title}
                onShareWindowClose={props.myHandl}
                windowWidth={645}
                windowHeight={445}
                className="Demo__some-network__share-button">
                <RedditIcon
                  size={45}
                  round />
              </RedditShareButton>
              <div className="Demo__some-network__share-count">
                Reddit
              </div>
            </div>

            <div className="Demo__some-network ">
            </div>
          </div>
        </div>
      </Paper>
    </div>
  );
}

ShareButtonCard.displayName = 'ShareButtonCard';
export default ShareButtonCard;