import React from 'react';

import { ListItem } from 'material-ui/List';

import MyAppBar from 'components/app-bar';
import CrossButton from 'components/cross-button';
import ProfilePic from 'components/profile-pic';
import PropertyList from '../../../../components/property-list';
import { addBoardMemberObjectAdapter } from '../../../board-members/components/add-board-member/util';
import { shouldHidePropAddlDetails } from '../../../../components/property-list/utils';
import gs_color from 'config/colors.css';

function handleOnSelect(propDetails, userDetails, props) {
    let data = {};

    if (props.API_TYPE === "REGISTRATION") {
        if (propDetails) {
            data = { ...data, ...propDetails };
        }

        if (userDetails) {
            data = { ...data, ...userDetails };
        }
    }
    else {
        if (propDetails) {
            data.prop_id = propDetails.id;
            data.prop_name = propDetails.primaryText;
        }

        if (userDetails) {
            data.user_id = userDetails.id;
            data.user_name = propDetails.primaryText;
        }
    }

    props.onSelect(data);
}

function renderNestedItems(userList, propDetails, props) {
    let reqList = [];

    if (userList && userList.length > 0) {
        userList.map(user => {
            reqList.push(
                <ListItem
                    key={user.id}
                    id={propDetails.primaryText + '-' + user.primaryText}
                    primaryText={
                        <div style={{ wordBreak: 'break-all' }}>
                            {user.primaryText}
                        </div>
                    }
                    secondaryText={user.secondaryText}
                    hoverColor="#fff"
                    onClick={() => handleOnSelect(propDetails, user, props)}
                    leftAvatar={<ProfilePic size={36} profile_pic={user.profilePic} display_name={user.primaryText} history={props.history} />}
                    style={{ border: `1px solid ${gs_color.backgroundSecondaryColor}`, margin: '0px 24px 0px 40px' }}
                />
            )
        })
    }

    return reqList;
}

function renderItemView(prop, filter_data, props) {
    let data = addBoardMemberObjectAdapter(prop, shouldHidePropAddlDetails(props.comm_schema_fields));
    const board_id = prop && prop.board_id ? prop.board_id : null;

    return (
        <ListItem
            key={data.id}
            id={data.primaryText}
            primaryText={
                <div style={{ wordBreak: 'break-all' }}>
                    {data.primaryText}
                </div>
            }
            secondaryText={data.secondaryText}
            hoverColor="#fff"
            onClick={() => {
                if (!props.showNestedItems || !data.userList || data.userList.length === 0) {
                    let prop_obj = { ...prop };

                    if (props.API_TYPE === "REGISTRATION" && filter_data) {
                        if (!prop_obj.prop_details) {
                            prop_obj.prop_details = {};
                        }
                        prop_obj.prop_details = { ...prop_obj.prop_details, ...(filter_data || {}) };
                        handleOnSelect({ ...addBoardMemberObjectAdapter(prop_obj), prop_unit_name: prop_obj.prop_unit_name, prop_details: prop_obj.prop_details, board_id }, null, props);
                    }
                    else {
                        handleOnSelect({ ...addBoardMemberObjectAdapter(prop_obj), prop_unit_name: prop_obj.prop_unit_name, prop_details: prop_obj.prop_details, board_id }, null, props);
                    }
                }
            }}
            nestedItems={props.showNestedItems ? renderNestedItems(data.userList, data, props) : []}
            style={{ border: `1px solid ${gs_color.backgroundSecondaryColor}`, margin: '8px 24px' }}
        />
    )
}

function getSelectedFilters(filter_data, props) {
    props.onSelect(filter_data);
}

function renderList(props) {
    return (
        <PropertyList
            board_id={props.board_id}
            selected_filter_values={props.selected_filter_values}
            filter_fields={props.comm_schema_fields}
            community_id={props.societyId}
            renderItemView={(prop, filter_data) => renderItemView(prop, filter_data, props)}
            groupByFloor={true}
            history={props.history}
            API_TYPE={props.API_TYPE}
            filtersOnly={props.filtersOnly}
            partialFiltersAllowed={props.partialFiltersAllowed}
            getSelectedFilters={(filter_data) => getSelectedFilters(filter_data, props)}
        />
    );
}

function SelectProperty(props) {
    return (
        <div className="nonAppBar">
            <MyAppBar
                title={props.title || `Select Property`}
                className="myAppBar"
                elementLeft={<CrossButton history={props.history} />}
            />
            {renderList(props)}
        </div>
    )
}

export default SelectProperty;