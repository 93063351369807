import React from 'react';
import ReactDOM from 'react-dom';

import SideNavBar from './components/side-nav-bar'
import './styles.css';

class LeftPanel extends React.Component {
    render() {
        return ReactDOM.createPortal(
            <SideNavBar {...this.props} />,
            document.querySelector('#left-panel')
        )
    }
}

export default LeftPanel;