import axios from 'middleware/axios';
import { product_conversion_values_kg, product_conversion_values_lb, country_selected } from 'config/storage-variables';
import { COUNTRY_CODE, API_LINK } from 'config/config';
import { isNullOrEmptyObject, isNumber } from 'utils/helper-functions';

export const kgLbConversion = (flag, value) => {
    if (flag === 1) {
        value = value * 2.2046;                //   flag=1 convert kg to lb
    }
    else if (flag === 0) {
        value = value * 0.4536;                //   flag=0 convert lb to kg
    }
    return value;
};

export const calculateWeight = cart_items =>
    new Promise((resolve, reject) => {
        if (isNullOrEmptyObject(cart_items)) {
            reject(null)
        }

        getConversionValues()
            .then(conversion_values => {
                let weight = 0;

                for (const item_key of Object.keys(cart_items)) {
                    for (const psku_key of Object.keys(cart_items[item_key].psku_cart)) {
                        const psku = cart_items[item_key].psku_cart[psku_key];
                        if (conversion_values[psku.punit_code]) {
                            const weightWithoutUnits = parseInt(psku.psize) * psku.cartCount;
                            weight += parseFloat(parseFloat(weightWithoutUnits * conversion_values[psku.punit_code]).toFixed(2));
                        }
                    }
                }

                resolve(weight)
            })
            .catch(error => reject(error))
    })

export const getMaxWeightUnit = () => {
    let code = localStorage.getItem(country_selected) ? localStorage.getItem(country_selected).toLowerCase() : "in";
    return code === COUNTRY_CODE.USA ? "lb" : "kg";
}

export function getConversionValues() {
    return new Promise((resolve, reject) => {
        let code = localStorage.getItem(country_selected) ? localStorage.getItem(country_selected).toLowerCase() : "in";

        if (code == COUNTRY_CODE.USA && sessionStorage.getItem(product_conversion_values_lb)) {
            resolve(JSON.parse(sessionStorage.getItem(product_conversion_values_lb)));

        } else if (code == COUNTRY_CODE.INDIA && sessionStorage.getItem(product_conversion_values_kg)) {
            resolve(JSON.parse(sessionStorage.getItem(product_conversion_values_kg)));

        } else {
            let apiParam = code === COUNTRY_CODE.USA ? "lb" : "kg";

            axios.get(`${API_LINK}/gs_Product_Order/gs_getConversionValues?kg/lb=${apiParam}`)
                .then(res => {
                    if (res && res.data && res.data.gsRespData && res.data.gsRespData.conversion_values) {
                        const conversion_values = res.data.gsRespData.conversion_values;

                        sessionStorage.setItem(
                            code == COUNTRY_CODE.USA ? product_conversion_values_lb : product_conversion_values_kg,
                            JSON.stringify(conversion_values)
                        );

                        resolve(conversion_values)

                    } else {
                        reject(res.data)
                    }
                })
                .catch(err => {
                    if (err.response && err.response.data && err.response.data.error) {
                        if (err.response.data.error.statusCode === 401) {
                            reject({ ...err.response.data.error, is_logout: true });
                        } else {
                            reject(err.response.data.error);
                        }
                    } else {
                        reject(err);
                    }
                })

        }
    })
}