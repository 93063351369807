import { REHYDRATE } from 'redux-persist';
import { MENU_ITEM_CUSTOMIZATION } from '../../../actions/types';

const INITIAL_STATE = {
    menu_item: undefined,
    selected_cat_id: undefined,
};

const FoodCustomization = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case REHYDRATE:
            return action.payload && action.payload.food_customization
                ? { ...action.payload.food_customization }
                : INITIAL_STATE;

        case MENU_ITEM_CUSTOMIZATION:
            const { menu_item, selected_cat_id } = action.payload;
            return { menu_item: menu_item, selected_cat_id: selected_cat_id || state.selected_cat_id };

        default:
            return state;
    }
};

export default FoodCustomization;
