import { REHYDRATE } from 'redux-persist'

import ApiError, { handleNetworkError } from '../../utils/api-error'
import { LOGOUT_RESET, SOCIETY_DEPARTMENTS, SOCIETY_DEPARTMENTS_SUCCESS, SOCIETY_DEPARTMENTS_ERROR } from '../../actions/types';

const INITIAL_STATE = {
    departments: undefined,
    all_sub_departments: [],
    cache: undefined,
    error: undefined,
    loading: true,
    is_logout: false
}

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case REHYDRATE: if (action.payload && action.payload.society_departments) return { ...action.payload.society_departments }; else return INITIAL_STATE;

        case SOCIETY_DEPARTMENTS: return { ...INITIAL_STATE, loading: true };

        case SOCIETY_DEPARTMENTS_SUCCESS:
            const apiData = action.payload && action.payload.apiData;
            const cache = action.payload && action.payload.cache;

            if (apiData !== undefined && apiData !== null) {
                let departments = ApiError(apiData.statusCode, apiData.message, apiData.gsRespData, "departments_list", []);
                if (departments) {
                    let all_sub_departments = [];
                    for (const department of departments) {
                        if (department.subdepartment_list && department.subdepartment_list.length > 0) {
                            all_sub_departments = [...all_sub_departments, ...department.subdepartment_list];
                        }
                    }
                    return { ...state, loading: false, is_logout: false, departments, all_sub_departments, cache }

                } else {
                    return { ...state, loading: false, is_logout: false, error: departments };
                }

            } else {
                return { ...state, loading: false, is_logout: false };
            }

        case SOCIETY_DEPARTMENTS_ERROR:
            let hNE_res = handleNetworkError(action);
            if (hNE_res.error)
                return { ...INITIAL_STATE, ...hNE_res, loading: false, is_logout: false };
            else if (action.payload && action.payload.response && action.payload.response.data && action.payload.response.data.error) {
                if (action.payload.response.data.error.statusCode === 401)
                    return { ...INITIAL_STATE, error: action.payload.response.data.error, loading: false, is_logout: true };
                else
                    return { ...INITIAL_STATE, error: action.payload.response.data.error, loading: false, is_logout: false };
            }
            else
                return { ...INITIAL_STATE, error: action.payload, loading: false, is_logout: false };

        case LOGOUT_RESET:
            return { ...INITIAL_STATE };

        default: return state;
    }
}