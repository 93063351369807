import React from 'react';

import styles from './styles';

class SocialMediaPlugin extends React.PureComponent {
    render() {
        const pluginWidth = ((window.innerWidth - 600) / 2) - 48;
        const pluginHeight = (window && window.innerHeight) ? window.innerHeight - 32 : 650;
        const showTimeline = pluginHeight > 400
        return (
            <div className="hideTabView" style={styles.OuterDiv}>
                <div className="fb-page"
                    data-href="https://www.facebook.com/olagateapp"
                    data-tabs={showTimeline ? "timeline" : ""}
                    data-width={pluginWidth}
                    data-height={pluginHeight}
                    data-small-header="true"
                    data-adapt-container-width="true"
                    data-hide-cover="false"
                    data-show-facepile="true"
                    hide_cta="true">
                    <blockquote cite="https://www.facebook.com/olagateapp" className="fb-xfbml-parse-ignore">
                        <a href="https://www.facebook.com/olagateapp"
                            style={{ color: "white" }}>
                            OlaGate: Your Digital Neighborhood Platform
                        </a>
                    </blockquote>
                </div>
            </div>
        )
    }
}

export default SocialMediaPlugin;