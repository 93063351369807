import {
    propertyDetailsRoute,
    propertyTransactionsRoute,
    propertyVisitorRoute,
    entryPassRoute,
    complaintsRoute,
    propertyVehiclesRoute,
    propertyParcelRoute,
    propertySettingsRoute,
    gateApprovalsRoute,
    emergencyRoute,
    pollsRoute
} from 'routes-parameters/config';

import VisitorIcon from '../../../../society/assets/visitor.svg';
import PropertyIcon from '../../../../society/assets/property.svg';
import PropTransactionIcon from '../../../../society/assets/property-accounts.svg';
import EntryPassIcon from '../../../../society/assets/id-card.svg';
import ComplaintsIcon from '../../../../society/assets/complaint.svg';
import GarageIcon from '../../../../society/assets/garage.png';
import ParcelDeliveryIcon from '../../../../society/assets/parcel.png';
import SettingsIcon from '../../../../society/assets/settings.png';
import GateApprovalIcon from '../../../../society/assets/gate-approval.svg';
import EmergencyIcon from '../../../../society/assets/emergency.svg';
import PollIcon from '../../../../society/assets/poll.svg';

import { UI_CONFIG, getUIOverride, UI_DISPLAY_TYPES } from '../../../config';
import { sortFeatureCards } from '../../../util';

export const myPropertyCard = {
    title: "Details",
    image: "",
    svgImage: PropertyIcon,
    color: "",
    route: `/${propertyDetailsRoute}`
}

const myVehiclesCard = {
    title: "Vehicles",
    image: "",
    svgImage: GarageIcon,
    color: "",
    route: `/${propertyVehiclesRoute}`
}

export function societyCards(ui_config, isBoardMember) {
    if (!ui_config) {
        ui_config = {};
    }
    let cards = [];
    if (!isBoardMember && [UI_DISPLAY_TYPES.SHOW, UI_DISPLAY_TYPES.DISABLE].includes(getUIOverride(UI_CONFIG.transactions, ui_config).display) &&
        [UI_DISPLAY_TYPES.SHOW, UI_DISPLAY_TYPES.DISABLE].includes(getUIOverride(UI_CONFIG.property_ledger, ui_config).display)) {
        cards.push({
            title: getUIOverride(UI_CONFIG.property_ledger, ui_config).title || "Property Ledger",
            image: "",
            svgImage: PropTransactionIcon,
            color: "",
            route: `/${propertyTransactionsRoute}`,
            disable: getUIOverride(UI_CONFIG.transactions, ui_config).display === UI_DISPLAY_TYPES.DISABLE ||
                getUIOverride(UI_CONFIG.property_ledger, ui_config).display === UI_DISPLAY_TYPES.DISABLE
        });
    }
    if ([UI_DISPLAY_TYPES.SHOW, UI_DISPLAY_TYPES.DISABLE].includes(getUIOverride(UI_CONFIG.emergency, ui_config).display)) {
        cards.push({
            title: getUIOverride(UI_CONFIG.emergency, ui_config).title || "Panic Alert",
            image: "",
            svgImage: EmergencyIcon,
            color: "",
            route: `/${emergencyRoute}`,
            disable: getUIOverride(UI_CONFIG.emergency, ui_config).display === UI_DISPLAY_TYPES.DISABLE
        });
    }
    if (!isBoardMember &&
        [UI_DISPLAY_TYPES.SHOW, UI_DISPLAY_TYPES.DISABLE].includes(getUIOverride(UI_CONFIG.complaint, ui_config).display)) {
        cards.push({
            title: getUIOverride(UI_CONFIG.complaint, ui_config).title || "Complaints",
            image: "",
            svgImage: ComplaintsIcon,
            color: "",
            route: `/${complaintsRoute}`,
            disable: getUIOverride(UI_CONFIG.complaint, ui_config).display === UI_DISPLAY_TYPES.DISABLE
        });
    }
    if ([UI_DISPLAY_TYPES.SHOW, UI_DISPLAY_TYPES.DISABLE].includes(getUIOverride(UI_CONFIG.gate_approvals, ui_config).display)) {
        cards.push({
            title: getUIOverride(UI_CONFIG.gate_approvals, ui_config).title || "Approvals",
            image: "",
            svgImage: GateApprovalIcon,
            color: "",
            route: `/${gateApprovalsRoute}`,
            disable: getUIOverride(UI_CONFIG.gate_approvals, ui_config).display === UI_DISPLAY_TYPES.DISABLE
        });
    }    
    if ([UI_DISPLAY_TYPES.SHOW, UI_DISPLAY_TYPES.DISABLE].includes(getUIOverride(UI_CONFIG.resident_poll, ui_config).display)) {
        cards.push({
            title: getUIOverride(UI_CONFIG.resident_poll, ui_config).title || "Poll & Survey",
            image: "",
            svgImage: PollIcon,
            color: "",
            route: `/${pollsRoute}`,
            disable: getUIOverride(UI_CONFIG.resident_poll, ui_config).display === UI_DISPLAY_TYPES.DISABLE
        });
    }       
    if ([UI_DISPLAY_TYPES.SHOW, UI_DISPLAY_TYPES.DISABLE].includes(getUIOverride(UI_CONFIG.visitors, ui_config).display)) {
        cards.push({
            title: getUIOverride(UI_CONFIG.visitors, ui_config).title || "Visitors",
            image: "",
            svgImage: VisitorIcon,
            color: "",
            route: `/${propertyVisitorRoute}`,
            disable: getUIOverride(UI_CONFIG.visitors, ui_config).display === UI_DISPLAY_TYPES.DISABLE
        });
    }    
    if ([UI_DISPLAY_TYPES.SHOW, UI_DISPLAY_TYPES.DISABLE].includes(getUIOverride(UI_CONFIG.visitors, ui_config).display) &&
    (
        (isBoardMember && [UI_DISPLAY_TYPES.SHOW, UI_DISPLAY_TYPES.DISABLE].includes(getUIOverride(UI_CONFIG.entry_pass_board, ui_config).display)) ||
        (!isBoardMember && [UI_DISPLAY_TYPES.SHOW, UI_DISPLAY_TYPES.DISABLE].includes(getUIOverride(UI_CONFIG.entry_pass, ui_config).display))
    )) {
    cards.push({
        title: getUIOverride(UI_CONFIG.entry_pass, ui_config).title || "Entry Pass",
        image: "",
        svgImage: EntryPassIcon,
        color: "",
        route: `/${entryPassRoute}`,
        disable: getUIOverride(UI_CONFIG.visitors, ui_config).display === UI_DISPLAY_TYPES.DISABLE ||
            (isBoardMember && getUIOverride(UI_CONFIG.entry_pass_board, ui_config).display === UI_DISPLAY_TYPES.DISABLE) ||
            (!isBoardMember && getUIOverride(UI_CONFIG.entry_pass, ui_config).display === UI_DISPLAY_TYPES.DISABLE)
    });
}
    if (
        (isBoardMember && [UI_DISPLAY_TYPES.SHOW, UI_DISPLAY_TYPES.DISABLE].includes(getUIOverride(UI_CONFIG.vehicle_board_office, ui_config).display)) ||
        (!isBoardMember && [UI_DISPLAY_TYPES.SHOW, UI_DISPLAY_TYPES.DISABLE].includes(getUIOverride(UI_CONFIG.vehicle, ui_config).display))
    ) {
        cards.push({
            ...myVehiclesCard,
            title: getUIOverride(UI_CONFIG.vehicle, ui_config).title || myVehiclesCard.title,
            disable: (isBoardMember && getUIOverride(UI_CONFIG.vehicle_board_office, ui_config).display === UI_DISPLAY_TYPES.DISABLE) ||
                (!isBoardMember && getUIOverride(UI_CONFIG.vehicle, ui_config).display === UI_DISPLAY_TYPES.DISABLE)
        });
    }
    if (
        (isBoardMember && [UI_DISPLAY_TYPES.SHOW, UI_DISPLAY_TYPES.DISABLE].includes(getUIOverride(UI_CONFIG.parcel_delivery_board_office, ui_config).display)) ||
        (!isBoardMember && [UI_DISPLAY_TYPES.SHOW, UI_DISPLAY_TYPES.DISABLE].includes(getUIOverride(UI_CONFIG.parcel_delivery, ui_config).display))
    ) {
        cards.push({
            title: getUIOverride(UI_CONFIG.parcel_delivery, ui_config).title || "Mail & Packages",
            image: "",
            svgImage: ParcelDeliveryIcon,
            color: "",
            route: `/${propertyParcelRoute}`,
            disable: (isBoardMember && getUIOverride(UI_CONFIG.parcel_delivery_board_office, ui_config).display === UI_DISPLAY_TYPES.DISABLE) ||
                (!isBoardMember && getUIOverride(UI_CONFIG.parcel_delivery, ui_config).display === UI_DISPLAY_TYPES.DISABLE)
        });
    }
    if (!isBoardMember) {
        cards.push({
            ...myPropertyCard
        });
    }
    cards.push({
        title: "Settings",
        image: "",
        svgImage: SettingsIcon,
        color: "",
        route: `/${propertySettingsRoute}`
    });

    cards = sortFeatureCards(cards);

    return cards;
}