import { REHYDRATE } from 'redux-persist';
import { CUSTOM_BANNERS, CUSTOM_BANNERS_SUCCESS, CUSTOM_BANNERS_ERROR } from '../../actions/types';
import ApiError, { parseError } from '../../utils/api-error';

const INITIAL_STATE = {
    loading: true,
    error: undefined,
    banner_data: undefined,
    cache: undefined
}

const CustomBanners = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case REHYDRATE:
            return action.payload && action.payload.custom_banners
                ? { ...action.payload.custom_banners }
                : INITIAL_STATE;

        case CUSTOM_BANNERS: {
            return {
                ...INITIAL_STATE,
                loading: true,
            }
        }

        case CUSTOM_BANNERS_SUCCESS: {
            const { cache } = action;
            const apiData = action.payload;

            const payload = ApiError(apiData.statusCode, apiData.message, apiData.gsRespData, null, {});

            if (payload.error) {
                return {
                    ...state,
                    error: payload.error,
                    loading: false
                }
            }

            const banner_data = payload && payload.banner_data ? payload.banner_data : undefined;

            let newState = {
                loading: false,
                error: undefined,
                banner_data: banner_data,
                cache: cache
            };

            return newState;
        }

        case CUSTOM_BANNERS_ERROR: {
            return {
                ...state,
                ...parseError(action)
            }
        }

        default:
            return state;
    }
};

export default CustomBanners;