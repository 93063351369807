import React from 'react'

import SectionHeader from '../../section-header'
import SectionVerticalScroll from '../../section-vertical-scroll'

import SectionItemCard from '../../section-item-card'

import { societyCards } from './config'
import { getUIOverride, UI_CONFIG } from '../../../config'

function SectionProperty({ society_ui_config, isBoardMember }) {

    const getTitle = () => {
        if(isBoardMember) {
            return getUIOverride(UI_CONFIG.board_office, society_ui_config).title || "Board Office";
        } else {
            return getUIOverride(UI_CONFIG.my_property, society_ui_config).title || "My Property";
        }
    }


    return (
        <div>
            <SectionHeader title={getTitle()} />
            <SectionVerticalScroll
                data={societyCards(society_ui_config, isBoardMember).map(data => <SectionItemCard key={data.route} {...data} />)}
                maxHorizontalItems={4}
            />
        </div>
    )
}

export default SectionProperty