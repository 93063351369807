import { REHYDRATE } from 'redux-persist';
import {
  SERVICE_CATEGORY, SERVICE_CATEGORY_SUCCESS, SERVICE_CATEGORY_ERROR,
  GET_NEARBY_SERVICES, GET_NEARBY_SERVICES_SUCCESS, GET_NEARBY_SERVICES_ERROR
} from '../../actions/types';
import { service_category, fetch_service_category } from 'config/storage-variables';
import ApiError, { handleNetworkError } from '../../utils/api-error';

const INITIAL_STATE = {
  // service_category: localStorage.getItem(service_category) === null ? null : JSON.parse(localStorage.getItem(service_category)),
  service_category: null,
  error: undefined,
  loading: true,
  is_logout: false
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case REHYDRATE:
      if (action.payload && action.payload.service_category)
        return { ...action.payload.service_category };
      else
        return INITIAL_STATE;

    case GET_NEARBY_SERVICES:
    case SERVICE_CATEGORY:
      return { ...INITIAL_STATE, loading: true, is_logout: false };

    case GET_NEARBY_SERVICES_SUCCESS:
      const apiData = action.payload;
      const payload = ApiError(apiData.statusCode, apiData.message, apiData.gsRespData, null, {});
      sessionStorage.setItem(fetch_service_category, 'true');

      if (payload.error) {
        return {
          ...state,
          error: payload.error,
          loading: false
        }
      }

      let category_list = [];
      if (payload && payload.cat_list && payload.cat_list.constructor === Array) {
        category_list = payload.cat_list.map(item => ({
          category_icon: item.cat_icon,
          category_id: item.cat_id,
          category_name: item.cat_name,
          status: "active"
        }));
      }
      // localStorage.setItem(service_category, JSON.stringify(category_list));

      let newState = {
        ...INITIAL_STATE,
        loading: false,
        error: undefined,
        service_category: category_list,
      };

      return newState;

    case SERVICE_CATEGORY_SUCCESS:
      sessionStorage.setItem(fetch_service_category, 'true');
      if (action.payload !== undefined && action.payload !== null) {
        let resp = ApiError(action.payload.statusCode, action.payload.message, action.payload.gsRespData, null, []);
        if (resp.length > 0) {
          // if (JSON.stringify(INITIAL_STATE.service_category) !== JSON.stringify(resp)) {
          //   localStorage.setItem(service_category, JSON.stringify(resp));
          // }
          return { ...INITIAL_STATE, service_category: resp, loading: false, is_logout: false };
        }
        else
          return { ...INITIAL_STATE, loading: false, is_logout: false };
      }
      else
        return { ...state, loading: false, is_logout: false };

    case GET_NEARBY_SERVICES_ERROR:
    case SERVICE_CATEGORY_ERROR:
      let hNE_res = handleNetworkError(action);
      if (hNE_res.error)
        return { ...INITIAL_STATE, ...hNE_res, loading: false, is_logout: false };
      else if (action.payload && action.payload.response && action.payload.response.data && action.payload.response.data.error) {
        if (action.payload.response.data.error.statusCode === 401)
          return { ...INITIAL_STATE, error: action.payload.response.data.error, service_category: [], loading: false, is_logout: true };
        else
          return { ...INITIAL_STATE, error: action.payload.response.data.error, service_category: [], loading: false, is_logout: false };
      }
      else
        return { ...INITIAL_STATE, error: action.payload, service_category: [], loading: false, is_logout: false };

    default: return state;
  }
}