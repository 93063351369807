import axios from 'middleware/axios';
import { API_LINK } from 'config/config';
import { cl_access_token } from 'config/storage-variables';

export function checkIfPropertyExists(community_id, board_id, prop_unit, prop_type, prop_details, cb) {
    let access_token = localStorage.getItem(cl_access_token);

    let prop_details_str = null;
    if (prop_details !== null || prop_details !== undefined) {
        prop_details_str = JSON.stringify(prop_details)
    }

    let data = `community_id=${community_id}&prop_unit=${prop_unit}&prop_type=${prop_type}&prop_details=${prop_details_str}&access_token=${access_token}`;
    if (board_id) {
        data += '&board_id=' + board_id;
    }

    axios.get(`${API_LINK}/gs_Community/gs_getCommunityPropertyExists?${data}`)
        .then(res => {
            if (res && res.data && res.data.statusCode.toString().slice(0, 2) == 20) {
                cb(res.data.gsRespData, null);
            } else {
                cb(null, res.data);
            }
        })
        .catch(err => {
            if (err.response && err.response.data && err.response.data.error) {
                if (err.response.data.error.statusCode === 401) {
                    cb(null, { ...err.response.data.error, is_logout: true });
                } else {
                    cb(null, err.response.data.error);
                }
            } else {
                cb(null, err);
            }
        })
}

export function registerProperty(addProperty, cb) {
    let access_token = localStorage.getItem(cl_access_token);
    axios.post(`${API_LINK}/gs_Community/gs_postAddCommunityProperty?access_token=${access_token}`, addProperty)
        .then(res => {
            if (res && res.data && res.data.statusCode.toString().slice(0, 2) == 20) {
                cb(res.data.gsRespData, null);
            } else {
                cb(null, res.data);
            }
        })
        .catch(err => {
            if (err.response && err.response.data && err.response.data.error) {
                if (err.response.data.error.statusCode === 401) {
                    cb(null, { ...err.response.data.error, is_logout: true });
                } else {
                    cb(null, err.response.data.error);
                }
            } else {
                cb(null, err);
            }
        })
}

export function reportDispute(prop_id, cb) {
    let access_token = localStorage.getItem(cl_access_token);
    axios.post(`${API_LINK}/gs_Community/gs_postReportPropConflict?access_token=${access_token}`, { prop_id })
        .then(res => {
            if (res && res.data && res.data.statusCode.toString().slice(0, 2) == 20) {
                cb(res.data.gsRespData, null);
            } else {
                cb(null, res.data);
            }
        })
        .catch(err => {
            if (err.response && err.response.data && err.response.data.error) {
                if (err.response.data.error.statusCode === 401) {
                    cb(null, { ...err.response.data.error, is_logout: true });
                } else {
                    cb(null, err.response.data.error);
                }
            } else {
                cb(null, err);
            }
        })
}