import React from 'react';
import OldBottomNav from './old-bottom-nav';
import BottomNav from './bottom-nav';
import { sp_signedIn } from 'config/storage-variables';

function BottomNavigation(props) {
    const is_sp = localStorage.getItem(sp_signedIn) === 'true';

    if (!is_sp) {
        return (
            <BottomNav {...props} />
        )
    } else {
        return (
            <OldBottomNav {...props} />
        )
    }
}

export default BottomNavigation;