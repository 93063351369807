import { REHYDRATE } from 'redux-persist'

import ApiError, { handleNetworkError } from '../../utils/api-error'
import {
    SOCIETY_MESSAGE_DETAILS, SOCIETY_MESSAGE_DETAILS_SUCCESS, SOCIETY_MESSAGE_DETAILS_ERROR,
    LOGOUT_RESET
} from '../../actions/types';
import { parseMsgDetailsApiData } from '../../scenes/client/scenes/society/scenes/message-board/config';

const INITIAL_STATE = {
    message_details: {},
    cache: null,
    error: undefined,
    loading: true,
    is_logout: false
}

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case REHYDRATE: if (action.payload && action.payload.society_message_details) return { ...action.payload.society_message_details }; else return INITIAL_STATE;

        case SOCIETY_MESSAGE_DETAILS: return { ...INITIAL_STATE, loading: true };

        case SOCIETY_MESSAGE_DETAILS_SUCCESS:
            const apiData = action.payload && action.payload.apiData;
            const cache = action.payload && action.payload.cache;

            if (apiData !== undefined && apiData !== null) {
                let message_details = ApiError(apiData.statusCode, apiData.message, apiData.gsRespData, "message_details", {});
                if (message_details && !message_details.error) {
                    message_details = parseMsgDetailsApiData(message_details);
                    return { ...state, loading: false, is_logout: false, message_details, cache }

                } else {
                    return { ...state, loading: false, is_logout: false, error: message_details };
                }
            } else {
                return { ...state, loading: false, is_logout: false };
            }

        case SOCIETY_MESSAGE_DETAILS_ERROR:
            let hNE_res = handleNetworkError(action);
            if (hNE_res.error)
                return { ...INITIAL_STATE, ...hNE_res, loading: false, is_logout: false };
            else if (action.payload && action.payload.response && action.payload.response.data && action.payload.response.data.error) {
                if (action.payload.response.data.error.statusCode === 401)
                    return { ...INITIAL_STATE, error: action.payload.response.data.error, loading: false, is_logout: true };
                else
                    return { ...INITIAL_STATE, error: action.payload.response.data.error, loading: false, is_logout: false };
            }
            else
                return { ...INITIAL_STATE, error: action.payload, loading: false, is_logout: false };

        case LOGOUT_RESET:
            return { ...INITIAL_STATE };

        default: return state;
    }
}