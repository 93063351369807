import { REHYDRATE } from 'redux-persist';
import { INVOICE_MEDIA_DATA, LOGOUT_RESET, CLEAR_INVOICE_MEDIA_DATA } from '../../actions/types';

const INITIAL_STATE = {};

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case REHYDRATE: if (action.payload && action.payload.invoice_media_data) return { ...action.payload.invoice_media_data }; else return INITIAL_STATE;
        case INVOICE_MEDIA_DATA: return { ...state, ...action.data };
        case CLEAR_INVOICE_MEDIA_DATA: let new_state = { ...state };
            if (action.data) {
                if (action.data === "all") {
                    new_state = INITIAL_STATE;
                }
            }
            return new_state;
        case LOGOUT_RESET: return { ...INITIAL_STATE };

        default: return state;
    }
}