import React, { PureComponent } from 'react'

import LeftArrow from 'material-ui/svg-icons/hardware/keyboard-arrow-left';
import RightArrow from 'material-ui/svg-icons/hardware/keyboard-arrow-right';

import gs_color from 'config/colors.css'
import commonStyles from './styles'
import './styles.css'

const left = 'left', right = 'right'
class SectionHorizontalScroll extends PureComponent {

    renderData = data => {
        const maxHorizontalItems = this.props.maxHorizontalItems || 3

        if (data && data.length > 0) {
            let array = [ ...data ]

            return (
                <>
                    {array}
                    {array.length > 0 && array.length > maxHorizontalItems && this.renderScrollButtons("left")}
                    {array.length > 0 && array.length > maxHorizontalItems && this.renderScrollButtons("right")}
                </>
            );
        }
    }

    renderScrollButtons = type => {
        const classNameScrollButton = type === left ? "home-screen-grid-scroll-left" : type === right ? "home-screen-grid-scroll-right" : "";

        return (
            <li id={type} onClick={() => this.scrollOnClick(type)} className={`${classNameScrollButton}`}>
                {type === left ? <LeftArrow style={{ width: "26px", height: "26px" }} /> : type === right ? <RightArrow style={{ width: "26px", height: "26px" }} /> : null}
            </li>
        );
    }

    scrollOnClick = type => {
        if (type === left) {
            this.el.scrollBy({
                left: -100,
                behavior: 'smooth'
            });
        }
        else if (type === right) {
            this.el.scrollBy({
                left: 100,
                behavior: 'smooth'
            });
        }
    }

    render() {
        return (
            <div ref={el => { this.el = el; }} style={commonStyles.horizontalScroll}>
                {this.renderData(this.props.data || [])}
            </div>
        )
    }
}

export default SectionHorizontalScroll