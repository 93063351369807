import { REHYDRATE } from 'redux-persist';
import { FILTER_RESTAURANT_DATA, GET_RESTAURANT_DATA, GET_RESTAURANT_DATA_ERROR, GET_RESTAURANT_DATA_SUCCESS } from '../../../actions/types';
import { CURRENCY_MAPPING } from '../../../config/config';
import ApiError, { parseError } from '../../../utils/api-error';

const sampleState = {
    loading: true,
    error: undefined,
    comp_id: undefined,
    opunit_id: undefined,
    cache: undefined,
    menu: [],
    filtered_menu: [],
    restaurant_details: undefined,
    product_list: {},
    filters: undefined,
    currency: CURRENCY_MAPPING['USD'],
    isd_code: '91',
    country_code: '',
    not_found: false,
}

const INITIAL_STATE = {
    data: {},
    cachedUrls: []
}


const Restaurant = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case REHYDRATE:
            return action.payload && action.payload.restaurant
                ? { ...action.payload.restaurant }
                : INITIAL_STATE;

        case GET_RESTAURANT_DATA: {
            const { page_url } = action;

            return {
                ...state,
                data: {
                    ...state.data,
                    [`${page_url}`]: {
                        loading: true
                    }
                }
            }
        }

        case GET_RESTAURANT_DATA_SUCCESS: {
            const { page_url } = action;
            const apiData = action.payload;

            const payload = ApiError(apiData.statusCode, apiData.message, apiData.gsRespData, null, {});

            if (payload.error) {
                return {
                    ...state,
                    data: {
                        ...state.data,
                        [`${page_url}`]: { error: payload.error, loading: false }
                    }
                }
            }

            if (payload.not_found) {
                return { ...state, data: { ...state.data, [`${page_url}`]: { not_found: true, loading: false } } }
            }

            const { menu_details, ...rest } = payload ? payload.hcomp_info : {};

            let newState = {
                loading: false,
                error: undefined,
                comp_id: rest && rest.comp_id,
                opunit_id: rest && rest.opunit_id,
                cache: action.cache,
                menu: [],
                filtered_menu: [],
                product_list: {},
                isd_code: '91',
                country_code: '',
            };
            let product_list = {};

            if (menu_details) {
                // Restructure menu data coming from server.
                let menu_obj = {};
                let menu = [];

                menu_details.forEach((item) => {
                    if (item.mitem_srvtype !== "addon") {
                        let cat_item_obj = {};
                        if (!menu_obj[item.mcat_id]) {
                            cat_item_obj = {
                                cat_id: item.mcat_id,
                                cat_name: item.mcat_name,
                                items: []
                            };
                        }
                        else {
                            cat_item_obj = menu_obj[item.mcat_id];
                        }

                        const mitem_rate_obj = item.mitem_rates && item.mitem_rates.length > 0 ? item.mitem_rates.reduce((arr, cur) => (!arr.mitem_rate || arr.mitem_rate > cur.mitem_rate ? cur : arr), {}) : {};

                        let menu_item_obj = {
                            item_id: item.mitem_id,
                            item_name: item.mitem_name,
                            item_desc: item.mitem_desc,
                            item_img: item.mitem_icon,
                            item_type: item.mitem_type,
                            is_available: item.is_available,
                            item_price: mitem_rate_obj.mitem_rate || 0,
                            item_discnt_price: mitem_rate_obj.mitem_discntrate || undefined,
                            item_priceid: mitem_rate_obj.miprice_id || 0,
                        }
                        if (item.mitem_rates) {
                            menu_item_obj.sizes = item.mitem_rates.map(mitem_rate => ({
                                price_id: mitem_rate.miprice_id,
                                title: mitem_rate.mip_title || "",
                                item_size: mitem_rate.mitem_size,
                                price: mitem_rate.mitem_rate,
                                discnt_rate: mitem_rate.mitem_discntrate || undefined,
                            }));
                            menu_item_obj.sizes_obj = {};
                            menu_item_obj.sizes.forEach(size => {
                                menu_item_obj.sizes_obj[size.price_id] = size;
                            });
                        }

                        let menu_item_addons = [];
                        if (item.addon_list) {
                            item.addon_list.forEach(add_on => {
                                menu_item_addons.push({
                                    item_id: add_on.addon_id,
                                    item_name: add_on.addon_name,
                                    item_price: add_on.addon_rates[0].mitem_rate,
                                });
                            });
                            menu_item_obj.add_ons = menu_item_addons;
                        }

                        cat_item_obj.items.push(menu_item_obj);
                        menu_obj[item.mcat_id] = cat_item_obj;
                    }
                });

                Object.keys(menu_obj).forEach(key => menu.push(menu_obj[key]));

                newState.menu = menu.filter((cat) => cat.items.length > 0);
                newState.filtered_menu = newState.menu;
                menu.forEach((cat) => {
                    if (cat && cat.items) {
                        cat.items.forEach(item => {
                            product_list[item.item_id] = { ...item, cat_id: cat.cat_id, cat_name: cat.cat_name };
                        });
                    }
                });
            }

            newState.restaurant_details = rest;
            newState.product_list = product_list;
            newState.order_cancel_time = rest.order_cancel_time;
            newState.review_options = rest.review_options;
            newState.user_cancel_options = rest.cancel_options && rest.cancel_options.customer ? rest.cancel_options.customer : [];
            newState.currency = rest && rest.currency ? CURRENCY_MAPPING[rest.currency] || rest.currency : CURRENCY_MAPPING['USD'];
            newState.isd_code = rest.isd_code || '91';
            newState.country_code = rest.country_code || '';
            newState.payment_gateway = rest.payment_gateway;



            const new_state = { ...state };

            const cachedUrls = [...state.cachedUrls];

            if (cachedUrls.length === 3) {
                const popUrl = cachedUrls.shift();
                delete new_state.data[`${popUrl}`];
            }

            cachedUrls.push(`${page_url}`);

            new_state.data[`${page_url}`] = newState;
            new_state.cachedUrls = cachedUrls;

            return new_state;
        }

        case GET_RESTAURANT_DATA_ERROR: {
            const { page_url } = action;

            return {
                ...state,
                data: {
                    ...state.data,
                    [`${page_url}`]: parseError(action)
                }
            }
        }

        case FILTER_RESTAURANT_DATA: {
            const { page_url } = action;

            const { is_veg } = action.payload;
            let new_state = { ...state.data[`${page_url}`] };
            const data = state.data[`${page_url}`];

            if (is_veg) {
                let filtered_menu = [];
                filtered_menu = data.menu.map((cat) => {
                    return {
                        ...cat,
                        items: cat.items.filter(item => item.item_type === "veg")
                    }
                });
                filtered_menu = filtered_menu.filter(cat => cat.items.length > 0);

                new_state.filtered_menu = filtered_menu;
                new_state.filters = { is_veg: true };
            }
            else {
                new_state.filtered_menu = data.menu;
                new_state.filters = { is_veg: false };
            }

            return { ...state, data: { ...state.data, [`${page_url}`]: new_state } };
        }

        default:
            return state;
    }
};

export default Restaurant;