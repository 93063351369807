import React, { PureComponent } from 'react';
import Paper from 'material-ui/Paper';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { faCircle } from '@fortawesome/free-regular-svg-icons';

import FlatButton from 'material-ui/FlatButton';
import { grey400 } from 'material-ui/styles/colors';
import MoreVertIcon from 'material-ui/svg-icons/navigation/more-vert';
import IconButton from 'material-ui/IconButton';
import IconMenu from 'material-ui/IconMenu';
import MenuItem from 'material-ui/MenuItem';

import { getAddressId, getAddressSocietyName, getAddressPropertyList, getFormattedAddress } from '../../../../client/scenes/products/config';
import gs_color from 'config/colors.css';

class AddressItem extends PureComponent {
    renderPropertyItem(property, address_id, selected) {
        const { prop_id, unit_name } = property || {};

        return (
            <div key={prop_id} style={{ margin: '8px', padding: '8px 0px', display: 'flex' }}>
                <div
                    style={{ width: '10%', textAlign: 'center', fontSize: '16px', cursor: 'pointer' }}
                    onClick={() => this.props.onClick && this.props.onClick(address_id, prop_id)}>
                    <FontAwesomeIcon
                        className='svg-inline--fa'
                        icon={selected ? faCheckCircle : faCircle}
                        color={gs_color.primaryColor}
                    />
                </div>
                <div style={{ width: '90%', paddingLeft: '8px', paddingRight: '12px', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    <div style={{ width: '100%' }}>
                        {unit_name && <div >{`${unit_name}`}</div>}
                    </div>
                </div>
            </div >
        )
    }

    renderProperties(propertyList, address_id, selectedPropId) {
        if (!propertyList || propertyList.length === 1) {
            return null;
        }

        return (
            <Paper style={{ marginTop: '8px', marginBottom: '16px', padding: '4px 0px' }}>
                {propertyList.map(prop => this.renderPropertyItem(prop, address_id, selectedPropId === prop.prop_id))}
            </Paper>
        );
    }

    render() {
        const address = this.props.address;
        const selected = !!this.props.isSelected;
        const societyName = getAddressSocietyName(address);
        const propertyList = getAddressPropertyList(address);
        const propertyName = propertyList && propertyList.length === 1 ? propertyList[0].unit_name : undefined;
        const formattedAddress = getFormattedAddress(address);
        const address_id = getAddressId(address);

        // const iconButtonElement = (
        //     <IconButton
        //         touch={true}
        //     >
        //         <MoreVertIcon color={grey400} />
        //     </IconButton>
        // );

        // var rightIconMenu = address => {
        //     return (
        //         <IconMenu iconButtonElement={iconButtonElement}>
        //             <MenuItem>Remove</MenuItem>
        //         </IconMenu>
        //     );
        // }

        return (
            <Paper style={{ margin: '10px 5%', borderRadius: '5px', padding: '12px 0px', display: 'flex' }}>
                <div
                    style={{ width: '10%', textAlign: 'center', fontSize: '16px', cursor: 'pointer' }}
                    onClick={() => this.props.onClick && this.props.onClick(address_id, propertyList && propertyList.length === 1 ? propertyList[0].prop_id : undefined)}>
                    <FontAwesomeIcon
                        className='svg-inline--fa'
                        icon={selected ? faCheckCircle : faCircle}
                        color={gs_color.primaryColor}
                    />
                </div>
                <div style={{ width: '90%', paddingLeft: '8px', paddingRight: '12px', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    <div style={{ width: '100%' }}>
                        {societyName && <div style={{ fontSize: '14px', fontWeight: 'bold', paddingBottom: '4px' }}>{`${societyName}`}</div>}
                        {propertyName && <div style={{ paddingBottom: '4px' }}>{`${propertyName}`}</div>}
                        {propertyList && this.renderProperties(propertyList, address_id, this.props.selectedPropId)}
                        {formattedAddress && <div >{`${formattedAddress}`}</div>}
                        {
                            address &&
                            <div style={{ borderTop: `1px solid ${gs_color.backgroundSecondaryColor}`, marginTop: '8px', display: 'flex' }}>
                                <FlatButton
                                    label="Show on Map"
                                    labelStyle={{ fontWeight: '600', padding: "0px", fontSize: '13px' }}
                                    primary={true}
                                    onClick={() => this.props.onShowMap && this.props.onShowMap(address)}
                                />
                            </div>
                        }
                    </div>
                </div>
                {/* {
                    address &&
                    <div style={{ width: '10%', textAlign: 'center' }} >
                        {rightIconMenu(address)}
                    </div>
                } */}
            </Paper>
        )
    }
}

export default AddressItem;