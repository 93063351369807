import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';
import Paper from 'material-ui/Paper';
import LeftArrow from 'material-ui/svg-icons/hardware/keyboard-arrow-left';
import RightArrow from 'material-ui/svg-icons/hardware/keyboard-arrow-right';

import { downloadMedia } from 'utils/handle-media';
import styles from '../styles.jsx';
import './styles.css';
import gs_color from 'config/colors.css';

const left = 'left', right = 'right';
class VerticalHorizontalSwipe extends PureComponent {
    handleCardClick(route, event) {
        if (typeof this.props.handleCardClick === "function") {
            this.props.handleCardClick(route, event);
        }
    }

    renderItem = datum => {
        const ui = <Paper style={styles.outerMemberItem}>
            <img src={downloadMedia(datum.icon)} style={{ height: '80px', width: '80px' }} />
            <div style={styles.memberTtile}>
                {datum.title}
            </div>
        </Paper>;

        if (!datum.redirectUrl) {
            return (
                <div key={datum.id} style={styles.outerMemberLink}>
                    {ui}
                </div>
            )
        }

        return (
            <Link to={datum.redirectUrl ? datum.redirectUrl : null} onClick={(event) => this.handleCardClick(datum.redirectUrl, event)} key={datum.id} style={styles.outerMemberLink}>
                {ui}
            </Link>
        )
    }

    renderViewAllItem = (redirectUrl, icon) => {
        const ui = <Paper style={styles.outerMemberItem}>
            <img src={icon} style={{ height: '80px', width: '80px' }} />
            <div style={{ color: gs_color.primaryColor, fontWeight: 600 }}>Browse All</div>
        </Paper>;

        if (!redirectUrl) {
            return (
                <div key={'VIEW_ALL'} style={styles.outerMemberLink}>
                    {ui}
                </div>
            )
        }

        return (
            <Link to={redirectUrl ? redirectUrl : null} onClick={(event) => this.handleCardClick(redirectUrl, event)} key={'VIEW_ALL'} style={styles.outerMemberLink}>
                {ui}
            </Link>
        )
    }

    renderScrollButtons = type => {
        const classNameScrollButton = type === left ? "home-screen-grid-scroll-left" : type === right ? "home-screen-grid-scroll-right" : "";

        return (
            <li id={type} onClick={() => this.scrollOnClick(type)} className={`${classNameScrollButton}`}>
                {type === left ? <LeftArrow style={{ color: gs_color.backgroundDarkColor, marginTop: "3px", width: "26px", height: "26px" }} /> : type === right ? <RightArrow style={{ color: gs_color.backgroundDarkColor, marginTop: "3px", width: "26px", height: "26px" }} /> : null}
            </li>
        );
    }

    renderData = data => {
        if (data && data.length > 0) {
            let array = []

            data.forEach(datum => {
                array.push(this.renderItem(datum));
            });

            if (this.props.showViewAll) {
                array.push(this.renderViewAllItem(this.props.viewAllRedirect, this.props.viewAllIcon))
            }

            return (
                <>
                    {array}
                    {array.length > 0 && array.length > 4 && this.renderScrollButtons("left")}
                    {array.length > 0 && array.length > 4 && this.renderScrollButtons("right")}
                </>
            );
        }
    }

    scrollOnClick = type => {
        if (type === left) {
            this.el.scrollBy({
                left: -100,
                behavior: 'smooth'
            });
        }
        else if (type === right) {
            this.el.scrollBy({
                left: 100,
                behavior: 'smooth'
            });
        }
    }

    render() {
        return (
            <div ref={el => { this.el = el; }} style={styles.horizontalScroll}>
                {this.renderData(this.props.data)}
            </div>
        )
    }
}

export default VerticalHorizontalSwipe;