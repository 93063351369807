import React from 'react'

import AddIcon from 'material-ui/svg-icons/content/add'
import RemoveIcon from 'material-ui/svg-icons/content/remove'

import gs_color from 'config/colors.css'

export const styles = {
    container: {
        border: `1px solid ${gs_color.gradientLightColor}`,
        borderRadius: '5px',
        padding: '4px 4px'
    },
    labelStyle: {
        backgroundColor: '#fff',
        marginTop: '-18px',
        marginBottom: '4px',
        width: 'fit-content',
        padding: '0px 4px',
        fontWeight: 'bolder',
        fontSize: '1.1em'
    },
    listContainer: {
        display: 'flex',
        flexWrap: 'wrap'
    }
}

function PostFilters({ label, selected, filters, addFilter, removeFilter }) {
    const selectedFilters = selected || []
    const isFilterArray = filters && filters.constructor === Array ? true : false;
    const filtersObj = filters || {}

    return (
        <div style={styles.container}>
            {
                label ?
                    <div style={styles.labelStyle}>{label}</div>
                    : null
            }
            <div style={styles.listContainer}>
                {
                    isFilterArray ?
                        filtersObj.map(type => {
                            if (selectedFilters.includes(type.id)) {
                                return (<SelectedFilter key={type.id} label={type.label} onClick={() => removeFilter(type.id)} />)
                            } else {
                                return (<UnSelectedFilter key={type.id} label={type.label} onClick={() => addFilter(type.id)} />)
                            }
                        })
                        :
                        Object.keys(filtersObj)
                            .map(type => {
                                if (selectedFilters.includes(type)) {
                                    return (<SelectedFilter key={type} label={filters[type]} onClick={() => removeFilter(type)} />)
                                } else {
                                    return (<UnSelectedFilter key={type} label={filters[type]} onClick={() => addFilter(type)} />)
                                }
                            })
                }
            </div>
        </div>
    )
}

function UnSelectedFilter({ label, onClick }) {
    return (
        <div onClick={() => onClick()} style={{ border: `1px solid ${gs_color.primaryColor}`, margin: '4px 4px', padding: '4px 4px', display: 'flex', borderRadius: '3px', color: gs_color.accentColor, cursor: 'pointer' }}>
            <AddIcon style={{ height: '18px', width: '18px' }} color={gs_color.accentColor} />
            <div style={{ marginLeft: '2px' }} >{label}</div>
        </div>
    )
}

function SelectedFilter({ label, onClick }) {
    return (
        <div onClick={() => onClick()} style={{ border: `1px solid ${gs_color.primaryColor}`, margin: '4px 4px', padding: '4px 4px', display: 'flex', borderRadius: '3px', color: '#fff', backgroundColor: gs_color.primaryColor, cursor: 'pointer' }}>
            <RemoveIcon style={{ height: '18px', width: '18px' }} color="#fff" />
            <div style={{ marginLeft: '2px' }} >{label}</div>
        </div>
    )
}

export default PostFilters