export function shouldHidePropAddlDetails(filter_fields) {
    return typeof filter_fields === "string";
}

function parseRegistrationPropObj(prop_obj) {
    let obj = {};

    if (prop_obj) {
        obj = {
            "prop_id": prop_obj.prop_id || prop_obj.value,
            "prop_type": prop_obj.property_type,
            "prop_details": prop_obj.prop_details || null,
            "prop_unit_name": prop_obj.value
        };
    }

    return obj;
}

function removeNonFloorPropDetails(prop_details) {
    if (!prop_details || prop_details.constructor !== Object || !prop_details.floor) {
        return prop_details;
    }

    return { floor: prop_details.floor };
}

export function parseSocietyPropList(prop_list, groupByFloor) {
    let data = [];

    if (prop_list && prop_list.constructor === Array && prop_list.length > 0) {
        if (groupByFloor) {
            let floor_prop_obj = {};
            let leaf_value;
            let leaf_key;

            prop_list.forEach(prop => {
                let obj = {};
                
                if (prop && prop.prop_details && prop.prop_details.floor) {
                    leaf_value = prop.prop_details.floor;
                    leaf_key="floor"
                }
                else if (prop && prop.prop_details && prop.prop_details.dept) {
                    leaf_value = prop.prop_details.dept;
                    leaf_key="dept"
                }
                else if (prop && prop.prop_details && prop.prop_details.lane) {
                    leaf_value = prop.prop_details.lane;
                    leaf_key="lane"
                }
                else if (prop && prop.prop_details && prop.prop_details.cross) {
                    leaf_value = prop.prop_details.cross;
                    leaf_key="cross"
                }
                else if (prop && prop.prop_details && prop.prop_details.sector) {
                    leaf_value = prop.prop_details.sector;
                    leaf_key="sector"
                }
                else if (prop && prop.prop_details && prop.prop_details.road) {
                    leaf_value = prop.prop_details.road;
                    leaf_key="road"
                }
                else if (prop && prop.prop_details && prop.prop_details.drive) {
                    leaf_value = prop.prop_details.drive;
                    leaf_key="drive"
                }
                else if (prop && prop.prop_details && prop.prop_details.street) {
                    leaf_value = prop.prop_details.street;
                    leaf_key="street"
                }
                else if (prop && prop.prop_details && prop.prop_details.place) {
                    leaf_value = prop.prop_details.place;
                    leaf_key="place"
                }
                else {
                    leaf_value = "NA";
                }

                if (!floor_prop_obj[leaf_value]) {
                    floor_prop_obj[leaf_value] = [];
                }

                obj = { ...prop };
                obj.prop_details = removeNonFloorPropDetails(prop.prop_details);
                floor_prop_obj[leaf_value].push(obj);
            });

            let unqiue_floors = Object.keys(floor_prop_obj);
            unqiue_floors = unqiue_floors.filter(leaf_value => leaf_value !== "NA");
            unqiue_floors = unqiue_floors.sort();
            unqiue_floors.forEach(leaf_value => {
                data.push({
                    type: leaf_key,
                    value: leaf_value,
                    data: floor_prop_obj[leaf_value]
                });
            });
            if (floor_prop_obj["NA"]) {
                data.push({
                    type: leaf_key,
                    value: null,
                    data: floor_prop_obj["NA"]
                });
            }
        }
        else {
            data = prop_list.map(prop => {
                let obj = { ...prop };
                obj.prop_details = removeNonFloorPropDetails(prop.prop_details);
                return obj;
            });
        }
    }

    return data;
}

export function parseRegistrationPropList(prop_list, groupByFloor) {
    let data = [];
    let leaf_type="";
    if (prop_list && prop_list.constructor === Array && prop_list.length > 0) {
        if (groupByFloor) {
            let no_floor_props = [];
            prop_list.forEach(prop => {
                let obj = {};
                leaf_type=prop.type;
                if ((prop.type === "floor" || prop.type === "dept" || prop.type === "lane" || 
                    prop.type === "cross" || prop.type === "sector" || prop.type === "road" || 
                    prop.type === "street"|| prop.type === "drive" || prop.type === "place"  ) 
                && prop.data && prop.data.constructor === Array && prop.data.length > 0) {
                    obj.type = prop.type;
                    obj.value = prop.value;
                    obj.data = prop.data.map(item => {
                        return parseRegistrationPropObj({ ...item, prop_id: prop.value + '$$' + item.value });
                    });
                    data.push(obj);
                }
                else if (prop.type === "prop_unit") {
                    obj = parseRegistrationPropObj({ ...prop, prop_id: prop.value, prop_details: null });
                    no_floor_props.push(obj);
                }
            });
            if (no_floor_props.length > 0) {
                data.push({
                    type: leaf_type,
                    value: null,
                    data: no_floor_props
                });
            }
        }
        else {
            prop_list.forEach(prop => {
                let obj = {};
                

                if ((prop.type === "floor" || prop.type === "dept" || prop.type === "lane" || 
                        prop.type === "cross" || prop.type === "sector" || prop.type === "road" || 
                        prop.type === "street"|| prop.type === "drive" || prop.type === "place" 
                   ) 
                    && prop.data && prop.data.constructor === Array && prop.data.length > 0) {
                    
                    let lprop_details = '';
                    if ( prop.type === "lane"){
                        lprop_details='{"lane": ' + prop.value+ '}';
                    } else if ( prop.type === "dept"){
                        lprop_details='{"dept": ' + prop.value+ '}';
                    } else if ( prop.type === "cross"){
                        lprop_details='{"cross": ' + prop.value+ '}';
                    } else if ( prop.type === "sector"){
                        lprop_details='{"sector": ' + prop.value+ '}';
                    } else if ( prop.type === "road"){
                        lprop_details='{"road": ' + prop.value+ '}';                    
                    } else if ( prop.type === "street"){
                        lprop_details='{"street": ' + prop.value+ '}';
                    } else if ( prop.type === "drive"){
                        lprop_details='{"drive": ' + prop.value+ '}';
                    } else if ( prop.type === "place"){
                        lprop_details='{"place": ' + prop.value+ '}';
                    } else {
                        lprop_details='{"floor": ' + prop.value+ '}';
                    }
                    
                    prop.data.forEach(item => {
                        obj = parseRegistrationPropObj({ ...item, prop_id: prop.value + '$$' + item.value, prop_details: lprop_details });
                        /*                            prop.type === "lane" ? { "lane": prop.value} : (
                                                        prop.type === "dept"?{"dept": prop.value} :{"floor": prop.value}
                                                    )) });
                        */
                        data.push(obj);
                    });
                }
                else if (prop.type === "prop_unit") {
                    obj = parseRegistrationPropObj({ ...prop, prop_id: prop.value, prop_details: null });
                    data.push(obj);
                }
            });
        }
    }

    return data;
}

export function parseFiltersForBoard(filter_options, board_id, common_board_id) {
    let data = [];
    if (filter_options && filter_options.constructor === Array) {
        if (!board_id || board_id === common_board_id) {
            return filter_options;
        }
        else {
            filter_options.forEach(option => {
                if (option.board_id === board_id) {
                    data.push(option);
                }
                else if (option.data && option.data.constructor === Array) {
                    let temp_data = parseFiltersForBoard(option.data, board_id, common_board_id);
                    if (temp_data.length > 0) {
                        data.push({ ...option, data: temp_data });
                    }
                }
            });
        }
    }
    return data;
}