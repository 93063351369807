import { REHYDRATE } from 'redux-persist';
import { PRODUCT_COMMUNITY } from '../../actions/types';

const INITIAL_STATE = {
    community_id: undefined
}

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case REHYDRATE: if (action.payload && action.payload.product_community) return { ...action.payload.product_community }; else return INITIAL_STATE;

        case PRODUCT_COMMUNITY:
            if (action.payload) {
                return { community_id: action.payload }
            } else {
                return INITIAL_STATE;
            }

        default: return state;
    }
}