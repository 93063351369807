import React from 'react';

import RaisedButton from 'material-ui/RaisedButton';
import NotFoundIcon from 'scenes/not-found/assets/NotFound.svg';
import gs_color from 'config/colors.css';

export default function ApiErrorRetry(props) {
    let style = { width: '100%', textAlign: 'center' }

    if (props.style) {
        style = Object.assign({}, style, props.style)
    }

    return (
        <div style={style}>
            <>
                {props.showIcon && <img width="82px" height="82px" src={NotFoundIcon} alt="Not Found" />}
            </>
            <div style={{ fontSize: '14px', fontWeight: 'bold' }}>{props && props.error && props.error.message && props.error.message.constructor === String ? props.error.message : "Something went wrong."}</div>
            <RaisedButton
                label="Retry"
                labelStyle={{ color: gs_color.accentColor }}
                backgroundColor={gs_color.primaryColor}
                style={{ margin: '12px 0px' }}
                onClick={() => props.onClick()}
            />
        </div>
    )
}