import React, { PureComponent } from 'react';
import GoogleMapReact from 'google-map-react';
import Marker from 'material-ui/svg-icons/maps/person-pin';
import { GOOGLE_MAP_API } from 'config/config';
import gs_color from 'config/colors.css';

var MAP;

class ShowMap extends PureComponent {
    static defaultProps = {
        landmark: "",
    }

    constructor(props) {
        super(props);
        MAP = {
            defaultZoom: 15,
            defaultCenter: this.props.center,
            options: {
                minZoom: 12,
                maxZoom: 19,
                styles: [
                    {
                        featureType: 'poi',
                        elementType: 'labels',
                        stylers: [{ visibility: 'on' }],
                    },
                ],
            },
        };
    }

    render() {
        return (
            <div style={{ width: '100%', height: '100%', zIndex: 100 }}>
                {
                    this.props.landmark !== ""
                        ? <div style={{ padding: '8px 12px', color: gs_color.textColor, backgroundColor: '#ffffff' }}><span style={{ fontWeight: '600' }}>Landmark: </span>{this.props.landmark}</div>
                        : null
                }
                <GoogleMapReact
                    defaultZoom={MAP.defaultZoom}
                    defaultCenter={MAP.defaultCenter}
                    options={MAP.options}
                    yesIWantToUseGoogleMapApiInternals
                    bootstrapURLKeys={{ key: GOOGLE_MAP_API }}
                    ref={(input) => { this.googleMap = input }}
                >
                    <Marker lat={this.props.center.lat} lng={this.props.center.lng} style={{ width: '30px', height: '30px' }} />
                </GoogleMapReact>
            </div>
        )
    }
}

ShowMap.displayName = 'ShowMap';
export default ShowMap;