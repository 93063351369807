import React from 'react'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';
import { updateMiscellaneousComponents } from 'actions';

import Paper from 'material-ui/Paper'
import SectionHeader from '../../section-header'
import { exploreRoute } from 'routes-parameters/config'
import OGFoodIcon from 'assets/OGFOOD_HD.png'
import OGFreshIcon from 'assets/OGFRESH_HD.png'
import OGCareIcon from 'assets/OGCARE_HD.png'
import gs_color from 'config/colors.css'

function renderImage(svgImage) {
    if (svgImage) {
        return (
            <img src={svgImage} style={{ width: '80px', height: '80px' }} />
        )
    }
    return null;
}

function SectionItemCard({ route, title, svgImage, onClick }) {
    return (
        <Link to={route} onClick={onClick} style={{ display: 'inline-flex', textDecoration: 'none', minHeight: '110px', marginLeft: '8px', marginRight: '8px', width: 'calc(50% - 16px)' }}>
            <Paper style={{ width: '100%', margin: '8px 0', padding: '6px 6px', borderRadius: '5px', position: 'relative', backgroundColor: 'white' }}>
                <div>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '8px 0' }}>
                        {renderImage(svgImage)}
                    </div>
                    <div style={{ whiteSpace:'nowrap', textAlign: 'center', paddingTop: '4px', fontWeight: 'bold', color: gs_color.accentColor, fontSize: '12px' }}>
                        {title || ""}
                    </div>
                </div>
            </Paper>
        </Link>
    )
}

function SectionNeighborhood(props) {

    const getTitle = () => "My Neighborhood"

    const onClick = (index) => {
        props.updateMiscellaneousComponents({ explore_screen_tab: index })
    }

    return (
        <div>
            <SectionHeader title={getTitle()
            } />
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <SectionItemCard route={exploreRoute} svgImage={OGFoodIcon} title="Shop Local" onClick={() => onClick(0)} />
                <SectionItemCard route={exploreRoute} svgImage={OGFreshIcon} title="Farm Fresh" onClick={() => onClick(0)} />
                <SectionItemCard route={exploreRoute} svgImage={OGCareIcon} title="Service Pros" onClick={() => onClick(0)} />
            </div>
            {/*
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <SectionItemCard route={exploreRoute} svgImage={OGFoodIcon} title="Shop Local" onClick={() => onClick(0)} />
                <SectionItemCard route={exploreRoute} svgImage={OGFreshIcon} title="Farm Fresh" onClick={() => onClick(1)} />
                <SectionItemCard route={exploreRoute} svgImage={OGCareIcon} title="Service Pros" onClick={() => onClick(2)} />
            </div>
            */}
        </div>
    )
}


function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        updateMiscellaneousComponents,
    }, dispatch);
}

export default connect(null, mapDispatchToProps)(SectionNeighborhood);