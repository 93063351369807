import { REHYDRATE } from 'redux-persist';
import {
    SOCIETY_GATE_APPROVAL_LIST, SOCIETY_GATE_APPROVAL_LIST_SUCCESS, SOCIETY_GATE_APPROVAL_LIST_ERROR,
    SOCIETY_GATE_APPROVAL_LIST_IGNORE_ENTRY, SOCIETY_GATE_APPROVAL_LIST_REMOVE_ENTRY,
    HIDE_SOCIETY_GATE_APPROVAL, SHOW_SOCIETY_GATE_APPROVAL,
    SOCIETY_AVAILABLE_TRIGGER_ACTION, SOCIETY_NOT_AVAILABLE_TRIGGER_ACTION,
    LOGOUT_RESET
} from '../../actions/types';
import ApiError, { handleNetworkError } from '../../utils/api-error';

const INITIAL_STATE = {
    gate_approval_list: [],
    error: undefined,
    loading: true,
    is_logout: false,
    should_call_api: false,
    should_hide: false,
    ignored_approvals: []
};

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case REHYDRATE: if (action.payload && action.payload.society_gate_approval_list) return { ...action.payload.society_gate_approval_list }; else return INITIAL_STATE;
        case SOCIETY_GATE_APPROVAL_LIST:
            return { ...state, loading: true, is_logout: false };
        case SOCIETY_GATE_APPROVAL_LIST_SUCCESS:
            if (action.payload !== undefined && action.payload !== null) {
                let data = action.payload;
                let resp = ApiError(data.statusCode, data.message, data.gsRespData, "visitor_log", []);
                resp = resp.filter(item => !state.ignored_approvals.includes(item.vlog_id));

                return { ...state, gate_approval_list: resp, loading: false, is_logout: false, error: undefined };
            }
            else
                return { ...state, loading: false, is_logout: false };
        case SOCIETY_GATE_APPROVAL_LIST_ERROR:
            let hNE_res = handleNetworkError(action);
            if (hNE_res.error)
                return { ...INITIAL_STATE, ...hNE_res, loading: false, is_logout: false };
            else if (action.payload && action.payload.response && action.payload.response.data && action.payload.response.data.error) {
                if (action.payload.response.data.error.statusCode === 401)
                    return { ...INITIAL_STATE, error: action.payload.response.data.error, loading: false, is_logout: true };
                else
                    return { ...INITIAL_STATE, error: action.payload.response.data.error, loading: false, is_logout: false };
            }
            else
                return { ...INITIAL_STATE, error: action.payload, loading: false, is_logout: false };
        case SOCIETY_GATE_APPROVAL_LIST_IGNORE_ENTRY:
            let gate_approval_list = [].concat(state.gate_approval_list);
            gate_approval_list = gate_approval_list.filter(item => item.vlog_id !== action.payload.vlog_id);

            let ignored_approvals = [].concat(state.ignored_approvals);
            ignored_approvals.push(action.payload.vlog_id);

            return { ...state, gate_approval_list, ignored_approvals };
        case SOCIETY_GATE_APPROVAL_LIST_REMOVE_ENTRY:
            let resp = [].concat(state.gate_approval_list);
            resp = resp.filter(item => item.vlog_id !== action.payload.vlog_id);

            return { ...state, gate_approval_list: resp };
        case HIDE_SOCIETY_GATE_APPROVAL: return { ...state, should_hide: true };
        case SHOW_SOCIETY_GATE_APPROVAL: return { ...state, should_hide: false };
        case LOGOUT_RESET: return { ...INITIAL_STATE, should_call_api: false };
        case SOCIETY_AVAILABLE_TRIGGER_ACTION: return { ...state, should_call_api: true };
        case SOCIETY_NOT_AVAILABLE_TRIGGER_ACTION: return { ...INITIAL_STATE, should_call_api: false };
        default: return state;
    }
}