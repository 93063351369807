import { fetchSocietiesData, selectProperty, clearSelectedProperty, parseUIConfig } from './society-util'
import { fetchNotificationData } from './society-notification'

function sortFeatureCards(cards) {
    return cards.sort((a, b) => a.disable ? 1 : b.disable ? -1 : 0);
}

export {
    fetchSocietiesData,
    fetchNotificationData,
    selectProperty,
    clearSelectedProperty,
    parseUIConfig,
    sortFeatureCards
}