import gs_color from 'config/colors.css';

const HeaderOuterDiv = {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    color: gs_color.textColor,
    cursor: 'pointer',
    justifyContent: 'center'
}

const HeaderImage = {
    height: '35px',
    width: '35px',
}

const HeaderText = {
    padding: '8px',
    marginLeft: '4px',
    fontSize: '24px',
    fontWeight: '800',
    fontFamily: "Days One, RobotoRegular, Droid Sans",
    textTransform: 'uppercase'
}

const TagLineText = {
    padding: '0px 8px 4px 8px',
    fontSize: '12px',
    color: "rgba(117, 117, 117, 1)",
    textAlign: "center"
};

const disabledStyle = {
    cursor: 'default'
}

export default {
    HeaderOuterDiv,
    HeaderImage,
    HeaderText,
    TagLineText,
    disabledStyle
}