import isEqual from 'lodash/isEqual';

export function getDuplicateIds(duplicate_address) {
    // takes an array having duplicate addresses and send distinct duplicated ids.

    let duplicate_prop_id = [];
    duplicate_address.forEach(addr => {
        if (!duplicate_prop_id.includes(addr.svc_prop_id))
            duplicate_prop_id.push(addr.svc_prop_id);
    });
    return duplicate_prop_id;
}

export function setSelectedAddress(address_list, selected_address, not_found_flag = false) {
    // takes input as address list and selected address and maps g_address object and updates selected address.
    // if not_found_flag is true, then if address not found then returns false.

    let selected_address_found = false;
    address_list.forEach((addr, i) => {
        if (isEqual(selected_address.g_address, addr.g_address)) {
            selected_address_found = true;
            selected_address = addr;
        }
    });
    if (selected_address_found === true)
        return selected_address;
    else if (not_found_flag)
        return false;
    else
        return selected_address;
}   