import { device_id } from 'config/storage-variables';

import { updateDeviceIdIndexedDB } from '../handle-indexeddb';
import { addDeviceIdCookies } from './preprocess-cookies';

export default function deviceId() {
    if (localStorage.getItem(device_id) === null) {

        // add deplay to get perfect fingerprint.

        setTimeout(() => {
            import(/* webpackChunkName: "fingerprintjs2" */ 'fingerprintjs2').then(Fingerprint2Obj => {
                const Fingerprint2 = Fingerprint2Obj.default;
                new Fingerprint2().get(function (result, components) {
                    addDeviceIdCookies(result);
                    updateDeviceIdIndexedDB(result);
                    localStorage.setItem(device_id, result);
                })
            }, 1000);
        });
    }
    else {
        updateDeviceIdIndexedDB(localStorage.getItem(device_id));
    }
}