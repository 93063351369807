import React from 'react';
import NoProductsIcon from '../../assets/no-products.svg';

function NoProductCategories() {
    return (
        <div style={{ padding: '8px', paddingTop: '30px', textAlign: "center" }}>
            <img width="82px" height="82px" src={NoProductsIcon} alt="No Products" />
            <div style={{ paddingTop: '20px', fontWeight: '500', fontSize: '17px' }}>No Products available in this City.</div>
        </div>
    );
}

export default NoProductCategories;