import { REHYDRATE } from 'redux-persist';
import {
    LOGOUT_RESET, LOGIN_TRIGGER_ACTION,
    HIDE_MISCELLANEOUS_COMPONENTS, SHOW_MISCELLANEOUS_COMPONENTS, UPDATE_EMAIL_HIGHLIGHT, UPDATE_MISCELLANEOUS_COMPONENTS,
} from '../../actions/types';
import { cl_email_exists } from 'config/storage-variables';

const INITIAL_STATE = {
    show_email_highlight: false,
    should_call_api: false,
    should_hide: false,
    explore_screen_tab: 0,
    selected_cat_id: undefined,
    selected_svc_id: undefined,
    selected_date: undefined,
    selected_ogfood_cat_id: undefined,
};

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case REHYDRATE: if (action.payload && action.payload.miscellaneous_components) return { ...action.payload.miscellaneous_components }; else return INITIAL_STATE;
        case HIDE_MISCELLANEOUS_COMPONENTS: return { ...state, should_hide: true };
        case SHOW_MISCELLANEOUS_COMPONENTS: return { ...state, should_hide: false };
        case UPDATE_EMAIL_HIGHLIGHT: return { ...state, show_email_highlight: action.payload };
        case LOGOUT_RESET: return { ...INITIAL_STATE, should_call_api: false };
        case LOGIN_TRIGGER_ACTION: return { ...state, should_call_api: true, show_email_highlight: !localStorage.getItem(cl_email_exists) };
        case UPDATE_MISCELLANEOUS_COMPONENTS:
            const payload = action.payload || {};
            return { ...state, ...payload };
        default: return state;
    }
}