import { REHYDRATE } from 'redux-persist';
import {
    USER_HOME_SCREEN_DATA, USER_HOME_SCREEN_DATA_SUCCESS, USER_HOME_SCREEN_DATA_FAILURE,
    LOGOUT_RESET, SOCIETY_NOT_AVAILABLE_TRIGGER_ACTION, SOCIETY_MESSAGE_NEW_COMMENT,
    USER_HOME_SCREEN_SET_BOARD_FEED_BADGE, USER_HOME_SCREEN_SET_SOCIETY_FEED_BADGE,
} from '../../actions/types';
import ApiError, { handleNetworkError } from '../../utils/api-error';
import { parseUIConfig } from '../../scenes/client/scenes/home-society/util';

const INITIAL_STATE = {
    cache: null,
    userDetails: null,
    authCode: null,
    notice_board: [],
    community_posts: [],
    alert_posts: [],
    error: undefined,
    loading: true,
    is_logout: false,
    msgTypeLabels: {},
    societyFeedTypes: {},
    boardFeedTypes: {},
    alert_msgtype_id: null,
    can_manage_department_acl: false,
    society_ui_config: {},
    showBoardFeedBadge: true,
    showSocietyFeedBadge: true,
};

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case REHYDRATE: if (action.payload && action.payload.user_home_screen) return { ...action.payload.user_home_screen }; else return INITIAL_STATE;
        case USER_HOME_SCREEN_DATA: return { ...INITIAL_STATE, loading: true, is_logout: false };
        case USER_HOME_SCREEN_DATA_SUCCESS:
            if (action.payload !== undefined && action.payload !== null) {
                let resp = ApiError(action.payload.statusCode, action.payload.message, action.payload.gsRespData, null, {});
                const cache = action.payload && action.payload.cache;

                if (resp.error) {
                    if (resp.error.statusCode === 401)
                        return { ...INITIAL_STATE, error: resp.error, loading: false, is_logout: true };
                    else
                        return { ...INITIAL_STATE, error: resp.error, loading: false, is_logout: false };
                }

                let newState = {};
                if (cache) {
                    newState.cache = cache;
                }
                if (resp.user_details) {
                    newState.userDetails = resp.user_details;
                }
                if (resp.app_config) {
                    newState.society_ui_config = parseUIConfig(resp.app_config);
                }
                if (resp.auth_code) {
                    newState.authCode = resp.auth_code;
                }
                if (resp.notice_board && resp.notice_board.constructor === Array && resp.notice_board.length > 0) {
                    newState.notice_board = resp.notice_board;
                }
                if (resp.community_posts && resp.community_posts.constructor === Array && resp.community_posts.length > 0) {
                    newState.community_posts = resp.community_posts;
                }
                if (resp.alert_posts && resp.alert_posts.constructor === Array && resp.alert_posts.length > 0) {
                    newState.alert_posts = resp.alert_posts;
                }
                if (resp.message_types && resp.message_types.constructor === Array && resp.message_types.length > 0) {
                    newState.msgTypeLabels = {}
                    newState.societyFeedTypes = {}
                    newState.boardFeedTypes = {}

                    for (const message_type of resp.message_types) {
                        if (message_type.status && message_type.status === "active") {
                            newState.msgTypeLabels[message_type.msgtype_id] = message_type.display_text;

                            if (message_type.msg_type === "emergency") {
                                newState.alert_msgtype_id = message_type.msgtype_id;
                            }

                            if (message_type.disp_place === "society_feed") {
                                newState.societyFeedTypes[message_type.msg_type] = message_type.msgtype_id;
                            }

                            if (message_type.disp_place === "board_feed" || message_type.disp_place === "temp_card") {
                                newState.boardFeedTypes[message_type.msg_type] = message_type.msgtype_id;
                            }
                        }
                    }
                }

                newState.can_manage_department_acl = !!resp.can_manage_department_acl;

                return { ...INITIAL_STATE, ...newState, loading: false, is_logout: false };
            }
            else
                return { ...state, loading: false, is_logout: false };
        case USER_HOME_SCREEN_DATA_FAILURE:
            let hNE_res = handleNetworkError(action);
            if (hNE_res.error)
                return { ...INITIAL_STATE, ...hNE_res, loading: false, is_logout: false };
            else if (action.payload && action.payload.response && action.payload.response.data && action.payload.response.data.error) {
                if (action.payload.response.data.error.statusCode === 401)
                    return { ...INITIAL_STATE, error: action.payload.response.data.error, loading: false, is_logout: true };
                else
                    return { ...INITIAL_STATE, error: action.payload.response.data.error, loading: false, is_logout: false };
            }
            else
                return { ...INITIAL_STATE, error: action.payload, loading: false, is_logout: false };
        case SOCIETY_MESSAGE_NEW_COMMENT:
            let community_posts = [...state.community_posts];
            community_posts = community_posts.map(message => {
                if (message.msgb_id == action.payload.message_id) {
                    message.no_of_comments = action.payload.no_of_comments;
                }
                return message;
            });
            return { ...state, community_posts };
        case USER_HOME_SCREEN_SET_BOARD_FEED_BADGE: 
            return { ...state, showBoardFeedBadge: action.payload }
        case USER_HOME_SCREEN_SET_SOCIETY_FEED_BADGE: 
            return { ...state, showSocietyFeedBadge: action.payload }
        case LOGOUT_RESET:
        case SOCIETY_NOT_AVAILABLE_TRIGGER_ACTION: return { ...INITIAL_STATE };
        default: return state;
    }
}