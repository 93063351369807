import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';

import RightIcon from 'material-ui/svg-icons/hardware/keyboard-arrow-right';
import VerticalHorizontalSwipe from '../vertical-horizontal-swipe';
import { selectServiceRoute } from 'routes-parameters/config';

import ServiceIcon from 'assets/ogcare.png';
import ImageTag from 'components/image-tag';
import styles from '../styles.jsx';

class VerticalService extends PureComponent {
    handleCardClick(route, event) {
        if (typeof this.props.handleCardClick === "function") {
            this.props.handleCardClick(route, event);
        }
    }

    render() {
        const data = this.props && this.props.data;
        const redirectRoute = this.props && this.props.redirectUrl ? this.props.redirectUrl : null;     // Because for US services should not get clicked
        // const redirectRoute = this.props && this.props.redirectUrl ? this.props.redirectUrl : `/${selectServiceRoute}`;

        const ui = <>
            <div style={styles.verticalTitleText}>
                OGCare
                        {
                    !redirectRoute ?
                        <span style={{ marginLeft: '5px' }}>(Upcoming)</span>
                        : null
                }
            </div>
            <div style={styles.verticalTitleRightIcon}>
                <RightIcon style={styles.verticalTitleRightIconStyle} />
            </div>
        </>;

        return (
            <div style={styles.verticalContainer}>
                <div style={styles.verticalTitleDiv}>
                    <ImageTag history={this.props.history} style={styles.verticalTitleIcon} src={ServiceIcon} />
                    {
                        redirectRoute ?
                            <Link to={redirectRoute} style={{ textDecoration: 'none', color: 'unset', display: 'flex', flex: 1, alignItems: 'center' }}>
                                {ui}
                            </Link>
                            :
                            ui
                    }
                </div>
                <VerticalHorizontalSwipe
                    data={data}
                    showViewAll={redirectRoute ? true : false}          // Because for US browse all services should not get shown
                    viewAllRedirect={redirectRoute}
                    viewAllIcon={ServiceIcon}
                    handleCardClick={(route, event) => this.handleCardClick(route, event)}
                />
            </div>
        )
    }
}

export default VerticalService;