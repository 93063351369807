import { REHYDRATE } from 'redux-persist';
import {
    SOCIETY_EMEETING_LIST, SOCIETY_EMEETING_LIST_SUCCESS, SOCIETY_EMEETING_LIST_ERROR,
    SOCIETY_EMEETING_LIST_IGNORE_MEETING, SOCIETY_EMEETING_LIST_REMOVE_MEETING,
    SOCIETY_AVAILABLE_TRIGGER_ACTION, SOCIETY_NOT_AVAILABLE_TRIGGER_ACTION,
    LOGOUT_RESET,
    HIDE_SOCIETY_EMEETING_REQUEST, SHOW_SOCIETY_EMEETING_REQUEST
} from '../../actions/types';
import ApiError, { handleNetworkError } from '../../utils/api-error';
import { MEETING_TYPE } from '../../scenes/emeeting/config';

const INITIAL_STATE = {
    e_meeting_list: [],
    error: undefined,
    loading: true,
    is_logout: false,
    should_call_api: false,
    should_hide: false,
    ignored_meetings: []
};

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case REHYDRATE: if (action.payload && action.payload.society_e_meeting_list) return { ...action.payload.society_e_meeting_list }; else return INITIAL_STATE;
        case SOCIETY_EMEETING_LIST:
            return { ...state, loading: true, is_logout: false };
        case SOCIETY_EMEETING_LIST_SUCCESS:
            if (action.payload !== undefined && action.payload !== null) {
                let data = action.payload;
                let resp = ApiError(data.statusCode, data.message, data.gsRespData, "active_meetings", {});
                let e_meeting_list = [];
                if (resp.eicomm && resp.eicomm.constructor === Array) {
                    resp.eicomm.forEach(item => {
                        e_meeting_list.push({ ...item, meeting_type: MEETING_TYPE.INDIVIDUAL });
                    });
                }
                if (resp.general_meetings && resp.general_meetings.constructor === Array) {
                    resp.general_meetings.forEach(item => {
                        e_meeting_list.push({ ...item, meeting_type: MEETING_TYPE.GENERAL });
                    });
                }
                if (resp.board_meetings && resp.board_meetings.constructor === Array) {
                    resp.board_meetings.forEach(item => {
                        e_meeting_list.push({ ...item, meeting_type: MEETING_TYPE.BOARD });
                    });
                }
                e_meeting_list = e_meeting_list.filter(item => !state.ignored_meetings.includes(item.meeting_id));

                return { ...state, e_meeting_list: e_meeting_list, loading: false, is_logout: false, error: undefined };
            }
            else
                return { ...state, loading: false, is_logout: false };
        case SOCIETY_EMEETING_LIST_ERROR:
            let hNE_res = handleNetworkError(action);
            if (hNE_res.error)
                return { ...INITIAL_STATE, ...hNE_res, loading: false, is_logout: false };
            else if (action.payload && action.payload.response && action.payload.response.data && action.payload.response.data.error) {
                if (action.payload.response.data.error.statusCode === 401)
                    return { ...INITIAL_STATE, error: action.payload.response.data.error, loading: false, is_logout: true };
                else
                    return { ...INITIAL_STATE, error: action.payload.response.data.error, loading: false, is_logout: false };
            }
            else
                return { ...INITIAL_STATE, error: action.payload, loading: false, is_logout: false };
        case SOCIETY_EMEETING_LIST_IGNORE_MEETING:
            let e_meeting_list = [].concat(state.e_meeting_list);
            e_meeting_list = e_meeting_list.filter(item => item.meeting_id !== action.payload.meeting_id);

            let ignored_meetings = [].concat(state.ignored_meetings);
            ignored_meetings.push(action.payload.meeting_id);

            return { ...state, e_meeting_list, ignored_meetings };
        case SOCIETY_EMEETING_LIST_REMOVE_MEETING:
            let resp = [].concat(state.e_meeting_list);
            resp = resp.filter(item => item.meeting_id !== action.payload.meeting_id);

            return { ...state, e_meeting_list: resp };
        case HIDE_SOCIETY_EMEETING_REQUEST: return { ...state, should_hide: true };
        case SHOW_SOCIETY_EMEETING_REQUEST: return { ...state, should_hide: false };
        case LOGOUT_RESET: return { ...INITIAL_STATE, should_call_api: false };
        case SOCIETY_AVAILABLE_TRIGGER_ACTION: return { ...state, should_call_api: true };
        case SOCIETY_NOT_AVAILABLE_TRIGGER_ACTION: return { ...INITIAL_STATE, should_call_api: false };
        default: return state;
    }
}