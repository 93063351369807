import React from 'react';
import RaisedButton from 'material-ui/RaisedButton';
import NotFoundIcon from './assets/NotFound.svg';
import { strings } from 'strings';
import { Link } from 'react-router-dom';
import { getEnvHomeRoute } from 'utils/helper-functions';

const style = {
  margin: 12,
};

function NotFound(props) {
  const envHomeRoute = getEnvHomeRoute();

  return (
    <div>
      <div style={{ paddingTop: "150px", textAlign: "center" }}>
        <img width="82px" height="82px" src={NotFoundIcon} alt="Not Found" />
        <div style={{ paddingTop: '20px', fontWeight: '500', fontSize: '17px' }}>{strings.not_found}</div>
        <div style={{ paddingTop: '10px', fontSize: '13px' }}>{strings.not_found_string}</div>
        <div style={{ paddingTop: '10px' }}>
          <Link style={{ textDecoration: "none" }} action="replace" to={`/${envHomeRoute}`}>
            <RaisedButton label="Go back to Home" style={style} />
          </Link>
        </div>
      </div>
    </div>
  );
}

NotFound.displayName = 'NotFound';
export default NotFound;