import { REHYDRATE } from 'redux-persist';
import { GEONAMES_CITY_LIST, GEONAMES_CITY_LIST_SUCCESS, GEONAMES_CITY_LIST_ERROR, GEONAMES_CITY_LIST_CLEAR_CACHE } from '../../actions/types';
import ApiError, { handleNetworkError } from '../../utils/api-error';

const INITIAL_STATE = {
    geonames_city_list: [],
    cache: null,
    error: undefined,
    loading: false,
    is_logout: false
};

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case REHYDRATE: if (action.payload && action.payload.geonames_city_list) return { ...action.payload.geonames_city_list }; else return INITIAL_STATE;
        case GEONAMES_CITY_LIST: return { ...INITIAL_STATE, loading: true, is_logout: false };
        case GEONAMES_CITY_LIST_SUCCESS:
            const apiData = action.payload && action.payload.apiData;
            const cache = action.payload && action.payload.cache;
            if (apiData !== undefined && apiData !== null) {
                const city_list = ApiError(apiData.statusCode, apiData.message, apiData.gsRespData, "cities", []);
                if (city_list && city_list.constructor === Array) {
                    return { ...state, loading: false, is_logout: false, geonames_city_list: city_list, cache: cache }

                } else {
                    return { ...state, loading: false, is_logout: false, error: city_list };
                }

            } else {
                return { ...state, loading: false, is_logout: false };
            }
        case GEONAMES_CITY_LIST_ERROR:
            let hNE_res = handleNetworkError(action);
            if (hNE_res.error)
                return { ...INITIAL_STATE, ...hNE_res, loading: false, is_logout: false };
            else if (action.payload && action.payload.response && action.payload.response.data && action.payload.response.data.error) {
                if (action.payload.response.data.error.statusCode === 401)
                    return { ...INITIAL_STATE, error: action.payload.response.data.error, geonames_city_list: [], loading: false, is_logout: true };
                else
                    return { ...INITIAL_STATE, error: action.payload.response.data.error, geonames_city_list: [], loading: false, is_logout: false };
            }
            else
                return { ...INITIAL_STATE, error: action.payload, geonames_city_list: [], loading: false, is_logout: false };
        case GEONAMES_CITY_LIST_CLEAR_CACHE:
            return INITIAL_STATE;
        default: return state;
    }
}