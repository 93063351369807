import { REHYDRATE } from 'redux-persist';
import {
    HOME_SCREEN_DATA, HOME_SCREEN_DATA_SUCCESS, HOME_SCREEN_DATA_FAILURE,
    ADS, ADS_SUCCESS, ADS_ERROR
} from '../../actions/types';
import ApiError, { handleNetworkError } from '../../utils/api-error';
import { ads_fetched } from 'config/storage-variables';

const INITIAL_STATE = {
    ads_data: [],
    override_ads_data: [],
    error: undefined,
    loading: true,
    is_logout: false,
    type: null
};

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case REHYDRATE: if (action.payload && action.payload.ads) return { ...action.payload.ads }; else return INITIAL_STATE;
        case HOME_SCREEN_DATA: return { ...INITIAL_STATE, loading: true, is_logout: false };
        case HOME_SCREEN_DATA_SUCCESS:
            sessionStorage.setItem(ads_fetched, 'true');
            if (action.payload !== undefined && action.payload !== null) {
                let resp = ApiError(action.payload.statusCode, action.payload.message, action.payload.gsRespData, "banners", []);
                if (resp && resp.length > 0) {
                    return { ...INITIAL_STATE, ads_data: resp, override_ads_data: state.override_ads_data, loading: false, is_logout: false };
                }
                else if (resp) {
                    return { ...INITIAL_STATE, loading: false, is_logout: false };
                }
            }
            else
                return { ...state, loading: false, is_logout: false };
            break;
        case HOME_SCREEN_DATA_FAILURE:
            let hNE_res = handleNetworkError(action);
            if (hNE_res.error)
                return { ...INITIAL_STATE, ...hNE_res, loading: false, is_logout: false };
            else if (action.payload && action.payload.response && action.payload.response.data && action.payload.response.data.error) {
                if (action.payload.response.data.error.statusCode === 401)
                    return { ...INITIAL_STATE, error: action.payload.response.data.error, loading: false, is_logout: true };
                else
                    return { ...INITIAL_STATE, error: action.payload.response.data.error, loading: false, is_logout: false };
            }
            else
                return { ...INITIAL_STATE, error: action.payload, loading: false, is_logout: false };
        case ADS: return { ...INITIAL_STATE, loading: true, is_logout: false };
        case ADS_SUCCESS:
            sessionStorage.setItem(ads_fetched, 'true');
            if (action.payload !== undefined && action.payload !== null) {
                let resp = ApiError(action.payload.statusCode, action.payload.message, action.payload.gsRespData, "city_ad_data", []);
                if (resp && resp.length > 0) {
                    return { ...INITIAL_STATE, ads_data: state.ads_data, override_ads_data: resp, loading: false, is_logout: false, type: action.payload.type };
                }
                else if (resp) {
                    return { ...INITIAL_STATE, loading: false, is_logout: false };
                }
            }
            else
                return { ...state, loading: false, is_logout: false };
            break;
        case ADS_ERROR:
            hNE_res = handleNetworkError(action);
            if (hNE_res.error)
                return { ...INITIAL_STATE, ...hNE_res, loading: false, is_logout: false };
            else if (action.payload && action.payload.response && action.payload.response.data && action.payload.response.data.error) {
                if (action.payload.response.data.error.statusCode === 401)
                    return { ...INITIAL_STATE, error: action.payload.response.data.error, loading: false, is_logout: true };
                else
                    return { ...INITIAL_STATE, error: action.payload.response.data.error, loading: false, is_logout: false };
            }
            else
                return { ...INITIAL_STATE, error: action.payload, loading: false, is_logout: false };

        default: return state;
    }
}