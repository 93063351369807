import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';

import DownArrow from 'material-ui/svg-icons/navigation/expand-more';

import { productListingRoute } from 'routes-parameters/config';
import { encodeUrl } from 'utils/helper-functions';
import { downloadMedia } from 'utils/handle-media';
import ProductDefaultImg from '../../assets/product-default.jpg';

import gs_color from 'config/colors.css';
import styles from './styles';
import './styles.css';

class ProductCategory extends PureComponent {
    constructor(props) {
        super(props)

        this.state = {
            open: props.open || false
        }
    }

    handleCategoryClick(subCategories) {
        const open = subCategories.length > 0 && !this.state.open;
        this.setState({ open: open })
        if (typeof this.props.onCategoryClick === "function") {
            this.props.onCategoryClick(open);
        }
    }

    handleSubCategoryRedirection(event, subCategory) {
        event.preventDefault();
        this.props.history.push({
            pathname: `/${encodeUrl(this.props.city_name)}/${encodeUrl(this.props.pcat_name)}/${encodeUrl(subCategory.pscat_name)}/${productListingRoute}`,
            state: {
                block_explore_back: true
            }
        });
        if (typeof this.props.onSubCategoryRedirection === "function") {
            this.props.onSubCategoryRedirection();
        }
    }

    render() {
        const subCategories = this.props.subCategories ? Object.keys(this.props.subCategories) : [];
        const noSubCats = subCategories.length === 0;
        const imgSrc = this.props.pcat_icon && this.props.pcat_icon.length > 0 ? downloadMedia(this.props.pcat_icon) : ProductDefaultImg;
        return (
            <div ref={el => this.props.getRef(el)} style={{ margin: '0px 0px' }} id={'product-cat-' + this.props.pcat_id}>
                <div style={this.state.open ? { ...styles.outerPaper, backgroundColor: 'white' } : styles.outerPaper}
                    onClick={() => this.handleCategoryClick(subCategories)}>
                    <div style={styles.firstDiv}>
                        <div style={styles.productImgDiv}>
                            <img style={{ height: '130px', width: '130px' }} src={imgSrc} />
                        </div>
                        <div style={styles.productDetailsDiv}>
                            <div style={styles.productCatHeader}>
                                {this.props.pcat_name}
                            </div>
                            <div className="descClamp" style={styles.productCatDesc}>
                                {this.props.pcat_desc}
                            </div>
                        </div>
                    </div>
                    <div style={this.state.open || noSubCats ? { ...styles.secondDiv, borderLeft: 'none' } : styles.secondDiv}>
                        <DownArrow color={noSubCats ? 'white' : '#757575'} style={this.state.open ? { ...styles.arrowIcon, border: 'none' } : styles.arrowIcon} className={this.state.open ? "rotateArrow" : ""} />
                    </div>
                </div>
                {
                    this.state.open &&
                    <div style={styles.outerSubCategoriesPaper}>
                        {
                            subCategories.map((key, i) => {
                                const subCategory = this.props.subCategories[key];
                                //const outerStyle = Object.assign({}, styles.outerSubCategoryItem, { backgroundColor: i % 2 == 0 ? gs_color.lightPrimaryColor : '#e9eef5' });
                                const outerStyle = Object.assign({}, styles.outerSubCategoryItem, {backgroundColor: 'white'});
                                const imgSrc = subCategory.pscat_icon && subCategory.pscat_icon.length > 0 ? downloadMedia(subCategory.pscat_icon) : ProductDefaultImg;
                                return (
                                    <div style={outerStyle} key={subCategory.pscat_id} id={'product-subcat-' + subCategory.pscat_id}>
                                        <Link style={{ textDecoration: "none" }} onClick={(event) => this.handleSubCategoryRedirection(event, subCategory)} to={`${encodeUrl(this.props.city_name)}/${encodeUrl(this.props.pcat_name)}/${encodeUrl(subCategory.pscat_name)}/${productListingRoute}`}>
                                            <div style={{ textAlign: 'center' }}>
                                                <img src={imgSrc} style={styles.subCategoryImage} />
                                            </div>
                                            <div style={styles.subCategoryTitle}>{subCategory.pscat_name}</div>
                                        </Link>
                                    </div>
                                )
                            })
                        }
                    </div>
                }
            </div>
        )
    }

    componentDidUpdate(prevProps) {
        if (prevProps.open !== this.props.open) {
            this.setState({ open: this.props.open });
        }
    }
}

export default ProductCategory;