import gs_color from 'config/colors.css';

const styles = {
    verticalContainer: {
        padding: '12px 0px',
        background: '#f5f5f5',
        marginBottom: '16px'
    },
    verticalTitleDiv: {
        width: '100%',
        padding: '0 5%',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        paddingBottom: '5px'
    },
    verticalTitleIcon: {
        height: '26px',
        width: '26px'
    },
    verticalTitleText: {
        marginLeft: '18px',
        fontSize: '18px',
        fontWeight: '600',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        color: gs_color.accentColor
    },
    verticalTitleRightIcon: {
        marginLeft: 'auto',
        display: 'inline-flex',
        borderRadius: '50%',
        backgroundColor: gs_color.accentColor
    },
    verticalTitleRightIconStyle: {
        color: '#fff',
        width: '18px',
        height: '18px'
    },
    horizontalScroll: {
        margin: '12px 0px',
        padding: '0 5%',
        whiteSpace: 'nowrap',
        overflowX: 'auto',
        overflowY: 'hidden'
    },
    outerMemberLink: {
        margin: '0px 4px 8px 4px',
        display: 'inline-flex',
        width: '128px',
        minHeight: '144px',
        textDecoration: 'none'
    },
    outerMemberItem: {
        padding: '10px 0px',
        display: 'flex',
        textAlign: 'center',
        borderRadius: '5px',
        width: '128px',
        minHeight: '144px',
        flexDirection: 'column',
        alignItems: 'center'
    },
    memberTtile: {
        marginTop: '4px',
        padding: '0px 8px',
        wordWrap: 'break-word',
        fontWeight: '600',
        whiteSpace: 'pre-wrap'
    }
}

export default styles;