import React from 'react';

import CircularProgressIcon from '../../assets/circular_loader.svg';

import './styles.css';

function FallbackUI() {
    if (
        CSS &&
        CSS.supports('transform: translate(-50%, -50%)') &&
        CSS.supports('animation: spin 1s linear infinite') &&
        CSS.supports('position: fixed')
    )
        return (
            <div className="div__center">
                <div className="spin__loader"></div>
            </div>
        );

    return (
        <div style={{ textAlign: 'center', marginTop: '40px' }}>
            <img src={CircularProgressIcon} style={{ width: '40px', height: '40px' }} alt='Loading ...' />
        </div>
    );
}

FallbackUI.displayName = 'FallbackUI';
export default FallbackUI;