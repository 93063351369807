import React, { Fragment, Component } from 'react';

import ImageViewer from '../image-viewer';
import { downloadMedia } from "../../utils/handle-media";

class ImageTag extends Component {
    static defaultProps = {
        zoomable: true,
        src: '',
        style: {}
    };

    constructor(props) {
        super(props);
        this.state = {
            open_image_viewer: false
        };

        this.images = [];
        this.selected_image_index = null;
    }

    handleHashChange = () => {
        if (window.location.hash === "#showImage" && this.images.length !== 0)
            this.setState({ open_image_viewer: true });
        else
            this.setState({ open_image_viewer: false });
    }

    componentDidMount() {
        window.addEventListener('hashchange', this.handleHashChange, false);
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (
            this.props.src === nextProps.src &&
            this.state.open_image_viewer === nextState.open_image_viewer &&
            Object.keys(this.props.style || {}).length === Object.keys(nextProps.style || {}).length
        )
            return false;
        else
            return true;
    }

    openImageViewer(image, index) {
        let images_arr = [];

        if (index)
            this.selected_image_index = index;

        let crop_size;
        if (window && window.innerWidth) {
            let innerWidth = window.innerWidth;
            if (innerWidth <= 400) {
                crop_size = '400:';
            }
            else if (innerWidth <= 600) {
                crop_size = '600:';
            }
            else {
                crop_size = '800:';
            }
        }
        if (!crop_size)
            crop_size = ':';

        const thumbnail = this.props.thumbnail ? typeof this.props.thumbnail === "string" ? downloadMedia(this.props.thumbnail, { crop: '150:150' }) : this.props.thumbnail : image;
        const regular = this.props.regular ? typeof this.props.regular === "string" ? downloadMedia(this.props.regular, { crop: crop_size }) : this.props.regular : image;
        const download = this.props.download ? typeof this.props.download === "string" ? downloadMedia(this.props.download, { crop: crop_size }) : this.props.download : image;

        images_arr.push({
            source: {
                thumbnail: thumbnail,
                regular: regular,
                download: download
            }
        })

        this.images = images_arr;

        this.setHash();
    }

    setHash() {
        window.location.hash = "showImage";
    }

    removeHash() {
        if (this.props.history) {
            this.props.history.goBack();
        }
    }

    closeImageViewer() {
        this.images = [];
        this.removeHash();
    }

    handleClick(event) {
        if (this.props.src) {
            event.preventDefault();
            event.stopPropagation();
            this.openImageViewer(this.props.src, 0);    // component which we need to zoom
        }
    }

    render() {
        if (!this.props.src) {
            return null;
        }
        else {
            return (
                <Fragment>
                    <img style={this.props.style || {}} src={this.props.src} onClick={(event) => this.handleClick(event)} />
                    {
                        this.state.open_image_viewer ?
                            <ImageViewer
                                currentIndex={this.selected_image_index}
                                images={this.images}
                                showClose={true}
                                handleClose={() => this.closeImageViewer()}
                            />
                            : null
                    }
                </Fragment>
            );
        }
    }

    componentWillUnmount() {
        window.removeEventListener('hashchange', this.handleHashChange, false);
    }
}

ImageTag.displayName = 'ImageTag';
export default ImageTag;