export function isIndexDBSupported() {
    return !!('indexedDB' in window);
}

export function getIndexDB(name = 'grs_db1') {
    return new Promise((resolve, reject) => {
        if (!isIndexDBSupported()) {
            reject({ message: 'IndexedDB is not supported by this browser' });
        }
        else if (process.env.NODE_ENV !== 'production') {
            reject({ message: 'IndexedDB is not used on non production environment' });
        }
        else {
            try {
                var openRequest = indexedDB.open(name, 1);

                openRequest.onsuccess = () => {
                    var db = openRequest.result;

                    resolve(db);
                };

                openRequest.onupgradeneeded = () => {
                    var db = openRequest.result;

                    // If store object (similar to table) does not exists then create it.
                    if (!db.objectStoreNames.contains('store')) {
                        db.createObjectStore('store', { keyPath: 'key' });
                    }

                    resolve(db);
                };

                openRequest.onerror = () => {
                    resolve({ message: 'Error while opening db' });
                };
            }
            catch (err) {
                reject(err);
            }
        }
    });
}

// Structure indexed db data from array to json
function structureIndexDBData(data, keys = null) {
    if (!data)
        return {};

    var response = {};

    data.forEach(item => {
        if (!keys || (keys && keys.includes(item.key))) {
            response[item.key] = item;
        }
    });

    return response;
}

export function getAllObjectIndexDB(db) {
    return new Promise((resolve, reject) => {

        if (!db)
            return {};

        var tx = db.transaction('store', 'readonly');
        var store = tx.objectStore('store');
        var request = store.getAll();

        request.onsuccess = () => {
            if (request.result)
                resolve({ data: structureIndexDBData(request.result) });
            else
                reject({ message: 'No valid data' });
        }

        request.onerror = () => {
            resolve({ message: 'Error while fetching data' });
        }
    });
}

export function getObjectIndexDB(db, keys) {
    return new Promise((resolve, reject) => {

        if (!db || !keys)
            return {};
        if (keys.constructor !== Object && keys.constructor !== Array)
            return {};
        if (keys.constructor === Object && Object.keys(keys).length === 0)
            return {};
        if (keys.constructor === Array && keys.length === 0)
            return {};

        var tx = db.transaction('store', 'readonly');
        var store = tx.objectStore('store');
        var request = store.getAll();

        var keys_arr = [];

        if (keys.constructor === Object) {
            keys_arr = Object.keys(keys);
        }
        else {
            keys_arr = keys;
        }

        request.onsuccess = () => {
            if (request.result)
                resolve({ data: structureIndexDBData(request.result, keys_arr) });
            else
                reject({ message: 'No valid data' });
        }

        request.onerror = () => {
            resolve({ message: 'Error while fetching data' });
        }
    });
}

export function setObjectIndexDB(db, keys) {
    return new Promise((resolve, reject) => {

        if (!db || !keys)
            return reject({ message: 'Invalid db or keys' });
        if (keys.constructor !== Object)
            return reject({ message: 'Invalid keys' });
        if (keys.constructor === Object && Object.keys(keys).length === 0)
            return reject({ message: 'Invalid keys' });

        var tx = db.transaction('store', 'readwrite');
        var store = tx.objectStore('store');

        Object.keys(keys).forEach(key => {
            var item = {
                key,
                value: keys[key]
            }

            store.put(item);
        });

        tx.oncomplete = () => {
            resolve({ message: 'Successfully Added / Updated' });
        }

        tx.onerror = () => {
            resolve({ message: 'Error in adding / updating' });
        }
    });
}

export function deleteObjectIndexDB(db, keys) {
    return new Promise((resolve, reject) => {

        if (!db || !keys)
            return reject({ message: 'Invalid db or keys' });
        if (keys.constructor !== Object && keys.constructor !== Array)
            return reject({ message: 'Invalid keys' });
        if (keys.constructor === Object && Object.keys(keys).length === 0)
            return reject({ message: 'Invalid keys' });
        if (keys.constructor === Array && keys.length === 0)
            return reject({ message: 'Invalid keys' });

        var tx = db.transaction('store', 'readwrite');
        var store = tx.objectStore('store');

        var keys_arr = [];

        if (keys.constructor === Object) {
            keys_arr = Object.keys(keys);
        }
        else {
            keys_arr = keys;
        }

        keys_arr.forEach(key => {
            store.delete(key);
        });

        tx.oncomplete = () => {
            resolve({ message: 'Successfully Deleted' });
        }

        tx.onerror = () => {
            resolve({ message: 'Error in deletion' });
        }
    });
}