import React, { PureComponent } from 'react'
import { connect } from 'react-redux'

import PassCodeDialog from '../../../society/scenes/entry-pass/components/pass-code-dialog'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faQrcode } from '@fortawesome/free-solid-svg-icons'

import gs_color from 'config/colors.css'
import './styles.css'

class ActionMyPass extends PureComponent {
    constructor(props) {
        super(props)

        this.state = {
            showDialog: false
        }

    }

    render() {
        return (
            <>
                <div className="action-outer" onClick={() => this.setState({ showDialog: true })}>
                    <div className="action-icon-outer">
                        <div className="action-icon action-icon-background">
                            <FontAwesomeIcon className='svg-inline--fa' icon={faQrcode} color={gs_color.accentColor} style={{ height: '14px', width: '14px' }} />
                        </div>
                    </div>
                    <div className="action-label">
                        My Pass
                    </div>
                </div>
                {
                    this.state.showDialog &&
                    <PassCodeDialog
                        show={this.state.showDialog}
                        onCloseDialog={() => this.setState({ showDialog: false })}
                        qrCodeText={this.props.permanentCode}
                        permanentCode={this.props.permanentCode}
                        profPicLink={this.props.profPicLink}
                        displayName={this.props.displayName}
                    />
                }
            </>
        )
    }
}

function mapStateToProps({ user_home_screen }) {
    const {
        userDetails,
        authCode,
    } = user_home_screen;

    return {
        permanentCode: authCode || "",
        profPicLink: userDetails && userDetails.profile_pic || "",
        displayName: userDetails && userDetails.display_name || ""
    };
}

export default connect(mapStateToProps)(ActionMyPass)