import { isNullOrEmptyObject } from 'utils/helper-functions';
import { parsePropAddlDetails } from '../../society/scenes/select-society/scenes/register-property/config';

export const getSelectedAddress = (address_list, address_id, cart_address_data) => {
    if (address_list && address_list.length > 0) {
        if (!isNullOrEmptyObject(cart_address_data)) {
            if (cart_address_data.shpinfo_id) {
                for (const address of address_list) {
                    if (address.shpinfo_id === cart_address_data.shpinfo_id) {
                        return address;
                    }
                }
            } else if (cart_address_data.cp_prop_id) {
                for (const address of address_list) {
                    if (address.cpt_id === cart_address_data.cp_prop_id) {
                        return address;
                    }
                }
            } else if (cart_address_data.community_id) {
                for (const address of address_list) {
                    if (getCommunityIdFromAddress(address) === cart_address_data.community_id) {
                        return address;
                    }
                }
            }
        }

        if (address_id) {
            for (const address of address_list) {
                if (address.cpt_id === address_id) {
                    return address;
                }
            }
        }
    }

    return null;
}

export function checkPropertyForAddress(address, prop_id) {
    if (address && address.society_info && address.society_info.prop_list && address.society_info.prop_list.length > 0) {
        return address.society_info.prop_list.some(prop => prop.prop_id === prop_id);
    }

    return false;
}

export function getAddressId(address) {
    return address ? address.svc_prop_id ? address.svc_prop_id : address.prop_id ? address.prop_id : address.temp_id ? address.temp_id : address.cpt_id : null;
}

export function getAddressSocietyName(address) {
    const addr = getAddress(address);
    return addr && addr.society_name ? addr.society_name : address && address.society_info ? address.society_info.name : null;
}

export function getAddressPropertyList(address) {
    if (address && address.society_info && address.society_info.prop_list && address.society_info.prop_list.length > 0) {
        return address.society_info.prop_list;
    }

    return undefined;
}

export function getAddressPropertyName(address) {
    let prop_name = null;
    if (address && address.selectedPropId) {
        const prop_list = getAddressPropertyList(address) || [];
        const prop = prop_list.filter(item => item.prop_id === address.selectedPropId)[0];
        if (prop && prop.unit_name) {
            prop_name = prop.unit_name;
        }
    }

    if (prop_name) {
        return prop_name;
    }

    const addr = getAddress(address);

    if (addr) {
        if (!isNullOrEmptyObject(addr.prop_details)) {
            return `${addr.prop_name} : ${parsePropAddlDetails(addr.prop_details)}`;

        } else if (addr.prop_name) {
            return addr.prop_name;
        }
    }

    return null;
}

export function getFormattedAddress(address) {
    const gAddress = getGAddress(address);
    return gAddress && gAddress.formatted_address;
}

export function getGAddress(address) {
    if (address && address.prop_address && address.prop_address.g_address) {
        return address.prop_address.g_address;
    }
    if (address && address.g_address) {
        return address.g_address;
    }
    return null;
}

export function getAddress(address) {
    const gAddress = getGAddress(address);
    return gAddress && gAddress.address;
}

export function createProductAddress(savedAddress) {
    return {
        temp_id: savedAddress.svc_prop_id,
        prop_address: {
            g_address: savedAddress.g_address
        }
    }
}

export function getCommunityIdFromAddress(address) {
    const addr = getAddress(address);
    return addr && addr.community_id ? addr.community_id : address && address.society_info ? address.society_info.community_id : null;
}

export function getCityIdFromAddress(address) {
    return address && address.city_id ? address.city_id : null;
}

export const getCartAddressDataFromAddress = address => {
    const addr = getAddress(address);
    return {
        community_id: addr && addr.community_id,
        shpinfo_id: address.shpinfo_id,
        cp_prop_id: address.cpt_id,
        prop_id: address.prop_id
    }
}

export const buildCartAddressData = (data, address) => {
    let reqData = { ...data };

    if (address) {
        if (address.shpinfo_id) {
            reqData.shpinfo_id = address.shpinfo_id;
        } else if (address.cpt_id) {
            reqData.cp_prop_id = address.cpt_id;
        } else {
            reqData.delv_address = { g_address: getGAddress(address) }
        }
    }

    return reqData;
}

export function buildFormattedAddress(data) {
    const address = getAddress(data);
    if (!data) {
        return null;
    }

    let temp_landmark = '';
    if (address.landmark != '') {
        temp_landmark = ", " + address.landmark;
    }

    let formatted_address = (address.street || '').trim() + ", " + (address.locality || '').trim() + temp_landmark + ", " + address.city + ", " + address.state + " " + address.zipcode + ", " + address.country;
    return formatted_address;
}

export function buildAddressWithSocietyDetails(data) {
    if (!data) {
        return null;
    }
    let new_address = { ...(data || {}) };

    const address = getAddress(data);
    const societyName = getAddressSocietyName(data);
    const propertyName = getAddressPropertyName(data);

    // Add society and property details in address.

    let street = '';
    if (address && address.street) {
        street = address.street;
    }
    if (societyName) {
        street = societyName + ', ' + street;
    }
    if (propertyName) {
        street = propertyName + ', ' + street;
    }

    if (new_address && new_address.g_address && new_address.g_address.address) {
        new_address.g_address.address.street = street;
        const formatted_address = buildFormattedAddress(new_address);
        new_address.g_address.formatted_address = formatted_address;
    }

    return new_address;
}