import React from 'react'

import SectionHeader from '../../section-header'
import SectionVerticalScroll from '../../section-vertical-scroll'

import SectionItemCard from '../../section-item-card'

import { societyBoardCards } from './config'
import { boardMemberViewRoute } from 'routes-parameters/config'
import { getUIOverride, UI_CONFIG } from '../../../config'

function SectionBoardView({ isd_code, society_ui_config, can_manage_department_acl, history }) {
    return (
        <div>
            <SectionHeader
                title={
                    getUIOverride(UI_CONFIG.board_member_zone, society_ui_config).title
                    || "Board Member Zone"
                }
                onActionClick={() => history.push(`/${boardMemberViewRoute}`)}
            />
            <SectionVerticalScroll
                data={
                    societyBoardCards(isd_code, society_ui_config, can_manage_department_acl)
                        .map(data => <SectionItemCard key={data.route} {...data} />)
                }
                maxHorizontalItems={4}
            />
        </div>
    )
}

export default SectionBoardView