import {
    last_fetch_all_props_notification_count, selected_prop_notification_count, all_props_notification_count,
    all_props_notification_data
} from 'config/storage-variables'

import { getAllPropertiesNotificationCount } from '../../society/api'

export const fetchNotificationData = propId => new Promise((resolve, reject) => {
    // Check if notification count api to be called.
    let check = false;
    if (sessionStorage.getItem(last_fetch_all_props_notification_count)) {
        const now = new Date();
        const last_fetch_all_props_notification_count_time = new Date(sessionStorage.getItem(last_fetch_all_props_notification_count))
        const diff_in_mins = Math.ceil(Math.abs(now.getTime() - last_fetch_all_props_notification_count_time.getTime()) / (1000 * 60))

        if (diff_in_mins >= 10) {
            // Last api call 10 mins or more before current time.
            check = true
        }

    } else {
        check = true
    }

    if (check) {
        getAllPropertiesNotificationCount((res, err) => {
            let count = 0;
            let allCount = 0;

            if (res && res.constructor === Array) {
                res.forEach(notif => {
                    allCount += (notif.count ? notif.count : 0)
                    if (notif.prop_id === propId) {
                        count = notif.count ? notif.count : 0
                        sessionStorage.setItem(selected_prop_notification_count, count)
                    }
                })
                sessionStorage.setItem(all_props_notification_count, allCount)
                sessionStorage.setItem(all_props_notification_data, JSON.stringify(res))
            } else {
                sessionStorage.removeItem(selected_prop_notification_count)
                sessionStorage.removeItem(all_props_notification_count)
                sessionStorage.removeItem(all_props_notification_data)
            }

            // Set last fetch time as now.
            const now = new Date();
            sessionStorage.setItem(last_fetch_all_props_notification_count, now.toISOString())

            resolve({
                notification_count_data: count,
                allPropNotificationCount: allCount,
                allPropNotificationData: res ? res : []
            })
        })

    } else {
        const count = sessionStorage.getItem(selected_prop_notification_count) ? sessionStorage.getItem(selected_prop_notification_count) : 0
        const allCount = sessionStorage.getItem(all_props_notification_count) ? sessionStorage.getItem(all_props_notification_count) : 0
        const allNotifData = sessionStorage.getItem(all_props_notification_data) ? JSON.parse(sessionStorage.getItem(all_props_notification_data)) : []
        resolve({
            notification_count_data: count,
            allPropNotificationCount: allCount,
            allPropNotificationData: allNotifData
        });
    }
})

export const getPropNotificationCount = propId => {
    let count = 0
    const allNotifDataStr = sessionStorage.getItem(all_props_notification_data)
    if (allNotifDataStr) {
        const allNotifData = JSON.parse(allNotifDataStr)
        const notification = allNotifData.filter(notif => notif.prop_id === propId)
        if(notification && notification.length > 0) {
            count = notification[0].count ? notification[0].count : 0;
            sessionStorage.setItem(selected_prop_notification_count, count)
        }
    }

    return count
}