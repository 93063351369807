import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { clearSocietyMsgBoardCache } from 'actions'

import SectionHeader from '../../section-header'
import SectionHorizontalScroll from '../../section-horizontal-scroll'

import SectionItemPostPreview, { SectionItemEmptyPostPreview, SectionItemPremiumPostPreview } from '../../section-item-post-preview'

import { societyFeedRoute } from 'routes-parameters/config';

function SectionSocietyFeed({ history, communityPosts, msgTypeLabels, feedTypes, clearSocietyMsgBoardCache, title, disable }) {
    return (
        <div>
            <SectionHeader
                title={title || "Society Feed"}
                onActionClick={() => {
                    clearSocietyMsgBoardCache()
                    history.push(`/${societyFeedRoute}`)
                }}
            />
            <SectionHorizontalScroll
                data={renderData(communityPosts, msgTypeLabels, history, feedTypes, disable)}
                maxHorizontalItems={1}
            />
        </div>
    )
}

function renderData(communityPosts, msgTypeLabels, history, feedTypes, disable) {
    if (disable) {
        return [<SectionItemPremiumPostPreview history={history} route={societyFeedRoute} key="Premium" />]
    }

    if (communityPosts && communityPosts.length > 0) {
        return communityPosts.map(data =>
            <SectionItemPostPreview
                key={data.msgb_id}
                {...data}
                fullWidth={communityPosts.length === 1}
                msgTypeLabels={msgTypeLabels}
                history={history}
                route={societyFeedRoute}
                feedTypes={feedTypes}
            />
        )
    }

    return [<SectionItemEmptyPostPreview key="Empty" />]
}

function mapStateToProps({ user_home_screen }) {
    const { community_posts, societyFeedTypes } = user_home_screen;

    return {
        communityPosts: community_posts || [],
        feedTypes: societyFeedTypes
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ clearSocietyMsgBoardCache }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(SectionSocietyFeed)