import axios from 'middleware/axios';
import { API_LINK } from 'config/config';
import { cl_access_token, cl_user_id } from 'config/storage-variables';

export function getUserActiveProperties(cb) {
    let access_token = localStorage.getItem(cl_access_token);

    axios.get(`${API_LINK}/gs_Community/gs_getUserActiveProperty?access_token=${access_token}`)
        .then(res => {
            if (res && res.data && res.data.statusCode.toString().slice(0, 2) == 20) {
                cb(res.data.gsRespData, null);
            } else {
                cb(null, res.data);
            }
        })
        .catch(err => {
            if (err.response && err.response.data && err.response.data.error) {
                if (err.response.data.error.statusCode === 401) {
                    cb(null, { ...err.response.data.error, is_logout: true });
                } else {
                    cb(null, err.response.data.error);
                }
            } else {
                cb(null, err);
            }
        })
}

export function getAllPropertiesInSociety(community_id, filter_data, cb) {
    let access_token = localStorage.getItem(cl_access_token);

    let data = "access_token=" + access_token;
    data += "&community_id=" + community_id;
    if (filter_data && Object.keys(filter_data).length > 0) {
        data += "&prop_filter=" + JSON.stringify(filter_data);
    }

    axios.get(`${API_LINK}/gs_Community/gs_getCommunityAllPropsDetails?${data}`)
        .then(res => {
            if (res && res.data && res.data.statusCode.toString().slice(0, 2) == 20) {
                cb(res.data.gsRespData, null);
            } else {
                cb(null, res.data);
            }
        })
        .catch(err => {
            if (err.response && err.response.data && err.response.data.error) {
                if (err.response.data.error.statusCode === 401) {
                    cb(null, { ...err.response.data.error, is_logout: true });
                } else {
                    cb(null, err.response.data.error);
                }
            } else {
                cb(null, err);
            }
        })
}

export function postSendSocietyRequest(data, cb) {
    let user_id = localStorage.getItem(cl_user_id);
    let access_token = localStorage.getItem(cl_access_token);

    if (user_id) {
        data.user_id = user_id;
    }

    let req_params = '';
    if (access_token) {
        req_params = '?access_token' + access_token;
    }

    axios.post(`${API_LINK}/gs_Users/gs_GetListOfPeerSP${req_params}`, data)
        .then((res) => {
            if (res.data.statusCode.toString().slice(0, 2) == 20)
                cb(res.data, null);
            else
                cb(null, res.data);
        })
        .catch((err) => {
            if (err.response && err.response.data && err.response.data.error)
                if (err.response.data.error.statusCode === 401) {
                    cb(null, { ...err.response.data.error, is_logout: true });
                }
                else
                    cb(null, err.response.data.error);
            else
                cb(null, err);
        });
}

export function getGateKeeperApprovalPending(community_id, board_id, cb) {
    let access_token = localStorage.getItem(cl_access_token);

    axios.get(`${API_LINK}/gs_Community/gs_getCommunitySecurityList?community_id=${community_id}&board_id=${board_id}&status=pending&access_token=${access_token}`)
        .then((res) => {
            if (res.data.statusCode.toString().slice(0, 2) == 20) {
                cb(res.data, null);
            }
            else {
                cb(null, res.data);
            }
        })
        .catch((err) => {
            if (err && err.response && err.response.data && err.response.data.error) {
                if (err.response.data.error.statusCode === 401) {
                    cb(null, { ...err.response.data.error, is_logout: true });
                }
                else
                    cb(null, err.response.data.error);
            }
            else
                cb(null, err);
        });
}

export function getCommunitySecurityList(community_id, board_id, cb) {
    let access_token = localStorage.getItem(cl_access_token);

    axios.get(`${API_LINK}/gs_Community/gs_getCommunitySecurityList?community_id=${community_id}&board_id=${board_id}&status=active,pending&access_token=${access_token}`)
        .then((res) => {
            if (res.data.statusCode.toString().slice(0, 2) == 20) {
                cb(res.data, null);
            } else {
                cb(null, res.data);
            }
        })
        .catch((err) => {
            if (err && err.response && err.response.data && err.response.data.error) {
                if (err.response.data.error.statusCode === 401) {
                    cb(null, { ...err.response.data.error, is_logout: true });
                }
                else
                    cb(null, err.response.data.error);
            }
            else
                cb(null, err);
        });
}

export function postVerifySecurity(board_id, security_id, action_type, message, cb) {
    let access_token = localStorage.getItem(cl_access_token);

    let data = {
        board_id: board_id,
        security_id: `${security_id}`,
        action_type: `${action_type}`
    }

    if (action_type === 0) {
        data.comment = message;
    }

    axios.post(`${API_LINK}/gs_Community/gs_postVerifyCommunitySecurity?access_token=${access_token}`, data)
        .then((res) => {
            if (res.data.statusCode.toString().slice(0, 2) == 20) {
                if (res.data.gsRespData) {
                    cb(res.data.gsRespData, null);
                }
                else
                    cb(null, res.data);
            }
            else {
                if (res.data.gsRespData) {
                    cb(null, res.data.gsRespData);
                }
                else
                    cb(null, res.data);
            }
        })
        .catch((err) => {
            if (err.response && err.response.data && err.response.data.error)
                if (err.response.data.error.statusCode === 401) {
                    cb(null, { ...err.response.data.error, is_logout: true });
                }
                else
                    cb(null, err.response.data.error);
            else
                cb(null, err);
        });
}

export function getAllPropertiesNotificationCount(cb) {
    let access_token = localStorage.getItem(cl_access_token);
    axios.get(`${API_LINK}/gs_Community/gs_getNotificationCountByProperty?access_token=${access_token}`)
        .then((res) => {
            if (res.data.statusCode.toString().slice(0, 2) == 20) {
                if (res.data.gsRespData) {
                    cb(res.data.gsRespData, null);
                }
                else
                    cb(null, res.data);
            }
            else {
                if (res.data.gsRespData) {
                    cb(null, res.data.gsRespData);
                }
                else
                    cb(null, res.data);
            }
        })
        .catch((err) => {
            if (err.response && err.response.data && err.response.data.error)
                if (err.response.data.error.statusCode === 401) {
                    cb(null, { ...err.response.data.error, is_logout: true });
                }
                else
                    cb(null, err.response.data.error);
            else
                cb(null, err);
        });
}

export function postRemoveCommunitySecurity(community_id, board_id, security_id, cb) {
    const access_token = localStorage.getItem(cl_access_token);
    const removed_by = localStorage.getItem(cl_user_id);
    const data = {
        community_id,
        board_ids: `${board_id}`,
        security_id,
        removed_by: removed_by
    }

    axios.post(`${API_LINK}/gs_Community/gs_postRemoveCommunitySecurity?access_token=${access_token}`, data)
        .then(res => {
            if (res && res.data && res.data.statusCode.toString().slice(0, 2) == 20) {
                cb(res.data.gsRespData, null);
            } else {
                cb(null, res.data);
            }
        })
        .catch(err => {
            if (err.response && err.response.data && err.response.data.error) {
                if (err.response.data.error.statusCode === 401) {
                    cb(null, { ...err.response.data.error, is_logout: true });
                } else {
                    cb(null, err.response.data.error);
                }
            } else {
                cb(null, err);
            }
        })
}

export function getCommunityRegistrationPropList(community_id, filter_data, cb) {
    let access_token = localStorage.getItem(cl_access_token);

    let data = "access_token=" + access_token;
    data += "&community_id=" + community_id;
    if (filter_data && Object.keys(filter_data).length > 0) {
        data += "&prop_filter=" + JSON.stringify(filter_data);
    }

    axios.get(`${API_LINK}/gs_Community/gs_getCommunityRegistrationPropList?${data}`)
        .then(res => {
            if (res && res.data && res.data.statusCode.toString().slice(0, 2) == 20) {
                cb(res.data.gsRespData, null);
            } else {
                cb(null, res.data);
            }
        })
        .catch(err => {
            if (err.response && err.response.data && err.response.data.error) {
                if (err.response.data.error.statusCode === 401) {
                    cb(null, { ...err.response.data.error, is_logout: true });
                } else {
                    cb(null, err.response.data.error);
                }
            } else {
                cb(null, err);
            }
        })
}

export function getCommunityPropSchema(community_id, cb) {
    let access_token = localStorage.getItem(cl_access_token);

    let data = "access_token=" + access_token;
    data += "&community_id=" + community_id;

    axios.get(`${API_LINK}/gs_Community/gs_getCommunityPropSchema?${data}`)
        .then(res => {
            if (res && res.data && res.data.statusCode.toString().slice(0, 2) == 20) {
                cb(res.data.gsRespData, null);
            } else {
                cb(null, res.data);
            }
        })
        .catch(err => {
            if (err.response && err.response.data && err.response.data.error) {
                if (err.response.data.error.statusCode === 401) {
                    cb(null, { ...err.response.data.error, is_logout: true });
                } else {
                    cb(null, err.response.data.error);
                }
            } else {
                cb(null, err);
            }
        })
}