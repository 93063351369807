import { REHYDRATE } from 'redux-persist';
import { SERVICES_ITEMS_GROUP, SERVICES_ITEMS_GROUP_SUCCESS, SERVICES_ITEMS_GROUP_ERROR, LOGOUT_RESET } from '../../actions/types';
import { parseServiceItems } from '../../scenes/client/scenes/booking/utils';
import { handleNetworkError } from '../../utils/api-error';

const INITIAL_STATE = {
    service_item_groups_list: [],
    error: undefined,
    loading: true,
    is_logout: false,
    cache: null
};


export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case REHYDRATE: if (action.payload && action.payload.services_item_groups) return { ...action.payload.services_item_groups }; else return INITIAL_STATE;
        case SERVICES_ITEMS_GROUP: return { ...INITIAL_STATE, loading: true, is_logout: false };
        case SERVICES_ITEMS_GROUP_SUCCESS:
            if (action.payload !== undefined && action.payload !== null) {
                if (action.payload.gsRespData && action.payload.gsRespData.get_svcgroupitems && action.payload.gsRespData.get_svcgroupitems.svc_grpitems && action.payload.gsRespData.get_svcgroupitems.svc_grpitems.length > 0) {
                    let svc_grpitems = parseServiceItems(action.payload.gsRespData.get_svcgroupitems.svc_grpitems);
                    return { ...INITIAL_STATE, service_item_groups_list: svc_grpitems, cache: action.payload.cache, loading: false, is_logout: false };
                }
                else {
                    return { ...INITIAL_STATE, loading: false, is_logout: false };
                }
            }
            else
                return { ...state, loading: false, is_logout: false };

        case SERVICES_ITEMS_GROUP_ERROR:
            let hNE_res = handleNetworkError(action);
            if (hNE_res.error)
                return { ...INITIAL_STATE, ...hNE_res, loading: false, is_logout: false };
            else if (action.payload && action.payload.response && action.payload.response.data && action.payload.response.data.error) {
                if (action.payload.response.data.error.statusCode === 401)
                    return { ...INITIAL_STATE, error: action.payload.response.data.error, loading: false, is_logout: true };
                else
                    return { ...INITIAL_STATE, error: action.payload.response.data.error, loading: false, is_logout: false };
            }
            else
                return { ...INITIAL_STATE, error: action.payload, loading: false, is_logout: false };
        case LOGOUT_RESET: return { ...INITIAL_STATE };
        default: return state;
    }
}