import EarlyMorningIcon from '../assets/icon_6am-8am.svg';
import MorningIcon from '../assets/icon_8am-12pm.svg';
import AfternoonIcon from '../assets/icon_12pm-5pm.svg';
import EveningIcon from '../assets/icon_5pm-9pm.svg';
import LateEveningIcon from '../assets/icon_9pm-12am.svg';
import EmergencyIcon from '../assets/icon_12am-6am.svg';

export const svc_rate_type = {
    hourly: "per hour",
    daily: "per day",
    weekly: "per week",
    monthly: "per month",
    pervisit: "per visit",
    onetime: "one time"
};

export const booking_type = {
    urgent: "Urgent Booking",
    today: "Book for Later Today",
    future: "Schedule a Service",
    broadcast_future: "Book With OGCare"
}

export const urgent = "Urgent Booking";
export const today = "Book for Later Today";
export const future = "Schedule a Service";
export const broadcast_future = "Book With OGCare";

export const broadcast_type = {
    urgent: "on_demand",
    broadcast_future: "post_future_date"
};

export const booking_filter = {
    booked: 'Booked',
    confirmed: 'Confirmed',
    reschedule: 'Reschedule',
    expired: 'Expired',
    unserviced: 'Unserviced',
    enroute: 'Enroute',
    accepted: 'Accepted',
    serviced: 'Serviced',
    completed: 'Completed',
    settled: 'Settled',
    indispute: 'Indispute',
    canceled: "Canceled",
    rejected: "Rejected",
    declined: 'Declined',
    broadcasted: 'Missed',
};


export const rate_factor_timeslots = [
    { id: 1, timeSlot: '6am - 8am', tag: 'Early Morning', imgUrl: EarlyMorningIcon, timeSlot_st: "6am", timeSlot_et: "8am" },
    { id: 2, timeSlot: '8am - 12pm', tag: 'Morning', imgUrl: MorningIcon, timeSlot_st: "8am", timeSlot_et: "12pm" },
    { id: 3, timeSlot: '12pm - 5pm', tag: 'Afternoon', imgUrl: AfternoonIcon, timeSlot_st: "12pm", timeSlot_et: "5pm" },
    { id: 4, timeSlot: '5pm - 9pm', tag: 'Evening', imgUrl: EveningIcon, timeSlot_st: "5pm", timeSlot_et: "9pm" },
    { id: 5, timeSlot: '9pm - 12am', tag: 'Late Evening', imgUrl: LateEveningIcon, timeSlot_st: "9pm", timeSlot_et: "12am" },
    { id: 6, timeSlot: '12am - 6am', tag: 'Emergency', imgUrl: EmergencyIcon, timeSlot_st: "12am", timeSlot_et: "6am" },
];

export const booking_flow_object = {
    category_id: "category_id",
    category_name: "category_name",
    svc_id: "svc_id",
    svc_name: "svc_name",
    svc_items: "svc_items",
    svc_item_groups: "svc_item_groups",
    search_suggestions: "search_suggestions",
    booking_svc_place: "booking_svc_place",
    booking_selected_address: "booking_selected_address",
    booking_svc_obj: "booking_svc_obj",
    booking_date: "booking_date",
    booking_duration: "booking_duration",
    booking_timeslot: "booking_timeslot",
    booking_timezone: "booking_timezone",
    sp_id: "sp_id",
    booking_quantity_info: "booking_quantity_info",
    booking_svc_time: "booking_svc_time",
    booking_address: "booking_address",
    booking_type: "booking_type",
    booking_svc_description: "booking_svc_description",
    booking_media: "booking_media",
    booking_discount: "booking_discount",
    booking_coupon_code: "booking_coupon_code",
    visiting_charges: "visiting_charges",
    booking_questionnaire_qa: "booking_questionnaire_qa",
    booking_questionnaire: "booking_questionnaire",
    booking_questionnaire_path: "booking_questionnaire_path",
    booking_search_flow: "booking_search_flow",
    sp_available_discount: "sp_available_discount",
    visit_date: "visit_date",
    cl_redeemable_rewards_obj: "cl_redeemable_rewards_obj",
    is_cl_redeem_reward: "is_cl_redeem_reward",
    is_premium_sp: "is_premium_sp",           //  Use for PAN India OGCare
};

export const booking_search_flow_enum = {
    sp_name: "sp_name",
    sp_list: "sp_list",
    on_demand: "on_demand"
};

export const invoice_obj = {
    invoice_media: "invoice_media",
};

export const quote_obj = {
    quote_media: "quote_media",
};