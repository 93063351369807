import React, { PureComponent } from 'react';

import LinearProgress from 'material-ui/LinearProgress';
import FlatButton from 'material-ui/FlatButton';
import Dialog from 'material-ui/Dialog';

import PropertiesFilter from '../properties-filter';
import PropertyListUI from '../property-list-ui';
import { getAllPropertiesInSociety, getCommunityRegistrationPropList, getCommunityPropSchema } from '../../api';
import { parseRegistrationPropList, parseSocietyPropList, parseFiltersForBoard } from './utils';
import gs_color from 'config/colors.css';

class PropertyList extends PureComponent {
    constructor() {
        super();

        this.state = {
            filter_api_loader: true,
            is_filter_selected: false,
            loading: false,
            prop_list: [],
            board_id: null,
            filter_fields: null,
            filter_options: null,
            common_board_id: null,
            showErrorDialog: false,
            errorMessage: "",
        }
    }

    componentDidMount() {
        if (!this.props.filter_fields) {
            this.setState({ filter_api_loader: false, filter_fields: null });
        }
        else {
            getCommunityPropSchema(this.props.community_id, (res, err) => {
                if (res) {
                    const filter_options = parseFiltersForBoard(res.filter_options, this.props.board_id, res.common_board_id);
                    this.setState({ filter_api_loader: false, filter_fields: res.filter_fields, filter_options: filter_options, common_board_id: res.common_board_id });
                } else {
                    if (err && err.message && err.message.constructor === String) {
                        this.setState({ errorMessage: err.message, showErrorDialog: true, filter_api_loader: false });
                    }
                }
            });
        }
    }

    reload() {
        this.callPropertyListFetch(this.filter_data);
    }

    callPropertyListFetch(filter_data, board_id) {
        this.filter_data = filter_data;

        if (this.props.filtersOnly) {
            if (typeof this.props.getSelectedFilters === "function") {
                this.props.getSelectedFilters(filter_data);
            }

            return;
        }

        this.setState({ is_filter_selected: true, loading: true });

        if (this.props.API_TYPE === "REGISTRATION") {
            getCommunityRegistrationPropList(this.props.community_id, filter_data, (res, err) => {
                if (res && res.prop_list) {
                    this.setState({ loading: false, prop_list: parseRegistrationPropList(res.prop_list, this.props.groupByFloor), board_id });
                } else {
                    if (err && err.message && err.message.constructor === String) {
                        this.setState({ errorMessage: err.message, showErrorDialog: true, loading: false });
                    }
                }
            });
        }
        else {
            getAllPropertiesInSociety(this.props.community_id, filter_data, (res, err) => {
                if (res && res.prop_list) {
                    this.setState({ loading: false, prop_list: parseSocietyPropList(res.prop_list, this.props.groupByFloor), board_id });
                } else {
                    if (err && err.message && err.message.constructor === String) {
                        this.setState({ errorMessage: err.message, showErrorDialog: true, loading: false });
                    }
                }
            });
        }
    }

    render() {
        if (this.state.filter_api_loader) {
            return (
                <div style={{ textAlign: 'center' }}>
                    <LinearProgress color={gs_color.accentColor} mode="indeterminate" />
                </div>
            );
        }

        const errorActions = [
            <FlatButton
                label="Ok"
                primary={true}
                onClick={() => this.setState({ showErrorDialog: false })}
            />
        ]

        return (
            <div style={this.props.style ? this.props.style : { padding: '20px 5%' }}>
                <PropertiesFilter
                    filter_fields={this.state.filter_fields}
                    filter_options={this.state.filter_options}
                    common_board_id={this.state.common_board_id}
                    selected_filter_values={this.props.selected_filter_values}
                    callPropertyListFetch={(filter_data, board_id) => this.callPropertyListFetch(filter_data, board_id)}
                    loading={this.state.loading}
                    is_filter_selected={this.state.is_filter_selected}
                    partialFiltersAllowed={this.props.partialFiltersAllowed}
                />
                {
                    !this.props.filtersOnly && this.state.is_filter_selected &&
                    <PropertyListUI
                        loading={this.state.loading}
                        data={this.state.prop_list}
                        filter_data={this.filter_data}
                        {...this.props}
                        board_id={this.state.board_id}
                    />
                }
                {
                    this.state.showErrorDialog &&
                    <Dialog
                        actions={errorActions}
                        open={this.state.showErrorDialog}
                        onRequestClose={() => this.setState({ showErrorDialog: false })}
                        titleClassName="dialogTitleClassName"
                        bodyClassName="dialogBodyClassName"
                        contentClassName="DialogNewStyle"
                    >
                        {
                            this.state.errorMessage.constructor === String &&
                            this.state.errorMessage.split("\n").map((string, i) => {
                                return (
                                    <div key={i} style={{ color: "#212121" }}>
                                        <span>{string}</span><br />
                                    </div>
                                )
                            })
                        }
                    </Dialog>
                }
            </div>
        )
    }
}

export default PropertyList;