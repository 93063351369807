import { REHYDRATE } from 'redux-persist';
import { SERVICE_SPECIFIC_FLOW, SERVICE_SPECIFIC_FLOW_SUCCESS, SERVICE_SPECIFIC_FLOW_ERROR } from '../../actions/types';
import ApiError, { handleNetworkError } from '../../utils/api-error';
import { isValidDigit, isValidFloat } from '../../utils/validation';

// const ssfj = {
//     "booking_flow_type": "service",         // "service/product/appointment",
//     "product_category_filter": "",
//     "is_deposit_applicable": false,          // "Deposit applicable",
//     "is_rate_factor_applicable": false,   // "true/false",
//     "offhour_applicable": false,          // "true/false",
//     "service_items_or_sp_list": "Show service items or sp list  **May be Future Use ",
//     "show_que_or_service_items": "que",   // que  ||  service_items     // "Show question set or service items  **Normally questionaire, while svc_itme in some service like Body & Beatury",
//     "question_set_available": true,          // "true/false   **false in case svc_item and generic problem statement",
//     "after_question_show_service_mapped_items": false,    // "true/false     **To control display of mapped svc_item on SP_DETAILS screen",
//     "show_requirements": "all",         // all/text/media/none       ** This to be used on question list screen. None for photography       ** Default both",
//     "is_quantity_applicable": false,           // "true/false",
//     "screen_type": "text",                      // "map / text / both       **Enum",
//     "client_specify_budget": false,           // "Applicable for service flow in Australia   **Refer YELP for US.",
//     "ondemand_applicable": true,              // "true/false",
//     "ondemand_duration_applicable": 30,          // Ondemand duration applicable 
//     "future_day_applicable": true,            // "true/false",
//     "show_profile_pic_unauthenticated": false, // "true/false  **Configure based on gender. Take consent from User for profile pic display.",
//     "show_profile_pic_booking_status": "all",   // "Booked / Confirmed / Any (includes sp details for authenticated) / All",
//     "quote_negotiation_attempts": 2,            // "Max negotiation (versions) of quote possible",
//     "store_amenities": false,              // "true/false   **Display list if amenities avialable at store like A.C.,Video Gae etc",
//     "max_days_allowed": { day: 8, month: 0, year: 0 },                     // "Max days client can book from current date",
//     "new_booking_after_duration": 30,            // Duration after which booking should be done.     eg. 3hrs for cake
//     "is_pickup_and_dropoff": false,               // "true/false     //SWF5",
//     "is_subscription": false,                 // "true/false       //SWF4",
//     "is_inspection": false,                   // "true/false    //SWF3",
//     "is_reschedule_applicable": true,           // "true/false",
//     "is_quotation_compulsory": false,         // "true/false        **continue service after inspection",
//     "is_invoice_compulsory": false,           // "true/false        **consultancy",
//     "is_prepaid_subscription_service": undefined,        //Related to subscription
//     "cancel_charges": undefined,                // value means its applicable
//     "visiting_charges": undefined,               // if no value, sp will override it                
//     "reschedule_period": 180,                    // "<default 3 hrs>       ** value in mins",
//     "cancel_period": 180,                         // "<default 3 hrs>       ** value in mins",
//     "default_bkg_dur": 30,                      // "<default 30 min>",
//     "booking_duration_factor": 30,              // "<default 30 min>   ** value in mins     // duration factor for timeslots.  eg. for painting 3 hrs -> 12 - 3, 3 - 6, 6 - 9, 9 - 12"
//     "schedule_min_duration": 0,                 // Minimum schedule duration in min
//     "schedule_max_duration": 1440,              // Maximum schedule duration in min
//     "display_title": {
//         "inspection_title": "Inspection",           // "Don't know what problem is/ Need help/ Inspection/Consultation",
//         "booking_date_title": "Inspection date",    // "Inspection date / Booking date / Pickup Date/Consultancy Date",
//         "delev_date_title": "Service date",         // "Delivery date / Service Date",
//         "visiting_charge": "Delivery Charge",       // "Visiting Charge / Delivery Charge"
//         "service_requirements": "Instructions"             // "Service Requirements / Instructions"
//     }
// };

const INITIAL_STATE = {
    service_specific: {},
    error: undefined,
    loading: false,
    is_logout: false
};

// Only to be used for testing
export function replaceStringToBoolean(input) {
    let output = {};

    if (input && input.constructor === Object) {
        Object.keys(input).forEach(key => {
            if (input[key].constructor === String) {
                if (input[key] === "true")
                    output[key] = true;
                else if (input[key] === "false")
                    output[key] = false;
                else if (!isNaN(input[key])) {
                    if (isValidDigit(input[key])) {
                        output[key] = parseInt(input[key], 10);
                    }
                    else if (isValidFloat(input[key])) {
                        output[key] = parseFloat(input[key]);
                    }
                    else
                        output[key] = input[key];
                }
                else
                    output[key] = input[key];
            }
            else
                output[key] = input[key];
        });

        return output;
    }
    else
        return input;
}

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case REHYDRATE: if (action.payload && action.payload.service_specific) return { ...action.payload.service_specific }; else return INITIAL_STATE;
        case SERVICE_SPECIFIC_FLOW: return { ...INITIAL_STATE, loading: true, is_logout: false };
        case SERVICE_SPECIFIC_FLOW_SUCCESS:
            if (action.payload !== undefined && action.payload !== null) {
                let data = action.payload;
                let resp = ApiError(data.statusCode, data.message, data.gsRespData, null, {});
                resp = replaceStringToBoolean(resp);
                return { ...INITIAL_STATE, service_specific: resp, loading: false, is_logout: false };
            }
            else
                return { ...state, loading: false, is_logout: false };
        case SERVICE_SPECIFIC_FLOW_ERROR:
            let hNE_res = handleNetworkError(action);
            if (hNE_res.error)
                return { ...INITIAL_STATE, ...hNE_res, loading: false, is_logout: false };
            else if (action.payload && action.payload.response && action.payload.response.data && action.payload.response.data.error) {
                if (action.payload.response.data.error.statusCode === 401)
                    return { ...INITIAL_STATE, error: action.payload.response.data.error, loading: false, is_logout: true };
                else
                    return { ...INITIAL_STATE, error: action.payload.response.data.error, loading: false, is_logout: false };
            }
            else
                return { ...INITIAL_STATE, error: action.payload, loading: false, is_logout: false };
        default: return state;
    }
}