import { compose, createStore, applyMiddleware } from 'redux';
import ReduxThunk from 'redux-thunk';
import { persistStore } from 'redux-persist';

import reducers from './reducers';

const middleware = applyMiddleware(ReduxThunk);      //Using middleware in our project

// Create a store on redux (reducer store).
const store = createStore(
    reducers,
    undefined,
    compose(
        middleware
    )
)

// Persist the store (on session storage)
persistStore(
    store,
    null,
    () => {
        store.getState() // if you want to get restoredState
    }
)

export default store;   // Return the store object