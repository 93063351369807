import React from 'react';

import Divider from 'material-ui/Divider';

import SideNavHeader from '../side-nav-header';
import SideNavItem from '../side-nav-item';
import SideNavItemMinor from '../side-nav-item-minor';
import sideNavConfig from '../../config/side-nav-config';
import {
    newScheduleRoute, spRegistrationRoute, scheduleRoute,
    homeRoute, spHomeRoute, ogcartRoute, ogcareRoute
} from 'routes-parameters/config';

import storage from '../../../../../middleware/storage';
import styles from './styles';

import { navMenuType } from '../../config/side-nav-config';

import { sp_signedIn, cl_signedIn, sp_create_schedule } from 'config/storage-variables';
import { isOGCart, isOGCare } from 'utils/helper-functions';
import SideNavCta from '../side-nav-cta';

const disabledRoutes = [newScheduleRoute, spRegistrationRoute];

class SideNavBar extends React.Component {
    constructor(props) {
        super(props)

        const [itemData,] = this.getItemDataNRoute()
        const selected = this.getSelected(itemData)
        const enabled = this.getEnabled()

        this.state = {
            selected,
            enabled,
        }
    }

    getItemDataNRoute = () => {

        if (storage.getLocal(sp_signedIn) === "true") {
            return [sideNavConfig.spItemData, spHomeRoute];

        } else {
            const pathname = this.props.pathname ? this.props.pathname : '/';
            this.is_ogcart = isOGCart() || pathname === `/${ogcartRoute}`;
            this.is_ogcare = isOGCare() || pathname === `/${ogcareRoute}`;
            return [sideNavConfig.clientItemData(this.is_ogcart, this.is_ogcare), this.is_ogcart ? ogcartRoute : this.is_ogcare ? ogcareRoute : homeRoute];
        }
    }

    getSelected = (data) => {
        const pathname = this.props.pathname ? this.props.pathname : '/';
        var selected;

        if (data) {
            selected = data.filter(d => d.route === pathname.slice(1))
        }

        return selected && selected.length > 0 ? selected[0].title : '';
    }

    setSelected = (title) => this.setState({ selected: title })

    getEnabled = () => {
        const pathname = this.props.pathname ? this.props.pathname : '/';

        if (pathname.slice(1) === scheduleRoute) {
            return !(storage.getLocal(sp_create_schedule) === 'true')

        } else {
            var disabled = disabledRoutes.filter(route => pathname.indexOf(route) != -1)
            return !(disabled && disabled.length > 0)
        }
    }

    render() {
        const [itemData, headerHomeRoute] = this.getItemDataNRoute()
        return (
            <div className="sideNavOuterTab" style={styles.SideNavOuter}>
                <div style={styles.SideNavInner}>
                    <SideNavHeader
                        enabled={this.state.enabled}
                        headerHomeRoute={headerHomeRoute}
                        is_ogcare={this.is_ogcare}
                        is_ogcart={this.is_ogcart}
                    />
                    <div style={styles.SideNavItemList}>
                        {
                            itemData.map((data) =>
                                data.type === navMenuType.menu
                                    ? <SideNavItem
                                        key={data.title}
                                        {...data}
                                        enabled={this.state.enabled}
                                        selected={this.state.selected}
                                        setSelected={this.setSelected} />
                                    : <SideNavCta
                                        key={data.title}
                                        {...data}
                                        enabled={this.state.enabled} />)
                        }
                    </div>
                    <Divider className="hideTabView" style={styles.SideNavDivider} />
                    <div className="hideTabView" style={styles.SideNavItemList}>
                        {
                            sideNavConfig.minorItemData.map((data) =>
                                <SideNavItemMinor
                                    key={data.title}
                                    {...data}
                                    enabled={this.state.enabled} />)
                        }
                    </div>
                </div>
            </div>
        )
    }

    componentDidUpdate(prevProps) {
        if (prevProps.pathname !== this.props.pathname || prevProps.showNewHome !== this.props.showNewHome) {
            const [itemData, headerHomeRoute] = this.getItemDataNRoute()
            const selected = this.getSelected(itemData)
            const enabled = this.getEnabled()
            this.setState({
                itemData,
                selected,
                enabled,
                headerHomeRoute
            })
        }
    }
}

export default SideNavBar;