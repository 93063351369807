import React from 'react';
import Paper from 'material-ui/Paper';
import gs_color from 'config/colors.css';

function CardView(props) {
    const disable = props.disable;

    return (
        <Paper onClick={() => props.onClick()} style={Object.assign({}, { borderRadius: '5px', padding: '20px 10px', position: 'relative' }, props.style || {})}>
            {
                disable &&
                <div style={{ position: 'absolute', zIndex: 5, top: 10, left: 10, backgroundColor: gs_color.primaryColor75, padding: '4px 6px', fontSize: '11px' }}>
                    PREMIUM
                </div>
            }
            <div style={Object.assign({}, disable && { opacity: 0.6, marginTop: 10 })}>
                <img
                    src={props.icon}
                    style={{ width: '60px', height: '60px' }}
                />
                <h4 style={{ marginBottom: '0px' }}>{props.title}</h4>
            </div>
        </Paper>
    );
}

export default CardView;