import axios from 'axios';
import { APP_VERSION } from '../config/config';
import { device_id, pushUriId, cl_user_id, sp_user_id } from '../config/storage-variables';

axios.defaults.headers.common['WEB_APP_VERSION'] = splitVersion();      // for all requests

var set_default_device_id = false, set_default_push_uri = false,
    set_default_cl_user_id = false, set_default_sp_user_id = false;

function splitVersion() {
    let version = APP_VERSION.split('.');
    return (version[0] * 100 + version[1] * 10 + version[2] * 1)
}

function handleDefaultDeviceId() {
    const DEVICE_ID = localStorage.getItem(device_id);

    if (set_default_device_id) {
        // Default Device id is set, check if device id not available then remove it.

        if (!DEVICE_ID) {
            set_default_device_id = false;

            delete axios.defaults.headers.common['DEVICE_ID'];
        }
    }
    else {
        // Default Device id is not set, check if device id available then add it.

        if (DEVICE_ID) {
            set_default_device_id = true;

            axios.defaults.headers.common['DEVICE_ID'] = DEVICE_ID;
        }
    }
}

function handleDefaultPushUri() {
    const PUSH_URI = localStorage.getItem(pushUriId);

    if (set_default_push_uri) {
        // Default push uri is set, check if push uri not available then remove it.

        if (!PUSH_URI) {
            set_default_push_uri = false;

            delete axios.defaults.headers.common['PUSH_URI'];
        }
    }
    else {
        // Default push uri is not set, check if push uri available then add it.

        if (PUSH_URI) {
            set_default_push_uri = true;

            axios.defaults.headers.common['PUSH_URI'] = PUSH_URI;
        }
    }
}

function handleDefaultClientUserId() {
    const user_id = localStorage.getItem(cl_user_id);

    if (set_default_cl_user_id) {
        // Default client user id is set, check if client user id not available then remove it.

        if (!user_id) {
            set_default_cl_user_id = false;

            delete axios.defaults.headers.common['CL_USER_ID'];
        }
    }
    else {
        // Default client user id is not set, check if client user id available then add it.

        if (user_id) {
            set_default_cl_user_id = true;

            axios.defaults.headers.common['CL_USER_ID'] = user_id;
        }
    }
}

function handleDefaultSpUserId() {
    const user_id = localStorage.getItem(sp_user_id);

    if (set_default_sp_user_id) {
        // Default sp user id is set, check if sp user id not available then remove it.

        if (!user_id) {
            set_default_sp_user_id = false;

            delete axios.defaults.headers.common['SP_USER_ID'];
        }
    }
    else {
        // Default sp user id is not set, check if sp user id available then add it.

        if (user_id) {
            set_default_sp_user_id = true;

            axios.defaults.headers.common['SP_USER_ID'] = user_id;
        }
    }
}

// Middleware function before every API call.
function middlewareAxiosRequest(url, data) {

    // let WEB_APP_VERSION_STRING = `WEB_APP_VERSION=${APP_VERSION}`;

    // if (url.indexOf('?') > -1) {
    //     WEB_APP_VERSION_STRING = '&' + WEB_APP_VERSION_STRING;
    // }
    // else {
    //     WEB_APP_VERSION_STRING = '?' + WEB_APP_VERSION_STRING;
    // }

    handleDefaultDeviceId();
    handleDefaultPushUri();
    handleDefaultClientUserId();
    handleDefaultSpUserId();

    return {
        // url: url + WEB_APP_VERSION_STRING,
        url: url,
        data
    };
}

// Handle every axios post API.
function axiosPost(url, data = {}) {
    // Manipulate data according to middleware function.
    const middlewareAxiosRequestData = middlewareAxiosRequest(url, data);
    const axios_url = middlewareAxiosRequestData.url;
    const axios_data = middlewareAxiosRequestData.data;

    return new Promise((resolve, reject) => {
        axios.post(axios_url, axios_data)
            .then(res => resolve(res))
            .catch(err => reject(err));
    });
}

// Handle every axios get API.
function axiosGet(url, data = {}) {
    // Manipulate data according to middleware function.
    const middlewareAxiosRequestData = middlewareAxiosRequest(url, data);
    const axios_url = middlewareAxiosRequestData.url;
    const axios_data = middlewareAxiosRequestData.data;

    return new Promise((resolve, reject) => {
        axios.get(axios_url, axios_data)
            .then(res => resolve(res))
            .catch(err => reject(err));
    });
}

// Handle every axios patch API.
function axiosPatch(url, data = {}) {
    // Manipulate data according to middleware function.
    const middlewareAxiosRequestData = middlewareAxiosRequest(url, data);
    const axios_url = middlewareAxiosRequestData.url;
    const axios_data = middlewareAxiosRequestData.data;

    return new Promise((resolve, reject) => {
        axios.patch(axios_url, axios_data)
            .then(res => resolve(res))
            .catch(err => reject(err));
    });
}

// Handle every axios delete API.
function axiosDelete(url, data = {}) {
    // Manipulate data according to middleware function.
    const middlewareAxiosRequestData = middlewareAxiosRequest(url, data);
    const axios_url = middlewareAxiosRequestData.url;
    const axios_data = middlewareAxiosRequestData.data;

    return new Promise((resolve, reject) => {
        axios.delete(axios_url, axios_data)
            .then(res => resolve(res))
            .catch(err => reject(err));
    });
}

// Return all middleware functions to default method function.
export default {
    post: axiosPost,
    get: axiosGet,
    patch: axiosPatch,
    delete: axiosDelete
};