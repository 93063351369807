import moment from 'moment';
import { POLL_QUESTION_CHAR_LIMIT } from 'config/config';
import { currentTimeMoment, convertTimseStampToText, dateDiffInDays } from 'utils/date-time-conversion';
import { parseBoardMemberRole } from '../select-society/scenes/register-property/config';
import { PollStatus } from './config';

export const parsePollObj = (poll) => {
    if (!poll) {
        return {};
    }

    const poll_id = poll.poll_id;
    const question = poll.poll_title;
    const isLive = poll.poll_status && poll.poll_status === PollStatus.ONLINE;
    const endTime = poll.end_date ? convertTimseStampToText(poll.end_date, 2) : null;
    const endTimeTS = poll.end_date;
    const voted_id = poll.voted_id;
    const showResult = !isLive || !!voted_id;
    const totalVotes = (poll.poll_result && poll.poll_result.total_votes) || 0;
    const totalParticipants = (poll.poll_result && poll.poll_result.total_participants) || 0;
    const profilePic = (poll.started_by && poll.started_by.profile_pic) || "";
    const displayName = (poll.started_by && poll.started_by.name) || "OlaGate User";
    const subTitle = poll.started_by && (poll.started_by.board_role ? parseBoardMemberRole(poll.started_by.board_role) : poll.started_by.propunit);
    const refType = poll.ref_type || "";
    const refId = poll.ref_typeid || "";
    const ticketId = poll.ticket_id || "";
    const mayOverrideResult = (poll.started_by && poll.started_by.board_role) || false;
    const overrideResult = !!poll.is_read_only;
    const pollType = poll.poll_type || "";
    const pollStatus = poll.poll_status || "";
    const startTime = poll.start_date ? convertTimseStampToText(poll.start_date, 2) : null;
    const participantType = poll.participant_type;
    const participantList = poll.participant_list && poll.participant_list.split(",");
    const visibility = poll.visibility || null;
    let pollDurDay = 0;
    let pollDurHrs = 0;

    if (poll.poll_status && poll.poll_status === PollStatus.SCHEDULE && poll.start_date) {
        const start = moment(poll.start_date);
        const end = moment(poll.end_date);

        const hours = end.diff(start, "hours");
        pollDurDay = Math.ceil(hours / 24);
        pollDurHrs = hours % 24;
    }

    let choices = [];
    let max = 0;
    let votesMax = [];

    if (poll.options && poll.options.length > 0) {
        const votes_stats = (poll.poll_result && poll.poll_result.votes_stats) || [];
        for (const option of poll.options) {
            let choice = {
                choice_id: option.vote_id,
                choice_text: (option.vote_option && option.vote_option.option) || ""
            }
            const stats = votes_stats.filter(stat => stat.vote_id === option.vote_id);
            if (stats.length > 0) {
                const votes = stats[0].vote_count || 0
                choice.votes = votes;
                if (votes > max) {
                    max = votes
                }
            }

            choices.push(choice);
        }

        if (max !== 0) {
            const maxIds = votes_stats.filter(stat => stat.vote_count === max);
            maxIds.forEach(maxId => {
                votesMax.push(maxId.vote_id);
            })
        }
    }

    return {
        poll_id,
        question,
        choices,
        user_selected_choice: voted_id,
        votesMax,
        totalVotes,
        totalParticipants,
        showResult,
        isLive,
        endTime,
        endTimeTS,
        profilePic,
        displayName,
        subTitle,
        refType,
        refId,
        ticketId,
        mayOverrideResult,
        pollType,
        pollStatus,
        startTime,
        participantType,
        participantList,
        visibility,
        pollDurDay,
        pollDurHrs,
        overrideResult
    }
}

export const getInitialScheduleTime = timezone => {
    let currentTime = currentTimeMoment(3, timezone);
    if (currentTime.minute() <= 15) {
        currentTime.minute(30);

    } else if (currentTime.minute() <= 45) {
        currentTime.add(1, 'hours').minute(0);
    }

    return currentTime;
}

export function validations(stateObj) {
    let errorState = {
        questionError: '',
        choicesError: {},
        participantListError: false,
        durationError: '',
    };
    let is_error = false;

    if (!stateObj.question) {
        errorState.questionError = 'Required';
        is_error = true;
    }
    else if (stateObj.question.trim().length > POLL_QUESTION_CHAR_LIMIT) {
        errorState.questionError = `Maximum ${POLL_QUESTION_CHAR_LIMIT} characters allowed`
        is_error = true;
    }

    const choices = { ...stateObj.choices };
    for (const key in choices) {
        let choice = (choices[key] || "").trim();

        if ((key == 0 || key == 1) && !choice) {
            const error = { ...errorState.choicesError };
            error[key] = "Required";
            errorState.choicesError = error;
            is_error = true;
        }
    }

    if (stateObj.showParticipantSettings && !stateObj.selectedParticipants.length) {
        errorState.participantListError = true;
        is_error = true;
    }

    if (!stateObj.selectedDurDay && !stateObj.selectedDurHrs) {
        errorState.durationError = 'Required';
        is_error = true;
    }

    return [errorState, is_error];
}


export const parseVerdictLog = (pollObj, verdictRespData) => {
    let data = {};
    let votesData = [];

    if (pollObj && verdictRespData) {
        const choices = pollObj.choices || [];

        data.totalParticipants = pollObj.totalParticipants || 0;
        data.totalVotes = 0;

        if (choices.length > 0) {
            let choiceVotes = {};

            for (const choice of choices) {
                choiceVotes[choice.choice_id] = {
                    id: choice.choice_id,
                    text: choice.choice_text,
                    totalVotes: 0,
                    anonVotes: 0,
                    people: [],
                    votesMax: []
                }
            }

            const vote_list = verdictRespData.vote_list || [];
            for (const vote of vote_list) {
                let person = {
                    id: vote.resident_uid,
                    displayName: vote.display_name,
                    profilePic: vote.profile_pic,
                    subTitle: vote.prop_unit_name,
                }

                if (choiceVotes[vote.vote_id]) {
                    const people = choiceVotes[vote.vote_id].people;
                    choiceVotes[vote.vote_id].people = [...people, person];
                    choiceVotes[vote.vote_id].totalVotes++;
                    data.totalVotes++;
                }
            }

            const anonymous_vote_list = verdictRespData.anonymous_vote_list || [];
            for (const vote of anonymous_vote_list) {
                if (choiceVotes[vote.vote_id]) {
                    choiceVotes[vote.vote_id].anonVotes = vote.votes;
                    choiceVotes[vote.vote_id].totalVotes = choiceVotes[vote.vote_id].totalVotes + vote.votes;
                    data.totalVotes = data.totalVotes + vote.votes;
                }
            }

            for (const choice of choices) {
                votesData.push(choiceVotes[choice.choice_id]);
            }

            let max = 0;
            let votesMax = [];
            for (const choiceId in choiceVotes) {
                if (choiceVotes[choiceId].totalVotes > max) {
                    max = choiceVotes[choiceId].totalVotes;
                }
            }

            for (const choiceId in choiceVotes) {
                if (max > 0 && choiceVotes[choiceId].totalVotes === max) {
                    votesMax.push(choiceVotes[choiceId].text);
                }
            }

            data.votesData = votesData;
            data.votesMax = votesMax;
        }
    }

    return data;
}