import React, { PureComponent } from 'react';
import MyAppBar from 'components/app-bar';
import BackButton from 'components/back-button';
import BottomNav from 'components/bottom-navigation';
import Error401 from './components/401';
import Error404 from './components/404';
import Error501 from './components/501';
import Offline from './components/offline';
import SomethingWentWrong from './components/something-went-wrong';

class ErrorComponent extends PureComponent {
    static defaultProps = {
        showAppBar: true,
        showBottomNav: true,
        hideErrorTitle: false
    };

    render() {
        let statusCode = null;
        if (this.props.error && this.props.error.statusCode)
            statusCode = this.props.error.statusCode;

        const children = this.props.children;

        return (
            <div className={this.props.showAppBar ? "nonAppBar" : ""}>
                {
                    this.props.showAppBar ?
                        <MyAppBar
                            className="myAppBar"
                            title={statusCode === 600 || statusCode === 601 ? "Network Error" : "Error"}
                            elementLeft={<BackButton history={this.props.history} />}
                        />
                        : null
                }
                <div className={this.props.showBottomNav ? "nonBottomNavigation" : ""}>
                    {
                        statusCode === 401 ?
                            <Error401 hideErrorTitle={this.props.hideErrorTitle} error={this.props.error} />
                            : statusCode === 400 || statusCode === 404 ?
                                <Error404 hideErrorTitle={this.props.hideErrorTitle} error={this.props.error} />
                                : statusCode === 500 || statusCode === 501 ?
                                    <Error501 hideErrorTitle={this.props.hideErrorTitle} error={this.props.error} />
                                    : statusCode === 600 || statusCode === 601 ?
                                        <Offline hideErrorTitle={this.props.hideErrorTitle} error={this.props.error} />
                                        : <SomethingWentWrong hideErrorTitle={this.props.hideErrorTitle} error={this.props.error} />
                    }
                    {
                        children
                    }
                </div>
                {
                    this.props.showBottomNav ?
                        <BottomNav
                            selectedIndex={-1}
                            history={this.props.history} />
                        : null
                }
            </div>
        )
    }
}

ErrorComponent.displayName = 'ErrorComponent';
export default ErrorComponent;