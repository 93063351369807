import React from 'react';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay, virtualize, bindKeyboard } from 'react-swipeable-views-utils';
import { styles } from './styles';
import { downloadMedia } from 'utils/handle-media';

function onClickAd(ad, props) {
    if (typeof props.onClickAd === "function") {
        props.onClickAd(ad)
    }
}

function renderAd(ad, props) {
    const url = ad.url

    return (
        <div
            onClick={() => onClickAd(ad, props)}
            style={{ borderRadius: '8px', width: '100%', textAlign: 'center' }}
            key={url}
        >
            <img
                src={downloadMedia(url)}
                width={props.width ? props.width : "100%"}
                height={props.height ? props.height : "auto"}
                style={{ borderRadius: '8px' }}
                alt=''
            />
        </div>
    );
}

function slideRenderer(params, props) {
    let { index } = params;
    index = index % props.Ads.length;

    if (props.renderAd) {
        return props.renderAd(props.Ads[index]);
    }

    return renderAd(props.Ads[index], props);
}

function RenderCards(props) {
    let EnhancedSwipeableViews = virtualize(SwipeableViews);
    if (props.autoplay) {
        EnhancedSwipeableViews = autoPlay(EnhancedSwipeableViews);
    }

    EnhancedSwipeableViews = bindKeyboard(EnhancedSwipeableViews);

    const style = { ...styles, ...(props.styles || {}) };

    return (
        <EnhancedSwipeableViews
            enableMouseEvents
            style={style.root}
            slideStyle={style.slideContainer}
            slideCount={props.count}
            interval={props.interval || undefined}
            slideRenderer={params => (props.slideRenderer && props.slideRenderer(params)) || slideRenderer(params, props)}
        />
    );
}

export default RenderCards;