import { REHYDRATE } from 'redux-persist'

import ApiError, { handleNetworkError } from '../../utils/api-error'
import {
    SOCIETY_POLL_LIST, SOCIETY_POLL_LIST_ERROR, SOCIETY_POLL_LIST_SUCCESS,
    LOGOUT_RESET, SOCIETY_POLL_LIST_MORE, SOCIETY_POLL_LIST_SCROLL
} from '../../actions/types';
import { convertMomentObjectToUTCISOString } from '../../utils/date-time-conversion';

const INITIAL_STATE = {
    poll_list: [],
    active_poll_list: [],
    cache: null,
    last_poll_start_date: undefined,
    can_load_more: false,
    scroll_offset: 0,
    error: undefined,
    loading: true,
    is_logout: false
}

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case REHYDRATE: if (action.payload && action.payload.society_poll_list) return { ...action.payload.society_poll_list }; else return INITIAL_STATE;

        case SOCIETY_POLL_LIST: return { ...INITIAL_STATE, loading: true, scroll_offset: state.scroll_offset || 0 };
        case SOCIETY_POLL_LIST_MORE: return { ...state, loading: true, is_logout: false };

        case SOCIETY_POLL_LIST_SUCCESS:
            const apiData = action.payload && action.payload.apiData;
            const cache = action.payload && action.payload.cache;
            const batchSize = action.payload && action.payload.batchSize;

            if (apiData !== undefined && apiData !== null) {
                let poll_list = ApiError(apiData.statusCode, apiData.message, apiData.gsRespData, "poll_list", []);
                let active_poll_list = ApiError(apiData.statusCode, apiData.message, apiData.gsRespData, "active_poll_list", []);

                if (poll_list && poll_list.constructor === Array) {
                    const state_list = state.poll_list && state.poll_list.constructor === Array ? state.poll_list : [];
                    const req_list = [...state_list, ...poll_list];

                    let last_start_date = undefined;
                    if (req_list.length > 0) {
                        last_start_date = req_list[req_list.length - 1].start_date;
                        if (last_start_date) {
                            last_start_date = convertMomentObjectToUTCISOString(0, last_start_date, null, null, true);
                        }
                    }

                    let loadMore = false;
                    if (poll_list.length === batchSize) {
                        loadMore = true;
                    }

                    if (active_poll_list) {
                        active_poll_list = active_poll_list.map(poll => { return { ...poll, poll_status: 'online' } });
                    }

                    let activePollList = active_poll_list && active_poll_list.length > 0 ? active_poll_list : state.active_poll_list;

                    return { ...state, loading: false, is_logout: false, poll_list: req_list, active_poll_list: activePollList, cache, last_poll_start_date: last_start_date, can_load_more: loadMore }

                } else {
                    return { ...state, loading: false, is_logout: false, error: poll_list, can_load_more: false };
                }

            } else {
                return { ...state, loading: false, is_logout: false };
            }

        case SOCIETY_POLL_LIST_ERROR:
            let hNE_res = handleNetworkError(action);
            if (hNE_res.error)
                return { ...INITIAL_STATE, ...hNE_res, loading: false, is_logout: false };
            else if (action.payload && action.payload.response && action.payload.response.data && action.payload.response.data.error) {
                if (action.payload.response.data.error.statusCode === 401)
                    return { ...INITIAL_STATE, error: action.payload.response.data.error, loading: false, is_logout: true };
                else
                    return { ...INITIAL_STATE, error: action.payload.response.data.error, loading: false, is_logout: false };
            }
            else
                return { ...INITIAL_STATE, error: action.payload, loading: false, is_logout: false };

        case SOCIETY_POLL_LIST_SCROLL:
            return { ...state, scroll_offset: action.payload.scroll_offset };

        case LOGOUT_RESET:
            return { ...INITIAL_STATE };

        default: return state;
    }
}