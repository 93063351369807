export const parseOrderItem = item => {
    let id = 0;
    let item_desc = "";
    let unit_price = 0;
    let quantity = 0;
    let sub_total = 0;
    let savings = 0;

    if (item.psku_id) {
        id = item.psku_id;
    }

    if (item && item.psku_disp_details) {
        const details = item.psku_disp_details;

        // if (details.Brand) {
        //     item_desc += `${details.Brand} - `;
        // }

        // if (details.product_title) {
        //     item_desc += `${details.product_title} - `;
        // }

        if (details.product_title) {
            item_desc += `${details.product_title}`;
        }

        if (details.psize && details.Unit) {
            item_desc += ` - (${details.psize} ${details.Unit})`;
        }

    }

    if (item.price) {
        unit_price = item.discounted_price;
    }

    if (item.quantity) {
        quantity = item.quantity;

        sub_total = quantity * unit_price;

        if (item.discount) {
            savings = quantity * item.discount;
        }
    }

    return {
        id,
        item_desc,
        unit_price,
        quantity,
        sub_total,
        savings
    }
}

export const parseOrderSummary = order => {
    let post_discount_total = 0;
    let delivery_charges = 0;
    let cgst = 0;
    let sgst = 0;
    let order_total = 0;
    let total_savings = 0;
    let conv_pct = 0;
    let conv_charge = 0;
    let delivery_tip;
    let reward_discount = 0;

    if (order && order.order_detail) {
        if (order.order_detail.price_info) {
            const info = order.order_detail.price_info;
            order_total = parseFloat(order.order_total);
            post_discount_total = parseFloat(info.postdiscount_total);
            delivery_charges = parseFloat(info.delivery_charges);
            total_savings = parseFloat(info.discount_total);
            conv_pct = info.ConvenienceFeesPct ? parseFloat(info.ConvenienceFeesPct) * 100 : 0;
            conv_charge = info.ConvenienceFees ? parseFloat(info.ConvenienceFees) : 0;
            delivery_tip = parseFloat(info.deliveryTip);
            reward_discount = info.reward_discount ? parseFloat(info.reward_discount) : 0;
        }

        if (order.order_detail.tax_info) {
            const info = order.order_detail.tax_info;
            cgst = info.ftax_total ? parseFloat(info.ftax_total) : 0;
            sgst = info.stax_total ? parseFloat(info.stax_total) : 0;
        }

    }

    return {
        post_discount_total,
        delivery_charges,
        cgst,
        sgst,
        order_total,
        total_savings,
        conv_pct,
        conv_charge,
        delivery_tip,
        reward_discount
    }
}