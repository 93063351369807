import { REHYDRATE } from 'redux-persist';
import { GET_DELIVERY_DATA, GET_DELIVERY_DATA_ERROR, GET_DELIVERY_DATA_SUCCESS } from '../../../actions/types';
import ApiError, { parseError } from '../../../utils/api-error';

const INITIAL_STATE = {
    loading: true,
    error: undefined,
    delivery_data: undefined,
    cache: undefined,
};

const FoodDeliveryData = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case REHYDRATE:
            return action.payload && action.payload.food_delivery_data
                ? { ...action.payload.food_delivery_data }
                : INITIAL_STATE;

        case GET_DELIVERY_DATA:
            return { ...state, loading: true, error: undefined };

        case GET_DELIVERY_DATA_SUCCESS: {
            const apiData = action.payload;
            const delv_info = ApiError(apiData.statusCode, apiData.message, apiData.gsRespData, "delv_info", {});

            if (delv_info && delv_info.error) {
                return { error: delv_info.error, loading: false, cache: undefined, delivery_data: undefined };
            } else {
                return { error: undefined, loading: false, cache: action.cache, delivery_data: delv_info };
            }
        }

        case GET_DELIVERY_DATA_ERROR:
            return { ...parseError(action) };

        default:
            return state;
    }
}

export default FoodDeliveryData;