import React from 'react';
import { Link } from 'react-router-dom';

import { tags } from 'tags';
import { strings } from 'strings';
import CompanyLogo from 'assets/Logo.svg';
import OGCareIcon from 'assets/ogcare.png';
import OGCartIcon from 'assets/ogcart.png';
import DNhoodLogo from 'assets/dnhood-logo.png';
import { isDNhoodEnv } from 'utils/helper-functions';
import styles from './styles';

class SideNavHeader extends React.PureComponent {
    render() {
        const enabled = this.props.enabled
        const outerStyle =
            enabled
                ? styles.HeaderOuterDiv
                : Object.assign({}, styles.HeaderOuterDiv, styles.disabledStyle)

        const is_dnhood_env = isDNhoodEnv();
        let logo = CompanyLogo, appName = tags.appName(), subHeading = strings.tagLine(), tagLine = "Stay Secure * Stay Managed * Stay Connected";

        if (is_dnhood_env) {
            logo = DNhoodLogo;
            tagLine = null;
        }
        // else if (this.props.is_ogcart) {
        //     logo = OGCartIcon;
        //     appName = "OGCART";
        //     subHeading = null;
        //     tagLine = strings.ogCartTagLine;
        // }
        // else if (this.props.is_ogcare) {
        //     logo = OGCareIcon;
        //     appName = "OGCARE";
        //     subHeading = null;
        //     tagLine = strings.ogCareTagLine;
        // }

        return (
            <Link
                action="replace"
                to={`/${this.props.headerHomeRoute}`}
                style={{ textDecoration: 'none' }}
                onClick={(e) => enabled ? {} : e.preventDefault()}>
                <div>
                    <div style={outerStyle}>
                        <img className="headerIconTab" src={logo} style={styles.HeaderImage} />
                        <div className="hideTabView" style={styles.HeaderText}>
                            {appName}
                        </div>
                    </div>
                    {
                        subHeading &&
                        <div className="hideTabView" style={styles.TagLineText}>
                            {subHeading}
                        </div>
                    }
                    {
                        tagLine &&
                        <div className="hideTabView" style={styles.TagLineText}>{tagLine}</div>
                    }
                </div>
            </Link>
        )
    }
}

export default SideNavHeader;