import { REHYDRATE } from 'redux-persist';
import { USER_PROFILE_DETAILS, USER_PROFILE_DETAILS_UPDATE, USER_PROFILE_DETAILS_SUCCESS, USER_PROFILE_DETAILS_ERROR, LOGOUT_RESET } from '../../actions/types';
import ApiError, { handleNetworkError } from '../../utils/api-error';

const INITIAL_STATE = {
    user_profile_details: {},
    error: undefined,
    loading: true,
    is_logout: false
};

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case REHYDRATE: if (action.payload && action.payload.user_profile_details) return { ...action.payload.user_profile_details }; else return INITIAL_STATE;
        case USER_PROFILE_DETAILS: return { ...INITIAL_STATE, loading: true, is_logout: false };
        case USER_PROFILE_DETAILS_UPDATE: return { ...state, user_profile_details: { ...state.user_profile_details, ...action.payload } };
        case USER_PROFILE_DETAILS_SUCCESS:
            if (action.payload !== undefined && action.payload !== null) {
                let data = action.payload;
                let resp = ApiError(data.statusCode, data.message, data.gsRespData, null, {});
                return { ...INITIAL_STATE, user_profile_details: resp, loading: false, is_logout: false };
            }
            else
                return { ...state, loading: false, is_logout: false };
        case USER_PROFILE_DETAILS_ERROR:
            let hNE_res = handleNetworkError(action);
            if (hNE_res.error)
                return { ...INITIAL_STATE, ...hNE_res, loading: false, is_logout: false };
            else if (action.payload && action.payload.response && action.payload.response.data && action.payload.response.data.error) {
                if (action.payload.response.data.error.statusCode === 401)
                    return { ...INITIAL_STATE, error: action.payload.response.data.error, loading: false, is_logout: true };
                else
                    return { ...INITIAL_STATE, error: action.payload.response.data.error, loading: false, is_logout: false };
            }
            else
                return { ...INITIAL_STATE, error: action.payload, loading: false, is_logout: false };
        case LOGOUT_RESET: return { ...INITIAL_STATE };
        default: return state;
    }
}