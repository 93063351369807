// import ImageCompressor from 'compressorjs';

import { APP_LINK } from 'config/config';

let isWebpSupported = null;

function canUseWebP() {
    var elem = document.createElement('canvas');

    if (!!(elem.getContext && elem.getContext('2d'))) {
        // was able or not to get WebP representation
        return elem.toDataURL('image/webp').indexOf('data:image/webp') == 0;
    }

    // very old browser like IE 8, canvas not supported
    return false;
}

if (isWebpSupported === null) {
    isWebpSupported = canUseWebP();
}

export function downloadExternalMedia(link) {
    if (!link || link.constructor !== String)
        return null;

    if (!(link.indexOf(APP_LINK) > -1 || link.indexOf('https://') > -1))
        return link;

    let split = link.split('.');
    let extension = (isWebpSupported && 'webp') || 'jpg';

    if (split.length > 1) {
        split[split.length - 1] = extension;
    }

    return split.join('.');
}

export function downloadMedia(link, options) {
    // File to be downloaded by img or other html tags.
    // Do not download here and send from here.
    // It will cause an extra effort to resolve promise or handle callback.

    options = { output_format: (isWebpSupported && 'webp') || 'jpg', ...options };

    let url = link;
    var fileExtension = url.split(".").pop();
    if (!['jpg', 'png', 'jpeg', 'gif', 'svg'].includes(fileExtension)) {
        delete options.output_format;
    }

    if (!link || link.constructor !== String)
        return null;

    if (link.indexOf(APP_LINK) > -1 || link.indexOf('https://') > -1) { }
    else {
        let options_params = '';

        if (options && options.constructor === Object) {
            Object.keys(options).forEach(key => {
                if (options_params)
                    options_params += '&';

                options_params += key + '=' + options[key];
            });
        }

        url = `${APP_LINK}/s=t/downloadMedia/${link}`;

        if (options_params)
            url += '?' + options_params;
    }

    return url;
}

export function compressImage(image, options) {
    /*
    if (!options) {
        options = {
            quality: 0.5,
            convertSize: 2000000
        }
        if (image.size > 2200000 && image.size <= 5200000) {
            options.quality = 0.5;
        }
        if (image.size > 5200000 && image.size <= 7200000) {
            options.quality = 0.4;
        }
        if (image.size > 7200000) {
            options.quality = 0.2;
        }
    }
    */

    if (!options || options.quality !== 1) {
        // Updated options with static options
        options = {
            quality: 0.8,
            maxWidth: 1280,
            maxHeight: 1280,
            minWidth: 200,
            minHeight: 200
        };
    }

    return new Promise((resolve, reject) => {
        // Dynamically load package
        import(/* webpackChunkName: "compressorjs" */ 'compressorjs')
            .then(ImageCompressorObj => {
                const ImageCompressor = ImageCompressorObj.default;

                new ImageCompressor(image, {
                    ...options,
                    success(result) { resolve(result) },
                    error(err) { reject(err) }
                });
            })
            .catch(err => reject(err))
    });
}