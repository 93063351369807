import { REHYDRATE } from 'redux-persist'

import ApiError, { handleNetworkError } from '../../utils/api-error'
import {
    SOCIETY_COMPLAINTS_LIST, SOCIETY_COMPLAINTS_LIST_ERROR, SOCIETY_COMPLAINTS_LIST_SUCCESS,
    LOGOUT_RESET, SOCIETY_COMPLAINTS_LIST_MORE, SOCIETY_COMPLAINTS_LIST_SCROLL
} from '../../actions/types';

const INITIAL_STATE = {
    complaint_list: [],
    acl_list: [],
    cache: null,
    last_complaint_id: undefined,
    can_load_more: false,
    scroll_offset: 0,
    error: undefined,
    loading: true,
    is_logout: false
}

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case REHYDRATE: if (action.payload && action.payload.society_complaint_list) return { ...action.payload.society_complaint_list }; else return INITIAL_STATE;

        case SOCIETY_COMPLAINTS_LIST: return { ...INITIAL_STATE, loading: true };
        case SOCIETY_COMPLAINTS_LIST_MORE: return { ...state, loading: true, is_logout: false };

        case SOCIETY_COMPLAINTS_LIST_SUCCESS:
            const apiData = action.payload && action.payload.apiData;
            const cache = action.payload && action.payload.cache;
            const batchSize = action.payload && action.payload.batchSize;

            if (apiData !== undefined && apiData !== null) {
                let complaint_list = ApiError(apiData.statusCode, apiData.message, apiData.gsRespData, "complaint_list", []);
                let acl_list = ApiError(apiData.statusCode, apiData.message, apiData.gsRespData, "acl_list", []);

                if (complaint_list && complaint_list.constructor === Array) {
                    const state_list = state.complaint_list && state.complaint_list.constructor === Array ? state.complaint_list : [];
                    const req_list = [...state_list, ...complaint_list];

                    let last_id = undefined;
                    if (req_list.length > 0) {
                        last_id = req_list[req_list.length - 1].id;
                    }

                    let loadMore = false;
                    if (complaint_list.length === batchSize) {
                        loadMore = true;
                    }

                    let aclList = acl_list && acl_list.length > 0 ? acl_list : state.acl_list;

                    return { ...state, loading: false, is_logout: false, complaint_list: req_list, acl_list: aclList, cache, last_complaint_id: last_id, can_load_more: loadMore }

                } else {
                    return { ...state, loading: false, is_logout: false, error: complaint_list, can_load_more: false };
                }

            } else {
                return { ...state, loading: false, is_logout: false };
            }

        case SOCIETY_COMPLAINTS_LIST_ERROR:
            let hNE_res = handleNetworkError(action);
            if (hNE_res.error)
                return { ...INITIAL_STATE, ...hNE_res, loading: false, is_logout: false };
            else if (action.payload && action.payload.response && action.payload.response.data && action.payload.response.data.error) {
                if (action.payload.response.data.error.statusCode === 401)
                    return { ...INITIAL_STATE, error: action.payload.response.data.error, loading: false, is_logout: true };
                else
                    return { ...INITIAL_STATE, error: action.payload.response.data.error, loading: false, is_logout: false };
            }
            else
                return { ...INITIAL_STATE, error: action.payload, loading: false, is_logout: false };

        case SOCIETY_COMPLAINTS_LIST_SCROLL:
            return { ...state, scroll_offset: action.payload.scroll_offset };

        case LOGOUT_RESET:
            return { ...INITIAL_STATE };

        default: return state;
    }
}