import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import axios from 'middleware/axios';

import {
    cl_user_id, sp_user_id, sp_signedIn, cl_signedIn, cl_selected_society_property, is_home_screen_flow
} from 'config/storage-variables';
import { API_LINK } from 'config/config';
import { fingerPrintJSComponents } from './utils/fingerprintjs2-config';

import './bootstrap/css/bootstrap.min.css';
// import './font-awesome/css/font-awesome.min.css';
import './css/normalize.min.css';
import './styles.css';

// import registerServiceWorker from './registerServiceWorker';        //Register Service Worker for Offline Caching
// import firebaseNotification from './utils/fire-notification';
import handleConnectionChange from './utils/handleNetworkChange';
import handleDialog from './utils/PermissionDialog';
import deviceId from './utils/device-id';
import { sendSSERequestToServer } from './utils/sse';
import { getDefaultCity, isDNhoodEnv } from 'utils/helper-functions';

import { GRS_ENV_CLIENT, GRS_ENV_SP } from 'config/config';

import ErrorComponent from 'components/error-components';
import { logoutResetReducer, loginTriggerAction, societyAvailableTriggerAction, societyNotAvailableTriggerAction } from 'actions/home-action';
import { booking_flow_object } from 'scenes/client/scenes/booking/config';

import {
    // Authentication
    changePasswordRoute,
    changePhoneRoute,
    changeEmailRoute,
    otpRoute,
    otpReceivedRoute,
    signinRoute,
    signupRoute,
    signupClientRoute,
    signupSpRoute,
    forgotPasswordRoute,
    linkExpiredRoute,
    forgotOrRegisterRoute,
    accountSuspendedRoute,
    accountSettingsRoute,
    languageRoute,
    thirdPartyPhoneRoute,
    emailVerifiedRoute,

    // Common
    aboutUsRoute,
    contactUsRoute,
    aboutgRuhapsRoute,
    openSourceLicencesRoute,
    userFeedbackRoute,
    profileRoute,
    editProfileRoute,
    propertyAddressRoute,
    displayAddressRoute,
    notificationsRoute,
    fixedMarkerRoute,
    // advertRoute,
    referralCodeRoute,
    rewardsRoute,
    helpRoute,
    exploreRoute,
    ogcartRoute,
    ogcareRoute,
    exploreAppsRoute,
	janSevaRoute,
	emergencyContactsRoute,    
	userEmergencyContactsRoute,
    userEmergencyAlertNotificationRoute,   

    // Post Booking
    bookingRoute,
    chatRoute,
    reviewRoute,
    writeReviewRoute,
    submittedReviewRoute,
    spInfoRoute,
    clientInfoRoute,
    bookingDetailsRoute,
    cancelRoute,
    rescheduleBookingRoute,
    quotationRoute,
    quotationDetailsRoute,
    invoiceRoute,

    // Pre Booking
    // spListMapRoute,
    getItemsRoute,
    getQuestionnaireRoute,
    spListRoute,
    serviceProviderRoute,
    bookTimeslotRoute,
    selectBookingTypeRoute,
    selectLocationRoute,
    questionListRoute,
    cartRoute,
    questionListCartRoute,
    selectServiceRoute,
    searchServiceRoute,
    spPageparentRoute,
    spDetailsPageRoute,
    selectSPServiceRoute,
    selectSPServiceGroupsRoute,
    selectSPTimeslotRoute,
    jskSpListRoute,

    // Quote Invoice
    editQuoteRoute,
    editInvoiceRoute,

    // SP Home
    premiumFeaturesRoute,
    setPublicUrlRoute,
    spPremiumDetailsRoute,
    spPlansRoute,
    spHomeRoute,
    spNearRoute,
    spBookingRoute,
    timeslotRoute,

    // SP Profile
    spRewardsRoute,
    broadcastHistoryRoute,
    spRegistrationRoute,
    spProfileRoute,
    editSPProfileRoute,
    spPortfolioImagesRoute,
    manageServiceLocationRoute,
    manageServicesRoute,
    mySPReviewRoute,
    addServicesRoute,
    setServiceRangeRoute,
    newServiceRequestRoute,
    paymentHistoryRoute,
    teamRoute,
    manageDiscountRoute,

    // SP Schedule
    scheduleRoute,
    newScheduleRoute,
    editScheduleRoute,
    schDetailsRoute,

    // Extra
    notFoundRoute,
    imageZoomRoute,

    // CL Society
    societyRoute,
    propertyVisitorRoute,
    selectSocietyRoute,
    propertyDetailsRoute,
    propertyTransactionsRoute,
    propertyTransactionsMakePaymentRoute,
    propertyTransactionsStatusRoute,
    societyNotificationRoute,
    societyNotificationRedirectRoute,
    entryPassRoute,
    gateApprovalsRoute,
    boardMemberViewRoute,
    societyMsgBoardRoute,
    boardSocietyMsgBoardRoute,
    intercomRoute,
    societyFeedRoute,
    complaintsRoute,
    complaintHelpDeskRoute,
    societyMsgDetailsRoute,
    societyDepartmentsRoute,
    pollsRoute,
    myPollsRoute,
    cbillRoute,
    cinvoiceRoute,
    propertyTransactionDetailsRoute,
    emergencyRoute,
    vaccineAvailabilityRoute,
    propertyVehiclesRoute,
    propertyParcelRoute,
    propertySettingsRoute,

    //CL Society Board
    societyStaffRoute,
    boardMembersRoute,
    societyPropertiesRoute,
    societyTransactionsRoute,
    pettycashTransactionsRoute,
    mySocietyRoute,
    gatekeepersInfoRoute,
    patrolTrackerRoute,
    manageCheckpointsRoute,
    emeetingRoute,
    emeetingsRoute,
    emeetingsHistoryRoute,
    exportDataRoute,
    residentRequestsRoute,
    societyDetailsRoute,
    societyTransactionDetailsRoute,
    societyBillsInvoicesRoute,
    societySettlementsRoute,
    societySettlementDetailsRoute,
    emergencyAlertNotificationRoute,

    //Product
    productsRoute,
    productListingRoute,
    productDetailsRoute,
    productCartRoute,
    productCheckoutRoute,
    productOrderPaymentRoute,
    productOrderPaymentPaypalRoute,
    ordersRoute,
    productOrderDetailsRoute,
    productFeedbackRoute,

    //Food
    ogfoodRoute,
    ogfoodCartRoute,
    ogfoodOrdersRoute,
    ogfoodOrderDetailsRoute,
} from './routes-parameters/config';

import MyLoadable from './dynamic-component-load';

import HomeImport from './imports/home';

import NotFound from './scenes/not-found';
// import SpBroadcastRequest from './scenes/sp/scenes/broadcast';
// import ClBroadcastRequest from './scenes/client/scenes/broadcast';

import storage from './middleware/storage';
import LeftPanel from './scenes/desktop/left-panel';
import RightPanel from './scenes/desktop/right-panel';

let SPProfileImport = null, QuoteInvoiceImport = null, SPScheduleImport = null, SPHomeImport = null;

if (!process.env.REACT_APP_GRS_ENV || process.env.REACT_APP_GRS_ENV === GRS_ENV_SP) {
    SPProfileImport = MyLoadable({
        loader: () => import(/* webpackChunkName: "SPProfileImport" */ './imports/sp-profile'),
    });
    QuoteInvoiceImport = MyLoadable({
        loader: () => import(/* webpackChunkName: "QuoteInvoiceImport" */ './imports/quote-invoice'),
    });
    SPScheduleImport = MyLoadable({
        loader: () => import(/* webpackChunkName: "SPScheduleImport" */ './imports/sp-schedule'),
    });
    SPHomeImport = MyLoadable({
        loader: () => import(/* webpackChunkName: "SPHomeImport" */ './imports/sp-home'),
    });
}

let PreBookingImport = null, ClSocietyImport = null, ClSocietyBoardImport = null, ProductImport = null, FoodImport = null;

if (!process.env.REACT_APP_GRS_ENV || process.env.REACT_APP_GRS_ENV === GRS_ENV_CLIENT) {
    PreBookingImport = MyLoadable({
        loader: () => import(/* webpackChunkName: "PreBookingImport" */ './imports/pre-booking'),
    });
    ClSocietyImport = MyLoadable({
        loader: () => import(/* webpackChunkName: "ClSocietyImport" */ './imports/cl-society'),
    });
    ClSocietyBoardImport = MyLoadable({
        loader: () => import(/* webpackChunkName: "ClSocietyBoardImport" */ './imports/cl-society-board'),
    });
    ProductImport = MyLoadable({
        loader: () => import(/* webpackChunkName: "ProductImport" */ './imports/product'),
    });
    FoodImport = MyLoadable({
        loader: () => import(/* webpackChunkName: "FoodImport" */ './imports/food'),
    });
}

let PostBookingImport = null, AuthenticationImport = null, CommonImport = null;

PostBookingImport = MyLoadable({
    loader: () => import(/* webpackChunkName: "PostBookingImport" */ './imports/post-booking'),
});
AuthenticationImport = MyLoadable({
    loader: () => import(/* webpackChunkName: "AuthenticationImport" */ './imports/authentication'),
});
CommonImport = MyLoadable({
    loader: () => import(/* webpackChunkName: "CommonImport" */ './imports/common'),
});

let ClBroadcastRequestImport = null, SpBroadcastRequestImport = null;
if (!process.env.REACT_APP_GRS_ENV || process.env.REACT_APP_GRS_ENV === GRS_ENV_CLIENT) {
    ClBroadcastRequestImport = MyLoadable({
        loader: () => import(/* webpackChunkName: "ClBroadcastRequest" */ './scenes/client/scenes/broadcast'),
        loading: () => null
    });
}
if (!process.env.REACT_APP_GRS_ENV || process.env.REACT_APP_GRS_ENV === GRS_ENV_SP) {
    SpBroadcastRequestImport = MyLoadable({
        loader: () => import(/* webpackChunkName: "SpBroadcastRequest" */ './scenes/sp/scenes/broadcast'),
        loading: () => null
    });
}

let SocietyGateApprovalsImport = null;
if (!process.env.REACT_APP_GRS_ENV || process.env.REACT_APP_GRS_ENV === GRS_ENV_CLIENT) {
    SocietyGateApprovalsImport = MyLoadable({
        loader: () => import(/* webpackChunkName: "SocietyGateApprovals" */ './scenes/client/scenes/society/scenes/gate-approvals/scenes/blocking-gate-approvals'),
        loading: () => null
    });
}

let EMeetingApprovalsImport = null;
if (!process.env.REACT_APP_GRS_ENV || process.env.REACT_APP_GRS_ENV === GRS_ENV_CLIENT) {
    EMeetingApprovalsImport = MyLoadable({
        loader: () => import(/* webpackChunkName: "EMeetingApprovals" */ './scenes/emeeting/components/blocking-call-screen'),
        loading: () => null
    });
}

let MiscellaneousComponentsImport = null;
if (!process.env.REACT_APP_GRS_ENV || process.env.REACT_APP_GRS_ENV === GRS_ENV_CLIENT) {
    MiscellaneousComponentsImport = MyLoadable({
        loader: () => import(/* webpackChunkName: "MiscellaneousComponents" */ './scenes/miscellaneous-components'),
        loading: () => null
    });
}

class App extends React.Component {
    constructor(props) {
        super(props);

        const defaultCity = getDefaultCity();

        this.state = {
            show_error_component: false,
            is_premium_sp: false,
            // update_language: false
        };
    }

    componentDidMount() {
        handleConnectionChange();
        handleDialog();
        deviceId();
        sendSSERequestToServer();
        console.log('%c Congratulations!! You have unlocked new offers on your account.', 'color: #4DB6AC; font-size: 24px;')
        console.log('%c Sorry!! You cannot use new offers on your account because your account will be suspended soon.', 'color: #e57373; font-size: 24px;')
    }

    onRehydrateLoaded() {
        // Check if user signed in
        if (storage.getLocal(cl_signedIn) === 'true' || storage.getLocal(sp_signedIn) === 'true') {
            loginTriggerAction();
        }
        else {
            logoutResetReducer();
        }

        // Check if society available
        if (storage.getLocal(cl_selected_society_property)) {
            societyAvailableTriggerAction();
        }
        else {
            societyNotAvailableTriggerAction();
        }
    }

    componentDidCatch(error, info) {
        // //console.log("componentDidCatch : ", error, info);
        if (process.env.NODE_ENV === 'production') {
            let device_info = {}, excp_detail = {}, user_id = null;
            if (storage.getLocal(cl_user_id))
                user_id = storage.getLocal(cl_user_id);
            else if (storage.getLocal(sp_user_id))
                user_id = storage.getLocal(sp_user_id);

            excp_detail.screen = info;
            excp_detail.error = error;

            setTimeout(() => {
                import(/* webpackChunkName: "fingerprintjs2" */ 'fingerprintjs2').then(Fingerprint2Obj => {
                    const Fingerprint2 = Fingerprint2Obj.default;
                    new Fingerprint2().get(function (result, components) {
                        device_info.deviceId = result;
                        let filter_components = [];
                        components.forEach(component => {
                            if (fingerPrintJSComponents.includes(component.key))
                                filter_components.push(component);
                        });
                        device_info.components = filter_components;

                        axios.post(`${API_LINK}/gs_Excp_Repository`, {
                            user_id, excp_detail, device_info, type: 'web'
                        })
                            .then(res => { })
                            .catch(err => { });
                    })
                });
            }, 100);
        }

        this.setState({ show_error_component: true });
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (
            this.props.location !== nextProps.location ||
            this.props.rehydrate_loading !== nextProps.rehydrate_loading ||
            (this.props.history && this.props.history.location && this.props.history.location.pathname) !== (nextProps.history && nextProps.history.location && nextProps.history.location.pathname) ||
            this.props.booking_flow_data[booking_flow_object.is_premium_sp] !== nextProps.booking_flow_data[booking_flow_object.is_premium_sp] ||
            this.state.is_premium_sp !== nextState.is_premium_sp ||
            this.state.show_error_component !== nextState.show_error_component
        ) {
            return true;
        }
        return false;
    }

    render() {
        if (!this.props.rehydrate_loading) {
            return (
                <div>loading ...</div>
            );
        }
        else if (this.state.show_error_component) {
            return (
                <ErrorComponent history={this.props.history} />
            );
        }
        else {
            let is_plans_screen_opened = false;
            if (this.props.history && this.props.history.location && this.props.history.location.pathname === `/${spPlansRoute}`) {
                is_plans_screen_opened = true;
            }

            return (
                <div>
                    <Switch>
                        {
                            (!process.env.REACT_APP_GRS_ENV || process.env.REACT_APP_GRS_ENV === GRS_ENV_SP) &&
                            <Route path={[
                                `/${spRewardsRoute}`,
                                `/${broadcastHistoryRoute}`,
                                `/${spRegistrationRoute}`,
                                `/${spProfileRoute}`,
                                `/${editSPProfileRoute}`,
                                `/${spPortfolioImagesRoute}`,
                                `/${manageServiceLocationRoute}`,
                                `/${manageServicesRoute}`,
                                `/${mySPReviewRoute}`,
                                `/${addServicesRoute}`,
                                `/${setServiceRangeRoute}`,
                                `/${newServiceRequestRoute}`,
                                `/${paymentHistoryRoute}`,
                                `/${teamRoute}`,
                                `/${manageDiscountRoute}`
                            ]}
                                component={SPProfileImport} />
                        }

                        {
                            (!process.env.REACT_APP_GRS_ENV || process.env.REACT_APP_GRS_ENV === GRS_ENV_SP) &&
                            <Route path={[
                                `/:bkg_id/${editQuoteRoute}`,
                                `/:bkg_id/${editInvoiceRoute}/:invoice_id`,
                                `/:bkg_id/${editInvoiceRoute}`,
                            ]}
                                component={QuoteInvoiceImport} />
                        }

                        {
                            (!process.env.REACT_APP_GRS_ENV || process.env.REACT_APP_GRS_ENV === GRS_ENV_SP) &&
                            <Route path={[
                                `/${scheduleRoute}`,
                                `/${newScheduleRoute}`,
                                `/${editScheduleRoute}`,
                                `/${schDetailsRoute}`,
                            ]}
                                component={SPScheduleImport} />
                        }

                        {
                            (!process.env.REACT_APP_GRS_ENV || process.env.REACT_APP_GRS_ENV === GRS_ENV_SP) &&
                            <Route path={[
                                `/${premiumFeaturesRoute}`,
                                `/${setPublicUrlRoute}`,
                                `/${spPremiumDetailsRoute}`,
                                `/${spPlansRoute}`,
                                `/${spHomeRoute}`,
                                `/${spNearRoute}`,
                                `/${spBookingRoute}`,
                                `/${timeslotRoute}`
                            ]}
                                component={SPHomeImport} />
                        }

                        {
                            (!process.env.REACT_APP_GRS_ENV || process.env.REACT_APP_GRS_ENV === GRS_ENV_CLIENT) &&
                            <Route path={[
                                // `/:svc_name/${spListMapRoute}`,
                                `/:svc_name/${getItemsRoute}`,
                                `/:svc_name/${getQuestionnaireRoute}`,
                                `/:svc_name/${spListRoute}`,
                                `/:svc_name/${serviceProviderRoute}`,
                                `/:svc_name/${bookTimeslotRoute}`,
                                `/${selectBookingTypeRoute}`,
                                `/${selectLocationRoute}`,
                                `/:svc_name/${questionListRoute}`,
                                `/:svc_name/${cartRoute}`,
                                `/:svc_name/${questionListCartRoute}`,
                                `/:cat_name/${searchServiceRoute}`,
                                `/${searchServiceRoute}`,
                                `/${selectServiceRoute}`,
                                `/${spPageparentRoute}/${spDetailsPageRoute}`,
                                `/${spPageparentRoute}/:sp_city/:sp_public_url`,
                                `/${spPageparentRoute}/:sp_public_url`,
                                `/${spDetailsPageRoute}`,
                                `/${selectSPServiceRoute}`,
                                `/${selectSPServiceGroupsRoute}`,
                                `/${selectSPTimeslotRoute}`,
                                `/${reviewRoute}`,
								`/:svc_name/${jskSpListRoute}`,                            
							]}
                                component={PreBookingImport} />
                        }

                        {
                            (!process.env.REACT_APP_GRS_ENV || process.env.REACT_APP_GRS_ENV === GRS_ENV_CLIENT) &&
                            <Route path={[
                                `/${societyRoute}`,
                                `/${propertyVisitorRoute}`,
                                `/${selectSocietyRoute}`,
                                `/${propertyDetailsRoute}`,
                                `/${propertyTransactionsRoute}`,
                                `/${propertyTransactionsMakePaymentRoute}`,
                                `/${propertyTransactionsStatusRoute}`,
                                `/${societyNotificationRoute}`,
                                `/${societyNotificationRedirectRoute}`,
                                `/${entryPassRoute}`,
                                `/${gateApprovalsRoute}`,
                                `/${boardMemberViewRoute}`,
                                `/${societyMsgBoardRoute}/:post_types`,
                                `/${societyMsgBoardRoute}`,
                                `/${intercomRoute}`,
                                `/${societyFeedRoute}/:post_types`,
                                `/${societyFeedRoute}`,
                                `/${complaintsRoute}/:complaint_id`,
                                `/${complaintsRoute}`,
                                `/${societyMsgDetailsRoute}/:msgb_id`,
                                `/${societyDepartmentsRoute}`,
                                `/${pollsRoute}`,
                                `/${myPollsRoute}`,
                                `/${cbillRoute}/:bill_key`,
                                `/${cinvoiceRoute}/:bill_key`,
                                `/${propertyTransactionDetailsRoute}/:txn_id/:txn_type`,
                                `/${propertyTransactionDetailsRoute}/:txn_id`,
                                `/${emergencyRoute}`,
                                `/${vaccineAvailabilityRoute}`,
                                `/${propertyVehiclesRoute}`,
                                `/${propertyParcelRoute}`,
                                `/${propertySettingsRoute}`
                            ]}
                                component={ClSocietyImport} />
                        }

                        {
                            (!process.env.REACT_APP_GRS_ENV || process.env.REACT_APP_GRS_ENV === GRS_ENV_CLIENT) &&
                            <Route path={[
                                `/${societyStaffRoute}`,
                                `/${boardMembersRoute}`,
                                `/${societyPropertiesRoute}`,
                                `/${societyTransactionsRoute}/:status`,
                                `/${societyTransactionsRoute}`,
                                `/${pettycashTransactionsRoute}/:status`,
                                `/${pettycashTransactionsRoute}`,
                                `/${mySocietyRoute}`,
                                `/${gatekeepersInfoRoute}`,
                                `/${patrolTrackerRoute}`,
                                `/${manageCheckpointsRoute}`,
                                `/${emeetingsRoute}/:meeting_id`,
                                `/${emeetingsRoute}`,
                                `/${emeetingRoute}`,
                                `/${emeetingsHistoryRoute}`,
                                `/${exportDataRoute}`,
                                `/${residentRequestsRoute}`,
                                `/${boardSocietyMsgBoardRoute}/:post_types`,
                                `/${boardSocietyMsgBoardRoute}`,
                                `/${complaintHelpDeskRoute}/:complaint_id`,
                                `/${complaintHelpDeskRoute}`,
                                `/${societyDetailsRoute}`,
                                `/${societyTransactionDetailsRoute}/:txn_id/:txn_type`,
                                `/${societyTransactionDetailsRoute}/:txn_id`,
                                `/${societyBillsInvoicesRoute}`,
                                `/${societySettlementsRoute}`,
                                `/${societySettlementDetailsRoute}/:settlement_id`,
                                `/${emergencyAlertNotificationRoute}`,
                            ]}
                                component={ClSocietyBoardImport} />
                        }

                        {
                            (!process.env.REACT_APP_GRS_ENV || process.env.REACT_APP_GRS_ENV === GRS_ENV_CLIENT) &&
                            <Route path={[
                                `/${productsRoute}`,
                                `/:city_name/:pcat_name/:pscat_name/${productListingRoute}`,
                                `/:city_name/:pcat_name/:pscat_name/:product_id/${productDetailsRoute}`,
                                `/${productCartRoute}`,
                                `/${productCheckoutRoute}`,
                                `/${productOrderPaymentRoute}`,
                                `/${productOrderPaymentPaypalRoute}`,
                                `/${ordersRoute}`,
                                `/:order_id/${productOrderDetailsRoute}`,
                                `/:order_id/${productFeedbackRoute}`
                            ]}
                                render={props => <ProductImport {...props} />}
                            />
                        }

                        {
                            (!process.env.REACT_APP_GRS_ENV || process.env.REACT_APP_GRS_ENV === GRS_ENV_CLIENT) &&
                            <Route path={[
                                `/${ogfoodRoute}/:page_url`,
                                `/${ogfoodRoute}`,
                                `/${ogfoodCartRoute}`,
                                `/${ogfoodOrdersRoute}`,
                                `/${ogfoodOrderDetailsRoute}`,
                            ]}
                                render={props => <FoodImport {...props} />}
                            />
                        }

                        <Route path={[
                            `/${bookingRoute}`,
                            `/:bkg_id/${chatRoute}`,
                            `/${reviewRoute}`,
                            `/${writeReviewRoute}`,
                            `/${submittedReviewRoute}`,
                            `/${spInfoRoute}`,
                            `/${clientInfoRoute}`,
                            `/:bkg_id/${bookingDetailsRoute}`,
                            `/${cancelRoute}`,
                            `/${rescheduleBookingRoute}`,
                            `/${quotationRoute}`,
                            `/:bkg_id/${quotationDetailsRoute}/:quote_id`,
                            `/:bkg_id/${quotationDetailsRoute}`,
                            `/:bkg_id/${invoiceRoute}/:invoice_id`
                        ]}
                            component={PostBookingImport} />

                        <Route path={[
                            `/${changePasswordRoute}`,
                            `/${changePhoneRoute}/:realm`,
                            `/${changeEmailRoute}`,
                            `/${otpRoute}`,
                            `/${otpReceivedRoute}/:phone/:realm`,
                            `/${signinRoute}`,
                            `/${signupRoute}`,
                            `/${signupClientRoute}`,
                            `/${signupSpRoute}`,
                            `/${forgotPasswordRoute}/:realm`,
                            `/${linkExpiredRoute}`,
                            `/${forgotOrRegisterRoute}`,
                            `/${accountSuspendedRoute}`,
                            `/${accountSettingsRoute}`,
                            `/${languageRoute}`,
                            `/${thirdPartyPhoneRoute}`,
                            `/${emailVerifiedRoute}`,
                        ]}
                            component={AuthenticationImport} />

                        <Route path={[
                            `/${aboutUsRoute}`,
                            `/${contactUsRoute}`,
                            `/${aboutgRuhapsRoute}`,
                            `/${openSourceLicencesRoute}`,
                            `/${userFeedbackRoute}`,
                            `/${helpRoute}`,
                            `/${profileRoute}`,
                            `/${editProfileRoute}`,
                            `/${propertyAddressRoute}`,
                            `/${displayAddressRoute}`,
                            `/${notificationsRoute}`,
                            `/${fixedMarkerRoute}/:realm`,
                            // `/${advertRoute}/:id`,
                            `/${referralCodeRoute}`,
                            `/${rewardsRoute}`,
                            `/${imageZoomRoute}`,
                            `/${exploreRoute}`,
                            `/${ogcartRoute}`,
                            `/${ogcareRoute}`,
                            `/${exploreAppsRoute}`,
							`/${janSevaRoute}`,
							`/${emergencyContactsRoute}`,                            
							`/${userEmergencyContactsRoute}`,
                            `/${userEmergencyAlertNotificationRoute}`,                           
                        ]}
                            component={CommonImport} />

                        <Route path={`/${notFoundRoute}`} component={NotFound} />
                        <Route path="/" exact render={props => <HomeImport {...props} />} />
                        <Route component={NotFound} />
                    </Switch>

                    {
                        (process.env.REACT_APP_GRS_ENV === GRS_ENV_SP) &&
                            storage.getLocal(sp_signedIn) === "true" ?
                            <div><SpBroadcastRequestImport hideScreen={is_plans_screen_opened} history={this.props.history} /></div>
                            :
                            (process.env.REACT_APP_GRS_ENV === GRS_ENV_CLIENT) &&
                                storage.getLocal(cl_signedIn) === "true" ?
                                <div><ClBroadcastRequestImport history={this.props.history} /></div>
                                :
                                !process.env.REACT_APP_GRS_ENV ?
                                    storage.getLocal(cl_signedIn) === "true" ?
                                        <div><ClBroadcastRequestImport history={this.props.history} /></div>
                                        :
                                        storage.getLocal(sp_signedIn) === "true" ?
                                            <div><SpBroadcastRequestImport hideScreen={is_plans_screen_opened} history={this.props.history} /></div>
                                            :
                                            null
                                    : null
                    }
                    {
                        (!process.env.REACT_APP_GRS_ENV || process.env.REACT_APP_GRS_ENV === GRS_ENV_CLIENT) &&
                        <div>
                            <SocietyGateApprovalsImport history={this.props.history} />
                        </div>
                    }
                    {
                        (!process.env.REACT_APP_GRS_ENV || process.env.REACT_APP_GRS_ENV === GRS_ENV_CLIENT) &&
                        <div>
                            <EMeetingApprovalsImport history={this.props.history} />
                        </div>
                    }
                    {
                        (!process.env.REACT_APP_GRS_ENV || process.env.REACT_APP_GRS_ENV === GRS_ENV_CLIENT) &&
                        <div>
                            <MiscellaneousComponentsImport history={this.props.history} />
                        </div>
                    }
                    {
                        !this.state.is_premium_sp &&
                        <LeftPanel
                            history={this.props.history}
                            pathname={this.props.history && this.props.history.location && this.props.history.location.pathname ? this.props.location.pathname : "/"}
                        />
                    }
                    {!isDNhoodEnv() && !this.state.is_premium_sp && <RightPanel />}
                </div>
            );
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.location !== prevProps.location && this.state.show_error_component) {
            this.setState({ show_error_component: false });
        }
        if (this.props.rehydrate_loading !== prevProps.rehydrate_loading && this.props.rehydrate_loading) {
            this.onRehydrateLoaded();
        }
        if (this.props.booking_flow_data[booking_flow_object.is_premium_sp] !== prevProps.booking_flow_data[booking_flow_object.is_premium_sp]) {
            if (!sessionStorage.getItem(is_home_screen_flow)) {
                this.setState({ is_premium_sp: this.props.booking_flow_data[booking_flow_object.is_premium_sp] });
            }
        }
    }
}

function mapStateToProps({ rehydrate_loading, booking_flow_data }) {
    return {
        rehydrate_loading: rehydrate_loading.loaded,
        booking_flow_data: booking_flow_data
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(App);