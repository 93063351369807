import React from 'react'
import { Link } from 'react-router-dom'

import Paper from 'material-ui/Paper'

import gs_color from 'config/colors.css'

function renderImage(svgImage) {
    if (svgImage) {
        return (
            <img src={svgImage} style={{ width: '60px', height: '60px' }} />
        )
    }
    return null;
}

function SectionItemCard({ route, title, svgImage, disable }) {
    return (
        <Link to={route} style={{ display: 'inline-flex', textDecoration: 'none', minHeight: '110px', marginLeft: '8px', marginRight: '8px', width: 'calc(33% - 16px)', maxWidth: '123px' }}>
            <Paper style={{ width: '100%', margin: '8px 0', padding: '4px 4px', borderRadius: '5px', position: 'relative' }}>
                {
                    disable &&
                    <div style={{ position: 'absolute', zIndex: 1, top: 10, left: 10, backgroundColor: gs_color.primaryColor75, padding: '4px 6px', fontSize: '11px' }}>
                        PREMIUM
                    </div>
                }
                <div style={Object.assign({}, disable && { opacity: 0.6, marginTop: 10 })}>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '8px 0' }}>
                        {renderImage(svgImage)}
                    </div>
                    <div style={{ textAlign: 'center', paddingTop: '0px', fontWeight: 'bold', color: gs_color.accentColor }}>
                        {title || ""}
                    </div>
                </div>
            </Paper>
        </Link>
    )
}

export default SectionItemCard