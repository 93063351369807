import axios from 'middleware/axios';
import moment from 'moment';
import { API_LINK } from 'config/config';
import { cl_signedIn, cl_access_token, cl_user_id, cl_selected_address, sp_signedIn, sp_user_id, sp_access_token, bkg_new_agent_data } from 'config/storage-variables';
import { convertJsonDateToStringDate, toISODate, currentTimeMoment, convertTimseStampToText, convertMomentObjectToUTCISOString, convertRenderTimeToISOTime } from 'utils/date-time-conversion';
import {
  SERVICES, SERVICES_SUCCESS, SERVICES_ERROR,
  ADS, ADS_SUCCESS, ADS_ERROR,
  SVC_DISCOUNTS, SVC_DISCOUNTS_SUCCESS, SVC_DISCOUNTS_ERROR,
  PRODUCT_CART_CLEAR,
  CLEAR_PRODUCT_SCROLL,
  BOOKING_FLOW_DATA, CLEAR_BOOKING_FLOW_DATA,
  SERVICES_ITEMS, SERVICES_ITEMS_SUCCESS, SERVICES_ITEMS_ERROR,
  SERVICES_ITEMS_GROUP, SERVICES_ITEMS_GROUP_SUCCESS, SERVICES_ITEMS_GROUP_ERROR,
  GETSPLIST, GETSPLIST_SUCCESS, GETSPLIST_ERROR,
  SERVICE_PROVIDER, SERVICE_PROVIDER_SUCCESS, SERVICE_PROVIDER_ERROR,
  SPPROFILE, SPPROFILE_SUCCESS, SPPROFILE_ERROR,
  SHOWBROADCASTSCREEN,
  USER_PROFILE_DETAILS, USER_PROFILE_DETAILS_UPDATE, USER_PROFILE_DETAILS_SUCCESS, USER_PROFILE_DETAILS_ERROR,
  SPSCHEDULELIST, SPSCHEDULELIST_SUCCESS, SPSCHEDULELIST_ERROR,
  SPSCHEDULE, SPSCHEDULE_SUCCESS, SPSCHEDULE_ERROR,
  SPSCHEDULEDETAILS, SPSCHEDULEDETAILS_SUCCESS, SPSCHEDULEDETAILS_ERROR,
  SPCONFIRMBOOKINGS, SPCONFIRMBOOKINGS_SUCCESS, SPCONFIRMBOOKINGS_ERROR,
  PROCESSING_BOOKING, PROCESSING_BOOKING_SUCCESS, PROCESSING_BOOKING_ERROR,
  BROADCAST_LIST, BROADCAST_LIST_SUCCESS, BROADCAST_LIST_ERROR,
  CLIENT_BLOCKING_DATA, CLIENT_BLOCKING_DATA_SUCCESS, CLIENT_BLOCKING_DATA_ERROR,
  COMPLETED_BOOKING, COMPLETED_BOOKING_ERROR, COMPLETED_BOOKING_SUCCESS,
  CANCELED_BOOKING, CANCELED_BOOKING_SUCCESS, CANCELED_BOOKING_ERROR,
  BOOKINGDETAILS, BOOKINGDETAILS_SUCCESS, BOOKINGDETAILS_ERROR, BOOKINGDETAILS_CLEAR,
  BROADCASTBOOKINGDETAILS, BROADCASTBOOKINGDETAILS_ERROR, BROADCASTBOOKINGDETAILS_SUCCESS,
  MYBOOKINGS, MYBOOKINGS_SUCCESS, MYBOOKINGS_ERROR,
  MYBOOKINGHISTORY, MYBOOKINGHISTORY_SUCCESS, MYBOOKINGHISTORY_ERROR,
  QUOTATION_LIST, QUOTATION_LIST_SUCCESS, QUOTATION_LIST_ERROR,
  QUOTATION_DETAILS, QUOTATION_DETAILS_SUCCESS, QUOTATION_DETAILS_ERROR,
  INVOICE_DETAILS, INVOICE_DETAILS_SUCCESS, INVOICE_DETAILS_ERROR,
  CLEAR_ROUTE, BROADCAST_BOOKING_LIST_DATA, CLIENT_BLOCKING_LIST_DATA,
  INVOICE_MEDIA_DATA, QUOTE_MEDIA_DATA, CLEAR_INVOICE_MEDIA_DATA, CLEAR_QUOTE_MEDIA_DATA,
  EXTERNALBOOKING, EXTERNALBOOKING_SUCCESS, EXTERNALBOOKING_ERROR,
  SERVICE_SPECIFIC_FLOW, SERVICE_SPECIFIC_FLOW_SUCCESS, SERVICE_SPECIFIC_FLOW_ERROR,
  QUESTION_TREE, QUESTION_TREE_SUCCESS, QUESTION_TREE_ERROR,
  PRODUCT_CATEGORY, PRODUCT_CATEGORY_SUCCESS, PRODUCT_CATEGORY_ERROR,
  PRODUCT_SUB_CATEGORY, PRODUCT_SUB_CATEGORY_SUCCESS, PRODUCT_SUB_CATEGORY_ERROR,
  PRODUCT_TYPES, PRODUCT_TYPES_SUCCESS, PRODUCT_TYPES_ERROR,
  PRODUCT_LIST, PRODUCT_LIST_MORE, PRODUCT_LIST_SUCCESS, PRODUCT_LIST_ERROR,
  PRODUCT_CART_ADD, PRODUCT_CART_MINUS,
  PRODUCT_DETAILS, PRODUCT_DETAILS_SUCCESS, PRODUCT_DETAILS_ERROR,
  CLIENT_ADDRESSES, CLIENT_ADDRESSES_SUCCESS, CLIENT_ADDRESSES_ERROR, CLIENT_ADDRESSES_SHIPPING_SELECT,
  PRODUCT_DELIVERY_DATE_SELECT,
  PRODUCT_COMMUNITY,
  PRODUCT_CART_SYNC, PRODUCT_CART_SYNC_SUCCESS, PRODUCT_CART_SYNC_FAILURE,
  PRODUCT_CART_SAVE, PRODUCT_CART_SAVE_SUCCESS, PRODUCT_CART_SAVE_FAILURE,
  PRODUCT_CART_REFRESH, PRODUCT_CART_REFRESH_SUCCESS, PRODUCT_CART_REFRESH_FAILURE,
  PRODUCT_CART_ONLINE_REDIRECT, PRODUCT_CART_CHECKOUT, PRODUCT_CART_CHECKOUT_SUCCESS, PRODUCT_CART_CHECKOUT_FAILURE,
  EMPTY_ACTION,
  CLIENT_ADDRESSES_CLEAR_CACHE,
  UPDATE_PRODUCT_SCROLL,
  PRODUCT_DETAILS_SELECT_SKU,
  PRODUCT_CART_TIP_AMOUNT,
  PRODUCT_CART_USE_REWARDS,
  SOCIETY_MSG_BOARD_LIST, SOCIETY_MSG_BOARD_LIST_MORE, SOCIETY_MSG_BOARD_LIST_SUCCESS, SOCIETY_MSG_BOARD_LIST_ERROR, CLEAR_SOCIETY_MSG_BOARD_LIST, SOCIETY_MSG_BOARD_LIST_SCROLL,
  SOCIETY_GATE_APPROVAL_LIST, SOCIETY_GATE_APPROVAL_LIST_ERROR, SOCIETY_GATE_APPROVAL_LIST_SUCCESS, SOCIETY_GATE_APPROVAL_LIST_IGNORE_ENTRY, SOCIETY_GATE_APPROVAL_LIST_REMOVE_ENTRY,
  HIDE_SOCIETY_GATE_APPROVAL, SHOW_SOCIETY_GATE_APPROVAL,
  SOCIETY_COMPLAINTS_LIST_ERROR, SOCIETY_COMPLAINTS_LIST_SUCCESS, SOCIETY_COMPLAINTS_LIST, SOCIETY_COMPLAINTS_LIST_MORE, SOCIETY_COMPLAINTS_DETAILS, SOCIETY_COMPLAINTS_DETAILS_SUCCESS, SOCIETY_COMPLAINTS_DETAILS_ERROR, SOCIETY_COMPLAINTS_LIST_SCROLL,
  SOCIETY_MESSAGE_DETAILS, SOCIETY_MESSAGE_DETAILS_SUCCESS, SOCIETY_MESSAGE_DETAILS_ERROR, SOCIETY_MESSAGE_NEW_COMMENT,
  SOCIETY_DEPARTMENTS, SOCIETY_DEPARTMENTS_SUCCESS, SOCIETY_DEPARTMENTS_ERROR,
  SOCIETY_EMEETING_LIST, SOCIETY_EMEETING_LIST_ERROR, SOCIETY_EMEETING_LIST_SUCCESS, SOCIETY_EMEETING_LIST_IGNORE_MEETING, SOCIETY_EMEETING_LIST_REMOVE_MEETING,
  HIDE_SOCIETY_EMEETING_REQUEST, SHOW_SOCIETY_EMEETING_REQUEST,
  HIDE_MISCELLANEOUS_COMPONENTS, SHOW_MISCELLANEOUS_COMPONENTS, UPDATE_EMAIL_HIGHLIGHT, UPDATE_MISCELLANEOUS_COMPONENTS,
  SOCIETY_POLL_LIST_SCROLL,
  SOCIETY_TRANSACTIONS_MORE, SOCIETY_TRANSACTIONS, SOCIETY_TRANSACTIONS_SUCCESS, SOCIETY_TRANSACTIONS_ERROR, SOCIETY_TRANSACTIONS_CLEAR_CACHE, SOCIETY_TRANSACTIONS_SCROLL,
  PROPERTY_TRANSACTIONS_MORE, PROPERTY_TRANSACTIONS, PROPERTY_TRANSACTIONS_SUCCESS, PROPERTY_TRANSACTIONS_ERROR, PROPERTY_TRANSACTIONS_CLEAR_CACHE, PROPERTY_TRANSACTIONS_SCROLL, 
  PETTYCASH_TRANSACTIONS_MORE, PETTYCASH_TRANSACTIONS, PETTYCASH_TRANSACTIONS_SUCCESS, PETTYCASH_TRANSACTIONS_ERROR, PETTYCASH_TRANSACTIONS_CLEAR_CACHE, PETTYCASH_TRANSACTIONS_SCROLL,
  TRANSACTION_DETAILS, TRANSACTION_DETAILS_SUCCESS, TRANSACTION_DETAILS_ERROR, TRANSACTION_DETAILS_CLEAR_CACHE,
  SOCIETY_BILLS, SOCIETY_BILLS_MORE, SOCIETY_BILLS_SUCCESS, SOCIETY_BILLS_ERROR, SOCIETY_BILLS_SCROLL, SOCIETY_BILLS_CLEAR_CACHE,
  SOCIETY_INVOICES, SOCIETY_INVOICES_MORE, SOCIETY_INVOICES_SUCCESS, SOCIETY_INVOICES_ERROR, SOCIETY_INVOICES_SCROLL, SOCIETY_INVOICES_CLEAR_CACHE, SOCIETY_INVOICES_SELECTION,
  SETTLEMENT_DETAILS, SETTLEMENT_DETAILS_SUCCESS, SETTLEMENT_DETAILS_ERROR, SETTLEMENT_DETAILS_CLEAR_CACHE, SOCIETY_SETTLEMENTS_MORE, SOCIETY_SETTLEMENTS, SOCIETY_SETTLEMENTS_SUCCESS, SOCIETY_SETTLEMENTS_ERROR, SOCIETY_SETTLEMENTS_CLEAR_CACHE, SOCIETY_SETTLEMENTS_SCROLL,
  GEONAMES_STATE_LIST, GEONAMES_STATE_LIST_SUCCESS, GEONAMES_STATE_LIST_ERROR, GEONAMES_STATE_LIST_CLEAR_CACHE,
  GEONAMES_CITY_LIST, GEONAMES_CITY_LIST_SUCCESS, GEONAMES_CITY_LIST_ERROR, GEONAMES_CITY_LIST_CLEAR_CACHE,
  GEONAMES_OTHER_CITY_DATA, GEONAMES_OTHER_CITY_DATA_CLEAR_CACHE, GEONAMES_OTHER_CITY_DATA_SUCCESS, GEONAMES_OTHER_CITY_DATA_ERROR, GEONAMES_OTHER_CITY_DATA_UDPATE_CACHE,
  GET_RESTAURANT_DATA, GET_RESTAURANT_DATA_SUCCESS, GET_RESTAURANT_DATA_ERROR, FILTER_RESTAURANT_DATA,
  MENU_ITEM_CUSTOMIZATION, ADD_CART, REMOVE_CART, CLEAR_CART,
  UPDATE_COUPON_CODE, CLEAR_COUPON_CODE, UPDATE_CHECKOUT_OPTIONS,
  GET_DELIVERY_DATA, GET_DELIVERY_DATA_SUCCESS, GET_DELIVERY_DATA_ERROR,
  GET_DISCOUNT_CODE_LIST, GET_DISCOUNT_CODE_LIST_SUCCESS, GET_DISCOUNT_CODE_LIST_ERROR, CLEAR_DISCOUNT_CODE_LIST_CACHE,
  GET_OGFOOD_DATA_LIST, GET_OGFOOD_DATA_LIST_MORE, GET_OGFOOD_DATA_LIST_SUCCESS, GET_OGFOOD_DATA_LIST_ERROR,
  GET_OGFOOD_ORDER_LIST, GET_OGFOOD_ORDER_LIST_MORE, GET_OGFOOD_ORDER_LIST_SUCCESS, GET_OGFOOD_ORDER_LIST_ERROR, CLEAR_OGFOOD_ORDER_LIST_CACHE,
  GET_OGFOOD_ORDER_DETAILS, GET_OGFOOD_ORDER_DETAILS_SUCCESS, GET_OGFOOD_ORDER_DETAILS_ERROR, CLEAR_OGFOOD_ORDER_DETAILS_CACHE,
  GET_NEARBY_SERVICES, GET_NEARBY_SERVICES_SUCCESS, GET_NEARBY_SERVICES_ERROR, GET_NEARBY_SERVICES_MORE,
} from './types';

import { getReducerState } from '../utils/get-reducer-state';
// import store from '../reducer-store';    // Get the store object created in the called file.
import { booking_flow_object } from 'scenes/client/scenes/booking/config';
import store from '../reducer-store';    // Get the store object created in the called file.

// All common function with home screen are written in this file.
// And re-exported to avoid code duplication
export * from './home-action-functions';

export function getServices(lastFetchServiceTime = null) {
  let address = localStorage.getItem(cl_selected_address);
  let request;
  if (address) {
    address = JSON.parse(address);
    if (address && address.g_address) {
      address = address.g_address;
      if (address && address.geo_location && address.geo_location.lat && address.geo_location.lng && address.address && address.address.country_code && address.address.zipcode) {
        if (lastFetchServiceTime === null)
          request = axios.get(`${API_LINK}/gs_Services/gs_SynchServicesList?lat=${address.geo_location.lat}&lng=${address.geo_location.lng}&country=${address.address.country_code}&zipcode=${address.address.zipcode}`);
        else
          request = axios.get(`${API_LINK}/gs_Services/gs_SynchServicesList?lat=${address.geo_location.lat}&lng=${address.geo_location.lng}&country=${address.address.country_code}&zipcode=${address.address.zipcode}&last_fetch_time=${lastFetchServiceTime}`);

        return (dispatch) => {
          dispatch({ type: SERVICES });

          request
            .then(res => dispatch({ type: SERVICES_SUCCESS, payload: res.data }))
            .catch(err => dispatch({ type: SERVICES_ERROR, payload: err }));
        };
      }
    }
  }
}

export function getAdsForCity(city_id, type) {
  let access_token;
  if (localStorage.getItem(cl_signedIn) === 'true') {
    access_token = localStorage.getItem(cl_access_token);
  }
  else if (localStorage.getItem(sp_signedIn) === 'true') {
    access_token = localStorage.getItem(sp_access_token);
  }

  let params = 'city_id=' + city_id;
  if (type) {
    params += '&type=' + type;
  }
  if (access_token) {
    params += '&access_token=' + access_token;
  }

  let request = axios.get(`${API_LINK}/gs_Open_City_Services/getAdForCity?${params}`);

  return (dispatch) => {
    dispatch({ type: ADS });

    request
      .then(res => dispatch({ type: ADS_SUCCESS, payload: { ...res.data, type } }))
      .catch(err => dispatch({ type: ADS_ERROR, payload: err }));
  };
}


export function getDiscountsForCity(city_id) {
  let data = 'city_id=' + city_id;
  if (localStorage.getItem(cl_signedIn) === 'true') {
    data += '&client_id=' + localStorage.getItem(cl_user_id);
    data += '&access_token=' + localStorage.getItem(cl_access_token);
  }

  let request = axios.get(`${API_LINK}/gs_Users/gs_GetDefaultCouponCode?${data}`);

  return (dispatch) => {
    dispatch({ type: SVC_DISCOUNTS });

    request
      .then(res => dispatch({ type: SVC_DISCOUNTS_SUCCESS, payload: res.data }))
      .catch(err => dispatch({ type: SVC_DISCOUNTS_ERROR, payload: err }));
  };
}

export function clearProductCart(forceClear = true) {
  return dispatch => {
    dispatch({ type: PRODUCT_CART_CLEAR, payload: forceClear });

    if (localStorage.getItem(cl_signedIn) === 'true') {
      const user_id = localStorage.getItem(cl_user_id);
      const access_token = localStorage.getItem(cl_access_token);

      const data = `customer_id=${user_id}&access_token=${access_token}`;

      const pcart = {
        owner_id: user_id,
        cart_items: []
      }

      let request = axios.post(`${API_LINK}/gs_User_PCart/gs_saveCart?${data}`, pcart);
      request.then().catch();
    }
  }
}

export function clearProductScroll(data) {
  if (!data)
    return null;
  if (data === "all") {
    return (dispatch) => {
      dispatch({ type: CLEAR_PRODUCT_SCROLL, data });
    };
  }
  else if (data.constructor === String) {
    let fields_to_remove = [data];

    return (dispatch) => {
      dispatch({ type: CLEAR_PRODUCT_SCROLL, data: fields_to_remove });
    };
  }
  else if (data.constructor === Object) {
    let fields_to_remove = [];
    for (var key in data)
      fields_to_remove.push(key);

    return (dispatch) => {
      dispatch({ type: CLEAR_PRODUCT_SCROLL, data: fields_to_remove });
    };
  }
  else if (data.constructor === Array) {
    return (dispatch) => {
      dispatch({ type: CLEAR_PRODUCT_SCROLL, data: data });
    };
  }
  else return null;
}

export function clearBookingFlowData(data) {
  if (!data)
    return null;
  if (data === "all") {
    return (dispatch) => {
      dispatch({ type: CLEAR_BOOKING_FLOW_DATA, data });
    };
  }
  else if (data.constructor === Object) {
    let fields_to_remove = [];
    for (var key in data)
      fields_to_remove.push(key);

    return (dispatch) => {
      dispatch({ type: CLEAR_BOOKING_FLOW_DATA, data: fields_to_remove });
    };
  }
  else if (data.constructor === Array) {
    return (dispatch) => {
      dispatch({ type: CLEAR_BOOKING_FLOW_DATA, data: data });
    };
  }
  else return null;
}

export function updateBookingFlowData(data) {
  return (dispatch) => {
    dispatch({ type: BOOKING_FLOW_DATA, data: { ...data } });
  };
}

export function getServiceSpecificJSON(category_id = null, svc_id = null, sp_id = null, cell_id = null) {
  let data = {};

  if (category_id !== null && category_id !== undefined && category_id !== '') {
    data.category_id = category_id;
  }
  if (svc_id !== null && svc_id !== undefined && svc_id !== '') {
    data.service_id = svc_id;
  }
  if (sp_id !== null && sp_id !== undefined && sp_id !== '') {
    data.sp_id = sp_id;
  }
  if (cell_id !== null && cell_id !== undefined && cell_id !== '') {
    data.cell_id = cell_id;
  }

  if (Object.keys(data).length === 0) {
    return 'Required atleast one id';
  }

  let request = axios.get(`${API_LINK}/gs_Services/gs_getWorkflowObject?instance=${JSON.stringify(data)}`);

  return (dispatch) => {
    dispatch({ type: SERVICE_SPECIFIC_FLOW });
    request
      .then(res => dispatch({ type: SERVICE_SPECIFIC_FLOW_SUCCESS, payload: res.data }))
      .catch(err => dispatch({ type: SERVICE_SPECIFIC_FLOW_ERROR, payload: err }));
  };
}

export function getQuestionTree(svc_id = null, que_id = null, ref_path = null) {
  if (!svc_id && !que_id && !ref_path) {
    return 'atleast one parameter is compulsory';
  }

  let data = null;

  if (svc_id) {
    data = 'service_id=' + svc_id;
  }
  else if (svc_id) {
    data = 'qid=' + que_id;
  }
  else if (svc_id) {
    data = 'ref_path=' + ref_path;
  }

  let request = axios.get(`${API_LINK}/gs_Que_Repository/gs_getSvcQueTree?${data}`);

  return (dispatch) => {
    dispatch({ type: QUESTION_TREE });
    request
      .then(res => dispatch({ type: QUESTION_TREE_SUCCESS, payload: res.data }))
      .catch(err => dispatch({ type: QUESTION_TREE_ERROR, payload: err }));
  };
}

export function clearRoute(data) {
  if (!data)
    return null;
  if (data === "all") {
    return (dispatch) => {
      dispatch({ type: CLEAR_ROUTE, data });
    };
  }
  else if (data.constructor === String) {
    let fields_to_remove = [data];

    return (dispatch) => {
      dispatch({ type: CLEAR_ROUTE, data: fields_to_remove });
    };
  }
  else if (data.constructor === Object) {
    let fields_to_remove = [];
    for (var key in data)
      fields_to_remove.push(key);

    return (dispatch) => {
      dispatch({ type: CLEAR_ROUTE, data: fields_to_remove });
    };
  }
  else if (data.constructor === Array) {
    return (dispatch) => {
      dispatch({ type: CLEAR_ROUTE, data: data });
    };
  }
  else return null;
}

export function updateBroadcastBookingList(data) {
  return (dispatch) => {
    dispatch({ type: BROADCAST_BOOKING_LIST_DATA, data: [...data] });
  };
}

export function updateClientBroadcastBookingList(data) {
  return (dispatch) => {
    dispatch({ type: CLIENT_BLOCKING_LIST_DATA, data: [...data] });
  };
}

export function updateInvoiceMediaData(data) {
  return (dispatch) => {
    dispatch({ type: INVOICE_MEDIA_DATA, data: { ...data } });
  };
}

export function updateQuoteMediaData(data) {
  return (dispatch) => {
    dispatch({ type: QUOTE_MEDIA_DATA, data: { ...data } });
  };
}

export function showBroadcastScreen(value) {
  return (dispatch) => {
    dispatch({ type: SHOWBROADCASTSCREEN, data: value });
  };
}

export function ClearInvoiceMediaData(data) {
  if (!data)
    return null;
  if (data === "all") {
    return (dispatch) => {
      dispatch({ type: CLEAR_INVOICE_MEDIA_DATA, data });
    };
  }
  else return null;
}

export function ClearQuoteMediaData(data) {
  if (!data)
    return null;
  if (data === "all") {
    return (dispatch) => {
      dispatch({ type: CLEAR_QUOTE_MEDIA_DATA, data });
    };
  }
  else return null;
}

export function getServiceItemsGroup(service_id, city_id) {
  const request = axios.get(`${API_LINK}/gs_Que_Repository/gs_getSvcGroupItems?service_id=${service_id}&city_id=${city_id}`);

  return (dispatch) => {
    dispatch({ type: SERVICES_ITEMS_GROUP });

    request
      .then(res => dispatch({ type: SERVICES_ITEMS_GROUP_SUCCESS, payload: { ...res.data, cache: { service_id, city_id } } }))
      .catch(err => dispatch({ type: SERVICES_ITEMS_GROUP_ERROR, payload: err }));
  };
}

export function getServiceItems(service_id) {
  const request = axios.get(`${API_LINK}/gs_Services/${service_id}/ServiceItems?filter[order]=item_name%20ASC`);

  return (dispatch) => {
    dispatch({ type: SERVICES_ITEMS });

    request
      .then(res => dispatch({ type: SERVICES_ITEMS_SUCCESS, payload: res.data }))
      .catch(err => dispatch({ type: SERVICES_ITEMS_ERROR, payload: err }));
  };
}

// Blocking data of client 
export function ClientBlockingScreenList(type) {
  let access_token;
  let action_type = null;
  let action_type_success = null;
  let action_type_error = null;
  // TYPE = 1 - CLIENT BROADCAST BOOKING LIST

  if (localStorage.getItem(cl_signedIn) === 'true') {
    access_token = localStorage.getItem(cl_access_token);
  }

  const request = axios.get(`${API_LINK}/gs_Users/gs_getClientBlockingData?access_token=${access_token}`);

  if (type === 1) {
    action_type = CLIENT_BLOCKING_DATA;
    action_type_success = CLIENT_BLOCKING_DATA_SUCCESS;
    action_type_error = CLIENT_BLOCKING_DATA_ERROR;
  }

  return (dispatch) => {
    request
      .then(res => {
        if (action_type) {
          dispatch({ type: action_type_success, payload: res.data })
        }

      })
      .catch(err => {
        if (action_type)
          dispatch({ type: action_type_error, payload: err })
      })
  };
}


export function MyBookings(bkg_type, type = 0) {
  // TYPE = 0 -> MYBOOKINGS
  // TYPE = 1 -> MYBOOKINGHISTORY
  // TYPE = 2 -> SPCONFIRMBOOKINGS & EXTERNALBOOKING

  //TYPE = 3 => PROCESSING_BOOKING and BROADCAST_LIST
  //TYPE = 4 => COMPLETED_BOOKING
  //TYPE = 5 => CANCELED_BOOKING
  //TYPE = 6 => BROADCAST_LIST
  //TYPE = 7 => PROCESSING_BOOKING

  let user_id, access_token;
  if (localStorage.getItem(cl_signedIn) === 'true') {
    user_id = localStorage.getItem(cl_user_id);
    access_token = localStorage.getItem(cl_access_token);
  }
  else if (localStorage.getItem(sp_signedIn) === 'true') {
    user_id = localStorage.getItem(sp_user_id);
    access_token = localStorage.getItem(sp_access_token);
  }

  const request = axios.get(`${API_LINK}/gs_Bookings/gs_GetBookingList?userId=${user_id}&bkgType=${bkg_type}&access_token=${access_token}`);
  let action_type1 = null;
  let action_type_success1 = null;
  let action_type_error1 = null;
  let action_type2 = null;
  let action_type_success2 = null;
  let action_type_error2 = null;

  if (type === 1) {
    action_type1 = MYBOOKINGHISTORY;
    action_type_success1 = MYBOOKINGHISTORY_SUCCESS;
    action_type_error1 = MYBOOKINGHISTORY_ERROR;
  }
  else if (type === 2) {
    action_type1 = SPCONFIRMBOOKINGS;
    action_type_success1 = SPCONFIRMBOOKINGS_SUCCESS;
    action_type_error1 = SPCONFIRMBOOKINGS_ERROR;

    action_type2 = EXTERNALBOOKING;
    action_type_success2 = EXTERNALBOOKING_SUCCESS;
    action_type_error2 = EXTERNALBOOKING_ERROR;
  }
  else if (type === 3) {
    action_type1 = PROCESSING_BOOKING;
    action_type_success1 = PROCESSING_BOOKING_SUCCESS;
    action_type_error1 = PROCESSING_BOOKING_ERROR;

    action_type2 = BROADCAST_LIST;
    action_type_success2 = BROADCAST_LIST_SUCCESS;
    action_type_error2 = BROADCAST_LIST_ERROR;
  }
  else if (type === 4) {
    action_type1 = COMPLETED_BOOKING;
    action_type_success1 = COMPLETED_BOOKING_SUCCESS;
    action_type_error1 = COMPLETED_BOOKING_ERROR;
  }
  else if (type === 5) {
    action_type1 = CANCELED_BOOKING;
    action_type_success1 = CANCELED_BOOKING_SUCCESS;
    action_type_error1 = CANCELED_BOOKING_ERROR;
  }
  else if (type === 6) {
    action_type2 = BROADCAST_LIST;
    action_type_success2 = BROADCAST_LIST_SUCCESS;
    action_type_error2 = BROADCAST_LIST_ERROR;
  }
  else if (type === 7) {
    action_type1 = PROCESSING_BOOKING;
    action_type_success1 = PROCESSING_BOOKING_SUCCESS;
    action_type_error1 = PROCESSING_BOOKING_ERROR;
  }
  else {
    action_type1 = MYBOOKINGS;
    action_type_success1 = MYBOOKINGS_SUCCESS;
    action_type_error1 = MYBOOKINGS_ERROR;
  }
  return (dispatch) => {
    if (action_type1)
      dispatch({ type: action_type1 });
    if (action_type2)
      dispatch({ type: action_type2 });

    request
      .then(res => {
        if (action_type1)
          dispatch({ type: action_type_success1, payload: res.data })
        if (action_type2) {
          dispatch({ type: action_type_success2, payload: res.data })
        }

      })
      .catch(err => {
        if (action_type1)
          dispatch({ type: action_type_error1, payload: err })
        if (action_type2)
          dispatch({ type: action_type_error2, payload: err })

      })
  };
}


export function clearBookingDetails() {
  return (dispatch) => {
    dispatch({ type: BOOKINGDETAILS_CLEAR });
  };
}

export function getBookingDetails(bkgId) {
  let access_token;
  if (localStorage.getItem(cl_signedIn) === 'true')
    access_token = localStorage.getItem(cl_access_token);
  else if (localStorage.getItem(sp_signedIn) === 'true')
    access_token = localStorage.getItem(sp_access_token);

  const request = axios.get(`${API_LINK}/gs_Bookings/gs_GetBookingDetails?bkgId=${bkgId}&access_token=${access_token}`);

  return (dispatch) => {
    dispatch({ type: BOOKINGDETAILS });

    request
      .then(res => dispatch({ type: BOOKINGDETAILS_SUCCESS, payload: res.data }))
      .catch(err => dispatch({ type: BOOKINGDETAILS_ERROR, payload: err }));
  };
}

export function getBroadcastBookingDetails(bkgId) {
  let access_token, realm;
  if (localStorage.getItem(cl_signedIn) === 'true') {
    access_token = localStorage.getItem(cl_access_token);
    realm = "svc_consumer";
  }
  else if (localStorage.getItem(sp_signedIn) === 'true') {
    access_token = localStorage.getItem(sp_access_token);
    realm = "svc_provider";
  }

  const request = axios.get(`${API_LINK}/gs_Bookings/gs_GetBroadcastBookingDetails?realm=${realm}&bkgId=${bkgId}&access_token=${access_token}`);

  return (dispatch) => {
    dispatch({ type: BROADCASTBOOKINGDETAILS });

    request
      .then(res => dispatch({ type: BROADCASTBOOKINGDETAILS_SUCCESS, payload: res.data }))
      .catch(err => dispatch({ type: BROADCASTBOOKINGDETAILS_ERROR, payload: err }));
  };
}

export function getQuotationList(bkgId) {
  let access_token;
  if (localStorage.getItem(cl_signedIn) === 'true')
    access_token = localStorage.getItem(cl_access_token);
  else if (localStorage.getItem(sp_signedIn) === 'true')
    access_token = localStorage.getItem(sp_access_token);

  const request = axios.get(`${API_LINK}/gs_Quotes/gs_GetQuoteList?booking_id=${bkgId}&access_token=${access_token}`);

  return (dispatch) => {
    dispatch({ type: QUOTATION_LIST });

    request
      .then(res => dispatch({ type: QUOTATION_LIST_SUCCESS, payload: res.data }))
      .catch(err => dispatch({ type: QUOTATION_LIST_ERROR, payload: err }));
  };
}

export function getQuotationDetails(booking_id, quote_id) {
  let access_token;
  if (localStorage.getItem(cl_signedIn) === 'true')
    access_token = localStorage.getItem(cl_access_token);
  else if (localStorage.getItem(sp_signedIn) === 'true')
    access_token = localStorage.getItem(sp_access_token);

  const request = axios.get(`${API_LINK}/gs_Bookings/${booking_id}/gs_GetQuoteDetails?quote_id=${quote_id}&access_token=${access_token}`)

  return (dispatch) => {
    dispatch({ type: QUOTATION_DETAILS });

    request
      .then(res => dispatch({ type: QUOTATION_DETAILS_SUCCESS, payload: res.data }))
      .catch(err => dispatch({ type: QUOTATION_DETAILS_ERROR, payload: err }));
  };
}

export function getInvoiceDetails(booking_id, invoice_id) {
  let access_token;
  if (localStorage.getItem(cl_signedIn) === 'true')
    access_token = localStorage.getItem(cl_access_token);
  else if (localStorage.getItem(sp_signedIn) === 'true')
    access_token = localStorage.getItem(sp_access_token);

  //const request = axios.get(`${API_LINK}/gs_Bookings/${booking_id}/gs_GetInvoiceDetails?invoice_id=${invoice_id}&access_token=${access_token}`)
  const request = axios.get(`${API_LINK}/gs_Invoices/${invoice_id}?access_token=${access_token}`)

  return (dispatch) => {
    dispatch({ type: INVOICE_DETAILS });

    request
      .then(res => dispatch({ type: INVOICE_DETAILS_SUCCESS, payload: res.data }))
      .catch(err => dispatch({ type: INVOICE_DETAILS_ERROR, payload: err }));
  };
}

export function getSPDetails(sp_id, svc_id, svc_item_list, timezone = null) {
  let formatted_date, svc_date_utc;
  let booking_date = null;
  const reducer_state = getReducerState();
  if (reducer_state.booking_flow_data[booking_flow_object.booking_date])
    booking_date = reducer_state.booking_flow_data[booking_flow_object.booking_date];
  let intraday = reducer_state.booking_flow_data[booking_flow_object.booking_timeslot] !== undefined && reducer_state.booking_flow_data[booking_flow_object.booking_timeslot] !== null ? 'false' : 'true';
  if (booking_date !== undefined && booking_date !== null) {
    booking_date = JSON.parse(booking_date);
    let { dd, mm, yyyy } = booking_date;
    mm += '';
    dd += '';
    if (mm.length < 2) mm = '0' + mm;
    if (dd.length < 2) dd = '0' + dd;

    formatted_date = [yyyy, mm, dd].join('-');
    svc_date_utc = convertMomentObjectToUTCISOString(2, formatted_date, "00:00:00", timezone);
  }
  let access_token = localStorage.getItem('cl_access_token');
  let params = `?sp_id=${sp_id}&svc_id=${svc_id}&svc_item_list=${svc_item_list}&svc_date=${svc_date_utc}&is_intraday=${intraday}&access_token=${access_token}`

  const request = axios.get(`${API_LINK}/gs_Users/gs_GetSPDetails${params}`);

  return (dispatch) => {
    dispatch({ type: SERVICE_PROVIDER });

    request
      .then(res => dispatch({ type: SERVICE_PROVIDER_SUCCESS, payload: res.data }))
      .catch(err => dispatch({ type: SERVICE_PROVIDER_ERROR, payload: err }));
  };
}

export function getSPProfile(sp_id = null, sp_public_url = null) {
  // Will be called if search by sp name is called.

  let access_token = localStorage.getItem('cl_access_token'), request;

  if (sp_id) {
    request = axios.get(`${API_LINK}/gs_Users/gs_GetSPProfile?sp_id=${sp_id}&access_token=${access_token}`);
  }
  else if (sp_public_url) {
    let data = '';
    data += 'sp_page_name=' + sp_public_url;
    if (access_token)
      data += '&access_token=' + access_token;

    request = axios.get(`${API_LINK}/gs_Users/gs_GetPremiumSpInfo?${data}`);
  }

  return (dispatch) => {
    dispatch({ type: SPPROFILE });

    request
      .then(res => dispatch({ type: SPPROFILE_SUCCESS, payload: res.data }))
      .catch(err => dispatch({ type: SPPROFILE_ERROR, payload: err }));
  };
}

export function getSPList(service_id, timezone, svc_item_list, intraday_padding = null) {
  const reducer_state = getReducerState();
  let formatted_date, svc_date_utc, booking_date = reducer_state.booking_flow_data[booking_flow_object.booking_date];
  let bkg_time_slots = reducer_state.booking_flow_data[booking_flow_object.booking_timeslot];
  let intraday;
  let bkg_duration = reducer_state.booking_flow_data[booking_flow_object.booking_duration];
  let svc_place = reducer_state.booking_flow_data[booking_flow_object.booking_svc_place];
  let ts_starttime = '', ts_endtime = '';

  if (booking_date !== undefined && booking_date !== null) {
    booking_date = JSON.parse(booking_date);
    let { dd, mm, yyyy } = booking_date;

    formatted_date = convertJsonDateToStringDate(dd, mm, yyyy);
    svc_date_utc = convertMomentObjectToUTCISOString(2, formatted_date, "00:00:00", timezone);
    let time = currentTimeMoment(0, this.timezone);
    let date = new Date();
    let current_date = toISODate(date);

    if (bkg_time_slots !== undefined && bkg_time_slots !== null) {
      bkg_time_slots = JSON.parse(bkg_time_slots);
      intraday = 'false';
      let start_time;

      // Intraday
      if (formatted_date === current_date) {
        start_time = bkg_time_slots.timeSlot_st;
        ts_starttime = convertMomentObjectToUTCISOString(2, formatted_date, start_time, timezone);
        if (intraday_padding) {
          let start = moment(ts_starttime).add(intraday_padding, 'minute');
          start = convertTimseStampToText(start, 1, 1, false, timezone);
          ts_starttime = convertMomentObjectToUTCISOString(2, formatted_date, start, timezone);

        }
      }
      // Future date
      else {
        start_time = bkg_time_slots.timeSlot_st;
        ts_starttime = convertMomentObjectToUTCISOString(2, formatted_date, start_time, timezone);
      }

      // Update end date if coming 12 am.
      if (!ts_endtime) {
        if (convertRenderTimeToISOTime(bkg_time_slots.timeSlot_et) === "00:00:00") {
          let temp_formatted_date = moment([yyyy, mm - 1, dd]);
          temp_formatted_date.add(1, 'd');
          temp_formatted_date = convertJsonDateToStringDate(temp_formatted_date.date(), temp_formatted_date.month() + 1, temp_formatted_date.year());
          ts_endtime = convertMomentObjectToUTCISOString(2, temp_formatted_date, bkg_time_slots.timeSlot_et, timezone);
        }
        else {
          ts_endtime = convertMomentObjectToUTCISOString(2, formatted_date, bkg_time_slots.timeSlot_et, timezone);
        }
      }
    }
    //Removed as of no use according to current intraday flow

    // else {
    //   intraday = 'true';
    // }
  }
  let lat, lng, zipcode, country_code;

  // Get lat, lng, zipcode and country_code from selected address.
  let prop_add = localStorage.getItem(cl_selected_address);
  if (prop_add !== null) {
    prop_add = JSON.parse(prop_add);
    if (prop_add && prop_add.g_address) {
      prop_add = prop_add.g_address;
      if (prop_add && prop_add.address && prop_add.geo_location) {
        lat = prop_add.geo_location.lat;
        lng = prop_add.geo_location.lng;
        zipcode = prop_add.address.zipcode;
        country_code = prop_add.address.country_code;
      }
    }
  }

  let params = '', batch_size = 10;

  if (intraday !== 'true')
    batch_size = 20;

  if (service_id) params += `svc_id=${service_id}`;
  if (lat) params += `&lat=${lat}`;
  if (lng) params += `&lng=${lng}`;
  if (zipcode) params += `&zipcode=${zipcode}`;
  if (country_code) params += `&country_code=${country_code}`;
  if (svc_date_utc) params += `&svc_date=${svc_date_utc}`;
  // if (intraday) params += `&is_intraday=${intraday}`;
  if (ts_starttime !== '') params += `&ts_starttime=${ts_starttime}`;
  if (ts_endtime !== '') params += `&ts_endtime=${ts_endtime}`;
  if (bkg_duration !== null && bkg_duration !== undefined) params += `&bkg_duration=${bkg_duration}`;
  if (svc_item_list !== null && svc_item_list !== undefined) params += `&svc_item_list=${svc_item_list}`;
  if (svc_place) params += `&svc_place=${svc_place}`;
  params += `&batch_size=${batch_size}`;

  let access_token = localStorage.getItem('cl_access_token');
  if (access_token) params += `&access_token=${access_token}`;

  let user_id = localStorage.getItem('cl_user_id');
  if (user_id) params += `&user_id=${user_id}`;
  sessionStorage.setItem(bkg_new_agent_data, 'true');

  const request = axios.get(`${API_LINK}/gs_Users/gs_GetCloseProximitySPBatch?${params}`)

  return (dispatch) => {
    dispatch({ type: GETSPLIST });

    request
      .then(res => dispatch({ type: GETSPLIST_SUCCESS, payload: res.data }))
      .catch(err => dispatch({ type: GETSPLIST_ERROR, payload: err }));
  };
}

export function UserProfileDetails() {
  let user_id, access_token;
  if (localStorage.getItem(cl_signedIn) === 'true') {
    user_id = localStorage.getItem(cl_user_id);
    access_token = localStorage.getItem(cl_access_token);
  }
  else if (localStorage.getItem(sp_signedIn) === 'true') {
    user_id = localStorage.getItem(sp_user_id);
    access_token = localStorage.getItem(sp_access_token);
  }

  if (user_id !== null && access_token !== null) {
    const request = axios.get(`${API_LINK}/gs_Users/${user_id}/gs_getUserProfileDetails?access_token=${access_token}`);

    return (dispatch) => {
      dispatch({ type: USER_PROFILE_DETAILS });

      request
        .then(res => dispatch({ type: USER_PROFILE_DETAILS_SUCCESS, payload: res.data }))
        .catch(err => dispatch({ type: USER_PROFILE_DETAILS_ERROR, payload: err }));
    };
  }
}

export function updateProfileDetails(data) {
  return (dispatch) => {
    dispatch({ type: USER_PROFILE_DETAILS_UPDATE, payload: data });
  };
}

export function patchClientDetails(profile_pic = null, common_info = null, client_info = null, display_name = null, phone, email, login_type, cb) {

  let user_id = localStorage.getItem(cl_user_id), access_token = localStorage.getItem(cl_access_token);

  if (user_id !== null && access_token !== null) {
    let data = {};

    if (profile_pic !== null)
      data["profile_pic"] = profile_pic;

    if (common_info !== null)
      data["common_info"] = common_info;

    if (client_info !== null)
      data["client_info"] = client_info;

    if (display_name !== null)
      data["display_name"] = display_name;

    const request = axios.patch(`${API_LINK}/gs_Users/${user_id}/gs_updateUserProfileDetails?access_token=${access_token}`, { instance: data })

    request
      .then((res) => {
        if (res.data.statusCode.toString().slice(0, 2) == 20) {
          cb(res.data, null);
        }
        else {
          cb(null, res.data);
        }
      })
      .catch((err) => {
        if (err.response && err.response.data && err.response.data.error) {
          if (err.response.data.error.statusCode === 401) {
            cb(null, { ...err.response.data.error, is_logout: true });
          }
          else
            cb(null, err.response.data.error);
        }
        else {
          cb(null, err);
        }
      });

  }
}


export function patchSpDetails(profile_pic = null, common_info = null, sp_info = null, display_name = null, phone, email, cb) {
  let user_id = localStorage.getItem(sp_user_id), access_token = localStorage.getItem(sp_access_token);

  if (user_id !== null && access_token !== null) {
    let data = {};

    if (profile_pic !== null)
      data["profile_pic"] = profile_pic;

    if (common_info !== null)
      data["common_info"] = common_info;

    if (sp_info !== null)
      data["sp_info"] = sp_info;

    if (display_name !== null)
      data["display_name"] = display_name;

    const request = axios.patch(`${API_LINK}/gs_Users/${user_id}/gs_updateUserProfileDetails?access_token=${access_token}`, { instance: data });
    request
      .then((res) => {
        if (res.data.statusCode.toString().slice(0, 2) == 20) {
          cb(res.data, null);
        }
        else {
          cb(null, res.data);
        }
      })
      .catch((err) => {
        if (err.response && err.response.data && err.response.data.error) {
          if (err.response.data.error.statusCode === 401) {
            cb(null, { ...err.response.data.error, is_logout: true });
          }
          else
            cb(null, err.response.data.error);
        }
        else {
          cb(null, err);
        }
      });
  };
}

export function getSPSchedules(sp_id, access_token) {
  const request = axios.get(`${API_LINK}/gs_Users/${sp_id}/sp_schedule?access_token=${access_token}`)

  return (dispatch) => {
    dispatch({ type: SPSCHEDULELIST });

    request
      .then(res => dispatch({ type: SPSCHEDULELIST_SUCCESS, payload: res.data }))
      .catch(err => dispatch({ type: SPSCHEDULELIST_ERROR, payload: err }));
  };
}

export function setSPSchedule(schedule) {
  return (dispatch) => {
    dispatch({ type: SPSCHEDULE_SUCCESS, payload: schedule });
    dispatch({ type: SPSCHEDULEDETAILS });
  }
}

export function getSPSchedule(sp_id, access_token, sch_id) {
  const request = axios.get(`${API_LINK}/gs_SP_Schedule/${sch_id}?access_token=${access_token}`)

  return (dispatch) => {
    dispatch({ type: SPSCHEDULE });

    request
      .then(res => {
        dispatch({ type: SPSCHEDULE_SUCCESS, payload: res.data })
      })
      .catch(err => dispatch({ type: SPSCHEDULE_ERROR, payload: err }));
  };
}

export function getSPScheduleDetails(sp_id, access_token, sch_id) {
  const request = axios.get(`${API_LINK}/gs_SP_Schedule/${sch_id}/SchDetail?filter[order]=start_time%20ASC&access_token=${access_token}`)

  return (dispatch) => {
    dispatch({ type: SPSCHEDULEDETAILS });

    request
      .then(res => {
        dispatch({ type: SPSCHEDULEDETAILS_SUCCESS, payload: res.data })
      })
      .catch(err => dispatch({ type: SPSCHEDULEDETAILS_ERROR, payload: err }));
  };
}

export function getSPOverrideSchedule(lower_date, upper_date) {
  let user_id = localStorage.getItem(sp_user_id), access_token = localStorage.getItem(sp_access_token);

  if (!access_token || !user_id) {
    return { err: "Authorization Required" };
  }

  let data = "";
  data += `userId=${user_id}`;

  if (lower_date)
    data += `&startTime=${lower_date}`;

  if (upper_date)
    data += `&endTime=${upper_date}`;

  data += `&access_token=${access_token}`;

  const request = axios.get(`${API_LINK}/gs_SP_Schedule/gs_GetScheduleDetails?${data}`);

  return (dispatch) => {
    dispatch({ type: SPSCHEDULEDETAILS });

    request
      .then(res => {
        dispatch({ type: SPSCHEDULEDETAILS_SUCCESS, payload: res.data })
      })
      .catch(err => dispatch({ type: SPSCHEDULEDETAILS_ERROR, payload: err }));
  };
}

export function getProductCategories(city_id) {
  let data = `city_id=${city_id}&batch_size=100`;
  if (localStorage.getItem(cl_signedIn) === 'true') {
    data += '&access_token=' + localStorage.getItem(cl_access_token);
  }

  let request = axios.get(`${API_LINK}/gs_Product_Category/gs_GetCatListForCity?${data}`)

  return (dispatch) => {
    dispatch({ type: PRODUCT_CATEGORY })

    request
      .then(res => dispatch({ type: PRODUCT_CATEGORY_SUCCESS, payload: { apiData: res.data, cache: { city_id: city_id } } }))
      .catch(err => dispatch({ type: PRODUCT_CATEGORY_ERROR, payload: err }));

  }
}

export function getProductSubCategories(city_id) {
  let data = `city_id=${city_id}&batch_size=500`;
  if (localStorage.getItem(cl_signedIn) === 'true') {
    data += '&access_token=' + localStorage.getItem(cl_access_token);
  }

  let request = axios.get(`${API_LINK}/gs_Product_SubCategory/gs_GetSubCatListForCity?${data}`)

  return (dispatch) => {
    dispatch({ type: PRODUCT_SUB_CATEGORY })

    request
      .then(res => dispatch({ type: PRODUCT_SUB_CATEGORY_SUCCESS, payload: { apiData: res.data, cache: { city_id: city_id } } }))
      .catch(err => dispatch({ type: PRODUCT_SUB_CATEGORY_ERROR, payload: err }));

  }
}

export function getProductTypes(city_id, pscat_id) {
  let data = `city_id=${city_id}&pscat_id=${pscat_id}`;
  if (localStorage.getItem(cl_signedIn) === 'true') {
    data += '&access_token=' + localStorage.getItem(cl_access_token);
  }

  let request = axios.get(`${API_LINK}/gs_Product_Type/gs_getActiveProductType?${data}`)

  return (dispatch) => {
    dispatch({ type: PRODUCT_TYPES })

    request
      .then(res => dispatch({ type: PRODUCT_TYPES_SUCCESS, payload: { apiData: res.data, cache: { city_id: city_id, pscat_id: pscat_id } } }))
      .catch(err => dispatch({ type: PRODUCT_TYPES_ERROR, payload: err }));

  }
}

export function getProductList(city_id, pscat_id = undefined, ptype_id = undefined, community_id = undefined, last_product_id = undefined, batch_size = 12, fetchOutOfStock) {
  let data = `city_id=${city_id}&batch_size=${batch_size}`;
  if (pscat_id) {
    data += `&pscat_id=${pscat_id}`;
  }
  if (ptype_id) {
    data += `&ptype_id=${ptype_id}`;
  }
  if (community_id) {
    data += `&community_id=${community_id}`;
  }
  if (last_product_id) {
    data += `&last_product_id=${last_product_id}`
  }
  if (typeof fetchOutOfStock === "boolean") {
    data += '&fetchOutOfStock=' + fetchOutOfStock;
  }
  if (localStorage.getItem(cl_signedIn) === 'true') {
    data += '&access_token=' + localStorage.getItem(cl_access_token);
  }

  let request = axios.get(`${API_LINK}/gs_Product/gs_getActiveProductList?${data}`)

  return (dispatch) => {
    if (last_product_id) {
      dispatch({ type: PRODUCT_LIST_MORE })
    } else {
      dispatch({ type: PRODUCT_LIST })
    }

    request
      .then(res => dispatch({ type: PRODUCT_LIST_SUCCESS, payload: { apiData: res.data, batchSize: batch_size, cache: { city_id, pscat_id, ptype_id, community_id, last_product_id } } }))
      .catch(err => dispatch({ type: PRODUCT_LIST_ERROR, payload: err }));

  }
}

export function addToCart(product, psku_id) {
  return dispatch => dispatch({ type: PRODUCT_CART_ADD, payload: { product, psku_id } })
}

export function minusFromCart(product, psku_id) {
  return dispatch => dispatch({ type: PRODUCT_CART_MINUS, payload: { product, psku_id } })
}

export function syncCart(addCheckOutInfo = false, city_id, community_id) {
  if (localStorage.getItem(cl_signedIn) === 'true') {
    const user_id = localStorage.getItem(cl_user_id);
    const access_token = localStorage.getItem(cl_access_token);

    let data = `customer_id=${user_id}&addCheckOutInfo=${addCheckOutInfo}&access_token=${access_token}`;
    if (city_id) {
      data += `&city_id=${city_id}`;
    }
    if (community_id) {
      data += `&community_id=${community_id}`;
    }

    let request = axios.get(`${API_LINK}/gs_User_PCart/gs_getCart?${data}`);

    return dispatch => {
      dispatch({ type: PRODUCT_CART_SYNC })

      request
        .then(res => dispatch({ type: PRODUCT_CART_SYNC_SUCCESS, payload: res.data }))
        .catch(err => dispatch({ type: PRODUCT_CART_SYNC_FAILURE, payload: err }));

    }
  } else {
    return dispatch => dispatch({ type: EMPTY_ACTION })
  }
}

export function saveCart(pcart) {
  if (localStorage.getItem(cl_signedIn) === 'true') {
    const user_id = localStorage.getItem(cl_user_id);
    const access_token = localStorage.getItem(cl_access_token);

    const data = `customer_id=${user_id}&access_token=${access_token}`;

    let request = axios.post(`${API_LINK}/gs_User_PCart/gs_saveCart?${data}`, pcart);

    return dispatch => {
      dispatch({ type: PRODUCT_CART_SAVE })

      request
        .then(res => dispatch({ type: PRODUCT_CART_SAVE_SUCCESS, payload: res.data }))
        .catch(err => dispatch({ type: PRODUCT_CART_SAVE_FAILURE, payload: err }));

    }
  } else {
    return dispatch => dispatch({ type: EMPTY_ACTION })
  }
}

export function refreshCart(pcart) {
  if (localStorage.getItem(cl_signedIn) === 'true') {
    const user_id = localStorage.getItem(cl_user_id);
    const access_token = localStorage.getItem(cl_access_token);

    const data = `customer_id=${user_id}&access_token=${access_token}`;

    let request = axios.post(`${API_LINK}/gs_User_PCart/gs_refreshCart?${data}`, { pcart, addCheckOutInfo: true });

    return dispatch => {
      dispatch({ type: PRODUCT_CART_REFRESH })

      request
        .then(res => dispatch({ type: PRODUCT_CART_REFRESH_SUCCESS, payload: res.data }))
        .catch(err => dispatch({ type: PRODUCT_CART_REFRESH_FAILURE, payload: err }));

    }
  } else {
    return dispatch => dispatch({ type: EMPTY_ACTION })
  }
}

export function getProductDetails(city_id, product_id, community_id = undefined) {
  let data = `city_id=${city_id}&product_id=${product_id}`;

  if (community_id) {
    data += `&community_id=${community_id}`;
  }

  if (localStorage.getItem(cl_signedIn) === 'true') {
    data += '&access_token=' + localStorage.getItem(cl_access_token);
  }

  let request = axios.get(`${API_LINK}/gs_Product/gs_getProductDetail?${data}`)

  return (dispatch) => {
    dispatch({ type: PRODUCT_DETAILS })

    request
      .then(res => dispatch({ type: PRODUCT_DETAILS_SUCCESS, payload: { apiData: res.data, cache: { city_id, product_id, community_id } } }))
      .catch(err => dispatch({ type: PRODUCT_DETAILS_ERROR, payload: err }));

  }
}

export function setProductDetailsSku(skuObj) {
  return dispatch => dispatch({ type: PRODUCT_DETAILS_SELECT_SKU, payload: skuObj })
}

export function getClientAddresses(city_id, cart_address_data = undefined) {
  let data = `city_id=${city_id}`;

  let user_id = undefined;

  if (localStorage.getItem(cl_signedIn) === 'true') {
    user_id = localStorage.getItem(cl_user_id);
    data += '&access_token=' + localStorage.getItem(cl_access_token);
  }

  let request = axios.get(`${API_LINK}/gs_User_ShippingInfo/gs_getUserPropertiesForProducts?${data}`)

  return dispatch => {
    dispatch({ type: CLIENT_ADDRESSES });

    request
      .then(res => dispatch({ type: CLIENT_ADDRESSES_SUCCESS, payload: { apiData: res.data, cache: { city_id, user_id }, cart_address_data } }))
      .catch(err => dispatch({ type: CLIENT_ADDRESSES_ERROR, payload: err }));
  }
}

export function setShippingAddress(address) {
  return dispatch => dispatch({ type: CLIENT_ADDRESSES_SHIPPING_SELECT, payload: address })
}

export function setProductCommunity(community_id) {
  return dispatch => dispatch({ type: PRODUCT_COMMUNITY, payload: community_id });
}

export function setOnlineRedirect(paymentMode) {
  return dispatch => dispatch({ type: PRODUCT_CART_ONLINE_REDIRECT, payload: paymentMode })
}

export function setDeliveryDate(delivery_date) {
  return dispatch => dispatch({ type: PRODUCT_DELIVERY_DATE_SELECT, payload: delivery_date })
}

export function setTipAmount(amount) {
  return dispatch => dispatch({ type: PRODUCT_CART_TIP_AMOUNT, payload: amount })
}

export function useRewardsForProducts(useRewards) {
  return dispatch => dispatch({ type: PRODUCT_CART_USE_REWARDS, payload: useRewards })
}

export function checkoutProductOrder(porder) {
  if (localStorage.getItem(cl_signedIn) === 'true') {
    const access_token = localStorage.getItem(cl_access_token);

    const data = `access_token=${access_token}`;

    let request = axios.post(`${API_LINK}/gs_Product_Order/gs_poCheckout?${data}`, porder);

    return dispatch => {
      dispatch({ type: PRODUCT_CART_CHECKOUT })

      request
        .then(res => dispatch({ type: PRODUCT_CART_CHECKOUT_SUCCESS, payload: res.data }))
        .catch(err => {
          if (err.response && err.response.data && err.response.data.error) {
            dispatch({ type: PRODUCT_CART_CHECKOUT_FAILURE, payload: err.response.data.error })
          }
          else {
            dispatch({ type: PRODUCT_CART_CHECKOUT_FAILURE, payload: err })
          }
        });

    }
  } else {
    return dispatch => dispatch({ type: EMPTY_ACTION })
  }
}

export function clearClientAddressCache() {
  return dispatch => dispatch({ type: CLIENT_ADDRESSES_CLEAR_CACHE })
}

// Use route parameters for this
export function updateProductScroll(screen, state) {
  return (dispatch) => {
    dispatch({ type: UPDATE_PRODUCT_SCROLL, data: { [screen]: state } });
  };
}

export function getSocietyMsgBoardList(community_id, prop_id, msgb_type = "", last_message_id = undefined, batch_size = 10) {
  let data = `batch_size=${batch_size}`;
  if (community_id) {
    data += `&community_id=${community_id}`;
  }
  if (prop_id) {
    data += `&prop_id=${prop_id}`;
  }
  if (msgb_type) {
    data += `&msgb_type=${msgb_type}`;
  }
  if (last_message_id) {
    data += `&last_msgb_id=${last_message_id}`
  }
  if (localStorage.getItem(cl_signedIn) === 'true') {
    data += '&access_token=' + localStorage.getItem(cl_access_token);
  }

  let request = axios.get(`${API_LINK}/gs_cmnt_messageboard/gs_getCmbPosts?${data}`)

  return (dispatch) => {
    if (last_message_id) {
      dispatch({ type: SOCIETY_MSG_BOARD_LIST_MORE })
    } else {
      dispatch({ type: SOCIETY_MSG_BOARD_LIST })
    }

    request
      .then(res => dispatch({ type: SOCIETY_MSG_BOARD_LIST_SUCCESS, payload: { apiData: res.data, batchSize: batch_size, cache: { community_id, prop_id, msgb_type, last_message_id } } }))
      .catch(err => dispatch({ type: SOCIETY_MSG_BOARD_LIST_ERROR, payload: err }));
  }
}

// Use route parameters for this
export function clearSocietyMsgBoardCache() {
  return (dispatch) => {
    dispatch({ type: CLEAR_SOCIETY_MSG_BOARD_LIST });
  };
}

export function getGateApprovalRequests() {
  let data = '';
  if (localStorage.getItem(cl_signedIn) === 'true') {
    data = 'access_token=' + localStorage.getItem(cl_access_token);
  }

  let request = axios.get(`${API_LINK}/gs_Community/gs_getGateApprovalRequests?${data}`);

  const dispatch = store.dispatch;

  dispatch({ type: SOCIETY_GATE_APPROVAL_LIST });

  request
    .then(res => dispatch({ type: SOCIETY_GATE_APPROVAL_LIST_SUCCESS, payload: res.data }))
    .catch(err => dispatch({ type: SOCIETY_GATE_APPROVAL_LIST_ERROR, payload: err }));
}

export function ignoreGateRequest(vlog_id) {
  return (dispatch) => {
    dispatch({ type: SOCIETY_GATE_APPROVAL_LIST_IGNORE_ENTRY, payload: { vlog_id } });
  }
}

export function removeGateRequest(vlog_id) {
  return (dispatch) => {
    dispatch({ type: SOCIETY_GATE_APPROVAL_LIST_REMOVE_ENTRY, payload: { vlog_id } });
  }
}

export function hideGateApprovalScreen() {
  return (dispatch) => {
    dispatch({ type: HIDE_SOCIETY_GATE_APPROVAL });
  }
}

export function showGateApprovalScreen() {
  return (dispatch) => {
    dispatch({ type: SHOW_SOCIETY_GATE_APPROVAL });
  }
}

export function getPropComplaintList(community_id, prop_id, msgb_type = "", last_message_id = undefined, batch_size = 10) {
  let data = `batch_size=${batch_size}`;
  if (community_id) {
    data += `&community_id=${community_id}`;
  }
  if (prop_id) {
    data += `&prop_id=${prop_id}`;
  }
  if (msgb_type) {
    data += `&msgb_type=${msgb_type}`;
  }
  if (last_message_id) {
    data += `&last_msgb_id=${last_message_id}`
  }
  if (localStorage.getItem(cl_signedIn) === 'true') {
    data += '&access_token=' + localStorage.getItem(cl_access_token);
  }

  let request = axios.get(`${API_LINK}/gs_cmnt_messageboard/gs_getCmbPosts?${data}`)

  return (dispatch) => {
    if (last_message_id) {
      dispatch({ type: SOCIETY_COMPLAINTS_LIST_MORE })
    } else {
      dispatch({ type: SOCIETY_COMPLAINTS_LIST })
    }

    request
      .then(res => dispatch({ type: SOCIETY_COMPLAINTS_LIST_SUCCESS, payload: { apiData: res.data, batchSize: batch_size, cache: { community_id, prop_id, msgb_type, last_message_id } } }))
      .catch(err => dispatch({ type: SOCIETY_COMPLAINTS_LIST_ERROR, payload: err }));
  }
}

export function getSocietyComplaintsList(req) {
  const { community_id, board_id, prop_id, status, priority, dept_id, subdept_id, type, assignedTo, last_complaint_id, batch_size, searchText } = req;
  const batchSize = batch_size || 10;

  let data = `batch_size=${batchSize}`;
  if (community_id) {
    data += `&community_id=${community_id}`;
  }
  if (board_id) {
    data += `&board_id=${board_id}`;
  }
  if (prop_id) {
    data += `&prop_id=${prop_id}`;
  }
  if (status) {
    data += `&status=${status}`;
  }
  if (priority) {
    data += `&priority=${priority}`
  }
  if (dept_id) {
    data += `&dept_id=${dept_id}`
  }
  if (subdept_id) {
    data += `&subdept_id=${subdept_id}`
  }
  if (type) {
    data += `&type=${type}`;
  }
  if (assignedTo) {
    data += `&only_assigned_to_me=${assignedTo}`
  }
  if (last_complaint_id) {
    data += `&last_complaint_id=${last_complaint_id}`
  }
  if (localStorage.getItem(cl_signedIn) === 'true') {
    data += '&access_token=' + localStorage.getItem(cl_access_token);
  }
  if (searchText) {
    data += `&search_text=${searchText}`
  }

  let request = axios.get(`${API_LINK}/gs_cmnt_complaints/gs_getComplaintList?${data}`)

  return (dispatch) => {
    if (last_complaint_id) {
      dispatch({ type: SOCIETY_COMPLAINTS_LIST_MORE })
    } else {
      dispatch({ type: SOCIETY_COMPLAINTS_LIST })
    }

    request
      .then(res => dispatch({ type: SOCIETY_COMPLAINTS_LIST_SUCCESS, payload: { apiData: res.data, batchSize: batchSize, cache: req } }))
      .catch(err => dispatch({ type: SOCIETY_COMPLAINTS_LIST_ERROR, payload: err }));
  }
}

export function getComplaintDetails(complaint_id) {
  let data = `complaint_id=${complaint_id}`;

  if (localStorage.getItem(cl_signedIn) === 'true') {
    data += '&access_token=' + localStorage.getItem(cl_access_token);
  }

  let request = axios.get(`${API_LINK}/gs_cmnt_complaints/gs_getComplaintDetails?${data}`)

  return dispatch => {
    dispatch({ type: SOCIETY_COMPLAINTS_DETAILS });

    request
      .then(res => dispatch({ type: SOCIETY_COMPLAINTS_DETAILS_SUCCESS, payload: res.data }))
      .catch(err => dispatch({ type: SOCIETY_COMPLAINTS_DETAILS_ERROR, payload: err }));
  }
}

export function getSocietyMessageDetails(msgb_id) {
  let data = `msgb_id=${msgb_id}`;

  if (localStorage.getItem(cl_signedIn) === 'true') {
    data += '&access_token=' + localStorage.getItem(cl_access_token);
  }

  let request = axios.get(`${API_LINK}/gs_cmnt_messageboard/gs_getCmbPostDetails?${data}`)

  return dispatch => {
    dispatch({ type: SOCIETY_MESSAGE_DETAILS });

    request
      .then(res => dispatch({ type: SOCIETY_MESSAGE_DETAILS_SUCCESS, payload: { apiData: res.data, cache: { msgb_id } } }))
      .catch(err => dispatch({ type: SOCIETY_MESSAGE_DETAILS_ERROR, payload: err }));
  }
}

export function getSocietyDepartments(community_id, board_id, prop_id) {
  let data = `community_id=${community_id}`;
  if (board_id) {
    data += '&board_id=' + board_id;
  }
  if (prop_id) {
    data += '&prop_id=' + prop_id;
  }
  if (localStorage.getItem(cl_signedIn) === 'true') {
    data += '&access_token=' + localStorage.getItem(cl_access_token);
  }

  let request = axios.get(`${API_LINK}/gs_cmnt_complaints/gs_getDepartmentsForCommunity?${data}`)

  return dispatch => {
    dispatch({ type: SOCIETY_DEPARTMENTS });

    request
      .then(res => dispatch({ type: SOCIETY_DEPARTMENTS_SUCCESS, payload: { apiData: res.data, cache: { community_id: community_id, prop_id: prop_id } } }))
      .catch(err => dispatch({ type: SOCIETY_DEPARTMENTS_ERROR, payload: err }));
  }
}

export function updateMessageBoardScroll(scroll_offset) {
  return (dispatch) => {
    dispatch({ type: SOCIETY_MSG_BOARD_LIST_SCROLL, payload: { scroll_offset: scroll_offset } });
  };
}

export function updateComplaintScroll(scroll_offset) {
  return (dispatch) => {
    dispatch({ type: SOCIETY_COMPLAINTS_LIST_SCROLL, payload: { scroll_offset: scroll_offset } });
  };
}

export function updateNewCommentMessageBoard(message_id, no_of_comments) {
  return (dispatch) => {
    dispatch({ type: SOCIETY_MESSAGE_NEW_COMMENT, payload: { message_id, no_of_comments } });
  };
}

export function getEMeetingRequests() {
  let data = '';
  if (localStorage.getItem(cl_signedIn) === 'true') {
    data = 'access_token=' + localStorage.getItem(cl_access_token);
  }

  let request = axios.get(`${API_LINK}/gs_Community/gs_GetActiveMeetingsForUser?${data}`);
  // let request = new Promise((resolve, reject) => {
  //   resolve({
  //     data: {
  //       statusCode: 200,
  //       gsRespData: {
  //         active_meetings: {
  //           eicomm: [{
  //             meeting_id: 'GRS_ABCD1',
  //             community_id: 'ff72186e-e3d7-4b2a-86ea-65f39068e887',
  //             organizer_id: '1234',
  //             organizer_name: 'Ravi Patel',
  //             organizer_realm: 'svc_consumer',
  //             profile_pic: null,
  //             start_ts: null
  //           }]
  //         }
  //       }
  //     }
  //   });
  // });

  return (dispatch) => {
    dispatch({ type: SOCIETY_EMEETING_LIST });

    request
      .then(res => dispatch({ type: SOCIETY_EMEETING_LIST_SUCCESS, payload: res.data }))
      .catch(err => dispatch({ type: SOCIETY_EMEETING_LIST_ERROR, payload: err }));
  }
}

export function ignoreEMeetingRequest(meeting_id) {
  return (dispatch) => {
    dispatch({ type: SOCIETY_EMEETING_LIST_IGNORE_MEETING, payload: { meeting_id } });
  }
}

export function removeEMeetingRequest(meeting_id) {
  return (dispatch) => {
    dispatch({ type: SOCIETY_EMEETING_LIST_REMOVE_MEETING, payload: { meeting_id } });
  }
}

export function hideEMeetingRequestScreen() {
  return (dispatch) => {
    dispatch({ type: HIDE_SOCIETY_EMEETING_REQUEST });
  }
}

export function showEMeetingRequestScreen() {
  return (dispatch) => {
    dispatch({ type: SHOW_SOCIETY_EMEETING_REQUEST });
  }
}

export function hideMiscellaneousComponentsScreen() {
  return (dispatch) => {
    dispatch({ type: HIDE_MISCELLANEOUS_COMPONENTS });
  }
}

export function showMiscellaneousComponentsScreen() {
  return (dispatch) => {
    dispatch({ type: SHOW_MISCELLANEOUS_COMPONENTS });
  }
}

export function updateEmailHighlight(value) {
  return (dispatch) => {
    dispatch({ type: UPDATE_EMAIL_HIGHLIGHT, payload: value });
  }
}

export function updateMiscellaneousComponents(payload) {
  return (dispatch) => {
    dispatch({ type: UPDATE_MISCELLANEOUS_COMPONENTS, payload: payload });
  }
}

export function updatePollScroll(scroll_offset) {
  return (dispatch) => {
    dispatch({ type: SOCIETY_POLL_LIST_SCROLL, payload: { scroll_offset: scroll_offset } });
  };
}

export function getSocietyTransactions(req) {
  const { community_id, board_id, status, txn_type, vendor_cat, last_txn_id, batch_size, selected_month, prop_id, payment_mode, filter_prop_id } = req;
  const batchSize = batch_size || 10;

  let data = `limit=${batchSize}`;

  if (community_id) {
    data += `&community_id=${community_id}`;
  }
  if (board_id) {
    data += `&board_id=${board_id}`;
  }
  if (status) {
    data += `&status=${status}`;
  }
  if (txn_type) {
    data += `&txn_type=${txn_type}`;
  }
  if (vendor_cat) {
    data += `&vendor_cat=${vendor_cat}`;
  }
  if (last_txn_id) {
    data += `&last_txn_id=${last_txn_id}`
  }
  if (payment_mode) {
    data += `&payment_mode=${payment_mode}`
  }
  if (selected_month && typeof selected_month === "string") {
    const split = selected_month.split(',');
    const month = split[0].trim();
    const year = split[1].trim();

    data += `&selected_month=${JSON.stringify({ month, year })}`
  }
  if (prop_id) {
    data += `&prop_id=${prop_id}`
  }
  if (filter_prop_id) {
    data += `&prop_id=${filter_prop_id}`
  }
  if (localStorage.getItem(cl_signedIn) === 'true') {
    data += '&access_token=' + localStorage.getItem(cl_access_token);
  }

  let request = axios.get(`${API_LINK}/gs_Community/gs_getCommunityTransactions?${data}`)

  return (dispatch) => {
    if (last_txn_id) {
      dispatch({ type: SOCIETY_TRANSACTIONS_MORE })
    } else {
      dispatch({ type: SOCIETY_TRANSACTIONS })
    }

    request
      .then(res => dispatch({ type: SOCIETY_TRANSACTIONS_SUCCESS, payload: { apiData: res.data, batchSize: batchSize, cache: req } }))
      .catch(err => dispatch({ type: SOCIETY_TRANSACTIONS_ERROR, payload: err }));
  }
}

export function clearSocietyTransactionsCache() {
  return dispatch => dispatch({ type: SOCIETY_TRANSACTIONS_CLEAR_CACHE })
}

export function updateSocietyTransactionsScroll(scroll_offset) {
  return (dispatch) => {
    dispatch({ type: SOCIETY_TRANSACTIONS_SCROLL, payload: { scroll_offset: scroll_offset } });
  };
}

///
export function getPettyCashTransactions(req) {
  const { community_id, board_id, status, txn_type, vendor_cat, last_txn_id, batch_size, selected_month, prop_id, payment_mode } = req;
  const batchSize = batch_size || 10;

  let data = `limit=${batchSize}`;

  if (community_id) {
    data += `&community_id=${community_id}`;
  }
  if (board_id) {
    data += `&board_id=${board_id}`;
  }
  if (status) {
    data += `&status=${status}`;
  }
  if (txn_type) {
    data += `&txn_type=${txn_type}`;
  }
  if (vendor_cat) {
    data += `&vendor_cat=${vendor_cat}`;
  }
  if (last_txn_id) {
    data += `&last_txn_id=${last_txn_id}`
  }
  if (payment_mode) {
    data += `&payment_mode=${payment_mode}`
  }
  if (selected_month && typeof selected_month === "string") {
    const split = selected_month.split(',');
    const month = split[0].trim();
    const year = split[1].trim();

    data += `&selected_month=${JSON.stringify({ month, year })}`
  }  
  if (localStorage.getItem(cl_signedIn) === 'true') {
    data += '&access_token=' + localStorage.getItem(cl_access_token);
  }

  let request = axios.get(`${API_LINK}/gs_Community/gs_getPettyCashTransactions?${data}`)

  return (dispatch) => {
    if (last_txn_id) {
      dispatch({ type: SOCIETY_TRANSACTIONS_MORE })
    } else {
      dispatch({ type: SOCIETY_TRANSACTIONS })
    }

    request
      .then(res => dispatch({ type: SOCIETY_TRANSACTIONS_SUCCESS, payload: { apiData: res.data, batchSize: batchSize, cache: req } }))
      .catch(err => dispatch({ type: SOCIETY_TRANSACTIONS_ERROR, payload: err }));
  }
}

export function clearPettyCashTransactionsCache() {
  return dispatch => dispatch({ type: SOCIETY_TRANSACTIONS_CLEAR_CACHE })
}

export function updatePettyCashTransactionsScroll(scroll_offset) {
  return (dispatch) => {
    dispatch({ type: SOCIETY_TRANSACTIONS_SCROLL, payload: { scroll_offset: scroll_offset } });
  };
}

///

export function getPropertyTransactions(req) {
  const { status, last_created_at, batch_size, prop_id } = req;
  const batchSize = batch_size || 10;

  let data = `limit=${batchSize}`;


  if (status) {
    data += `&status=${status}`;
  }
  if (last_created_at) {
    data += `&last_created_at=${last_created_at}`
  }
  if (prop_id) {
    data += `&prop_id=${prop_id}`
  }
  if (localStorage.getItem(cl_signedIn) === 'true') {
    data += '&access_token=' + localStorage.getItem(cl_access_token);
  }

  let request = axios.get(`${API_LINK}/gs_Community/gs_getPropertyTransactions?${data}`)

  return (dispatch) => {
    if (last_created_at) {
      dispatch({ type: PROPERTY_TRANSACTIONS_MORE })
    } else {
      dispatch({ type: PROPERTY_TRANSACTIONS })
    }

    request
      .then(res => dispatch({ type: PROPERTY_TRANSACTIONS_SUCCESS, payload: { apiData: res.data, batchSize: batchSize, cache: req } }))
      .catch(err => dispatch({ type: PROPERTY_TRANSACTIONS_ERROR, payload: err }));
  }
}

export function clearPropertyTransactionsCache() {
  return dispatch => dispatch({ type: PROPERTY_TRANSACTIONS_CLEAR_CACHE })
}

export function updatePropertyTransactionsScroll(scroll_offset) {
  return (dispatch) => {
    dispatch({ type: PROPERTY_TRANSACTIONS_SCROLL, payload: { scroll_offset: scroll_offset } });
  };
}

export function getTransactionDetails(txn_id, txn_type, is_community_txn) {
  let data = `txn_id=${txn_id}&txn_type=${txn_type}&is_cmnt_txn=${is_community_txn}`;

  if (localStorage.getItem(cl_signedIn) === 'true') {
    data += '&access_token=' + localStorage.getItem(cl_access_token);
  }

  let request = axios.get(`${API_LINK}/gs_Community/gs_getTransactionDetails?${data}`)

  return (dispatch) => {
    dispatch({ type: TRANSACTION_DETAILS })

    request
      .then(res => dispatch({ type: TRANSACTION_DETAILS_SUCCESS, payload: { apiData: res.data } }))
      .catch(err => dispatch({ type: TRANSACTION_DETAILS_ERROR, payload: err }));
  }
}

export function clearTransactionDetailsCache(txn_id) {
  return dispatch => dispatch({ type: PROPERTY_TRANSACTIONS_CLEAR_CACHE, payload: { txn_id } })
}

export function getSocietyBills(req) {
  const { community_id, last_bill_id, batch_size, selected_month, filter_prop_id } = req;
  const batchSize = batch_size || 10;

  let data = `limit=${batchSize}`;

  if (community_id) {
    data += `&community_id=${community_id}`;
  }
  if (last_bill_id) {
    data += `&last_bill_id=${last_bill_id}`
  }
  if (selected_month && typeof selected_month === "string") {
    const split = selected_month.split(',');
    const month = split[0].trim();
    const year = split[1].trim();

    data += `&selected_month=${JSON.stringify({ month, year })}`
  }
  if (filter_prop_id) {
    data += `&prop_id=${filter_prop_id}`
  }
  if (localStorage.getItem(cl_signedIn) === 'true') {
    data += '&access_token=' + localStorage.getItem(cl_access_token);
  }

  let request = axios.get(`${API_LINK}/gs_Community/gs_getCommunityPBills?${data}`)

  return (dispatch) => {
    if (last_bill_id) {
      dispatch({ type: SOCIETY_BILLS_MORE })
    } else {
      dispatch({ type: SOCIETY_BILLS })
    }

    request
      .then(res => dispatch({ type: SOCIETY_BILLS_SUCCESS, payload: { apiData: res.data, batchSize: batchSize, cache: req } }))
      .catch(err => dispatch({ type: SOCIETY_BILLS_ERROR, payload: err }));
  }
}

export function clearSocietyBillsCache() {
  return dispatch => dispatch({ type: SOCIETY_BILLS_CLEAR_CACHE })
}

export function updateSocietyBillsScroll(scroll_offset) {
  return (dispatch) => {
    dispatch({ type: SOCIETY_BILLS_SCROLL, payload: { scroll_offset: scroll_offset } });
  };
}

export function getSocietyInvoices(req) {
  const { community_id, last_bill_id, batch_size, selected_month, filter_prop_id } = req;
  const batchSize = batch_size || 10;

  let data = `limit=${batchSize}`;
  data += `&status=paid`;

  if (community_id) {
    data += `&community_id=${community_id}`;
  }
  if (last_bill_id) {
    data += `&last_bill_id=${last_bill_id}`
  }
  if (selected_month && typeof selected_month === "string") {
    const split = selected_month.split(',');
    const month = split[0].trim();
    const year = split[1].trim();

    data += `&selected_month=${JSON.stringify({ month, year })}`
  }
  if (filter_prop_id) {
    data += `&prop_id=${filter_prop_id}`
  }
  if (localStorage.getItem(cl_signedIn) === 'true') {
    data += '&access_token=' + localStorage.getItem(cl_access_token);
  }

  let request = axios.get(`${API_LINK}/gs_Community/gs_getCommunityPBills?${data}`)

  return (dispatch) => {
    if (last_bill_id) {
      dispatch({ type: SOCIETY_INVOICES_MORE })
    } else {
      dispatch({ type: SOCIETY_INVOICES })
    }

    request
      .then(res => dispatch({ type: SOCIETY_INVOICES_SUCCESS, payload: { apiData: res.data, batchSize: batchSize, cache: req } }))
      .catch(err => dispatch({ type: SOCIETY_INVOICES_ERROR, payload: err }));
  }
}

export function clearSocietyInvoicesCache() {
  return dispatch => dispatch({ type: SOCIETY_INVOICES_CLEAR_CACHE })
}

export function updateSocietyInvoicesScroll(scroll_offset) {
  return (dispatch) => {
    dispatch({ type: SOCIETY_INVOICES_SCROLL, payload: { scroll_offset: scroll_offset } });
  };
}

export function updateSocietyInvoicesSelection(is_selected) {
  return (dispatch) => {
    dispatch({ type: SOCIETY_INVOICES_SELECTION, payload: { is_selected: is_selected } });
  };
}

export function getSocietySettlements(req) {
  const { community_id, last_created_at, batch_size, selected_month } = req;
  const batchSize = batch_size || 10;

  let data = `limit=${batchSize}`;

  if (community_id) {
    data += `&community_id=${community_id}`;
  }
  if (last_created_at) {
    data += `&last_created_at=${last_created_at}`
  }
  if (selected_month && typeof selected_month === "string") {
    const split = selected_month.split(',');
    const month = split[0].trim();
    const year = split[1].trim();

    data += `&selected_month=${JSON.stringify({ month, year })}`
  }
  if (localStorage.getItem(cl_signedIn) === 'true') {
    data += '&access_token=' + localStorage.getItem(cl_access_token);
  }

  let request = axios.get(`${API_LINK}/gs_Community_Settlement/gs_getCommunitySettlementList?${data}`)

  return (dispatch) => {
    if (last_created_at) {
      dispatch({ type: SOCIETY_SETTLEMENTS_MORE })
    } else {
      dispatch({ type: SOCIETY_SETTLEMENTS })
    }

    request
      .then(res => dispatch({ type: SOCIETY_SETTLEMENTS_SUCCESS, payload: { apiData: res.data, batchSize: batchSize, cache: req } }))
      .catch(err => dispatch({ type: SOCIETY_SETTLEMENTS_ERROR, payload: err }));
  }
}

export function clearSocietySettlementsCache() {
  return dispatch => dispatch({ type: SOCIETY_SETTLEMENTS_CLEAR_CACHE })
}

export function updateSocietySettlementsScroll(scroll_offset) {
  return (dispatch) => {
    dispatch({ type: SOCIETY_SETTLEMENTS_SCROLL, payload: { scroll_offset: scroll_offset } });
  };
}

export function getSettlementDetails(settlement_id, community_id, include_pending = false) {
  let data = `settlement_id=${settlement_id}`, is_pending = false;
  if (!settlement_id && include_pending) {
    is_pending = true;
    data = `include_pending_sett=true&community_id=${community_id}`;
  }

  if (localStorage.getItem(cl_signedIn) === 'true') {
    data += '&access_token=' + localStorage.getItem(cl_access_token);
  }

  let request = axios.get(`${API_LINK}/gs_Community_Settlement/gs_getCommunitySettlementDetails?${data}`)

  return (dispatch) => {
    dispatch({ type: SETTLEMENT_DETAILS })

    request
      .then(res => dispatch({ type: SETTLEMENT_DETAILS_SUCCESS, payload: { apiData: res.data, is_pending } }))
      .catch(err => dispatch({ type: SETTLEMENT_DETAILS_ERROR, payload: err }));
  }
}

export function getGeonamesStateList(country_code) {
  let request = axios.get(`${API_LINK}/gs_Cities/gs_getAllStatesForCountry?country_code=${country_code}`)

  return (dispatch) => {
    dispatch({ type: GEONAMES_STATE_LIST })

    request
      .then(res => dispatch({ type: GEONAMES_STATE_LIST_SUCCESS, payload: { apiData: res.data, cache: { country_code } } }))
      .catch(err => dispatch({ type: GEONAMES_STATE_LIST_ERROR, payload: err }));
  }
}

export function getGeonamesCityList(country_code, state_code) {
  let reqObject = `country_code=${country_code}`;
  if (state_code) {
    reqObject += `&state_code=${state_code}`;
  }
  let request = axios.get(`${API_LINK}/gs_Cities/gs_getAllCitiesForCountry?${reqObject}`)

  return (dispatch) => {
    dispatch({ type: GEONAMES_CITY_LIST })

    request
      .then(res => dispatch({ type: GEONAMES_CITY_LIST_SUCCESS, payload: { apiData: res.data, cache: { country_code, state_code } } }))
      .catch(err => dispatch({ type: GEONAMES_CITY_LIST_ERROR, payload: err }));
  }
}

export function clearGeonamesCityList() {
  return dispatch => dispatch({ type: GEONAMES_CITY_LIST_CLEAR_CACHE })
}

export function getGeonamesOtherCitySPList(city_id) {
  let request = axios.get(`${API_LINK}/gs_Cities/gs_getSPListForCity?city_id=${city_id}`)

  return (dispatch) => {
    dispatch({ type: GEONAMES_OTHER_CITY_DATA })

    request
      .then(res => dispatch({ type: GEONAMES_OTHER_CITY_DATA_SUCCESS, payload: { apiData: res.data } }))
      .catch(err => dispatch({ type: GEONAMES_OTHER_CITY_DATA_ERROR, payload: err }));
  }
}

export function updateGeonamesOtherCityData(data) {
  return (dispatch) => {
    dispatch({ type: GEONAMES_OTHER_CITY_DATA_UDPATE_CACHE, payload: data });
  };
}

export function clearGeonamesOtherCityData() {
  return dispatch => dispatch({ type: GEONAMES_OTHER_CITY_DATA_CLEAR_CACHE })
}

export function getRestaurantDataApi(page_url) {
  let request = axios.get(`${API_LINK}/gs_hcomp_opunit/gs_getOpUnitHomePageUrl?opunit_id=${page_url}`)

  return (dispatch) => {
    dispatch({ type: GET_RESTAURANT_DATA, page_url: page_url })

    request
      .then(res => dispatch({ type: GET_RESTAURANT_DATA_SUCCESS, payload: res.data, cache: { page_url: page_url }, page_url: page_url }))
      .catch(err => dispatch({ type: GET_RESTAURANT_DATA_ERROR, payload: err, page_url: page_url }));
  }
}

export const filterRestaurantData = (is_veg, page_url) => {
  return (dispatch) => {
    dispatch({ type: FILTER_RESTAURANT_DATA, payload: { is_veg }, page_url: page_url });
  };
};

export const menuItemCustomize = (data) => {
  return (dispatch) => {
    dispatch({ type: MENU_ITEM_CUSTOMIZATION, payload: data });
  }
}

export const addCartItem = (reqData) => {
  return (dispatch) => {
    dispatch({ type: ADD_CART, payload: reqData });
  };
};

export const removeCartItem = (reqData) => {
  return (dispatch) => {
    dispatch({ type: REMOVE_CART, payload: reqData });
  };
};

export const clearCart = () => {
  return (dispatch) => {
    dispatch({ type: CLEAR_CART });
  };
};

export const updateCouponCode = (req) => {
  return (dispatch) => {
    dispatch({ type: UPDATE_COUPON_CODE, payload: req });
  };
}

export const clearCouponCode = () => {
  return (dispatch) => {
    dispatch({ type: CLEAR_COUPON_CODE });
  };
}

export function getDeliveryDataApi(pihcomp_id, piopunit_id) {
  let request = axios.get(`${API_LINK}/gs_hcopu_delivery/gs_GetDeliveryData?pihcomp_id=${pihcomp_id}&piopunit_id=${piopunit_id}`)

  return (dispatch) => {
    dispatch({ type: GET_DELIVERY_DATA });

    request
      .then(res => dispatch({ type: GET_DELIVERY_DATA_SUCCESS, payload: res.data, cache: { comp_id: pihcomp_id, opunit_id: piopunit_id } }))
      .catch(err => dispatch({ type: GET_DELIVERY_DATA_ERROR, payload: err }));
  }
}

export const updateCheckoutOptions = (req) => {
  return (dispatch) => dispatch({ type: UPDATE_CHECKOUT_OPTIONS, payload: req });
};

export const callGetDiscountListApi = (reqData) => {
  const {
    pihcomp_id, piopunit_id, picoupon_code, pidelivery_type,
  } = reqData;

  let data = `pihcomp_id=${pihcomp_id}&piopunit_id=${piopunit_id}`;
  if (picoupon_code) {
    data += `&picoupon_code=${picoupon_code}`;
  }
  if (pidelivery_type) {
    data += `&pidelivery_type=${pidelivery_type}`;
  }
  if (localStorage.getItem(cl_signedIn) === 'true') {
    data += '&user_id=' + localStorage.getItem(cl_user_id);
    data += '&access_token=' + localStorage.getItem(cl_access_token);
  }
  data += `&olagate_coupon=true`;

  let request = axios.get(`${API_LINK}/gs_hcopu_discount/gs_GetDiscountCodeList?${data}`);

  return (dispatch) => {
    dispatch({ type: GET_DISCOUNT_CODE_LIST });

    request
      .then((res) =>
        dispatch({ type: GET_DISCOUNT_CODE_LIST_SUCCESS, payload: res.data, cache: reqData })
      )
      .catch((err) =>
        dispatch({ type: GET_DISCOUNT_CODE_LIST_ERROR, payload: err })
      );
  };
};

export const clearDiscountListCache = () => {
  return (dispatch) => {
    dispatch({ type: CLEAR_DISCOUNT_CODE_LIST_CACHE });
  };
}

export function getOGFoodDataListApi(filters) {
  const resp = {
    "cat_list": [
      {
        "cat_id": 11,
        "cat_icon": "https://storage.googleapis.com/gruhaps-gcs-public/grs.pi/pct/snacks_fastfood.jpg",
        "cat_name": "Fruits & Vegetables"
      },
      {
        "cat_id": 13,
        "cat_icon": "https://storage.googleapis.com/gruhaps-gcs-public/grs.pi/pct/snacks_fastfood.jpg",
        "cat_name": "Hotel & Restaurant"
      },
      {
        "cat_id": 12,
        "cat_icon": "https://storage.googleapis.com/gruhaps-gcs-public/grs.pi/pct/snacks_fastfood.jpg",
        "cat_name": "Groceries"
      },
      {
        "cat_id": 14,
        "cat_icon": "https://storage.googleapis.com/gruhaps-gcs-public/grs.pi/pct/snacks_fastfood.jpg",
        "cat_name": "Wine"
      },
      {
        "cat_id": 15,
        "cat_icon": "https://storage.googleapis.com/gruhaps-gcs-public/grs.pi/pct/snacks_fastfood.jpg",
        "cat_name": "Pan Shop"
      }
    ],
    "opunit_list": [
      {
        "cat_id": 13,
        "opunit_id": 19,
        "unit_name": "OGTest Restaurant OGTest Restaurant OGTest Restaurant OGTest Restaurant OGTest Restaurant",
        "avg_rating": null,
        "media": {
          "pic": [
            "restaurant/mh/nagpur/440012/0ed1bf7b-7e23-4733-85ce-3194914300b9/19/OGGUEST_MEDIA_FILES_f8b179d0-f5d2-11eb-b001-87011b008cf4.jpeg"
          ]
        },
        "address": "Dhantoli Dhantoli Dhantoli Dhantoli Dhantoli Dhantoli Dhantoli Dhantoli Dhantoli Dhantoli Dhantoli",
        "opunit_time": {
          "open_at": "2021-08-05T04:30:00Z",
          "close_at": "2021-08-05T17:30:00Z"
        },
        "opunit_type": "main",
        "review_count": 0,
        "popularity_idx": 0
      }
    ]
  };

  // return (dispatch) => {
  //   dispatch({ type: GET_OGFOOD_DATA_LIST, filters });
  //   setTimeout(() => {
  //     dispatch({ type: GET_OGFOOD_DATA_LIST_SUCCESS, payload: { statusCode: 200, gsRespData: resp }, cache: filters });
  //   }, 500);
  // }

  // return;

  if (!filters) {
    filters = {};
  }
  if (!filters.batch_size) {
    filters.batch_size = 15;
  }

  let data = '';
  if (filters) {
    Object.keys(filters).forEach(key => {
      if (data) {
        data += '&';
      }
      if (typeof filters[key] === "object") {
        data += key + '=' + encodeURI(JSON.stringify(filters[key]));
      }
      else {
        data += key + '=' + filters[key];
      }
    });
  }
  if (data) {
    data = '?' + data;
  }
  let request = axios.get(`${API_LINK}/gs_hcomp_category/gs_GetNearbyBusinessList${data}`)

  return (dispatch) => {
    if (filters && filters.last_opuid) {
      dispatch({ type: GET_OGFOOD_DATA_LIST_MORE, cache: filters });
    }
    else {
      dispatch({ type: GET_OGFOOD_DATA_LIST, cache: filters });
    }

    request
      .then(res => dispatch({ type: GET_OGFOOD_DATA_LIST_SUCCESS, payload: res.data, cache: filters }))
      .catch(err => dispatch({ type: GET_OGFOOD_DATA_LIST_ERROR, payload: err, cache: filters }));
  }
}

export function getOrderListApi(filters) {
  if (!filters) {
    filters = {};
  }
  if (!filters.batch_size) {
    filters.batch_size = 15;
  }
  filters.access_token = localStorage.getItem(cl_access_token);

  // filters = {
  //   // first_ord_date ?: string;
  //   // last_ord_date ?: string;
  //   // last_order_id ?: number;
  //   // batch_size ?: number;
  // }

  let data = '';
  if (filters) {
    Object.keys(filters).forEach(key => {
      if (data) {
        data += '&';
      }
      if (typeof filters[key] === "object") {
        data += key + '=' + encodeURI(JSON.stringify(filters[key]));
      }
      else {
        data += key + '=' + filters[key];
      }
    });
  }
  if (data) {
    data = '?' + data;
  }

  let request = axios.get(`${API_LINK}/gs_hcopu_miorder/gs_GetOrderList${data}`)

  return (dispatch) => {
    if (filters && filters.last_order_id) {
      dispatch({ type: GET_OGFOOD_ORDER_LIST_MORE, cache: filters });
    }
    else {
      dispatch({ type: GET_OGFOOD_ORDER_LIST, cache: filters });
    }

    request
      .then(res => dispatch({ type: GET_OGFOOD_ORDER_LIST_SUCCESS, payload: res.data, cache: filters }))
      .catch(err => dispatch({ type: GET_OGFOOD_ORDER_LIST_ERROR, payload: err }));
  }
}

export function clearOrderListCache() {
  return (dispatch) => {
    dispatch({ type: CLEAR_OGFOOD_ORDER_LIST_CACHE });
  };
}

export function getOrderDetailsApi(filters) {
  if (!filters) {
    filters = {};
  }
  filters.access_token = localStorage.getItem(cl_access_token);

  let data = '';
  if (filters) {
    Object.keys(filters).forEach(key => {
      if (data) {
        data += '&';
      }
      if (typeof filters[key] === "object") {
        data += key + '=' + encodeURI(JSON.stringify(filters[key]));
      }
      else {
        data += key + '=' + filters[key];
      }
    });
  }
  if (data) {
    data = '?' + data;
  }

  let request = axios.get(`${API_LINK}/gs_hcopu_miorder/gs_GetOrderDetails${data}`)

  return (dispatch) => {
    dispatch({ type: GET_OGFOOD_ORDER_DETAILS });

    request
      .then(res => dispatch({ type: GET_OGFOOD_ORDER_DETAILS_SUCCESS, payload: res.data, cache: filters }))
      .catch(err => dispatch({ type: GET_OGFOOD_ORDER_DETAILS_ERROR, payload: err }));
  }
}

export function clearOrderDetailsCache({ order_id }) {
  return (dispatch) => {
    dispatch({ type: CLEAR_OGFOOD_ORDER_DETAILS_CACHE, payload: { order_id } });
  };
}

export function getNearbyServicesApi(filters) {
  if (!filters) {
    filters = {};
  }

  if (!filters.batch_size) {
    filters.batch_size = 15;
  }

  let data = '';
  if (filters) {
    Object.keys(filters).forEach(key => {
      if (data) {
        data += '&';
      }
      if (typeof filters[key] === "object") {
        data += key + '=' + encodeURI(JSON.stringify(filters[key]));
      }
      else {
        data += key + '=' + filters[key];
      }
    });
  }
  if (data) {
    data = '?' + data;
  }

  let request = axios.get(`${API_LINK}/gs_SP_Profile/gs_GetNearbySPList${data}`)

  return (dispatch) => {
    if (filters && filters.last_sp_id) {
      dispatch({ type: GET_NEARBY_SERVICES_MORE, cache: filters });
    }
    else {
      dispatch({ type: GET_NEARBY_SERVICES, cache: filters });
    }

    request
      .then(res => dispatch({ type: GET_NEARBY_SERVICES_SUCCESS, payload: res.data, cache: filters }))
      .catch(err => dispatch({ type: GET_NEARBY_SERVICES_ERROR, payload: err }));
  }
}
