export const discount_type = {
    abs: "abs",
    pct: "pct"
};

export const dcnt_types = {
    category_id: 'category_id',
    service_id: 'service_id',
    portfolio_id: 'portfolio_id',
    svc_cell_id: 'svc_cell_id',
    sp_id: 'sp_id'
};

export const offered_by = {
    system: "system",
    sp: "sp",
}

export const discount_status = {
    pending: 'pending',
    applied: 'applied',
    reverted: 'reverted',
    invalid: 'invalid'
};

export const ALLSERVICE = "ALLSERVICE";

export const MAX_TIMES = 15;
export const MAX_PCT = 100;
export const MAX_INVOICE_PCT = 50;
export const MAX_DISCOUNT = 10000;
export const MIN_DISCOUNT_CODE_LEN = 5;
export const MAX_DISCOUNT_CODE_LEN = 8;
export const MAX_START_DATE = 90;       //  days from today
export const MAX_END_DATE = 1;       //  year from start date