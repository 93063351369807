import React from 'react'

import Home from '../../scenes/client/scenes/home-society';
import Redirect from '../../scenes/redirect';

import qs from 'utils/query-string';

import { updateFromQS } from '../../utils/preprocess-cookies';

export default function HomeImport(props) {
    if (props.location && props.location.pathname === "/" && (!props.location.search || (props.location.search && !(qs.parse(props.location.search).route !== null && qs.parse(props.location.search).route !== undefined)))) {
        if (props.location.search) {
            updateFromQS(qs.parse(props.location.search));
        }

        return <Home {...props} />
    }
    else {
        return <Redirect {...props} />
    }
}