import { currentTimeMoment, compareDate, momentWithTimezone, convertTimseStampToText } from 'utils/date-time-conversion';

export const parseMeetingTime = time => {
    let formatted_time = '';
    let now = currentTimeMoment(0);
    let yesterday = currentTimeMoment(0);
    yesterday.subtract(1, 'days');
    let meeting_time = momentWithTimezone(time, 0);

    if (compareDate(now, meeting_time) === 0) {
        formatted_time = "Today, " + convertTimseStampToText(time, 1);
    }
    else if (compareDate(now, meeting_time) === 0) {
        formatted_time = "Yesterday, " + convertTimseStampToText(time, 1);
    }
    else {
        formatted_time = convertTimseStampToText(time, 2);
    }

    return formatted_time;
}

export const parseMeetingDuration = meeting_duration => {
    let formatted_duration = '';

    if (meeting_duration && !isNaN(meeting_duration)) {
        meeting_duration = parseInt(meeting_duration, 10);

        if (meeting_duration > 3600) {
            let num = meeting_duration / 3600;
            formatted_duration = Math.round(parseFloat(num) * 10) / 10 + ' hrs';
        }
        else if (meeting_duration > 60) {
            formatted_duration = Math.floor(meeting_duration / 60) + ' mins';
        }
        else {
            formatted_duration = meeting_duration + ' secs';
        }
    }

    return formatted_duration;
}

export const parseMeetingLogData = (apiResponse, meeting_type, user_id) => {
    let data = [];
    apiResponse.forEach(item => {
        data.push({
            id: item.meeting_id,
            display_name: item.organizer_id === user_id ? "You" : item.organizer_name,
            profile_pic: item.profile_pic,
            meeting_type: meeting_type || item.meeting_type,
            time: parseMeetingTime(item.start_ts),
            meeting_duration: parseMeetingDuration(item.meeting_duration),
            participants_num: item.participants_num,
            attended: item.attended
        });
    });

    return data;
}