const SideNavOuter = { 
    display: "flex", 
    height: "100%", 
    flexDirection: "column", 
    alignItems: 'flex-end',
    marginRight: '24px'
}

const SideNavInner = {
    marginTop: "8px",
    overflowY: 'auto',
    minHeight: '0',
    flexGrow: 1,
    paddingBottom: '16px'
}

const SideNavItemList = {
    marginTop: '12px'
}

const SideNavDivider = {
    marginTop: '12px'
}

const SideNavFooter = {
    marginTop: '16px'
}

export default {
    SideNavOuter,
    SideNavInner,
    SideNavItemList,
    SideNavDivider,
    SideNavFooter
}