import React, { PureComponent } from 'react'

import commonStyles from '../section-horizontal-scroll/styles'
import '../section-horizontal-scroll/styles.css'

class SectionVerticalScroll extends PureComponent {

    renderData = data => {
        if (data && data.length > 0) {
            return data;
        }
    }

    render() {
        return (
            <div ref={el => { this.el = el; }} style={commonStyles.verticalScroll}>
                {this.renderData(this.props.data || [])}
            </div>
        )
    }
}

export default SectionVerticalScroll